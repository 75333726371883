import {
  Button,
  Input,
  Row,
  Space,
  Form,
} from 'antd'
import React, {Fragment} from 'react'
import {ModalView} from '../../../../../common/ModalView'
import {showNotification} from 'utils'
import {API} from 'aws-amplify'
import {FormattedMessage} from 'react-intl'
import {appStateSelectors} from 'configs/ducks/appState'
import {connect} from 'react-redux'
  
const CollectionTemplateNewModal = ({
  documentChecklistCollectionID,
  isModalVisible,
  toggleModal,
  handleRefresh
}) => {
  const [form] = Form.useForm()
  
  const onFinish = async values => {
    try {
      await API.post('doc-check', '/collections/add-item', {
        body: {
          ...values,
          documentChecklistCollectionID
        }
      })
      form.setFieldsValue({ title: '' })
      toggleModal(true)
      showNotification('success', 'Templates', 'Successfully Created')
      handleRefresh()
    } catch (error) {
      showNotification('error', 'Templates', error.message)
    }
  }
  
  return (
    <Fragment>
      <ModalView
        title='Add New Template Item'
        width={800}
        isModalVisible={isModalVisible}
        handleOk={() => toggleModal(false)}
        handleCancel={() => toggleModal(true)}
        footer={null}
        body={
          <Fragment>
            <Form
              form={form}
              layout='vertical'
              onFinish={onFinish}
              initialValues={{title: ''}}
            >
                
              <Form.Item name='title' label='Title'>
                <Input />
              </Form.Item>
                
              <Row justify='end'>
                <Space>
                  <Button htmlType='submit' type='primary'>
                    <FormattedMessage id='components.button.save' />
                  </Button>
                </Space>
              </Row>
            </Form>
          </Fragment>
        }
      />
    </Fragment>
  )
}
  
const mapStateToProps = state => {
  return {currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,}
}
export default connect(mapStateToProps, {})(CollectionTemplateNewModal)
  