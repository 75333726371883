import {
  List,
  Record
} from 'immutable'

import {
  INPUT_CHANGE,
  CLEAR_STATE
} from '../../constants'

import {combineReducers} from 'redux'

import * as types from './types'

const TeamRecord = Record({
  id: null,
  accountID: null,
  teamName: null,
  active: null,
})

const ReducerRecord = Record({
  entities: List([]),
  loading: false,
  clientRecord: new TeamRecord()
})

const entities = (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.FETCH_TEAMS_REQUEST:
    case types.GET_TEAM_REQUEST:
    case types.SAVE_TEAM_REQUEST:
    case types.DELETE_TEAM_REQUEST:
      return state.set('loading', true)
    case types.FETCH_TEAMS_SUCCESS:
      return state.mergeWith((oldVal, newVal, key) => (key === 'entities' ? List(newVal) : newVal), {
        loading: false,
        entities: payload.map(client => new TeamRecord(client))
      })
    case types.FETCH_TEAMS_ERROR:
    case types.GET_TEAM_ERROR:
    case types.SAVE_TEAM_ERROR:
    case types.SAVE_TEAM_SUCCESS:
    case types.DELETE_TEAM_ERROR:
    case types.DELETE_TEAM_SUCCESS:
      return state.merge({
        loading: false,
        entities: state.entities.filter(client => client.id !== payload)
      })
    case INPUT_CHANGE:
      return state.setIn(['clientRecord', payload.inputName], payload.inputValue)
    case CLEAR_STATE:
      return state.update('clientRecord', record => record.clear())
    default:
      return state
  }
}

const NewClientDefaultState = Record({
  client: new TeamRecord(),
  loading: false,
  duplicateEmail: false
})

const newClient = (state = new NewClientDefaultState(), {type, payload}) => {
  switch (type) {
    case types.CREATE_TEAM_REQUEST:
      return state.set('loading', true)
    case types.CREATE_TEAM_SUCCESS:
    case types.CREATE_TEAM_ERROR:
      return state.set('loading', false)
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}

export default combineReducers({
  entities,
  newClient
})
