import {
  BUCKET,
  DOCW_DOCUMENTS_BUCKET,
  DOCW_TEMPLATES_BUCKET,
  APPLICANT_IMAGES_BUCKET,
} from 'consts'
import {notification} from 'antd'
import { Storage } from 'aws-amplify'
import { IStorage } from './types'

const typedStorage: IStorage = {
  bucket: {
    documents: BUCKET,
    wordTemplates: DOCW_TEMPLATES_BUCKET,
    wordDocuments: DOCW_DOCUMENTS_BUCKET,
    applicantImages: APPLICANT_IMAGES_BUCKET,
  },

  validateBucket(bucketName) {
    if (!this.bucket[bucketName]) {
      notification.error({message: `Bucket ${bucketName} was not found in env`})
    }
  },

  get(fileName, config) {
    const bucketName = this.bucket[config.bucket]

    this.validateBucket(config.bucket)

    return Storage.get(fileName.toString(), {
      ...config,
      bucket: bucketName
    })
  },
  put(fileName, file, config) {
    const bucketName = this.bucket[config.bucket]

    this.validateBucket(config.bucket)

    return Storage.put(fileName.toString(), file, {
      ...config,
      contentType: file.type,
      bucket: bucketName,
    }) as Promise<{ key: string }>
  },
  del(fileName, config) {
    const bucketName = this.bucket[config.bucket]

    this.validateBucket(config.bucket)

    return Storage.remove(fileName.toString(), {
      ...config,
      bucket: bucketName,
    })
  },
}

export default typedStorage
