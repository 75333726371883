import React, {
  useEffect,
  Fragment,
  useState
} from 'react'

import {useHistory} from 'react-router-dom'

import {
  Col,
  Row,
  Spin,
  Form,
  Button,
  Descriptions,
  Drawer,
  Space,
  Switch,
  Input,
  notification,
  Select
} from 'antd'
import moment from 'moment'
import { API } from 'aws-amplify'
import {listHOC} from 'common'

const { Option } = Select

export const ClientCompanyDrawerComponent = listHOC(({
  visible,
  companyDrawerData,
  toggleModal,
  asyncFunction,
  getData,
  countriesList,
  streetCodes,
  currentAccountID,
  clientRecord
}) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [state, setState] = useState({
    id: companyDrawerData?.id,
    firstName: companyDrawerData?.id,
    middleNames: companyDrawerData?.middleNames,
    lastName: companyDrawerData?.lastName,
    officePosition: companyDrawerData?.officePosition,
    address: companyDrawerData?.address,
    dateOfBirth: companyDrawerData?.dateOfBirth,
    email: companyDrawerData?.email,
    telephoneMobile: companyDrawerData?.telephoneMobile,
    individualId: companyDrawerData?.individualId,
    streetAddressUnitNumber: companyDrawerData?.streetAddressUnitNumber,
  })

  useEffect(() => {
    setState({
      ...state,
      individualId: companyDrawerData?.individualId,
    })
  }, [companyDrawerData])
  
  if (companyDrawerData == null) {
    return <div></div>
  }

  const onTextboxChange = (event, state) => {
    // console.log(event.target)
    // Have to use event persist to stable https://reactjs.org/docs/events.html#event-pooling
    event.persist()

    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value
      }
    })
  }
  const saveRecord = (state) => {
    delete state.enableEdited
    console.log('saving states', state)
    try {
      API.put('client-company', `/update-officeholders/${companyDrawerData?.clientId}/${companyDrawerData?.id}`, {
        body: {
          clientsOfficeholders: {
            id: companyDrawerData?.id,
            fileBy: `${state?.lastName ? state.lastName : companyDrawerData?.lastName}, ${state?.firstName ? state.firstName : companyDrawerData?.firstName}`,
            officePosition: state?.officePosition,
            individualId: state?.individualId,
            email: state?.email
          },
          contactIndividuals: {
            fileBy: `${state?.lastName ? state.lastName : companyDrawerData?.lastName}, ${state?.firstName ? state.firstName : companyDrawerData?.firstName}`,
            firstName: state?.firstName,
            middleNames: state?.middleNames,
            lastName: state?.lastName,
            dateOfBirth: state?.dateOfBirth,
            telephoneMobile: state?.telephoneMobile,
            address: state?.address,
            streetAddressUnitNumber: state?.streetAddressUnitNumber,
            streetAddressStreetNumber: state?.streetAddressStreetNumber,
            streetAddressStreetName: state?.streetAddressStreetName,
            streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
            streetAddressSuburb: state?.streetAddressSuburb,
            streetAddressState: state?.streetAddressState,
            streetAddressPostcode: state?.streetAddressPostcode,
            streetAddressCountryId: state?.streetAddressCountryId
          }
        }
      })

      notification.success({message: 'Saved !'})
      getData()
    } catch (error) {
      console.error('error occured', error)
      notification.error({message: error.message})
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  const createNewInfoReq = () => {
    setButtonLoading(true)
    API.post('information-requests', '/create', {
      body: {
        clientId: companyDrawerData.clientId,
        accountId: currentAccountID,
        informationRequestId: 1,
        email: clientRecord.email,
        firstName: clientRecord.first_name,
        lastName: clientRecord.last_name,
        activateFinancial: true,
        activateEdd: true,
        thirdParty: true,
        recipientFirstName: companyDrawerData.firstName,
        recipientLastName: companyDrawerData.lastName,
        recipientEmail: companyDrawerData.email,
      }
    })
      .then(() => {
        setButtonLoading(false)
        history.push('/clients/information-requests/list')
        notification.success({message: 'New Record has been created !'})
      })
      .catch(error => {
        setButtonLoading(false)
        history.push('/clients/information-requests/list')
        notification.error({message: error.message})
      })
  }

  return (
    <Fragment>
      <Drawer
        title='Client Company Detail (Officeholders)'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!companyDrawerData.id}>
            <Form form={form} scrollToFirstError={true} layout='vertical'>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Form.Item label='Edit / View'>
                  <Switch
                    checkedChildren='Edit'
                    unCheckedChildren='View'
                    id='enableEdited'
                    checked={state.enableEdited == 1}
                    onChange={event => {
                      return setState(
                        {
                          ...state,
                          enableEdited: event,
                        }
                      )
                    }}
                  />
                </Form.Item>
                <Col xs={24}>
                  {companyDrawerData.id && !state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='Firstname'>
                          <strong>{companyDrawerData.firstName}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Middlename'>
                          <strong>{companyDrawerData.middleNames}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Lastname'>
                          <strong>{companyDrawerData.lastName}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Position'>
                          <strong>{companyDrawerData.officePosition}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Address'>
                          <strong>{companyDrawerData.address}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Date of Birth'>
                          <strong>{
                          companyDrawerData?.dateOfBirth
                            ? moment(companyDrawerData.dateOfBirth).format('YYYY/MM/DD')
                            : ''
                          }
                          </strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                          <strong>{companyDrawerData.email}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Telephone'>
                          <strong>{companyDrawerData.telephoneMobile}</strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <p> Address </p>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}>

                        <Descriptions.Item label='Unit Number'>
                          <strong>{companyDrawerData.streetAddressUnitNumber}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetNumber' key='Street Number' label='Street Number' shouldUpdate>
                          <strong>{companyDrawerData.streetAddressStreetNumber}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetName' key='Street name' label='Street name' shouldUpdate>
                          <strong>{companyDrawerData.streetAddressStreetName}</strong>
                        </Descriptions.Item>
  
                        <Descriptions.Item name='streetAddressStreetTypeId' key='Street Type' label='Street Type' shouldUpdate>
                          <strong>{companyDrawerData?.streetAddressStreetTypeId &&
                          streetCodes.streetType.find((element) => {
                            return element.in === companyDrawerData.streetAddressStreetTypeId
                          })?.fileBy
                          }</strong>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
                          <strong>{companyDrawerData.streetAddressSuburb}</strong>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressState' key='State' label='State' shouldUpdate>
                          <strong>{companyDrawerData.streetAddressState}</strong>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
                          <strong>{companyDrawerData.streetAddressPostcode}</strong>
                        </Descriptions.Item>
            
                        <Descriptions.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>
                          
                          <strong>{companyDrawerData?.streetAddressCountryId &&
                            countriesList.find((element) => {
                              return element.id === companyDrawerData.streetAddressCountryId
                            })?.full_name
                          }</strong>
 
                        </Descriptions.Item>
        
                      </Descriptions>
                      <Button
                        loading={buttonLoading}
                        onClick={createNewInfoReq}
                        disabled={
                          !companyDrawerData.firstName ||
                          !companyDrawerData.lastName ||
                          !companyDrawerData.email
                        }
                      > Create New Info Req </Button>
                    </>
                  )}
                  {companyDrawerData.id && state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='ID'>
                          <Input id='id' disabled value={state?.id ? state.id : companyDrawerData.id} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Firstname'>
                          <Input id='firstName' value={state?.firstName ? state.firstName : companyDrawerData.firstName} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Middlename'>
                          <Input id='middleNames' value={state?.middleNames ? state.middleNames : companyDrawerData.middleNames} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Lastname'>
                          <Input id='lastName' value={state?.lastName ? state.lastName : companyDrawerData.lastName} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Position'>
                          <Input id='officePosition' value={state?.officePosition ? state.officePosition : companyDrawerData.officePosition} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Address'>
                          <Input id='address' value={state?.address ? state.address : companyDrawerData.address} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Date Of Birth'>
                          <Input id='dateOfBirth'
                            placeholder='YYYY-MM-DD'
                            defaultValue={companyDrawerData?.dateOfBirth
                              ? moment(companyDrawerData.dateOfBirth).format('YYYY-MM-DD')
                              : ''}
                            onChange={(event, state) => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                          <Input id='email' value={state?.email ? state.email : companyDrawerData.email} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Telephone'>
                          <Input id='telephoneMobile' value={state?.telephoneMobile ? state.telephoneMobile : companyDrawerData.telephoneMobile} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                      </Descriptions>
                      <p> Address </p>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}>

                        <Descriptions.Item label='Unit Number'>
                          <Input id='streetAddressUnitNumber' value={state?.streetAddressUnitNumber ? state.streetAddressUnitNumber : companyDrawerData.streetAddressUnitNumber} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetNumber' key='Street Number' label='Street Number' shouldUpdate>
                          <Input id='streetAddressStreetNumber' value={state?.streetAddressStreetNumber ? state.streetAddressStreetNumber : companyDrawerData.streetAddressStreetNumber} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetName' key='Street name' label='Street name' shouldUpdate>
                          <Input id='streetAddressStreetName' value={state?.streetAddressStreetName ? state.streetAddressStreetName : companyDrawerData.streetAddressStreetName} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
  
                        <Descriptions.Item name='streetAddressStreetTypeId' key='Street Type' label='Street Type' shouldUpdate>
                          <Select
                            showSearch
                            value={state?.streetAddressStreetTypeId ? state.streetAddressStreetTypeId : companyDrawerData.streetAddressStreetTypeId}
                            showArrow
                            placeholder='Select Street'
                            optionFilterProp='children'
                            onChange={(value) => {
                              form.setFieldsValue({ 'streetAddressStreetTypeId': value })
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  'streetAddressStreetTypeId': value
                                }
                              })
                            } }
                          >
                            {
                              streetCodes.streetType &&
                          streetCodes.streetType.map(x => (
                            <Option key={x.id} value={x.id}>
                              {x.fileBy}
                            </Option>
                          ))
                            }
                          </Select>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
                          <Input id='streetAddressSuburb' value={state?.streetAddressSuburb ? state.streetAddressSuburb : companyDrawerData.streetAddressSuburb} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressState' key='State' label='State' shouldUpdate>
                          <Input id='streetAddressState' value={state?.streetAddressState ? state.streetAddressState : companyDrawerData.streetAddressState} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
                          <Input id='streetAddressPostcode' value={state?.streetAddressPostcode ? state.streetAddressPostcode : companyDrawerData.streetAddressPostcode} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
            
                        <Descriptions.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>
                          <Select
                            showSearch
                            value={state?.streetAddressCountryId ? state.streetAddressCountryId : companyDrawerData.streetAddressCountryId}
                            showArrow
                            placeholder='Select Country'
                            optionFilterProp='children'
                            onChange={(value) => {
                              form.setFieldsValue({ 'streetAddressCountryId': value })
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  'streetAddressCountryId': value
                                }
                              })
                            } }
                          >
                            {
                              countriesList.map(({ id, full_name }) => (
                                <Option key={id} value={id}>
                                  {full_name}
                                </Option>
                              ))
                            }
                          </Select>
                        </Descriptions.Item>
        
                      </Descriptions>

                      <Button onClick={ ()=> saveRecord(state)}> Save </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
})