import React, {
  useEffect,
  useState
} from 'react'

import {API} from 'utils'

import {columnSize} from '../common'

import {
  Row,
  Col,
  Form,
  Spin,
  Descriptions,
} from 'antd'

import moment from 'moment'

const DocumentDigisignTemplatesSummary = ({match: {params: {templateId}},}) => {
  const [form] = Form.useForm()
  const [state, setState] = useState({
    loading: true,
    documentRecord: null,
  })

  const asyncFunctions = async () => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true
      }))
      const url = await API.get('digidoc', `/templates/id/${templateId}`)
      const details = await API.get('digidoc', `/templates/query/${templateId}`)
      setState({
        ...state,
        documentRecord: url.payload,
        documentDetail: details.payload,
        loading: false
      })
    } catch (error) {
      console.log('err', error)

      setState(prevState => ({
        ...prevState,
        loading: false
      }))
    }
  }

  useEffect(() => {
    asyncFunctions()
  }, [])

  return (
    <Spin spinning={state.loading}>
      <Form form={form} layout='vertical'>
        <Row gutter={[48, 0]}>
          <Col {...columnSize}>
            <Descriptions
              bordered
              layout='vertical'
              size='small'
              style={{marginBottom: 24}}
              column={{
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2
              }}
            >
              <Descriptions.Item label='ID'>
                <strong>{state.documentRecord?.id}</strong>
              </Descriptions.Item>
              <Descriptions.Item label='Template Name'>
                <strong>{state.documentRecord?.templateName}</strong>
              </Descriptions.Item>
              <Descriptions.Item label='eSignatures ID'>
                <strong>{state.documentRecord?.eSignaturesId}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col {...columnSize}>
            <Descriptions
              bordered
              layout='vertical'
              size='small'
              style={{marginBottom: 24}}
              column={{
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2
              }}
            >
              <Descriptions.Item label='ID'>
                <strong>{state.documentDetail?.template_id}</strong>
              </Descriptions.Item>
              <Descriptions.Item label='Template Name'>
                <strong>{state.documentDetail?.template_name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label='Created At'>
                <strong>{state.documentDetail?.created_at ? moment(state.documentDetail?.created_at).format('DD/MM/YYYY HH:mm:ss') : ''}</strong>
              </Descriptions.Item>
              <Descriptions.Item label='Placeholder Fields'>
                <strong>{String(state.documentDetail?.placeholder_fields)}</strong>
              </Descriptions.Item>
              <Descriptions.Item label='Signer Fields'>
                <strong>{state.documentDetail?.signer_fields}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

export default DocumentDigisignTemplatesSummary
