import API from '@aws-amplify/api'
import {
  Col,
  Row,
  Skeleton,
  Descriptions
} from 'antd'
import React, {
  useEffect,
  Fragment,
  useState
} from 'react'
import {useSelector} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'
import {createUseStyles} from 'react-jss'
import moment from 'moment'

const useStyles = createUseStyles({
  contentViewer: {
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis'
  },
})

export const JournalViewPageComponent = ({
  match: {params: {journalId}},
  ...rest
}) => {
  const classes = useStyles()
  const currentAccountID = useSelector(state => appStateSelectors.stateSelector(state).currentAccountRecord.id)
  const [state, setState] = useState({
    loading: true,
    journalDetail: {
      adminJournalType: '',
      clientId: null,
      id: null,
      journalTypeId: null,
      ipAddress: null,
      lead: null,
      leadId: null,
      memo: '',
      recordCreated: null,
      staff: null,
      staffId: null,
      title: ''
    },
    clients: [],
    leads: [],
    help: '',
    error: 'success',
    htmlContent: ''
  })

  useEffect(() => {
    getClientsAndleads()
  }, [currentAccountID])

  const getClientsAndleads = async () => {
    try {
      const [clients, leads, journal] = await Promise.all([
        API.get('clients', `/getSimpleListByAccountId/${currentAccountID}`),
        API.get('leads', `/getSimpleListByAccountId/${currentAccountID}`),
        API.get('journals', `/byJournalId/${journalId}`)
      ])

      const clientInfo = await API.get('clients', `/get-by-id/${journal.payload.clientId}`)
  
      setState({
        ...state,
        leads: leads.payload,
        clients: clients.payload,
        loading: false,
        journalDetail: journal.payload,
        clientDetail: clientInfo.payload,
        htmlContent: journal.payload.memo
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  if (state.loading) {
    return <Skeleton />
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Journal Type" span={2}>{state.journalDetail.adminJournalType}</Descriptions.Item>
            <Descriptions.Item label="IP Address" span={2}>{state.journalDetail.ipAddress}</Descriptions.Item>
            <Descriptions.Item label="Created" span={2}>{moment(state.journalDetail.recordCreated).format('DD/MM/YYYY - HH:mm:ss')}</Descriptions.Item>
            <Descriptions.Item label="Client" span={2}>{state.journalDetail.clientId}</Descriptions.Item>
            <Descriptions.Item label="Client Name" span={2}>{`${state.clientDetail.first_name} ${state.clientDetail.last_name}`}</Descriptions.Item>
            <Descriptions.Item label="Journal Title" span={5}>{state.journalDetail.title}</Descriptions.Item>
            <Descriptions.Item label="Journal Memo"><div className={classes.contentViewer} dangerouslySetInnerHTML={{__html: state.htmlContent}} /></Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Fragment>
  )
}