import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'

import {
  API,
  Storage
} from 'aws-amplify'

import {openNotificationWithIcon} from '../../utils'

import * as types from './types'

function* getInformationRequests(action) {
  const accountId = action.payload
  try {
    const {payload} = yield call([API, API.get], 'information-requests', `/list/${accountId}`)
    yield put({
      type: types.GET_INFORMATION_REQUESTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_INFORMATION_REQUESTS_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_INFORMATION_REQUESTS_REQUEST, getInformationRequests),
  ])
}