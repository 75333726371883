import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import {showNotification} from 'utils'

import generator from 'generate-password'

import {create_client_cognito_account} from 'library/clients'

import * as types from './types'

import {getDocument} from '../documents/sagas'

function* fetchClients() {
  try {
    const {payload} = yield call([API, API.get], 'opportunities', '/list')
    yield put({
      type: types.FETCH_CLIENTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_CLIENTS_ERROR})
  }
}

function* getClient(action) {
  try {
    const {payload: aws_cognito_id} = action
    const {payload: client} = yield call([API, API.get], 'opportunities', `/get-by-id/${aws_cognito_id}`)
    yield put({
      type: types.GET_CLIENT_SUCCESS,
      payload: client
    })
  } catch (e) {
    showNotification('error', 'Error', e.message)
    yield put({type: types.GET_CLIENT_ERROR})
  }
}

function* financialAccount(action) {
  try {
    const {payload: id} = action
    const {payload: client} = yield call([API, API.get], 'financial-accounts', `/list/${id}`)
    yield put({
      type: types.FINANCIAL_ACCOUNT_SEND_SUCCESS,
      payload: client
    })
  } catch (e) {
    showNotification('error', 'Error', e.message)
    yield put({type: types.FINANCIAL_ACCOUNT_SEND_ERROR})
  }
}

function* saveClientRecord(action) {
  try {
    const {payload: client} = action
    showNotification('info', 'In Process', 'Saving information...')

    yield call([API, API.put], 'clients', `/update/${client.id}`, {body: {...client}})

    showNotification('success', 'Success', 'Successfully saved.')
    yield put({type: types.SAVE_CLIENT_SUCCESS})
  } catch (e) {
    showNotification('error', 'Error', 'Failed to save')
    yield put({type: types.SAVE_CLIENT_ERROR})
  }
}

function* createClient(action) {
  try {
    const {
      payload: {
        newClient, accountID, history
      }
    } = action
    showNotification('info', 'In Process', 'Creating new client in database.')
    const {payload} = yield call([API, API.post], 'clients', '/create', {
      body: {
        ...newClient,
        file_by: `${newClient.last_name}, ${newClient.first_name}`,
        account_id: accountID
      }
    })
    showNotification('success', 'Record Created', 'Creating portal login for new client member.')
    const password = generator.generate({
      length: 10,
      numbers: true,
      lowercase: true,
      uppercase: true,
      strict: true
    })
    create_client_cognito_account(payload.insertId, newClient.email, password)
    showNotification('success', 'Record Created', 'New client has been successfully created.')
    yield put({type: types.CREATE_CLIENT_SUCCESS})
    history.push('/clients/list')
  } catch (e) {
    showNotification('error', 'Error', e.message)
    yield put({type: types.CREATE_CLIENT_ERROR})
  }
}

function* getDocuments(action) {
  try {
    const {payload: clientID} = action
    const {payload: {document_list}} = yield call([API, API.get], 'documents', `/list/client/${clientID}`)

    yield put({
      type: types.GET_DOCUMENTS_SUCCESS,
      payload: document_list
    })
  } catch (e) {
    showNotification('error', 'Error', e.message)
  }
}

function* watchGetDocumentAction(action) {
  try {
    const {payload: itemID} = action
    const result = yield call(getDocument, itemID)
    yield put({
      type: types.GET_DOCUMENT_SUCCESS,
      payload: result
    })
  } catch (e) {
    yield put({type: types.GET_DOCUMENT_ERROR})
  }
}

function* createNewDocument(action) {
  try {
    const {
      payload: {
        description, long_description, fileIDs, currentAccountID, document_type_id, client_id, successCb
      }
    } = action

    const {payload: {document_id}} = yield call([API, API.post], 'documents', '/create', {
      body: {
        client_id,
        description,
        long_description,
        document_type_id,
        file_ids: fileIDs,
        account_id: currentAccountID
      }
    })

    showNotification('success', 'Record Created', 'Document record has been successfully created.')

    successCb()

    yield put({
      type: types.CREATE_NEW_DOCUMENT_SUCCESS,
      payload: {
        document_type_id,
        description,
        id: document_id
      }
    })

  } catch (e) {
    showNotification('error', 'Save Failed', e.message)
    yield put({type: types.CREATE_NEW_DOCUMENT_ERROR})
  }
}

function* checkClientsEmail(action) {
  try {
    const {payload: {email, cb}} = action
    const {payload} = yield call([API, API.post], 'clients', '/check-email', {body: email})
    if (!payload) {
      yield put({type: types.CHECK_CLIENTS_EMAIL_ERROR})
    } else {
      yield put({type: types.CHECK_CLIENTS_EMAIL_SUCCESS})
      yield call(cb)
    }
  } catch (e) {
    showNotification('error', 'Request Failed', e.message)
  }
}

function* getVerificationList(action) {
  try {
    const {payload: clientID} = action
    const {payload} = yield call([API, API.get], 'verifications', `/get/client/${clientID}`)
    yield put({
      type: types.GET_VERIFICATIONS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    showNotification('error', 'Request Failed', e.message)
    yield put({type: types.GET_VERIFICATIONS_LIST_ERROR})
  }
}

function* createDigiDoc(action) {
  try {
    const {payload} = action
    const {payload: client} = yield call([API, API.post], 'digidoc', '/create', {body: payload})

    yield put({
      type: types.DIGIDOC_CREATE_SUCCESS,
      payload: client
    })
    showNotification('success', 'Document Created Successfully')
  } catch (e) {
    showNotification('error', 'Request Failed', e.message)
    yield put({type: types.DIGIDOC_CREATE_ERROR})
  }
}

function* sendDigiDocEmail(action) {
  try {
    const {payload} = action
    const data = yield call([API, API.post], 'email', '/client/send-digidoc-url', {body: payload})
    yield put({
      type: types.DIGIDOC_SEND_SUCCESS,
      data
    })
    showNotification('success', 'Email sent Successsfully')
  } catch (e) {
    showNotification('error', 'Request Failed', e.message)
    yield put({type: types.DIGIDOC_SEND_ERROR})
  }
}

function* getJournalsListByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'journals', `/byId/${action.payload}`)
    yield put({
      type: types.GET_JOURNAL_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_JOURNAL_BY_CLIENT_ERROR})
    showNotification('error', 'Failed to get journal list', e.message)
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_JOURNAL_BY_CLIENT_REQUEST, getJournalsListByClientId),
    yield takeEvery(types.DIGIDOC_CREATE_REQUEST, createDigiDoc),
    yield takeEvery(types.FINANCIAL_ACCOUNT_SEND_REQUEST, financialAccount),
    yield takeEvery(types.DIGIDOC_SEND_REQUEST, sendDigiDocEmail),
    yield takeEvery(types.FETCH_CLIENTS_REQUEST, fetchClients),
    yield takeEvery(types.CREATE_CLIENT_REQUEST, createClient),
    yield takeEvery(types.GET_CLIENT_REQUEST, getClient),
    yield takeEvery(types.SAVE_CLIENT_REQUEST, saveClientRecord),
    yield takeEvery(types.CHECK_CLIENTS_EMAIL_REQUEST, checkClientsEmail),
    yield takeEvery(types.GET_DOCUMENTS_REQUEST, getDocuments),
    yield takeEvery(types.GET_DOCUMENT_REQUEST, watchGetDocumentAction),
    yield takeEvery(types.CREATE_NEW_DOCUMENT_REQUEST, createNewDocument),
    yield takeEvery(types.GET_VERIFICATIONS_LIST_REQUEST, getVerificationList)
  ])
}