import * as types from './types'

/**
 * @param {number} accountId
 * @returns {{payload: *, type: string}}
 */
export const fetchChecklistCollections = accountId => ({
  type: types.FETCH_CHECKLISTCOLLECTIONS_REQUEST,
  payload: accountId
})
