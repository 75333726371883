import React from 'react';
import {Row, Col, Input, Form, Divider, InputDatePicker, Descriptions} from 'components';

import {columnSize} from '../../../common';
import moment from 'moment';
import {label} from 'aws-amplify';

const eApplicationFormAgreement = ({clientRecord}) => {

  const agreementDatetime = clientRecord?.eafApplication_datetime
    ? moment(clientRecord?.eafApplication_datetime).format('ddd DD MMM YYYY hh:mm:ss a')
    : 'N/A'

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col>
          <h3>eApplicationForm Online Application</h3>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Descriptions title='Application Details' column={{lg: 3, sm: 1}} layout='vertical' bordered size='small'>
            <Descriptions.Item label='EAF ID'>{clientRecord?.eafApplication_id}</Descriptions.Item>
            <Descriptions.Item label='Submission DateTime' span={2}>
              {agreementDatetime}
            </Descriptions.Item>
            <Descriptions.Item label='Applicant Name'>
              {clientRecord?.first_name} {clientRecord?.last_name}
            </Descriptions.Item>
            <Descriptions.Item label='Applicant Email'>
              {clientRecord?.email}
            </Descriptions.Item>
            <Descriptions.Item label='Applicant IP'>
              {clientRecord?.eafApplication_ip}
            </Descriptions.Item>
            <Descriptions.Item label='eApplicationForm Link'>
              <a
                href={`https://admin.eapplicationform.com/applications/${clientRecord?.eafApplication_id}`}
                target='_blank'
                rel='noreferrer'
              >
                {`https://admin.eapplicationform.com/applications/${clientRecord?.eafApplication_id}`}
              </a>
            </Descriptions.Item>
          </Descriptions>
          <Divider/>
          <Descriptions title='AML Checks' column={{lg: 3, sm: 1}} layout='vertical' bordered size='small'>
            <Descriptions.Item label='EAF ID'>{clientRecord?.eafApplication_id}</Descriptions.Item>
            <Descriptions.Item label='Initial Check Datetime' span={3}>
              {clientRecord?.eafApplication_initialCheckDateTime}
            </Descriptions.Item>
            <Descriptions.Item label='Initial AML Check' span={3}>
              <a
                href={clientRecord?.eafApplication_initialCheckUrl}
                target='_blank'
                rel='noreferrer'
              >
                {clientRecord?.eafApplication_initialCheckUrl}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label='Initial Monitoring' span={3}>
              <a
                href={clientRecord?.eafApplication_monitoringUrl}
                target='_blank'
                rel='noreferrer'
              >
                {clientRecord?.eafApplication_monitoringUrl}
              </a>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions title='Legal Agreements' column={{lg: 3, sm: 1}} layout='vertical' bordered size='small'>
            <Descriptions.Item label='Electronic Agreements'>
            <strong>Financial Services Guide</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Client Agreement</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Product Disclosure Statement</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Risk Disclosure</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>TMD Commodity CFDs</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>TMD Cryptocurrency CFDs</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>TMD Foreign Exchange Contract CFDs</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>TMD Indices CFDs</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>TMD Shares and ETFs CFDs</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Privacy Policy</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Complaints Policy</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Cookie Policy</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Hedging Policy</strong> - electronic agreement: {agreementDatetime}<br/>
            <strong>Website Terms and Conditions</strong> - electronic agreement: {agreementDatetime}<br/>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

    </>
  );
};
export default eApplicationFormAgreement;
