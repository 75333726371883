import * as types from './types'

/**
 * @param {number} payload
 * @returns {{payload: *, type: string}}
 */

export const fetchClientsV2 = payload => (
  {
    type: types.FETCH_TCCLIENTS_V2_REQUEST,
    payload
  }
)

export const fetchWallets = payload => (
  {
    type: types.FETCH_TCWALLETS_REQUEST,
    payload
  }
)

export const fetchGeneralWalletTransactions = payload => (
  {
    type: types.FETCH_TCWALLETS_TRANSACTIONS_REQUEST,
    payload
  }
)