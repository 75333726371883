import {API} from 'aws-amplify'
import {useSelector} from 'react-redux'
import {useAsync, useAsyncFn} from 'react-use'
import { notification } from 'antd'

export default function useClientWatching() {
  const staffId = useSelector(state => state.appState.currentStaffRecord.id)
  
  const [updateState, update] = useAsyncFn(async (clientId) => {
    try {
      if (staffId && clientId) {
        await API.post('watchlist-client', '/set', {
          body: {
            staffId,
            clientId
          }
        })
      }
    } catch (error) {
      notification.error({message: error.message})
    }
  })

  const state = useAsync(async () => {
    if (staffId) {
      const response = await API.get('watchlist-client', `/get-staff/${staffId}`)
      return response?.payload
    }
    return null
  }, [staffId, updateState])

  return {
    state,
    updateState,
    update
  }
}
