import React, {Component} from 'react'
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Radio,
  Modal,
  Form,
  notification,
  Tag,
  InputDatePicker,
  InputSelect,
  FormInputNumber,
  NavLink
} from 'components'

import {API} from 'utils'
import {routes} from 'configs'
import moment from 'moment'
import {connect} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'
import Highlighter from 'react-highlight-words'
import {
  SearchOutlined,
  SyncOutlined
} from '@ant-design/icons'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class TradingTransactionsListPageComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      filterStatus: [],
      filterPlatform: [],
      filterCurrencies: [],
      tableLoading: true,
      showNewDepositModal: false,
      showNewWithdrawalModal: false,
      modalLoading: false,
      tradingAccountsByAccount: [],
      debit: 0,
      credit: 0,
      transactionDate: '1970-01-01',
      radioValue: 'all',
      client_nickname: '',
      client_email: '',
      trading_platform_file_by: '',
      tradingPlatformUsername: ''
    }

    API.get('trading-transactions-2', '/list-status')
      .then(response => {
        const dataSource = []
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            statusName: response.payload[i].statusName,
          })
        }
        this.setState({filterStatus: dataSource})
      })
      .catch(error => {
        console.log(error)
      })

    this.getTransactionAccountList()
  }

  componentDidMount() {
    this.loadData()
  }

  getTransactionAccountList = async() => {
    await API.get('trading-transactions-2', `/get-ta-list/account/${this.props.currentAccountID}`).then(
      response => {
        this.setState({
          ...this.state,
          tradingAccountsByAccount: response.payload
        })
      }
    )
  }

  onCloseNewDepositModal = () => {
    this.setState({
      showNewDepositModal: false,
      client_nickname: '',
      client_email: '',
      trading_platform_file_by: '',
      tradingPlatformUsername: ''
    })
  }
  onCloseNewWithdrawalModal = () => {
    this.setState({
      showNewWithdrawalModal: false,
      client_nickname: '',
      client_email: '',
      trading_platform_file_by: '',
      tradingPlatformUsername: ''
    })
  }
  onOpenNewDepositModal = async () => {
    await this.getTransactionAccountList()
    this.setState({showNewDepositModal: true})
  }
  onOpenNewWithdrawalModal = async () => {
    await this.getTransactionAccountList()
    this.setState({showNewWithdrawalModal: true})
  }

  saveNewTradingTransactionRecord = async (values) => {

    await API.post('trading-transactions-2', '/create', {
      body: {
        tradingAccountID: values.tradingAccountID,
        debit: values.debit ? Number(values.debit) : null,
        credit: values.credit ? Number(values.credit) : null,
        statusID: 1,
        transactionDate: values.transactionDate
      }
    })
      .then(database_result => {
        openNotificationWithIcon('success', 'Record Created', 'New Record has been successfully created.')

        this.props.history.push(routes.tradingTransactions2)
      })
      .then(database_result => {
      })
      .catch(error => {
        console.log(error)
      })
  };

  onFinish = async values => {
    await this.saveNewTradingTransactionRecord(values)
    this.setState({showNewDepositModal: false})
    this.setState({showNewWithdrawalModal: false})
    this.loadData()
  }

  onHandleChange = value => {
    this.setState({'debit': value})
  }

  loadData = async () => {
    this.setState({tableLoading: true})

    await API.get('trading-transactions-2', `/list/${this.props.currentAccountID}`)
      .then(response => {
        const dataSource = []
        for (let i = 0; i < response.payload.length; i++) {
          const item = response.payload[i]
          dataSource.push({
            key: item.id,
            id: item.id,
            // recordCreated: item.recordCreated,
            transactionDate: item.transactionDate,
            client: item.client,
            tradingPlatformAccount: item.tradingPlatformAccount,
            tradingPlatformAccountID: item.tradingPlatformAccountID,
            tradingAccountID: item.tradingAccountID,
            tradingPlatformUsername: item.tradingPlatformUsername,
            statusID: item.statusID,
            statusName: item.statusName,
            currency: item.currency,
            debit: item.debit ? item.debit.toFixed(2) : '',
            credit: item.credit ? item.credit.toFixed(2) : '',
          })
        }

        const filterPlatform = []
        for (let i = 0; i < dataSource.length; i++) {
          const item = dataSource[i]
          if (filterPlatform.find(
            element => element.id === item.tradingPlatformAccountID) === undefined
          ) {
            filterPlatform.push({
              id: item.tradingPlatformAccountID,
              value: item.tradingPlatformAccount
            })
          }
        }

        const filterCurrencies = []
        for (let i = 0; i < dataSource.length; i++) {
          const item = dataSource[i]
          if (filterCurrencies.find(
            element => element.id === item.currency) === undefined
          ) {
            filterCurrencies.push({
              id: item.currency,
              value: item.currency
            })
          }
        }

        this.setState({
          dataSource: dataSource,
          tableLoading: false,
          filterPlatform: filterPlatform,
          filterCurrencies: filterCurrencies
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={dataIndex === 'id' ? 'Search ID' : 'Search Name'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };

  handleReset = clearFilters => {
    clearFilters()
    this.setState({searchText: ''})
  };

  onRadioGroupChange = async (event) => {
    this.setState({radioValue: event.target.value})
    let dataSourceAll = []
    let dataSourceDebit = []
    let dataSourceCredit = []
    switch (event.target.value) {
      case 'all':
        await this.loadData()
        dataSourceAll = this.state.dataSource.filter(function(value) {
          return value.credit > 0 || value.debit > 0
        })
        this.setState({dataSource: dataSourceAll})
        break
      case 'debit':
        await this.loadData()
        dataSourceDebit = this.state.dataSource.filter(function(value) {
          return value?.debit > 0
        })
        this.setState({dataSource: dataSourceDebit})
        break
      case 'credit':
        await this.loadData()
        dataSourceCredit = this.state.dataSource.filter(function(value) {
          return value?.credit > 0
        })
        this.setState({dataSource: dataSourceCredit})
        break
      default:
        console.log('all')
        break
    }
  }

  render() {

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        render: (id, record) =>
          <NavLink to={routes.tradingTransactions2View(id)}>{record.id}</NavLink>
      },
      {
        title: 'Date',
        dataIndex: 'transactionDate',
        sorter: (a, b) => moment.utc(a.transactionDate).diff(moment.utc(b.transactionDate)),
        defaultSortOrder: 'descend',
        render: (text, record) => (
            record?.transactionDate
              ? moment(record.transactionDate).format('YYYY/MM/DD')
              : ''
        )
      },
      {
        title: 'Client',
        dataIndex: 'Client',
        sorter: (a, b) => a.id - b.id,
        // defaultSortOrder: 'descend',
        render: (id, record) => {
          return record.client
        }
      },
      {
        title: 'Platform',
        dataIndex: 'tradingPlatformAccountID',
        sorter: (a, b) => a.tradingPlatformAccount.localeCompare(b.tradingPlatformAccount),
        filters: this.state.filterPlatform.map(ele => {
          return {
            text: ele.value,
            value: ele.id,
          }
        }),
        onFilter: (value, record) => record.tradingPlatformAccountID === value,
        render: (id, record) => {
          return <div>
            {record.tradingPlatformAccount}
          </div>
        }
      },
      {
        title: 'GAT Trader Username',
        dataIndex: 'tradingAccountID',
        sorter: (a, b) => a.tradingPlatformUsername.localeCompare(b.tradingAccountID),
        ...this.getColumnSearchProps('tradingAccountID'),
        render: (id, record) => {
          return <div>
            <NavLink to={`/trading/accounts/${record.tradingAccountID}`} target="_blank">
              {record.tradingPlatformUsername}
            </NavLink>
          </div>
        }
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        sorter: (a, b) => a.currency - b.currency,
        filters: this.state.filterCurrencies.map(ele => {
          return {
            text: ele.value,
            value: ele.id,
          }
        }),
        onFilter: (value, record) => record.currency === value,
      }]

    if (this.state?.radioValue === 'debit' || this.state?.radioValue === 'all') {
      this.columns.push({
        title: 'Deposit',
        dataIndex: 'debit',
      })
    }

    if (this.state?.radioValue === 'credit' || this.state?.radioValue === 'all') {
      this.columns.push({
        title: 'Withdrawal',
        dataIndex: 'credit',
      })
    }

    this.columns.push({
      title: 'Status',
      dataIndex: 'statusName',
      filters: this.state.filterStatus.map(ele => {
        return {
          text: ele.statusName,
          value: ele.id,
        }
      }),
      onFilter: (value, record) => record.statusID === value,
      render: data_item => {
        switch (data_item) {
          case 'Draft':
            return (
              <Tag color={'gray'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                Draft
              </Tag>
            )
          case 'Pending':
            return (
              <Tag color={'red'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                Pending
              </Tag>
            )
          case 'Pending Reject':
            return (
              <Tag color={'red'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                  Pending Reject
              </Tag>
            )
          case 'Pending Approve':
            return (
              <Tag color={'red'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                  Pending Approve
              </Tag>
            )
          case 'Approved':
            return (
              <Tag color={'lime'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                Approved
              </Tag>
            )
          case 'Rejected':
            return (
              <Tag color={'geekblue'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                Rejected
              </Tag>
            )
          case 'Cancelled':
            return (
              <Tag color={'grey'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                Cancelled
              </Tag>
            )
          default:
            return (
              <Tag color={'red'} style={{
                width: 150,
                textAlign: 'center'
              }} key={data_item}>
                Error
              </Tag>
            )
        }
      }
    })

    // const tradingAccountsOptions = []
    // const tradingAccountsByAccount = this.state.tradingAccountsByAccount.sort(
    //   (a, b) => a.tradingPlatformUsername?.localeCompare(b.tradingPlatformUsername) || a.client_email < b.client_email)
    // for (let i = 0; i < tradingAccountsByAccount.length; i++) {
    //   const item = tradingAccountsByAccount[i]
    //   tradingAccountsOptions.push(
    //     {
    //       text: item.id,
    //       value: `${item.tradingPlatformUsername} - ${item.trading_platform_type_file_by} ${item.trading_platform_mode_file_by} - ${item.client_email} - ${item.client_nickname} `,
    //       client_nickname: item.client_nickname,
    //       client_email: item.client_email,
    //       trading_platform_mode_file_by: `${item.trading_platform_type_file_by} ${item.trading_platform_mode_file_by}`,
    //       tradingPlatformUsername: item.tradingPlatformUsername
    //     }
    //   )
    // }

    return (
      <React.Fragment>
        <Row gutter={[96, 24]}>
          <Col xs={24} style={{textAlign: 'right'}}>
            <Button onClick={this.onOpenNewDepositModal}>Create Deposit</Button>
            <Button onClick={this.onOpenNewWithdrawalModal}>Create Withdrawal</Button>
            <Button onClick={()=> [this.setState({radioValue: 'all'}), this.loadData()]} icon={<SyncOutlined />} />
          </Col>
        </Row>
        <Radio.Group onChange={this.onRadioGroupChange} value={this.state.radioValue || ''}>
          <Radio value='all'>All</Radio>
          <Radio value='debit'>Deposits</Radio>
          <Radio value='credit'>Withdrawals</Radio>
        </Radio.Group>
        <Table
          loading={this.state.tableLoading}
          columns={this.columns}
          dataSource={this.state.dataSource}
        />

        <Modal
          title='Create Deposit'
          open={this.state.showNewDepositModal}
          onCancel={this.onCloseNewDepositModal}
          width={600}
          footer={null}
        >
          <Form onFinish={this.onFinish}>
            <Form.Item hasFeedback name='tradingAccountID'>
              <InputSelect
                required
                label='Trading Account'
                name='tradingAccountID'
                placeholder='Select Trading Account'
                showSearch
                options={this.state.tradingAccountsByAccount}
                getOptionProps={opt => ({
                  value: opt.id,
                  children: `${opt.tradingPlatformUsername} - ${opt.trading_platform_type_file_by} ${opt.trading_platform_mode_file_by} - ${opt.client_email} - ${opt.client_nickname} `
                })}
              />
            </Form.Item>
            {this.state?.client_nickname && `Client Name: ${this.state.client_nickname}`}<br/>
            {this.state?.client_email && `Client Email: ${this.state.client_email}`}<br/>
            {this.state?.trading_platform_file_by && `Platform:  ${this.state.trading_platform_file_by}`}<br/>
            {this.state?.tradingPlatformUsername && `Username: ${this.state.tradingPlatformUsername }(eg LGR007890)`}
            <FormInputNumber
              required
              label='Amount'
              name='debit'
              precision={2}
            />
            <InputDatePicker
              required
              name='transactionDate'
              label='common.date'
            />
            <Button loading={this.state.modalLoading} type='primary' htmlType='submit'>
              Create
            </Button>
          </Form>
        </Modal>

        <Modal
          title='Create Withdrawal'
          open={this.state.showNewWithdrawalModal}
          onCancel={this.onCloseNewWithdrawalModal}
          footer={null}
          destroyOnClose
        >
          <Form
            preserve={false}
            onFinish={this.onFinish}
          >
            <Form.Item hasFeedback name='tradingAccountID'>
              <InputSelect
                required
                label='Trading Account'
                name='tradingAccountID'
                placeholder='Select Trading Account'
                showSearch
                options={this.state.tradingAccountsByAccount}
                getOptionProps={opt => ({
                  value: opt.id,
                  children: `${opt.tradingPlatformUsername} - ${opt.trading_platform_type_file_by} ${opt.trading_platform_mode_file_by} - ${opt.client_email} - ${opt.client_nickname} `
                })}
              />
            </Form.Item>
            {this.state?.client_nickname && `Client Name: ${this.state.client_nickname}`}<br/>
            {this.state?.client_email && `Client Email: ${this.state.client_email}`}<br/>
            {this.state?.trading_platform_file_by && `Platform:  ${this.state.trading_platform_file_by}`}<br/>
            {this.state?.tradingPlatformUsername && `Username: ${this.state.tradingPlatformUsername }(eg LGR007890)`}
            <FormInputNumber
              required
              label='Amount'
              name='credit'
              precision={2}
            />
            <InputDatePicker
              required
              name='transactionDate'
              label='common.date'
            />
            <Button loading={this.state.modalLoading} type='primary' htmlType='submit'>
              Create
            </Button>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  return {currentAccountID: currentAccountRecord.id}
}

export default connect(mapStateToProps, {})(TradingTransactionsListPageComponent)