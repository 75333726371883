import { API } from 'aws-amplify'
import * as Types from './types'

const typedAPI: Types.IAPI = {
  get: (apiName, path, init) => API.get(apiName, path, init || {}),
  post: (apiName, path, init) => API.post(apiName, path, init || {}),
  put: (apiName, path, init) => API.put(apiName, path, init || {}),
  patch: (apiName, path, init) => API.patch(apiName, path, init || {}),
  del: (apiName, path, init) => API.del(apiName, path, init || {}),
}

export default typedAPI
