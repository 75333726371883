import delios from './delioscrm-200x30.png'
import gatTrader from './gattrader-200x60.png'
import ctin from './ctincrm-200x30.png'
import gatMoney from './gatmoney-200x60.png'
import cobaltTrading from './cobalt_trading_200x60_white.png'
import finteksec from './finteksec_200x70.png'

export default {
  1: delios,
  2: gatTrader,
  5: ctin,
  6: gatMoney,
  7: cobaltTrading,
  8: gatTrader,
  9: finteksec
}