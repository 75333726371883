import React from 'react'

import {
  Row,
  Col,
  Form,
  Divider,
  ExtendedDescriptions,
  SubmitButton,
  Checkbox,
  Table,
} from 'components'
import {
  useSelector,
  useForm
} from 'hooks'
import {
  SectionTitle,
  InteractiveColumn
} from '../shared'
import {IndustrialClassification} from './components'
import {Types} from '../../duck'
import {
  ClientInformationRequest,
  SicDivision,
  SicGroup
} from 'types'
import {API} from 'utils'

const FORM_NAME = 'employment'

interface EmploymentProps extends Types.CommonProps {
  informationRequestRecord: ClientInformationRequest;
}

const Employment: React.FC<EmploymentProps> = ({
  informationRequestRecord,
  eApplicationFormData,
  clientRecord,
  updateClientRecord,
  submitting
}) => {
  const [form] = useForm()
  const [state, setState] = React.useState<{
    fetching: boolean;
    sicDivisions: SicDivision[];
    sicGroups: SicGroup[];
  }>({
    fetching: false,
    sicDivisions: [],
    sicGroups: []
  })
  const loading = submitting.includes(FORM_NAME)
  const financial = eApplicationFormData?.financial
  const {employmentStatusList} = useSelector(state => ({employmentStatusList: state.appState.employmentStatusList,}))

  React.useEffect(() => {
    setState(prevState => ({
      ...prevState,
      fetching: true
    }))

    Promise.all([
      API.get('clients', '/get-sic-divisions'),
      API.get('clients', '/get-sic-divisions-groups-list'),
    ])
      .then(([{payload: sicDivisions}, {payload: sicGroups}]) => {
        setState(prevState => ({
          ...prevState,
          sicGroups,
          sicDivisions,
          fetching: false
        }))
      })
      .catch(() => {
        setState(prevState => ({
          ...prevState,
          fetching: false
        }))
      })

  }, [])

  return (
    <Form
      name={FORM_NAME}
      form={form}
      layout='vertical'
      initialValues={{
        ...eApplicationFormData,
        ...(financial || {})
      }}
      onValuesChange={async (values) => {
        try {
          const [key, value] = Object.entries(values)[0]

          if (['sicDivisionID', 'sicGroupID'].includes(key)) {
            updateClientRecord({[key]: value})
          }

          if (key !== 'sicDivisionID') {
            return
          }

          setState(prevState => ({
            ...prevState,
            fetching: true
          }))

          const division = state.sicDivisions.find(item => item.id === value)?.division

          const {payload} = await API.get('clients', `/get-sic-division-groups/${division}`)

          form.setFieldValue('sicGroupID', null)
          
          setState(prevState => ({
            ...prevState,
            sicGroups: payload,
            fetching: false
          }))
        } catch (e: any) {
          setState(prevState => ({
            ...prevState,
            fetching: false
          }))
        }
      }}
    >
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <SectionTitle>Employment</SectionTitle>
          {!informationRequestRecord?.thirdParty ?
            (
              <Table<Types.InteractiveTableColumn>
                pagination={false}
                dataSource={[
                  {
                    item: 'Occupation',
                    clientFieldName: 'occupation',
                    formFieldName: ['financial', 'occupation'],
                  },
                  {
                    item: 'Employment Status',
                    clientFieldName: 'employment_status_id',
                    formFieldName: ['financial', 'employmentStatusID'],
                  },
                  {
                    item: 'Employer',
                    clientFieldName: 'employer',
                    formFieldName: ['financial', 'employer'],

                  },
                  {
                    item: 'Annual Income',
                    clientFieldName: 'annual_income',
                    formFieldName: ['financial', 'annualIncome'],
                  },
                  {
                    item: 'Net Worth',
                    clientFieldName: 'net_worth',
                    formFieldName: ['financial', 'netWorth'],
                  },
                  {
                    item: 'US Citizen',
                    clientFieldName: 'us_citizen',
                    formFieldName: ['financial', 'usCitizen'],
                  },
                  {
                    item: 'US Tax Resident',
                    clientFieldName: 'us_tax_resident',
                    formFieldName: ['financial', 'usTaxResident'],
                  },
                ]}
                columns={[
                  {
                    title: 'Item',
                    dataIndex: 'item',
                  },
                  {
                    title: 'New Data (Info Request)',
                    dataIndex: 'clientFieldName',
                    render: (key, {clientFieldName, formFieldName}) => {
                      const props: Types.InteractiveColumnBasic = {
                        clientFieldName,
                        formFieldName,
                        updateClientRecord,
                        clientRecord,
                        loading
                      }

                      if (key === 'employment_status_id') {
                        return (
                          <InteractiveColumn
                            {...props}
                            inputType='select'
                            options={employmentStatusList}
                            getOptionProps={opt => ({
                              value: opt.id,
                              children: opt.file_by
                            })}
                          />
                        )
                      }

                      if (['us_tax_resident', 'us_citizen'].includes(clientFieldName)) {
                        return <InteractiveColumn {...props} inputType='group' />
                      }

                      return (
                        <InteractiveColumn {...props} />
                      )
                    }
                  },
                  {
                    title: 'Current Client',
                    dataIndex: 'clientFieldName',
                    render: (key, {clientFieldName}) => {
                      const value = clientRecord[clientFieldName]

                      if (key === 'employment_status_id') {
                        return employmentStatusList.find(e => e.id === clientRecord.employment_status_id)?.file_by
                      }

                      if (['us_citizen', 'us_tax_resident'].includes(key)) {
                        return <Checkbox checked={Boolean(value)} />
                      }

                      return value
                    }
                  },
                ]}
              />
            )
            : (
              <ExtendedDescriptions
                items={[
                  {
                    label: 'Occupation',
                    translate: false,
                    value: financial?.occupation
                  },
                  {
                    label: 'Employer',
                    translate: false,
                    value: financial?.employer
                  },
                  {
                    label: 'Net Worth',
                    translate: false,
                    value: financial?.netWorth
                  },
                  {
                    label: 'US Citizen',
                    translate: false,
                    value: <Checkbox checked={Boolean(financial?.usCitizen)} />
                  },
                  {
                    label: 'US Tax Resident',
                    translate: false,
                    value: <Checkbox checked={Boolean(financial?.usTaxResident)} />
                  },
                  {
                    label: 'Country',
                    translate: false,
                    value: eApplicationFormData.placeOfBirthCountry
                  },
                ]}
              />
            )}
        </Col>
      </Row>
      <IndustrialClassification
        {...state}
        sicDivisionID={clientRecord.sicDivisionID}
        sicGroupID={clientRecord.sicGroupID}
      />
      <SubmitButton loading={loading} />
      <Divider />
    </Form>
  )
}

export default Employment
