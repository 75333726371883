import React from 'react'
import {
  ExtendedDescriptions,
  Card,
  Button,
  ConfigProvider,
  Tooltip,
  PlusCircleOutlined,
  notification,
  Row
} from 'components'
import {FileRenderer} from '../shared'
import {COLORS} from 'consts'
import {operations} from '../../duck'
import {API} from 'utils'
import {routes} from 'configs'

interface EafDocument {
  contentType: string;
  url: string;
}

interface VerificationProps {
  client_id: number | string;
  informationRequestId: number | string;
  verification: {
    applicantId?: string;
    front?: EafDocument;
    back?: EafDocument;
    selfie?: EafDocument;
    sumSubFiles: EafDocument[];
  }
}

const Verification: React.FC<VerificationProps> = ({
  verification, client_id, informationRequestId
}) => {
  const [submitting, toggleSubmitting] = React.useState(false)

  const onClick = async () => {
    try {
      toggleSubmitting(true)

      const pendingPromises = ([
        verification?.front?.url,
        verification?.back?.url,
        verification?.selfie?.url,
      ]
        .filter(url => url) as string[]
      )
        .map(operations.createFileFromURL)

      const files = await Promise.all(pendingPromises)

      const {document} = await API.post('documents', '/create', {
        body: {
          applicantId: verification?.applicantId,
          client_id,
          description: `Identification from Information Request #${informationRequestId}`,
          document_type_id: 2,
          files,
        }
      })

      toggleSubmitting(false)

      const link = (
        <a href={routes.documentsView(document.id)} target='_blank' rel="noreferrer">
          View {document.id}
        </a>
      )

      notification.success({message: link})
    } catch (e: any) {
      toggleSubmitting(false)
      notification.error({message: e?.response?.data?.error || e.message})
    }
  }

  const items = [
    {
      label: 'Front',
      translate: false,
      copyable: false,
      value: verification?.front
    },
    {
      label: 'Back',
      translate: false,
      copyable: false,
      value: verification?.back
    },
    {
      label: 'Selfie',
      translate: false,
      copyable: false,
      value: verification?.selfie
    },
  ]
    .map(({value, ...item}) => {

      if (!value) {
        return {
          ...item,
          value: 'No file uploaded'
        }
      }

      return {
        ...item,
        value: <FileRenderer {...value} />
      }
    })
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: COLORS.orange[5],
            colorPrimaryHover: '#fff',
          },
        },
      }}
    >
      <Card
        title='Verification'
        style={{marginTop: 24}}
        extra={verification && (
          <Tooltip title='Create Document'>
            <Button
              icon={<PlusCircleOutlined />}
              loading={submitting}
              onClick={onClick}
            />
          </Tooltip>
        )}
      >
        <ExtendedDescriptions
          layout='horizontal'
          items={items}
        />
        <Row style={{marginTop: 24}}>
          <ExtendedDescriptions
            title='SumSub'
            translateTitle={false}
            layout='horizontal'
            items={verification?.sumSubFiles?.map(item => ({
              label: 'File',
              translate: false,
              copyable: false,
              value: <FileRenderer {...item} />
            }))}
          />
        </Row>
      </Card>
    </ConfigProvider>
  )
}

export default Verification
