import React, {
  Fragment,
  useEffect
} from 'react'
import {
  connect,
  useDispatch
} from 'react-redux'
import {
  Divider,
  Table,
  Popconfirm,
  Button
} from 'antd'
import {
  Link,
  NavLink
} from 'react-router-dom'
import {
  DeleteOutlined,
  EditOutlined,
  SyncOutlined
} from '@ant-design/icons'

import {listHOC} from 'common'
import {
  agentsActions,
  agentsSelectors
} from 'configs/ducks/agents/index'
import {appStateSelectors} from 'configs/ducks/appState'
// import { deleteTeam } from 'configs/ducks/teams/actions'
import moment from 'moment'

const AgentsListPageComponent = ({
  getColumnSearchProps, entities, loading, getTopics,
  currentAccountID, fetchCb
}) => {

  const dispatch = useDispatch()
  const columns = [
    {
      title: 'Agent Name',
      dataIndex: 'fileBy',
      ...getColumnSearchProps({
        dataIndex: 'fileBy',
        placeholder: 'Team'
      })
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps({
        dataIndex: 'email',
        placeholder: 'Email',
      })
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      ...getColumnSearchProps({
        dataIndex: 'firstName',
        placeholder: 'First Name',
      })
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      ...getColumnSearchProps({
        dataIndex: 'lastName',
        placeholder: 'Last Name',
      })
    },
    {
      title: 'Created',
      dataIndex: 'createdDatetime',
      ...getColumnSearchProps({
        dataIndex: 'createdDatetime',
        placeholder: 'Created ',
      }),
      render: (text, record) => (
        record?.createdDatetime
          ? moment(record.createdDatetime).format('YYYY/MM/DD HH:mm:ss')
          : ''
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <Fragment>
            <Link to={`/agents/view/${record.id}?tab=summary`}>
              <EditOutlined />
            </Link>
            {/*<Divider type='vertical'/>
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
        </Popconfirm>*/}
          </Fragment>
        )
      }
    }
  ]
  // const deleteRecord = async record => {
  //   dispatch(deleteTeam(record.id))
  // }

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={entities}
      loading={loading}
      scroll={{x: true}}
      pagination={{
        total: entities.length,
        showTotal: total => `Total ${total} records`,
        pageSizeOptions: ['20', '50', '100'],
        defaultPageSize: 20,
        showSizeChanger: true
      }}
    />
  )
}

const {fetchAgents} = agentsActions
const {agentsSelector} = agentsSelectors

const mapStateToProps = state => {
  return {
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    ...agentsSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: fetchAgents})(listHOC(AgentsListPageComponent))