import React, {
  useEffect,
  useState
} from 'react'
import {
  FormSelect,
  FileUpload,
  listHOC,
  inputTypes
} from 'common'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined
} from '@ant-design/icons'
import {connect} from 'react-redux'
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  Tag,
  InputDatePicker,
  Input,
  NavLink
} from 'components'

import {
  tradingActions,
  tradingSelectors
} from 'configs/ducks/trading'
import {appStateSelectors} from 'configs/ducks/appState'

import {getStatusOptions} from '../common'

export const tradingTransactionStatuses = {
  1: {
    text: 'Draft',
    color: 'default'
  },
  2: {
    text: 'Pending',
    color: 'processing'
  },
  3: {
    text: 'Pending Reject',
    color: 'warning'
  },
  4: {
    text: 'Pending Approve',
    color: 'warning'
  },
  5: {
    text: 'Rejected',
    color: 'error'
  },
  6: {
    text: 'Approved',
    color: 'success'
  },
  7: {
    text: 'Cancelled',
    color: 'default'
  },
}
const statusFilters = getStatusOptions(tradingTransactionStatuses)

const Filters = (
  {
    onChange,
    value
  }
) =>
  <Radio.Group onChange={onChange} value={value}>
    <Radio value='all'>All</Radio>
    <Radio value='debit'>Deposits</Radio>
    <Radio value='credit'>Withdrawals</Radio>
  </Radio.Group>

const TradingTransactionsModal = (
  {
    onClose,
    onSubmit,
    loading,
    disabled,
    visible,
    title,
    toggleDisabled,
    tradingAccounts,
    amountFormField,
    children
  }
) => {
  const [form] = Form.useForm()
  const closeModal = () => {
    form.resetFields(['teOperationId', 'trading_account_id', 'debit', 'credit', 'aws_file_id', 'transactionDate'])
    onClose()
  }
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={values => onSubmit({
          ...values,
          resetFields: closeModal
        })}
        onValuesChange={({aws_file_id}) => {
          if (aws_file_id) {
            const {status} = aws_file_id[0]
            toggleDisabled(status !== 'done')
          }
        }}
      >
        <FormSelect
          required
          label='Trading Account ID'
          name='trading_account_id'
          optionValue='id'
          placeholder='Select Trading Account ID'
          options={tradingAccounts}
          getOptionProps={(
            {
              id,
              client,
              platform,
              platform_id,
              currencies_iso_alpha_3,
              tradingPlatformUsername
            }
          ) => ({children: `${id} - ${platform} ${tradingPlatformUsername ? `[ ${tradingPlatformUsername} ]` : ''}  ${client}`})}
        />
        <Form.Item
          name={amountFormField}
          label='Amount'
          rules={[
            {
              required: true,
              message: 'This field is required!'
            },
            {
              validator(_, value) {
                if (typeof value === 'number' && value <= 0) {
                  return Promise.reject('Value must be greater than 0!')
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <InputNumber step={0.1} style={{ width: '100%' }}/>
        </Form.Item>

        <InputDatePicker
          required
          label='common.date'
          name='transactionDate'
        />
        <Form.Item
          label="TE Operation ID"
          name="teOperationId"
        >
          <Input />
        </Form.Item>
        {children}
        <Row justify='end'>
          <Space>
            <Button onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              disabled={disabled}
            >
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

const MODAL_CONSTANTS = {
  deposit: 'Deposit',
  withdrawal: 'Withdrawal',
}

function TradingTransactions(
  {
    getColumnSearchProps,
    getTopics = () => null,
    getTradingAccounts,
    fetchCb,
    createDepositWithdrawal,
    filterTradingTransactions,
    tradingTransactionsBtnValue,
    loading,
    list,
    tradingAccounts,
    accountID,
    currentAccountID
  }
) {
  const [state, setState] = useState({
    modal: '', // Deposit or Withdrawal
    disabled: false
  })
  const {
    modal,
    disabled
  } = state
  useEffect(() => {
    // if this component doesn't mount at TradingAccountsView
    if (!accountID) {
      getTradingAccounts(currentAccountID)
    }
    const openModalByKeyword = modal => setState({
      ...state,
      modal,
    })
    getTopics({
      extra: (
        <Space>
          <Button
            onClick={() => openModalByKeyword(MODAL_CONSTANTS.deposit)}
          >
            Create Deposit
          </Button>
          <Button
            onClick={() => openModalByKeyword(MODAL_CONSTANTS.withdrawal)}
          >
            Create Withdrawal
          </Button>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </Space>
      )
    })
  }, [])
  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (text, {
          id,
          credit,
          debit
        }) => {
          if (debit > 0) {
            return <NavLink to={`/trading/transactions/${id}/deposit`}>
              {text}
            </NavLink>
          }
          if (credit > 0) {
            return <NavLink to={`/trading/transactions/${id}/withdrawal`}>
              {text}
            </NavLink>
          }
          return id
        }
      })
    },
    {
      title: 'Reconciled',
      dataIndex: 'reconciledFlag',
      render: (value) => value ? <CheckCircleOutlined style={{ color: 'green' }}/> : <CloseCircleOutlined style={{ color: 'red'}} />
    },
    {
      title: 'Date',
      dataIndex: 'record_created',
      sorter: (a, b) => a.record_created - b.record_created,
      ...getColumnSearchProps({
        dataIndex: 'record_created',
        placeholder: 'Date',
        filterInputType: inputTypes.DATE_RANGE
      })
    },
    {
      title: 'Client',
      dataIndex: 'client',
      ...getColumnSearchProps({
        dataIndex: 'client',
        placeholder: 'Client',
        render: (text, {trading_account_client_id}) =>
          <>
            <NavLink to={`/clients/view/${trading_account_client_id}?tab=summary`}>
              [{trading_account_client_id}]
            </NavLink>
            &nbsp;
            {text}
          </>
      })
    },
    {
      title: 'Trading Account',
      dataIndex: 'tradingAccount',
      ...getColumnSearchProps({
        dataIndex: 'tradingAccount',
        placeholder: 'Trading Account',
        render: (text, {trading_account_id}) =>
          <>
            <NavLink to={`/trading/accounts/${trading_account_id}`}>
              [{trading_account_id}]
            </NavLink>
            &nbsp;
            {text}
          </>
      })
    },
    {
      title: 'Status',
      dataIndex: 'status_id',
      ...getColumnSearchProps({
        dataIndex: 'status_id',
        placeholder: 'Status',
        filterInputType: inputTypes.SELECT,
        selectFilterOptions: statusFilters,
        getOptionProps: ({text, color}) => ({children: <Tag color={color}>{text}</Tag>}),
        optionValue: 'id',
        render: (_, {status_id}) => {
          if (!status_id) {
            return null
          }
          const {
            text,
            color
          } = tradingTransactionStatuses[status_id]
          return (
            <Tag color={color}>
              {text}
            </Tag>
          )
        },
      })
    },
    {
      title: 'Te Option Id',
      dataIndex: 'teOperationId',
      ...getColumnSearchProps({
        dataIndex: 'teOperationId',
        placeholder: 'ID',
      })
    }
  ]
  const debit = {
    title: 'Debit',
    dataIndex: 'debit',
    sorter: (a, b) => a.debit - b.debit,
    ...getColumnSearchProps({
      dataIndex: 'debit',
      placeholder: 'Debit',
    })
  }
  const credit = {
    title: 'Credit',
    dataIndex: 'credit',
    sorter: (a, b) => a.credit - b.credit,
    ...getColumnSearchProps({
      dataIndex: 'credit',
      placeholder: 'Credit',
    })
  }
  switch (tradingTransactionsBtnValue) {
    case 'credit':
      columns.push(credit)
      break
    case 'debit':
      columns.push(debit)
      break
    default:
      columns = [...columns, debit, credit]
  }

  const closeModal = () => setState({
    ...state,
    modal: ''
  })
  const modalProps = {
    [MODAL_CONSTANTS.deposit]: {
      title: 'Create Deposit',
      amountFormField: 'debit',
      disabled,
      toggleDisabled: disabled => setState({
        ...state,
        disabled
      }),
      onSubmit: (
        {
          teOperationId,
          aws_file_id,
          trading_account_id,
          debit,
          transactionDate,
          resetFields
        }
      ) => {
        const body = aws_file_id ? {
          teOperationId,
          trading_account_id,
          debit,
          transactionDate,
          aws_file_id: aws_file_id[0].response.key
        } : {
          teOperationId,
          trading_account_id,
          debit,
          transactionDate
        }
        const payload = {
          body,
          route: '/deposit'
        }

        createDepositWithdrawal(payload, () => {
          fetchCb(currentAccountID)
          resetFields()
        })
      },
      children: (
        <FileUpload
          name='aws_file_id'
          multiple={false}
          maxCount={1}
        />
      )
    },
    [MODAL_CONSTANTS.withdrawal]: {
      title: 'Create Withdrawal',
      amountFormField: 'credit',
      children: null,
      onSubmit: (
        {
          resetFields,
          ...body
        }
      ) => {
        const payload = {
          body,
          route: '/withdrawal'
        }
        createDepositWithdrawal(payload, () => {
          fetchCb(currentAccountID)
          resetFields()
        })
      }
    }
  }
  const currentModalProps = modalProps[modal]
  return <>
    <Table
      rowKey='id'
      columns={columns}
      dataSource={list}
      loading={loading}
      scroll={{x: true}}
      title={() => {
        const onChange = ({target: {value}}) => filterTradingTransactions(value)
        return <Filters value={tradingTransactionsBtnValue} onChange={onChange} />
      }}
    />
    <TradingTransactionsModal
      tradingAccounts={tradingAccounts}
      onClose={closeModal}
      loading={loading}
      visible={Boolean(currentModalProps)}
      {...(currentModalProps || {})}
    />
  </>
}

const mapStateToProps = (state, ownProps) => {
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  const {
    list,
    loading,
    tradingTransactionsBtnValue
  } = tradingSelectors.tradingTransactionsSelector(state)
  const {list: tradingAccounts} = tradingSelectors.tradingAccountsSelector(state)

  return {
    currentAccountID: ownProps.accountID || currentAccountRecord.id,
    list,
    loading,
    tradingTransactionsBtnValue,
    tradingAccounts
  }
}

const {
  getTradingTransactions,
  filterTradingTransactions,
  getTradingAccounts,
  createDepositWithdrawal,
} = tradingActions

const mapDispatchToProps = {
  fetchCb: getTradingTransactions,
  filterTradingTransactions,
  getTradingAccounts,
  createDepositWithdrawal
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(listHOC(TradingTransactions))