import * as React from 'react'
import {
  Route,
  Redirect
} from 'components'
import { routes } from 'configs'
import {
  RouteComponentProps,
  RouteProps
} from 'types'

interface AuthenticatedRouteProps extends RouteProps {
  Component: React.FC<RouteComponentProps>;
  authenticated: boolean;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  Component,
  authenticated,
  path
}) => (
  <Route
    exact
    path={path}
    render={(props) => {
      return authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={`${routes.login}?redirect=${props.location.pathname}${props.location.search}`}
        />
      )
    }}
  />
)
export default AuthenticatedRoute
