import React from 'react'

import {NavLink} from 'react-router-dom'

import {Table} from 'antd'

import {connect} from 'react-redux'

import {appStateSelectors} from 'configs/ducks/appState'
import {
  settingsActions,
  settingsSelectors
} from 'configs/ducks/settings'

import {listHOC} from 'common'

const TradingPlatformsListPageComponent = (
  {
    getColumnSearchProps,
    tradingPlatformsList,
    loading
  }
) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (text, record) =>
        <NavLink to={`/settings/trading-platforms/${record.id}`}>
          {text}
        </NavLink>
    },
    {
      title: 'Trading Platform Mode',
      dataIndex: 'trading_platform_mode_file_by',
      ...getColumnSearchProps({
        dataIndex: 'trading_platform_mode_file_by',
        placeholder: 'Trading Platform Mode'
      })
    },
    {
      title: 'Trading Platform Type',
      dataIndex: 'trading_platform_type_file_by',
      ...getColumnSearchProps({
        dataIndex: 'trading_platform_type_file_by',
        placeholder: 'Trading Platform Type',
      })
    },
  ]

  return (
    <Table
      rowKey='id'
      scroll={{x: true}}
      loading={loading}
      columns={columns}
      dataSource={tradingPlatformsList}
    />
  )
}

const mapStateToProps = (state, {match: {params: {id}}}) => {
  id = Number(id)
  const {
    loading,
    tradingPlatformsList
  } = settingsSelectors
    .stateSelector(state)
  const {currentAccountRecord} = appStateSelectors
    .stateSelector(state)
  return {
    currentRecord: tradingPlatformsList.find(item => item.id === id) || {},
    currentAccountID: currentAccountRecord.id,
    tradingPlatformsList,
    loading
  }
}

const mapDispatchToProps = {fetchCb: settingsActions.getTradingPlatformsList}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(listHOC(TradingPlatformsListPageComponent))