import {ACCOUNTS_START_NEW_ACCOUNT_CREATION, ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION} from '../actions/accounts';

const initialState = {
  new_account_data: null,
  new_account_submitted: false,
  new_account_created: false
};

function accounts(state = initialState, action) {
  switch (action.type) {
    case ACCOUNTS_START_NEW_ACCOUNT_CREATION:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_account_data: {
          file_by: '',
          admin_user_id: ''
        },
        new_account_submitted: false,
        new_account_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
    case ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION:
      return Object.assign({}, state, {
        new_account_data: Object.assign({}, state.new_account_data, {
          [action.value.key]: action.value.value
        })
      });
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default accounts;
