import React, {Component} from 'react'

import {API} from 'aws-amplify'

//import { Layout } from 'antd';
import {Row, Col} from 'antd'
import {Select, Button} from 'antd'
import {notification} from 'antd'
import {Form, Input} from 'antd'

const {Option} = Select

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class UserClientSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {is_saving: false}
  }

  async componentDidMount() {
    try {
      const userClient = await this.getUserClientRecord(this.props.id)
      // console.log(userClient)
      this.setState({...userClient.payload})
    } catch (e) {
      console.log(e)
    }
  }

  async getUserClientRecord(userClient_id) {
    return API.get('user-client', `/id/${userClient_id}`)
  }

  async saveUserClientRecord(userClient_record) {
    console.log(userClient_record)
    await API.put('user-client', `/update/${this.state.id}`, {body: userClient_record})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value})
  };

  handle_submit = async event => {
    event.preventDefault()
    //console.log(event)
    //this.setState({ isLoading: true });
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id, aws_cognito_id, email
    } = this.state
    try {
      await this.saveUserClientRecord({
        id,
        aws_cognito_id,
        email
      })
      this.setState({is_saving: false})
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      console.log(e)
    }
  };

  render() {
    console.log(this.state)

    return (
      <React.Fragment>
        <Form layout='vertical' onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label='ID'>
                <Input
                  id='id'
                  value={this.state.id || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Aws Cognito ID'>
                <Input
                  id='aws_cognito_id'
                  value={this.state.aws_cognito_id || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Email'>
                <Input
                  id='email'
                  value={this.state.email || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

export default UserClientSummary
