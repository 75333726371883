import * as React from 'react'
import {Typography} from 'components'

const Title: React.FC = ({children}) => {
  return (
    <Typography.Title level={5} style={{marginTop: '0.5em'}}>
      {children}
    </Typography.Title>
  )
}

export default Title
