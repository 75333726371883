import React, {
  useState,
  useCallback
} from 'react'
import {
  Table,
  Typography,
  Button,
  Modal,
  Skeleton,
  Drawer,
  Space
} from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import {listHOC} from 'common'
import moment from 'moment'
import {
  FINANCIAL_ACCOUNT_TYPES,
  DATE_FORMAT
} from 'consts'

import CreateTransaction from './CreateTransaction'
import useAccountTransaction from '../hooks/useAccountTransaction'
import styles from './AccountTransaction.module.css'
import AccountTransactionDrawer from './AccountTransactionDrawer'

const AccountTransaction = ({getColumnSearchProps, type}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
  const [transaction, setTransaction] = useState({})
  const [state, doFetch] = useAccountTransaction()
  const handleViewTransaction = useCallback(transaction => {
    setTransaction(transaction)
    setIsUpdateModalVisible(true)
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID'
      }),
      render: (value, raw) => (
        <Button type='link' onClick={() => handleViewTransaction(raw)}>
          {value}
        </Button>
      )
    },
    {
      title: 'Reconciled',
      dataIndex: 'reconciledFlag',
      filters: [
        {
          text: 'YES',
          value: true
        },
        {
          text: 'NO',
          value: false
        }
      ],
      onFilter: (value, record) => Boolean(record.reconciledFlag) === value,
      render: (value) => value ? <CheckCircleOutlined style={{ color: 'green' }}/> : <CloseCircleOutlined style={{ color: 'red'}} />
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      render: value => <span>{moment(value).format(DATE_FORMAT)}</span>
    },
    {
      title: 'Memo',
      dataIndex: 'memo'
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      render: value => {
        return (
          <span>
            {type === FINANCIAL_ACCOUNT_TYPES.BANK ? (
              value && value.toFixed(2)
            ) : value}
          </span>
        )
      }
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      render: value => {
        return (
          <span>
            {type === FINANCIAL_ACCOUNT_TYPES.BANK ? (
              value && value.toFixed(2)
            ) : value}
          </span>
        )
      }
    }
  ]

  /* CREATE */
  const showModal = () => {
    setIsModalVisible(true)
  }
  
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onCreateSuccess = () => {
    setIsModalVisible(false)
    doFetch()
  }

  /* UPDATE */
  const handleCancelUpdate = () => {
    setIsUpdateModalVisible(false)
  }
  const onUpdateSuccess = () => {
    setIsUpdateModalVisible(false)
    doFetch()
  }

  return (
    <Skeleton loading={state.loading}>
      <Modal title='Create Transaction' footer={false} visible={isModalVisible} onCancel={handleCancel}>
        <CreateTransaction onSaveSuccess={onCreateSuccess} />
      </Modal>
      <Drawer
        title={`Update Transaction [${transaction?.id}]`}
        width={720}
        onClose={handleCancelUpdate}
        visible={isUpdateModalVisible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={handleCancelUpdate}>Cancel</Button>
            <Button onClick={handleCancelUpdate} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <AccountTransactionDrawer
          isUpdate
          key={transaction?.id}
          transaction={transaction}
          onSaveSuccess={onUpdateSuccess}
        />
      </Drawer>
      <div className={styles.header}>
        <div>
          <Typography.Title level={4}>Transactions</Typography.Title>
        </div>
        <div>
          <Button onClick={showModal}>Create Transaction</Button>
        </div>
      </div>
      <Table size='small' rowKey='id' columns={columns} dataSource={state.value} scroll={{x: true}} />
    </Skeleton>
  )
}

export default listHOC(AccountTransaction)
