import * as React from 'react'
import {
  Form,
  Select
} from 'antd'
import { useIntl } from 'hooks'
import { selectors } from './duck'
import * as Types from '../types'

const { Option } = Select

const Dropdown: React.FC<Types.InnerSelectProps> = ({
  options = [],
  placeholder,
  showArrow = true,
  allowClear = false,
  getOptionProps = opt => ({
    children: opt.children,
    value: opt.value
  }),
  style = { width: '100%' },
  innerRef,
  filterOption = selectors.filterOption,
  ...props
}) => {
  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Select<any, Types.Option>
      {...props}
      ref={innerRef}
      style={style}
      placeholder={placeholder}
      allowClear={allowClear}
      showArrow={showArrow}
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
    >
      {options.map((option, index) => {
        const {
          value, children, ...rest
        } = getOptionProps(option, index)

        return (
          <Option key={value} value={value} {...rest}>
            {children}
          </Option>
        )
      })}
    </Select>
  )
}

const InputSelect: React.FC<Types.InputSelectProps> = ({
  label,
  name,
  isFormItem = true,
  preserve = true,
  required,
  rules = [],
  placeholder = 'placeholders.select',
  innerRef,
  noStyle,
  className,
  ...props
}) => {
  const intl = useIntl()

  const S = (
    <Dropdown
      {...{
        ...props,
        placeholder: selectors.getPlaceholder(placeholder, intl),
      }}
      innerRef={innerRef}
    />
  )

  if (!isFormItem) {
    return S
  }

  return (
    <Form.Item
      label={
        label ? intl.formatMessage({id: label}) : null
      }
      name={name}
      preserve={preserve}
      noStyle={noStyle}
      className={className}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: 'validation.required' }),
        },
        ...rules,
      ]}
    >
      {S}
    </Form.Item>
  )
}

export default InputSelect
