import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {
  Table,
  Tag,
  Button,
  Row,
  Col,
  Card
} from 'antd'
import {listHOC} from 'common'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  organiserActions,
  organiserEntitiesSelector
} from 'configs/ducks/organiser'
import {inputTypes} from 'common/listHOC'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {
  staffsActions,
  staffsSelectors
} from 'configs/ducks/staffs'
import moment from 'moment'

import {API} from 'aws-amplify'
import {getStatusOptions} from '../../common'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import {OrganiserViewPageDrawerComponent} from '../../organiser/OrganiserViewPageDrawerComponent'
import OrganiserCreateNewTaskPageComponent from '../../organiser/OrganiserCreateNewTaskPageComponent'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */

const statuses = {
  1: {
    color: 'geekblue',
    text: 'High'
  },
  2: {
    color: 'blue',
    text: 'Medium'
  },
  3: {
    color: 'warning',
    text: 'Low'
  }
}

const flagStatus = {
  1: {
    color: 'red',
    text: 'No'
  },
  2: {
    color: 'blue',
    text: 'Yes'
  }
}
const statusFilters = getStatusOptions(statuses)

const ClientTasksListPageComponent = ({
  getColumnSearchProps,
  byClientIds,
  loading,
  fetchCb,
  clientId,
  currentStaff,
  clientList,
  staffList,
  currentAccountID,
  ...rest
}) => {
  const [state, setState] = useState({
    visible: false,
    organiserData: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      organiserData: record,
      visible: bool
    })
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = bool => setIsModalVisible(bool)

  const updateCompletedFlags = async values => {
    if (values.completedFlag) {
      values.completedDate = moment()
      values.completedStaffId = currentStaff.id
      values.completedFlag = 1
    } else {
      values.completedFlag = 0
      values.completedDate = null
    }
    const {id, ...payload} = values
    await API.put('organiser', `/update/${id}`, {body: payload})
    fetchCb(rest.client_id)
  }

  const columns = [
    {
      title: 'Task ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (highlighter, record) => (
          <Button type='link' onClick={() => toggleDrawer(true, record)}>
            {highlighter}
          </Button>
        )
      })
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: (a, b) => a.dueDate - b.dueDate,
      ...getColumnSearchProps({
        dataIndex: 'dueDate',
        placeholder: 'Due Date',
        filterInputType: inputTypes.DATE_RANGE,
        render: (highlighter, record) => <Fragment>{moment(highlighter).format('YYYY-MM-DD')}</Fragment>
      })
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      sorter: (a, b) => a.priority - b.priority,
      ...getColumnSearchProps({
        dataIndex: 'priority',
        placeholder: 'priority',
        filterInputType: inputTypes.SELECT,
        selectFilterOptions: statusFilters,
        getOptionProps: ({text, color}) => ({children: <Tag color={color}>{text}</Tag>}),
        optionValue: 'id',
        render: (_, {priority}) => {
          if (!priority) {
            return null
          }
          const {text, color} = statuses[priority]
          return (
            <Tag color={color} style={{textAlign: 'center'}}>
              {text}
            </Tag>
          )
        }
      })
    },
    {
      title: 'Description',
      dataIndex: 'shortDescription',
      ...getColumnSearchProps({
        dataIndex: 'shortDescription',
        placeholder: 'Description'
      })
    },
    {
      title: 'Completed',
      dataIndex: 'completedFlag',
      sorter: (a, b) => a.completedFlag - b.completedFlag,
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      onFilter: (value, record) => record.completedFlag === value,
      render: (completedFlag, record) => (
        <Fragment>
          <Row gutter={8}>
            <Col>
              <Checkbox
                checked={completedFlag === 1}
                onChange={e => {
                  updateCompletedFlags({
                    ...record,
                    completedFlag: e.target.checked
                  })
                }}
              />
            </Col>
            <Col>
              {!completedFlag ? (
                <Tag color='red' style={{textAlign: 'center'}}>
                  No
                </Tag>
              ) : (
                <span>{record.completedDate.split('T')[0]}</span>
              )}
            </Col>
          </Row>
        </Fragment>
      )
    }
  ]

  return (
    <Fragment>
      <Card
        style={{minHeight: 360}}
        title='Staff Tasks'
        extra={ state.deletedFlag !== 1 ? [
          <Button key='2' onClick={() => toggleModal(true)}>
            Create New
          </Button>
        ] : []}
      >
        <Table
          rowKey='id'
          columns={columns}
          dataSource={byClientIds}
          loading={loading}
          scroll={{x: true}}
          pagination={{
            total: byClientIds.length,
            showTotal: total => `Total ${total} records`,
            pageSizeOptions: ['20', '50', '100'],
            defaultPageSize: 20,
            showSizeChanger: true
          }}
        />

        {rest.client_id && currentStaff && currentStaff.id && (
          <OrganiserCreateNewTaskPageComponent
            isModalVisible={isModalVisible}
            defaultClient={rest.client_id}
            toggleModal={toggleModal}
            staffId={currentStaff.id}
            currentAccountID={rest.currentAccountId}
            id={rest.client_id}
            fetchC={fetchCb}
          />
        )}
      </Card>
      <OrganiserViewPageDrawerComponent
        {...state}
        toggleModal={toggleDrawer}
        clientList={clientList}
        fetchC={fetchCb}
        id={clientId}
        staffList={staffList}
      />
    </Fragment>
  )
}
const {fetchOrganiserByClientId} = organiserActions
const {organiserSelector} = organiserEntitiesSelector
const mapStateToProps = state => {
  return {
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    currentStaff: appStateSelectors.stateSelector(state).currentStaffRecord,
    staffList: staffsSelectors.stateSelector(state).entities,
    clientList: clientsSelectors.clientsSelector(state).entities,
    ...organiserSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: fetchOrganiserByClientId,})(listHOC(ClientTasksListPageComponent))
