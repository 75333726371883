import * as React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import {
  Form,
  Upload,
  Typography,
  UploadProps,
  UploadFile,
  notification
} from 'antd'
import {
  FormattedMessage,
  useIntl
} from 'react-intl'
import { RcFile } from 'types'

const ACCEPTED_FILES = '.pdf, .doc, .docx, .jpg, .jpeg, .png, .msword, .vnd.openxmlformats-officedocument.wordprocessingml.document'

interface FileUploadProps extends UploadProps {
  accept?: string;
  onRemove?: UploadProps['onRemove'];
  onUploadSuccess?: (file: RcFile) => void;
  required?: boolean;
  multiple?: boolean;
  name?: string;
  label?: string | React.ReactNode;
  maxCount?: number;
  // TODO UploadRef available in antd 5.* v
  // innerRef?: React.Ref<UploadRef>;
  formItemClassName?: string;
}

const addThumbURL = (file: UploadFile) => {
  // new Chrome update prevents opening files via base64
  file.thumbUrl = URL.createObjectURL((file.originFileObj || file) as File)
  return file
}

const FileUpload: React.FC<FileUploadProps> = ({
  accept = ACCEPTED_FILES,
  onRemove,
  required = false,
  multiple = false,
  name,
  label,
  maxCount,
  children,
  formItemClassName,
  onUploadSuccess,
  ...rest
}) => {
  const intl = useIntl()
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e.map(addThumbURL)
    }
    return e && e.fileList.map(addThumbURL)
  }

  const dragger = (
    <Upload.Dragger
      {...rest}
      multiple={multiple}
      accept={accept}
      onRemove={onRemove}
      maxCount={maxCount}
      showUploadList={{showRemoveIcon: Boolean(onRemove)}}
      customRequest={({ onSuccess, file }) => {
        onSuccess?.(file)

        onUploadSuccess?.(file as RcFile)
      }}
      isImageUrl={(file) =>
        ['jpg', 'jpeg', 'png'].some(
          (format) => file.type?.indexOf(format) !== -1
        )
      }
      beforeUpload={(file) => {
        const format = file.type.split('/')[1]

        if (file.size / 1024 / 1024 > 5) {
          notification.error({message: intl.formatMessage({id: 'notifications.error.bigFile'})})

          return Upload.LIST_IGNORE
        }

        if (!accept.match(format)) {
          notification.error({
            message: intl.formatMessage({id: 'notifications.error.invalidFileFormat'}, {
              format,
              allowedFormats: accept
            })
          })

          return Upload.LIST_IGNORE
        }
      }}
    >
      {children || (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <Typography.Paragraph>
            <FormattedMessage id="fileUpload.description" />
          </Typography.Paragraph>
          {multiple && (
            <Typography.Paragraph>
              <FormattedMessage id="fileUpload.multi" />
            </Typography.Paragraph>
          )}
        </>
      )}
    </Upload.Dragger>
  )

  if (!name) {
    return dragger
  }

  return (
    <Form.Item
      name={name}
      label={label}
      className={formItemClassName}
      getValueProps={(fileList = []) => ({fileList: fileList.map(addThumbURL)})}
      getValueFromEvent={normFile}
      valuePropName="fileList"
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
      ]}
    >
      {dragger}
    </Form.Item>
  )
}

export default FileUpload
