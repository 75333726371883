import React, {
  Fragment,
  useState
} from 'react'
  
import {
  Row,
  Col,
  Descriptions,
  ClientsSelect,
  Switch,
  Input,
  Button,
  Form,
  DatePicker
} from 'components'

import {FormSelect} from 'common'
import moment from 'moment'

export const filterOption = (input, option) => option.children.toLowerCase().includes(input.toLowerCase())

export const columnSize = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12
}

export const VerificationView = ({
  updateVerification,
  verificationData,
  verificationData: {
    id, client_id, request_datetime, response_datetime, trulioo_record_id, trulioo_transaction_id, rawResponse
  },
  trulioData: {
    loaded, trulioMatch, trulioMatchRule, DatasourceResults, InputFields
  }
}) => {
  const [switchState, setSwitchState] = useState(false)
  const [requestDatetimeState, setRequestDatetimeState] = useState(request_datetime)
  const [responseDatetimeState, setResponseDatetimeState] = useState(response_datetime)
  const [truliooRecordIdState, setTruliooRecordIdState] = useState(trulioo_record_id)
  const [truliooTransactionIdState, setTruliooTransactionIdState] = useState(trulioo_transaction_id)

  const dataSourceFieldStatus = {
    match: 'green',
    missing: 'red'
  }
  const descriptionCol = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  const onSwitchChange = (checked) => {
    setSwitchState(checked)
  }

  const onInputboxChange = (event) => {
    if (event.target.id === 'request_datetime'){
      setRequestDatetimeState(event.target.value)
    }
    if (event.target.id === 'response_datetime'){
      setResponseDatetimeState(event.target.value)
    }
    if (event.target.id === 'trulioo_record_id'){
      setTruliooRecordIdState(event.target.value)
    }
    if (event.target.id === 'trulioo_transaction_id'){
      setTruliooTransactionIdState(event.target.value)
    }
  }
  const onRequestDatetimePickerChange = (momentValue, value) => {
    setRequestDatetimeState(momentValue.utc(8).toISOString())
  }

  const onResponseDatetimePickerChange = (momentValue, value) => {
    setResponseDatetimeState(momentValue.utc(8).toISOString())
  }

  const onFormSubmit = values => {
    updateVerification({
      'id': verificationData.id,
      'client_id': values.client_id,
      'request_datetime': requestDatetimeState,
      'response_datetime': responseDatetimeState,
      'trulioo_record_id': truliooRecordIdState,
      'trulioo_transaction_id': truliooTransactionIdState,
    })
    setSwitchState(!switchState)
  }

  // console.log('rawResponse', JSON.parse(rawResponse))
  // console.log('rawResponse?.Record?.RecordStatus', JSON.parse(rawResponse).Record.RecordStatus)
  // console.log('JSON.parse(rawResponse).Record.Rule.Note', JSON.parse(rawResponse).Record.Rule.Note)

  const dataParsedRawResponse = JSON.parse(rawResponse)
  return (
    <>
      <Row gutter={[24,0]} >
        {id && (

          <Col xs={12}>

            <Descriptions
              bordered
              title='Verification Info'
              layout='vertical'
              size='small'
              style={{marginBottom: 20}}
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1
              }}
              extra={
                <Switch
                  size="small"
                  onChange={onSwitchChange}
                  checkedChildren="Edit"
                  unCheckedChildren="View"
                  checked={switchState}
                />
              }
            >
              <Descriptions.Item label='Client'>
                {switchState &&
                  <Form layout='vertical' onFinish={onFormSubmit}>
                    <ClientsSelect
                      required
                      name='client_id'
                    />
                  </Form>
                }
              </Descriptions.Item>

              <Descriptions.Item label='Request Datetime'>
                {!switchState && request_datetime}
                {switchState &&
                                          <>
                                            <Input id='request_datetime' value={requestDatetimeState} onChange={onInputboxChange} />

                                            <Form.Item
                                              name='request_datetime'
                                              normalize={momentValue => momentValue.format('YYYY-MM-DD')}
                                              getValueProps={value => ({ value: value ? moment(value, 'YYYY-MM-DD') : '' })}
                                            >
                                              <DatePicker
                                                allowClear={false}
                                                placeholder='Select date'
                                                showTime={{ format: 'HH:mm' }}
                                                value={requestDatetimeState}
                                                onChange={onRequestDatetimePickerChange} />
                                            </Form.Item>
                                          </>}
              </Descriptions.Item><Descriptions.Item label='Response Datetime'>
                {!switchState && response_datetime}
                {switchState &&
                                          <>
                                            <Input id='response_datetime' value={responseDatetimeState} onChange={onInputboxChange} />

                                            <Form.Item
                                              name='responseDatetime'
                                              normalize={momentValue => momentValue.format('YYYY-MM-DD')}
                                              getValueProps={value => ({ value: value ? moment(value, 'YYYY-MM-DD') : '' })}
                                            >
                                              <DatePicker
                                                allowClear={false}
                                                placeholder='Select date'
                                                showTime={{ format: 'HH:mm' }}
                                                value={responseDatetimeState}
                                                onChange={onResponseDatetimePickerChange} />
                                            </Form.Item>
                                          </>}
              </Descriptions.Item><Descriptions.Item label='Trulioo Record ID'>
                {!switchState && trulioo_record_id}
                {switchState && <Input id='trulioo_record_id' value={truliooRecordIdState} onChange={onInputboxChange} />}
              </Descriptions.Item><Descriptions.Item label='Trulioo Transaction ID'>
                {!switchState && trulioo_transaction_id}
                {switchState && <Input id='trulioo_transaction_id' value={truliooTransactionIdState} onChange={onInputboxChange} />}
              </Descriptions.Item>
            </Descriptions>

            {switchState && <Row justify='end'>
              <Button type='primary' onClick={onFormSubmit}>
              Save
              </Button>
            </Row>}
          </Col>
        )}
 
        {loaded && (
          <>
            <Col xs={12}>
              <Descriptions
                size='small'
                layout='vertical'
                title='Verification Match Info'
                bordered
                style={{marginBottom: 20}}
                column={{
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1
                }}
              >
                <Descriptions.Item label='Trulioo Match'>
                  <span style={{color: dataParsedRawResponse?.Record.RecordStatus === 'match' ? 'green' : ''}}>{dataParsedRawResponse?.Record.RecordStatus}</span>
                </Descriptions.Item>
                <Descriptions.Item label='Trulioo Match Rule'>{dataParsedRawResponse?.Record.Rule.Note}</Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {loaded && (
          <>
            <Row gutter={[24,0]}>

              {Boolean(dataParsedRawResponse?.Record.DatasourceResults.length) && (
                <Col xs={12}>
                  <Descriptions
                    style={{marginBottom: 20}}
                    title='Datasource Results'
                    layout='vertical'
                    bordered
                    column={descriptionCol}
                  >
                    {dataParsedRawResponse?.Record.DatasourceResults.map(({
                      DatasourceName, DatasourceFields, Errors
                    }) => (
                      <Descriptions.Item label={DatasourceName} key={DatasourceName}>
                        {Errors.length > 0
                          ? Errors.map(({Code, Message}) => <p>{Code}:{Message}</p>)
                          : DatasourceFields.length ? DatasourceFields.map(({FieldName, Status}) => <p>{FieldName}:{Status}</p>)
                            : ''
                        }
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </Col>
              )}

              {dataParsedRawResponse?.Record.DatasourceResults.map(({DatasourceName, DatasourceFields}) => (
                <Fragment key={DatasourceName}>
                  {Boolean(DatasourceFields.length) > 0 &&
                      <Col xs={12}>
                        <Descriptions
                          style={{marginBottom: 20}}
                          title='Datasource Fields'
                          label={DatasourceName}
                          bordered
                          layout='vertical'
                          column={descriptionCol}
                        >
                          {DatasourceFields.map(({FieldName, Status}) => (
                            <Descriptions.Item label={FieldName} key={FieldName}>
                              <span style={{color: dataSourceFieldStatus[Status] || ''}}>{Status}</span>
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </Col>
                  }
                </Fragment>
              ))}

              {Boolean(dataParsedRawResponse?.Record.DatasourceResults) &&
                dataParsedRawResponse?.Record.DatasourceResults.map(({AppendedFields}) => (
                  AppendedFields.length ?
                    <Col xs={24}>
                      <Descriptions
                        style={{marginBottom: 20}}
                        title='Appended Fields'
                        layout='vertical'
                        bordered
                        column={descriptionCol}
                      >
                        {AppendedFields.map(({FieldName, Data}) =>
                          <Descriptions.Item label={FieldName} key={FieldName}>
                            {Data}
                          </Descriptions.Item>)}
                      </Descriptions>
                    </Col>
                    : ''
                ))
              }
            </Row>
            
          </>
        )}
      </Row>
    </>
  )
}