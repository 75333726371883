import React, {
  useState,
  useRef
} from 'react'
import {
  Button,
  Form,
  Input,
  Modal,
  Select
} from 'antd'
import {useForm} from 'antd/lib/form/Form'

import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw,
  convertFromRaw
} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import draftToHtml from 'draftjs-to-html'

import SelectDocuments from '../select-documents/select-documents'

const {Option} = Select

const CreateJournal = (props) => {
  const {
    clientId, isModalVisible, toggleModal, onFinish, documentId, prefillType, prefillTitle, prefillMemo,
  } = props

  const content = convertFromRaw({
    'entityMap': {},
    'blocks': [
      {
        'key': 'e4brl',
        'text': prefillMemo || '',
        'type': 'unstyled',
        'depth': 0,
        'entityRanges': [],
        'data': {}
      }
    ]
  })

  const [form] = useForm()
  const [editorState, setEditorState] = useState(EditorState.createWithContent(content))
  const [editorText, seteditorText] = useState(prefillMemo)
  const ref = useRef(null)
  
  const onEditorStateChange = (editor) => {
    setEditorState(editor)
    seteditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }

  const onDone = (values) => {
    values['clientId'] = clientId
    values['memo'] = editorText
    onFinish(values)
    setTimeout(() => {
      ref.current.resetFields()
      setEditorState(EditorState.createEmpty())
    }, 2000)
  }

  const handleChangeDocument = (value) => {
    form.setFieldsValue({ linkedDocumentId: value })
  }

  return (
    <Modal
      title='Create Journal'
      open={isModalVisible}
      onOk={null}
      onCancel={() => {
        toggleModal(false)
      }}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      footer={null}
      destroyOnClose
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={onDone}
        requiredMark={false}
        ref={ref}
        initialValues={{
          linkedDocumentId: documentId,
          journalTypeId: prefillType,
          title: prefillTitle,
          memo: prefillMemo
        }}
      >
        <Form.Item
          name='journalTypeId'
          label='Journal Type'
          rules={[
            {
              required: true,
              message: 'Journal Type is Required'
            }
          ]}
        >
          <Select>
            <Option value='2'>Staff Note</Option>
            <Option value='1'>System Note</Option>
            <Option value='3'>Client Update</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='title'
          label='Journal Title'
          rules={[
            {
              required: true,
              message: 'Journal Title is Required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='linkedDocumentId' label='Link Documents'>
          <SelectDocuments
            value={form.getFieldValue('linkedDocumentId')}
            clientId={clientId}
            onChange={handleChangeDocument}
          />
        </Form.Item>
        <Form.Item
          name='memo'
          label='Memo'
          rules={[
            {
              required: true,
              message: 'Memo is Required'
            }
          ]}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            editorStyle={{
              border: '1px solid #F1F1F1',
              borderRadius: '2px',
              paddingLeft: '10px',
              paddingRight: '10px',
              height: '250px'
            }}
            toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history']}}
          />
        </Form.Item>
        <div style={{textAlign: 'right'}}>
          <Button type='primary' htmlType='submit'>
            Create
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateJournal