import { useEffect } from 'react'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import { getDocuments } from 'configs/ducks/clients/actions'
import { clientDocumentsSelector } from 'configs/ducks/clients/selectors'

const useClientDocuments = clientId => {
  const dispatch = useDispatch()
  const documents = useSelector(clientDocumentsSelector)

  useEffect(() => {
    async function fetchDocument() {
      if (clientId) {
        await dispatch(getDocuments(clientId))
      }
    }

    fetchDocument()
  }, [clientId, dispatch])

  return [
    documents
  ]
}

export default useClientDocuments