import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import * as types from './types'

function* fetchClientsV2(action) {
  const {payload: id} = action
  try {
    const {payload} = yield call([API, API.get], 'trading-commissions', `/client/list/account/${id}`)
    yield put({
      type: types.FETCH_TCCLIENTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_TCCLIENTS_V2_ERROR})
  }
}

function* fetchWallets(action) {
  const {payload: id} = action
  try {
    const {payload} = yield call([API, API.get], 'trading-commissions', `/wallets/${id}`)
    yield put({
      type: types.FETCH_TCWALLETS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_TCWALLETS_ERROR})
  }
}

function* fetchGeneralWalletTransactions(action) {
  const {payload: id} = action
  try {
    const {payload} = yield call([API, API.get], 'trading-commissions', `/wallet-transactions/account/${id}`)
    yield put({
      type: types.FETCH_TCWALLETS_TRANSACTIONS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_TCWALLETS_TRANSACTIONS_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.FETCH_TCCLIENTS_V2_REQUEST, fetchClientsV2),
    yield takeEvery(types.FETCH_TCWALLETS_REQUEST, fetchWallets),
    yield takeEvery(types.FETCH_TCWALLETS_TRANSACTIONS_REQUEST, fetchGeneralWalletTransactions)
  ])
}
