import React, {
  useEffect,
  useState
} from 'react'
import {routes} from 'configs'
import {
  Button,
  Descriptions,
  Skeleton,
  Table,
  Tag,
  NavLink,
  Form,
  InputSelect,
  ClientsSelect,
  Modal,
  SubmitButton
} from 'components'
import {
  useHistory,
  useSelector
} from 'hooks'
import moment from 'moment'
import {
  API,
  showNotification
} from 'utils'

import {listHOC} from 'hocs'

function TradingAccounts({
  entities, loading, getColumnSearchProps, getTopics
}) {
  const history = useHistory()
  const [state, setState] = useState({
    submitting: false,
    modalOpen: false
  })
  const {
    clientRecord,
    tradingPlatformAccounts,
    currencies
  } = useSelector(state => ({
    clientRecord: state.clients.entities.clientRecord,
    tradingPlatformAccounts: state.appState.tradingPlatformAccounts,
    currencies: state.appState.currencies,
  }))

  useEffect(() => {
    getTopics({
      extra: <Button onClick={() => setState(prevState => ({
        ...prevState,
        modalOpen: true
      }))}>Create New</Button>
    })
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (text, record) => <NavLink to={routes.tradingAccountsView(record.id)}>{text}</NavLink>
      })
    },
    getColumnSearchProps({
      title: 'Platform',
      dataIndex: 'platform',
      onFilter: (value, record) => record.platform === value,
      render: (text, record) => `${record.trading_platform_type_file_by} ${record.trading_platform_mode_file_by}`
    }),
    {
      title: 'Platform ID',
      dataIndex: 'tradingPlatformUsername',
      ...getColumnSearchProps({
        dataIndex: 'tradingPlatformUsername',
        placeholder: 'tradingPlatformUsername'
      })
    },
    getColumnSearchProps({
      title: 'Client ID',
      dataIndex: 'client_id',
      sorter: (a, b) => a.client_id - b.client_id,
      render: (text, record) => <NavLink to={routes.clientsView({clientID: record.client_id})}>{text}</NavLink>
    }),
    {
      title: 'Client',
      dataIndex: 'client_nickname',
      ...getColumnSearchProps({
        dataIndex: 'client',
        placeholder: 'Client'
      })
    },
    {
      title: 'Created',
      dataIndex: 'recordCreated',
      ...getColumnSearchProps({
        dataIndex: 'recordCreated',
        placeholder: 'recordCreated',
        render: (text, record) => (text ? <span>{moment(text).format('DD-MM-YYYY HH:mm:ss')}</span> : <span></span>)
      })
    },
    {
      title: 'Account Status',
      dataIndex: 'accountLock',
      filters: [
        {
          text: 'Locked',
          value: true
        },
        {
          text: 'OK',
          value: false
        }
      ],
      onFilter: (value, record) => Boolean(record.accountLock) === value,
      render: (text, record) =>
        text ? <Tag color='red'> LOCK </Tag> : <Tag color='green'> OK </Tag>
    },
    {
      title: 'Security Status',
      dataIndex: 'securityLock',
      filters: [
        {
          text: 'Locked',
          value: true
        },
        {
          text: 'OK',
          value: false
        }
      ],
      onFilter: (value, record) => Boolean(record.securityLock) === value,
      render: (text, record) =>
        text ? <Tag color='red'> LOCK </Tag> : <Tag color='green'> OK </Tag>
    }
  ]

  return (
    <>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={entities}
        loading={loading}
        scroll={{x: true}}
      />
      <Modal
        footer={null}
        destroyOnClose
        title='New Trading Account'
        open={state.modalOpen}
        onCancel={() => {
          setState(prevState => ({
            ...prevState,
            modalOpen: false
          }))
        }}
      >
        <Form
          layout='vertical'
          preserve={false}
          onFinish={async values => {
            try {
              setState(prevState => ({
                ...prevState,
                submitting: true
              }))

              const response = await API.post('trading-accounts', '/createAccount', {body: {...values}})
              history.push(`/trading/accounts/${response.payload.insertId}`)

              setState(prevState => ({
                ...prevState,
                submitting: false
              }))
              showNotification('success', 'Journals', 'Successfully Created')
            } catch (e) {
              setState(prevState => ({
                ...prevState,
                submitting: false
              }))
              showNotification('error', 'Journals', e?.response?.data?.error || e.message)
            }
          }}
        >
          <ClientsSelect
            required
            name='client_id'
          />
          {clientRecord && clientRecord.id && (
            <Skeleton>
              <Descriptions title='Client Info' layout='vertical'>
                <Descriptions.Item label='Id'>{clientRecord.id}</Descriptions.Item>
                <Descriptions.Item label='First Name'>{clientRecord.first_name}</Descriptions.Item>
                <Descriptions.Item label='Last Name'>{clientRecord.last_name}</Descriptions.Item>
                <Descriptions.Item label='Email' span={2}>
                  {clientRecord.email}
                </Descriptions.Item>
              </Descriptions>
            </Skeleton>
          )}
          <InputSelect
            required
            label='tradingAccounts.title'
            name='trading_platform_account_id'
            options={tradingPlatformAccounts}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.fileBy
            })}
          />
          <InputSelect
            required
            label='common.currency'
            name='currency_id'
            options={currencies}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.iso_alpha_3
            })}
          />
          <SubmitButton loading={state.submitting} />
        </Form>
      </Modal>
    </>
  )
}

export default listHOC(TradingAccounts, ({rootState}) => {
  return API.get('trading-accounts', `/account/${rootState.appState.currentAccountRecord.id}`)
})