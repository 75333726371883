import {LANGUAGE_SET_ID} from '../actions/language.js'

const initialState = {
  language_current_ui: 'en',
  language_list: ['en', 'zh']
}

function language(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_SET_ID:
      return {
        ...state,
        language_current_ui: action.value
      }

    default:
      return state
  }
}

export default language
