import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {
  Table,
  Tag,
  Button,
  Row,
  Col
} from 'antd'
import {
  listHOC,
  inputTypes
} from 'common'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  organiserActions,
  organiserEntitiesSelector
} from 'configs/ducks/organiser'
import {staffsSelectors} from 'configs/ducks/staffs'

import {getStatusOptions} from '../common'

import moment from 'moment'
import {API} from 'aws-amplify'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import OrganiserCreateNewTaskPageComponent from './OrganiserCreateNewTaskPageComponent'
import {OrganiserViewPageDrawerComponent} from './OrganiserViewPageDrawerComponent'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */

const statuses = {
  1: {
    color: 'geekblue',
    text: 'High'
  },
  2: {
    color: 'blue',
    text: 'Medium'
  },
  3: {
    color: 'warning',
    text: 'Low'
  }
}

const flagStatus = {
  1: {
    color: 'red',
    text: 'No'
  },
  2: {
    color: 'blue',
    text: 'Yes'
  }
}
const statusFilters = getStatusOptions(statuses)
const flagFilters = getStatusOptions(flagStatus)

const OrganisersTasksListPageComponent = ({
  getColumnSearchProps,
  currentAccountID,
  byStaffIds,
  loading,
  fetchCb,
  currentStaff,
  clientId,
  getTopics,
  staffList
}) => {
  const [isCreateModalVisible, setCreateIsModalVisible] = useState(false)
  const toggleCreateModal = bool => setCreateIsModalVisible(bool)

  const [state, setState] = useState({
    visible: false,
    organiserData: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      organiserData: record,
      visible: bool
    })
  }
  const updateCompletedFlags = async values => {
    if (values.completedFlag) {
      values.completedDate = moment()
      values.completedStaffId = currentStaff.id
      values.completedFlag = 1
    } else {
      values.completedFlag = 0
      values.completedDate = null
    }
    const {id, ...payload} = values
    await API.put('organiser', `/update/${id}`, {body: payload})
    fetchCb(clientId)
  }

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => toggleCreateModal(true)}>Create New</Button>
        </>
      )
    })
  }, [])

  const columns = [
    {
      title: 'Task ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (highlighter, record) => (
          <Button type='link' onClick={() => toggleDrawer(true, record)}>
            {highlighter}
          </Button>
        )
      })
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
      ...getColumnSearchProps({
        dataIndex: 'dueDate',
        placeholder: 'Due Date',
        filterInputType: inputTypes.DATE_RANGE,
        render: (highlighter, record) => <Fragment>{moment(highlighter).format('YYYY-MM-DD')}</Fragment>
      })
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      sorter: (a, b) => a.priority - b.priority,
      filters: [
        {
          text: 'High',
          value: 1
        },
        {
          text: 'Medium',
          value: 2
        },
        {
          text: 'Low',
          value: 3
        }
      ],
      onFilter: (value, record) => record.completedFlag === value,
      render: text => <Tag color={statuses[text].color}> {statuses[text].text}</Tag>
    },
    {
      title: 'Description',
      dataIndex: 'shortDescription',
      ...getColumnSearchProps({
        dataIndex: 'shortDescription',
        placeholder: 'Description'
      })
    },
    {
      title: 'Client',
      dataIndex: 'client_nickname',
      sorter: (a, b) => a.client_nickname - b.client_nickname,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'client_nickname',
        placeholder: 'Client'
      })
    },
    {
      title: 'Completed',
      dataIndex: 'completedFlag',
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      onFilter: (value, record) => record.completedFlag === value,
      render: (completedFlag, record) => (
        <Fragment>
          <Row gutter={8}>
            <Col>
              <Checkbox
                checked={completedFlag === 1}
                onChange={e => {
                  updateCompletedFlags({
                    ...record,
                    completedFlag: e.target.checked
                  })
                }}
              />
            </Col>
            <Col>
              {!completedFlag ? (
                <Tag color='red' style={{textAlign: 'center'}}>
                  No
                </Tag>
              ) : (
                <span>{moment(record.completedDate).toISOString()}</span>
              )}
            </Col>
          </Row>
        </Fragment>
      )
    }
  ]

  return (
    <Fragment>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={byStaffIds}
        loading={loading}
        scroll={{x: true}}
        pagination={{
          total: byStaffIds.length,
          showTotal: total => `Total ${total} records`,
          pageSizeOptions: ['20', '50', '100'],
          defaultPageSize: 20,
          showSizeChanger: true
        }}
      />
      <OrganiserCreateNewTaskPageComponent
        isModalVisible={isCreateModalVisible}
        toggleModal={toggleCreateModal}
        fetchC={fetchCb}
        id={clientId}
        currentAccountID={currentAccountID}
      />
      <OrganiserViewPageDrawerComponent
        {...state}
        toggleModal={toggleDrawer}
        id={clientId}
        fetchC={fetchCb}
        staffList={staffList}
      />
    </Fragment>
  )
}
const {organiserSelector} = organiserEntitiesSelector
const {fetchOrganiserByStaffId} = organiserActions
const mapStateToProps = state => {
  return {
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    currentStaff: appStateSelectors.stateSelector(state).currentStaffRecord,
    clientId: appStateSelectors.stateSelector(state).currentStaffRecord.id,
    staffList: staffsSelectors.stateSelector(state).entities,
    ...organiserSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: fetchOrganiserByStaffId,})(listHOC(OrganisersTasksListPageComponent))