import React, {
  useEffect,
  Fragment,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'hooks'

import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {
  Col,
  Input,
  Row,
  Spin,
  Form,
  Button,
  InputSelect,
  ClientsSelect,
  InputDatePicker,
  Typography,
  Descriptions,
  Drawer,
  Space
} from 'components'
import {columnSize} from '../common'
import {FormSelect} from 'common'
import moment from 'moment'
import {openNotificationWithIcon} from 'configs/utils'
import {API} from 'aws-amplify'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import {
  EditorState,
  convertToRaw,
  ContentState
} from 'draft-js'
import {Link} from 'react-router-dom'

const {Title} = Typography

const priorityTypes = [
  {
    id: 1,
    label: 'HIGH'
  },
  {
    id: 2,
    label: 'MEDIUM'
  },
  {
    id: 3,
    label: 'LOW'
  }
]

const dateFormat = 'DD/MM/YYYY'

export const OrganiserViewPageDrawerComponent = ({
  visible,
  organiserData,
  toggleModal,
  staffList,
  fetchC,
  id
}) => {
  const [tradingAccountTransactions, setTradingAccountTrasactions] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, seteditorText] = useState('')
  const onEditorStateChange = editor => {
    setEditorState(editor)
    seteditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }
  const [currDocument, setCurrentDocument] = useState(null)
  const [currTransactions, setCurrentTransactions] = useState(null)
  const clientRecord = useSelector(state => state.clients.entities.clientRecord)
  const {documentsList} = useSelector(state => clientsSelectors.clientDocumentsSelector(state))
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const getTransactions = async clientId => {
    const response = await API.get('trading-transactions', `/client/${clientId}`)
    console.info(' respomnse ', response)
    setTradingAccountTrasactions(response.payload)
  }

  useEffect(() => {
    if (organiserData) {
      form.setFieldsValue(organiserData)
      if (organiserData.clientId) {
        dispatch(clientsActions.getDocuments(organiserData.clientId))
        getTransactions(organiserData.clientId)
      }
      if (organiserData.documentId) {
        setCurrentDocument(organiserData.documentId)
      } else {
        setCurrentDocument(null)
      }
      if (organiserData.transactionId) {
        setCurrentTransactions(organiserData.transactionId)
      } else {
        setCurrentTransactions(null)
      }
      if (organiserData.longDescription) {
        const html = organiserData.longDescription
        const contentBlock = htmlToDraft(html)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  }, [organiserData])

  const onSubmit = async values => {
    await API.put('organiser', `/update/${organiserData.id}`, {
      body: {
        ...values,
        longDescription: editorText
      }
    })

    if (fetchC) {
      fetchC(id)
    }

    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
    toggleModal(false)
  }

  if (organiserData == null) {
    return <div></div>
  }

  if (organiserData.dueDate) {
    organiserData.dueDate = moment(moment(organiserData.dueDate).format(dateFormat), dateFormat)
  }

  if (organiserData.completedDate) {
    organiserData.completedDate = moment(moment(organiserData.completedDate).format(dateFormat), dateFormat)
  }

  return (
    <Fragment>
      <Drawer
        title='Organiser Detail'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!organiserData.id}>
            <Form form={form} layout='vertical' onFinish={onSubmit} initialValues={organiserData}>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Col xs={24}>
                  <Row gutter={[48, 0]}>
                    <Col {...columnSize}>
                      <InputSelect
                        required
                        name='staffId'
                        label='common.staff'
                        options={staffList}
                        getOptionProps={opt => ({
                          value: opt.id,
                          children: `${opt.first_name} ${opt.last_name}`
                        })}
                      />
                    </Col>
                    <Col {...columnSize}>
                      <InputSelect
                        name='priority'
                        label='common.priority'
                        options={priorityTypes}
                        getOptionProps={opt => ({
                          value: opt.id,
                          children: opt.label
                        })}
                      />
                    </Col>
                    <Col {...columnSize}>
                      <ClientsSelect
                        name='clientId'
                        label='common.client'
                      />
                    </Col>
                  </Row>
                  <Fragment>
                    <Row gutter={8}>
                      {documentsList && documentsList.length > 0 && (
                        <Col xs={12}>
                          <FormSelect
                            allowClear
                            label={
                              <Fragment>
                                <span>Document</span>

                                {currDocument && (
                                  <Fragment>
                                    &nbsp; &nbsp;
                                    <Link to={`/documents/view/${currDocument}`}>
                                      Click here to see Document record
                                    </Link>
                                  </Fragment>
                                )}
                              </Fragment>
                            }
                            name='documentId'
                            optionValue='id'
                            value={currDocument}
                            selected={currDocument}
                            placeholder='Select Document'
                            getOptionProps={({description, id}) => ({children: `${description} [${id}]`})}
                            options={documentsList}
                            onSelect={async select => {
                              setCurrentDocument(select)
                            }}
                          />
                        </Col>
                      )}

                      {tradingAccountTransactions.length > 0 && (
                        <Col xs={12}>
                          <FormSelect
                            allowClear
                            label={
                              <Fragment>
                                <span>Transactions</span>

                                {currTransactions && (
                                  <Fragment>
                                    &nbsp; &nbsp;
                                    <Link to={`/trading/transactions/${currTransactions}/deposit`}>
                                      Click here to see transaction record
                                    </Link>
                                  </Fragment>
                                )}
                              </Fragment>
                            }
                            name='transactionId'
                            optionValue='id'
                            value={currTransactions}
                            placeholder='Select Document'
                            getOptionProps={({
                              id, record_created, debit
                            }) => ({children: `${id},  ${record_created.split('T')[0]}, [${debit}]`})}
                            options={tradingAccountTransactions}
                            onSelect={async select => {
                              setCurrentTransactions(select)
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Fragment>
                  {clientRecord.id && (
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{
                        marginBottom: 24,
                        marginTop: 0
                      }}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='First Name'>
                        <strong>{clientRecord.first_name}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Last Name'>
                        <strong>{clientRecord.last_name}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Email'>
                        <strong>{clientRecord.email}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                  <Row gutter={[48, 0]}>

                    <Col {...columnSize}>
                      <InputDatePicker
                        name='dueDate'
                        label='date.due'
                      />
                    </Col>
                    <Col {...columnSize}>
                      <Form.Item
                        name='completedFlag'
                        label='Task Completed'
                        valuePropName='checked'
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Title level={5}>Description</Title>
                  <Form.Item name='shortDescription'>
                    <Input />
                  </Form.Item>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{
                      border: '1px solid #F1F1F1',
                      borderRadius: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      height: '250px'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col offset={18} xs={2}>
                  <Form.Item>
                    <Button type='primary' htmlType='submit'>
                      Save Record
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}
