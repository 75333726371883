import * as React from 'react'
import * as C from 'antd'
import { FormattedMessage } from 'react-intl'
import { createUseStyles } from 'react-jss'

interface SubmitButtonProps {
  loading?: boolean;
}

const useStyles = createUseStyles({
  formItem: {margin: 0},
  row: {marginTop: 14}
})

const SubmitButton: React.FC<SubmitButtonProps> = ({ loading }) => {
  const classes = useStyles()
  
  return (
    <C.Row justify="center" className={classes.row}>
      <C.Form.Item shouldUpdate className={classes.formItem}>
        {({ isFieldsTouched }) => (
          <C.Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={!isFieldsTouched}
          >
            <FormattedMessage id="button.submit" />
          </C.Button>
        )}
      </C.Form.Item>
    </C.Row>
  )
}

export default SubmitButton
