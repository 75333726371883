import React from 'react'

import {routes} from 'configs'

import AccountNewPageComponent from 'pages/dashboard/accounts/AccountNewPageComponent'
import AccountViewPageComponent from 'pages/dashboard/accounts/AccountViewPageComponent'

import TradingAccounts from 'pages/dashboard/trading/TradingAccounts'
import TradingAccountsView from 'pages/dashboard/trading/TradingAccountsView'
import TradingCommissionsFull from 'pages/dashboard/trading/TradingCommissionsFull'
import TradingCommissionsSum from 'pages/dashboard/trading/TradingCommissionsSum'
import TradingTransactions from 'pages/dashboard/trading/TradingTransactions'

import ClientNewPageComponent from 'pages/dashboard/clients/ClientNewPageComponent'
import ClientListPageComponent from 'pages/dashboard/clients/ClientListPageComponent'
import ClientViewPageComponent from 'pages/dashboard/clients/ClientViewPageComponent'
import ClientNewBulkPageComponent from 'pages/dashboard/clients/ClientNewBulkPageComponent'
import ClientNewCSVPageComponent from 'pages/dashboard/clients/ClientNewCSVPageComponent'

import TradingCommissionClientListPageComponent from 'pages/dashboard/trading-commission/TradingCommissionClientListPageComponent'
import TradingCommissionWalletsListPageComponent from 'pages/dashboard/trading-commission/TradingCommissionWalletsListPageComponent'
import WalletViewPageComponent from 'pages/dashboard/trading-commission/WalletViewPageComponent'
import TradingCommissionGeneralWalletTransactionsListPageComponent from 'pages/dashboard/trading-commission/TradingCommissionGeneralWalletTransactionsListPageComponent'

import CurrenciesListPageComponent from 'pages/dashboard/currencies/CurrenciesListPageComponent'

import CollectionsListPageComponentContainer from 'pages/dashboard/checklist-collections/CollectionsListPageComponent'
import CollectionsViewPageComponent from 'pages/dashboard/checklist-collections/CollectionsViewPageComponent'
import DocumentDigisignTemplatesListPageComponent from 'pages/dashboard/documents/DocumentDigisignTemplatesListPageComponent'
import DocumentDigisignTemplatesSummary from 'pages/dashboard/documents/DocumentDigisignTemplatesSummary'
import DocumentNewPageComponent from 'pages/dashboard/documents/DocumentNewPageComponent'

import StaffNewPageComponent from 'pages/dashboard/staff/StaffNewPageComponent'
import StaffListPageComponent from 'pages/dashboard/staff/StaffListPageComponent'
import StaffViewPageComponent from 'pages/dashboard/staff/StaffViewPageComponent'

import TradingPlatformsListPageComponent from 'pages/dashboard/trading-platforms/TradingPlatformsListPageComponent'
import TradingPlatformsViewPageComponent from 'pages/dashboard/trading-platforms/TradingPlatformsViewPageComponent'

import UserProfilePageComponent from 'pages/dashboard/user_profile/UserProfilePageComponent'

import UserClientListPageComponent from 'pages/dashboard/user_client/UserClientListPageComponent'
import UserClientViewPageComponent from 'pages/dashboard/user_client/UserClientViewPageComponent'

// import VerificationMonitorReportPageComponent from 'pages/dashboard/verifications/VerificationMonitorReportPageComponent'
// import ScheduledVerificationsPageComponent from 'pages/dashboard/verifications/ScheduledVerificationsPageComponent'
// import ScheduledEnrolmentsPageComponent from 'pages/dashboard/verifications/ScheduledEnrolmentsComponent'
// import VerificationResultsPageComponent from 'pages/dashboard/verifications/VerificationResultsPageComponent'
import VerificationSummary from 'pages/dashboard/verifications/VerificationSummary'// import VerificationCreateRecordPageComponent from 'pages/dashboard/verifications/VerificationCreateRecordPageComponent'

import {
  identification,
  documents,
  clients,
  verifications,
  informationRequest
} from 'pages'

import ProfitLoss from 'pages/dashboard/reports/profitLoss/ProfitLoss'
import Derivatives from 'pages/dashboard/reports/derivatives/Derivatives'
import HourlyPositions from 'pages/dashboard/reports/hourlyPositions/HourlyPositions'
import DailyPositions from 'pages/dashboard/reports/dailyPositions/DailyPositions'
import Dashboard from 'pages/dashboard/reports/dashboard/Dashboard'
import ListWithoutInfoReq from 'pages/dashboard/reports/listWithoutInfoReq/ListWithoutInfoReq'
import OutstandingRequests from 'pages/dashboard/reports/outstandingRequests/OutstandingRequests'
import ClientStatusMetrics from 'pages/dashboard/reports/clientStatusMetrics/ClientStatusMetrics'
import LeadStatusMetrics from 'pages/dashboard/reports/leadStatusMetrics/LeadStatusMetrics'
import AccountMetrics from 'pages/dashboard/reports/accountMetrics/AccountMetrics'
import InformationRequestMetrics from 'pages/dashboard/reports/informationRequestMetrics/InformationRequestMetrics'

import DashboardPageComponent from 'pages/dashboard/dashboard/DashboardPageComponent'
import JournalsListPage from 'pages/dashboard/journals/journalsListPageComponent'
import {JournalViewPageComponent} from 'pages/dashboard/journals/journalViewPageComponent'
import {SystemToolsPageComponent} from 'pages/dashboard/system-tools/systemToolsPageComponent'
import OrganiserListPageComponent from 'pages/dashboard/organiser/OrganiserListPageComponent'
import ClientWatchingPage from 'pages/dashboard/clients/watching/ClientWatchingPage'
import OrganisersTasksListPageComponent from 'pages/dashboard/organiser/OrganisersTasksListPageComponent'

import ClientClosureListPageComponent from 'pages/dashboard/clients/ClientClosureListPageComponent'
import ClientClosureViewPageComponent from 'pages/dashboard/clients/ClientClosureViewPageComponent'
import ClientsInformationRequests from 'pages/dashboard/clients/ClientsInformationRequests'

import AccountsListPageComponent from 'pages/dashboard/backoffice-accounts/AccountListPageComponent'
import BackofficeAccountViewPageComponent from 'pages/dashboard/backoffice-accounts/BackofficeAccountViewPageComponent'
import BackofficeAccountNewPageComponent from 'pages/dashboard/backoffice-accounts/BackofficeAccountNewPageComponent'
import XeondaRecordIdsListPageComponent from 'pages/dashboard/backoffice-accounts/XeondaRecordIdsListPageComponent'
import BackofficeUpdateCSVPageComponent from 'pages/dashboard/backoffice-accounts/BackofficeUpdateCSVPageComponent'

import EmailSendFromCsvComponent from 'pages/dashboard/documents/EmailSendFromCsv'

import EmailCustomTemplatesListPageComponent from 'pages/dashboard/email-custom-templates/EmailCustomTemplatesListPageComponent'
import EmailCustomTemplatesViewPageComponent from 'pages/dashboard/email-custom-templates/EmailCustomTemplatesViewPageComponent'

import TeamListPageComponent from 'pages/dashboard/teams/TeamListPageComponent'
import TeamViewPageComponent from 'pages/dashboard/teams/TeamViewPageComponent'
import TeamNewPageComponent from 'pages/dashboard/teams/TeamNewPageComponent'

import TradingTransactionsListPageComponent from 'pages/dashboard/trading-transactions/TradingTransactionsListPageComponent'
import TradingTransactionsViewPageComponent from 'pages/dashboard/trading-transactions/TradingTransactionsViewPageComponent'
import WalletTransactionViewPageComponent from 'pages/dashboard/trading-commission/WalletTransactionViewPageComponent'

import AgentsListPageComponent from 'pages/dashboard/agents/AgentsListPageComponent'
import AgentsViewPageComponent from 'pages/dashboard/agents/AgentsViewPageComponent'

import TradingTransactionDetailsDeposit from 'pages/dashboard/trading/TradingTransactionDetailsDeposit'
import TradingTransactionDetailsWithdrawal from 'pages/dashboard/trading/TradingTransactionDetailsWithdrawal'
import AccountFinancialAccounts from 'pages/dashboard/account-financial-accounts/AccountFinancialAccounts'
import AccountFinancialAccount from 'pages/dashboard/account-financial-accounts/account/Account'

// import {dashboardHOC} from './operations'
import {dashboardHOC} from 'hocs'

export const ROUTES = [
  {
    path: '/dashboard',
    title: 'Dashboard Page',
    Component: DashboardPageComponent
  },
  {
    path: '/clients/new',
    title: 'Client Management',
    subTitle: 'Create a New Client Record',
    Component: ClientNewPageComponent
  },
  {
    path: '/clients/new-bulk',
    title: 'New Record (Bulk)',
    subTitle: 'Create New Client Records',
    Component: ClientNewBulkPageComponent
  },
  {
    path: '/clients/new-csv',
    title: 'New Record [CSV]',
    subTitle: 'Create New Client Records',
    Component: ClientNewCSVPageComponent
  },
  {
    path: routes.clientsList,
    Component: ClientListPageComponent,
    title: 'Client List'
  },
  {
    path: '/clients/view/:client_id',
    title: '-',
    Component: ClientViewPageComponent
  },
  {
    path: '/clients/watching',
    Component: ClientWatchingPage,
    title: 'Watching',
    subTitle: 'Clients List'
  },
  {
    path: '/clients/financial-accounts',
    Component: ClientsInformationRequests,
    title: 'Financial Accounts',
    subTitle: 'Clients Financial Accounts'
  },
  {
    path: '/clients/information-requests/list',
    Component: ClientsInformationRequests,
    title: 'Information Requests',
  },
  {
    path: routes.clientsInformationRequestView(),
    Component: informationRequest.View,
    title: 'Information Request Details',
  },
  {
    path: '/trading-commission/clients',
    Component: TradingCommissionClientListPageComponent,
    title: 'Client Management',
    subTitle: 'Client List'
  },
  {
    path: '/trading-commission/wallets',
    Component: TradingCommissionWalletsListPageComponent,
    title: 'Wallet List',
  },
  {
    path: '/trading-commission/wallets/transactions/id/:walletTransactionID',
    Component: WalletTransactionViewPageComponent,
    title: 'Transaction Management',
  },
  {
    path: '/trading-commission/wallets/transactions',
    Component: TradingCommissionGeneralWalletTransactionsListPageComponent,
    title: 'Transaction Management',
    subTitle: 'Transaction List'
  },
  {
    path: '/trading-commission/wallets/id/:walletID',
    Component: WalletViewPageComponent,
    title: 'Wallet Management',
  },
  {
    path: '/agents/list',
    Component: AgentsListPageComponent,
    title: 'Agent Management',
    subTitle: 'Current Agent List'
  },
  {
    path: '/agents/view/:agentID',
    Component: AgentsViewPageComponent,
    title: 'Agent Management',
    subTitle: 'Agent View'
  },
  {
    path: '/teams/list',
    Component: TeamListPageComponent,
    title: 'Team Management',
    subTitle: 'Current Team List'
  },
  {
    path: '/teams/view/:teamID',
    Component: TeamViewPageComponent,
    title: 'Team Management',
    subTitle: 'Team View'
  },
  {
    path: '/teams/new',
    Component: TeamNewPageComponent,
    title: 'Team Management',
    subTitle: 'Create a New Team Record'
  },
  {
    path: '/staff/new',
    Component: StaffNewPageComponent,
    title: 'Staff Management',
    subTitle: 'Create a New Staff Record'
  },
  {
    path: '/staff/list',
    Component: StaffListPageComponent,
    title: 'Staff Management',
    subTitle: 'Current Staff List'
  },
  {
    path: '/reports/financial/profit-loss',
    Component: ProfitLoss,
    title: 'Reports',
  },
  {
    path: '/reports/derivatives/derivative-company-statement',
    Component: Derivatives,
    title: 'Reports',
  },
  {
    path: '/reports/positions/hourly',
    Component: HourlyPositions,
    title: 'Reports',
  },
  {
    path: '/reports/positions/daily',
    Component: DailyPositions,
    title: 'Reports',
  },
  {
    path: '/reports/dashboard',
    Component: Dashboard,
    title: 'Reports',
    subTitle: 'Dashboard'
  },
  {
    path: '/reports/information-requests/metrics',
    Component: InformationRequestMetrics,
    title: 'Reports',
  },
  {
    path: '/reports/information-requests/leads-without-requests',
    Component: ListWithoutInfoReq,
    title: 'Reports',
  },
  {
    path: '/reports/information-requests/outstanding-requests',
    Component: OutstandingRequests,
    title: 'Reports',
  },
  {
    path: '/reports/clients/client-status-metrics',
    Component: ClientStatusMetrics,
    title: 'Reports',
  },
  {
    path: '/reports/leads/lead-status-metrics',
    Component: LeadStatusMetrics,
    title: 'Reports',
  },
  {
    path: '/reports/management/metrics',
    Component: AccountMetrics,
    title: 'Reports',
    subTitle:  'Account Metrics'
  },
  {
    path: '/staff/view/:id',
    Component: StaffViewPageComponent,
    title: 'Staff Management',
    subTitle: 'View and update your Staff Details'
  },
  {
    path: routes.tradingAccountsView(),
    Component: TradingAccountsView,
    title: 'Account Details',
    subTitle: 'View Trading Account Details'
  },
  {
    path: routes.tradingAccounts,
    Component: TradingAccounts,
    title: 'Trading Accounts',
    subTitle: 'View Trading Account List'
  },
  {
    path: '/trading/commissions-full',
    Component: TradingCommissionsFull,
    title: 'Commissions Full',
    subTitle: 'CTIN Trader Commission Report - Full'
  },
  {
    path: routes.tradingTransactions2,
    Component: TradingTransactionsListPageComponent,
    title: 'Trading Transactions 2 List',
    subTitle: 'Trading Transactions 2 List'
  },
  {
    path: routes.tradingTransactions2View(),
    Component: TradingTransactionsViewPageComponent,
    title: 'Trading Transaction Management',
    subTitle: 'View Transaction Details'
  },
  {
    path: '/identification/list',
    Component: identification.List,
    title: 'Identification List',
    subTitle: 'Identification List'
  },
  {
    path: '/identification/view/:id',
    Component: identification.View,
    title: 'Identification View',
    subTitle: 'View Identification Details'
  },
  {
    path: '/identification/new',
    Component: identification.New,
    title: 'Identification New ',
    subTitle: 'Identification New '
  },
  {
    path: '/trading/commissions-sum',
    Component: TradingCommissionsSum,
    title: 'Commissions Sum',
    subTitle: 'CTIN Trader Commission Report - Sum'
  },
  {
    path: '/trading/transactions',
    Component: TradingTransactions,
    title: 'Trading Transactions',
    subTitle: 'Trading Transactions List'
  },
  {
    path: routes.tradingTransactionsDeposit(),
    Component: TradingTransactionDetailsDeposit,
    title: 'Trading Transactions',
    subTitle: 'View Trading Transactions Details'
  },
  {
    path: '/trading/transactions/:id/withdrawal',
    Component: TradingTransactionDetailsWithdrawal,
    title: 'Trading Transactions',
    subTitle: 'View Trading Transactions Details'
  },
  {
    path: '/account/new',
    Component: AccountNewPageComponent,
    title: 'Account Management',
    subTitle: 'Create a New Account Record'
  },
  {
    path: routes.documentsWordTemplates,
    Component: documents.wordTemplates.List,
    title: 'Word Templates',
    subTitle: 'Word templates list'
  },
  {
    path: routes.documentsWordTemplatesNew,
    Component: documents.wordTemplates.New,
    title: 'Create word template',
  },
  {
    path: '/documents/digisign/templates/list',
    Component: DocumentDigisignTemplatesListPageComponent,
    title: 'DigiSign Templates'
  },
  {
    path: '/documents/digisign/templates/id/:templateId',
    Component: DocumentDigisignTemplatesSummary,
    title: 'View Document',
  },
  {
    path: routes.documentsNew,
    Component: DocumentNewPageComponent,
    title: 'New Document',
  },
  {
    path: routes.documentsView(),
    Component: documents.Summary,
    title: 'View Document',
  },
  {
    path: '/documents/emails/csv',
    Component: EmailSendFromCsvComponent,
    title: 'Email Send From CSV',
  },
  {
    path: '/clients/portalUsers',
    Component: UserClientListPageComponent,
    title: 'User Client List',
  },
  {
    path: '/clients/accountClosures/list',
    Component: ClientClosureListPageComponent,
    title: 'Account Closures',
    subTitle: 'View all account closure records'
  },
  {
    path: routes.clientDocWList(),
    Component: clients.wordDocuments.List,
    title: 'DocW List',
    subTitle: 'Create documents based on templates'
  },
  {
    path: '/clients/accountClosures/view/:closureRecordId',
    Component: ClientClosureViewPageComponent,
    title: 'Account Closures (Clients)',
    subTitle: 'View account closure records details'
  },
  {
    path: '/organiser/task-list',
    Component: OrganiserListPageComponent,
    title: 'Organiser - Tasks',
    subTitle: 'Global Task List'
  },
  {
    path: '/organiser/my-tasks',
    Component: OrganisersTasksListPageComponent,
    title: 'Organiser - Tasks',
    subTitle: 'My Task List'
  },
  {
    title: 'Details',
    path: '/clients/portalUsers/view/:userClient_id',
    Component: UserClientViewPageComponent
  },
  {
    path: '/financial/account-financial-accounts',
    Component: AccountFinancialAccounts,
    title: 'Financial Accounts',
  },
  {
    path: '/financial/account-financial-account/:id',
    Component: AccountFinancialAccount,
    title: 'Financial Account Details',
  },
  {
    path: routes.verificationsNew(),
    Component: verifications.New,
    title: 'Verification Management',
    subTitle: 'Create a New Verification Record'
  },
  {
    path: routes.verificationsView(),
    Component: VerificationSummary,
    title: 'Verification Management',
    subTitle: 'Verification Details'
  },
  {
    path: '/settings/account',
    Component: AccountViewPageComponent,
    title: 'Account Management',
    subTitle: 'Page description goes here'
  },
  {
    path: '/settings/trading-platforms',
    Component: TradingPlatformsListPageComponent,
    title: 'Trading Platforms Management',
    subTitle: 'Trading Platforms List'
  },
  {
    path: '/settings/trading-platforms/:id',
    Component: TradingPlatformsViewPageComponent,
    title: 'Trading Platforms Management',
    subTitle: 'View and update your Trading Platforms Details'
  },
  {
    title: 'User Profile',
    path: '/settings/user-profile',
    Component: UserProfilePageComponent
  },
  {
    path: '/settings/currencies',
    Component: CurrenciesListPageComponent,
    title: 'Currencies Management',
    subTitle: 'Currencies List'
  },
  {
    path: '/settings/journals',
    Component: JournalsListPage,
    title: 'Journals Management',
    subTitle: 'A complete list of journals in this account'
  },
  {
    path: '/settings/system-tools',
    Component: SystemToolsPageComponent,
    title: 'System Tools',
    subTitle: 'A complete list of System Tools in this account'
  },
  {
    path: '/settings/journals/:journalId',
    Component: JournalViewPageComponent,
    title: 'Journals Management',
    subTitle: 'Journal Detail'
  },
  {
    path: '/settings/doc-check/collections',
    Component: CollectionsListPageComponentContainer,
    title: 'Collections Management',
    subTitle: 'Collections List'
  },
  {
    path: '/settings/doc-checks/:collectionID',
    Component:  CollectionsViewPageComponent,
    title: 'Collections Management',
  },
  {
    path: '/backoffice/accounts/list',
    Component: AccountsListPageComponent,
    title: 'Accounts Management',
    subTitle: 'Accounts List'
  },
  {
    path: '/backoffice/xeonda-record-ids/list',
    Component: XeondaRecordIdsListPageComponent,
    title: 'Xeonda Record IDs',
    subTitle: 'XeondaRecordIds List'
  },
  {
    path: '/backoffice/field-update',
    Component: BackofficeUpdateCSVPageComponent,
    title: 'Field Update',
    subTitle: 'Field Update'
  },

  {
    path: '/backoffice/accounts/view/:id',
    Component: BackofficeAccountViewPageComponent,
    title: 'Account Management',
    subTitle: 'Account Details'
  },
  {
    path: '/backoffice/accounts/new',
    Component: BackofficeAccountNewPageComponent,
    title: 'Account Management',
    subTitle: 'Create a New Account Record'
  },
  {
    path: '/documents/emails/templates',
    Component: EmailCustomTemplatesListPageComponent,
    title: 'Email Custom Templates',
    subTitle: 'Email Custom Templates List'
  },
  {
    path: '/documents/emails/templates/:id',
    Component: EmailCustomTemplatesViewPageComponent,
    title: 'Email Custom Templates Management',
    subTitle: 'Email Custom Templates Details'
  },
].map(({
  title , subTitle , Component, path
}) => ({
  path,
  Component: dashboardHOC({
    title,
    subTitle,
    // TODO
    // eslint-disable-next-line
    // @ts-ignore
    Component
  })
}))
