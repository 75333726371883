import API from '@aws-amplify/api'
import {Button, Col, Row} from 'antd'
import React, {Fragment, useState} from 'react'

import {showNotification} from 'utils'

export const System = ({currentAccountID}) => {
  const [loading, setLoading] = useState(false)
  const OnRun = async () => {
    try {
      setLoading(true)
      await API.get('systemTools', `/clientFilebyToFirstLastName/${currentAccountID}`)
      setLoading(false)
      showNotification('success', 'System Tools', 'Successfully udpated the first and last name of users')
    } catch (error) {
      setLoading(false)
      showNotification('error', 'System Tools', error.message)
    }
  }
  return (
    <Fragment>
      <Row justify='start'>
        <Col xs={12}>
          <Row justify='end' gutter={[12.12]}>
            <Col xs={12}>
              <h4 style={{textTransform: 'uppercase'}}> Click to sync name </h4>
            </Col>
            <Col xs={12} onClick={OnRun}>
              <Button type='primary' htmlType='button' disabled={loading}>
                Run
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}