
export const CUSTOMER_TYPES_OPTIONS = [
  {
    id: 1,
    label: 'Resident',
    riskScore: 1,
  },
  {
    id: 2,
    label: 'Permanent Resident',
    riskScore: 2,
  },
  {
    id: 3,
    label: 'Foreigner / Legal Person / Legal Arrangement',
    riskScore: 3,
  },
]

export const SOCIAL_STATUS_OPTIONS = [
  {
    id: 1,
    label: 'Non-PEP',
    riskScore: 1,
  },
  {
    id: 15,
    label: 'Local PEP / Foreign PEP',
    riskScore: 15,
  },
]

export const SOURCE_OF_WEALTH_OPTIONS = [
  {
    id: '1a',
    label: 'Employment – Salaries',
    riskScore: 1,
  },
  {
    id: '1b',
    label: 'Employment - Retirement Income',
    riskScore: 1,
  },
  {
    id: '3c',
    label: 'Employment – Self-employed',
    riskScore: 3,
  },
  {
    id: '3d',
    label: 'Business Earnings – Earnings',
    riskScore: 3,
  },
  {
    id: '5e',
    label: 'Inheritance/Family Gifts – Inheritance',
    riskScore: 5,
  },
  {
    id: '5f',
    label: 'Insurance – Proceeds/Settlement',
    riskScore: 5,
  },
  {
    id: '1g',
    label: 'Settlement - Divorce Settlement',
    riskScore: 1,
  },
  {
    id: '3h',
    label: 'Investment - Income/Returns',
    riskScore: 3,
  },
  {
    id: '1i',
    label: 'Winnings – Government Lottery',
    riskScore: 1,
  },
  {
    id: '3j',
    label: 'Winnings - Non-Government Lottery',
    riskScore: 3,
  },
  {
    id: '1k',
    label: 'Earnings - Sales of Business',
    riskScore: 1,
  },
  {
    id: '1l',
    label: 'Earnings - Sales of Property',
    riskScore: 1,
  },
  {
    id: '1m',
    label: 'Earnings - Sales of Investment',
    riskScore: 1,
  },
]

export const PURPOSE_OF_ACCOUNT_OPTIONS = [
  {
    id: 1,
    label: 'Savings',
    riskScore: 1,
  },
  {
    id: 2,
    label: 'Payroll',
    riskScore: 1,
  },
  {
    id: 3,
    label: 'Security',
    riskScore: 1,
  },
  {
    id: 4,
    label: 'Funds Transfer',
    riskScore: 1,
  },
  {
    id: 5,
    label: 'Investment (Fund, Insurance)',
    riskScore: 1,
  },
  {
    id: 6,
    label: 'Trust',
    riskScore: 1,
  },
  {
    id: 7,
    label: 'Electronic Payment',
    riskScore: 1,
  },
  {
    id: 8,
    label: 'Pension Funds',
    riskScore: 1,
  },
  {
    id: 9,
    label: 'Credit Card',
    riskScore: 1,
  },
  {
    id: 10,
    label: 'Loans / Credit',
    riskScore: 1,
  },
  {
    id: 11,
    label: 'Weapons / Military',
    riskScore: 15,
  },
  {
    id: 12,
    label: 'Multi-Level Marketing',
    riskScore: 15,
  },
  {
    id: 13,
    label: 'Pawnbrokers',
    riskScore: 15,
  },
  {
    id: 14,
    label: 'Political Organizations',
    riskScore: 15,
  },
  {
    id: 15,
    label: 'Precious Metals',
    riskScore: 15,
  },
  {
    id: 16,
    label: 'Adult Entertainment',
    riskScore: 15,
  },
  {
    id: 17,
    label: 'Drug Paraphernalia',
    riskScore: 15,
  },
  {
    id: 18,
    label: 'CBD & Related Products',
    riskScore: 15,
  },
  {
    id: 19,
    label: 'Carbon Credits',
    riskScore: 15,
  },
  {
    id: 20,
    label: 'Crypto Assets',
    riskScore: 15,
  },
  {
    id: 21,
    label: 'Gambling',
    riskScore: 15,
  },
  {
    id: 22,
    label: 'Ponzi / Pyramid Schemes',
    riskScore: 15,
  },
  {
    id: 23,
    label: 'Illegal Goods, Counterfeit Goods, Human Trafficking',
    riskScore: 15,
  },
  {
    id: 24,
    label: 'Mining and Extraction',
    riskScore: 15,
  },
  {
    id: 25,
    label: 'Speculative Trading',
    riskScore: 15,
  },
  {
    id: 26,
    label: 'Non-Permitted Activities',
    riskScore: 15,
  }
]

export const EXPECTED_ACTIVITY_OPTIONS = [
  {
    id: 1,
    label: 'Low Turnover, "<"  6 transactions per month',
    riskScore: 1,
  },
  {
    id: 3,
    label: 'Medium Turnover, 6 to 19 transactions per month',
    riskScore: 3,
  },
  {
    id: 5,
    label: 'High Turnover, ">" 20 transactions per month',
    riskScore: 5,
  },
]

export const NET_WORTH_OPTIONS = [
  {
    id: 1,
    label: '"<" USD 50,000 - Individual/Company',
    riskScore: 1,
  },
  {
    id: 2,
    label: 'USD 50,000 to USD 500,000 - Individual/Company ',
    riskScore: 2,
  },
  {
    id: 4,
    label: 'USD 500,000 and above',
    riskScore: 4,
  },
]

export const TRANSACTIONS_OPTIONS = [
  {
    id: '1a',
    label: 'Face to Face',
    riskScore: 1,
  },
  {
    id: '3b',
    label: 'On Behalf/Through Intermediaries and/or agents',
    riskScore: 3,
  },
  {
    id: '3c',
    label: 'Non- Face-to-Face',
    riskScore: 3,
  },
]