import React from 'react'

import {
  Form,
  InputCheckbox
} from 'components'
import {CommonProps} from '../../types'

const LastStep: React.FC<CommonProps> = ({children, initialValues}) => {
  return (
    <Form
      name='lastStep'
      layout='vertical'
      initialValues={initialValues}
    >
      <InputCheckbox required name='confirm_1' label='verification.confirm' labelValues={{number: 1}} />
      <InputCheckbox required name='confirm_2' label='verification.confirm' labelValues={{number: 2}} />
      <InputCheckbox required name='confirm_3' label='verification.confirm' labelValues={{number: 3}} />
      {children}
    </Form>
  )
}
export default LastStep
