import {
  Record,
  List
} from 'immutable'

import * as types from './types'

import {clientsTypes} from '../clients'

import {
  FileListItemRecord,
  DocumentRecord
} from '../common'
import {CLEAR_STATE} from '../../constants'

import moment from 'moment'

const ReducerRecord = Record({
  entities: [],
  fileList: [],
  email: [],
  docTypeList: [],
  fileIDs: List([]),
  currentDocument: new DocumentRecord({}),
  emailCustomTemplate: [],
  loading: false
})

export default (state = new ReducerRecord(), {
  type,
  payload
}) => {
  switch (type) {
    case types.GET_DOCUMENTS_REQUEST:
    case types.GET_DOCW_REQUEST:
    case types.GET_DOCW_BY_ACCOUNT_REQUEST:
    case types.GET_DIGISIGNTEMPLATES_REQUEST:
    case types.GET_EMAILCUSTOMTEMPLATES_REQUEST:
    case types.GET_EMAILCUSTOMTEMPLATE_REQUEST:
    case types.SAVE_EMAILCUSTOMTEMPLATE_REQUEST:
    case types.GET_DOCTYPE_LIST_REQUEST:
    case clientsTypes.GET_DOCUMENT_REQUEST:
    case clientsTypes.CREATE_NEW_DOCUMENT_REQUEST:
      return state.set('loading', true)

    case types.GET_DOCTYPE_LIST_SUCCESS:
      return state
        .merge({
          loading: false,
          docTypeList: payload
        })

    case types.GET_DOCUMENTS_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.map(({record_created, ...rest}) => ({
            ...rest,
            record_created: record_created ? moment(record_created).format('YYYY-MM-DD HH:mm:ss') : ''
          }))
        })
    case types.GET_DOCW_BY_ACCOUNT_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.map(({record_created, ...rest}) => ({
            ...rest,
            record_created: record_created ? moment(record_created).format('YYYY-MM-DD HH:mm:ss') : ''
          }))
        })
    case types.GET_DOCW_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.map(({record_created, ...rest}) => ({
            ...rest,
            record_created: record_created ? moment(record_created).format('YYYY-MM-DD HH:mm:ss') : ''
          }))
        })
    case types.GET_DIGISIGNTEMPLATES_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.map(({record_created, ...rest}) => ({
            ...rest,
            record_created: record_created ? moment(record_created).format('YYYY-MM-DD HH:mm:ss') : ''
          }))
        })
    case types.GET_EMAILCUSTOMTEMPLATES_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.map(({record_created, ...rest}) => ({
            ...rest,
            record_created: record_created ? moment(record_created).format('YYYY-MM-DD HH:mm:ss') : ''
          }))
        })
    case types.GET_EMAILCUSTOMTEMPLATE_SUCCESS:
      return state
        .merge({
          loading: false,
          emailCustomTemplate: payload,
        })
    case clientsTypes.GET_DOCUMENT_SUCCESS:
      const {
        fileList,
        email,
        document
      } = payload

      const currentDocument = new DocumentRecord(document)
        .set('record_created', moment(document.record_created).format('YYYY-MM-DD HH:mm:ss'))
      return state
        .merge({
          fileList: fileList.map(item => new FileListItemRecord(item)),
          email,
          loading: false,
          currentDocument,
        })
    case types.FILE_UPLOAD_SUCCESS:
      return state.update('fileIDs', ids => ids.push(payload))

    case CLEAR_STATE:
      return state.clear()
    case types.SAVE_EMAILCUSTOMTEMPLATE_SUCCESS:
      return state.merge({loading: false})
    case types.GET_DOCUMENTS_ERROR:
    case types.GET_DIGISIGNTEMPLATES_ERROR:
    case types.GET_EMAILCUSTOMTEMPLATES_ERROR:
    case types.GET_EMAILCUSTOMTEMPLATE_ERROR:
    case types.GET_DOCW_ERROR:
    case types.GET_DOCW_BY_ACCOUNT_ERROR:
    case types.SAVE_EMAILCUSTOMTEMPLATE_ERROR:
    case types.GET_DOCTYPE_LIST_ERROR:
    case clientsTypes.GET_DOCUMENT_ERROR:
    case clientsTypes.CREATE_NEW_DOCUMENT_SUCCESS:
    case clientsTypes.CREATE_NEW_DOCUMENT_ERROR:
      return state.set('loading', false)
    default:
      return state
  }
}