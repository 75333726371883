import React from 'react'
import {useIntl} from 'hooks'
import {
  Form,
  Input,
  FormItemProps,
  InputProps
} from 'antd'

interface InputTextProps extends Omit<FormItemProps, 'status'>, Pick<InputProps, 'status'> {
  placeholder?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  formItemClassName?: string;
}

const InputText: React.FC<InputTextProps> = (
  {
    name,
    placeholder = '',
    rules = [],
    required,
    disabled,
    label,
    formItemClassName,
    status,
    preserve
  }
) => {
  const intl = useIntl()

  return (
    <Form.Item
      label={label && intl.formatMessage({id: label})}
      normalize={(value) => value.replace(/\s{2,}/g, ' ')}
      name={name}
      className={formItemClassName}
      preserve={preserve}
      rules={[
        {
          required,
          message: "Can't be blank"
        },
        ...rules
      ]}
    >
      <Input
        status={status}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete='new-password'
      />
    </Form.Item>
  )
}
export default InputText