import {Record} from 'immutable'

import * as types from './types'

import {
  INPUT_CHANGE,
  CLEAR_STATE
} from '../../constants'

const LeadsData = Record({
  first_name: '',
  id: null,
  last_name: '',
  email: '',
  account_id: '',
  tel_work: '',
  tel_home: '',
  tel_mobile: '',
  street_address_region: null,
  street_address_line_1: null,
  street_address_line_2: null,
  street_address_suburb: null,
  street_address_postcode: null,
  street_address_country: null,
  postal_address_region: null,
  postal_address_line_1: null,
  postal_address_line_2: null,
  postal_address_suburb: null,
  postal_address_state: null,
  postal_address_postcode: null,
  postal_address_country: null
})
const ReducerRecord = Record({
  entities: [],
  loading: false,
  leadsData: new LeadsData(),
  leadsList: [],
  leadsDetails: {},
  journals: [],
  duplicateEmail: false,
  streetCodes: {streetTypes: []}
})

export default (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.CREATE_LEAD_REQUEST:
    case types.FETCH_LEADS_REQUEST:
    case types.GET_LEAD_REQUEST:
    case types.SAVE_LEAD_REQUEST:
    case types.STREET_TYPE_REQUEST:
    case types.GET_JOURNAL_BY_LEAD_REQUEST:
      return state.set('loading', true)

    case types.CREATE_LEAD_ERROR:
    case types.FETCH_LEADS_ERROR:
    case types.GET_LEAD_ERROR:
    case types.SAVE_LEAD_ERROR:
    case types.SAVE_LEAD_SUCCESS:
    case types.STREET_TYPE_ERROR:
    case types.GET_JOURNAL_BY_LEAD_ERROR:
      return state.set('loading', false)

    case types.CREATE_LEAD_SUCCESS:
      return state.merge({
        entities: [...state.entities, payload],
        loading: false
      })
    case types.STREET_TYPE_SUCCESS:
      return state.merge({
        streetCodes: payload,
        loading: false
      })
    case types.CHECK_LEADS_EMAIL_REQUEST:
      return state.merge({
        loading: true,
        duplicateEmail: false
      })
    case types.CHECK_LEADS_EMAIL_SUCCESS:
      return state.set('loading', false)
    case types.CHECK_LEADS_EMAIL_ERROR:
      return state.merge({
        loading: false,
        duplicateEmail: true
      })
    case types.FETCH_LEADS_SUCCESS:
      return state.merge({
        leadsList: payload,
        loading: false
      })
    case types.GET_LEAD_SUCCESS:
      return state.merge({
        leadsDetails: payload,
        loading: false
      })
    case types.GET_JOURNAL_BY_LEAD_SUCCESS:
      return state.merge({
        loading: false,
        journals: payload
      })
    case INPUT_CHANGE:
      return state.setIn(['leadsData', payload.inputName], payload.inputValue)
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}