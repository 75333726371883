import {Amplify} from 'aws-amplify'
import {ENDPOINTS} from 'consts'

type ConfigureAmplify = (configType: 'staff' | 'client') => void;

const configureAmplify: ConfigureAmplify = (configType) => {
  const config = {
    REGION: process.env.REACT_APP_REGION,

    USER_POOL_ID: process.env.REACT_APP_STAFF_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_STAFF_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID,
  }

  if (configType === 'client') {
    config.USER_POOL_ID = process.env.REACT_APP_CLIENTS_USER_POOL_ID
    config.APP_CLIENT_ID = process.env.REACT_APP_CLIENTS_APP_CLIENT_ID
    config.IDENTITY_POOL_ID = process.env.REACT_APP_CLIENTS_IDENTITY_POOL_ID
  }

  Amplify.configure({
    Auth: {
      // mandatorySignIn: true,
      region: config.REGION,
      userPoolId: config.USER_POOL_ID,
      identityPoolId: config.IDENTITY_POOL_ID,
      userPoolWebClientId: config.APP_CLIENT_ID
    },
    Storage: {
      region: config.REGION,
      bucket: process.env.REACT_APP_BUCKET,
      identityPoolId: config.IDENTITY_POOL_ID
    },
    API: {endpoints: ENDPOINTS}
  })
  
}

export default configureAmplify
