import React from 'react'

import {
  Row,
  Col,
  Button,
  Form,
  Input
} from 'antd'

import {columnSize} from '../../common'

const EmailCustomTemplatesSummary = (
  {
    id,
    templateName,
    fieldList,
    emailContent,
    saveEmailCustomTemplate,
  }
) => {
  return (
    <Form
      layout='vertical'
      onFinish={(values) => saveEmailCustomTemplate(values)}
      initialValues={{
        id,
        templateName,
        fieldList,
        emailContent,
      }}
    >
      <Row gutter={[24, 0]}>
        <Col {...columnSize}>
          <Form.Item
            label='ID'
            name='id'
          >
            {id}
          </Form.Item>
          <Form.Item
            label='Template Name'
            name='templateName'
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label='Field List'
            name='fieldList'
          >
            <Input.TextArea rows={8} />
          </Form.Item>
        </Col>
        <Col {...columnSize}>
          <Form.Item
            label='Email Content'
            name='emailContent'
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='end'>
        <Button type='primary' htmlType='submit'>
          Save Record
        </Button>
      </Row>
    </Form>
  )
}

export default EmailCustomTemplatesSummary
