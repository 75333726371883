import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  showNotification,
  API
} from 'utils'
import _ from 'lodash'
import {
  Row,
  Col,
  Form,
  Card,
  Tooltip,
  Button,
  Table,
  Tag,
  Input,
  Checkbox
} from 'antd'

import {InputSelect} from 'components'

import {CreateJournal} from 'common'

import {useDispatch} from 'react-redux'
import {staffsActions} from 'configs/ducks/staffs'
import {FormattedMessage} from 'react-intl'

import {constants} from './duck'

const ScoreValue = ({name, options}) => (
  <Form.Item label='Score' dependencies={[name]}>
    {({getFieldValue}) => {
      const countryId = getFieldValue(name)
      const riskScore = options.find(opt => opt.id === countryId)?.riskScore
      return <span style={{color: riskScore > 25 ? 'red' : ''}}>{riskScore}</span>
    }}
  </Form.Item>
)

const ClientRisk = ({
  form,
  clientRecord,
  currentAccountId,
  staffId,
  clientId,
  asyncFunction,
}) => {
  const dispatch = useDispatch()

  const [state, setState] = useState({risk_nickname: null})

  const [risk_customer_type_notes, setRiskCustomerTypeNote] = useState('')

  const [risk_social_status_notes, setRiskSocialStatusNote] = useState('')

  const [risk_country_nationality_notes, setRiskCountryNationalityNote] = useState('')

  const [risk_sourceofwealth_notes, setRiskSourceofwealthNote] = useState('')

  const [risk_transactions_notes, setRiskTransactionsNote] = useState('')

  const [risk_account_purpose_notes, setRiskAccountPurposeNote] = useState('')

  const [risk_expected_activity_notes, setRiskExpectedActivityNote] = useState('')

  const [risk_networth_notes, setRiskNetworthNote] = useState('')
  const [risScoreListOptions, setRiskScoreOptions] = useState([])

  const [complianceFlag, setComplianceFlag] = useState(clientRecord?.complianceFlag)

  const prefillType = '2'
  const prefillTitle = 'Staff changed client risk rating'
  const prefillMemo = 'The client’s risk scores were changed to ...'

  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = bool => setIsModalVisible(bool)

  useEffect(() => {
    API.get('clients-risk', `/countries/riskScoreList/${currentAccountId}`)
      .then(({payload: resp}) => {
        setRiskScoreOptions(resp)
      })
  }, [currentAccountId])

  const columns = [
    {
      title: 'Overall risk indicator',
      dataIndex: 'indicator',
      key: 'indicator',
      render: (_, { indicator }) => {
        if (indicator === 'Low') {
          return (
            <Tag color='green' key={indicator}>
              {indicator.toUpperCase()}
            </Tag>
          )
        }

        if (indicator === 'Medium') {
          return (
            <Tag color={'blue'} key={indicator}>
              {indicator.toUpperCase()}
            </Tag>
          )
        }

        if (indicator === 'High') {
          return (
            <Tag color={'volcano'} key={indicator}>
              {indicator.toUpperCase()}
            </Tag>
          )
        }

        if (indicator === 'Automatic Decline') {
          return (
            <Tag color={'red'} key={indicator}>
              {indicator.toUpperCase()}
            </Tag>
          )
        }
      },
    },
    {
      title: 'Total score',
      dataIndex: 'range',
      key: 'range',
    },
    {
      title: 'Decision to Onboard',
      dataIndex: 'description',
      key: 'description',
    },
  ]
  const data = [
    {
      key: '1',
      indicator: 'Low',
      range: 'Up to 13',
      description: 'Yes',
    },
    {
      key: '2',
      indicator: 'Medium',
      range: '14-20',
      description: 'Yes, but with monitoring',
    },
    {
      key: '3',
      indicator: 'High',
      range: '21 to 25',
      description: 'Yes, but with close monitoring',
    },
    {
      key: '4',
      indicator: 'Automatic Decline',
      range: '>26',
      description: 'No',
    },
  ]

  useEffect(() => {
    if (currentAccountId) {
      dispatch(staffsActions.getStaffList(currentAccountId))
    }
  }, [currentAccountId, dispatch])

  useEffect(() => {
    form.setFieldsValue({risk_customer_type_notes: risk_customer_type_notes})
    form.setFieldsValue({risk_social_status_notes: risk_social_status_notes})
    form.setFieldsValue({risk_country_nationality_notes: risk_country_nationality_notes})

    form.setFieldsValue({risk_sourceofwealth_notes: risk_sourceofwealth_notes})
    form.setFieldsValue({risk_transactions_notes: risk_transactions_notes})
    form.setFieldsValue({risk_account_purpose_notes: risk_account_purpose_notes})
    form.setFieldsValue({risk_expected_activity_notes: risk_expected_activity_notes})
    form.setFieldsValue({risk_networth_notes: risk_networth_notes})
    
    form.setFieldsValue({complianceFlag: complianceFlag})
    
    if (clientRecord) {

      setRiskCustomerTypeNote(clientRecord?.risk_customer_type_notes ? clientRecord.risk_customer_type_notes : '')
      setRiskSocialStatusNote(clientRecord?.risk_social_status_notes ? clientRecord.risk_social_status_notes : '')
      setRiskCountryNationalityNote(clientRecord?.risk_country_nationality_notes ? clientRecord.risk_country_nationality_notes : '')
      setRiskSourceofwealthNote(clientRecord?.risk_sourceofwealth_notes ? clientRecord.risk_sourceofwealth_notes : '')
      setRiskTransactionsNote(clientRecord?.risk_transactions_notes ? clientRecord.risk_transactions_notes : '')
      setRiskAccountPurposeNote(clientRecord?.risk_account_purpose_notes ? clientRecord.risk_account_purpose_notes : '')
      setRiskExpectedActivityNote(clientRecord?.risk_expected_activity_notes ? clientRecord.risk_expected_activity_notes : '')
      setRiskNetworthNote(clientRecord?.risk_networth_notes ? clientRecord.risk_networth_notes : '')
      setState((prev) => ({
        ...prev,
        complianceFlag
      }))
    }
  }, [
    clientRecord,
    risk_customer_type_notes,
    risk_social_status_notes,
    risk_country_nationality_notes,
    risk_sourceofwealth_notes,
    risk_transactions_notes,
    risk_account_purpose_notes,
    risk_expected_activity_notes,
    risk_networth_notes,
    state.complianceFlag
  ])

  const onFinish = async values => {
    try {
      setComplianceFlag(values.complianceFlag)
      await API.post('journals', '/create', {
        body: {
          ...values,
          staffId
        }
      })
      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Journals', error.message)
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  try {
    if (!state.risk_nickname && !state.is_loaded){
      API.get('clients',`/get-by-id/${clientRecord.id}`).then(
        res => {
          // console.log(res.payload)
          setState(
            {
              risk_nickname: res.payload.risk_nickname,
              is_loaded: true,
              deletedFlag: res.payload?.deletedFlag
            }
          )
          return res.payload.risk_nickname
        }
      )
    }
    // console.log(state)
    // console.log(clientRecord)
  } catch (e) {
    console.log(e)
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <p>Total Risk Score:</p>
          <Form.Item shouldUpdate>
            {({getFieldsValue}) => {
              const values = getFieldsValue([
                'risk_customer_type',
                'risk_social_status',
                'risk_country_nationality',
                'risk_sourceofwealth',
                'risk_transactions',
                'risk_account_purpose',
                'risk_expected_activity',
                'risk_networth',
                'complianceFlag'
              ])
              const total = _.reduce(values, (result, value, key) => {
                let val = value

                if (key === 'risk_country_nationality') {
                  val = risScoreListOptions.find(item => item.id === value)?.riskScore || 0
                }

                if (key === 'risk_transactions') {
                  val = constants.TRANSACTIONS_OPTIONS.find(item => item.id === value)?.riskScore || 0
                }

                if (key === 'risk_sourceofwealth') {
                  val = constants.SOURCE_OF_WEALTH_OPTIONS.find(item => item.id === value)?.riskScore || 0
                }

                if (key === 'risk_account_purpose') {
                  val = constants.PURPOSE_OF_ACCOUNT_OPTIONS.find(item => item.id === value)?.riskScore || 0
                }

                if (key === 'complianceFlag' && values?.complianceFlag) {
                  val = 0
                  result = 21
                } else if (key === 'complianceFlag') {
                  val = 0
                }

                return result + val
              }, 0)

              if (complianceFlag) {
                return (
                  <Tag
                    color='volcano'
                  > 21 (High) </Tag>
                )
              }

              return (
                <Tag
                  color={`${
                    total === 0
                      ? 'purple'
                      : total <= 13
                        ? 'green'
                        : total >= 14 && total <= 20
                          ? 'blue'
                          : total >= 21 && total <= 25
                            ? 'volcano'
                            : 'red'
                  }`
                  }
                >
                  {`${total} ` +
                        `(${
                          total === 0
                            ? 'No Data'
                            : total <= 13
                              ? 'Low'
                              : total >= 14 && total <= 20
                                ? 'Medium'
                                : total >= 21 && total <= 25
                                  ? 'High'
                                  : 'Declined'
                        })`}
                </Tag>
              )
            }}
          </Form.Item>

        </Col>
        <Col xs={12}>
          <Table columns={columns} dataSource={data} />
        </Col>
      </Row>
      <Row gutter={[0, 0]}>
        <Col xs={12}>
          <Card title='Customer risk' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.risk.customerType'
                  name='risk_customer_type'
                  options={constants.CUSTOMER_TYPES_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_customer_type'
                  options={constants.CUSTOMER_TYPES_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item name='risk_customer_type_notes'>
                    <Input
                      value={risk_customer_type_notes}
                      onChange={event => {
                        setRiskCustomerTypeNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.risk.socialStatus'
                  name='risk_social_status'
                  options={constants.SOCIAL_STATUS_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_social_status'
                  options={constants.SOCIAL_STATUS_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_social_status_notes'>
                    <Input
                      value={'risk_social_status_notes'}
                      onChange={event => {
                        setRiskSocialStatusNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>

          <Card title='Country Risk' bordered={false}>
            <Row gutter={[12, 4]} align='center'>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.risk.countryNationality'
                  name='risk_country_nationality'
                  options={risScoreListOptions}
                  getOptionProps={(option) => ({
                    children: `${option.countryName} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_country_nationality'
                  options={risScoreListOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_country_nationality_notes'>
                    <Input
                      value={'risk_country_nationality_notes'}
                      onChange={event => {
                        setRiskCountryNationalityNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>

          <Card title='Source of Wealth' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.financial.sourceOfWealth'
                  name='risk_sourceofwealth'
                  options={constants.SOURCE_OF_WEALTH_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_sourceofwealth'
                  options={constants.SOURCE_OF_WEALTH_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_sourceofwealth_notes'>
                    <Input
                      value={'risk_sourceofwealth_notes'}
                      onChange={event => {
                        setRiskSourceofwealthNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>

          <Card title='Transaction or Distribution Channel' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.risk.transactionOrDistributionChannel'
                  name='risk_transactions'
                  options={constants.TRANSACTIONS_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_transactions'
                  options={constants.TRANSACTIONS_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_transactions_notes'>
                    <Input
                      value={'risk_transactions_notes'}
                      onChange={event => {
                        setRiskTransactionsNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12}>
          <Card title='Purpose of Account' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.risk.purposeOfAccount'
                  name='risk_account_purpose'
                  options={constants.PURPOSE_OF_ACCOUNT_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_account_purpose'
                  options={constants.PURPOSE_OF_ACCOUNT_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_account_purpose_notes'>
                    <Input
                      value={risk_account_purpose_notes}
                      onChange={event => {
                        setRiskAccountPurposeNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>

          <Card title='Expected Account Activity' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='client.risk.expectedAccountActivity'
                  name='risk_expected_activity'
                  options={constants.EXPECTED_ACTIVITY_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_expected_activity'
                  options={constants.EXPECTED_ACTIVITY_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_expected_activity_notes'>
                    <Input
                      value={risk_expected_activity_notes}
                      onChange={event => {
                        setRiskExpectedActivityNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>

          <Card title='Net Worth' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col xs={20}>
                <InputSelect
                  required
                  label='data_clients.net_worth'
                  name='risk_networth'
                  options={constants.NET_WORTH_OPTIONS}
                  getOptionProps={(option) => ({
                    children: `${option.label} ${option.riskScore}`,
                    value: option.id
                  })}
                />
              </Col>
              <Col xs={4}>
                <ScoreValue
                  name='risk_networth'
                  options={constants.NET_WORTH_OPTIONS}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Tooltip title='Enter any optional notes'>
                  <Form.Item style={{marginBottom: 0}} name='risk_networth_notes'>
                    <Input
                      value={risk_networth_notes}
                      onChange={event => {
                        setRiskNetworthNote(event.target.value)
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
          </Card>
          <Card title='Head of Compliance Approval' bordered={false}>
            <Row gutter={[12, 4]}>
              <Col span={12}>
                <Form.Item name="complianceFlag" valuePropName="checked">
                  <Checkbox
                    checked={state.complianceFlag}
                    onChange={event => {
                      setComplianceFlag(event.target.checked)
                    }}>
                        Yes, high risk but with close monitoring.
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify='end'>
        <Button
          type='primary'
          htmlType='submit'
          onClick={() => toggleModal(true)}
        >
          <FormattedMessage id='components.save_record' />
        </Button>
      </Row>
      <CreateJournal
        clientId={clientId}
        prefillType={prefillType}
        prefillTitle={prefillTitle}
        prefillMemo={prefillMemo}
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onFinish={onFinish}
      />
    </Fragment>
  )
}

export default ClientRisk
