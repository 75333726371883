import * as React from 'react'
import {
  Input,
  Form,
  FormItemProps
} from 'antd'
import {FormattedMessage} from 'react-intl'

interface InputTextAreaProps extends Pick<FormItemProps, 'name' | 'required'> {
  label?: string;
  name: string;
  rows?: number;
  autoSize?: {
    minRows?: number
    maxRows: number
  }
}

const InputTextArea: React.FC<InputTextAreaProps> = ({
  label, name, required, rows, autoSize
}) => {
  return (
    <Form.Item
      name={name}
      label={label && <FormattedMessage id={label} />}
      rules={[
        {
          required,
          message: <FormattedMessage id='validation.required' />
        },
      ]}
    >
      <Input.TextArea rows={rows} autoSize={autoSize} />
    </Form.Item>
  )
}

export default InputTextArea
