import React, {useState} from 'react'
import {setLanguageSelectId} from 'configs/actions/language'

import {
  appStateDuck,
  routes
} from 'configs'

import {
  Dropdown,
  Row,
  Col,
  Layout,
  Space,
  ExtendedAutoComplete,
  Tag,
  NavLink,
  InputSelect,
  Button,
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  notification
} from 'components'
import {
  useDispatch,
  useSelector
} from 'hooks'
import {
  Account,
  Staff,
  Client,
  UserStaff,
  TradingAccount
} from 'types'
import {languages} from 'consts'

import ReactCountryFlag from 'react-country-flag'

import {createUseStyles} from 'react-jss'
import {API} from 'utils'
import _ from 'lodash'

const useStyles = createUseStyles({
  staffMenuMargin: {marginLeft: [[24], '!important']},
  '@media screen and (max-width: 768px)': {staffMenuMargin: {marginLeft: [[0], '!important']}},
  button: {
    padding: 0,
    height: 'auto'
  },
  switchUserCol: {
    display: 'flex',
    alignItems: 'center'
  },
  autocompleteItem: {color: 'black'},
  header: {
    padding: '0 24px',
    background: '#fff',
    height: 'auto',
    overflowY: 'auto',
  },
})

interface TradingAccounts extends TradingAccount {
  tradingPlatformType: string;
}

const Header = () => {
  const classes = useStyles()
  const [state, setState] = useState<{
    loading: boolean;
    clients: Client[];
    tradingAccounts: TradingAccounts[];
  }>({
    clients: [],
    tradingAccounts: [],
    loading: false,
  })
  const dispatch = useDispatch()

  const {
    currentAccountRecord, currentUserRecord, staffAccess, language, currentStaffRecord
  } = useSelector(state => ({
    currentAccountRecord: state.appState.currentAccountRecord as Account,
    currentStaffRecord: state.appState.currentStaffRecord as Staff,
    currentUserRecord: state.appState.currentUserRecord as UserStaff,
    staffAccess: state.appState.staffAccess,
    language: state.language.language_current_ui,
  }))

  const debounced = _.debounce(async value => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true
      }))

      const body = {searchTerm: value.trim()}

      const [clients, tradingAccounts] = await Promise.all([
        API.post<Client[]>('clients', '/global-search', {body}),
        API.post<TradingAccounts[]>('trading-accounts', '/search', {
          body: {
            ...body,
            account_id: currentAccountRecord.id
          }
        })
      ])

      setState(prevState => ({
        ...prevState,
        clients,
        tradingAccounts,
        loading: false
      }))
    } catch (e: any) {
      notification.error({message: e?.response?.data?.error || e.message})
      setState(prevState => ({
        ...prevState,
        loading: false
      }))

    }
  }, 1000)

  return (
    <Layout.Header className={classes.header}>
      <Row justify='space-between'>
        {currentStaffRecord.id && (
          <>
            <Col>
              <ExtendedAutoComplete
                options={[
                  ...state.clients,
                  ...state.tradingAccounts
                ]}
                defaultOpen={false}
                dropdownMatchSelectWidth={600}
                notFoundContent={'Not Client Found'}
                placeholder='Type Client`s ID, Name, Email'
                onChange={value => {
                  if (!value) {
                    setState(prevState => ({
                      ...prevState,
                      clients: [],
                      tradingAccounts: [],
                      loading: false
                    }))
                  } else {
                    debounced(value)
                  }
                }}
                getOptionProps={opt => {
                  const keys = Object.keys(opt)

                  if (keys.includes('trading_platform_account_id')) {
                    const title = `${opt.tradingPlatformUsername} ${opt.tradingPlatformType || ''}`
                    return {
                      value: title,
                      label: (
                        <NavLink to={routes.tradingAccountsView(opt.id)} className={classes.autocompleteItem}>
                          {title}
                        </NavLink>
                      )
                    }
                  }

                  const title = `${opt.id} - ${opt.accountShortName || ''} - ${opt.first_name} ${opt.last_name} ${opt.preferred_first_name || ''} ${opt.preferred_last_name || ''} ${opt.companyName || opt.trust_trustName || ''} ${opt.account_user_id ? ` [Ref ${opt.account_user_id}]` : ''} ${opt.email}`
                    .replace(/\s{2,}/, ' ')

                  return {
                    value: title,
                    label: (
                      <NavLink
                        className={classes.autocompleteItem}
                        to={routes.clientsView({
                          clientID: opt.id,
                          tab: 'summary'
                        })}
                      >
                        {title}
                      </NavLink>
                    )
                  }
                }}
              />
            </Col>

            <Col>
              <Row justify='space-between'>
                {Boolean(currentUserRecord.beta) &&
                      <Col>
                        <Tag color='green'>
                            Beta
                        </Tag>
                      </Col>
                }
                <Col>
                  {Boolean(currentUserRecord.backoffice) &&
                  <Tag color='volcano'>
                    Backoffice
                  </Tag>
                  }
                </Col>
                <Col>
                  <Tag color='blue'>
                    {currentAccountRecord.account_name}
                  </Tag>
                </Col>
                <Col>
                  <InputSelect
                    isFormItem={false}
                    value={language}
                    onChange={value => dispatch(setLanguageSelectId(value))}
                    options={languages}
                    filterOption={(input, option) => {
                      /*
                        <Option>
                          <Space>
                            <ReactCountryFlag />
                            <span>{text}</span>
                          </Space>
                        </Option>
                      * */
                      return option.children.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                    }}
                    getOptionProps={({
                      flag, text, value
                    }) => (
                      {
                        value,
                        children: (
                          <Space>
                            <ReactCountryFlag
                              {...flag}
                              title={text}
                              style={{
                                fontSize: '1.5em',
                                lineHeight: '1.5em'
                              }}
                            />
                            <span>{text}</span>
                          </Space>
                        )
                      }
                    )}
                  />
                </Col>
                <Col className={classes.switchUserCol}>
                  <Dropdown.Button
                    placement='bottomRight'
                    icon={<UserOutlined />}
                    className={classes.staffMenuMargin}
                    menu={{
                      items: [
                        {
                          key: 'dashboard',
                          icon: <DashboardOutlined />,
                          label: <NavLink to='/dashboard'>Go to Dashboard</NavLink>
                        },
                        {
                          key: 'profile',
                          icon:  <UserOutlined />,
                          label: <NavLink to='/user-profile'>View Staff Profile</NavLink>
                        },
                        {
                          key: 'switchStaff',
                          icon: <UserSwitchOutlined />,
                          label: 'Switch staff member',
                          children: staffAccess.map(staff => ({
                            key: staff.id,
                            onClick: () => {
                              dispatch(
                                appStateDuck.appStateActions.switchStaff(staff.id, staff.account_id, currentUserRecord.id)
                              )
                            },
                            label: (
                              <Button
                                className={classes.button}
                                type='text'
                              >
                                <strong>{staff.accountShortName}</strong> - {staff.first_name} {staff.last_name}
                              </Button>
                            )
                          }))
                        },
                        {
                          key: 'logout',
                          icon: <LogoutOutlined />,
                          onClick: () => dispatch(appStateDuck.appStateActions.logOutUser()),
                          label:  (
                            <Button
                              type='text'
                              className={classes.button}
                            >
                              Log out
                            </Button>
                          )
                        }
                      ]
                    }}
                  >
                    {currentStaffRecord.first_name} {currentStaffRecord.last_name}
                  </Dropdown.Button>
                </Col>
              </Row>
            </Col>
          </>)}
      </Row>
    </Layout.Header>
  )
}

export default Header

