import * as React from 'react'
import {
  Table,
  NavLink
} from 'components'
import {listHOC} from 'hocs'
import {API} from 'utils'
import {
  Country,
  IdentificationDocumentCategory,
  IdentificationRecord,
  POACategory,
  ListHOCChildProps
} from 'types'

export interface DataSource extends IdentificationRecord {
  client_first_name: string;
  client_last_name: string;
  nickname: IdentificationDocumentCategory['nickname'];
  country_name: Country['full_name'];
  admin_proof_of_address_categories_file_by: POACategory['file_by'];
}

const IdentificationList: React.FC<ListHOCChildProps<DataSource>> = ({
  getColumnSearchProps, entities, loading
}) => {

  return (
    <Table
      rowKey='id'
      scroll={{x: true}}
      loading={loading}
      dataSource={entities}
      pagination={{
        pageSizeOptions: ['10', '15'],
        defaultPageSize: 20,
        showSizeChanger: true
      }}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: 'descend',
          render: (text, record) => <NavLink to={`/identification/view/${record.id}`}>{text}</NavLink>
        },
        {
          title: 'Client Id',
          dataIndex: 'client_id',
          sorter: (a, b) => a.client_id - b.client_id,
          defaultSortOrder: 'descend'
        },
        {
          title: 'Client Name',
          render: (text, record) => `${record.client_first_name} ${record.client_last_name}`,
          defaultSortOrder: 'descend'
        },
        {
          title: 'Issue Country',
          dataIndex: 'country_name',
          defaultSortOrder: 'descend'
        },
        getColumnSearchProps({
          title: 'Document Type',
          dataIndex: 'nickname',
          filterInputType: listHOC.inputTypes.SELECT,
          selectFilterOptions: entities.map(option => option.nickname),
          getOptionProps: (nickname) => ({
            value: nickname,
            children: nickname
          })
        }),
        getColumnSearchProps({
          title: 'Proof of Address',
          dataIndex: 'admin_proof_of_address_categories_file_by',
          filterInputType: listHOC.inputTypes.SELECT,
          selectFilterOptions: entities.map(option => option.admin_proof_of_address_categories_file_by),
          getOptionProps: (poaCategoryFileBy) => ({
            value: poaCategoryFileBy,
            children: poaCategoryFileBy
          })
        }),
        {
          title: 'Document Number',
          dataIndex: 'document_number',
          defaultSortOrder: 'descend'
        }
      ]}
    />
  )
}

export default listHOC(IdentificationList, ({rootState}) => API.get('identification', `/list/${rootState.appState.currentAccountRecord.id}`))
