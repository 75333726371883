import {Record} from 'immutable'

import * as types from './types'

import {INPUT_CHANGE, CLEAR_STATE} from '../../constants'

const OrganiserData = Record({
  id: null,
  staffId: null,
  recordCreated: null,
  dueDate: null,
  priority: null,
  shortDescription: null,
  longDescription: null,
  clientId: null,
  completedFlag: null,
  documentId: null,
  transactionId: null,
  completedDate: null,
  completedStaffId: null,
  staff_nickname: null,
  client_nickname: null,
  first_name: null,
  last_name: null
})
const ReducerRecord = Record({
  entities: [],
  organiserData: new OrganiserData(),
  byStaffIds: [],
  byClientIds: [],
  loading: false
})

export default (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.ORGANISER_BY_ID_REQUEST:
    case types.ORGANISER_BY_STAFF_ID_REQUEST:
    case types.ORGANISER_BY_CLIENT_ID_REQUEST:
      return state.set('loading', true)

    case types.ORGANISER_BY_ID_ERROR:
    case types.ORGANISER_BY_STAFF_ID_ERROR:
    case types.ORGANISER_BY_CLIENT_ID_ERROR:
      return state.set('loading', false)

    case types.FETCH_ORGANISER_SUCCESS:
      return state.merge({
        entities: payload.map(organiser => new OrganiserData(organiser)),
        loading: false
      })

    case types.ORGANISER_BY_STAFF_ID_SUCCESS:
      return state.merge({
        byStaffIds: payload.map(organiser => new OrganiserData(organiser)),
        loading: false
      })

    case types.ORGANISER_BY_CLIENT_ID_SUCCESS:
      return state.merge({
        byClientIds: payload.map(organiser => new OrganiserData(organiser)),
        loading: false
      })

    case types.ORGANISER_BY_ID_SUCCESS:
      return state.merge({
        organiserData: new OrganiserData(payload),
        loading: false
      })

    case INPUT_CHANGE:
      return state.setIn(['organiserData', payload.inputName], payload.inputValue)
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}