import React, {Fragment} from 'react'

import {
  showNotification,
  API
} from 'utils'
import {ModalView} from '../../../../common/ModalView'
import {
  Form,
  Input,
  Row,
  Space,
  Button,
  InputDatePicker
} from 'components'
import {useForm} from 'antd/lib/form/Form'

export const ClientCreateNewAuthPersonComponent = ({
  isModalVisible,
  toggleModal,
  // staffId,
  clientId,
  asyncFunction,
  getData
}) => {
  const [form] = useForm()
  const onFinish = async values => {
    try {
      await API.post('clients', '/create-clientsAuthPerson', {
        body: {
          ...values,
          clientId
        }
      })
      showNotification('success', 'Auth Person', 'Successfully Created')
      getData()
    } catch (error) {
      showNotification('error', 'Auth Person', error.message)
    }

    toggleModal(false)

    if (asyncFunction) {
      asyncFunction()
    }
  }

  return (
    <ModalView
      title={'Create New Auth Person'}
      isModalVisible={isModalVisible}
      footer={null}
      handleOk={() => toggleModal(!isModalVisible)}
      handleCancel={() => toggleModal(!isModalVisible)}
      body={
        <Fragment>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              fileBy: '',
              email: '',
              telephone: '',
              address: ''
            }}
          >
            <Form.Item
              name='fileBy'
              label='Name'
              rules={[
                {
                  required: true,
                  message: 'Name is Required'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='email' label='Email'>
              <Input />
            </Form.Item>
            <Form.Item name='telephone' label='Telephone'>
              <Input />
            </Form.Item>
            <Form.Item name='address' label='Address'>
              <Input />
            </Form.Item>

            <InputDatePicker
              name='dateOfBirth'
              label='common.dateOfBirth'
              isAfterToday
            />

            <Row justify='end'>
              <Space>
                <Button htmlType='submit' type='primary'>
                  Save
                </Button>
              </Space>
            </Row>
          </Form>
        </Fragment>
      }
    />
  )
}
