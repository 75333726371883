import listHOC from './listHOC'
import React from 'react'
import {
  Table,
  Image
} from 'antd'
import {
  FilePdfOutlined,
  MailOutlined
} from '@ant-design/icons'

const FileList = listHOC(({
  document,
  fileList,
  getColumnSearchProps,
  loading
}) => {
  const columns = [
    {
      title: 'File ID',
      dataIndex: 'file_id',
      width: 70,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'file_id',
        placeholder: 'File ID',
        render: (highlighter, record) => {
          return <React.Fragment>
            {highlighter}
          </React.Fragment>

        }
      }),
    },
    {
      title: 'Link',
      dataIndex: 'file_id',
      width: 70,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'file_id',
        placeholder: 'File ID',
        render: (highlighter, {contentType, url}) => {

          let fileTypeIcon
          if (contentType.toString().includes('rfc822')) {
            fileTypeIcon = <MailOutlined />
          } else if (contentType.toString().includes('pdf')) {
            fileTypeIcon = <FilePdfOutlined />
          } else if (contentType.toString().includes('image')) {
            fileTypeIcon = <Image src={url} width={120} height={80}/>
          } else {
            fileTypeIcon = <Image src={url} width={120} height={80}/>
          }
          
          return (
            <React.Fragment>
              {
                fileTypeIcon
              }
              <br />
            Click{' '}
              <a href={url} rel='noreferrer' target='_blank'>
              here
              </a>
              {' '}to view in a new tab.
           
            </React.Fragment>
          )
        }
      })
    }
  ]

  return document.id ? (
    <React.Fragment>
      <div style={{width: '100%'}}>
        <h3>Details for document: {document.id}</h3>
        {document.description && <p style={{width: '100%'}}>
          <strong>{document.description}</strong>
        </p>}
        <p>{document.long_description}</p>
        <p>There are {fileList.length} file(s) attached to this document.</p>
        <p>Clicking on them below will open each in a new browser tab.</p>
      </div>
      <Table
        rowKey='file_id'
        scroll={{x: true}}
        style={{width: '100%'}}
        loading={loading}
        columns={columns}
        dataSource={fileList}
      />
    </React.Fragment>
  ) : <h3>No document selected</h3>
})

export default FileList