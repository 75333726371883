import listHOC from './listHOC'
import React, {
  useEffect,
  useState
} from 'react'
import {
  Button,
  Col,
  Descriptions,
  notification,
  Row,
  Spin,
  Table,
  Image
} from 'antd'
import API from '@aws-amplify/api'
import {
  FilePdfOutlined,
  MailOutlined
} from '@ant-design/icons'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

const DocumentFileList = listHOC(
  ({
    document, fileList, getColumnSearchProps, loading, layout, getDoctypeList, getClientByID, clientRecord
  }) => {
    const columns = [
      {
        title: 'File ID',
        dataIndex: 'file_id',
        width: 70,
        defaultSortOrder: 'descend',
        ...getColumnSearchProps({
          dataIndex: 'file_id',
          placeholder: 'File ID',
          render: (highlighter, record) => (
            <React.Fragment>
              {highlighter}
            </React.Fragment>
          )
        })
      },
      {
        ...getColumnSearchProps({
          title: 'Link',
          width: 70,
          defaultSortOrder: 'descend',
          dataIndex: 'file_id',
          placeholder: 'File ID',
          render: (highlighter, {contentType, url}) => {
            let fileTypeIcon
            if (contentType.toString().includes('rfc822')) {
              fileTypeIcon = <MailOutlined />
            } else if (contentType.toString().includes('pdf')) {
              fileTypeIcon = <FilePdfOutlined />
            } else if (contentType.toString().includes('image')) {
              fileTypeIcon = <Image src={url} width={120} height={80}/>
            } else {
              fileTypeIcon = <Image src={url} width={120} height={80}/>
            }
            
            return (
              <React.Fragment>
                {
                  fileTypeIcon
                }
                <br />
              Click{' '}
                <a href={url} rel='noreferrer' target='_blank'>
                here
                </a>
                {' '}to view in a new tab.
             
              </React.Fragment>
            )
          }
        })
      }
    ]

    const [state, setState] = useState({
      digiDocUrl: null,
      first: true,
      digiDocUuid: null,
      localSpinner: true
    })

    useEffect(() => {
      getDoctypeList()
    }, [])

    useEffect(() => {
      setState({
        digiDocUrl: null,
        first: true,
        digiDocUuid: null,
        localSpinner: true
      })

      asyncFunctions()

      if (document.client_id && state.first) {
        getClientByID(document.client_id)
        setState({
          ...state,
          first: false
        })
      }
    }, [document])

    const asyncFunctions = async () => {
      if (document.account_id) {
        if (document.deliosDocId) {
          try {
            const url = await API.get('documents', `/deliosdoc/${document.id}`)
            setState({
              ...state,
              digiDocUuid: url.payload.pandaDocId,
              localSpinner: false
            })
          } catch (error) {
            console.log('err', error)
          }
        } else {
          setState({
            ...state,
            digiDocUuid: null,
            localSpinner: false
          })
        }
      } else {
        setState({
          ...state,
          digiDocUuid: null,
          localSpinner: false
        })
      }
    }
    const fetchUrl = async () => {
      await API.post('digidoc', '/generatePandaDocUrl', {
        body: {
          documentId: state.digiDocUuid,
          email: clientRecord.email
        }
      })
        .then(res => {
          setState({
            ...state,
            digiDocUrl: res.payload.id,
            localSpinner: false
          })
        })
        .catch(err => {
          openNotificationWithIcon('error', 'Document', err.message)
        })
    }

    return document.id ? (
      <React.Fragment>
        <Spin spinning={state.localSpinner}>
          <Row>
            <Col xs={8}>
              <div style={{width: '100%'}}>
                <h3>Details for document: {document.id}</h3>
                {document.description && (
                  <p style={{width: '100%'}}>
                    <strong>{document.description}</strong>
                  </p>
                )}
                <p>{document.long_description}</p>
              </div>
            </Col>
            <Col xs={16}>
              {state.digiDocUuid ? (
                <React.Fragment>
                  <div style={{width: '100%'}}>
                    <h3>Details for document: {document.id}</h3>
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{marginBottom: 24}}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='DeliosDoc ID'>
                        <strong>{state.digiDocUuid}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{marginBottom: 24}}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='Admin URL:'>
                        {state.digiDocUuid && (
                          <a
                            href={`https://app.pandadoc.com/a/#/document/v1/editor/${state.digiDocUuid}`}
                            target='_blank' rel="noreferrer"
                          >
                            https://app.pandadoc.com/a/#/document/v1/editor/${state.digiDocUuid}
                          </a>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{marginBottom: 24}}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='Client URL:'>
                        {state.digiDocUrl && (
                          <a href={`https://app.pandadoc.com/s/${state.digiDocUrl}`} target='_blank' rel="noreferrer">
                            https://app.pandadoc.com/s/{state.digiDocUrl}
                          </a>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <Button onClick={fetchUrl}>Get URL</Button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Table
                    rowKey='file_id'
                    scroll={{x: true}}
                    style={{width: '100%'}}
                    loading={loading}
                    columns={columns}
                    dataSource={fileList}
                    size='small'
                    pagination={{
                      defaultPageSize: 5,
                      showSizeChanger: false
                    }}
                  />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Spin>
      </React.Fragment>
    ) : (
      <h3>No document selected</h3>
    )
  }
)

export default DocumentFileList
