import * as React from 'react'
import {
  PlusOutlined,
  Table,
  Button,
  EyeOutlined,
  Card
} from 'components'
import {FULL_DATE_TIME_FORMAT} from 'consts'
import dayjs from 'dayjs'
import {
  CreateJournalModal,
  JournalDrawer
} from './components'
import {InformationRequestJournal} from 'types'
import {JournalCreateNewComponentForIRDetails} from 'pages/dashboard/journals/journalCreateNewComponentForIRDetails'
import {JournalsViewDrawerComponentForIRDetails} from 'pages/dashboard/journals/journalsViewDrawerComponentForIRDetails'
import {API} from 'utils'

const Journals: React.FC<{informationRequestID: number | string;}> = ({informationRequestID}) => {
  const [state, setState] = React.useState<{
    loading: boolean;
    journals: InformationRequestJournal[];
    modalOpen: boolean;
    currentJournal: InformationRequestJournal | null;
  }>({
    loading: false,
    journals: [],
    modalOpen: false,
    currentJournal: null,
  })

  React.useEffect(() => {
    if (informationRequestID) {
      const getJournals = async () => {
        try {
          setState(prevState => ({
            ...prevState,
            loading: true
          }))
          const {payload} = await API.get('information-requests', `/journals/${informationRequestID}`)

          setState(prevState => ({
            ...prevState,
            loading: false,
            journals:  payload
          }))
        } catch (e) {
          setState(prevState => ({
            ...prevState,
            loading: false
          }))
        }
      }

      getJournals()
    }
  }, [informationRequestID])
  
  return (
    <Card
      title='Journals'
      style={{marginTop: 24}}
      extra={
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            setState({
              ...state,
              modalOpen: true
            })
          }}
        />
      }
    >
      <Table
        rowKey='file_id'
        scroll={{x: true}}
        style={{width: '100%'}}
        loading={state.loading}
        columns={[
          {
            title: 'Created',
            dataIndex: 'recordCreated',
            width: 70,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
            render: (text) => text && dayjs(text).format(FULL_DATE_TIME_FORMAT)
          },
          {
            title: 'Staff',
            dataIndex: 'staff',
            width: 70,
            defaultSortOrder: 'descend',
          },
          {
            title: 'Action',
            dataIndex: 'id',
            width: 70,
            defaultSortOrder: 'descend',
            render: (id) => (
              <Button
                type='link'
                icon={<EyeOutlined />}
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    currentJournal: state.journals.find(item => item.id === id) as InformationRequestJournal
                  }))
                }}
              />
            )
          }
        ]}
        dataSource={state.journals}
        size='small'
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: false
        }}
      />
      <CreateJournalModal
        open={state.modalOpen}
        informationRequestID={informationRequestID}
        onSuccess={newJournal => {
          setState(prevState => ({
            ...prevState,
            journals: prevState.journals.concat(newJournal),
            modalOpen: false
          }))
        }}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            modalOpen: false
          }))
        }}
      />
      <JournalDrawer
        currentJournal={state.currentJournal}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            currentJournal: null
          }))
        }}
      />
    </Card>
  )
}

export default Journals
