import React, {
  useEffect,
  useRef,
  useState
} from 'react'

import {
  Button,
  Input,
  DatePicker
} from 'antd'

import {SearchOutlined} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

import FormSelect from './FormSelect'

const {RangePicker} = DatePicker

export const inputTypes = {
  DATE_RANGE: 'DATE_RANGE',
  INPUT: 'INPUT',
  SELECT: 'SELECT'
}

const listHOC = Component => props => {
  const [{
    searchWords, rangeValues, searchedColumn
  }, setState] = useState({
    searchWords: [],
    rangeValues: [],
    searchedColumn: ''
  })
  const searchInput = useRef()
  useEffect(() => {
    const {
      currentAccountID, clientId, fetchCb = () => null, clearState = () => null
    } = props
    if (clientId) {
      fetchCb(clientId)
    } else if (currentAccountID && clientId === undefined) {
      fetchCb(currentAccountID)
    } else {
      fetchCb()
    }
    return clearState
  }, [])
  /**
   * @param {Array} selectedKeys
   * @param {Function} confirm
   * @param {string} dataIndex
   */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let tempString = ''
    let tempArray = []

    if (typeof(selectedKeys) === 'object' && typeof(selectedKeys[0]) === 'string' && dataIndex === 'email') {
      tempString = selectedKeys[0]
      tempArray = selectedKeys

      tempString = selectedKeys[0].trim()

      const index = tempArray.indexOf(selectedKeys[0])

      if (index !== -1) {
        tempArray[index] = tempString
      }

      confirm()
      setState({
        searchedColumn: dataIndex,
        searchWords: tempArray,
        rangeValues
      })
    } else {
      confirm()
      setState({
        searchedColumn: dataIndex,
        searchWords: selectedKeys,
        rangeValues
      })
    }

  }
  /**
   * @param {Function} clearFilters
   */
  const handleReset = clearFilters => {
    clearFilters()
    setState({
      searchWords: [],
      rangeValues: []
    })
  }

  const getColumnSearchProps = ({
    dataIndex,
    placeholder = dataIndex,
    filterInputType = inputTypes.INPUT,
    render = highlighter => highlighter,
    selectFilterOptions = [],
    getOptionProps = option => ({children: option}),
    optionValue
  }) => {
    let onFilter
    let getFilterComponent
    switch (filterInputType) {
      case inputTypes.DATE_RANGE:
        onFilter = (value, record) => {
          const [from, to] = searchWords
          return record[dataIndex] >= from && record[dataIndex] <= to
        }
        getFilterComponent = ({setSelectedKeys}) => (
          <div style={{marginBottom: 5}}>
            <RangePicker
              showTime
              value={rangeValues}
              ref={ref => {
                searchInput.current = ref
              }}
              onChange={(rangeValues, stringValues) => {
                setSelectedKeys(stringValues)
                setState({
                  searchWords: stringValues,
                  rangeValues
                })
              }}
            />
          </div>
        )
        break
      case inputTypes.SELECT:
        onFilter = (value, record) => record[dataIndex] === value
        selectFilterOptions = Array.from(new Set(selectFilterOptions.filter(o => o)).values())
        getFilterComponent = ({setSelectedKeys, selectedKeys}) => (
          <div style={{marginBottom: 5}}>
            <FormSelect
              isFormItem={false}
              allowClear={false}
              showSearch={false}
              placeholder={`Filter by ${placeholder}`}
              options={selectFilterOptions}
              getOptionProps={getOptionProps}
              optionValue={optionValue}
              onChange={value => setSelectedKeys(value && [value])}
              value={selectedKeys[0]}
              ref={ref => {
                searchInput.current = ref
              }}
            />
          </div>
        )
        break
      case inputTypes.INPUT:
        onFilter = (value, record) => String(record[dataIndex]).toLowerCase().includes(value.toLowerCase())
        getFilterComponent = ({
          setSelectedKeys, selectedKeys, confirm
        }) => (
          <Input
            ref={ref => {
              searchInput.current = ref
            }}
            placeholder={`Search by ${placeholder}`}
            value={selectedKeys[0]}
            onChange={({target: {value}}) => setSelectedKeys(value && [value])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 188,
              marginBottom: 8,
              display: 'block'
            }}
          />
        )
        break
    }
    return {
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters
      }) => (
        <div style={{padding: 8}}>
          {getFilterComponent({
            setSelectedKeys,
            selectedKeys,
            confirm
          })}
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
              marginRight: 8
            }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{width: 90}}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered && '#1890ff'}} />,
      onFilter,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          const focusMethods = {
            [inputTypes.DATE_RANGE]: 'focus',
            [inputTypes.INPUT]: 'select',
            [inputTypes.SELECT]: 'focus'
          }
          setTimeout(() => {
            const method = focusMethods[filterInputType]
            searchInput.current[method]()
          }, 100)
        }
      },
      render: (text, record) =>
        render(
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0
              }}
              searchWords={searchWords.map(el => String(el))}
              autoEscape
              textToHighlight={(text || '').toString()}
            />
          ) : (
            text
          ),
          record
        )
    }
  }
  return <Component {...props} getColumnSearchProps={getColumnSearchProps} />
}
export default listHOC