import React, {
  useEffect,
  useState
} from 'react'

import {routes} from 'configs'
import {
  connect,
  useSelector
} from 'react-redux'
import {
  tradingActions,
  tradingSelectors
} from 'configs/ducks/trading'

import TradingAccountTransactions from './TradingAccountTransaction'
import TradingAccountTransactions2 from './TradingAccountTransaction2'
import {
  showNotification,
  API
} from 'utils'

import {
  Form,
  Typography,
  Skeleton,
  Tabs
} from 'antd'
import queryString from 'query-string'
import {
  TradingClientView,
  TradingViewComponent
} from './trading-view'
import {
  useHistory,
  useLocation
} from 'react-router'

const {Title} = Typography

const ROUTES = [
  {
    path: routes.tradingAccounts,
    breadcrumbName: 'Trading Account List'
  },
  {
    path: 'first',
    breadcrumbName: 'View Details'
  }
]

function TradingAccountsView({
  match: {params: {id: matchID}},
  getTradingAccount,
  tradingAccountRecord,
  getTopics,
  tradingAccountRecord: {
    id,
    trading_platform_type_file_by,
    trading_platform_mode_file_by,
    currencies_iso_alpha_3,
    client_id,
    client_first_name,
    tradingPlatformId,
    accountLock,
    tradingPlatformCreated,
    securityLock,
    recordCreated,
    tradingPlatformUsername,
    client_last_name,
    client_email,
    client_tel_mobile,
    pw,
    teg,
    defaultWithdrawalAccountID,
    ...rest
  }
}) {
  const [rend, setRend] = useState(true)
  const history = useHistory()
  const [activeTab, setTab] = useState('')
  const [form] = Form.useForm()
  const location = useLocation()
  const currentUser = useSelector(state => state.appState?.currentUserRecord)
  useEffect(() => {
    getTradingAccount(matchID)
  }, [])

  useEffect(() => {
    if (Object.keys(tradingAccountRecord).length > 0) {
      setRend(false)
    }
  }, [tradingAccountRecord])

  useEffect(() => {
    if (tradingPlatformUsername) {
      form.setFieldsValue({tradingPlatformUsername: tradingPlatformUsername})
    } else {
      form.setFieldsValue({tradingPlatformUsername: null})
    }

    if (pw) {
      form.setFieldsValue({pw: pw})
    } else {
      form.setFieldsValue({pw: null})
    }

    if (teg) {
      form.setFieldsValue({teg: teg})
    } else {
      form.setFieldsValue({teg: null})
    }
  }, [tradingPlatformUsername, pw, teg])

  const onFinish = async values => {
    try {
      await API.put('trading-accounts', `/update/${id}`, {
        body: {
          ...values,
          defaultWithdrawalAccountID: values?.defaultWithdrawalAccountID ? values.defaultWithdrawalAccountID : null
        }
      })
      getTradingAccount(matchID)
      showNotification('success', 'Trading Account', 'Successfully Updated')
    } catch (error) {
      showNotification('error', 'Trading Account', error.message)
    }
  }

  useEffect(() => {
    getTopics({
      title: `Trading Account ${tradingPlatformUsername ? tradingPlatformUsername : '' && `- ${tradingPlatformUsername}`}`,
      breadCrumb: ROUTES
    })
  }, [tradingPlatformUsername])

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search)
    setTab(activeTab)
  }, [location.search])

  const onTabClick = e => {
    history.push(`/trading/accounts/${matchID}?tab=${e}`)
  }

  return (
    <>
      {rend ? (
        <Skeleton />
      ) : (
        <React.Fragment>
          {/* <Row>
            <Col xs={24}>
              <Title level={3}>Trading Account {tradingPlatformUsername && `- ${tradingPlatformUsername}`}</Title>
            </Col>
          </Row> */}
          <Form onFinish={onFinish} layout='vertical' form={form}>
            <Tabs type='card' activeKey={activeTab} onTabClick={onTabClick}>
              <Tabs.TabPane tab='Summary' key='tsummary'>
                <TradingViewComponent {...tradingAccountRecord} currentAccountID={matchID} />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Client' key='tclient'>
                <TradingClientView tradingAccountRecord={tradingAccountRecord} />
              </Tabs.TabPane>
              {currentUser?.beta === 1 && <Tabs.TabPane tab='Transactions OLD' key='ttransaction'>
                <Title level={3}>Transactions OLD</Title>
                <TradingAccountTransactions currentAccountID={matchID} />
              </Tabs.TabPane>}
              <Tabs.TabPane tab='Transactions' key='ttransaction2'>
                <Title level={3}>Transactions</Title>
                <TradingAccountTransactions2 currentAccountID={matchID} />
              </Tabs.TabPane>
            </Tabs>
          </Form>
          <br />
        </React.Fragment>
      )}
    </>
  )
}
const mapStateToProps = state => {
  const {loading, tradingAccountRecord} = tradingSelectors.tradingAccountSelector(state)
  return {
    tradingAccountRecord,
    loading
  }
}

export default connect(mapStateToProps, {getTradingAccount: tradingActions.getTradingAccount})(TradingAccountsView)
