import * as React from 'react'
import {
  Table,
  Button,
  DeleteOutlined,
  NavLink,
  FileAddOutlined,
  FileAddTwoTone,
  UserAddOutlined,
  Card,
  ArrowLeftOutlined,
  Space
} from 'components'
import {
  NewTaskModal,
  CreateJournalModal,
  CreateNewDocumentModal,
  RequirementsDrawer
} from './components'
import {useSelector} from 'hooks'
import {listHOC} from 'hocs'
import {routes} from 'configs'
import {COLORS} from 'consts'
import {
  showNotification,
  API
} from 'utils'
import {createUseStyles} from 'react-jss'
import {
  ListHOCChildProps,
  Staff
} from 'types'
import {Types} from './duck'

const useStyles = createUseStyles({
  icon: {
    fontSize: 18,
    cursor: 'pointer'
  },
})

const DocumentList: React.FC<Types.DocumentListProps & ListHOCChildProps<Types.ClientDocument>> = ({
  getColumnSearchProps, updateState, entities, clientRecord, loading
}) => {
  const classes = useStyles()
  const [state, setState] = React.useState<{
    loading: boolean;
    requirementsDrawerOpen: boolean;
    createNew: boolean;
    removedIDs: number[];
    modal: {
      document: Types.ClientDocument | undefined;
      kind?: 'client' | 'journal'
    }
  }>({
    loading: false,
    requirementsDrawerOpen: false,
    createNew: false,
    removedIDs: [],
    modal: {
      document: undefined,
      kind: undefined
    }
  })
  const currentStaffRecord = useSelector(state => state.appState.currentStaffRecord as Staff)

  const deleteDocument = async (id: number) => {
    try {
      setState(prevState => ({
        ...prevState,
        removedIDs: prevState.removedIDs.concat(id),
      }))

      await Promise.all([
        await API.put('documents', `/update/${id}`, {body: {deleted: true}}),
        await API.post('journals', '/create', {
          body: {
            journalTypeId: 1,
            staffId: currentStaffRecord.id,
            clientId: clientRecord.id,
            title: 'Document Deleted',
            memo: `Document ID ${id} deleted`,
          }
        })
      ])

      updateState({entities: entities.filter(item => item.id !== id)})

      setState(prevState => ({
        ...prevState,
        removedIDs: prevState.removedIDs.filter(removedID => removedID !== id),
      }))

      showNotification('success', 'Journals', 'Successfully Created')
    } catch (e: any) {
      setState(prevState => ({
        ...prevState,
        removedIDs: prevState.removedIDs.filter(removedID => removedID !== id),
      }))
      showNotification('error', 'API', e.message)
    }
  }

  return (
    <Card
      title="Client Documents"
      bordered={true}
      extra={
        <Space>
          <Button
            key='createNew'
            icon={<FileAddOutlined />}
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                createNew: true
              }))
            }}
          />
          <Button
            key='drawer'
            icon={<ArrowLeftOutlined />}
            type='primary'
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                requirementsDrawerOpen: true
              }))
            }}
          />
        </Space>
      }
    >
      <CreateNewDocumentModal
        open={state.createNew}
        client_id={clientRecord.id}
        onSuccess={document => {
          updateState({entities: entities.concat(document)})
        }}
        toggleOpen={(open) => {
          setState(prevState => ({
            ...prevState,
            createNew: open
          }))
        }}
      />
      <RequirementsDrawer
        open={state.requirementsDrawerOpen}
        clientRecord={clientRecord}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            requirementsDrawerOpen: false
          }))
        }}
      />
      <CreateJournalModal
        toggleModal={(open) => {
          setState(prevState => ({
            ...prevState,
            modal: {
              kind: 'journal',
              document: open ? prevState.modal.document : undefined
            }
          }))
        }}
        clientId={clientRecord.id}
        staffId={currentStaffRecord.id}
        open={Boolean(state.modal.document) && state.modal.kind === 'journal'}
        documentId={state.modal.document?.id}
        documents={entities}
      />
      {state.modal.document && (
        <NewTaskModal
          documents={entities}
          clientID={clientRecord.id}
          selectedDocument={state.modal.document}
          open={Boolean(state.modal.document) && state.modal.kind === 'client'}
          toggleModal={(open: boolean) => {
            setState(prevState => ({
              ...prevState,
              modal: {
                kind: 'client',
                document: open ? prevState.modal.document : undefined
              }
            }))
          }}
          updateDocuments={documents => {
            updateState({entities: documents})
          }}
        />
      )}
      <Table
        rowKey='id'
        loading={loading}
        style={{width: '100%'}}
        scroll={{x: true}}
        dataSource={entities}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: false
        }}
        columns={
          [
            getColumnSearchProps({
              title: 'Document',
              dataIndex: 'id',
              width: 70,
              sorter: (a, b) => a.id - b.id,
              defaultSortOrder: 'descend',
              placeholder: 'ID',
              render: (highlighter, record) => {
                return (
                  <NavLink to={routes.documentsView(record.id)}>
                    {highlighter}
                  </NavLink>
                )
              }
            }),
            getColumnSearchProps({
              title: 'Document Type',
              dataIndex: 'document_type_fileby',
              placeholder: 'Document Type'
            }),
            getColumnSearchProps({
              title: 'Description',
              dataIndex: 'description',
              placeholder: 'Description'
            }),
            {
              title: 'Action',
              render: (text, record) => {

                return (
                  <Space>
                    <FileAddTwoTone
                      className={classes.icon}
                      twoToneColor={COLORS.cyan[4]}
                      onClick={() => {
                        setState(prevState => ({
                          ...prevState,
                          modal: {
                            kind: 'journal',
                            document: record
                          }
                        }))
                      }}
                    />
                    <UserAddOutlined
                      className={classes.icon}
                      onClick={() => {
                        setState(prevState => ({
                          ...prevState,
                          modal: {
                            kind: 'client',
                            document: record
                          }
                        }))
                      }}
                    />
                    {currentStaffRecord.security_documents_canDelete && (
                      <Button
                        danger
                        type='text'
                        loading={state.removedIDs.includes(record.id)}
                        onClick={() => deleteDocument(record.id)}
                        icon={<DeleteOutlined className={classes.icon} />}
                        style={{
                          padding: 0,
                          height: 'auto',
                          width: 'auto'
                        }}
                      />
                    )}
                  </Space>
                )
              }
            }
          ]
        }
      />
    </Card>
  )
}

export default listHOC<any, Types.DocumentListProps, {client_id: string;}>(DocumentList, ({match}) => API.get('documents', `/list/client/${match.params.client_id}`))