import React from 'react'
import {
  Descriptions,
  Skeleton,
  Typography
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import {tradingTransactionSelector} from 'configs/ducks/trading/selectors'

const ClientDetails = () => {
  const { tradingTransactionRecord, loading } = useSelector(tradingTransactionSelector)
  return (
    <Skeleton loading={loading}>
      <Typography.Title level={4}>
        <FormattedMessage id='trading_transaction_detail.client_details' />
      </Typography.Title>
      <Descriptions layout="vertical" bordered>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.client_id' />} span={2}>
          {tradingTransactionRecord?.clients_id}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.account_user_id' />} span={2}>
          {tradingTransactionRecord?.clients_account_user_id}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.first_name' />} span={2}>
          {tradingTransactionRecord?.clients_first_name}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.last_name' />} span={2}>
          {tradingTransactionRecord?.clients_last_name}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.email' />} span={6}>
          {tradingTransactionRecord?.clients_email}
        </Descriptions.Item>
      </Descriptions>
    </Skeleton>
  )
}

export default React.memo(ClientDetails)