import React, {
  Fragment,
  useState
} from 'react'

import {
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Skeleton,
  Spin,
  Typography,
  Checkbox,
  notification
} from 'antd'

import {
  FormSelect,
  InputEmail
} from 'common'
import { PREFERRED_COUNTRIES } from 'consts'

import _ from 'lodash'

import {FormattedMessage} from 'react-intl'
import PhoneInput from 'react-phone-input-2'
import { API } from 'aws-amplify'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

export const filterOption = (input, option) => option.children.toLowerCase().includes(input.toLowerCase())

const {Title} = Typography

export const columnSize = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12
}
/**
 * @param {Object} options
 * @returns {{id: number}[]}
 */
export const getStatusOptions = options =>
  Object.entries(options)
    .map(([id, option]) => ({
      id: Number(id),
      ...option
    }))
    .sort((a, b) => (b.text < a.text ? 1 : -1))

export const VerificationTrulioResults = ({
  verificationData: {
    id, request_datetime, response_datetime, trulioo_record_id, trulioo_transaction_id
  },
  trulioData: {
    loaded, trulioMatch, trulioMatchRule, DatasourceResults, InputFields
  }
}) => {
  const dataSourceFieldStatus = {
    match: 'green',
    missing: 'red'
  }
  const descriptionCol = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  return (
    <>
      {id && (
        <Descriptions
          bordered
          title='Verification Info'
          layout='vertical'
          size='small'
          style={{marginBottom: 20}}
          column={{
            xs: 1,
            sm: 2,
            md: 2,
            lg: 4
          }}
        >
          <Descriptions.Item label='Request Datetime'>{request_datetime}</Descriptions.Item>
          <Descriptions.Item label='Response Datetime'>{response_datetime}</Descriptions.Item>
          <Descriptions.Item label='Trulioo Record ID'>{trulioo_record_id}</Descriptions.Item>
          <Descriptions.Item label='Trulioo Transaction ID'>{trulioo_transaction_id}</Descriptions.Item>
        </Descriptions>
      )}
      {loaded && (
        <>
          <Descriptions
            size='small'
            layout='vertical'
            title='Verification Match Info'
            bordered
            style={{marginBottom: 20}}
            column={{
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2
            }}
          >
            <Descriptions.Item label='Trulioo Match'>
              <span style={{color: trulioMatch === 'match' ? 'green' : ''}}>{trulioMatch}</span>
            </Descriptions.Item>
            <Descriptions.Item label='Trulioo Match Rule'>{trulioMatchRule}</Descriptions.Item>
          </Descriptions>

          {Boolean(InputFields.length) && (
            <Descriptions
              style={{marginBottom: 20}}
              title='Input Fields'
              layout='vertical'
              bordered
              column={descriptionCol}
            >
              {InputFields.map(({FieldName, Value}) => (
                <Descriptions.Item label={FieldName} key={FieldName}>
                  {Value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}

          {DatasourceResults.map(({
            DatasourceName, DatasourceFields, AppendedFields
          }) => (
            <Fragment key={DatasourceName}>
              <Descriptions
                style={{marginBottom: 20}}
                label={DatasourceName}
                bordered
                layout='vertical'
                column={descriptionCol}
              >
                {DatasourceFields.map(({FieldName, Status}) => (
                  <Descriptions.Item label={FieldName} key={FieldName}>
                    <span style={{color: dataSourceFieldStatus[Status] || ''}}>{Status}</span>
                  </Descriptions.Item>
                ))}
              </Descriptions>
              {Boolean(AppendedFields.length) && (
                <Descriptions style={{marginBottom: 20}} bordered layout='vertical' column={descriptionCol}>
                  {AppendedFields.map(({FieldName, Data}) => (
                    <Descriptions.Item label={FieldName} key={FieldName}>
                      {Data}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              )}
            </Fragment>
          ))}
        </>
      )}
    </>
  )
}

export const StaffClientSummary = ({
  accountsList, clientStatusList, id, getStaffRecord
}) => {
  const getOptionProps = ({file_by}) => ({children: file_by})

  const callback = () => {
    getStaffRecord(id)
  }

  const setDeleted = (checked) => {
    try {
      API.put('staff', `/update/${id}`, {body: {'deleted': checked}})
    
      openNotificationWithIcon('success', 'Staff', 'Successfully updated')
    } catch (error) {
      console.error('Err', error)
      openNotificationWithIcon('error', 'Staff', error.mesage)
    }
    callback()
  }

  return (
    <Row gutter={[96, 24]}>
      <Col {...columnSize}>
        <Row gutter={[12, 0]}>
          <Col {...columnSize}>
            <Form.Item
              label='First Name'
              name='first_name'
              rules={[
                {
                  required: true,
                  message: 'First name is required'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...columnSize}>
            <Form.Item label='Middle Name(s)' name='middle_name'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label='Last Name'
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Last name is required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="deleted" valuePropName="checked">
          <Checkbox
            onChange={e => {
              setDeleted(e.target.checked)
            }}
          > Deleted</Checkbox>
        </Form.Item>
      </Col>
      <Col {...columnSize}>
        <FormSelect
          label='Account'
          name='account_id'
          placeholder='Select Account'
          optionValue='id'
          getOptionProps={getOptionProps}
          options={accountsList}
        />
        <Form.Item shouldUpdate>
          {({getFieldValue}) => (
            <Descriptions bordered layout='vertical' size='small'>
              <Descriptions.Item label='E-mail'>{getFieldValue('email')}</Descriptions.Item>
            </Descriptions>
          )}
        </Form.Item>

        {clientStatusList && (
          <FormSelect
            allowClear
            label='Status ID'
            name='status_id'
            placeholder='Select Status'
            optionValue='id'
            getOptionProps={getOptionProps}
            options={clientStatusList}
          />
        )}
      </Col>
    </Row>
  )
}

export const AccountSummary = ({
  accountsList, clientStatusList, accountTypeList
}) => {
  const getOptionProps = ({fileBy}) => ({children: fileBy})
  return (
    <Row gutter={[96, 24]}>
      <Col {...columnSize}>
        <Row gutter={[12, 0]}>
          <Col {...columnSize}>
            <Form.Item
              label='ID'
              name='id'
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...columnSize}>
            <FormSelect
              label='Account Type ID'
              name='accountTypeId'
              placeholder='Select Account Type'
              optionValue='id'
              getOptionProps={getOptionProps}
              options={accountTypeList}
            />
          </Col>
        </Row>
        <Form.Item
          label='Account Email'
          name='account_email'
          rules={[
            {
              required: true,
              message: 'Account Email is required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Account Name'
          name='account_name'
          rules={[
            {
              required: true,
              message: 'Account Name is required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Account Slug'
          name='account_slug'
          rules={[
            {
              required: true,
              message: 'Account Slug is required'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col {...columnSize}>
        <Form.Item
          label='Email Client Matters'
          name='emailClientMatters'

        >
          <Input />
        </Form.Item>
        <Form.Item
          label='From Email'
          name='fromEmail'

        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Page Header Logo'
          name='page_header_logo'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Favicon'
          name='favicon'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='File By'
          name='file_by'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Short Name'
          name='shortName'
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

export const StaffClientContact = ({onPhoneInputChange}) => {
  const left = [
    {
      label: 'Telephone (home)',
      name: 'tel_home'
    },
    {
      label: 'Telephone (work)',
      name: 'tel_work'
    },
    {
      label: 'Telephone (mobile)',
      name: 'tel_mobile'
    }
  ]

  return (
    <>
      <Title level={4}>Telephone</Title>
      {left.map(props => (
        <Form.Item key={props.label} {...props}>
          <PhoneInput
            inputStyle={{width: '100%'}}
            preferredCountries={PREFERRED_COUNTRIES}
            masks={{au: '(.) ....-....'}}
            enableSearch
            disableCountryGuess={false}
            onChange={onPhoneInputChange(props.name)}
          />
        </Form.Item>
      ))}
    </>
  )
}

const DynamicAddress = ({
  prefix, selectOptions, ...values
}) => {
  const title = prefix.replace(/\w/y, match => match.toUpperCase())
  const country = `${prefix}_address_country`
  const line_1 = `${prefix}_address_line_1`
  const line_2 = `${prefix}_address_line_2`
  const state = `${prefix}_address_state`
  const postcode = `${prefix}_address_postcode`
  const region = `${prefix}_address_region`
  const city = `${prefix}_address_city`
  const suburb = `${prefix}_address_suburb`

  const selectValue = values[country]

  const colLayout = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 8
  }

  const getActiveItemFromOption = option => _.get(_.invertBy(option, value => value === 1).true, '[0]', null)

  const attr = getActiveItemFromOption(
    _.pick(
      selectOptions.find(({id}) => id === selectValue),
      ['has_city', 'has_region', 'has_suburb']
    )
  )

  let dynamicComponent
  switch (attr) {
    case 'has_region':
      dynamicComponent = {
        name: region,
        label: <FormattedMessage id='data_new_client_page.region' defaultMessage='Region' />
      }
      break
    case 'has_city':
      dynamicComponent = {
        name: city,
        label: <FormattedMessage id='data_new_client_page.city' defaultMessage='City' />
      }
      break
    case 'has_suburb':
      dynamicComponent = {
        name: suburb,
        label: <FormattedMessage id='data_new_client_page.street_address_suburb' defaultMessage='Suburb' />
      }
      break
    default:
      dynamicComponent = null
  }

  const getOptionProps = ({
    full_name, has_city, has_region, has_suburb
  }) => ({
    children: full_name,
    'data-address': {
      has_city,
      has_region,
      has_suburb
    }
  })
  return (
    <Col {...columnSize}>
      <Title level={4}>{title} Address</Title>
      <FormSelect
        label='Country'
        name={country}
        optionValue='id'
        placeholder='Select Country'
        options={selectOptions}
        getOptionProps={getOptionProps}
      />
      <Form.Item label='Line 1' name={line_1}>
        <Input />
      </Form.Item>
      <Form.Item label='Line 2' name={line_2}>
        <Input />
      </Form.Item>
      <Row gutter={[12, 0]}>
        {dynamicComponent && (
          <Col {...colLayout}>
            <Form.Item
              name={dynamicComponent.name}
              label={dynamicComponent.label}
              className={dynamicComponent.className}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
        <Col {...colLayout}>
          <Form.Item label='State' name={state}>
            <Input />
          </Form.Item>
        </Col>
        <Col {...colLayout}>
          <Form.Item label='PostCode' name={postcode}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  )
}

export const StaffClientAddress = ({form, countriesList}) => <DynamicAddress {...form.getFieldsValue()} prefix='street' selectOptions={countriesList} />

export const StaffClientForm = ({
  mounting, loading, onSubmit, initialValues, children
}) => {
  const [form] = Form.useForm()
  const forceUpdate = useState()[1].bind(null, {})
  const onValuesChange = changed => {
    const [name] = Object.keys(changed)
    console.log(changed)
    if (name === 'street_address_country' || name === 'postal_address_country') {
      forceUpdate()
    }
  }
  return (
    <Skeleton active loading={mounting}>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={onSubmit}
          initialValues={initialValues}
        >
          {children(form)}
        </Form>
      </Spin>
    </Skeleton>
  )
}

export const AccountForm = ({
  mounting, loading, onSubmit, initialValues, children
}) => {
  const [form] = Form.useForm()
  const forceUpdate = useState()[1].bind(null, {})
  const onValuesChange = changed => {
    const [name] = Object.keys(changed)
    if (name === 'street_address_country' || name === 'postal_address_country') {
      forceUpdate()
    }
  }
  return (
    <Skeleton active loading={mounting}>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={onSubmit}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          {children(form)}
        </Form>
      </Spin>
    </Skeleton>
  )
}

export const ClientStaffWizard01 = ({
  duplicateEmail, accountsList, currentUserRecord
}) => {
  const getOptionProps = ({file_by}) => ({children: file_by})
  return (
    <Row gutter={[96, 24]}>
      <Col {...columnSize}>
        <Row gutter={[12, 0]}>
          <Col {...columnSize}>
            <Form.Item
              hasFeedback
              label='First Name'
              name='first_name'
              rules={[
                {
                  required: true,
                  message: 'First name is required'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...columnSize}>
            <Form.Item label='Middle Name(s)' name='middle_name' style={{marginBottom: 0}}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          hasFeedback
          label='Last Name'
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Last name is required'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      {currentUserRecord?.backoffice &&
        <Col xs={12}>
          <FormSelect
            label='Account'
            name='account_id'
            placeholder='Select Account'
            optionValue='id'
            getOptionProps={getOptionProps}
            options={accountsList}
          />
        </Col>
      }
      <Col {...columnSize}>
        <InputEmail required isDuplicate={duplicateEmail} />
      </Col>
    </Row>
  )
}

export const ClientStaffWizard02 = ({
  countriesList, onPhoneInputChange, ...rest
}) => {
  const getOptionProps = ({full_name}) => ({children: full_name})
  const fields = [
    {
      title: 'Contact Details',
      isPhone: true,
      ui: [
        {
          label: 'Secondary Email',
          name: 'email_secondary'
        }
      ]
    },
    {
      title: 'Postal Address',
      ui: [
        {
          label: 'Street Address (Line 1)',
          name: 'postal_address_line_1'
        },
        {
          label: 'Street Address (Line 2)',
          name: 'postal_address_line_2'
        },
        {
          label: 'Suburb',
          name: 'postal_address_suburb'
        },
        {
          label: 'Country',
          name: 'postal_address_country',
          props: {
            placeholder: 'Select Country',
            options: countriesList,
            getOptionProps,
            optionValue: 'id'
          }
        }
      ]
    },
    {
      title: 'Street Address',
      ui: [
        {
          label: 'Street Address (Line 1)',
          name: 'street_address_line_1'
        },
        {
          label: 'Street Address (Line 2)',
          name: 'street_address_line_2'
        },
        {
          label: 'Suburb',
          name: 'street_address_suburb'
        },
        {
          label: 'State',
          name: 'street_address_state'
        },
        {
          label: 'PostCode',
          name: 'street_address_postcode'
        },
        {
          label: 'Country',
          name: 'street_address_country',
          props: {
            placeholder: 'Select Country',
            options: countriesList,
            getOptionProps,
            optionValue: 'id'
          }
        }
      ]
    }
  ]
  const data = fields.map(({
    title, ui, isPhone
  }) => (
    <Col {...columnSize} key={title}>
      <Title level={4}>{title}</Title>
      {isPhone && (
        <React.Fragment>
          <Form.Item label='Telephone (home)' name='tel_home'>
            <PhoneInput
              inputStyle={{width: '100%'}}
              country={'us'}
              disableCountryGuess={false}
              onChange={onPhoneInputChange('tel_home')}
            />
          </Form.Item>
          <Form.Item label='Telephone (work)' name='tel_work'>
            <PhoneInput
              inputStyle={{width: '100%'}}
              country={'us'}
              disableCountryGuess={false}
              onChange={onPhoneInputChange('tel_work')}
            />
          </Form.Item>
          <Form.Item label='Telephone (mobile)' name='tel_mobile'>
            <PhoneInput
              inputStyle={{width: '100%'}}
              country={'us'}
              disableCountryGuess={false}
              onChange={onPhoneInputChange('tel_mobile')}
            />
          </Form.Item>
        </React.Fragment>
      )}

      {ui.map(({
        label, name, props
      }) =>
        props ? (
          <FormSelect key={label} label={label} name={name} {...props} />
        ) : (
          <Form.Item label={label} name={name} key={label}>
            <Input />
          </Form.Item>
        )
      )}
    </Col>
  ))
  return <Row gutter={16}>{data}</Row>
}