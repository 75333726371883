import {APP_NAME} from 'consts'

export const moduleName = 'verifications'

export const FETCH_VERIFICATIONS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_VERIFICATIONS_REQUEST`
export const FETCH_VERIFICATIONS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_VERIFICATIONS_SUCCESS`
export const FETCH_VERIFICATIONS_ERROR = `${APP_NAME}/${moduleName}/FETCH_VERIFICATIONS_ERROR`

export const FETCH_ENROLMENTS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_ENROLMENTS_REQUEST`
export const FETCH_ENROLMENTS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_ENROLMENTS_SUCCESS`
export const FETCH_ENROLMENTS_ERROR = `${APP_NAME}/${moduleName}/FETCH_ENROLMENTS_ERROR`

export const FETCH_CHECKS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_CHECKS_REQUEST`
export const FETCH_CHECKS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_CHECKS_SUCCESS`
export const FETCH_CHECKS_ERROR = `${APP_NAME}/${moduleName}/FETCH_CHECKS_ERROR`

export const FETCH_DAILY_REPORT_REQUEST = `${APP_NAME}/${moduleName}/FETCH_DAILY_REPORT_REQUEST`
export const FETCH_DAILY_REPORT_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_DAILY_REPORT_SUCCESS`
export const FETCH_DAILY_REPORT_ERROR = `${APP_NAME}/${moduleName}/FETCH_DAILY_REPORT_ERROR`

export const CREATE_VERIFICATION_REQUEST = `${APP_NAME}/${moduleName}/CREATE_VERIFICATION_REQUEST`
export const CREATE_VERIFICATION_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_VERIFICATION_SUCCESS`
export const CREATE_VERIFICATION_ERROR = `${APP_NAME}/${moduleName}/CREATE_VERIFICATION_ERROR`

export const GET_VERIFICATIONS_INFO_REQUEST = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_INFO_REQUEST`
export const GET_VERIFICATIONS_INFO_SUCCESS = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_INFO_SUCCESS`
export const GET_VERIFICATIONS_INFO_ERROR = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_INFO_ERROR`

export const UPDATE_VERIFICATION_REQUEST = `${APP_NAME}/${moduleName}/UPDATE_VERIFICATION_REQUEST`
export const UPDATE_VERIFICATION_SUCCESS = `${APP_NAME}/${moduleName}/UPDATE_VERIFICATION_SUCCESS`
export const UPDATE_VERIFICATION_ERROR = `${APP_NAME}/${moduleName}/UPDATE_VERIFICATION_ERROR`

export const CREATE_TRULIOO_RECORD_REQUEST = `${APP_NAME}/${moduleName}/CREATE_TRULIOO_RECORD_REQUEST`
export const CREATE_TRULIOO_RECORD_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_TRULIOO_RECORD_SUCCESS`
export const CREATE_TRULIOO_RECORD_ERROR = `${APP_NAME}/${moduleName}/CREATE_TRULIOO_RECORD_ERROR`
