import {ApiNames} from './types'

// Bad API. Need to rename
export const API_NAMES: ApiNames = {
  detail1_label: 'detail_1',
  detail2_label: 'detail_2',
  detail3_label: 'detail_3',
  upload_back: 'file_id_back',
  upload_front: 'file_id_front',
  has_expiry: 'expiry',
}

export const COMMON_COL_PROPS = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12
}

export const ROW_GUTTER = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32
}
