import React, {
  Fragment,
  useState
} from 'react'
import {
  InputDatePicker,
  Row,
  Col,
  Typography,
  Table,
  Button,
  Descriptions,
  Card,
  Tooltip,
  Divider,
  Switch
} from 'components'
import dayjs from 'dayjs'
import moment from 'moment'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'

interface IState {
  openPositionsData: Array<String>[];
  openPositionsTotalData: Array<String>[];
  tradesReportData: Array<String>[];
  tradesReportTotalData: Array<String>[];
  headers: string[];
  name: string;
  dateSelected: boolean;
  startDate: Date;
  endDate: Date;
  totalTakeprofit: number,
  // totalProfit: number,
  totalSwaps: number,
  totalCommission: number,
  totalMargin: number,
  totalProfitTradesReport: number,
  totalExecutionFee: number,
  fromDate: string,
  fromDateNewYork: string,
  toDate: string,
  toDateNewYork: string,
  accountCurrency: string,
  accountName: string,
  tradedNotional: string,
  openingBalance: number,
  commission: string,
  swap: string,
  markUp: string,
  spread: string,
  periodProfitLoss: number,
  openPositionsTotalProfit: number,
  closedPositionsTotalProfit: number,
}
const KEY: string = '999'

export const Derivatives: React.FC = () => {
  const {account_slug, account_name} = useSelector((state: any) => appStateSelectors.stateSelector(state).currentAccountRecord)
  const [state, setState] = useState<IState>({
    openPositionsData: [],
    openPositionsTotalData: [],
    tradesReportData: [],
    tradesReportTotalData: [],
    headers: [],
    name: '',
    dateSelected: false,
    startDate: new Date(),
    endDate: new Date(),
    totalTakeprofit: 0,
    // totalProfit: 0,
    totalSwaps: 0,
    totalCommission: 0,
    totalMargin: 0,
    totalProfitTradesReport: 0,
    totalExecutionFee: 0,
    fromDate: '',
    toDate: '',
    fromDateNewYork: '',
    toDateNewYork: '',
    accountCurrency: 'USD',
    accountName: account_name,
    tradedNotional: '',
    openingBalance: 0,
    commission: '',
    swap: '',
    markUp: '',
    spread: '',
    periodProfitLoss: 0,
    openPositionsTotalProfit: 0,
    closedPositionsTotalProfit: 0
  })
  const [checked, setChecked] = useState(true)
  const [checkedTradesReport, setTradesReportChecked] = useState(true)
  const [loading, setLoading] = useState(false)

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day')
  }

  const handleFetch = async (startDateTimeMoment: dayjs.Dayjs): Promise<any> => {
    const currentDatetimeMoment = dayjs()
    const currentDatetimeUnix: Number = currentDatetimeMoment.utc().unix() * 1000

    startDateTimeMoment.utc().hour(currentDatetimeMoment.get('hour'))
    startDateTimeMoment.utc().minute(currentDatetimeMoment.get('minute'))
    startDateTimeMoment.utc().second(currentDatetimeMoment.get('second'))

    const startDateTimeUnix: Number = startDateTimeMoment.unix() * 1000

    const axiosConfig = {
      baseURL: 'https://mario.pimwatech.com/ctin',
      method: 'post',
    } as const

    // open-positions-report
    const openPositionsReportResponse = await axios({
      ...axiosConfig,
      url: '/open-positions-report',
      data: {
        key: KEY,
        account: account_slug, // ctin , gat ...
      }
    })
    const openPositionsData: any = []

    if (openPositionsReportResponse.data.te_response.data) {
      openPositionsReportResponse.data.te_response.data.map((item: string[]) => {
        openPositionsData.push({
          positionId: item[0],
          account: item[1],
          login: item[2],
          groupname: item[3],
          instrumentname: item[4],
          instrumentgroup: item[5],
          amount: item[6],
          openprice: item[7],
          buyselltype: item[8],
          opentime: item[9],
          currentprice: item[10],
          stoploss: item[11],
          sllimitprice: item[12],
          takeprofit: item[13],
          profit: item[14],
          swaps: item[15],
          commission: item[16],
          currency: item[17],
          routename: item[18],
          margin: item[19],
          autotrade: item[20],
          terminaltype: item[21],
          tradingmode: item[22],
          omnibusname: item[23],
          exchange: item[24],
        })
      })
    }
    const tradesReportResponse = await axios({
      ...axiosConfig,
      url: '/trades-report',
      data: {
        key: KEY,
        account: account_slug, // ctin , gat ...
        startDate: startDateTimeUnix,
        endDate: currentDatetimeUnix
      }
    })
    const tradesReportData: any = []
    if (tradesReportResponse.data.te_response.data) {
      tradesReportResponse.data.te_response.data.map((item: string[]) => {
        tradesReportData.push({
          account: item[0],
          sender: item[1],
          instrumentname: item[2],
          orderid: item[3],
          tradeid: item[4],
          externalid: item[5],
          routename: item[6],
          operation: item[7],
          time: item[8],
          price: item[9],
          amount: item[10],
          crossprice: item[11],
          profit: item[12],
          executionfee: item[13],
          extprice: item[14],
          tradevolume: item[15],
          purchaseprice: item[16],
          exchange: item[17],
          type: item[18],
          valuedate: item[19],
          autotrade: item[20],
          terminaltype: item[21],
          rebates: item[22],
          expyear: item[23],
          expmonth: item[24],
          expday: item[25],
          derivativetype: item[26],
          strikeprice: item[27],
          external_order_id: item[28],
          trades_bought: item[29],
          trades_sold: item[30],
          symbol_type: item[31],
          tradingmode: item[32],
          tradableinstrumentid: item[33],
          omnibusname: item[34],
          clientorderid: item[35],
          execvenue: item[36],
          description: item[37],
        })
      })
    }
    
    const gatProfitLossResponse = await axios({
      ...axiosConfig,
      url: '/report-1642798',
      data: {
        key: KEY,
        account: account_slug, // ctin , gat ...
        startDate: startDateTimeUnix,
        endDate: currentDatetimeUnix
      }
    })
    console.log(gatProfitLossResponse)
    let spread = 0
    let swap = 0
    let commission = 0
    let markUp = 0
    for (const i in gatProfitLossResponse.data.te_response.data) {
      const record = gatProfitLossResponse.data.te_response.data[i]
      console.log(record)
      switch (record[0]) {
        case 'spread_GAT_collection_LP':
          spread = parseFloat(record[1])
          break
        case 'swap_GAT_collection_LP':
          swap = parseFloat(record[1])
          break
        case 'commission_LP_GAT_collect':
          commission = parseFloat(record[1])
          break
        case 'markup_GAT_collection_LP':
          markUp = parseFloat(record[1])
          break
      }
    }

    const openPositionsGroupData = openPositionsData.reduce((group: any, item: any) => {
      const { instrumentname } = item
      group[instrumentname] = group[instrumentname] ?? []
      group[instrumentname].push(item)
      return group
    }, {})

    const tradesReportGroupData = tradesReportData.reduce((group: any, item: any) => {
      const { instrumentname } = item
      group[instrumentname] = group[instrumentname] ?? []
      group[instrumentname].push(item)
      return group
    }, {})

    const openPositionsTotalData: any = []
    const tradesReportTotalData: any = []

    let openPositionsTotalProfit = 0
    for (const key in openPositionsGroupData) {
      let totalAmount = 0
      let currentPrice = 0
      let totalProfit = 0
      let totalSwaps = 0
      openPositionsGroupData[key].forEach((item: any) => {
        if (item.buyselltype == 'Sell') {
          totalAmount -= parseFloat(item.amount)
        } else {
          totalAmount += parseFloat(item.amount)
        }
        currentPrice = parseFloat(item.currentprice)
        totalProfit += parseFloat(item.profit)
        totalSwaps += parseFloat(item.swaps)
      })
      openPositionsGroupData[key].totalAmount = totalAmount
      openPositionsGroupData[key].currentPrice = currentPrice
      openPositionsGroupData[key].exposure = totalAmount * currentPrice
      openPositionsGroupData[key].totalProfit = totalProfit
      openPositionsGroupData[key].totalSwaps = totalSwaps

      console.log(key, openPositionsGroupData[key])

      openPositionsTotalData.push({
        key: key,
        totalAmount: openPositionsGroupData[key].totalAmount.toFixed(2),
        currentPrice: openPositionsGroupData[key].currentPrice.toFixed(2),
        profit: openPositionsGroupData[key].totalProfit.toFixed(2)
      })
      openPositionsTotalProfit += openPositionsGroupData[key].totalProfit

    }
    console.log('openPositionsTotalData', openPositionsTotalData)
    console.log('openPositionsTotalProfit', openPositionsTotalProfit)
    // setState({
    //   ...state,
    //   openPositionsTotalData: openPositionsTotalData,
    //   openPositionsTotalProfit: openPositionsTotalProfit.toFixed()
    // })

    // let totalProfit = 0
    let totalSwaps = 0
    for (const key in openPositionsGroupData) {
      // totalProfit += openPositionsGroupData[key].totalProfit
      totalSwaps += openPositionsGroupData[key].totalSwaps
    }

    for (const key in tradesReportGroupData) {
      let totalAmount = 0
      let totalProfit = 0
      tradesReportGroupData[key].forEach((item: any) => {
        if (item.buyselltype === 'Sell') {
          totalAmount -= parseFloat(item.amount)
        } else {
          totalAmount += parseFloat(item.amount)
        }
        totalProfit += parseFloat(item.profit)
      })
      tradesReportGroupData[key].totalAmount = totalAmount
      tradesReportGroupData[key].totalProfit = totalProfit

      console.log(key, tradesReportGroupData[key])

      tradesReportTotalData.push({
        key: key,
        totalAmount: tradesReportGroupData[key].totalAmount.toFixed(2),
        profit: tradesReportGroupData[key].totalProfit.toFixed(2)
      })

      console.log('tradesReportTotalData', tradesReportTotalData)
      // setState({
      //   ...state,
      //   openPositionsTotalData: openPositionsTotalData,
      //   openPositionsTotalProfit: openPositionsTotalProfit.toFixed(),
      //   tradesReportTotalData: tradesReportTotalData,
      // })
    }

    let totalProfitTradesReport = 0
    let totalExecutionFee = 0

    if (tradesReportData?.length > 0){
      tradesReportData.forEach((pageData: any) => {
        totalProfitTradesReport += Number(pageData.profit)
        totalExecutionFee += Number(pageData.executionfee)
      })
    }

    // const periodProfitLoss = totalProfitTradesReport - spread - swap - commission - markUp
    const periodProfitLoss = totalProfitTradesReport - commission

    setState({
      ...state,
      ...openPositionsReportResponse.data.te_response,
      ...tradesReportResponse.data.te_response,
      openPositionsData: openPositionsData,
      openPositionsTotalData: openPositionsTotalData,
      openPositionsTotalProfit: openPositionsTotalProfit,
      // totalProfit: totalProfit.toFixed(2),
      totalSwaps: totalSwaps.toFixed(2),
      closedPositionsTotalProfit: totalProfitTradesReport,
      totalExecutionFee: totalExecutionFee.toFixed(2),
      tradesReportData: tradesReportData,
      tradesReportTotalData: tradesReportTotalData,
      spread,
      swap,
      markUp,
      commission,
      periodProfitLoss,
      dateSelected: true,
      startDate: startDateTimeMoment,
      endDate: currentDatetimeUnix,
      fromDate: startDateTimeMoment ? `${dayjs(startDateTimeMoment).format('DD/MM/YYYY HH:mm:ss')} UTC` : '',
      toDate: `${currentDatetimeMoment.utc().format('DD/MM/YYYY HH:mm:ss')} UTC`,
      fromDateNewYork: startDateTimeMoment ? `${dayjs(startDateTimeMoment).utcOffset('-04:00').format('DD/MM/YYYY HH:mm:ss')} NYC` : '',
      toDateNewYork: `${currentDatetimeMoment.utcOffset('-04:00').format('DD/MM/YYYY HH:mm:ss')} NYC`,
    })
    setLoading(false)
  }
  
  const columnsOpenPositionsTotalTable = [
    {
      key: 'key',
      title: 'Instrument',
      dataIndex: 'key',
    },
    {
      key: 'totalAmount',
      title: 'Total Amount',
      dataIndex: 'totalAmount',
    },
    {
      key: 'currentPrice',
      title: 'Current Price',
      dataIndex: 'currentPrice',
    },
    {
      key: 'profit',
      title: 'Profit',
      dataIndex: 'profit',
    },
  ]

  const columnsTradesReportTotalTable = [
    {
      key: 'key',
      title: 'Instrument',
      dataIndex: 'key',
    },
    {
      key: 'totalAmount',
      title: 'Total Amount',
      dataIndex: 'totalAmount',
    },
    {
      key: 'profit',
      title: 'Profit',
      dataIndex: 'profit',
    },
  ]

  const columnsOpenPositionsTable = [
    {
      title: 'Position ID',
      dataIndex: 'positionId',
      width: 150,
      ellipsis: {showTitle: false},
      render: (positionId: any) => (
        <Tooltip placement="topLeft" title={positionId}>
          {positionId}
        </Tooltip>
      ),
    },
    {
      title: 'Login',
      dataIndex: 'login',
      width: 150,
      ellipsis: {showTitle: false},
      render: (login: any) => (
        <Tooltip placement="topLeft" title={login}>
          {login}
        </Tooltip>
      ),
    },
    {
      title: 'Instrument Name',
      dataIndex: 'instrumentname',
      width: 150,
      ellipsis: {showTitle: false},
      render: (instrumentname: any) => (
        <Tooltip placement="topLeft" title={instrumentname}>
          {instrumentname}
        </Tooltip>
      ),
    },
    {
      title: 'Instrument Group',
      dataIndex: 'instrumentgroup',
      width: 150,
      ellipsis: {showTitle: false},
      render: (instrumentgroup: any) => (
        <Tooltip placement="topLeft" title={instrumentgroup}>
          {instrumentgroup}
        </Tooltip>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 150,
      ellipsis: {showTitle: false},
      render: (amount: any) => (
        <Tooltip placement="topLeft" title={amount}>
          {amount}
        </Tooltip>
      ),
    },
    {
      title: 'Open Price',
      dataIndex: 'openprice',
      width: 150,
      ellipsis: {showTitle: false},
      render: (openprice: any) => (
        <Tooltip placement="topLeft" title={openprice}>
          {openprice}
        </Tooltip>
      ),
    },
    {
      title: 'Buy/Sell Type',
      dataIndex: 'buyselltype',
      width: 150,
      ellipsis: {showTitle: false},
      render: (buyselltype: any) => (
        <Tooltip placement="topLeft" title={buyselltype}>
          {buyselltype}
        </Tooltip>
      ),
    },
    {
      title: 'Open Time',
      dataIndex: 'opentime',
      width: 150,
      ellipsis: {showTitle: false},
      render: (opentime: any) => (
        <Tooltip placement="topLeft" title={opentime}>
          {opentime}
        </Tooltip>
      ),
    },
    {
      title: 'Current Price',
      dataIndex: 'currentprice',
      width: 150,
      ellipsis: {showTitle: false},
      render: (currentprice: any) => (
        <Tooltip placement="topLeft" title={currentprice}>
          {currentprice}
        </Tooltip>
      ),
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      width: 150,
      ellipsis: {showTitle: false},
      render: (profit: any) => (
        <Tooltip placement="topLeft" title={profit}>
          {profit}
        </Tooltip>
      ),
    },
    {
      title: 'Swaps',
      dataIndex: 'swaps',
      width: 150,
      ellipsis: {showTitle: false},
      render: (swaps: any) => (
        <Tooltip placement="topLeft" title={swaps}>
          {swaps}
        </Tooltip>
      ),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      width: 150,
      ellipsis: {showTitle: false},
      render: (commission: any) => (
        <Tooltip placement="topLeft" title={commission}>
          {commission}
        </Tooltip>
      ),
    },
    {
      title: 'Margin',
      dataIndex: 'margin',
      width: 150,
      ellipsis: {showTitle: false},
      render: (margin: any) => (
        <Tooltip placement="topLeft" title={margin}>
          {margin}
        </Tooltip>
      ),
    },
  ]
  
  const columnsTradesReportTable = [
    {
      title: 'Account',
      width: 150,
      dataIndex: 'account',
      ellipsis: {showTitle: false},
      render: (account: any) => (
        <Tooltip placement="topLeft" title={account}>
          {account}
        </Tooltip>
      ),
    },
    {
      title: 'Instrument Name',
      width: 150,
      dataIndex: 'instrumentname',
      ellipsis: {showTitle: false},
      render: (instrumentname: any) => (
        <Tooltip placement="topLeft" title={instrumentname}>
          {instrumentname}
        </Tooltip>
      ),
    },
    {
      title: 'Order ID',
      width: 150,
      dataIndex: 'orderid',
      ellipsis: {showTitle: false},
      render: (orderid: any) => (
        <Tooltip placement="topLeft" title={orderid}>
          {orderid}
        </Tooltip>
      ),
    },
    {
      title: 'Trade ID',
      width: 150,
      dataIndex: 'tradeid',
      ellipsis: {showTitle: false},
      render: (tradeid: any) => (
        <Tooltip placement="topLeft" title={tradeid}>
          {tradeid}
        </Tooltip>
      ),
    },
    {
      title: 'Operation',
      width: 150,
      dataIndex: 'operation',
      ellipsis: {showTitle: false},
      render: (operation: any) => (
        <Tooltip placement="topLeft" title={operation}>
          {operation}
        </Tooltip>
      ),
    },
    {
      title: 'Time',
      width: 150,
      dataIndex: 'time',
      ellipsis: {showTitle: false},
      render: (time: any) => (
        <Tooltip placement="topLeft" title={time}>
          {time}
        </Tooltip>
      ),
    },
    {
      title: 'Price',
      width: 150,
      dataIndex: 'price',
      ellipsis: {showTitle: false},
      render: (price: any) => (
        <Tooltip placement="topLeft" title={price}>
          {price}
        </Tooltip>
      ),
    },
    {
      title: 'Amount',
      width: 150,
      dataIndex: 'amount',
      ellipsis: {showTitle: false},
      render: (amount: any) => (
        <Tooltip placement="topLeft" title={amount}>
          {amount}
        </Tooltip>
      ),
    },
    {
      title: 'Profit',
      width: 150,
      dataIndex: 'profit',
      ellipsis: {showTitle: false},
      render: (profit: any) => (
        <Tooltip placement="topLeft" title={profit}>
          {profit}
        </Tooltip>
      ),
    },
    {
      title: 'Execution Fee',
      width: 150,
      dataIndex: 'executionfee',
      ellipsis: {showTitle: false},
      render: (executionfee: any) => (
        <Tooltip placement="topLeft" title={executionfee}>
          {executionfee}
        </Tooltip>
      ),
    },
    {
      title: 'Ext Price',
      width: 150,
      dataIndex: 'extprice',
      ellipsis: {showTitle: false},
      render: (extprice: any) => (
        <Tooltip placement="topLeft" title={extprice}>
          {extprice}
        </Tooltip>
      ),
    },
    {
      title: 'Trade Volume',
      width: 150,
      dataIndex: 'tradevolume',
      ellipsis: {showTitle: false},
      render: (tradevolume: any) => (
        <Tooltip placement="topLeft" title={tradevolume}>
          {tradevolume}
        </Tooltip>
      ),
    },
    {
      title: 'Purchase Price',
      width: 150,
      dataIndex: 'purchaseprice',
      ellipsis: {showTitle: false},
      render: (purchaseprice: any) => (
        <Tooltip placement="topLeft" title={purchaseprice}>
          {purchaseprice}
        </Tooltip>
      ),
    },
    {
      title: 'Value Date',
      width: 150,
      dataIndex: 'valuedate',
      ellipsis: {showTitle: false},
      render: (valuedate: any) => (
        <Tooltip placement="topLeft" title={valuedate}>
          {valuedate}
        </Tooltip>
      ),
    },
    {
      title: 'Trades Bought',
      width: 150,
      dataIndex: 'trades_bought',
      ellipsis: {showTitle: false},
      render: (trades_bought: any) => (
        <Tooltip placement="topLeft" title={trades_bought}>
          {trades_bought}
        </Tooltip>
      ),
    },
    {
      title: 'Trades Sold',
      width: 150,
      dataIndex: 'trades_sold',
      ellipsis: {showTitle: false},
      render: (trades_sold: any) => (
        <Tooltip placement="topLeft" title={trades_sold}>
          {trades_sold}
        </Tooltip>
      ),
    },
    {
      title: 'Description',
      width: 150,
      dataIndex: 'description',
      ellipsis: {showTitle: false},
      render: (description: any) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
  ]

  const resetDatepicker = () => {
    setState({
      ...state,
      openPositionsData: [],
      tradesReportData: [],
      dateSelected: false,
      totalTakeprofit: 0,
      openPositionsTotalProfit: 0,
      // totalProfit: 0,
      totalSwaps: 0,
      totalCommission: 0,
      totalMargin: 0,
      commission: '',
      swap: '',
      markUp: '',
      spread: '',
      periodProfitLoss: 0,
      closedPositionsTotalProfit: 0,
      totalExecutionFee: 0,
      startDate: new Date(),
      endDate: new Date(),
      fromDate: '',
      toDate: '',
      fromDateNewYork: '',
      toDateNewYork: '',
    })
  }

  const handleChange = async (checked: boolean | ((prevState: boolean) => boolean)) => {
    setChecked(checked)
  }

  const handleTradesReportChange = async (checked: boolean | ((prevState: boolean) => boolean)) => {
    setTradesReportChecked(checked)
  }

  return (
    <Fragment>

      <Row>
        <Col span={24} style={{textAlign: 'right'}}>
          {!state?.dateSelected
            ? <InputDatePicker
              // defaultValue={dayjs()}
              formItem={false}
              placeholder={'Select Start Date'}
              disabledDate={disabledDate}
              onChange={async (dateObject) => {
                if (dateObject){
                  setLoading(true)
                  await handleFetch(dateObject)
                } else {
                  setState({
                    ...state,
                    dateSelected: false
                  })
                }
              }}
            />
            : <Button type={'primary'} onClick={resetDatepicker}>Reset</Button>}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{textAlign: 'center'}}>
          <Typography.Title level={4}> Derivative Company Statement </Typography.Title>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Typography.Title level={5}>Trading Statement</Typography.Title>
            <Descriptions
              bordered
              size='small'
              style={{marginBottom: 24}}
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1
              }}
            >
              <Descriptions.Item label='From Date/Time'>
                <strong>{state.fromDate}</strong><br/>
                <em>{state.fromDateNewYork}</em>
              </Descriptions.Item>

              <Descriptions.Item label='To Date/Time'>
                <strong>{state.toDate}</strong><br/>
                <em>{state.toDateNewYork}</em>
              </Descriptions.Item>

              <Descriptions.Item label='Account Currency'>
                <strong>{state.accountCurrency}</strong>
              </Descriptions.Item>

              <Descriptions.Item label='Account Name'>
                <strong>{state.accountName}</strong>
              </Descriptions.Item>

              <Descriptions.Item label=' Traded Notional (USD)'>
                <strong>{state.tradedNotional}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Typography.Title level={5}>Account Summary (USD)</Typography.Title>
            <Descriptions
              bordered
              size='small'
              style={{marginBottom: 24}}
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1
              }}
            >
                      
              <Descriptions.Item label='Opening Balance'>
                <strong>{state.openingBalance.toFixed(2)}</strong><br/>
                <em>To be read from database</em>
              </Descriptions.Item>

              <Descriptions.Item label='Realised Profit/Loss'>
                <strong>{state.closedPositionsTotalProfit.toFixed(2)}</strong><br/>
                {state.closedPositionsTotalProfit > 0 && <em>For the period ...</em>}
              </Descriptions.Item>

              <Descriptions.Item label='Subtract Commission'>
                <strong>{state.commission}</strong>
              </Descriptions.Item>

              <Descriptions.Item label='Swap'>
                <strong>{state.swap}</strong><br/>
                <em>Unsure how to apply this - see notes</em>
              </Descriptions.Item>

              <Descriptions.Item label='Includes Mark Up'>
                <strong>{state.markUp}</strong><br/>
                <em>Unsure how to apply this - see notes</em>
              </Descriptions.Item>

              <Descriptions.Item label='Includes Spread'>
                <strong>{state.spread}</strong><br/>
                <em>Unsure how to apply this - see notes</em>
              </Descriptions.Item>

              <Descriptions.Item label='Gross Profit/Loss'>
                <strong>{state.periodProfitLoss.toFixed(2)}</strong><br/>
                <em>periodProfitLoss = totalProfitTradesReport - commission</em>
              </Descriptions.Item>

              <Divider/>

              <Descriptions.Item label='Unrealised Profit/Loss'>
                <strong>{state.openPositionsTotalProfit.toFixed(2)}</strong><br/>
                {state.openPositionsTotalProfit > 0 && <em>As at ...</em>}
              </Descriptions.Item>

            </Descriptions>
          </Card>
        </Col>
      </Row>

      <Divider/>

      <Row>
        <Col span={24}>
          <Row>
            <Col span={20}>
              <h3>Open Positions</h3>
            </Col>
            <Col span={4}push={1}>
              <Switch loading={loading} onChange={handleChange} checked={checked} /> Show / Hide
            </Col>
          </Row>
          {checked &&
          
            <Table
              loading={loading}
              size="small"
              scroll={{ x: 1500 }}
              columns={columnsOpenPositionsTable}
              dataSource={state.openPositionsData}
              bordered={true}
              footer={content =>
                state.openPositionsTotalProfit !== 0 &&
                <Row>
                  <Col span={3}>
                    <strong>TOTALS:</strong>
                  </Col>
                  <Col span={21}>
                    <Table
                      loading={loading}
                      dataSource={state.openPositionsTotalData}
                      columns={columnsOpenPositionsTotalTable}
                      bordered={true} />
                  </Col>
                </Row>
              
              }/>

          }
          {!checked &&
            <Row>
              <Col span={3}>
                <strong>TOTALS:</strong>
              </Col>
              <Col span={21}>
                <Table
                  dataSource={state.openPositionsTotalData}
                  columns={columnsOpenPositionsTotalTable}
                  bordered={true}
                />
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Divider/>

      <Row>
        <Col span={24}>
          <Row>
            <Col span={20}>
              <h3>Trades Report</h3>
            </Col>
            <Col span={4}push={1}>
              <Switch loading={loading} onChange={handleTradesReportChange} checked={checkedTradesReport} /> Show / Hide
            </Col>
          </Row>
          {checkedTradesReport &&
          <Table
            loading={loading}
            size="small"
            scroll={{x: 1500}}
            columns={columnsTradesReportTable}
            dataSource={state.tradesReportData}
            bordered={true}
            footer={content =>
              state.openPositionsTotalProfit !== 0 &&
              <Row>
                <Col span={3}>
                  <strong>TOTALS:</strong>
                </Col>
                <Col span={21}>
                  <Table
                    dataSource={state.tradesReportTotalData}
                    columns={columnsTradesReportTotalTable}
                    bordered={true}
                  />
                </Col>
              </Row>
            }
          />
          }
          {!checkedTradesReport &&
            <Row>
              <Col span={3}>
                <strong>TOTALS:</strong>
              </Col>
              <Col span={21}>
                <Table
                  dataSource={state.tradesReportTotalData}
                  columns={columnsTradesReportTotalTable}
                  bordered={true}
                />
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </Fragment>
  )
}

export default Derivatives