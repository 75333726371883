import React, {
  useEffect,
  useState
} from 'react'
import {
  connect,
  useDispatch
} from 'react-redux'
import {
  Button,
  Card,
  Skeleton,
  Table
} from 'antd'

import {listHOC} from 'common'
import {
  settingsActions,
  settingsSelectors
} from 'configs/ducks/settings/index'
import {JournalCreateNewComponent} from './journalCreateNewComponent'
import {appStateSelectors} from 'configs/ducks/appState'
import API from '@aws-amplify/api'
import moment from 'moment'
import {JournalsViewDrawerComponent} from './journalsViewDrawerComponent'
import {SyncOutlined} from '@ant-design/icons'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} leadsList
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const JournalsPageComponent = ({
  getColumnSearchProps, journals, currentAccountID, loading, staffId, getTopics, fetchCb, ...rest
}) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isModalVisible: false,
    clients: [],
    leads: [],
    visible: false,
    journalDetail: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      journalDetail: record,
      visible: bool
    })
  }

  const toggleDrawerState = bool => {
    setState({
      ...state,
      visible: bool
    })
  }

  const callLeads = () => {
    dispatch(getAllJournals())
  }
  const toggleModal = async bool => {
    setState({
      ...state,
      isModalVisible: bool
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (highlighter, record) => (
          <Button type='link' onClick={() => toggleDrawer(true, record)}>
            {highlighter}
          </Button>
        )
      })
    },
    {
      title: 'Journal Type',
      dataIndex: 'adminJournalType',
      ...getColumnSearchProps({
        dataIndex: 'adminJournalType',
        placeholder: 'adminJournalType'
      })
    },
    {
      title: 'Staff Member',
      dataIndex: 'staff',
      ...getColumnSearchProps({
        dataIndex: 'staff_member',
        placeholder: 'First Name'
      })
    },
    {
      title: 'Client',
      dataIndex: 'client',
      ...getColumnSearchProps({
        dataIndex: 'client',
        placeholder: 'Last Name'
      })
    },
    {
      title: 'Lead',
      dataIndex: 'lead',
      ...getColumnSearchProps({
        dataIndex: 'lead',
        placeholder: 'Email'
      })
    },
    {
      title: 'Title',
      dataIndex: 'title',
      ...getColumnSearchProps({
        dataIndex: 'title',
        placeholder: 'Mobile'
      })
    },
    {
      title: 'Created',
      dataIndex: 'recordCreated',
      ...getColumnSearchProps({
        dataIndex: 'created',
        placeholder: 'Date'
      }),
      render: data => <span>{moment(data).format('DD/MM/YYYY - HH:mm:ss')}</span>
    }
  ]

  useEffect(() => {
    asyncFunction()
  }, [])

  const asyncFunction = async () => {
    try {
      const [clients, leads] = await Promise.all([
        API.get('clients', `/getSimpleListByAccountId/${currentAccountID}`),
        API.get('leads', `/getSimpleListByAccountId/${currentAccountID}`)
      ])

      setState({
        ...state,
        leads: leads.payload,
        clients: clients.payload,
        isModalVisible: false
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const handle_create_new = () => {
    toggleModal(true)
  }

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <React.Fragment>
      <div
        style={{
          padding: 24,
          background: '#fff',
          minHeight: 360
        }}
      >
        <Card
          style={{minHeight: 360}}
          title='Journals'
          extra={[
            <Button key='2' onClick={handle_create_new}>
              Create New
            </Button>
          ]}
        >
          <div style={{paddingTop: 24}}>
            <Skeleton active loading={loading}>
              {/* <ClientViewPageComponentContainer client_id={this.props.match.params.client_id} /> */}
              {/* <JournalsPageComponent /> */}
              <Table
                rowKey='id'
                columns={columns}
                dataSource={journals}
                loading={loading}
                scroll={{x: true}}
                pagination={{
                  total: journals.length,
                  showTotal: total => `Total ${total} records`,
                  pageSizeOptions: ['20', '50', '100'],
                  defaultPageSize: 20,
                  showSizeChanger: true
                }}
              />
            </Skeleton>
            <JournalCreateNewComponent
              toggleModal={toggleModal}
              {...state}
              asyncFunction={callLeads}
              currentAccountId={currentAccountID}
              staffId={staffId}
            />
            {state.journalDetail && <JournalsViewDrawerComponent
              toggleModal={toggleDrawerState}
              visible={state.visible}
              journalDetail={state.journalDetail}
            />}
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

const {getAllJournals} = settingsActions

const mapStateToProps = state => {
  const {loading, journals} = settingsSelectors.stateSelector(state)
  return {
    loading,
    journals,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    staffId: appStateSelectors.stateSelector(state).currentStaffRecord.id
  }
}

export default connect(mapStateToProps, {fetchCb: getAllJournals})(listHOC(JournalsPageComponent))
