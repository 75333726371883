import {INPUT_CHANGE, CLEAR_STATE} from '../constants'

export const inputChange = (inputName, inputValue) => ({
  type: INPUT_CHANGE,
  payload: {
    inputName,
    inputValue
  }
})

export const clearState = () => ({type: CLEAR_STATE})