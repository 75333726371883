import * as React from 'react'
import {
  AutoComplete,
  AutoCompleteProps,
  Input,
  InputProps
} from 'antd'

interface ExtendedAutocompleteProps extends Omit<AutoCompleteProps, 'placeholder' | 'options'>, Pick<InputProps, 'size' | 'placeholder'> {
  loading?: boolean
  options: any[];
  getOptionProps: (option: any) => {
    value: string | number;
    label: React.ReactNode;
  }
}

const ExtendedAutocomplete: React.FC<ExtendedAutocompleteProps> = ({
  placeholder, size, options = [],getOptionProps, loading, ...props
}) => {
  return (
    <AutoComplete
      {...props}
      options={options.map(getOptionProps)}
    >
      <Input.Search
        size='middle'
        placeholder={placeholder}
        loading={loading}
        enterButton
      />
    </AutoComplete>
  )
}

export default ExtendedAutocomplete
