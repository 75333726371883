import React from 'react'
import {
  Form,
  Checkbox
} from 'antd'

const StaffNewStep3 = ({form, ...rest}) => {
  return (
    <>
      <span>{
        form.getFieldValue('first_name')
      }</span>
      <Form.Item hasFeedback name='createStaffLogin' valuePropName='checked'>
        <Checkbox>Create Staff Login</Checkbox>
      </Form.Item>
      <p>
        Press “Confirm” below to proceed.
      </p>
    </>
  )
}

export default StaffNewStep3