import React, {
  useEffect,
  Fragment,
  useState
} from 'react'
  
import {
  Col,
  Row,
  Spin,
  Form,
  Button,
  Descriptions,
  Drawer,
  Space,
  Checkbox,
  Tag
} from 'antd'
import moment from 'moment'
import { API } from 'aws-amplify'
  
export const ScheduledVerificationsDrawerComponent = ({
  visible, VerificationChecksDrawerData, toggleModal, currentStaffId, currentStaff, getDataByClientId, clientId
}) => {
  const [form] = Form.useForm()
  const [state, setState] = useState({
    visible: false,
    VerificationChecksDrawerData: null,
    form_checkstaff: null,
    form_staffCheckDatetime: null
  })
  
  useEffect(() => {
    if (VerificationChecksDrawerData?.id){
      API.get('client-monitoring', `/scheduled/verifications/${VerificationChecksDrawerData?.id}`).then(
        res => {
          setState({
            ...state,
            data: res.payload,
            VerificationChecksDrawerData: res.payload
          })
          return console.log('payload', res.payload)
        }
      )
    }
  
  }, [VerificationChecksDrawerData])
  
  if (VerificationChecksDrawerData == null) {
    return <div></div>
  }

  const update_record_in_database = async (event, currentStaffId, record) => {
    if (!event.target.checked){
      setState({
        ...state,
        form_checkstaff: '',
        form_staffCheckDatetime: '',
      })
      
      return await API.put('client-monitoring', `/scheduled/update-verifications/${record.id}`, {
        body: {
          staffCheckId: currentStaffId,
          clear: false
        }
      })

    } else if (event.target.checked){
      setState({
        ...state,
        form_checkstaff: currentStaff,
        form_staffCheckDatetime: moment().format('YYYY/MM/DD HH:mm:ss'),
      })

      return await API.put('client-monitoring', `/scheduled/update-verifications/${record.id}`, {
        body: {
          staffCheckId: currentStaffId,
          clear: true
        }
      })
    }
  }
  
  return (
    <Fragment>
      <Drawer
        title='Schedule Verification Detail'
        width={720}
        onClose={() => {
          setState({
            ...state,
            form_checkstaff: null,
            form_staffCheckDatetime: null,
          })
          toggleModal(false)
          getDataByClientId(clientId)
        }}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
                Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!VerificationChecksDrawerData.id}>
            <Form form={form} scrollToFirstError={true} layout='vertical'>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Col xs={24}>
                  {VerificationChecksDrawerData.id && (
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{
                        marginBottom: 24,
                        marginTop: 0
                      }}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='Name'>
                        <strong>{VerificationChecksDrawerData.file_by}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Email'>
                        <strong>{VerificationChecksDrawerData.email}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Timestamp'>
                        <strong>{VerificationChecksDrawerData?.timestamp
                          ? moment(VerificationChecksDrawerData.timestamp).format('YYYY/MM/DD HH:mm:ss')
                          : ''
                        }</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Check Result'>
                        <strong>
                          {VerificationChecksDrawerData?.checkResult === 1
                            ? <Tag color="green">No Change</Tag>
                            : VerificationChecksDrawerData?.checkResult === 0
                              ? <Tag color="red">Result Alert</Tag>
                              : <Tag color="red">Result Alert</Tag>
                          }
                        </strong>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Col>
              </Row>
            </Form>

            <Descriptions
              bordered
              layout='vertical'
              size='small'
              style={{
                marginBottom: 24,
                marginTop: 0
              }}
              column={{
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2
              }}
            >
              <Descriptions.Item label='Check Box'>
                <Checkbox
                  defaultChecked={VerificationChecksDrawerData?.staffCheckDatetime !== null}
                  onChange={(event) => update_record_in_database(
                    event,
                    currentStaffId,
                    VerificationChecksDrawerData
                  )}>
                    Not Relevant
                </Checkbox>
              </Descriptions.Item>
              <Descriptions.Item label='Checked By'>
                {VerificationChecksDrawerData?.staffCheckDatetime === null &&
                  <><p>
                    {state?.form_checkstaff ? state.form_checkstaff : ''}
                  </p>{' '}<p>{state?.form_staffCheckDatetime ? moment(state.form_staffCheckDatetime).format('YYYY/MM/DD HH:mm:ss') : ''}</p></>
                }
                {VerificationChecksDrawerData?.staffCheckDatetime !== null &&
                  <><p>
                    {VerificationChecksDrawerData?.checkStaff ? VerificationChecksDrawerData?.checkStaff : ''}
                  </p>{' '}<p>{VerificationChecksDrawerData?.staffCheckDatetime ? moment(VerificationChecksDrawerData.staffCheckDatetime).format('YYYY/MM/DD HH:mm:ss') : ''}</p></>
                }
              </Descriptions.Item>
            </Descriptions>

          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}