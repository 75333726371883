import * as types from './types'

/**
 * @param {number} accountID
 * @returns {{payload: *, type: string}}
 */
export const fetchVerifications = accountID => ({
  type: types.FETCH_VERIFICATIONS_REQUEST,
  payload: accountID
})

/**
 * @param {number} accountID
 * @returns {{payload: *, type: string}}
 */
export const fetchEnrolments = accountID => ({
  type: types.FETCH_ENROLMENTS_REQUEST,
  payload: accountID
})

/**
 * @param {string} selectDateString
 * @returns {{payload: *, type: string}}
 */
export const fetchDailyReport = selectDateString => ({
  type: types.FETCH_DAILY_REPORT_REQUEST,
  payload: selectDateString
})

/**
 * @param {number} accountID
 * @returns {{payload: *, type: string}}
 */
 export const fetchChecks = accountID => ({
  type: types.FETCH_CHECKS_REQUEST,
  payload: accountID
})

export const createVerification = payload => ({
  type: types.CREATE_VERIFICATION_REQUEST,
  payload
})

/**
 * @param {number} id
 * @returns {{payload: *, type: string}}
 */
export const getVerificationInfo = id => ({
  type: types.GET_VERIFICATIONS_INFO_REQUEST,
  payload: id
})
/**
 * @param {Object} record
 * @returns {{payload: *, type: string}}
 */
export const updateVerification = record => ({
  type: types.UPDATE_VERIFICATION_REQUEST,
  payload: record
})
/**
 * @param {Object} body
 * @param {Object} cb
 * @returns {{payload: *, type: string}}
 */
export const createTruliooRecord = (
  {
    body,
    cb
  }
) => ({
  type: types.CREATE_TRULIOO_RECORD_REQUEST,
  payload: {
    body,
    cb
  }
})
