import {Record} from 'immutable'

import * as types from './types'

const ReducerRecord = Record({
  commissionsFullList: [],
  commissionsSumList: [],
  tradingAccountsList: [],
  tradingTransactionsList: [],
  tradingTransactionsListSnapshot: [],
  tradingTransactionDetail: [],
  tradingTransactionDetailSnapshot: [],
  tradingAccountRecord: {},
  tradingTransactionRecord: {
    document: {},
    fileList: []
  },
  loading: false,
  tradingTransactionsBtnValue: 'all'
})

export default (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.GET_COMMISSIONS_FULL_REQUEST:
    case types.GET_COMMISSIONS_SUM_REQUEST:
    case types.GET_TRADING_ACCOUNTS_REQUEST:
    case types.GET_TRADING_ACCOUNT_REQUEST:
    case types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_REQUEST:
    case types.GET_TRADING_TRANSACTIONS_REQUEST:
    case types.GET_TRADING_TRANSACTION_REQUEST:
    case types.GET_TRADING_TRANSACTION2_REQUEST:
    case types.CREATE_DEPOSIT_WITHDRAWAL_REQUEST:
    case types.UPDATE_STATUS_ID_REQUEST:
      return state.set('loading', true)

    case types.GET_COMMISSIONS_FULL_ERROR:
    case types.GET_COMMISSIONS_SUM_ERROR:
    case types.GET_TRADING_ACCOUNTS_ERROR:
    case types.GET_TRADING_ACCOUNT_ERROR:
    case types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_ERROR:
    case types.GET_TRADING_TRANSACTIONS_ERROR:
    case types.GET_TRADING_TRANSACTION_ERROR:
    case types.GET_TRADING_TRANSACTION2_ERROR:
    case types.CREATE_DEPOSIT_WITHDRAWAL_ERROR:
    case types.CREATE_DEPOSIT_WITHDRAWAL_SUCCESS:
    case types.UPDATE_STATUS_ID_ERROR:
      return state.set('loading', false)
    case types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_SUCCESS:
      return state.merge({
        loading: false,
        tradingTransactionDetail: payload,
        tradingTransactionDetailSnapshot: payload
      })
    case types.REMOVE_DOCUMENT_SUCCESS:
      return state.merge({
        loading: false,
        tradingTransactionRecord: {
          ...state.tradingTransactionRecord,
          document_id: null,
          fileList: []
        }
      })
    case types.GET_COMMISSIONS_FULL_SUCCESS:
      return state.merge({
        loading: false,
        commissionsFullList: payload
      })
    case types.GET_TRADING_ACCOUNTS_SUCCESS:
      return state.merge({
        loading: false,
        tradingAccountsList: payload
      })
    case types.GET_TRADING_ACCOUNT_SUCCESS:
      return state.merge({
        loading: false,
        tradingAccountRecord: payload
      })
    case types.GET_COMMISSIONS_SUM_SUCCESS:
      return state.merge({
        loading: false,
        commissionsSumList: payload
      })
    
    case types.UPDATE_WITHDRAWAL_TARGET:
      return state.merge({
        tradingTransactionRecord: {
          ...state.tradingTransactionRecord,
          withdrawalTargetFinancialAccountId: payload
        }
      })
    case types.UPDATE_TRANSACTION_RECORD: {
      return state.merge({
        tradingTransactionRecord: {
          ...state.tradingTransactionRecord,
          ...payload
        }
      })
    }
    case types.GET_TRADING_TRANSACTION_SUCCESS:
    case types.GET_TRADING_TRANSACTION2_SUCCESS:
    case types.UPDATE_STATUS_ID_SUCCESS:
      return state.merge({
        loading: false,
        tradingTransactionRecord:
          type === types.GET_TRADING_TRANSACTION_SUCCESS
            ? payload
            : {
              ...state.tradingTransactionRecord,
              ...payload
            }
      })
    case types.GET_TRADING_TRANSACTIONS_SUCCESS:
      return state.merge({
        loading: false,
        tradingTransactionsList: payload,
        tradingTransactionsListSnapshot: payload
      })
    case types.FILTER_TRADING_TRANSACTIONS:
      const {tradingTransactionsListSnapshot, tradingTransactionDetailSnapshot} = state
      if (payload === 'credit' || payload === 'debit') {
        return state.merge({
          tradingTransactionsBtnValue: payload,
          tradingTransactionsList: tradingTransactionsListSnapshot.filter(item => item[payload] > 0),
          tradingTransactionDetail: tradingTransactionDetailSnapshot.filter(item => item[payload] && item[payload] > 0),
        })
      }
      return state.merge({
        tradingTransactionsBtnValue: payload,
        tradingTransactionsList: tradingTransactionsListSnapshot,
        tradingTransactionDetail: tradingTransactionDetailSnapshot
      })
    default:
      return state
  }
}