import * as React from 'react'
import {
  Table,
  InputSelect,
  Row,
  Col
} from 'components'
import {SectionTitle} from '../../../shared'
import {
  SicDivision,
  SicGroup
} from 'types'

interface IndustrialClassificationProps {
  sicDivisions: SicDivision[];
  sicGroups: SicGroup[];
  fetching: boolean;
  sicDivisionID: number | undefined;
  sicGroupID: number | undefined;
}

const IndustrialClassification: React.FC<IndustrialClassificationProps> = (
  {
    sicGroups,
    sicDivisions,
    fetching,
    sicGroupID,
    sicDivisionID
  }
) => {
  return (
    <>
      <SectionTitle>
        Standard Industrial Classification
      </SectionTitle>
      <Row gutter={14}>
        <Col span={10}>
          <InputSelect
            label='informationRequest.sicDivision'
            name='sicDivisionID'
            options={sicDivisions}
            getOptionProps={opt => ({
              value: opt.id,
              children: opt.title
            })}
          />
        </Col>
        <Col span={14}>
          <InputSelect
            required
            label='informationRequest.sicGroup'
            name='sicGroupID'
            options={sicGroups}
            loading={fetching}
            getOptionProps={opt => ({
              value: opt.id,
              children: opt.title
            })}
          />
        </Col>
      </Row>
      <Table
        pagination={false}
        dataSource={sicDivisionID && sicGroupID ? [
          {
            sicDivisionID,
            sicGroupID
          },
        ] : []}
        columns={[
          {
            title: 'Sic Division',
            dataIndex: 'sicDivisionID',
            render: () => sicDivisions.find(item => item.id === sicDivisionID)?.title
          },
          {
            title: 'Sic Group',
            dataIndex: 'sicGroupID',
            render: () => sicGroups.find(item => item.id === sicGroupID)?.title
          }
        ]}
      />

    </>
  )
}

export default IndustrialClassification
