import * as React from 'react'
import {
  Form,
  Row,
  Col,
  InputText,
  ExtendedDescriptions,
  FileUpload,
  notification
} from 'components'
import {
  FULL_DATE_TIME_FORMAT,
  ROW_GUTTER,
  COMMON_COL_PROPS
} from 'consts'
import dayjs from 'dayjs'
import {
  useIntl,
  useForm
} from 'hooks'
import {
  DocWTemplate,
  FormInstance
} from 'types'
import {createUseStyles} from 'react-jss'
import {
  API,
  Storage
} from 'utils'

const useStyles = createUseStyles({
  title: {margin: 0},
  file: {marginTop: 14}
})

interface ViewDetailsProps {
  record: DocWTemplate;
  form: FormInstance;
  onSuccess: (record: DocWTemplate) => void;
  toggleSubmitting: (submitting: boolean) => void;
}

const ViewTemplate: React.FC<ViewDetailsProps> = ({
  record,
  form,
  toggleSubmitting,
  onSuccess
}) => {
  const intl = useIntl()
  const classes = useStyles()

  const {awsFileID} = record

  React.useEffect(() => {
    if (awsFileID) {
      Storage.get(awsFileID, {
        bucket: 'wordTemplates',
        download: true
      })
        .then(({Body}) => {
          const blob = Body as Blob

          const file = new File([blob], awsFileID, {type: blob.type,})

          // add thumbUrl for displaying a picture
          // eslint-disable-next-line
          // @ts-ignore
          file.thumbUrl = URL.createObjectURL(blob)
          form.setFieldValue('file', [file])
        })
        .catch(() => {

        })
    }
  }, [awsFileID])

  return (
    <Form
      form={form}
      layout='vertical'
      preserve={false}
      initialValues={{
        ...record,
        file: []
      }}
      onFinish={async (values) => {
        toggleSubmitting(true)

        try {
          const [file] = values.file
          let awsFileID = record.awsFileID

          if (record.awsFileID !== file.name) {
            const bucketConfig = {bucket: 'wordTemplates'} as const
            
            const [, {key}] = await Promise.all([
              record.awsFileID ? Storage.del(record.awsFileID, bucketConfig) : Promise.resolve(),
              Storage.put(`${Date.now()}-${file.name}`, file.originFileObj || file, bucketConfig)
            ])

            awsFileID = key
          }

          const init = {
            body: {
              title: values.title,
              awsFileID
            }
          }

          const updatedRecord = await API.patch('accounts', `/${record.accountID}/docw/templates/${record.id}`, init)

          onSuccess(updatedRecord)

          notification.success({message: intl.formatMessage({id: 'notifications.success.updated'}) })
        } catch (e: any) {
          notification.error({message: e.response?.data?.error || e.message})
          toggleSubmitting(false)
        }
      }}
    >
      <ExtendedDescriptions
        items={[
          {
            label: 'common.account',
            value: record.account_name
          },
          {
            label: 'common.title',
            value: (
              <InputText required name='title' formItemClassName={classes.title} />
            ),
          },
          {
            label: 'common.createdAt',
            value: dayjs(record.createdAt).format(FULL_DATE_TIME_FORMAT),
          },
        ]}
      />
      <FileUpload
        required
        name='file'
        listType='picture'
        maxCount={1}
        formItemClassName={classes.file}
        accept='.doc, .docx, .msword, .vnd.openxmlformats-officedocument.wordprocessingml.document'
      />
    </Form>
  )
}

export default ViewTemplate

