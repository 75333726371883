import * as React from 'react'

import {
  Form,
  SubmitButton,
  Modal,
  List,
  InputSelect,
  InputText
} from 'components'
import {useSelector} from 'hooks'
import {
  showNotification,
  API
} from 'utils'
import {
  Taxation,
  NoTINReason
} from 'types'

interface CreateTaxationModal {
  clientID: number;
  open: boolean;
  onCancel: () => void;
  onSuccess: (record: Taxation) => void;
  noTINReasons: NoTINReason[];
}

const CreateTaxationModal: React.FC<CreateTaxationModal> = ({
  clientID,
  open,
  onCancel,
  onSuccess,
  noTINReasons
}) => {
  const [submitting, toggleSubmitting] = React.useState(false)
  const countriesList = useSelector(state => state.appState.countriesList)

  const onFinish = async (values: any) => {
    try {
      toggleSubmitting(true)

      const newRecord = await API.post('clients', `/${clientID}/taxation`, {
        body: {
          jurisdictionCountryID: values.jurisdictionCountryID,
          noTinReason: values.noTinReason,
          tin: values.tin,
          furtherDetails: values.furtherDetails,
          clientID
        }
      })

      showNotification('success', 'Templates', 'Successfully Created')

      toggleSubmitting(false)
      onSuccess(newRecord)
    } catch (e: any) {
      toggleSubmitting(false)
      showNotification('error', 'API', e.response?.data?.error || e.message)
    }
  }

  return (
    <Modal
      title='Create a New CRS Record'
      open={open}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        preserve={false}
        layout='vertical'
        onFinish={onFinish}
      >
        <InputSelect
          required
          name='jurisdictionCountryID'
          label='taxation.countryJurisdiction'
          options={countriesList}
          getOptionProps={(opt) => ({
            children: opt.full_name,
            value: opt.id
          })}
        />
        <InputText
          name='tin'
          label='taxation.tin'
        />
        <Form.Item dependencies={['tin']} noStyle>
          {({getFieldValue}) => {
            const tin = getFieldValue('tin')

            return (
              <InputSelect
                required={!tin}
                name='noTinReason'
                label='taxation.noTINReason'
                options={noTINReasons}
                getOptionProps={(opt: NoTINReason) => ({
                  children: `${opt.code}: ${opt.reason}`,
                  value: opt.id
                })}
              />
            )
          }}
        </Form.Item>
        <InputText
          name='furtherDetails'
          label='common.details'
        />
        <Form.Item dependencies={['noTinReason']} noStyle>
          {({getFieldValue}) => {
            const reasonID = getFieldValue('noTinReason')

            return (
              <List
                size="small"
                dataSource={noTINReasons.filter(item => !reasonID || item.id === reasonID)}
                renderItem={(item) => <List.Item>Code: {item.code}. {item.reason}</List.Item>}
              />
            )
          }}
        </Form.Item>
        <SubmitButton loading={submitting} />
      </Form>
    </Modal>
  )
}

export default CreateTaxationModal

