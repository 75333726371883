import React, {
  useEffect,
  useState
} from 'react'
import {
  Divider,
  Tabs
} from 'antd'
import {
  useDispatch,
  useSelector
} from 'react-redux'

import {
  tradingActions,
  tradingSelectors
} from 'configs/ducks/trading'
import ClientDetails from './sub-components/clientDetails'
import TransactionDetails from './sub-components/TransactionDetail'
import AttachedDocument from './sub-components/AttachedDocument'
import RequireInfoFromClient from './sub-components/RequireInfoFromClient'
import {
  useHistory,
  useLocation
} from 'react-router'
import queryString from 'query-string'

function TradingTransactionDetailsDeposit({match: {params}}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeTab, setTab] = useState('')
  const location = useLocation()
  const {tradingTransactionRecord, loading} = useSelector(tradingSelectors.tradingTransactionSelector)

  useEffect(() => {
    if (params.id) {
      dispatch(tradingActions.getTradingTransaction(params.id, 'deposit'))
    }
  }, [dispatch, params.id])

  const refresh = () => {
    setTimeout(() => {
      dispatch(tradingActions.getTradingTransaction(params.id, 'deposit'))
    }, 3000)
  }

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search)
    setTab(activeTab)
  }, [location.search])

  const onTabClick = e => {
    history.push(`/trading/transactions/${params.id}/deposit?tab=${e}`)
  }
  console.log(tradingTransactionRecord)

  return (
    <>
      <Tabs type='card' activeKey={activeTab} onTabClick={onTabClick}>
        <Tabs.TabPane tab='Client' key='dclient'>
          <ClientDetails />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Transactions' key='dtransaction'>
          <TransactionDetails name="debit" />
          <Divider />
          <RequireInfoFromClient />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Documents' key='ddocuments'>
          <AttachedDocument
            trading_account_id={tradingTransactionRecord.trading_account_id}
            debit={tradingTransactionRecord.debit}
            id={params.id}
            loading={loading}
            document={tradingTransactionRecord.document}
            file={tradingTransactionRecord.fileList?.[0]}
            onSuccessUpload={refresh}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default TradingTransactionDetailsDeposit
