import React, {
  useState,
  useEffect
} from 'react'
import {
  connect,
  useSelector
} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {
  Button,
  Row,
  Tabs,
  Skeleton,
  Spin
} from 'antd'

import EmailCustomTemplatesSummary from './templates_view/EmailCustomTemplatesSummary'

import queryString from 'query-string'
import {clearState} from 'configs/actions/common'
import {
  documentsActions,
  documentsSelectors
} from 'configs/ducks/documents'
import {FormattedMessage} from 'react-intl'

const {TabPane} = Tabs

const EmailCustomTemplatesViewPageComponent = ({
  match: {params: {id}},
  emailCustomTemplate,
  getEmailCustomTemplate,
  saveEmailCustomTemplate,
  loading,
  ...rest
}) => {
  const {search} = useLocation()
  const [state, setState] = useState({
    activeTab: queryString.parse(search).tab,
    currentStep: 0
  })

  useEffect(() => {
    setState({
      ...state,
      activeTab: search ? queryString.parse(search).tab : 'summary'
    })
  }, [search])

  useEffect(() => {
    getEmailCustomTemplate(id)
    return clearState
  }, [])

  // return null
  return (
    <Skeleton active loading={!emailCustomTemplate?.id}>
      <Spin spinning={loading}>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Summary' key='1'>
            <EmailCustomTemplatesSummary id={id} {...emailCustomTemplate} saveEmailCustomTemplate={saveEmailCustomTemplate}/>
          </TabPane>
        </Tabs>
      </Spin>
    </Skeleton>
  )
}

const {saveEmailCustomTemplate, getEmailCustomTemplate} = documentsActions

const mapStateToProps = state => {
  const {loading, emailCustomTemplate} = documentsSelectors.stateSelector(state)

  return {
    loading,
    emailCustomTemplate,
    getEmailCustomTemplate,
    saveEmailCustomTemplate
  }
}

export default connect(mapStateToProps, {
  saveEmailCustomTemplate,
  getEmailCustomTemplate,
  clearState
})(EmailCustomTemplatesViewPageComponent)