import * as types from './types'

/**
 * @param {number} payload
 * @returns {{payload: *, type: string}}
 */
export const fetchTeams = payload => ({
  type: types.FETCH_TEAMS_REQUEST,
  payload
})
/**
 * @param {number} id
 * @returns {{payload: *, type: string}}
 */
export const getTeamByID = id => ({
  type: types.GET_TEAM_REQUEST,
  payload: id
})

export const deleteTeam = payload => ({
  type: types.DELETE_TEAM_REQUEST,
  payload
})

/**
 * @param {Object} teamRecord
 * @returns {{payload: *, type: string}}
 */
export const saveTeamRecord = teamRecord => ({
  type: types.SAVE_TEAM_REQUEST,
  payload: teamRecord
})

export const createTeam = (newTeam, accountID, history) => ({
  type: types.CREATE_TEAM_REQUEST,
  payload: {
    newTeam,
    accountID,
    history
  }
})
