import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {
  Empty,
  Spin,
  Skeleton,
} from 'antd'

import {listHOC} from 'common'

import {clientsActions} from 'configs/ducks/clients'
import {
  verificationsSelectors,
  verificationsActions
} from 'configs/ducks/verifications'
import {clearState} from 'configs/actions/common'

import {VerificationView} from './view/VerificationView'

const VerificationSummary = (
  {
    match: {params: {id: recordID}},
    verificationData,
    trulioData,
    getVerificationInfo,
    updateVerification,
    loading
  }
) => {
  useEffect(() => {
    getVerificationInfo(recordID)
  }, [recordID])
  
  return (
    <Spin spinning={verificationData.id && loading}>
      <Skeleton active loading={!verificationData.id && loading}>
        {!verificationData.id ? <Empty /> :
          <VerificationView
            updateVerification={updateVerification}
            verificationData={verificationData}
            trulioData={trulioData}
          />
        }
      </Skeleton>
    </Spin>
  )
}
const {
  getVerificationInfo,
  updateVerification
} = verificationsActions

const mapStateToProps = state => {
  const {
    loading,
    ...rest
  } = verificationsSelectors.stateSelector(state)
  return {
    currentAccountID: state.appState.currentAccountRecord.id,
    loading: loading,
    ...rest
  }
}

export default connect(
  mapStateToProps,
  {
    fetchCb: clientsActions.fetchClients,
    getVerificationInfo,
    updateVerification,
    clearState
  }
)(listHOC(VerificationSummary))
