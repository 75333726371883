import reducer from './reducers'

import * as informationRequestsSelectors from './selectors'
import * as informationRequestsActions from './actions'
import * as informationRequestsTypes from './types'
import informationRequestsSaga from './sagas'

export {
  informationRequestsSaga,
  informationRequestsTypes,
  informationRequestsActions,
  informationRequestsSelectors,
}

export default reducer