import {
  all,
  call,
  put,
  takeEvery,
  select
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import * as types from './types'
import * as actions from './actions'
import {stateSelector} from './selectors'

import {openNotificationWithIcon} from '../../utils'

import {getDocument} from '../documents/sagas'

function* getCommissionsFull(action) {
  try {
    const {
      payload: {
        startDate,
        endDate
      }
    } = action
    const init = {
      queryStringParameters: {
        startDate,
        endDate
      }
    }
    const {payload: {te_response: {data}}} = yield call(
      [API, API.get],
      'trading-commissions',
      '/te/full',
      init
    )
    yield put({
      type: types.GET_COMMISSIONS_FULL_SUCCESS,
      payload: data
    })
  } catch (e) {
    yield put({type: types.GET_COMMISSIONS_FULL_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}
function* getCommissionsSum(action) {
  try {
    const {payload: {startDate, endDate}} = action
    const init = {
      queryStringParameters: {
        startDate,
        endDate
      }
    }
    const {payload} = yield call(
      [API, API.get],
      'trading-commissions',
      '/te/sum',
      init
    )
    yield put({
      type: types.GET_COMMISSIONS_SUM_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_COMMISSIONS_SUM_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* getTradingAccounts(action) {
  try {
    const {payload} = yield call(
      [API, API.get],
      'trading-accounts',
      `/account/${action.currentAccountID}`
    )
    yield put({
      type: types.GET_TRADING_ACCOUNTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_TRADING_ACCOUNTS_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* getTradingAccount(action) {
  try {
    const {payload} = yield call(
      [API, API.get],
      'trading-accounts',
      `/id/${action.id}`
    )

    yield put({
      type: types.GET_TRADING_ACCOUNT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_TRADING_ACCOUNT_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* getTradingTransactions(action) {
  try {
    const {payload} = yield call(
      [API, API.get],
      'trading-transactions',
      `/account/${action.currentAccountID}`
    )
    yield put({
      type: types.GET_TRADING_TRANSACTIONS_SUCCESS,
      payload
    })
    const {tradingTransactionsBtnValue} = yield select(stateSelector)
    yield put(
      actions.filterTradingTransactions(tradingTransactionsBtnValue)
    )
  } catch (e) {
    yield put({type: types.GET_TRADING_TRANSACTIONS_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* getTradingTransaction(action) {
  try {
    const {
      id,
      route
    } = action
    const {payload} = yield call(
      [API, API.get],
      'trading-transactions',
      `/${route}/${id}`
    )
    const result = yield call(getDocument, payload.document_id)
    // console.log(result)
    yield put({
      type: types.GET_TRADING_TRANSACTION_SUCCESS,
      payload: {
        ...payload,
        ...result
      }
    })
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.GET_TRADING_TRANSACTION_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', error || message)
  }
}

function* getTradingTransaction2(action) {
  try {
    const {
      id,
      route
    } = action
    const {payload} = yield call(
      [API, API.get],
      'trading-transactions-2',
      `/${route}/${id}`
    )
    const result = yield call(getDocument, payload.document_id)
    // console.log(result)
    yield put({
      type: types.GET_TRADING_TRANSACTION2_SUCCESS,
      payload: {
        ...payload,
        ...result
      }
    })
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.GET_TRADING_TRANSACTION2_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', error || message)
  }
}

function* removeDocument(action) {
  try {
    const {id} = action.payload
    const {payload} = yield call(
      [API, API.del],
      'trading-transactions',
      `/deposit/${id}/document`
    )
    yield put({
      type: types.REMOVE_DOCUMENT_SUCCESS,
      payload
    })
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.REMOVE_DOCUMENT_ERROR})
    openNotificationWithIcon('error', 'Failed to remove document', error || message)
  }
}

function* removeTradingTransaction2Document(action) {
  try {
    const {id} = action.payload
    const {payload} = yield call(
      [API, API.del],
      'trading-transactions-2',
      `/document/${id}`
    )
    yield put({
      type: types.REMOVE_DOCUMENT2_SUCCESS,
      payload
    })
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.REMOVE_DOCUMENT2_ERROR})
    openNotificationWithIcon('error', 'Failed to remove document', error || message)
  }
}

function* uploadDocument(action) {
  try {
    const {id, ...rest} = action.payload
    const {payload} = yield call(
      [API, API.post],
      'trading-transactions',
      `/deposit/${id}/document`,
      {body: rest}
    )
    yield put({
      type: types.UPLOAD_DOCUMENT_SUCCESS,
      payload
    })
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.UPLOAD_DOCUMENT_ERROR})
    openNotificationWithIcon('error', 'Failed to upload document', error || message)
  }
}

function* uploadDocument2(action) {
  try {
    const {id, ...rest} = action.payload
    const {payload} = yield call(
      [API, API.post],
      'trading-transactions-2',
      `/document/${id}`,
      {body: rest}
    )
    yield put({
      type: types.UPLOAD_DOCUMENT2_SUCCESS,
      payload
    })
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.UPLOAD_DOCUMENT2_ERROR})
    openNotificationWithIcon('error', 'Failed to upload document', error || message)
  }
}

function* createDeposit(action) {
  try {
    const {
      payload: {
        body,
        route
      },
      cb
    } = action

    const {payload} = yield call(
      [API, API.post],
      'trading-transactions',
      route,
      {body}
    )
    yield put({
      type: types.CREATE_DEPOSIT_WITHDRAWAL_SUCCESS,
      payload
    })
    yield call(cb)
    openNotificationWithIcon('success', 'Success', 'Successfully saved.')
  } catch (e) {
    openNotificationWithIcon('error', 'Failed to save data', e.message)
    yield put({type: types.CREATE_DEPOSIT_WITHDRAWAL_ERROR})
  }
}

function* updateStatusID(action) {
  try {
    const {
      status_id,
      transaction_id
    } = action
    const body = {
      status_id,
      transaction_id
    }
    const {payload} = yield call([API, API.put], 'trading-transactions', '/update', {body})
    yield put({
      type: types.UPDATE_STATUS_ID_SUCCESS,
      payload: {status_id: payload.status_id}
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Failed to update', e.message)
    yield put({type: types.UPDATE_STATUS_ID_ERROR})
  }
}

function* getTradingTransactionsByAccountId(action) {
  try {
    const {payload} = yield call(
      [API, API.get],
      'trading-transactions',
      `/trans/${action.currentAccountID}`
    )
    yield put({
      type: types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_SUCCESS,
      payload
    })
    const {tradingTransactionsBtnValue} = yield select(stateSelector)
    yield put(
      actions.filterTradingTransactions(tradingTransactionsBtnValue)
    )
  } catch (e) {
    yield put({type: types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_REQUEST, getTradingTransactionsByAccountId),
    yield takeEvery(types.GET_COMMISSIONS_FULL_REQUEST, getCommissionsFull),
    yield takeEvery(types.GET_COMMISSIONS_SUM_REQUEST, getCommissionsSum),

    yield takeEvery(types.GET_TRADING_ACCOUNTS_REQUEST, getTradingAccounts),
    yield takeEvery(types.GET_TRADING_ACCOUNT_REQUEST, getTradingAccount),

    yield takeEvery(types.GET_TRADING_TRANSACTIONS_REQUEST, getTradingTransactions),
    yield takeEvery(types.GET_TRADING_TRANSACTION_REQUEST, getTradingTransaction),
    yield takeEvery(types.GET_TRADING_TRANSACTION2_REQUEST, getTradingTransaction2),
    
    yield takeEvery(types.REMOVE_DOCUMENT_REQUEST, removeDocument),
    yield takeEvery(types.REMOVE_DOCUMENT2_REQUEST, removeTradingTransaction2Document),
    yield takeEvery(types.UPLOAD_DOCUMENT_REQUEST, uploadDocument),
    yield takeEvery(types.UPLOAD_DOCUMENT2_REQUEST, uploadDocument2),
    yield takeEvery(types.CREATE_DEPOSIT_WITHDRAWAL_REQUEST, createDeposit),

    yield takeEvery(types.UPDATE_STATUS_ID_REQUEST, updateStatusID)
  ])
}