import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Typography,
  Skeleton,
  Select,
  Row,
  Col
} from 'antd'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import { financialAccountAction } from 'configs/ducks/clients/actions'
import { clientsSelector } from 'configs/ducks/clients/selectors'
import {tradingTransactionSelector} from 'configs/ducks/trading/selectors'
import {updateWithdrawalTargetFinancialAccountId} from 'configs/ducks/trading/actions'

const { Option } = Select

const WithdrawalDetails = () => {
  const dispatch = useDispatch()
  const { tradingTransactionRecord, loading } = useSelector(tradingTransactionSelector)
  const { financialAccount } = useSelector(clientsSelector)
  const {clients_id, withdrawalTargetFinancialAccountId} = (tradingTransactionRecord || {})

  const onChange = value => {
    dispatch(updateWithdrawalTargetFinancialAccountId(value))
  }

  useEffect(() => {
    if (clients_id) {
      dispatch(financialAccountAction(clients_id))
    }
  }, [dispatch, clients_id])

  return (
    <Skeleton loading={loading}>
      <Row>
        <Col span={12}>
          <Typography.Title level={4}>
            <FormattedMessage id='trading_transaction_detail.withdrawal_details' />
          </Typography.Title>
          <Typography.Paragraph>
            <small style={{color: 'red'}}>*</small> Select account for funds to be deposit
          </Typography.Paragraph>
          <Select
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            placeholder='Deposit account'
            onChange={onChange}
            value={withdrawalTargetFinancialAccountId}
          >
            {React.Children.toArray(financialAccount.map( account => (
              <Option value={account.id}>{account.fileBy}, {account.financial_account_type_name}</Option>
            )))}
          </Select>
        </Col></Row>
    </Skeleton>
  )
}

export default WithdrawalDetails