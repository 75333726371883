import React, {
  useState,
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  useLocation,
  useHistory
} from 'react-router-dom'
import {
  Tabs,
  Row,
  Button,
  Form,
  Spin
} from 'antd'
import _ from 'lodash'
import {
  createUseStyles,
  useTheme
} from 'react-jss'
import queryString from 'query-string'
import {appStateSelectors} from 'configs/ducks/appState'
import moment from 'moment'
import {System} from './tools/system'
import {XeondaRecordIds} from './tools/xeondaRecordIds'

const {TabPane} = Tabs

const tabs = [
  {
    tab: 'System',
    key: 'system',
    Component: System,
    props: ['accounts', 'leadsDetails', 'clientStatusList']
  },
  {
    tab: 'Xeonda Record IDs',
    key: 'xrid',
    Component: XeondaRecordIds,
    // props: ['accounts', 'leadsDetails', 'clientStatusList']
  }
]

const useStyles = createUseStyles(theme => ({
  label: {fontWeight: theme.label_font_weight},
  inputDisabled: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color
  }
}))

export const SystemToolsPageComponent = ({
  match: {params: {leadId}},
  ...rest
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles({theme})
  const [activeTab, setState] = useState('')
  const [active, setActive] = useState(false)

  const {currentAccountRecord: {id: currentAccountID}} = useSelector(state => appStateSelectors.stateSelector(state))

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search)
    setState(activeTab)
  }, [location.search])

  const onTabClick = e => {
    history.push(`/settings/system-tools?tab=${e}`)
  }

  return (
    <React.Fragment>
      <Spin spinning={active}>
        <Tabs activeKey={activeTab} onTabClick={onTabClick}>
          {tabs.map(({
            tab, key, Component, props
          }) => (
            <TabPane tab={tab} key={key}>
              <Component
                {..._.pick(rest, props)}
                id={leadId}
                classes={classes}
                form={form}
                currentAccountID={currentAccountID}
              />
            </TabPane>
          ))}
        </Tabs>
      </Spin>
    </React.Fragment>
  )
}
