import {agentsSaga} from './ducks/agents'
import {appStateSaga} from './ducks/appState'
import {clientsSaga} from './ducks/clients'
import {documentsSaga} from './ducks/documents'
import {verificationsSaga} from './ducks/verifications'
import {leadsSaga} from './ducks/leads'
import {settingsSaga} from './ducks/settings'
import {staffsSaga} from './ducks/staffs'
import {teamsSaga} from './ducks/teams'
import {accountsSaga} from './ducks/backoffice-accounts'
import {tradingSaga} from './ducks/trading'
import {all} from 'redux-saga/effects'
import {opportunitiesSaga} from './ducks/opportunities'
import {leadsNewSaga} from './ducks/leads-new'
import { organiserSaga } from './ducks/organiser'
import { xRecordIdsSaga } from './ducks/xRecordIds'
import { informationRequestsSaga } from './ducks/information-requests'
import { tradingCommissionsClientsSaga } from './ducks/trading-commissions'
import { docCheckSaga } from './ducks/doc-check'

export default function * () {
  yield all([
    agentsSaga(),
    appStateSaga(),
    accountsSaga(),
    clientsSaga(),
    documentsSaga(),
    docCheckSaga(),
    leadsSaga(),
    verificationsSaga(),
    settingsSaga(),
    staffsSaga(),
    teamsSaga(),
    tradingSaga(),
    opportunitiesSaga(),
    leadsNewSaga(),
    organiserSaga(),
    xRecordIdsSaga(),
    informationRequestsSaga(),
    tradingCommissionsClientsSaga()
  ])
}