import React, {Component} from 'react'
import {connect} from 'react-redux'
import { API } from 'aws-amplify'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Row,
  Select,
  notification,
  Form,
  Input,
  InputNumber,
  Skeleton,
  Spin,
  Drawer
} from 'antd'
import moment from 'moment'
import ClientCreateFinancialAccount from '../drawers/ClientCreateFinancialAccount'

const {Option} = Select
const { TextArea } = Input

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class TradingTransactionsWithdrawal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      statusList: [],
      financialAccountList: [],
      currentStaff: props.currentStaff,
      gatMoneyDeposited: false,
      selectedAccount: {},
      visibleDrawer: false
    }
  }

  async componentDidMount() {
    try {
      const TradingTransaction = await this.getTradingTransactionsRecord(this.props.id)
      this.setState({
        ...TradingTransaction.payload,
        'authorise1_datetime': TradingTransaction.payload?.authorise1_datetime ? moment(TradingTransaction.payload.authorise1_datetime).format('YYYY-MM-DD HH:mm:ss') : '',
        'authorise2_datetime': TradingTransaction.payload?.authorise2_datetime ? moment(TradingTransaction.payload.authorise2_datetime).format('YYYY-MM-DD HH:mm:ss') : '',
        loading: true
      })
      const {client_id} = this.state
      await API.get('trading-transactions-2', '/list-status')
        .then(response => {
          const dataSource = []
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              statusName: response.payload[i].statusName,
            })
          }
          this.setState({statusList: dataSource})
        })
        .catch(error => {
          console.log(error)
        })

      await API.get('financial-accounts', `/list/${client_id}`)
        .then(response => {
          const dataSource = []
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              fileBy: response.payload[i].fileBy,
            })
          }
          this.setState({financialAccountList: dataSource})
        })
        .catch(error => {
          console.log(error)
        })

      this.setState({loading: false})
    } catch (e) {
      console.log(e)
    }
  }

  async getTradingTransactionsRecord(tradingTransactionID) {
    return API.get('trading-transactions-2', `/id/${tradingTransactionID}`)
  }
  
  async saveTradingTransactionsRecord(tradingTransactionRecord) {
    await API.patch('trading-transactions-2', `/id/${this.state.id}`, {body: tradingTransactionRecord})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  setVisibleDrawer = (value) => {
    this.setState({
      ...this.state,
      visibleDrawer: value
    })
  }

  showSelectedAccount = (value) => {
    this.setVisibleDrawer(true)
    this.setSelectedAccount(value)
  }

  setSelectedAccount = (id) => {
    API.get('financial-accounts', `/id/${id}`).then(
      response => {
        this.setState({
          ...this.state,
          selectedAccount: response.payload
        })
      }
    )
  }

  onCloseDrawer = () => {
    this.setVisibleDrawer(false)
  }

  handleTeApproveWithdrawal = async () => {
    const operation = await API.post('trading-transactions-2', `/operation/${this.props.id}`,
      {
        body: {
          action: 'approve',
          staffID: this.props.appState.currentStaffRecord.id
        }
      })
    // console.log(operation)
    openNotificationWithIcon('success', 'Withdrawal Request', 'Trading Transaction has been approved.')
    this.setState({
      statusID: 6,
      transmissionDateTime: moment().format('HH:mm:ss DD-MM-YYYY'),
      transmissionAmount: `${this.state.currency} ${Number.parseFloat(this.state.credit).toFixed(2)}`,
      transmissionUser: this.props.appState.currentStaffRecord.file_by
    })
  }
  
  handleTeRejectWithdrawal = async () => {
    const operation = await API.post('trading-transactions-2', `/operation/${this.props.id}`,
      {
        body: {
          action: 'reject',
          staffID: this.props.appState.currentStaffRecord.id
        }
      })
    // console.log(operation)
    openNotificationWithIcon('warning', 'Withdrawal Request', 'Trading Transaction has been rejected.')
    this.setState({statusID: 5})
  }
  
  handleGatMoneyDeposit = async () => {
    try {
      const operation = await API.post('trading-transactions-2', '/gat-money/deposit',
        {
          body: {
            transactionID: this.props.id,
            targetAccountID: this.state.targetAccountID,
            amount: this.state.credit,
            currency: this.state.currency,
            staffID: this.props.appState.currentStaffRecord.id
          }
        })
      // console.log(operation)
      this.setState({
        gatMoneyDeposited: true,
        financialAccountTransmissionAmount: `${this.state.currency} ${Number.parseFloat(this.state.credit).toFixed(2)}`,
        financialAccountTransmissionDatetime: moment().format('HH:mm:ss DD-MM-YYYY'),

      })
      openNotificationWithIcon('success', 'Deposit', 'Deposit transaction has been entered into GAT Money Target Account')
    } catch (e) {
      console.log(e)
      openNotificationWithIcon('error', 'Deposit', 'Deposit transaction to GAT Money encountered an error. Please review.')
    }
    
  }

  handleChange = value => {
    this.setState({'credit': value})
  };

  onHandleChangeClient = value => {
    this.setState({'clientID': value})
  }

  onHandleChangeStatus = value => {
    this.setState({'statusID': value})
  }

  onHandleChangeTargetAccount = value => {
    this.setState({'targetAccountID': value})
  }

  onHandleTextAreaChange = ({ target: { value } }) => {
    this.setState({ 'internalNotes': value })
  };

  onCheckboxOnChange = (event) => {
    if ( event.target.id === 'authorise1_staffID'){
      this.setState({
        'authorise1_staffID': event.target.checked ? this.state.currentStaff.id : null,
        'authorise1_staff': event.target.checked ? this.state.currentStaff.file_by : null,
        'authorise1_datetime': moment().format('YYYY-MM-DD HH:mm:ss')
      })
      API.patch('trading-transactions-2', `/approval/${this.state.id}`, {
        body:
        {
          'authorise1_staffID': this.state.currentStaff.id,
          'authorise1_cancel': !event.target.checked
        }
      })
      openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
    } else if (event.target.id === 'authorise2_staffID'){
      this.setState({
        'authorise2_staffID': event.target.checked ? this.state.currentStaff.id : null,
        'authorise2_staff': event.target.checked ? this.state.currentStaff.file_by : null,
        'authorise2_datetime': moment().format('YYYY-MM-DD HH:mm:ss')
      })
      API.patch('trading-transactions-2', `/approval/${this.state.id}`, {
        body:
        {
          'authorise2_staffID': this.state.currentStaff.id,
          'authorise2_cancel': !event.target.checked
        }
      })
      openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
    }
  }

  onHandleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id,
      credit,
      statusID,
      targetAccountID,
      internalNotes
    } = this.state
    try {
      await this.saveTradingTransactionsRecord({
        id,
        credit,
        statusID,
        targetAccountID,
        internalNotes
      })
      this.setState({is_saving: false})
    } catch (e) {
      console.log(e)
    }
  };

  render() {

    if (!this.props.appState) {
      return null
    }

    return (
      <React.Fragment>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <h3>Withdrawal Transaction</h3>
          </Col>
        </Row>
        <Skeleton active loading={this.state.loading}>
          <Spin spinning={this.state.loading}>
            <Form layout='vertical' onSubmit={this.onHandleSubmit}>
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Card
                    title='Withdrawal Details'
                  >
                    <Form.Item label='Created'>
                      {this.state?.recordCreated
                        ? moment(this.state?.recordCreated).format('DD/MM/YYYY HH:mm:ss')
                        : ''
                      }
                    </Form.Item>
                    <Row gutter={[8, 24]}>
                      <Col xs={4}>
                        <Form.Item label='Currency'>
                          {this.state.currency || ''}
                        </Form.Item>
                      </Col>
                      <Col xs={20}>
                        <Form.Item label='Amount'>
                          <InputNumber
                            id='credit'
                            value={this.state.financialAccountTransmissionAmount || this.state.credit || ''}
                            disabled={this.state.financialAccountTransmissionAmount}
                            style={{width: '100%'}} min='0' max='1000000' precision={2} step={0.1}
                            onChange={this.onHandleChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label='TE Operation ID'>
                      <Input
                        id='teOperationID'
                        value={this.state.teOperationID || ''}
                        // value={1978537}
                        // onChange={this.onHandleChange}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card
                    title='Target Account'
                  >
                    <Form.Item>
                      <Select
                        onChange={this.onHandleChangeTargetAccount}
                        defaultValue={this.state.targetAccountID || ''}
                        value={this.state.targetAccountID || ''}
                        disable={this.state.financialAccountTransmissionDatetime}
                      >
                        {
                          this.state.financialAccountList.map(({ id, fileBy }) => (
                            <Option key={id} value={id}>
                              {fileBy}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                    {this.state.targetAccountID &&
                    <Button type="link" onClick={() => this.showSelectedAccount(this.state.targetAccountID)}>Show Detail</Button>
                    }
                    {/* <p>
                      <strong>Wallet ID: </strong>59<br/>
                      <strong>GAT Money Email: </strong>gatmoneypteltdlogin@gatbank.com<br/>
                    </p> */}
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[96, 24]}>
                <Col xs={8}>
                  <Card
                    title='Authorisations'
                  >
                  
                    <Form.Item label='Status'>
                      <Select
                        onChange={this.onHandleChangeStatus}
                        defaultValue={this.state.statusID || ''}
                        value={this.state.statusID || ''}
                      >
                        {
                          this.state.statusList.map(({ id, statusName }) => (
                            <Option key={id} value={id}>
                              {statusName}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                    <Divider />
                    <Form.Item label='Internal Notes'>
                      <TextArea
                        autoSize={{
                          minRows: 3,
                          maxRows: 5
                        }}
                        value={this.state.internalNotes}
                        // value={'automatic withdrawal / deposit into GM - audit notes here'}
                        onChange={this.onHandleTextAreaChange}
                      />
                    </Form.Item>
                    <Divider/>
                    <Form.Item label="Authorisation">
                      <Checkbox
                        id='authorise1_staffID'
                        checked={
                          this.state.authorise1_staffID
                        }
                        disabled={this.state.currentStaff.id !== this.state.authorise1_staffID && this.state.authorise1_staffID !== null}
                        onChange={event => this.onCheckboxOnChange(event)}
                      >
                    Authorisation 1
                      </Checkbox>
                      {
                        this.state.authorise1_staffID && <span>
                          <br/>
                          <em>
                            {this.state.authorise1_staff} at {this.state.authorise1_datetime}
                          </em>
                          <br/>
                        </span>
                      }
                      <br/>
                      <Checkbox
                        id='authorise2_staffID'
                        checked={
                          this.state.authorise2_staffID
                        }
                        disabled={this.state.currentStaff.id !== this.state.authorise2_staffID && this.state.authorise2_staffID !== null}
                        onChange={event => this.onCheckboxOnChange(event)}
                      >
                    Authorisation 2
                      </Checkbox>
                      {
                        this.state.authorise2_staffID && <span>
                          <br/>
                          <em>
                            {this.state.authorise2_staff} at {this.state.authorise2_datetime}
                          </em>
                          <br/>
                        </span>
                      }
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={8}>
                  <Card
                    title='Transaction Processing'
                  >
                    <Form.Item label='Trading Platform'>
                      <Button
                        type='primary'
                        disabled={true}
                        style={{
                          width: 200,
                          marginBottom: 4
                        }}
                      >
                        Withdraw from TE
                      </Button>
                      <br/>
                      <Button
                        type='primary'
                        onClick={this.handleTeApproveWithdrawal}
                        disabled={!this.state.teOperationID || this.state.statusID == 5 || this.state.transmissionDateTime || !this.props.appState.currentStaffRecord.security_transactions_canProcessTE}
                        style={{
                          width: 200,
                          marginBottom: 4
                        }}
                      >
                        Approve Withdrawal
                      </Button>
                      <br/>
                      <Button
                        type='primary'
                        onClick={this.handleTeRejectWithdrawal}
                        disabled={!this.state.teOperationID || this.state.statusID == 5 || this.state.transmissionDateTime || !this.props.appState.currentStaffRecord.security_transactions_canProcessTE}
                        style={{ width: 200 }}
                      >
                        Reject Withdrawal
                      </Button>
                    </Form.Item>
                    <Form.Item label='GAT Money'>
                      <Button
                        type='primary'
                        disabled={(!this.state.targetAccountID) || this.state.statusID == 5 || this.state.gatMoneyDeposited || this.state.financialAccountTransmissionDatetime || (!this.props.appState.currentStaffRecord.security_transactions_canProcessGM)}
                        style={{ width: 200 }}
                        onClick={this.handleGatMoneyDeposit}
                      >
                        Deposit into GAT Money
                      </Button>
                    </Form.Item>
                    <Form.Item label='Email'>
                      <Button
                        type='primary'
                        disabled={true}
                        style={{ width: 200 }}
                      >
                        Email Withdrawal Advice
                      </Button>
                    </Form.Item>

                  </Card>
                </Col>
                <Col xs={8}>
                  <Card
                    title='Log'
                  >
                    <Form.Item label='Trading Platform Transmission'>
                      <Input
                        id='transmissionDateTime'
                        value={this.state.transmissionDateTime || ''}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Trading Platform Transmission Amount'>
                      <Input
                        id='transmissionAmount'
                        value={this.state.transmissionAmount || ''}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Trading Platform Transmission User'>
                      <Input
                        id='transmissionUser'
                        value={this.state.transmissionUserName || ''}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Target Account Deposit Amount'>
                      <Input
                        id='financialAccountTransmissionAmount'
                        value={this.state.financialAccountTransmissionAmount}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Target Account Deposit Datetime'>
                      <Input
                        id='financialAccountTransmissionDatetime'
                        value={this.state.financialAccountTransmissionDatetime}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Target Account Transmission User'>
                      <Input
                        id='financialAccountTransmissionUser'
                        value={this.state.financialAccountTransmissionUserName || ''}
                        disabled
                      />
                    </Form.Item>

                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[96, 24]}>
              </Row>
              <Row justify="end">
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={this.state.is_saving}
                  onClick={this.onHandleSubmit}
                >Save</Button>
              </Row>
            </Form>
            <Drawer
              title={`Financial Account [${this.state.targetAccountID}]`}
              placement="right"
              width={500}
              onClose={this.onCloseDrawer}
              visible={this.state.visibleDrawer}
           
            >
              <ClientCreateFinancialAccount data={this.state.selectedAccount}/>
            </Drawer>
          </Spin>
        </Skeleton>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentStaff: appStateSelectors.stateSelector(state).currentStaffRecord,
    app_state: state.app_state,
    appState: state.appState,
    language: state.language,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const TradingTransactionsWithdrawalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradingTransactionsWithdrawal)

export default TradingTransactionsWithdrawalContainer

