import React, {
  FC,
  useEffect,
  useState
} from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  FormattedMessage,
  FormInputNumber,
  InputSelect,
  InputText,
  Row,
  Tag
} from 'components'
import {SyncOutlined} from '@ant-design/icons'
import {API} from 'aws-amplify'

interface DynamicFieldsProps {
  clientAccountID: number;
  clientID: number;
  gatTraderClientID?: number;
  gatTraderAccountID?: number;
  gatMoneyClientID?: number;
}

const DynamicFields: FC<DynamicFieldsProps> = ({
  clientAccountID, clientID, gatTraderClientID, gatTraderAccountID, gatMoneyClientID
}) => {
  const empty = ![2,6,8].includes(clientAccountID)
  const [state, setState] = useState<{
    gatTraderAccountIDList: any[];
    featureCheck: boolean;
    checkingFeature: boolean;
  }>({
    gatTraderAccountIDList: [],
    featureCheck: false,
    checkingFeature: false,
  })

  const checkFeature = async () => {
    try {
      setState(prevState => ({
        ...prevState,
        checkingFeature: true
      }))

      const featureCheck = await API.get('trading-accounts', `/check/${clientID}`, {})

      setState(prevState => ({
        ...prevState,
        featureCheck,
        checkingFeature: false
      }))

    } catch (error) {
      setState(prevState => ({
        ...prevState,
        checkingFeature: false
      }))
    }
  }

  useEffect(() => {
    checkFeature()

    const init = async (clientID?: number) => {
      if (!clientID) {
        return
      }

      API.get('trading-accounts', `/client/${clientID}`, {})
        .then((gatTraderAccountIDList) => {
          setState(prevState => ({
            ...prevState,
            gatTraderAccountIDList
          }))
        })
        .catch(() => {

        })
    }

    if (clientAccountID === 6) {
      init(gatTraderClientID)
    }

    if (clientAccountID === 2) {
      init(gatMoneyClientID)
    }
  }, [])

  if (empty) {
    return null
  }

  return (
    <Row gutter={[12, 0]}>
      <Col span={12}>
        <Card
          size="small"
          title="Xeonda Client Account Linking"
          style={{height: '100%'}}
        >
          {clientAccountID === 6 ? (
            <>
              <FormInputNumber
                label={<FormattedMessage id='data_clients.gatTraderClientID' />}
                name='gatTraderClientID'
                notLessThan={1}
              />
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <InputSelect
                    label='data_clients.gatTraderAccountID'
                    name='gatTraderAccountID'
                    options={state.gatTraderAccountIDList}
                    getOptionProps={(opt) => ({
                      value: opt.id,
                      children: `[${opt.id}] ${opt.tradingPlatformUsername}`
                    })}
                  />

                </Col>
                <Col span={12}>
                  <InputText
                    label='data_clients.gatTraderLogin'
                    style={{width: 300}}
                    name='gatTraderLogin'
                  />
                </Col>
              </Row>
              <p>
                <strong>Xeonda Links:</strong><br/>
                GAT Trader Client:
                <a
                  href={`https://admin.xeonda.com/clients/view/${gatTraderClientID}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {gatTraderClientID}
                </a>
                <br/>
                GAT Trader Account:
                <a
                  href={`https://admin.xeonda.com/trading/accounts/${gatTraderAccountID}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {gatTraderAccountID}
                </a><br/>
              </p>
            </>
          ) : (
            <>
              <InputText
                label='data_clients.gatMoneyClientID'
                name='gatMoneyClientID'
              />
              <div>
                <p>
                  <strong>Xeonda Links:</strong><br/>
                  GAT Money Client:
                  <a href={`https://admin.xeonda.com/clients/view/${gatMoneyClientID}`} target='_blank' rel='noopener noreferrer'>
                    {gatMoneyClientID}
                  </a><br/>
                </p>
              </div>
            </>
          )}
        </Card>
      </Col>
      <Col span={12}>
        <Card size="small" title="Account Features" style={{height: '100%'}}>
          {clientAccountID === 2 || clientAccountID === 8 ? (
            <Form.Item
              valuePropName='checked'
              name='feature_gatMoneyTraderWithdrawal'
              help="You must set a default withdrawal account on the platform logins for this feature (under 'Platform tab')"
            >
              <Checkbox
              >
                Enable Gat Money Trader Withdrawal (GAT Trader withdraw to GAT Money)
              </Checkbox>
            </Form.Item>
          ) : (
            <Form.Item
              valuePropName='checked'
              name='feature_gatMoneyTraderTransfer'
              help="You must fill in the GAT Trader Client (Xeonda ID), GAT Trader Account (Xeonda ID) and GAT Trader Login fields to the left for this feature to work"
            >
              <Checkbox>
                Enable Money Trader Transfer (GAT Money to GAT Trader)
              </Checkbox>
            </Form.Item>
          )}
          {clientAccountID === 8 &&
            <Form.Item
              valuePropName='checked'
              name='feature_gatTraderCommissions'
            >
              <Checkbox>
                  Enable Gat Trader Referral System
              </Checkbox>
            </Form.Item>
          }
          <Divider />
          <Form.Item
            label='Feature Check'
          >
            {state?.featureCheck ? (
              <Tag color='green' >
                Success
              </Tag>
            ) : (
              <Tag color='red' >
                Fail
              </Tag>
            )}
            <Button
              loading={state.checkingFeature}
              onClick={checkFeature}
              icon={<SyncOutlined />}
            />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  )
}

export default DynamicFields
