import React, {
  useState,
  useEffect
} from 'react'

import {connect} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'

import * as CSV from 'csv-string'
import {
  Table,
  Upload,
  Button,
  notification
} from 'antd'

import {InboxOutlined} from '@ant-design/icons'
import {API} from 'aws-amplify'
import {clearState} from 'configs/actions/common'

const EmailSendFromCsvComponent = ({currentAccountID, clearState}) => {
  const [state, setState] = useState({})

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  const showNotificationWithIcon = (
    type,
    header,
    message
  ) => {
    notification[type]({
      message: header,
      description: message,
    })
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
    }
  ]

  // Requires parameters
  // old: true/false
  // clientEmail: email address
  // accountId: account id
  const gatMoneyActivated = async (data) => {
    let clientId
    await API.post('clients', '/check-email-by-account', {
      body: {
        email: data.clientEmail,
        account_id: data.accountId
      }
    })
      .then((res) => {
        console.log(res)
        clientId = res.payload.results[0].id
      })
      .catch((err) => {
        console.error(err)
        showNotificationWithIcon(
          'error',
          'Error',
          err.message
        )
        return
      })
    let client
    await API.get('clients', `/get-by-id/${clientId}`)
      .then((res) => {
        console.log(res)
        client = res.payload
      })
      .catch((err) => {
        console.error(err)
        showNotificationWithIcon(
          'error',
          'Error',
          err.message
        )
        return
      })
    if (data.old) {
      await API.post('email', '/gatmoney-active-old', {
        body: {
          data: {
            id: clientId,
            clientRecord: client,
            // username: client.platformUsername,
            // platformPassword: client.platformPassword
          }
        }
      })
        .then((res) => {
          console.log(res)
          showNotificationWithIcon(
            'success',
            'Success',
            'Email Sent to ' + data.clientEmail
          )
        })
        .catch((err) => {
          console.error(err)
          showNotificationWithIcon(
            'error',
            'Error',
            err.message
          )
        })
    } else if (client.platformUsername && client.platformPassword) {
      await API.post('email', '/gatmoney-active', {
        body: {
          data: {
            id: clientId,
            clientRecord: client,
            username: client.platformUsername,
            platformPassword: client.platformPassword
          }
        }
      })
        .then((res) => {
          console.log(res)
          showNotificationWithIcon(
            'success',
            'Success',
            'Email Sent to ' + data.clientEmail
          )
        })
        .catch((err) => {
          console.error(err)
          showNotificationWithIcon(
            'error',
            'Error',
            err.message
          )
        })
    } else {
      await API.post('email', '/interaction', {
        body: {
          interaction: `Fail sending activation email to client ID ${client.id} email ${client.email}`,
          result: 'Given email address does not have a platform username and platform password'
        }
      })
        .then((res) => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
      showNotificationWithIcon(
        'error',
        'Error',
        `Email ${data.clientEmail} does not have a platform username and password}`
      )
    }
  }

  const emailMigration = async (data) => {
    let clientId
    await API.post('clients', '/check-email-by-account', {
      body: {
        email: data.clientEmail,
        account_id: data.accountId
      }
    })
      .then((res) => {
        console.log(res)
        clientId = res.payload.results[0].id
      })
      .catch((err) => {
        console.error(err)
        showNotificationWithIcon(
          'error',
          'Error',
          err.message
        )
        return
      })
    let client
    await API.get('clients', `/get-by-id/${clientId}`)
      .then((res) => {
        console.log(res)
        client = res.payload
      })
      .catch((err) => {
        console.error(err)
        showNotificationWithIcon(
          'error',
          'Error',
          err.message
        )
        return
      })
    if (clientId && client) {
      await API.post('email', '/gatmoney-migration', {
        body: {
          data: {
            id: clientId,
            clientRecord: client,
            // username: client.platformUsername,
            // platformPassword: client.platformPassword
          }
        }
      })
        .then((res) => {
          console.log(res)
          showNotificationWithIcon(
            'success',
            'Success',
            'Email Sent to ' + data.clientEmail + ` [${data.currentCount}]`
          )
        })
        .catch((err) => {
          console.error(err)
          showNotificationWithIcon(
            'error',
            'Error',
            err.message
          )
        })
    } else {
      await API.post('email', '/interaction', {
        body: {
          interaction: `Fail sending migration email to email ${data.clientEmail}`,
          result: 'Given email address did not return a result'
        }
      })
        .then((res) => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
      showNotificationWithIcon(
        'error',
        'Error',
        `Email ${data.clientEmail} - Given email address did not return a result`
      )
    }
  }
  
  const onEmailGatMoneyActivationClick = async () => {

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )

    for (let i = 0; i < state.dataSource.length; i++) {
      const record = state.dataSource[i]
      await gatMoneyActivated({
        old: false,
        clientEmail: record.email,
        accountId: currentAccountID,
      })
    }
    
    showNotificationWithIcon(
      'success',
      'Processing',
      'Processing data has completed'
    )

  }

  const onEmailGatMoneyActivationOldClick = async () => {

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )

    for (let i = 0; i < state.dataSource.length; i++) {
      const record = state.dataSource[i]
      await gatMoneyActivated({
        old: true,
        clientEmail: record.email,
        accountId: currentAccountID,
      })
    }
    
    showNotificationWithIcon(
      'success',
      'Processing',
      'Processing data has completed'
    )

  }

  const onEmailGatMoneyMigrationClick = async () => {

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )

    for (let i = 0; i < state.dataSource.length; i++) {
      const record = state.dataSource[i]
      await emailMigration({
        clientEmail: record.email,
        accountId: currentAccountID,
        currentCount: i
      })
    }
    
    showNotificationWithIcon(
      'success',
      'Processing',
      'Processing data has completed'
    )

  }

  return !state.loaded ? (
    <Upload.Dragger
      name="file"
      accept=".csv"
      multiple={false}
      beforeUpload={(file) => {
        const reader = new FileReader()

        reader.readAsText(file)

        reader.onload = async ({ target }) => {
          if (target && target.result && typeof target.result === 'string') {
            const arr = CSV.parse(target.result)

            const dataSource = []

            arr.forEach(
              (
                [
                  email,
                  value,
                ]
              ) => {
                dataSource.push({
                  email,
                  value
                })
              }
            )

            setState((prev) => ({
              ...prev,
              loaded: true,
              dataSource,
            }))
          }
        }

        return false
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single CSV upload.
      </p>
    </Upload.Dragger>
  ) : (
    
    <>
      <Table
        dataSource={state.dataSource}
        columns={columns}
        rowKey="gmtId"
        scroll={{ x: true }}
        pagination={{
          pageSizeOptions: ['5', '10', '15'],
          defaultPageSize: 5,
          showSizeChanger: true,
        }} />
      {state.dataSource.length > 0 &&
        <>
          <Button
            loading={state.loading}
            onClick={onEmailGatMoneyActivationClick}
          >
            Email - GAT Money Activation
          </Button>
          <Button
            loading={state.loading}
            onClick={onEmailGatMoneyActivationOldClick}
          >
            Email - GAT Money Activation [OLD]
          </Button>
          <Button
            loading={state.loading}
            onClick={onEmailGatMoneyMigrationClick}
          >
            Email - Migration
          </Button>
        </>
      }
    </>
  )
}

const mapStateToProps = ({language, ...state}) => {
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  return {currentAccountID: currentAccountRecord.id}
}

export default connect(mapStateToProps, {clearState})(EmailSendFromCsvComponent)
