import React, {Component} from 'react'
import {API} from 'aws-amplify'

import {Table, Row, Col, notification, Form, Input, Button, Modal} from 'antd'

import {DeleteOutlined} from '@ant-design/icons'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class UserClientClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client_id: '',
      is_saving: false,
      tableLoading: false
    }

    this.columns = [
      {
        title: 'Client ID',
        dataIndex: 'client_id'
      },
      {
        title: 'Allow',
        dataIndex: 'allow'
      },
      {
        title: 'Action',
        render: (text, record) => (
          <span>
            <DeleteOutlined onClick={() => this.showModal(this.state.user_client_id, record.client_id)} />
          </span>
        )
      }
    ]
  }

  async componentDidMount() {
    await this.getUserClientAccessList()
  }

  async saveUserClientRecord(userClient_record) {
    await API.post('user-client', '/create/access-record', {body: userClient_record})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  async getUserClientAccessList() {
    this.setState({tableLoading: true})
    await API.get('user-client', `/access-list-by-id/${this.props.id}`)
      .then(response => {
        const dataSource = []
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            client_id: response.payload[i].client_id,
            allow: response.payload[i].allow
          })
        }
        this.setState({
          dataSource: dataSource,
          user_client_id: this.props.id,
          tableLoading: false
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  deleteRecord = async (user_client_id, client_id) => {
    await API.put('user-client', `/delete/${user_client_id}/${client_id}`)
      .then(async response => {
        await this.getUserClientAccessList()
      })
      .catch(error => {
        console.log(error)
      })
  };

  handleSubmit = async event => {
    // event.preventDefault();
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {client_id} = this.state
    try {
      await this.saveUserClientRecord({
        client_id,
        user_client_id: this.props.id
      }).then(async response => {
        await this.getUserClientAccessList()
        this.setState({
          client_id: '',
          is_saving: false
        })
      })
    } catch (e) {
      console.log(e)
      this.setState({
        client_id: '',
        is_saving: false
      })
      openNotificationWithIcon('error', 'Error on Save', e.message)
    }
  };

  showModal = (user_client_id, client_id) => {
    this.setState({
      visible: true,
      user_client_id,
      client_id
    })
  };

  onHideModalOk = () => {
    this.setState({visible: false})
    if (Boolean(this.state.user_client_id) && Boolean(this.state.client_id)) {
      this.deleteRecord(this.state.user_client_id, this.state.client_id)
        .then(async response => {
          await this.getUserClientAccessList()
          this.setState({client_id: ''})
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  handleChange = event => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value})
  };

  onHideModalCancel = () => {
    this.setState({visible: false})
  };

  render() {
    return (
      <React.Fragment>
        <Form layout='vertical' onFinish={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Table columns={this.columns} loading={this.state.tableLoading} dataSource={this.state.dataSource} />
            </Col>
            <Col xs={12}>
              <h3>Add a new Client ID to this user</h3>
              <Form.Item label={'Client ID'}>
                <Input
                  id='client_id'
                  value={this.state.client_id}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Button type='primary' htmlType='submit' loading={this.state.is_saving}>
                Add Client
              </Button>
            </Col>
          </Row>
          <Modal
            title="Are you sure you're going to delete this record?"
            visible={this.state.visible}
            onOk={this.onHideModalOk}
            onCancel={this.onHideModalCancel}
            okText='Confirm'
            cancelText='Cancel'
          ></Modal>
        </Form>
      </React.Fragment>
    )
  }
}

export default UserClientClients
