import {API} from 'aws-amplify'
import {showNotification} from 'utils'

export const getIdentificationDocumentCategories = async (countryId: number) => {
  try {
    return API.get('identification', `/categories/country/${countryId}`, {})
  } catch (e: any) {
    showNotification(
      'error',
      'Failed to get POA document categories',
      e?.response?.data?.error || e.message
    )
    return {payload: []}
  }
}

export const getCountries = async () => {
  try {
    return API.get('countries', '/list', {})
  } catch (e: any) {
    showNotification(
      'error',
      'Failed to get countries',
      e?.response?.data?.error || e.message
    )
    return {payload: []}
  }
}

export const getPOACategories = async () => {
  try {
    return API.get('identification', '/poa_categories', {})
  } catch (e: any) {
    showNotification(
      'error',
      'Failed to get POA categories',
      e?.response?.data?.error || e.message
    )
    return {payload: []}
  }
}

export const createDocuments = async (client_id: number, account_id: number, files: {id: string; type: string}[]) => {
  return API.post('documents', '/create', {
    body: {
      client_id,
      description: 'Identification Record',
      long_description: 'Identification Record',
      document_type_id: 2,
      files,
      account_id
    }
  })
}
export const createIdentificationRecord = async (data: any) => {
  return API.post(
    'identification',
    '/create',
    {body: data}
  )
}

export const getClients = async (accountID: number) => {
  try {
    return API.get('clients', `/list/account/${accountID}`, {})
  } catch (e: any) {
    showNotification(
      'error',
      'Failed to fetch clients',
      e?.response?.data?.error || e.message
    )
    return {payload: []}
  }
}