import React, {
  Fragment,
  useState,
  useEffect
} from 'react'

import {
  Col,
  Descriptions,
  Empty,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  Typography,
  Divider
} from 'antd'
import API from '@aws-amplify/api'
import Axios from 'axios'
import {columnSize} from 'pages/dashboard/common'

import {FormattedMessage} from 'react-intl'
import {showNotification} from 'utils'
import {FormSelect} from 'common'

export const TradingViewComponent = ({
  id,
  trading_platform_type_file_by,
  trading_platform_mode_file_by,
  currencies_iso_alpha_3,
  client_id,
  client_first_name,
  tradingPlatformId,
  accountLock,
  tradingPlatformCreated,
  securityLock,
  recordCreated,
  tradingPlatformUsername,
  client_last_name,
  client_email,
  client_tel_mobile,
  pw,
  teg,
  defaultWithdrawalAccountID,
  ...rest
}) => {

  const [state, setState] = useState({tradingAccountsByAccount: []})
  const [sendingNewAccountEmailButton, setSendingNewAccountEmailButton] = useState(false)
  const [sendingInfoEmailButton, setSendingInfoEmailButton] = useState(false)
  const getTransactionAccountList = async() => {
    await API.get('financial-accounts', `/list/${client_id}`).then(
      response => {
        setState({
          ...state,
          tradingAccountsByAccount: response.payload
        })

        const tradingAccountsOptions = []
        for (let i = 0; i < response.payload.length; i++) {
          const item = response.payload[i]
          tradingAccountsOptions.push(
            {
              text: item.id,
              value: `${item.financial_account_type_name} ${item.fileBy} `,
            }
          )
        }

        setState({
          ...state,
          tradingAccountsOptions: tradingAccountsOptions
        })
      }
    )
  }

  const fields = [
    {
      label: 'ID',
      field: id
    },
    {
      label: 'Trading Platform Type',
      field: trading_platform_type_file_by
    },
    {
      label: 'Trading Platform Mode',
      field: trading_platform_mode_file_by
    },
    {
      label: 'Trading Platform ID',
      field: tradingPlatformId
    },
    {
      label: 'Currency',
      field: currencies_iso_alpha_3
    }
  ]

  const createTeLiveAccount = async () => {
    if (
      !client_first_name ||
      !client_last_name ||
      !client_email ||
      !client_tel_mobile ||
      !tradingPlatformUsername ||
      !pw ||
      !teg
    ) {
      showNotification('error', 'Client Email', 'Unable to send client email.  Required information is missing.')
      return
    }
    showNotification('info', 'Trading Account', 'Creating account in Trader Evolution')
    await Axios.post('https://mario.pimwatech.com/ctin/live', {
      first_name: client_first_name,
      last_name: client_last_name,
      name: `${client_first_name} ${client_last_name}`,
      email: client_email,
      telephone: client_tel_mobile,
      password: pw,
      user_id: tradingPlatformUsername,
      te_group: teg
    })
      .then(response => {
        showNotification('success', 'Trading Account', 'Trading account successfully created')
      })
      .catch(error => {
        showNotification('error', 'Trading Account', error.message)
      })
  }

  const sendWelcomeEmail = async () => {
    setSendingNewAccountEmailButton(true)
    if (!client_id || !tradingPlatformUsername || !pw) {
      showNotification('error', 'Client Email', 'Unable to send client email.  Required information is missing.')
      setSendingNewAccountEmailButton(false)
      return
    }
    try {
      await API.post('email', '/client/trading-account', {
        body: {
          client_id,
          tradingPlatformUsername,
          pw,
          newAccount: true
        }
      })
      setSendingNewAccountEmailButton(false)
      showNotification('success', 'Welcome Email', 'Email successfully sent')
    } catch (error) {
      setSendingNewAccountEmailButton(false)
      showNotification('error', 'Welcome Email', error.message)
    }
  }

  const sendAccountDetailsEmail = async () => {
    setSendingInfoEmailButton(true)
    if (!client_id || !tradingPlatformUsername ) {
      showNotification('error', 'Client Email', 'Unable to send client email.  Required information is missing.')
      setSendingInfoEmailButton(false)
      return
    }
    try {
      await API.post('email', '/client/trading-account', {
        body: {
          client_id,
          tradingPlatformUsername,
          newAccount: false
        }
      })
      setSendingInfoEmailButton(false)
      showNotification('success', 'Account Details Email', 'Email successfully sent')
    } catch (error) {
      setSendingInfoEmailButton(false)
      showNotification('error', 'Account Details Email', error.message)
    }
  }

  useEffect(() => {
    getTransactionAccountList()
  }, [])
    
  return (
    <Fragment>
      <Row gutter={[96, 24]}>
        <Col {...columnSize}>
          {id ? (
            <Descriptions
              bordered
              layout='vertical'
              size='small'
              column={{
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3
              }}
            >
              {fields.map(({label, field}) => (
                <Descriptions.Item label={label} key={label}>
                  {field}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ) : (
            <Empty />
          )}
        </Col>
        <Col md={6}>
          <Form.Item
            label='Platform Account Username'
            name='tradingPlatformUsername'
            initialValue={tradingPlatformUsername}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Password' name='pw' initialValue={pw}>
            <Input />
          </Form.Item>
          <Form.Item
            name='teg'
            label='Trading User Group'
            initialValue={teg}
            help="GAT Trader User Group: 922323"
          >
            <Input />
          </Form.Item>
          <Divider />
          <Form.Item
            name='defaultWithdrawalAccountID'
            label='Default Withdrawal Account ID'
            initialValue={defaultWithdrawalAccountID}
            help="Enter the Xeonda Financial Account ID for the default withdrawal account."
          >
            <FormSelect
              label='Trading Account'
              name='defaultWithdrawalAccountID'
              placeholder='Select Trading Account'
              optionValue='text'
              getOptionProps={({text, value}) => ({children: `[${text}] ${value}`})}
              showSearch
              allowClear
              options={state?.tradingAccountsOptions}
              filterOption={(input, option) => {
                return option.children.toLowerCase().includes(input.toLowerCase())
              }}
            />
          </Form.Item>
          <Divider />
          
          <Typography.Title level={4}>Commands</Typography.Title>
          <Button
            type='primary'
            disabled={
              !client_first_name || !client_last_name || !client_email || !tradingPlatformUsername || !pw || !teg
            }
            onClick={() => createTeLiveAccount()}
          >
            Create TE Account
          </Button>
          <br />
          <br />
          <Button
            type='primary'
            loading={sendingNewAccountEmailButton}
            disabled={!client_id || !tradingPlatformUsername || !pw}
            // disabled={true}
            onClick={() => sendWelcomeEmail()}
          >
            Email Client - New Account Created
          </Button>
          <br />
          <br />
          <Button
            type='primary'
            loading={sendingInfoEmailButton}
            disabled={!client_id || !tradingPlatformUsername }
            // disabled={true}
            onClick={() => sendAccountDetailsEmail()}
          >
            Email Client - Account Details
          </Button>
        </Col>

        <Col md={6}>
          <Form.Item name='accountLock' initialValue={accountLock} valuePropName={'checked'}>
            <Checkbox>Account Lock</Checkbox>
          </Form.Item>
          <Form.Item name='securityLock' initialValue={securityLock} valuePropName={'checked'}>
            <Checkbox>Security Lock</Checkbox>
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            <FormattedMessage id='components.save_record' />
          </Button>
        </Col>

        {/* <Col {...columnSize}>

        </Col> */}
      </Row>
    </Fragment>
  )
}
