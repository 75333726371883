import React from 'react'

import {Table} from 'components'
import {
  CreateDocument,
  SectionTitle
} from '../shared'

interface PoaProps {
  client_id: number | string;
  informationRequestId: number | string;
  poaDocuments: Array<{
    poaCategory: string;
    contentType: string;
    url: string;
  }>
}

const Poa: React.FC<PoaProps> = ({
  client_id,
  informationRequestId,
  poaDocuments
}) => {

  return (
    <>
      <SectionTitle>POA Documents</SectionTitle>
      <Table
        dataSource={poaDocuments}
        columns={[
          {
            title: 'Category',
            dataIndex: 'poaCategory'
          },
          {
            title: 'Content Type',
            dataIndex: 'contentType'
          },
          {
            title: 'Action',
            dataIndex: 'contentType',
            render: (_, doc) => (
              <CreateDocument
                kind='poa'
                contentType={doc.contentType}
                url={doc.url}
                client_id={client_id}
                informationRequestId={informationRequestId}
              />
            )
          },
        ]}
      />
    </>
  )
}

export default Poa
