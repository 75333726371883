import {Record} from 'immutable'

import * as types from './types'
import {CLEAR_STATE} from '../../constants'

const ReducerRecord = Record({
  entities: [],
  currentStaff: {},
  loading: false,
})

export default (state = new ReducerRecord(), {
  type,
  payload
}) => {
  switch (type) {
    case types.GET_ACCOUNT_LIST_REQUEST:
    case types.GET_ACCOUNT_TYPE_LIST_REQUEST:
    case types.UPDATE_ACCOUNT_RECORD_REQUEST:
    case types.CREATE_NEW_ACCOUNT_REQUEST:
      return state.set('loading', true)
    case types.GET_ACCOUNT_LIST_ERROR:
    case types.UPDATE_ACCOUNT_RECORD_ERROR:
    case types.GET_ACCOUNT_TYPE_LIST_ERROR:
    case types.CREATE_NEW_ACCOUNT_SUCCESS:
    case types.CREATE_NEW_ACCOUNT_ERROR:
      return state.set('loading', false)

    case types.GET_ACCOUNT_LIST_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload
        })
    case types.GET_ACCOUNT_TYPE_LIST_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload
        })
    case types.GET_ACCOUNT_RECORD_SUCCESS:
      return state
        .merge({
          loading: false,
          currentStaff: payload
        })
    case types.UPDATE_ACCOUNT_RECORD_SUCCESS:
      return state
        .merge({
          loading: false,
          currentStaff: payload
        })
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}