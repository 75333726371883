import React, {
  useEffect,
  Fragment,
  useState
} from 'react'

import {useHistory} from 'react-router-dom'

import {
  Col,
  Row,
  Spin,
  Form,
  Button,
  Descriptions,
  Drawer,
  Space,
  Switch,
  Input,
  InputNumber,
  notification,
  Select,
  Radio
} from 'antd'
import moment from 'moment'
import { API } from 'aws-amplify'
import {listHOC} from 'common'

const { Option } = Select

export const ClientCompanyShareholderDrawerComponent = listHOC(({
  visible_shareholder,
  companyDrawerData_shareholder,
  toggleModal,
  asyncFunction,
  getData,
  currentAccountID,
  clientRecord
}) => {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState(false)
  const history = useHistory()
  const [state, setState] = useState({})
  const [shareholderType, setShareholderType] = useState('1')
  const [beneficiallyOwned, setBeneficiallyOwned] = useState(Boolean(companyDrawerData_shareholder?.beneficiallyOwned) ? '1' : '0')
  useEffect(() => {
    setState({
      address: companyDrawerData_shareholder?.address,
      beneficialOwner: companyDrawerData_shareholder?.beneficialOwner,
      beneficiallyOwned: companyDrawerData_shareholder?.beneficiallyOwned,
      clientId: companyDrawerData_shareholder?.clientId,
      companyName: companyDrawerData_shareholder?.companyName,
      companyNumber: companyDrawerData_shareholder?.companyNumber,
      dateOfBirth: companyDrawerData_shareholder?.dateOfBirth,
      deleted: companyDrawerData_shareholder?.deleted,
      email: companyDrawerData_shareholder?.email,
      fileBy: companyDrawerData_shareholder?.fileBy,
      id: companyDrawerData_shareholder?.id,
      individualName: companyDrawerData_shareholder?.individualName,
      shareholderType: companyDrawerData_shareholder?.shareholderType,
      telephoneMobile: companyDrawerData_shareholder?.telephoneMobile,
      ownershipPercentage: companyDrawerData_shareholder?.ownershipPercentage,
    })
  }, [companyDrawerData_shareholder])

  if (companyDrawerData_shareholder == null) {
    return <div></div>
  }

  const onTextboxChange = (event, state) => {
    // Have to use event persist to stable https://reactjs.org/docs/events.html#event-pooling
    event.persist()

    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value
      }
    })
  }

  const onInputNumbeChange = (value, state) => {
    setState((prevState) => {
      return {
        ...prevState,
        'ownershipPercentage': value
      }
    })
  }
  const saveRecord = (state) => {
    delete state.enableEdited
    try {
      API.put('client-company', `/update-shareholders/${companyDrawerData_shareholder?.clientId}/${companyDrawerData_shareholder?.id}`, {
        body: {
          shareholderType: state.shareholderType,
          beneficiallyOwned: beneficiallyOwned,
          beneficialOwner: state?.beneficialOwner,
          individualId: state.shareholderType === '1' ? companyDrawerData_shareholder.individualId : null,
          companyId: state.shareholderType === '2' ? companyDrawerData_shareholder.individualId : null,
          clientsShareholders: {
            individualName: state.shareholderType === '1' ? state?.individualName : null,
            companyName: state.shareholderType === '2' ? state?.companyName : null,
            companyNumber: state.shareholderType === '2' ? state?.companyNumber : null,
            shareholderType: state?.shareholderType ? state.shareholderType : companyDrawerData_shareholder.shareholderType,
            beneficiallyOwned,
            individualId: state.shareholderType === '1' ? companyDrawerData_shareholder.individualId : null,
            companyId: state.shareholderType === '2' ? companyDrawerData_shareholder.individualId : null,
            ownershipPercentage: state.ownershipPercentage
          },
          contactIndividuals: {
            fileBy: `${state?.lastName ? state.lastName : companyDrawerData_shareholder?.lastName}, ${state?.firstName ? state.firstName : companyDrawerData_shareholder?.firstName}`,
            firstName: state?.firstName,
            middleNames: state?.middleNames,
            lastName: state?.lastName,
            dateOfBirth: state?.dateOfBirth,
            telephoneMobile: state?.telephoneMobile,
            address: state?.address,
            streetAddressUnitNumber: state?.streetAddressUnitNumber,
            streetAddressStreetNumber: state?.streetAddressStreetNumber,
            streetAddressStreetName: state?.streetAddressStreetName,
            streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
            streetAddressSuburb: state?.streetAddressSuburb,
            streetAddressState: state?.streetAddressState,
            streetAddressPostcode: state?.streetAddressPostcode,
            streetAddressCountryId: state?.streetAddressCountryId
          },
          contactCompanies: {
            fileBy: `${state?.lastName ? state.lastName : companyDrawerData_shareholder?.lastName}, ${state?.firstName ? state.firstName : companyDrawerData_shareholder?.firstName}`,
            firstName: state?.firstName,
            middleNames: state?.middleNames,
            lastName: state?.lastName,
            dateOfBirth: state?.dateOfBirth,
            telephoneMobile: state?.telephoneMobile,
            address: state?.address,
            streetAddressUnitNumber: state?.streetAddressUnitNumber,
            streetAddressStreetNumber: state?.streetAddressStreetNumber,
            streetAddressStreetName: state?.streetAddressStreetName,
            streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
            streetAddressSuburb: state?.streetAddressSuburb,
            streetAddressState: state?.streetAddressState,
            streetAddressPostcode: state?.streetAddressPostcode,
            streetAddressCountryId: state?.streetAddressCountryId
          }
        }
      })

      notification.success({message: 'Saved !'})
      getData()
    } catch (error) {
      console.error('error occured', error)
      notification.error({message: error.message})
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  const onSelectChange = (value) => {
    setShareholderType(value)
    setState(
      (prevState) => {
        return {
          ...prevState,
          'shareholderType': value,
        }
      })
  }

  const onRadioChange = (e) => {
    setBeneficiallyOwned(e.target.value)
    setState(
      (prevState) => {
        return {
          ...prevState,
          'beneficiallyOwned': e.target.value,
        }
      })
  }

  const createNewInfoReq = () => {
    setButtonLoading(true)
    API.post('information-requests', '/create', {
      body: {
        clientId: companyDrawerData_shareholder.clientId,
        accountId: currentAccountID,
        informationRequestId: 1,
        email: clientRecord.email,
        firstName: clientRecord.first_name,
        lastName: clientRecord.last_name,
        activateFinancial: true,
        activateEdd: true,
        thirdParty: true,
        recipientFirstName: companyDrawerData_shareholder.firstName,
        recipientLastName: companyDrawerData_shareholder.lastName,
        recipientEmail: companyDrawerData_shareholder.email,
      }
    })
      .then(() => {
        setButtonLoading(false)
        history.push('/clients/information-requests/list')
        notification.success({message: 'New Record has been created !'})
      })
      .catch(error => {
        setButtonLoading(false)
        history.push('/clients/information-requests/list')
        notification.error({message: error.message})
      })
  }

  return (
    <Fragment>
      <Drawer
        title='Client Company Detail (Shareholder-Individual)'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible_shareholder}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!companyDrawerData_shareholder.id}>
            <Form form={form} scrollToFirstError={true} layout='vertical'>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Form.Item label='Edit / View'>
                  <Switch
                    checkedChildren='Edit'
                    unCheckedChildren='View'
                    id='enableEdited'
                    checked={state.enableEdited == 1}
                    onChange={event => {
                      return setState(
                        {
                          ...state,
                          enableEdited: event,
                        }
                      )
                    }}
                  />
                </Form.Item>
                <Col xs={24}>
                  {companyDrawerData_shareholder.id && !state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='ID'>
                          <strong>{companyDrawerData_shareholder.id}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Client ID'>
                          <strong>{companyDrawerData_shareholder.clientId}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Firstname'>
                          <strong>{companyDrawerData_shareholder.firstName}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Middlename'>
                          <strong>{companyDrawerData_shareholder.middleNames}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Lastname'>
                          <strong>{companyDrawerData_shareholder.lastName}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Shareholder Type'>
                          <strong>{companyDrawerData_shareholder.shareholderType === '1' ? 'Individual' : 'Company'}</strong>
                        </Descriptions.Item>
                        {companyDrawerData_shareholder.shareholderType === '1' &&
                        <Descriptions.Item label='Individual Name'>
                          <strong>{companyDrawerData_shareholder.individualName}</strong>
                        </Descriptions.Item>
                        }
                        {companyDrawerData_shareholder.shareholderType === '2' &&
                      <Descriptions.Item label='Company Name'>
                        <strong>{companyDrawerData_shareholder.companyName}</strong>
                      </Descriptions.Item>
                        }
                        {companyDrawerData_shareholder.shareholderType === '2' &&
                      <Descriptions.Item label='Company Number'>
                        <strong>{companyDrawerData_shareholder.companyNumber}</strong>
                      </Descriptions.Item>
                        }
                        {companyDrawerData_shareholder.shareholderType === '1' &&
                      <Descriptions.Item label='Date Of Birth'>
                        <strong>{
                          companyDrawerData_shareholder?.dateOfBirth
                            ? moment(companyDrawerData_shareholder.dateOfBirth).format('YYYY/MM/DD')
                            : ''
                        }</strong>
                      </Descriptions.Item>
                        }
                        <Descriptions.Item label='Beneficially Owned'>
                          <strong>{companyDrawerData_shareholder.beneficiallyOwned === '1' ? 'Yes' : 'No'}</strong>
                        </Descriptions.Item>
                        {companyDrawerData_shareholder.beneficiallyOwned === '0' &&
                        <Descriptions.Item label='Beneficially Owner'>
                          <strong>{companyDrawerData_shareholder.beneficiallyOwner}</strong>
                        </Descriptions.Item>
                        }
                        <Descriptions.Item label='Address'>
                          <strong>{companyDrawerData_shareholder.address}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Ownership Percentage'>
                          <strong>{companyDrawerData_shareholder.ownershipPercentage}</strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Button
                        onClick={createNewInfoReq}
                        loading={buttonLoading}
                        disabled={
                          !companyDrawerData_shareholder.firstName ||
                  !companyDrawerData_shareholder.lastName ||
                  !companyDrawerData_shareholder.email
                        }
                      > Create New Info Req </Button>
                    </>

                  )}
                  {companyDrawerData_shareholder.id && state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='ID'>
                          <Input id='id' disabled value={state?.id ? state.id : companyDrawerData_shareholder.id} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Firstname'>
                          <Input id='firstName' value={state?.firstName ? state.firstName : companyDrawerData_shareholder.firstName} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Middlename'>
                          <Input id='middleNames' value={state?.middleNames ? state.middleNames : companyDrawerData_shareholder.middleNames} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Lastname'>
                          <Input id='lastName' value={state?.lastName ? state.lastName : companyDrawerData_shareholder.lastName} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Shareholder Type' required>
                          <Select
                            id='shareholderType'
                            style={{width: 120,}}
                            placeholder={'Please select...'}
                            defaultValue={companyDrawerData_shareholder?.shareholderType ? companyDrawerData_shareholder.shareholderType : '1'}
                            onChange={onSelectChange}
                            disabled
                          >
                            <Option value="1">Individual</Option>
                            <Option value="2">Company</Option>
                          </Select>
                        </Descriptions.Item>
                        {(state.shareholderType === '1') &&
                          <Descriptions.Item label='Individual Name'>
                            <Input id='individualName' value={state?.individualName ? state.individualName : companyDrawerData_shareholder.individualName} onChange={(event) => onTextboxChange(event, state)} />
                          </Descriptions.Item>
                        }
                        {(state.shareholderType === '2') &&
                          <Descriptions.Item label='Company Name'>
                            <Input id='companyName' value={state?.companyName ? state.companyName : companyDrawerData_shareholder.companyName} onChange={(event) => onTextboxChange(event, state)} />
                          </Descriptions.Item>
                        }
                        {(state.shareholderType === '2') &&
                        <Descriptions.Item label='Company Number'>
                          <Input id='companyNumber' value={state?.companyNumber ? state.companyNumber : companyDrawerData_shareholder.companyNumber} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        }
                        {(state.shareholderType === '1') &&
                        <Descriptions.Item label='Date Of Birth'>
                          <Input id='dateOfBirth'
                            placeholder='YYYY-MM-DD'
                            defaultValue={companyDrawerData_shareholder?.dateOfBirth
                              ? moment(companyDrawerData_shareholder.dateOfBirth).format('YYYY-MM-DD')
                              : ''}
                            onChange={(event, state) => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        }
                        <Descriptions.Item label='Beneficially Owned'>
                          <Radio.Group onChange={onRadioChange} value={beneficiallyOwned}>
                            <Space direction="vertical">
                              <Radio value={'1'}>Yes</Radio>
                              <Radio value={'0'}>No</Radio>
                            </Space>
                          </Radio.Group>
                        </Descriptions.Item>
                        {beneficiallyOwned === '0' &&
                          <Form.Item name='beneficialOwner' label='Beneficial Owner'>
                            <Input id='beneficialOwner' value={state?.beneficialOwner ? state.beneficialOwner : companyDrawerData_shareholder.beneficialOwner} onChange={(event) => onTextboxChange(event, state)} />
                          </Form.Item>
                        }
                        <Descriptions.Item label='Address'>
                          <Input id='address' value={state?.address ? state.address : companyDrawerData_shareholder.address} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Telephone'>
                          <Input id='telephoneMobile' value={state?.telephoneMobile ? state.telephoneMobile : companyDrawerData_shareholder.telephoneMobile} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Ownership Percentage'>
                          <InputNumber id='ownershipPercentage' max={100} min={1} value={state?.ownershipPercentage ? state.ownershipPercentage : companyDrawerData_shareholder.ownershipPercentage} onChange={(event) => onInputNumbeChange(event, state)} precision={0} step={1}/>
                        </Descriptions.Item>
                      </Descriptions>
                      <Button onClick={ ()=> saveRecord(state)}> Save </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
})