import {APP_NAME} from 'consts'

export const moduleName = 'teams'

export const FETCH_TEAMS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_TEAMS_REQUEST`
export const FETCH_TEAMS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_TEAMS_SUCCESS`
export const FETCH_TEAMS_ERROR = `${APP_NAME}/${moduleName}/FETCH_TEAMS_ERROR`

export const GET_TEAM_REQUEST = `${APP_NAME}/${moduleName}/GET_TEAM_REQUEST`
export const GET_TEAM_SUCCESS = `${APP_NAME}/${moduleName}/GET_TEAM_SUCCESS`
export const GET_TEAM_ERROR = `${APP_NAME}/${moduleName}/GET_TEAM_ERROR`

export const SAVE_TEAM_REQUEST = `${APP_NAME}/${moduleName}/SAVE_TEAM_REQUEST`
export const SAVE_TEAM_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_TEAM_SUCCESS`
export const SAVE_TEAM_ERROR = `${APP_NAME}/${moduleName}/SAVE_TEAM_ERROR`

export const CREATE_TEAM_REQUEST = `${APP_NAME}/${moduleName}/CREATE_TEAM_REQUEST`
export const CREATE_TEAM_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_TEAM_SUCCESS`
export const CREATE_TEAM_ERROR = `${APP_NAME}/${moduleName}/CREATE_TEAM_ERROR`

export const DELETE_TEAM_REQUEST = `${APP_NAME}/${moduleName}/DELETE_TEAM_REQUEST`
export const DELETE_TEAM_SUCCESS = `${APP_NAME}/${moduleName}/DELETE_TEAM_SUCCESS`
export const DELETE_TEAM_ERROR = `${APP_NAME}/${moduleName}/DELETE_TEAM_ERROR`
