import React from 'react'

import {
  Col,
  Row,
  Form,
  InputSelect,
  ClientsSelect,
} from 'components'
import {COMMON_COL_PROPS} from 'consts'
import {CommonProps} from '../../types'

const COUNTRIES = [
  {
    value: 'CN',
    children: 'China'
  },
  {
    value: 'TW',
    children: 'Taiwan'
  },
  {
    value: 'KR',
    children: 'South Korea'
  },
  {
    value: 'HK',
    children: 'Hong Kong'
  },
  {
    value: 'MY',
    children: 'Malaysia'
  },
  {
    value: 'AU',
    children: 'Australia'
  },
]

const ClientStep: React.FC<CommonProps> = ({children, initialValues}) => {
  return (
    <Form
      name='client'
      layout='vertical'
      initialValues={initialValues}
    >
      <Row gutter={[12, 0]}>
        <Col {...COMMON_COL_PROPS}>
          <ClientsSelect
            required
            name='clientId'
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputSelect
            required
            name='countryCode'
            label='common.country'
            options={COUNTRIES}
          />
        </Col>
      </Row>
      {children}
    </Form>
  )
}
export default ClientStep