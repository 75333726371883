import reducer from './reducers'

import * as settingsSelectors from './selectors'
import * as settingsActions from './actions'
import * as settingsTypes from './types'
import settingsSaga from './sagas'

export {
  settingsSaga,
  settingsTypes,
  settingsActions,
  settingsSelectors,
}

export default reducer