import React, {Component} from 'react'

import {API} from 'aws-amplify'

import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input
} from 'antd'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class AccountBranding extends Component {
  constructor(props) {
    super(props)
    this.state = {is_saving: false}
  }

  async componentDidMount() {
    const account = await this.getAccountRecord(this.props.accountID)
    this.setState({...account.payload})
  }

  async getAccountRecord(id) {
    return API.get('accounts', `/get-by-id/${id}`)
  }

  async saveAccountRecord(account_record) {
    await API.put('accounts', `/update/${this.state.id}`, {body: account_record})
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  handleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Process', 'Record is being saved')

    const {favicon} = this.state

    try {
      await this.saveAccountRecord({favicon})
      this.setState({is_saving: false})
      openNotificationWithIcon('success', 'Save Complete', 'Record has been successfully saved')
    } catch (e) {
      console.log(e)
      openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
      this.setState({is_saving: false})
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form layout='vertical' onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label='favicon'>
                <Input
                  id='favicon'
                  value={this.state.favicon}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[96, 24]}>
            <Col xs={24} style={{textAlign: 'right'}}>
              <Button type='primary' htmlType='submit' loading={this.state.is_saving} onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

export default AccountBranding
