import React from 'react'

import {connect} from 'react-redux'
import {
  settingsActions,
  settingsSelectors
} from 'configs/ducks/settings'

import {appStateSelectors} from 'configs/ducks/appState'

import {
  Tabs,
  Spin,
  Skeleton
} from 'antd'

import AccountSummary from './account_view/AccountSummary'
import AccountBranding from './account_view/AccountBranding'

const {TabPane} = Tabs

const AccountViewPageComponent = (
  {
    saveAccount,
    accountRecord,
    loading
  }
) => {
  const saveAccountRecord = values => {
    saveAccount({
      ...accountRecord,
      ...values
    })
  }
  return (
    <Skeleton active loading={!accountRecord.id}>
      <Spin spinning={loading}>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Summary' key='1'>
            <AccountSummary {...accountRecord} saveAccountRecord={saveAccountRecord}/>
          </TabPane>
          <TabPane tab='Branding' key='2'>
            <AccountBranding accountID={accountRecord.id} />
          </TabPane>
        </Tabs>
      </Spin>
    </Skeleton>
  )
}

const mapStateToProps = state => {
  const {loading} = settingsSelectors.stateSelector(state)
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  return {
    accountRecord: currentAccountRecord,
    loading
  }
}

const AccountViewPageComponentContainer = connect(
  mapStateToProps,
  {saveAccount: settingsActions.saveAccount}
)(AccountViewPageComponent)

export default AccountViewPageComponentContainer
