import * as React from 'react'
import {
  Table,
  Row,
  notification,
  Button,
  PlusOutlined
} from 'components'
import {CreateDocument} from './components'
import {FULL_DATE_TIME_FORMAT,} from 'consts'
import {listHOC} from 'hocs'
import dayjs from 'dayjs'
import {
  API,
  saveFile,
  Storage
} from 'utils'
import {createUseStyles} from 'react-jss'
import {
  DocWDocument,
  ListHOCChildProps
} from 'types'

const useStyles = createUseStyles({marginTop: {marginTop: 14}})

const DocWList: React.FC<ListHOCChildProps<DocWDocument, {client_id: string;}>> = ({
  getColumnSearchProps, entities, updateState, loading
}) => {
  const classes = useStyles()
  const [state, setState] = React.useState<{
    drawerOpen: boolean;
  }>({drawerOpen: false,})

  const columns = [
    getColumnSearchProps({
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (id, {awsFileID}) => (
        <Button
          type='link'
          onClick={async () => {
            try {
              const {Body} = await Storage.get(awsFileID, {
                bucket: 'wordDocuments',
                download: true
              })

              saveFile(awsFileID, Body)
            } catch (e: any) {
              notification.error({message: e.message})
            }
          }}
        >
          {id}
        </Button>
      )
    }),
    getColumnSearchProps({
      title: 'Title',
      dataIndex: 'title',
    }),
    getColumnSearchProps({
      title: 'Created',
      dataIndex: 'createdAt',
      render: (_, record) => (
        dayjs(record.createdAt).format(FULL_DATE_TIME_FORMAT)
      )
    })
  ]

  return (
    <>
      <Row justify='end'>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            setState(prevState => ({
              ...prevState,
              drawerOpen: true
            }))
          }}
        />
      </Row>
      <CreateDocument
        open={state.drawerOpen}
        onSuccess={(document) => {
          updateState({entities: entities.concat(document)})
        }}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            drawerOpen: false
          }))
        }}
      />
      <Table
        rowKey='id'
        className={classes.marginTop}
        loading={loading}
        columns={columns}
        scroll={{x: true}}
        dataSource={entities}
        pagination={{
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true
        }}
      />
      
    </>
  )
}

export default listHOC<DocWDocument, any, {client_id: string;}>(
  DocWList,
  async ({match, rootState}) => API.get('clients', `/${match.params.client_id}/docw/documents`)
)
