import React, {
  useEffect,
  Fragment,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'

import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {
  Col,
  Input,
  Row,
  Spin,
  Form,
  Button,
  InputDatePicker,
  InputSelect,
  ClientsSelect,
  Typography,
  Descriptions,
  Drawer,
  Space,
} from 'components'

import moment from 'moment'
import {openNotificationWithIcon} from 'configs/utils'
import {API} from 'aws-amplify'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import {
  EditorState,
  convertToRaw,
  ContentState
} from 'draft-js'
import {columnSize} from '../../common'

const {Title} = Typography

const priorityTypes = [
  {
    id: 1,
    label: 'HIGH'
  },
  {
    id: 2,
    label: 'MEDIUM'
  },
  {
    id: 3,
    label: 'LOW'
  }
]

const dateFormat = 'DD/MM/YYYY'

export const ClientTasksPageDrawerComponent = ({
  visible,
  organiserData,
  toggleModal,
  staffList,
  currentAccountID,
  callback
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, seteditorText] = useState('')
  const onEditorStateChange = editor => {
    setEditorState(editor)
    seteditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }
  const {clientRecord} = useSelector(state => clientsSelectors.clientsSelector(state))
  const [form] = Form.useForm()

  useEffect(() => {
    if (organiserData) {
      form.setFieldsValue(organiserData)

      if (organiserData.notes) {
        const html = organiserData.notes
        const contentBlock = htmlToDraft(html)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  }, [organiserData])

  const onSubmit = async values => {
    await API.put('tasksClient', `/update/${organiserData.id}`, {
      body: {
        ...values,
        notes: editorText
      }
    })
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
    if (callback) {
      callback(currentAccountID)
    }
    toggleModal(false)
  }

  if (organiserData == null) {
    return <div></div>
  }

  if (organiserData.dueDate) {
    organiserData.dueDate = moment(moment(organiserData.dueDate).format(dateFormat), dateFormat)
  }

  if (organiserData.completedDate) {
    organiserData.completedDate = moment(moment(organiserData.completedDate).format(dateFormat), dateFormat)
  }

  return (
    <Fragment>
      <Drawer
        title='Client Task Detail'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!organiserData.id}>
            <Form
              form={form}
              initialValues={organiserData}
              scrollToFirstError= {true}
              layout='vertical'
              onFinish={onSubmit}
            >
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Col xs={24}>
                  <Row gutter={[48, 0]}>
                    <Col {...columnSize}>
                      <InputSelect
                        required
                        name='staffId'
                        label='common.staff'
                        options={staffList}
                        getOptionProps={opt => ({
                          value: opt.id,
                          children: `${opt.first_name} ${opt.last_name}`
                        })}
                      />
                    </Col>
                    <Col {...columnSize}>
                      <ClientsSelect
                        name='clientId'
                        label='common.client'
                        getOptionProps={opt => ({
                          value: opt.id,
                          children: `${opt.first_name} ${opt.last_name}`
                        })}
                      />
                    </Col>
                    <Col {...columnSize}>
                      <InputSelect
                        name='priority'
                        label='common.priority'
                        options={priorityTypes}
                      />
                    </Col>
                  </Row>
                  {clientRecord.id && (
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{
                        marginBottom: 24,
                        marginTop: 0
                      }}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='First Name'>
                        <strong>{clientRecord.first_name}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Last Name'>
                        <strong>{clientRecord.last_name}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Email'>
                        <strong>{clientRecord.email}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                  <Row gutter={[48, 0]}>
                    <Col {...columnSize}>
                      <InputDatePicker
                        name='dueDate'
                        label='date.due'
                        format={dateFormat}
                      />
                    </Col>
                    <Col {...columnSize}>
                      <Form.Item
                        name='completedFlag'
                        label='Task Completed'
                        valuePropName='checked'
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Title level={5}>Title</Title>

                  <Form.Item name='title'>
                    <Input />
                  </Form.Item>

                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{
                      border: '1px solid #F1F1F1',
                      borderRadius: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      height: '250px'
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col offset={18} xs={2}>
                  <Form.Item>
                    <Button type='primary' htmlType='submit'>
                      Save Record
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}
