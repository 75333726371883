import {APP_NAME} from 'consts'

export const moduleName = 'appState'

export const GET_CURRENT_COUNTRY_REQUEST = `${moduleName}/GET_CURRENT_COUNTRY_REQUEST`
export const GET_CURRENT_COUNTRY_SUCCESS = `${moduleName}/GET_CURRENT_COUNTRY_SUCCESS`
export const GET_CURRENT_COUNTRY_ERROR = `${moduleName}/GET_CURRENT_COUNTRY_ERROR`

export const SWITCH_STAFF_REQUEST = `${APP_NAME}/${moduleName}/SWITCH_STAFF_REQUEST`
export const SWITCH_STAFF_SUCCESS = `${APP_NAME}/${moduleName}/SWITCH_STAFF_SUCCESS`
export const SWITCH_STAFF_ERROR = `${APP_NAME}/${moduleName}/SWITCH_STAFF_ERROR`

export const SWITCH_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/SWITCH_ACCOUNT_REQUEST`
export const SWITCH_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/SWITCH_ACCOUNT_SUCCESS`

export const AUTHENTICATE_USER_REQUEST = `${APP_NAME}/${moduleName}/AUTHENTICATE_USER_REQUEST`
export const AUTHENTICATE_USER_SUCCESS = `${APP_NAME}/${moduleName}/AUTHENTICATE_USER_SUCCESS`
export const AUTHENTICATE_USER_ERROR = `${APP_NAME}/${moduleName}/AUTHENTICATE_USER_ERROR`

export const LOGIN_USER_REQUEST = `${APP_NAME}/${moduleName}/LOGIN_USER_REQUEST`
export const LOGIN_USER_SUCCESS = `${APP_NAME}/${moduleName}/LOGIN_USER_SUCCESS`
export const LOGIN_USER_ERROR = `${APP_NAME}/${moduleName}/LOGIN_USER_ERROR`

export const LOG_OUT_USER_REQUEST = `${APP_NAME}/${moduleName}/LOG_OUT_USER_REQUEST`
export const LOG_OUT_USER_SUCCESS = `${APP_NAME}/${moduleName}/LOG_OUT_USER_SUCCESS`
export const LOG_OUT_USER_ERROR = `${APP_NAME}/${moduleName}/LOG_OUT_USER_ERROR`

export const RE_MOUNT_APP = `${APP_NAME}/${moduleName}/RE_MOUNT_APP`

export const UPDATE_USER_LAST_ACTIVE_ACCOUNT = `${APP_NAME}/${moduleName}/UPDATE_USER_LAST_ACTIVE_ACCOUNT`

