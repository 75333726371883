import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import {openNotificationWithIcon} from '../../utils'

import * as types from './types'

function* fetchChecklistCollections(action) {
  const accountId = action.payload
  try {
    const {payload} = yield call([API, API.get], 'doc-check', `/collections/account/${accountId}`)
    yield put({
      type: types.FETCH_CHECKLISTCOLLECTIONS_SUCCESS,
      payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.FETCH_CHECKLISTCOLLECTIONS_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.FETCH_CHECKLISTCOLLECTIONS_REQUEST, fetchChecklistCollections),
  ])
}