import React, {
  Fragment,
  useState
} from 'react'
import {
  DatePicker,
  Row,
  Col,
  Typography,
  Table,
  Button
} from 'antd'
import {Area} from '@ant-design/charts'
import moment from 'moment'
import {API} from 'aws-amplify'
const {RangePicker} = DatePicker

interface IState {
  data: Array<String>[];
  headers: string[];
  name: string;
  dateSelected: boolean,
  startDate: Date;
  endDate: Date
}

export const HourlyPositions: React.FC = () => {
  // const {account_slug, account_name} = useSelector((state: any) => appStateSelectors.stateSelector(state).currentAccountRecord)
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    dateSelected: false,
    startDate: new Date(),
    endDate: new Date()
  })
  const [loading, setLoading] = useState(false)
  // recordDateTime vs openPositionsTotalProfit
  const [openPositionsTotalProfitChartData, setOpenPositionsTotalProfitChart] = useState([])
  // recordDateTime vs totalProfitTradesReport
  const [totalProfitTradesReportChartData, setTotalProfitTradesReportChart] = useState([])

  const fetch = async (start: moment.Moment, end: moment.Moment): Promise<any> => {
    const response = await API.post('reports', '/position/hourly', {
      body: {
        startDate: moment(start).format('YYYY-MM-DD'),
        endDate: moment(end).format('YYYY-MM-DD')
      }
    })

    const data = response.payload.database_result
    const temp: any = []
    if (data) {
      data.map((item: any) => {
        temp.push({
          id: item.id,
          recordDateTime: item?.recordDateTime ? `${moment(item.recordDateTime).utc().format('YYYY-MM-DD HH:mm:ss')} UTC` : '',
          openPositionsTotalProfit: item.openPositionsTotalProfit,
          totalProfitTradesReport: item.totalProfitTradesReport,
          spread: item.spread,
          swap: item.swap,
          commission: item.commission,
          markup: item.markup
        })
      })
    }

    //Reduce Data for openPositionsTotalProfits
    const openPositionsReduceData = temp.map(
      (ele: any) => {
        return {
          recordDateTime: ele.recordDateTime,
          openPositionsTotalProfit: ele.openPositionsTotalProfit
        }
      }
    )

    //Reduce Data for totalProfitTradesReport
    const totalProfitTradesReportReduceData = temp.map(
      (ele: any) => {
        return {
          recordDateTime: ele.recordDateTime,
          totalProfitTradesReport: ele.totalProfitTradesReport
        }
      }
    )

    setOpenPositionsTotalProfitChart(openPositionsReduceData)
    setTotalProfitTradesReportChart(totalProfitTradesReportReduceData)

    setLoading(false)
    setState({
      ...state,
      data: temp,
      startDate: start.toDate(),
      endDate: end.toDate(),
      dateSelected: true
    })
  }

  const openPositionsTotalProfitChartConfig = {
    data: openPositionsTotalProfitChartData,
    height: 200,
    xField: 'recordDateTime',
    yField: 'openPositionsTotalProfit',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {style: {fill: '#aaa',},},
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' }
    },
  }

  const totalProfitTradesReportChartConfig = {
    data: totalProfitTradesReportChartData,
    height: 200,
    xField: 'recordDateTime',
    yField: 'totalProfitTradesReport',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {style: {fill: '#aaa',},},
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' }
    },
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Date Time',
      dataIndex: 'recordDateTime',
    },
    {
      title: 'Open Positions Total Profit',
      dataIndex: 'openPositionsTotalProfit'
    },
    {
      title: 'Total Profit Trades Report',
      dataIndex: 'totalProfitTradesReport'
    },
    {
      title: 'Spread',
      dataIndex: 'spread'
    },
    {
      title: 'Swap',
      dataIndex: 'swap'
    },
    {
      title: 'Commission',
      dataIndex: 'commission'
    },
    {
      title: 'Markup',
      dataIndex: 'markup'
    }
  ]

  const resetDatepicker = () => {
    setState({
      ...state,
      data: [],
      dateSelected: false
    })
  }

  const onChange = async (dates: any, dateStrings: Array<String>) => {
    if (dates){
      setLoading(true)
      await fetch(dates[0], dates[1])
    } else {
      setState({
        ...state,
        dateSelected: false
      })
    }
  }

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={5}>{
            state?.dateSelected
              ? `${moment(state.startDate).format('YYYY/MM/DD')} 00:00:00 UTC - ${moment(state.endDate).format('YYYY/MM/DD')} 00:00:00 UTC`
              : ''}</Typography.Title>
        </Col>
        <Col span={8} style={{textAlign: 'right'}}>
          {!state?.dateSelected ? <RangePicker onChange={onChange} /> : <Button type={'primary'} onClick={resetDatepicker}>Reset</Button>}
        </Col>
      </Row>
      <br />
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={4}>
            Open Positions Profit Position
          </Typography.Title>
        </Col>
      </Row>
      <br />
      <Row gutter={[0, 48]}>
        <Col span={24}>
          <Area {...openPositionsTotalProfitChartConfig} />
        </Col>
      </Row>
      <br />
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={4}>
            Closed Positions Profit (24hr period)
          </Typography.Title>
        </Col>
      </Row>
      <br />
      <Row gutter={[0, 48]}>
        <Col span={24}>
          <Area {...totalProfitTradesReportChartConfig} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            size="small"
            columns={columns}
            dataSource={state.data}
            bordered={true}
            title={() => {
              return <>
                <Row>
                  <Col span={16}>
                    <Typography.Title level={4}> Data Table </Typography.Title>
                  </Col>
                </Row>
              </>
            }
            }
            showHeader={true}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default HourlyPositions
