import * as types from './types'

/**
 * @param {number} payload
 * @returns {{payload: *, type: string}}
 */
export const fetchAgents = payload => ({
  type: types.FETCH_AGENTS_REQUEST,
  payload
})
