import * as types from './types'

/**
 * @param {number} currentAccountID
 * @returns {{id: *, type: string}}
 */
export const getAccountList = currentAccountID => ({
  type: types.GET_ACCOUNT_LIST_REQUEST,
  currentAccountID
})
/**
 * @param {number} id
 * @returns {{id: *, type: string}}
 */
export const getAccountRecord = id => ({
  type: types.GET_ACCOUNT_RECORD_REQUEST,
  id
})
/**
 * @param {Object} body
 * @returns {{id: *, type: string}}
 */
export const updateAccountRecord = body => ({
  type: types.UPDATE_ACCOUNT_RECORD_REQUEST,
  body
})

/**
 * @param {email} staff
 * @param {Function} history
 * @returns {{id: *, type: string}}
 */
export const createNewAccount = (staff, history) => ({
  type: types.CREATE_NEW_ACCOUNT_REQUEST,
  payload: {
    staff,
    history
  }
})