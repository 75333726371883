import React, {Component} from 'react'
import {Table} from 'antd'

import {NavLink} from 'react-router-dom'

import {API} from 'aws-amplify'
import {EditOutlined} from '@ant-design/icons'

const columns = [
  {
    title: 'User ID',
    dataIndex: 'id',
  },
  {
    title: 'Authentication ID',
    dataIndex: 'aws_cognito_id'
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: id =>
      <NavLink to={`/clients/portalUsers/view/${id}`}>{<EditOutlined/>}</NavLink>
  }
]

class UserClientListPageComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {dataSource: []}
  }

  componentDidMount() {
    API.get('user-client', '/list')
      .then(response => {

        const dataSource = []
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            aws_cognito_id: response.payload[i].aws_cognito_id,
            email: response.payload[i].email
          })
        }
        this.setState({dataSource: dataSource})
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.state.dataSource}
        />
      </React.Fragment>
    )
  }
}

export default UserClientListPageComponent
