
const {
  REACT_APP_BUCKET: BUCKET,
  REACT_APP_DOCW_TEMPLATES_BUCKET: DOCW_TEMPLATES_BUCKET,
  REACT_APP_DOCW_DOCUMENTS_BUCKET: DOCW_DOCUMENTS_BUCKET,
  REACT_APP_APPLICANT_IMAGES_BUCKET: APPLICANT_IMAGES_BUCKET,
  REACT_APP_EAF_PUBLIC_API: EAF_PUBLIC_API,
  REACT_APP_STAGE: STAGE
} = process.env as Record<string, string>

const PROD = STAGE === 'prod'

export {
  BUCKET,
  DOCW_DOCUMENTS_BUCKET,
  DOCW_TEMPLATES_BUCKET,
  APPLICANT_IMAGES_BUCKET,
  EAF_PUBLIC_API,
  PROD
}
