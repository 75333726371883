import React, {
  useState,
  useEffect
} from 'react'
import {
  useSelector,
  useHistory
} from 'hooks'

import {
  Card,
  Table,
  Button,
  Popconfirm,
  Divider,
  EyeOutlined,
  DeleteOutlined
} from 'components'
import {API} from 'utils'
import {ClientAuthPersonsDrawerComponent} from './drawers/ClientAuthPersonsDrawerComponent'
import {ClientCreateNewAuthPersonComponent} from './ClientCreateNewAuthPerson'

const ClientAuthPersons = ({
  client_id,
  staffId,
}) => {
  const history = useHistory()

  const [openCreateNew, setOpenCreateNew] = useState(false)

  const [authPersons, setAuthPersons] = useState({
    loading: true,
    authPersons: [],
  })
  const {
    streetCodes, currentAccountID, countriesList
  } = useSelector(state => ({
    streetCodes: state.leads,
    currentAccountID: state.appState.currentAccountRecord.id,
    countriesList: state.appState.countriesList,
  }))

  useEffect (() => {
    getData(client_id)
  }, [client_id])

  const getData = async () => {
    if (client_id) {
      try {
        await API.get('clients', `/authpersons-by/${client_id}`).then(
          response => {
            setAuthPersons({
              loading: true,
              authPersons: response.payload
            })
          }
        )
      } catch (e) {
        console.log('error,', 'Failed to get authPersons list,', e.message)
      }
    }
  }

  const [state, setState] = useState({
    visible: false,
    authPersonsDrawerData: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      authPersonsDrawerData: record,
      visible: bool
    })
    getData()
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fileBy',
    },
    
    {
      title: 'Email',
      dataIndex: 'email',
    },
        
    {
      title: 'Telephone',
      dataIndex: 'telephone',
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return <React.Fragment>
          <a onClick={() => toggleDrawer(true, record)}> <EyeOutlined /> </a>
          <Divider type='vertical' />
          <Popconfirm
            title='Are you sure to delete this record?'
            onConfirm={() => deleteRecord(record)}
            okText='Yes'
            onCancel='No'
          >
            <DeleteOutlined style={{color: 'red'}} />
          </Popconfirm>
        </React.Fragment>
      }
    },
    
  ]

  const handle_create_new = () => {
    setOpenCreateNew(true)
  }

  const deleteRecord = async record => {
    if (record.id) {
      try {
        await API.del('clients',`/delete-clientsAuthPerson/${record.id}/${record.clientId}`)
        history.push(`/clients/view/${record.clientId}?tab=authPersons`)
        getData()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <React.Fragment>
      <Card
        style={{minHeight: 360}}
        title='Auth Persons'
        extra={
          <Button key='2' onClick={handle_create_new}>
            Create New
          </Button>
        }
      >
        <div style={{paddingTop: 24}}>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={authPersons.authPersons}
            scroll={{x: true}}
            pagination={{
              // total: authPersons.length,
              // showTotal: total => `Total ${total} records`,
              pageSizeOptions: ['20', '50', '100'],
              defaultPageSize: 20,
              showSizeChanger: true
            }}
          />
          <ClientCreateNewAuthPersonComponent
            toggleModal={setOpenCreateNew}
            clientId={client_id}
            currentAccountId={currentAccountID}
            staffId={staffId}
            isModalVisible={openCreateNew}
            getData={getData}
          />
            
          <ClientAuthPersonsDrawerComponent
            {...state}
            toggleModal={toggleDrawer}
            id={client_id}
            getData={getData}
            currentAccountID={currentAccountID}
            countriesList={countriesList}
            streetCodes={streetCodes}
          />

        </div>
      </Card>
    </React.Fragment>
  )
}

export default ClientAuthPersons

