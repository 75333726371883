import React, {
  useState,
  useEffect
} from 'react'
import {
  Form,
  Typography,
  Row,
  Col,
  Button,
  notification,
} from 'antd'

import {FormSelect} from 'common'
import {API} from 'aws-amplify'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

const ClientReferrals = ({clientRecord}) => {
  const [state, setState] = useState({
    clientsTradingCommissionDetailsStatusID: 0,
    clientsTradingCommissionDetails: {clientsTradingCommissionDetailsStatusID: 0},
    clientsAgents: [],
    agentID: clientRecord.agentID
  })

  const asyncFunction = async () => {
    try {
      const {payload: clientsTradingCommissionDetails} = await API.get('trading-commissions', `/client/id/${clientRecord.id}`)
      const {payload: clientsAgents} = await API.get('agents', `/list/${clientRecord.account_id}`)

      setState(prevState => ({
        ...prevState,
        clientsTradingCommissionDetails,
        clientsTradingCommissionDetailsStatusID: clientsTradingCommissionDetails
          ? clientsTradingCommissionDetails.clientsTradingCommissionDetailsStatusID
          : 0,
        clientsAgents
      }))
      
    } catch (error) {
      console.log('error', error)
    }
  }

  const onFinish = async values => {
    values['clientsTradingCommissionDetailsStatusID'] = state.clientsTradingCommissionDetailsStatusID
    values['agentID'] = state.agentID

    try {
      await API.put('trading-commissions', `/client/id/${clientRecord.id}`, {body: {statusID: state.clientsTradingCommissionDetailsStatusID}})
      await API.put('clients', `/simple-update/${clientRecord.id}`, {body: {agentID: state.agentID}})
      openNotificationWithIcon('success', 'Client', 'Successfully updated')
    } catch (error) {
      console.error('error occured', error)
      openNotificationWithIcon('error', 'Clients', error.message)
    }
  }

  const getStatus = (statusID) => {
    switch (statusID) {
      case 1:
        return '1 - Pending'
      case 2:
        return '2 - Pending'
      case 3:
        return '3 - Rejected'
      default:
        return '1 - Pending'
    }
  }

  const getAgentValue = (agentID) => {
    return `[${state.clientsAgents.filter((obj => obj.id === agentID))[0].id}] ${state.clientsAgents.filter((obj => obj.id === agentID))[0].fileBy}`
  }

  const handleSelectChange = (id, values) => {
    setState({
      ...state,
      clientsTradingCommissionDetailsStatusID: values.id
    })
  }

  const handleAgentSelectChange = (id, values) => {
    setState({
      ...state,
      agentID: values.id
    })
  }

  useEffect(() => {
    asyncFunction()
  }
  , [])

  if (state.clientsTradingCommissionDetailsStatusID === 0) {
    return null
  }

  return (
    <>
      <Typography.Title level={4}> Referrals </Typography.Title>
      <Row gutter={[12, 0]}>
        <Col xs={12}>
          <Form.Item name='clientsTradingCommissionDetailsStatusID'>
            <FormSelect
              allowClear
              label='Status'
              name='clientsTradingCommissionDetailsStatusID'
              placeholder='Select Status'
              optionValue='id'
              options={[{
                id: 1,
                text: 1,
                value: '1 - Pending'
              },{
                id: 2,
                text: 2,
                value: '2 - Approved'
              },{
                id: 3,
                text: 3,
                value: '3 - Rejected'
              },
              ]}
              defaultValue={getStatus(state.clientsTradingCommissionDetailsStatusID)}
              onChange={handleSelectChange}
              filterOption={(input, option) => {
                return String(option.value).toLowerCase().includes(input.toLowerCase())
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item name='agents'>
            <FormSelect
              label='Agents'
              name='agentID'
              placeholder='Select Agent'
              optionValue='id'
              options={state.clientsAgents ? state.clientsAgents.map(
                item => {
                  return {
                    id: item.id,
                    text: item.id,
                    value: item.fileBy,
                  }
                }
                
              ) : []}
              getOptionProps={({id, value}) => ({children: `[${id}] ${value}`})}
              onChange={handleAgentSelectChange}
              defaultValue={
                clientRecord?.agentID ? getAgentValue(clientRecord.agentID) : ''
              }
              filterOption={(input, option) => {
                return String(option.value).toLowerCase().includes(input.toLowerCase())
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Button type='primary' onClick={onFinish}>
        Save Record
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ClientReferrals
