import * as React from 'react'

import {
  Form,
  InputNumber,
  FormItemProps,
  InputNumberProps
} from 'antd'
import {FormattedMessage} from 'react-intl'

interface FormInputNumberProps extends Omit<FormItemProps, 'status'>, Pick<InputNumberProps, 'max' | 'min' | 'precision' | 'step'> {
  placeholder?: string;
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  notLessThan?: number;
}

const FormInputNumber: React.FC<FormInputNumberProps> = (
  {
    name,
    placeholder = '',
    rules = [],
    required,
    disabled,
    label,
    notLessThan,
    precision,
    min,
    max,
    step
  }
) => {
  return (
    <Form.Item
      label={label}
      name={name}
      normalize={value => value ? parseFloat(value) : null}
      rules={[
        {
          required,
          message: <FormattedMessage id='validation.required' />
        },
        {
          validator: async (_, value) => {
            if (notLessThan && (value && value < notLessThan)) {
              return Promise.reject(new Error(`Can't be less than ${notLessThan}!`))
            }
          },
        },
        ...rules
      ]}
    >
      <InputNumber
        style={{width: '100%'}}
        placeholder={placeholder}
        disabled={disabled}
        precision={precision}
        min={min}
        max={max}
        step={step}
      />
    </Form.Item>
  )
}
export default FormInputNumber