import * as React from 'react'
import {
  Button,
  Form,
  Modal,
  InputText,
  InputSelect,
  Row
} from 'components'

import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {
  API,
  showNotification
} from 'utils'
import {Types} from '../../duck'

interface CreateJournalProps {
  clientId: number;
  staffId: number;
  open: boolean,
  toggleModal: (open: boolean) => void;
  documentId?: number;
  documents: Types.ClientDocument[];
}

const CreateJournal: React.FC<CreateJournalProps> = ({
  clientId, open, toggleModal, documentId, staffId, documents
}) => {
  const [form] = Form.useForm()
  const [{editorState, loading}, setState] = React.useState({
    loading: false,
    editorState: EditorState.createEmpty()
  })

  return (
    <Modal
      title='Create Journal'
      open={open}
      footer={null}
      destroyOnClose
      onCancel={() => {
        toggleModal(false)
      }}
    >
      <Form
        layout='vertical'
        form={form}
        preserve={false}
        initialValues={{
          linkedDocumentId: documentId,
          journalTypeId: 1,
          title: '',
          memo: EditorState.createEmpty()
        }}
        onFinish={async (values) => {
          try {
            setState(prevState => ({
              ...prevState,
              loading: true
            }))
            await API.post('journals', '/create', {
              body: {
                ...values,
                staffId,
                clientId,
                memo: draftToHtml(convertToRaw(editorState.getCurrentContent()))
              }
            })

            showNotification('success', 'Journals', 'Successfully Created')

            setState(prevState => ({
              ...prevState,
              loading: false
            }))

            toggleModal(false)
          } catch (error: any) {
            setState(prevState => ({
              ...prevState,
              loading: false
            }))
            showNotification('error', 'Journals', error.message)
          }
        }}
      >
        <InputSelect
          required
          name='journalTypeId'
          label='common.journalType'
          options={[
            {
              value: 1,
              children: 'System Node'
            },
            {
              value: 2,
              children: 'Staff Node'
            },
            {
              value: 3,
              children: 'Client Node'
            },
          ]}
          getOptionProps={({value, children}) => ({
            value,
            children
          })}
        />
        <InputText
          required
          name='title'
          label='data_clients.journalTitle'
        />
        <InputSelect
          name='linkedDocumentId'
          label='common.linkDocuments'
          options={documents}
          getOptionProps={opt => ({
            value: opt.id,
            children: `${opt.id}, ${opt.document_type_fileby} ${opt.description}`
          })}
        />
        <Form.Item
          name='memo'
          label='Memo'
          rules={[
            {
              required: true,
              message: 'Memo is Required'
            }
          ]}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={(editor) => {
              console.log(editor)
              setState(prevState => ({
                ...prevState,
                editorState: editor
              }))
            }}
            editorStyle={{
              border: '1px solid #F1F1F1',
              borderRadius: '2px',
              paddingLeft: '10px',
              paddingRight: '10px',
              height: '250px'
            }}
            toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history']}}
          />
        </Form.Item>
        <Row justify='end'>
          <Button type='primary' htmlType='submit' loading={loading}>
            Create
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

export default CreateJournal