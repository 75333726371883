export const ACCOUNTS_START_NEW_ACCOUNT_CREATION = 'ACCOUNTS_START_NEW_ACCOUNT_CREATION'
export const ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION = 'ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION'

export function startNewAccountCreation() {
  return {type: ACCOUNTS_START_NEW_ACCOUNT_CREATION}
}

export function updateNewAccountCreation(key, value) {
  return {
    type: ACCOUNTS_UPDATE_NEW_ACCOUNT_CREATION,
    value: {
      key,
      value
    }
  }
}
