import React, {forwardRef} from 'react'

import {Select, Form} from 'antd'

const filterOption = (input, option) =>
  option.children ? option.children.toLowerCase().includes(input ? input.toLowerCase() : '') : false

const {Option} = Select

const InputSelect = forwardRef(
  (
    {
      options = [],
      placeholder,
      showArrow = true,
      allowClear = false,
      getOptionProps = option => option,
      optionValue = null,
      ...rest
    },
    ref
  ) => {
    return (
      <Select
        ref={ref}
        autoComplete='new-password'
        style={{width: '100%'}}
        placeholder={placeholder}
        allowClear={allowClear}
        showArrow={showArrow}
        showSearch
        optionFilterProp='children'
        filterOption={filterOption}
        {...rest}
      >
        {options.map(option => {
          const v = !optionValue ? option : option[optionValue]
          return <Option key={v} value={v} {...getOptionProps(option)} />
        })}
      </Select>
    )
  }
)

const FormSelect = forwardRef(({
  isFormItem = true, required = false, name, label, ...selectProps
}, ref) => {
  const input = <InputSelect {...selectProps} ref={ref} />
  if (!isFormItem) {
    return input
  }
  return (
    <Form.Item
      name={name}
      label={label}
      normalize={v => v || null} // prevent undefined
      rules={[
        {
          required,
          message: `Please enter ${label}`
        }
      ]}
    >
      {input}
    </Form.Item>
  )
})

export default FormSelect