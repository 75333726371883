import moment from 'moment'
import React, {
  useEffect,
  useState
} from 'react'
import Axios from 'axios'
import {
  Card,
  Table
} from 'antd'

import NumberFormat from 'react-number-format'


interface IProps {
  account_slug: string;
}
interface IState {
  data: Array<String>[];
  headers: string[];
  name: string;
}
const KEY: string = '999'

export const ProfitAndLossGraph: React.FC<IProps> = ({ account_slug }: IProps) => {
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: ''
  })
  useEffect(() => {
    fetch()
  }, [])

  const fetch = async (): Promise<any> => {
    const startOfMonth: Number = moment().startOf('month').utc().unix() * 1000
    const currDate: Number = moment().utc().unix() * 1000
    const response = await Axios.post('https://mario.pimwatech.com/ctin/profitloss', {
      key: KEY,
      account: account_slug, // ctin , gat ...
      startDate: startOfMonth,
      endDate: currDate
      // startDate: '1633050000000',      //commenting for testing
      // endDate: '1634173200000'
    })
    const data = response.data.te_response.data
    const temp: any = []
    if (data) {
      data.map((item: string[]) => {
        if (item){
          temp.push({
            name: item[0],
            value: item[1]
          })
        }
      })
    }

    setState({
      ...state,
      ...response.data.te_response,
      data: temp
    })
  }
  const columns = [
    {
      title: '',
      dataIndex: 'name'
    },
    {
      title: '',
      dataIndex: 'value',
      render: (text: number) =>
        <React.Fragment>
          <NumberFormat
            prefix={"$"}
            value={text}
            decimalScale={2}
            fixedDecimalScale
            displayType={"text"}
          />
        </React.Fragment>
    }
  ]

  return (
    <Card
      title='Profit & Loss (current month)'
      style={{ height: '100%' }}
    >
      <Table
        columns={columns}
        dataSource={state.data}
        bordered={true}
        size="small"
        showHeader={false}
      />
    </Card>
  )
}
