import React, {
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {
  tradingCommissionsClientsActions,
  tradingCommissionsClientsSelectors,
} from 'configs/ducks/trading-commissions/index'

import {appStateSelectors} from 'configs/ducks/appState'

import {Tabs} from 'antd'

import WalletSummary from './wallet_view/WalletSummary'
import WalletTransactions from './wallet_view/WalletTransactions'
import {API} from 'aws-amplify'

const {TabPane} = Tabs

const WalletViewPageComponent = (
  {
    getTopics,
    accountsList,
    match: {params: {walletID}}
  }
) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('trading-commissions', `/wallet/${walletID}`)
        getTopics(
          'View Wallet Details',
          response.payload.id
        )
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <Tabs defaultActiveKey='1'>
      <TabPane tab='Summary' key='1'>
        <WalletSummary id={walletID} accountsList={accountsList}/>
      </TabPane>
      <TabPane tab='Transactions' key='2'>
        <WalletTransactions id={walletID} />
      </TabPane>
    </Tabs>
  )
}

const {tradingCommissionsWalletsSelector} = tradingCommissionsClientsSelectors
const mapStateToProps = state => {
  const {accountsList} = appStateSelectors.stateSelector(state)
  return {
    accountsList,
    ...tradingCommissionsWalletsSelector(state)
  }
}

export default connect(mapStateToProps, {})(WalletViewPageComponent)