import * as React from 'react'
import {
  Form,
  Input,
  FormItemProps,
  InputRef
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'hooks'
import { XOR } from 'types'

interface InputPasswordProps
  extends Pick<FormItemProps, 'rules' | 'name' | 'noStyle'> {
  placeholder?: string;
  tooltip?: string;
  label?: string;
  className?: string;
  required?: boolean;
  autoFocus?: boolean;
  preserve?: boolean;
  innerRef?: React.MutableRefObject<InputRef | null>;
  dependencies?: string[];
  validate?: boolean;
}

interface NotFormItemProps extends InputPasswordProps {
  isFormItem: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const REG_EXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\][{}()?\\\-“!@#%&/,><’:;|_~`])\S{8,99}$/

const InputPassword: React.FC<XOR<InputPasswordProps, NotFormItemProps>> = ({
  name,
  placeholder = '',
  rules = [],
  required,
  label,
  innerRef,
  autoFocus,
  className,
  noStyle,
  tooltip,
  isFormItem = true,
  preserve,
  validate,
  ...props
}) => {
  const intl = useIntl()

  const input = (
    <Input.Password
      {...props}
      autoFocus={autoFocus}
      placeholder={
        placeholder ? intl.formatMessage({ id: placeholder }) : undefined
      }
      autoComplete="new-password"
      ref={innerRef}
    />
  )

  if (!isFormItem) {
    return input
  }

  return (
    <Form.Item
      label={
        label ? intl.formatMessage({
          id: label,
          defaultMessage: label
        }) : null
      }
      normalize={(value) => value.replace(/\s{2,}/g, ' ')}
      name={name}
      noStyle={noStyle}
      preserve={preserve}
      className={className}
      tooltip={tooltip && <FormattedMessage id={tooltip} />}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: 'validation.required' }),
        },
        {
          validator: (_, value) => {
            if (!validate) {
              return Promise.resolve()
            }

            if (value && !REG_EXP.test(value)) {
              return Promise.reject(
                <FormattedMessage id="validation.password" />
              )
            }

            return Promise.resolve()
          },
        },
        ...rules,
      ]}
    >
      {input}
    </Form.Item>
  )
}
export default InputPassword
