import * as React from 'react'
import {
  Form,
  FormItemProps,
  Input,
  InputProps,
  InputRef
} from 'antd'
import {useIntl} from 'react-intl'

interface InputEmailProps extends FormItemProps {
  required?: boolean;
  isDuplicate?: boolean;
  autoFocus?: boolean;
  inputProps?: InputProps;
  label?: string;
  innerRef?: React.MutableRefObject<InputRef | null>;
  placeholder?: string;
}

const InputEmail: React.FC<InputEmailProps> = ({
  required,
  label ,
  name ,
  isDuplicate,
  placeholder
}) => {
  const intl = useIntl()

  return (
    <Form.Item
      name={name}
      label={label && intl.formatMessage({id: label})}
      normalize={(value) => value.replace(/\s{2,}/g, ' ')}
      rules={[
        {
          required,
          message: intl.formatMessage({id: 'validation.required'})
        },
        {
          type: 'email',
          message: intl.formatMessage({id: 'validation.invalidEmail'})
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject('Email already exists')
              : Promise.resolve()
          },
        },
      ]}
    >
      <Input placeholder={placeholder && intl.formatMessage({id: placeholder})} />
    </Form.Item>
  )
}
export default InputEmail
