import { Record } from 'immutable'

export const DocumentRecord = Record({
  id: null,
  account_id: null,
  client_id: null,
  description: '',
  document_type_id: '',
  document_type_fileby: '',
  long_description: '',
  record_created: '',
  deliosDocId: '',
  documentsEmailsId: ''
})

export const FileListItemRecord = Record({
  file_id: '',
  contentType: '',
  url: ''
})

export const emailItemRecord = Record({
  toAddress: '',
  subject: '',
  sendDatetime: '',
  sendGridMessageId: '',
  emailContentId: ''
})