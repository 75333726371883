import React from 'react'
import moment from 'moment'

import {v1 as uuid} from 'uuid'

import {Storage} from 'aws-amplify'

import {
  Form,
  notification,
  Upload
} from 'antd'

import {InboxOutlined} from '@ant-design/icons'

const {Dragger} = Upload

const FileUpload = (
  {
    files,
    onUploadStart,
    onUploadSuccess,
    onUploadError,
    required = false,
    multiple = true,
    name = 'fileUpload',
    maxCount,

  }
) => {
  const showErrorNotification = description => {
    notification.error({
      message: 'Failed to upload',
      description
    })
  }
  const onFileUpload = async (
    {
      file,
      onSuccess,
      onProgress,
      onError
    }
  ) => {
    const format = file.type.split('/')[1]
    if (25 * (1024 * 1024) < file.size) {
      onError()
      showErrorNotification('Maximum file size should not be greater 20MB')
      return
    }
    if (!/(pdf|doc|docx|jpg|jpeg|png|eml|xlsx|txt|csv|plain|rfc822)$/g.test(format)) {
      onError()
      const allowedFormats = '.pdf, .doc, .docx, .jpg, .jpeg, .png, .eml, .xlsx, .txt, .csv'
      showErrorNotification(`The following types of format allowed only: ${allowedFormats}`)
      return
    }

    const s3_filename = `${moment.utc().format('YYYYMMDD-HHmmss')}-${uuid()}`

    Storage.put(s3_filename, file, {
      contentType: file.type,
      progressCallback(progress) {
        onUploadStart()
        onProgress(
          {percent: Math.round((progress.loaded / progress.total) * 100)},
          file
        )
      }
    })
      .then(res => {
        onSuccess(res)
        onUploadSuccess({
          id: res.key,
          type: file.type
        })
      })
      .catch(e => {
        onUploadError()
        showErrorNotification(e.message)
      })
  }
  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <Form.Item
      label='Document Upload'
      name={name}
      valuePropName='fileList'
      getValueFromEvent={normFile}
      rules={[{
        required: required && !files.length,
        message: 'Please upload your file here'
      }]}
    >
      <Dragger
        multiple={multiple}
        accept='.txt, .pdf, .doc, .docx, .jpg, .jpeg, .png, .eml, .xlsx, .csv, text/plain, message/*'
        showUploadList={{showRemoveIcon: false}}
        customRequest={onFileUpload}
        maxCount={maxCount}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        {!maxCount && <p className='ant-upload-hint'>Multiple files can be added to this Record.</p>}
      </Dragger>
    </Form.Item>
  )
}
FileUpload.defaultProps = {
  onUploadStart: () => null,
  onUploadSuccess: () => null,
  onUploadError: () => null,
  files: [],
}
export default FileUpload