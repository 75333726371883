import React, {
  useState,
  useEffect
} from 'react'
import {
  connect,
  useDispatch,
  useSelector
} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  Card,
  Skeleton,
  Table,
  Button,
  Divider,
  Popconfirm
} from 'antd'
import API from '@aws-amplify/api'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {createUseStyles} from 'react-jss'
import {
  EyeOutlined,
  DeleteOutlined,
  SyncOutlined
} from '@ant-design/icons'
import {ClientTrustsDrawerComponent} from './ClientTrustsDrawerComponent'
import {ClientTrustsBeneficariesDrawerComponent} from './ClientTrustsBeneficariesDrawerComponent'
import {ClientCreateNewAppointorComponent} from './ClientCreateNewAppointor'
import {ClientCreateNewBeneficiaryComponent} from './ClientCreateNewBeneficiary'

const useStyles = createUseStyles({
  flagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {marginLeft: '0.25rem'}
  }
})

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} leadsList
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const ClientTrusts = ({
  getColumnSearchProps,
  loading,
  client_id,
  clientId,
  currentAccountID,
  staffId,
  getAppointorsByClientId,
  getBeneficiariesByClientId,
  onPhoneInputChange,
  countriesList,
  ...rest
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [id, setId] = useState(null)
  const history = useHistory()
  const {streetCodes} = useSelector(state => state.leads)

  const [openCreateNewAppointors, setOpenCreateNewAppointors] = useState(false)
  const [openCreateNewBeneficiaries, setOpenCreateNewBeneficiaries] = useState(false)

  const [appointors, setAppointors] = useState({
    loading: true,
    appointors: []
  })

  const [beneficiaries, setBeneficiaries] = useState({
    loading: true,
    beneficiaries: []
  })

  useEffect(() => {
    getData(client_id)
  }, [client_id])

  const getData = async () => {
    // getAppointorsByClientId()
    if (client_id) {
      try {
        await API.get('client-trust', `/appointors-by/${client_id}`).then(response => {
          setAppointors({
            loading: true,
            appointors: response.payload
          })
        })
      } catch (e) {
        console.log('error,', 'Failed to get appointors list,', e.message)
      }
    }

    // getBeneficiariesByClientId()
    if (client_id) {
      try {
        await API.get('client-trust', `/beneficiaries-by/${client_id}`).then(response => {
          setBeneficiaries({
            loading: true,
            beneficiaries: response.payload
          })
        })
      } catch (e) {
        console.log('error,', 'Failed to get beneficiaries list,', e.message)
      }
    }
  }

  const [state, setState] = useState({
    visible: false,
    trustDrawerData: null,
    trustDrawerData_beneficiaries: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      trustDrawerData: record,
      visible: bool
    })
    getData()
  }

  const toggleDrawer_beneficiaries = (bool, record) => {
    setState({
      ...state,
      trustDrawerData_beneficiaries: record,
      visible_beneficiaries: bool
    })
    getData()
  }

  const appointors_columns = [
    {
      title: 'File By',
      dataIndex: 'fileBy',
      render: (text, record) => {
        if (!record?.firstName || !record?.lastName){
          return ''
        }
        return `${record.lastName}, ${record.firstName}`
      }
    },
    
    {
      title: 'Email',
      dataIndex: 'email'
    },

    {
      title: 'Telephone',
      dataIndex: 'telephoneMobile'
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <React.Fragment>
            <a onClick={() => toggleDrawer(true, record)}>
              {' '}
              <EyeOutlined />{' '}
            </a>
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteClientsAppointorRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </React.Fragment>
        )
      }
    }
  ]

  const beneficiaries_columns = [
    {
      title: 'Type',
      dataIndex: 'beneficiaryType',
      render: (text, record) => {
        if (record.beneficiaryType === '1'){
          return 'Individual'
        }
        if (record.beneficiaryType === '2'){
          return 'Company'
        }
      }
    },

    {
      title: 'File By',
      dataIndex: 'fileBy',
      render: (text, record) => {
        if (!record?.firstName || !record?.lastName){
          return ''
        }
        return `${record.lastName}, ${record.firstName}`
      }
    },

    {
      title: 'Individual',
      dataIndex: 'individualName'
    },

    {
      title: 'Company',
      dataIndex: 'companyName'
    },

    {
      title: 'Beneficially Held',
      dataIndex: 'beneficiallyOwned',
      render: text => {
        if (text) {
          return 'yes'
        } else if (!text) {
          return 'no'
        }

        return ''
      }
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <React.Fragment>
            <a onClick={() => toggleDrawer_beneficiaries(true, record)}>
              {' '}
              <EyeOutlined />{' '}
            </a>
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteClientsBeneficiaryRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </React.Fragment>
        )
      }
    }
  ]

  const handle_create_new_appointors = () => {
    setOpenCreateNewAppointors(true)
  }

  const handle_create_new_beneficiaries = () => {
    setOpenCreateNewBeneficiaries(true)
  }

  const deleteClientsAppointorRecord = async record => {
    if (record.id) {
      try {
        await API.del('clients',`/delete-clientsAppointor/${record.id}/${record.clientId}`)
        history.push(`/clients/view/${record.clientId}?tab=trusts`)
        getData()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const deleteClientsBeneficiaryRecord = async record => {
    if (record.id) {
      try {
        await API.del('clients',`/delete-clientsBeneficiary/${record.id}/${record.clientId}`)
        history.push(`/clients/view/${record.clientId}?tab=trusts`)
        getData()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <React.Fragment>
      <Card
        style={{minHeight: 360}}
        title='Trusts'
        extra={ state.deletedFlag !== 1 ? [
          <Button key='1' onClick={handle_create_new_appointors}>
            New Appointor
          </Button>,
          <Button key='2' onClick={handle_create_new_beneficiaries}>
            New Beneficiary
          </Button>,
          <Button onClick={() => getData(currentAccountID)} icon={<SyncOutlined />} />
        ] : []}
      >
        <div style={{paddingTop: 24}}>
          <Skeleton active loading={loading}>
            <Table
              rowKey='id'
              columns={appointors_columns}
              dataSource={appointors.appointors}
              loading={loading}
              scroll={{x: true}}
              pagination={{
                // total: appointors.length,
                // showTotal: total => `Total ${total} records`,
                pageSizeOptions: ['20', '50', '100'],
                defaultPageSize: 20,
                showSizeChanger: true
              }}
            />
            <Table
              rowKey='id'
              columns={beneficiaries_columns}
              dataSource={beneficiaries.beneficiaries}
              loading={loading}
              scroll={{x: true}}
              pagination={{
                // total: beneficiaries.length,
                // showTotal: total => `Total ${total} records`,
                pageSizeOptions: ['20', '50', '100'],
                defaultPageSize: 20,
                showSizeChanger: true
              }}
            />
            <ClientCreateNewAppointorComponent
              toggleModal={setOpenCreateNewAppointors}
              clientId={clientId}
              currentAccountId={currentAccountID}
              staffId={staffId}
              isModalVisible={openCreateNewAppointors}
              getData={getData}
              onPhoneInputChange={onPhoneInputChange}
              countriesList={countriesList}
              streetCodes={streetCodes}
            />
            <ClientCreateNewBeneficiaryComponent
              toggleModal={setOpenCreateNewBeneficiaries}
              clientId={clientId}
              currentAccountId={currentAccountID}
              staffId={staffId}
              isModalVisible={openCreateNewBeneficiaries}
              getData={getData}
              countriesList={countriesList}
              streetCodes={streetCodes}
            />
            {
              <ClientTrustsDrawerComponent
                {...state}
                toggleModal={toggleDrawer}
                id={clientId}
                getData={getData}
                countriesList={countriesList}
                streetCodes={streetCodes}
              />
            }
            {
              <ClientTrustsBeneficariesDrawerComponent
                {...state}
                toggleModal={toggleDrawer_beneficiaries}
                id={clientId}
                getData={getData}
                countriesList={countriesList}
                streetCodes={streetCodes}
              />
            }
          </Skeleton>
        </div>
      </Card>
    </React.Fragment>
  )
}

const {getAppointorsByClientId} = clientsActions
const {getBeneficiariesByClientId} = clientsActions

const mapStateToProps = state => {
  const {
    loading, appointors, beneficiaries, clientRecord
  } = clientsSelectors.clientsSelector(state)
  return {
    loading,
    appointors,
    beneficiaries,
    clientId: clientRecord.id
  }
}

export default connect(mapStateToProps, {
  getAppointorsByClientId,
  getBeneficiariesByClientId
})(ClientTrusts)
