import * as React from 'react'
import {InformationRequestJournal} from 'types'
import {
  Button,
  Col,
  ExtendedDescriptions,
  Drawer,
  Row,
  Skeleton,
  Space
} from 'components'
import {FULL_DATE_TIME_FORMAT} from 'consts'
import dayjs from 'dayjs'

interface JournalDrawerProps {
  currentJournal: InformationRequestJournal | null;
  onClose: () => void;
}

const JournalDrawer: React.FC<JournalDrawerProps> = ({currentJournal, onClose}) => {

  return (
    <Drawer
      title='Jounral Detail'
      width={720}
      onClose={onClose}
      open={Boolean(currentJournal)}
      mask={true}
      maskClosable={true}
    >
      <ExtendedDescriptions
        items={[
          {
            label: 'Client Information Request ID',
            translate: false,
            value: currentJournal?.clientInformationRequestID
          },
          {
            label: 'Created At',
            translate: false,
            value: currentJournal?.recordCreated && dayjs(currentJournal.recordCreated).format(FULL_DATE_TIME_FORMAT)
          },
          {
            label: 'Staff',
            translate: false,
            value: currentJournal?.staff
          },
          {
            label: 'Description',
            translate: false,
            value: currentJournal?.description
          },
          {
            label: 'Memo',
            translate: false,
            value: currentJournal?.memo
          },
        ]}
      />

    </Drawer>
  )
}

export default JournalDrawer
