import React, {useEffect} from 'react'

import {connect} from 'react-redux'
import {
  Table,
  Button,
  notification
} from 'antd'
import {SyncOutlined,} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'

import {listHOC} from 'common'
import {
  tradingCommissionsClientsActions,
  tradingCommissionsClientsSelectors,
} from 'configs/ducks/trading-commissions/index'
import {appStateSelectors} from 'configs/ducks/appState'
import moment from 'moment'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */

const TradingCommissionGeneralWalletTransactionsListPageComponent = ({
  getColumnSearchProps, entities, loading, fetchCb, currentAccountID, getTopics
}) => {
  const history = useHistory()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'tradingCommissionWalletTransactionsID',
      width: 70,
      sorter: (a, b) => a.tradingCommissionWalletTransactionsID - b.tradingCommissionWalletTransactionsID,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'tradingCommissionWalletTransactionsID',
        placeholder: 'ID',
        render: (highlighter, record) => (
          <a onClick={() => {
            history.push(`/trading-commission/wallets/transactions/id/${record.tradingCommissionWalletTransactionsID}`)
          }}>
            {highlighter}
          </a>
        )
      })
    },
    {
      title: 'Date',
      dataIndex: 'transactionDate',
      width: 70,
      render: (text, record) => {
        return text ? moment(text).format('DD/MM/YYYY') : ''
      }
    },
    {
      title: 'Wallet',
      dataIndex: 'tradingCommissionWalletsID',
      sorter: (a, b) => a.tradingCommissionWalletsID - b.tradingCommissionWalletsID,
      ...getColumnSearchProps({
        dataIndex: 'tradingCommissionWalletsID',
        placeholder: 'ID',
        render: (highlighter, record) => (
          <a onClick={() => {
            history.push(`/trading-commission/wallets/id/${record.tradingCommissionWalletsID}`)
          }}>
            {highlighter}
          </a>
        )
        
      })
    },
    {
      title: 'Client',
      dataIndex: 'file_by',
      ...getColumnSearchProps({
        dataIndex: 'file_by',
        placeholder: 'File By'
      })
    },
    {
      title: 'Currency',
      dataIndex: 'short_name',
      ...getColumnSearchProps({
        dataIndex: 'short_name',
        placeholder: 'Currency'
      })
    },
    {
      title: 'Memo',
      dataIndex: 'memo',

    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      width: 100,
      render: value => {
        return (
          <span>
            {value && value.toFixed(2)}
          </span>
        )
      }
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      width: 100,
      render: value => {
        return (
          <span>
            {value && value.toFixed(2)}
          </span>
        )
      }
    }
  ]

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      rowKey='tradingCommissionWalletsID'
      columns={columns}
      dataSource={entities}
      loading={loading}
      scroll={{x: true}}
      pagination={{
        total: entities.length,
        showTotal: total => `Total ${total} records`,
        pageSizeOptions: ['20', '50', '100'],
        defaultPageSize: 20,
        showSizeChanger: true
      }}
    />
  )
}

const {fetchGeneralWalletTransactions} = tradingCommissionsClientsActions
const {tradingCommissionsWalletsSelector} = tradingCommissionsClientsSelectors

const mapStateToProps = state => {
  const {entities, loading} = tradingCommissionsWalletsSelector(state)
  return {
    dataSource: entities,
    loading,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    ...tradingCommissionsWalletsSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: fetchGeneralWalletTransactions})(listHOC(TradingCommissionGeneralWalletTransactionsListPageComponent))