import React from 'react'

import {
  FormInputNumber,
  InputText,
  Form,
  Row,
  Col
} from 'components'
import {
  COMMON_COL_PROPS,
  ROW_GUTTER
} from 'consts'
import {CommonProps} from '../../types'

const LocationStep: React.FC<CommonProps> = ({children, initialValues}) => {
  return (
    <Form
      name='location'
      layout='vertical'
      initialValues={initialValues}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <FormInputNumber
            name='unitNumber'
            label='Unit Number'
          />
          <FormInputNumber
            name='buildingNumber'
            label='Building Number'
          />
          <InputText
            name='streetType'
            label='common.streetType'
          />
          <FormInputNumber
            name='postalCode'
            label='Postal Code'
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputText
            name='streetName'
            label='common.streetName'
          />
          <InputText
            name='suburb'
            label='common.suburb'
          />
          <InputText
            name='stateProvinceCode'
            label='common.stateProvince'
          />
        </Col>
      </Row>
      {children}
    </Form>
  )
}

export default LocationStep