import {APP_NAME} from 'consts'

export const moduleName = 'agents'

export const FETCH_AGENTS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_AGENTS_REQUEST`
export const FETCH_AGENTS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_AGENTS_SUCCESS`
export const FETCH_AGENTS_ERROR = `${APP_NAME}/${moduleName}/FETCH_AGENTS_ERROR`

export const GET_AGENT_REQUEST = `${APP_NAME}/${moduleName}/GET_AGENT_REQUEST`
export const GET_AGENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_AGENT_SUCCESS`
export const GET_AGENT_ERROR = `${APP_NAME}/${moduleName}/GET_AGENT_ERROR`

export const SAVE_AGENT_REQUEST = `${APP_NAME}/${moduleName}/SAVE_AGENT_REQUEST`
export const SAVE_AGENT_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_AGENT_SUCCESS`
export const SAVE_AGENT_ERROR = `${APP_NAME}/${moduleName}/SAVE_AGENT_ERROR`
