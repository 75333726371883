import React, {Component} from 'react'
import { API } from 'aws-amplify'

import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input
} from 'antd'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class AgentSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {is_saving: false}
  }

  async componentDidMount() {
    try {
      const agent = await this.getAgentRecord(this.props.id)
      this.setState({...agent.payload})
    } catch (e) {
      console.log(e)
    }
  }

  async getAgentRecord(agentID) {
    return API.get('agents', `/id/${agentID}`)
  }

  async saveAgentRecord(agent_record) {
    await API.patch('agents', `/id/${this.state.id}`, {body: agent_record})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onHandleChangeAccount = value => {
    console.log(value)
    this.setState({'accountID': value})
  }

  onHandleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id,
      fileBy,
      firstName,
      lastName,
      clientID
    } = this.state
    try {
      await this.saveAgentRecord({
        id,
        fileBy,
        firstName,
        lastName,
        clientID
      })
      this.setState({is_saving: false})
    } catch (e) {
      console.log(e)
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form layout='vertical' onSubmit={this.onHandleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label='ID'>
                <Input
                  id='id'
                  value={this.state.id || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='File By'>
                <Input
                  id='fileBy'
                  value={this.state.fileBy || ''}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='First Name'>
                <Input
                  id='firstName'
                  value={this.state.firstName || ''}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Last Name'>
                <Input
                  id='lastName'
                  value={this.state.lastName || ''}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Client ID'>
                <Input
                  id='clientID'
                  value={this.state.clientID || ''}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item>
                {this.state?.clientID && <><strong>Xeonda Links:</strong><br/>
                Client:
                  <a href={`https://admin.xeonda.com/clients/view/${this.state?.clientID}`} target='_blank' rel='noopener noreferrer'>
                    {this.state?.clientID}
                  </a><br/></>
                }
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit' onClick={this.onHandleSubmit}>
            Save
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

export default AgentSummary
