import * as React from 'react'

import {
  Divider,
  Form,
  Radio,
  Typography,
  Row,
  Col,
  Spin
} from 'antd'
import {InputSelect} from 'components'

import * as H from 'hooks'

import {showNotification} from 'utils'

import {FormattedMessage} from 'react-intl'
import ReactCountryFlag from 'react-country-flag'
import {
  ConfirmModal,
  ColumnsRenderer
} from './components'
import {ColumnsRendererProps} from './components/columns-renderer/types'

import _ from 'lodash'

import * as duck from './duck'

import {
  IdentificationDocumentCategory,
  POACategory,
  Account
} from 'types'

const {Title} = Typography

const IdentificationNew: React.FC = () => {
  const [form] = Form.useForm()
  const [state, setState] = React.useState<duck.Types.State>({
    identificationDocumentCategories: [],
    poaCategories: [],
    countries: [],
    clients: [],
    loading: false
  })
  const [modalState, setModalState] = React.useState({
    visible: false,
    loading: false
  })
  const account = H.useSelector(state => state.appState.currentAccountRecord as Account)

  React.useEffect(() => {
    setState(prev => ({
      ...prev,
      loading: true
    }))

    Promise.all([
      duck.operations.getCountries(),
      duck.operations.getPOACategories(),
      duck.operations.getClients(account.id),
    ])
      .then(([
        {payload: countries},
        {payload: poaCategories},
        {payload: clients},
      ]) => {
        setState(prev => ({
          ...prev,
          countries,
          clients,
          poaCategories
        }))
      })
      .finally(() => {
        setState(prev => ({
          ...prev,
          loading: false
        }))
      })
  }, [account])
  const prevVal = React.useRef<any>()

  const handleValuesChange = (changed: object) => {
    const [name, value] = Object.entries(changed)[0]

    switch (name) {
      case 'identification_document_category_id':
      case 'pofadd_type_id':
        if (prevVal.current && prevVal.current !== value) {
          const categoryItems = name === 'identification_document_category_id'
            ? state.identificationDocumentCategories
            : state.poaCategories

          const {
            categoryEntries,
            category: newCat
          } = duck.selectors.getCategory(
            categoryItems,
            value
          )
          const {category: prevCat} = duck.selectors.getCategory(
            categoryItems,
            prevVal.current
          )

          const resetArr: any[] = []

          categoryEntries.forEach(([key]) => {
            // Bad DB design
            // detail1_label: MRZ Line 1
            // detail1_label: Issue or State
            // The same keys have different values. Remove
            // Also we need to clear uploaded files if docCategoryRecord doesn't have upload(back/front))

            if ((prevCat && newCat) && prevCat[key as keyof duck.Types.Category] !== newCat[key as keyof duck.Types.Category]) {
              resetArr.push(duck.constants.API_NAMES[key as keyof duck.Types.ApiNames] || key)
            }
          })

          if (resetArr.length) {
            form.resetFields([...resetArr, 'pofadd_type_other'])
          }
        }
        prevVal.current = value
        break
      case 'document_issue_country_id':
        setState(prev => ({
          ...prev,
          loading: true
        }))
        duck.operations.getIdentificationDocumentCategories(value)
          .then(({payload: newCategories}) => {
            const categoryID = form.getFieldValue('identification_document_category_id')

            if (newCategories.every(({id}: any) => id !== categoryID)) {
              form.setFieldsValue({
                identification_document_category_id: null,
                file_id_front: [],
                file_id_back: [],
                pofadd_file_id: [],
              })
            }

            setState(prev => ({
              ...prev,
              identificationDocumentCategories: newCategories,
              loading: false
            }))
          })
        break
      case 'identificationType':
        [
          ...Object.values(duck.constants.API_NAMES),
          'document_number',
          'identification_document_category_id',
          'pofadd_type_id',
          'pofadd_type_other',
          'pofadd_file_id',
        ].forEach((field: string) => form.setFieldsValue({[field]: null}))
        break
      default:
        break
    }
  }

  const closeModal = () => setModalState({
    loading: false,
    visible: false
  })

  const openModal = () => setModalState({
    ...modalState,
    visible: true
  })

  const onCreateIdentificationRecord = async () => {
    const values = _.omit(form.getFieldsValue(), ['identificationType'])
    values.expiry = _.get(values, 'expiry.displayValue', null)
    const file_id_back = _.get(values, 'file_id_back.id', '')
    const file_id_front = _.get(values, 'file_id_front.id', '')
    const pofadd_file_id = _.get(values, 'pofadd_file_id.id', '')

    setModalState({
      ...modalState,
      loading: true
    })

    const files = []

    // push to files signatire {id, type}[]
    file_id_front && files.push(values.file_id_front)
    file_id_back && files.push(values.file_id_back)
    pofadd_file_id && files.push(values.pofadd_file_id)

    // change for saving as str into identificationRecords table
    values.file_id_back = _.get(values, 'file_id_back.id', '')
    values.file_id_front = _.get(values, 'file_id_front.id', '')
    values.pofadd_file_id = _.get(values, 'pofadd_file_id.id', '')

    try {
      const {payload: {document_id}} = await duck.operations.createDocuments(values.client_id, account.id, files)
      await duck.operations.createIdentificationRecord({
        ...values,
        document_id
      });

      [
        ...Object.values(duck.constants.API_NAMES),
        'document_number',
        'document_issue_country_id',
        'pofadd_type_other',
        'pofadd_file_id',
        'client_id',
      ].forEach(field => form.setFieldsValue({[field]: null}))

      closeModal()

      const idType = form.getFieldValue('identificationType') === 1 ? 'Identification' : 'POA'

      showNotification(
        'success',
        'Success',
        `${idType} record is created`
      )

    } catch (e: any) {
      const msg = e?.response?.data?.error || e.message
      showNotification(
        'error',
        'Failed to save',
        msg
      )
    }

    setModalState(prev => ({
      ...prev,
      loading: false
    }))
  }

  return (
    <Spin spinning={state.loading}>
      <Form
        form={form}
        initialValues={{
          identificationType: 1,
          file_id_front: [],
          file_id_back: [],
          pofadd_file_id: [],
        }}
        onValuesChange={handleValuesChange}
        layout='vertical'
      >
        <Divider />
        <Title level={4}>Identity Document</Title>
        <Title level={5}>Step 1 - Select Document</Title>
        <Row gutter={duck.constants.ROW_GUTTER}>
          <Col {...duck.constants.COMMON_COL_PROPS}>
            <Form.Item
              name='identificationType'
              label='Identification type'
            >
              <Radio.Group>
                <Radio value={1}>
                  Identification
                </Radio>
                <Radio value={2}>
                  Proof of Address
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col {...duck.constants.COMMON_COL_PROPS}>
            <Row gutter={duck.constants.ROW_GUTTER}>
              <Col span={12}>
                <InputSelect
                  required
                  label='common.client'
                  name='client_id'
                  getOptionProps={(option) => ({
                    value: option.id,
                    children: option.file_by
                  })}
                  options={state.clients}
                />
              </Col>
              <Col span={12}>
                <InputSelect
                  required
                  name='document_issue_country_id'
                  label='identification.issueCountry'
                  options={state.countries}
                  getOptionProps={({full_name, id}) => ({
                    children: full_name,
                    value: id
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={duck.constants.ROW_GUTTER}>
          <Col {...duck.constants.COMMON_COL_PROPS}>
            <Form.Item dependencies={['document_issue_country_id']}>
              {({getFieldValue}) => {
                const selectedCountry = getFieldValue('document_issue_country_id')

                if (selectedCountry) {
                  const {
                    iso_alpha_2,
                    full_name
                  } = state.countries.find(({id}) => id === selectedCountry) || {}
                  return (
                    <>
                      <FormattedMessage id="user_profile_verification_form.text_please_provide_details_for" /> &nbsp;
                      <ReactCountryFlag
                        className="emojiFlag"
                        countryCode={iso_alpha_2?.toLowerCase()}
                        style={{
                          fontSize: '1.5em',
                          lineHeight: '1.5em',
                        }}
                        aria-label={full_name}
                      />
                      &nbsp;
                      {full_name}
                    </>
                  )
                }
                return null
              }}
            </Form.Item>
          </Col>
          <Col {...duck.constants.COMMON_COL_PROPS}>
            <Form.Item shouldUpdate>
              {({getFieldsValue}) => {
                const {
                  identificationType,
                  document_issue_country_id
                } = getFieldsValue([
                  'identificationType',
                  'document_issue_country_id'
                ])

                if (!document_issue_country_id) {
                  return null
                }

                const {
                  name,
                  ...props
                } = identificationType === 1 ? {
                  name: 'identification_document_category_id',
                  options: state.identificationDocumentCategories,
                  getOptionProps: (option: IdentificationDocumentCategory) => ({
                    children: option.nickname,
                    value: option.id
                  })
                } : {
                  name: 'pofadd_type_id',
                  options: state.poaCategories,
                  getOptionProps: (option: POACategory) => ({
                    children: option.file_by,
                    value: option.id
                  })
                }

                return (
                  <InputSelect
                    required
                    preserve={false}
                    label='user_profile_verification_form.ddl_title_document'
                    name={name}
                    {...props}
                  />
                )
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item dependencies={['identificationType', 'pofadd_type_id', 'identification_document_category_id']}>
          {({getFieldsValue}) => {
            const {
              identificationType,
              pofadd_type_id ,
              identification_document_category_id
            } = getFieldsValue([
              'identificationType',
              'pofadd_type_id',
              'identification_document_category_id'
            ])

            if (identificationType === 1 ? !identification_document_category_id : !pofadd_type_id ) {
              return null
            }

            const props: {
              categoryName: 'identification_document_category_id' | 'pofadd_type_id';
              categoryItems: IdentificationDocumentCategory[] | POACategory[];
              renderInputFields: ColumnsRendererProps['renderInputFields'];
            } = identificationType === 1 ? {
              renderInputFields: (key, value, apiNames) => ({
                show: key.includes('label') && value,
                label: value,
                name: apiNames[key as keyof duck.Types.ApiNames]
              }),
              categoryName: 'identification_document_category_id',
              categoryItems: state.identificationDocumentCategories
            } : {
              renderInputFields: (key, value) => ({
                show: value === 99,
                label: 'Other',
                name: 'pofadd_type_other'
              }),
              categoryName: 'pofadd_type_id',
              categoryItems: state.poaCategories
            }
            return <>
              <Divider />
              <Title level={5}>Step 2 - Complete details</Title>
              <ColumnsRenderer
                identificationType={identificationType}
                onBtnClick={openModal}
                {...props}
              />
            </>
          }}
        </Form.Item>
        <ConfirmModal
          {...modalState}
          onCloseModal={closeModal}
          onOk={onCreateIdentificationRecord}
        />
      </Form>
    </Spin>
  )
}
/*
* {children(() => {
        if (!identificationRecords.length) {
          showNotification(
            'error',
            'Error',
            'Identification records are missing'
          )
          return true
        }

        return false
      })}
* */

export default IdentificationNew
