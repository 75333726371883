import React, {
  useEffect,
  useState
} from 'react'

import {Tabs} from 'antd'

import UserClientSummary from './user_client_view/UserClientSummary'
import UserClientClients from './user_client_view/UserClientClients'
import {API} from 'aws-amplify'

const {TabPane} = Tabs

const UserClientViewPageComponent = (
  {
    getTopics,
    match: {params: {userClient_id}}
  }
) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('user-client', `/id/${userClient_id}`)
        getTopics(
          'View User Client Details',
          response.payload.id
        )
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <Tabs defaultActiveKey='1'>
      <TabPane tab='Summary' key='1'>
        <UserClientSummary id={userClient_id} />
      </TabPane>
      <TabPane tab='Clients' key='2'>
        <UserClientClients id={userClient_id} />
      </TabPane>
    </Tabs>
  )
}

export default UserClientViewPageComponent
