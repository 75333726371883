import {APP_NAME} from 'consts'

export const moduleName = 'backoffice-accounts'

export const GET_ACCOUNT_RECORD_REQUEST = `${APP_NAME}/${moduleName}/GET_ACCOUNT_RECORD_REQUEST`
export const GET_ACCOUNT_RECORD_SUCCESS = `${APP_NAME}/${moduleName}/GET_ACCOUNT_RECORD_SUCCESS`
export const GET_ACCOUNT_RECORD_ERROR = `${APP_NAME}/${moduleName}/GET_ACCOUNT_RECORD_ERROR`

export const GET_ACCOUNT_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_ACCOUNT_LIST_REQUEST`
export const GET_ACCOUNT_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_ACCOUNT_LIST_SUCCESS`
export const GET_ACCOUNT_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_ACCOUNT_LIST_ERROR`

export const GET_ACCOUNT_TYPE_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_ACCOUNT_TYPE_LIST_REQUEST`
export const GET_ACCOUNT_TYPE_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_ACCOUNT_TYPE_LIST_SUCCESS`
export const GET_ACCOUNT_TYPE_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_ACCOUNT_TYPE_LIST_ERROR`

export const UPDATE_ACCOUNT_RECORD_REQUEST = `${APP_NAME}/${moduleName}/UPDATE_ACCOUNT_RECORD_REQUEST`
export const UPDATE_ACCOUNT_RECORD_SUCCESS = `${APP_NAME}/${moduleName}/UPDATE_ACCOUNT_RECORD_SUCCESS`
export const UPDATE_ACCOUNT_RECORD_ERROR = `${APP_NAME}/${moduleName}/UPDATE_ACCOUNT_RECORD_ERROR`

export const CREATE_NEW_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/CREATE_NEW_ACCOUNT_REQUEST`
export const CREATE_NEW_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_NEW_ACCOUNT_SUCCESS`
export const CREATE_NEW_ACCOUNT_ERROR = `${APP_NAME}/${moduleName}/CREATE_NEW_ACCOUNT_ERROR`