import { SelectProps } from 'antd'
import { IntlShape } from 'react-intl'
import { Option } from '../../types'

export const getPlaceholder = (
  placeholder: SelectProps['placeholder'],
  intl: IntlShape
) => {
  return placeholder && typeof placeholder === 'string'
    ? intl.formatMessage({
      id: placeholder,
      defaultMessage: ''
    })
    : placeholder
}

export const filterOption = (input: string, option: Option | undefined) => {
  if (!option) {
    return false
  }

  if (typeof option.children === 'string') {
    return option?.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
  }

  return option.children.props.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1
}
