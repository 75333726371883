import React, {
  useEffect,
  Fragment,
  useState
} from 'react'

import {
  Col,
  Row,
  Spin,
  Form,
  Button,
  Descriptions,
  Drawer,
  Space,
  Switch,
  Input,
  notification,
  Select
} from 'antd'
import moment from 'moment'
import { API } from 'aws-amplify'

const { Option } = Select

export const ClientTrustsDrawerComponent = ({
  visible,
  trustDrawerData,
  toggleModal,
  asyncFunction,
  getData,
  countriesList,
  streetCodes
}) => {
  const [form] = Form.useForm()
  const [state, setState] = useState({
    id: trustDrawerData?.id,
    firstName: trustDrawerData?.firstName,
    middleNames: trustDrawerData?.middleNames,
    lastName: trustDrawerData?.lastName,
    address: trustDrawerData?.address,
    dateOfBirth: trustDrawerData?.dateOfBirth,
    email: trustDrawerData?.email,
    fileBy: trustDrawerData?.fileBy,
    telephoneMobile: trustDrawerData?.telephoneMobile,
    individualId: trustDrawerData?.individualId,
    streetAddressUnitNumber: trustDrawerData?.streetAddressUnitNumber,
  })

  useEffect(() => {
    setState({
      ...state,
      individualId: trustDrawerData?.individualId,
    })

  }, [trustDrawerData])

  if (trustDrawerData == null) {
    return <div></div>
  }

  const onTextboxChange = (event, state) => {
    // console.log(event.target)
    // Have to use event persist to stable https://reactjs.org/docs/events.html#event-pooling
    event.persist()

    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value
      }
    })
  }
  const saveRecord = (state) => {
    delete state.enableEdited
    try {
      API.put('client-trust', `/update-appointors/${trustDrawerData?.clientId}/${trustDrawerData?.id}`, {
        body: {
          clientsAppointors: {
            id: trustDrawerData?.id,
            fileBy: `${state?.lastName ? state.lastName : trustDrawerData?.lastName}, ${state?.firstName ? state.firstName : trustDrawerData?.firstName}`,
            individualId: state?.individualId,
            email: state?.email
          },
          contactIndividuals: {
            fileBy: `${state?.lastName ? state.lastName : trustDrawerData?.lastName}, ${state?.firstName ? state.firstName : trustDrawerData?.firstName}`,
            firstName: state?.firstName,
            middleNames: state?.middleNames,
            lastName: state?.lastName,
            dateOfBirth: state?.dateOfBirth,
            telephoneMobile: state?.telephoneMobile,
            address: state?.address,
            streetAddressUnitNumber: state?.streetAddressUnitNumber,
            streetAddressStreetNumber: state?.streetAddressStreetNumber,
            streetAddressStreetName: state?.streetAddressStreetName,
            streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
            streetAddressSuburb: state?.streetAddressSuburb,
            streetAddressState: state?.streetAddressState,
            streetAddressPostcode: state?.streetAddressPostcode,
            streetAddressCountryId: state?.streetAddressCountryId
          }
        }
      })
      notification.success({message: 'Saved !'})
      getData()
    } catch (error) {
      console.error('error occured', error)
      notification.error({message: error.message})
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  return (
    <Fragment>
      <Drawer
        title='Client Trusts Detail'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!trustDrawerData.id}>
            <Form form={form} scrollToFirstError={true} layout='vertical'>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Form.Item label='Edit / View'>
                  <Switch
                    checkedChildren='Edit'
                    unCheckedChildren='View'
                    id='enableEdited'
                    checked={state.enableEdited == 1}
                    onChange={event => {
                      return setState({
                        ...state,
                        enableEdited: event
                      })
                    }}
                  />
                </Form.Item>
                <Col xs={24}>
                  {trustDrawerData.id && !state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='Firstname'>
                          <strong>{trustDrawerData.firstName}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Middlename'>
                          <strong>{trustDrawerData.middleNames}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Lastname'>
                          <strong>{trustDrawerData.lastName}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Date of Birth'>
                          <strong>{trustDrawerData?.dateOfBirth
                            ? moment(trustDrawerData?.dateOfBirth).format('YYYY/MM/DD')
                            : ''}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                          <strong>{trustDrawerData.email}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item label='Telephone'>
                          <strong>{trustDrawerData.telephoneMobile}</strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <p> Address </p><Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}>

                        <Descriptions.Item label='Unit Number'>
                          <strong>{trustDrawerData.streetAddressUnitNumber}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetNumber' key='Street Number' label='Street Number' shouldUpdate>
                          <strong>{trustDrawerData.streetAddressStreetNumber}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetName' key='Street name' label='Street name' shouldUpdate>
                          <strong>{trustDrawerData.streetAddressStreetName}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetTypeId' key='Street Type' label='Street Type' shouldUpdate>
                          <strong>{trustDrawerData?.streetAddressStreetTypeId &&
                            streetCodes.streetType.find((element) => {
                              return element.in === trustDrawerData.streetAddressStreetTypeId
                            })?.fileBy}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
                          <strong>{trustDrawerData.streetAddressSuburb}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressState' key='State' label='State' shouldUpdate>
                          <strong>{trustDrawerData.streetAddressState}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
                          <strong>{trustDrawerData.streetAddressPostcode}</strong>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>

                          <strong>{trustDrawerData?.streetAddressCountryId &&
                            countriesList.find((element) => {
                              return element.id === trustDrawerData.streetAddressCountryId
                            })?.full_name}</strong>

                        </Descriptions.Item>

                      </Descriptions>
                    </>
                  )}
                  {trustDrawerData.id && state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='ID'>
                          <Input id='id' disabled value={state?.id ? state.id : trustDrawerData.id} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='First Name'>
                          <Input id='firstName' value={state?.firstName ? state.firstName : trustDrawerData.firstName} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Middle Names'>
                          <Input id='middleNames' value={state?.middleNames ? state.middleNames : trustDrawerData.middleNames} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Last Name'>
                          <Input id='lastName' value={state?.lastName ? state.lastName : trustDrawerData.lastName} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Date of Birth'>
                          <Input id='dateOfBirth'
                            placeholder='YYYY-MM-DD'
                            defaultValue={trustDrawerData?.dateOfBirth
                              ? moment(trustDrawerData.dateOfBirth).format('YYYY-MM-DD')
                              : ''}
                            onChange={(event, state) => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                          <Input id='email' value={state?.email ? state.email : trustDrawerData.email} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Telephone'>
                          <Input id='telephoneMobile' value={state?.telephoneMobile ? state.telephoneMobile : trustDrawerData.telephoneMobile} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                      </Descriptions>
                      <p> Address </p>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}>

                        <Descriptions.Item label='Unit Number'>
                          <Input id='streetAddressUnitNumber' value={state?.streetAddressUnitNumber ? state.streetAddressUnitNumber : trustDrawerData.streetAddressUnitNumber} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetNumber' key='Street Number' label='Street Number' shouldUpdate>
                          <Input id='streetAddressStreetNumber' value={state?.streetAddressStreetNumber ? state.streetAddressStreetNumber : trustDrawerData.streetAddressStreetNumber} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressStreetName' key='Street name' label='Street name' shouldUpdate>
                          <Input id='streetAddressStreetName' value={state?.streetAddressStreetName ? state.streetAddressStreetName : trustDrawerData.streetAddressStreetName} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
  
                        <Descriptions.Item name='streetAddressStreetTypeId' key='Street Type' label='Street Type' shouldUpdate>
                          <Select
                            showSearch
                            value={state?.streetAddressStreetTypeId ? state.streetAddressStreetTypeId : trustDrawerData.streetAddressStreetTypeId}
                            showArrow
                            placeholder='Select Street'
                            optionFilterProp='children'
                            onChange={(value) => {
                              form.setFieldsValue({ 'streetAddressStreetTypeId': value })
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  'streetAddressStreetTypeId': value
                                }
                              })
                            } }
                          >
                            {
                              streetCodes.streetType &&
                          streetCodes.streetType.map(x => (
                            <Option key={x.id} value={x.id}>
                              {x.fileBy}
                            </Option>
                          ))
                            }
                          </Select>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
                          <Input id='streetAddressSuburb' value={state?.streetAddressSuburb ? state.streetAddressSuburb : trustDrawerData.streetAddressSuburb} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressState' key='State' label='State' shouldUpdate>
                          <Input id='streetAddressState' value={state?.streetAddressState ? state.streetAddressState : trustDrawerData.streetAddressState} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
         
                        <Descriptions.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
                          <Input id='streetAddressPostcode' value={state?.streetAddressPostcode ? state.streetAddressPostcode : trustDrawerData.streetAddressPostcode} onChange={(event) => onTextboxChange(event, state)}/>
                        </Descriptions.Item>
            
                        <Descriptions.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>
                          <Select
                            showSearch
                            value={state?.streetAddressCountryId ? state.streetAddressCountryId : trustDrawerData.streetAddressCountryId}
                            showArrow
                            placeholder='Select Country'
                            optionFilterProp='children'
                            onChange={(value) => {
                              form.setFieldsValue({ 'streetAddressCountryId': value })
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  'streetAddressCountryId': value
                                }
                              })
                            } }
                          >
                            {
                              countriesList.map(({ id, full_name }) => (
                                <Option key={id} value={id}>
                                  {full_name}
                                </Option>
                              ))
                            }
                          </Select>
                        </Descriptions.Item>
        
                      </Descriptions>

                      <Button onClick={ ()=> saveRecord(state)}> Save </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}