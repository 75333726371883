import * as types from './types'

/**
 * @returns {{type: string}}
 */
export const fetchClients = () => ({type: types.FETCH_CLIENTS_REQUEST})

export const deleteClient = payload => ({
  type: types.DELETE_CLIENT_REQUEST,
  payload
})

export const clientsTasksList = payload => ({
  type: types.GET_CLIENT_TASKS_LIST_REQUEST,
  payload
})

export const clientsTasksbyClientId = payload => ({
  type: types.GET_CLIENT_TASKS_BY_CLIENT_ID_REQUEST,
  payload
})

export const clientsTaskDetails = payload => ({
  type: types.GET_CLIENT_TASK_REQUEST,
  payload
})

export const fetchClientsClosures = payload => ({
  type: types.FETCH_CLIENTS_CLOSURE_REQUEST,
  payload
})
/**
 * @param {number} id
 * @returns {{payload: *, type: string}}
 */
export const getClientByID = id => ({
  type: types.GET_CLIENT_REQUEST,
  payload: id
})

export const getClientClosureByID = (id, closureRecordId) => ({
  type: types.GET_CLIENT_CLOSURE_REQUEST,
  payload: {
    id,
    closureRecordId
  }
})
export const createDigiDoc = document => ({
  type: types.DIGIDOC_CREATE_REQUEST,
  payload: document
})

export const getInformationRequestsByClientId = payload => ({
  type: types.GET_INFORMATIONREQUEST_BY_CLIENT_REQUEST,
  payload
})

export const getJournalByClientId = payload => ({
  type: types.GET_JOURNAL_BY_CLIENT_REQUEST,
  payload
})

export const getOfficeholdersByClientId = payload => ({
  type: types.GET_OFFICEHOLDER_BY_CLIENT_REQUEST,
  payload
})

export const getShareholdersByClientId = payload => ({
  type: types.GET_SHAREHOLDER_BY_CLIENT_REQUEST,
  payload
})

export const getAppointorByClientId = payload => ({
  type: types.GET_APPOINTOR_BY_CLIENT_REQUEST,
  payload
})

export const getBeneficiaryByClientId = payload => ({
  type: types.GET_BENEFICIARY_BY_CLIENT_REQUEST,
  payload
})

export const financialAccountAction = document => ({
  type: types.FINANCIAL_ACCOUNT_SEND_REQUEST,
  payload: document
})

export const updateFinancialAccountAccount = payload => ({
  type: types.FINANCIAL_ACCOUNT_UPDATE_SUCCESS,
  payload
})

export const sendDigiDocEmail = document => ({
  type: types.DIGIDOC_SEND_REQUEST,
  payload: document
})
/**
 * @param {Object} clientRecord
 * @returns {{payload: *, type: string}}
 */
export const saveClientRecord = clientRecord => ({
  type: types.SAVE_CLIENT_REQUEST,
  payload: clientRecord
})

/*
  Integrate react router with redux in a later story --------
 */
export const createClient = (newClient, accountID, history) => ({
  type: types.CREATE_CLIENT_REQUEST,
  payload: {
    newClient,
    accountID,
    history
  }
})

/**
 * @param {number} clientID
 * @returns {{payload: *, type: string}}
 */
export const getDocuments = clientID => ({
  type: types.GET_DOCUMENTS_REQUEST,
  payload: clientID
})
/**
 * @param {number} itemID
 * @returns {{payload: *, type: string}}
 */
export const getDocument = itemID => ({
  type: types.GET_DOCUMENT_REQUEST,
  payload: itemID
})
/**
 * @param {Object} payload
 * @returns {{payload: *, type: string}}
 */
export const createNewDocument = ({
  description,
  long_description,
  files,
  currentAccountID,
  document_type_id,
  client_id,
  successCb = () => null
}) => ({
  type: types.CREATE_NEW_DOCUMENT_REQUEST,
  payload: {
    description,
    long_description,
    files,
    currentAccountID,
    document_type_id,
    client_id,
    successCb
  }
})
/**
 * @param {string} email
 * @param {Function} cb
 * @returns {{payload: *, type: string}}
 */
export const checkClientsEmail = (email, cb) => ({
  type: types.CHECK_CLIENTS_EMAIL_REQUEST,
  payload: {
    email,
    cb
  }
})
/**
 * @param {number} clientID
 * @returns {{payload: *, type: string}}
 */
export const getVerificationList = clientID => ({
  type: types.GET_VERIFICATIONS_LIST_REQUEST,
  payload: clientID
})

/**
 * @param {number} accountID
 * @returns {{payload: *, type: string}}
 */
export const getSourceOfWealthSelectListByAccountId = accountID => ({
  type: types.GET_SOURCEOFWEALTH_BY_ACCOUNT_REQUEST,
  payload: accountID
})