import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {
  NavLink,
  useHistory
} from 'react-router-dom'
import {
  Table,
  Descriptions,
  Button
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import {listHOC} from 'common'
import {
  xRecordIdsActions,
  xRecordIdsSelectors
} from 'configs/ducks/xRecordIds'

import {appStateSelectors} from 'configs/ducks/appState'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
function XeondaRecordIdsListPageComponent({
  getColumnSearchProps,
  dataSource,
  loading,
  getTopics,
  fetchCb,
  backoffice
}) {
  const columns = [
    {
      title: 'Record ID',
      dataIndex: 'recordId',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Client',
      dataIndex: 'clientId',
      render: clientId => <NavLink to={`/clients/view/${clientId}?tab=summary`}>{clientId}</NavLink>
    },
  ]
  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  if (backoffice !== 1) {
    return <div> You don't have access to this page, please contact xxx for help.</div>
  }

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey='id'
      scroll={{x: true}}
    />
  )
}
const mapStateToProps = state => {
  const {currentUserRecord} = appStateSelectors.stateSelector(state)
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  const {
    loading,
    entities
  } = xRecordIdsSelectors.stateSelector(state)
  
  return {
    currentAccountID: currentAccountRecord.id,
    backoffice: currentUserRecord.backoffice,
    dataSource: entities,
    loading
  }
}

export default connect(
  mapStateToProps,
  {fetchCb: xRecordIdsActions.getXRecordIdsList}
)(listHOC(XeondaRecordIdsListPageComponent))
