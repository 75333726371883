import React, {Component} from 'react'
import {API} from 'aws-amplify'

import {
  Row,
  Col,
  Select,
  Button,
  notification,
  Form,
  Input
} from 'antd'
const {Option} = Select

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class NewTeamSinglePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teamName: '',
      accountID: ''
    }
  }
  validate_step_1 = event => {
    let validate1 = false
    let validate2 = false
    if (this.state.teamName.length > 0) {
      validate1 = true
    }

    if (this.state.accountID > 0) {
      validate2 = true
    }

    return validate1 && validate2
  };

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onHandleSelectChangeAccount = value => {
    this.setState({accountID: value})
  };

  onHandleSubmit = async event => {
    let validate = true
    validate = this.validate_step_1()

    if (validate) {
      await API.post('teams', '/create', {
        body: {
          accountID: this.state.accountID,
          teamName: this.state.teamName
        }
      })
        .then(database_result => {
          openNotificationWithIcon('success', 'Record Created', 'New Team has been successfully created.')
          return database_result
        })
        .then(database_result => {
          this.props.history.push('/teams/list')
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  render() {

    return (
      <Form layout='vertical'>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item
                  label='Team Name'
                >
                  <Input
                    id='teamName'
                    value={this.state.teamName}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label='Account'
                >
                  <Select
                    id='accountID'
                    showSearch
                    value={this.state.accountID}
                    placeholder='Select Account'
                    onChange={this.onHandleSelectChangeAccount}
                  >
                    {
                      this.props.accountsList.map(({ id, file_by }) => (
                        <Option key={id} value={id}>
                          {file_by}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[96, 24]}>
          <Col xs={24} style={{textAlign: 'right'}}>
            <Button type='primary' onClick={this.onHandleSubmit}>
              Save Record
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default NewTeamSinglePage
