import React, {Component} from 'react'
import {connect} from 'react-redux'
import {API} from 'aws-amplify'

import {appStateSelectors} from 'configs/ducks/appState'

import {injectIntl} from 'react-intl'
import withStyles from 'react-jss'

import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input,
  Typography
} from 'antd'
import PhoneInput from 'react-phone-input-2'

import { PREFERRED_COUNTRIES } from 'consts'

const {Title} = Typography

const styles = theme => ({
  form_label: {fontWeight: theme.label_font_weight},
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color
  }
})
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class UserContact extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const user = await this.getUserProfile(this.props.currentUserID)
    console.log(user)
    this.setState({...user.payload})
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.currentUserID !== nextProps.currentUserID) {
      const user = await this.getUserProfile(nextProps.currentUserID)
      console.log(user)
      this.setState({...user.payload})
    }
  }

  async getUserProfile(user_id) {
    return API.get('staff', `/get/id/${user_id}`)
  }

  async saveUserProfile(user) {
    await API.put('staff', `/update/${this.state.id}`, {body: user})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value})
    //this.props.updateNewClientCreation(event.target.id, event.target.value);
  };

  onPhoneInputChange = id => value => {
    this.setState({[id]: value})
  };

  handleSubmit = async event => {
    event.preventDefault()
    // console.log(event)
    this.setState({isLoading: true})

    const {
      // aws_cognito_id,
      // first_name,
      // middle_name,
      // last_name,
      // email,
      tel_work,
      tel_home,
      tel_mobile,
      email_secondary
    } = this.state
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        // first_name: first_name,
        // middle_name: middle_name,
        // last_name: last_name,
        tel_work,
        tel_home,
        tel_mobile,
        email_secondary
      })
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveUserProfile({
          // aws_cognito_id: aws_cognito_id,
          //first_name: first_name,
          // middle_name: middle_name,
          //last_name: last_name,
          tel_work,
          tel_home,
          tel_mobile,
          email_secondary
        })
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form layout='vertical'>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Contact Details</Title>
              <Form.Item label='Telephone (home)'>
                <PhoneInput
                  inputStyle={{width: '100%'}}
                  preferredCountries={PREFERRED_COUNTRIES}
                  masks={{au: '(.) ....-....'}}
                  enableSearch
                  country={'us'}
                  disableCountryGuess={false}
                  onChange={this.onPhoneInputChange('tel_home')}
                />
              </Form.Item>
              <Form.Item label='Telephone (work)'>
                <PhoneInput
                  inputStyle={{width: '100%'}}
                  preferredCountries={PREFERRED_COUNTRIES}
                  masks={{au: '(.) ....-....'}}
                  enableSearch
                  country={'us'}
                  disableCountryGuess={false}
                  onChange={this.onPhoneInputChange('tel_work')}
                />
              </Form.Item>
              <Form.Item label='Telephone (mobile)'>
                <PhoneInput
                  inputStyle={{width: '100%'}}
                  preferredCountries={PREFERRED_COUNTRIES}
                  masks={{au: '(.) ....-....'}}
                  enableSearch
                  country={'us'}
                  disableCountryGuess={false}
                  onChange={this.onPhoneInputChange('tel_mobile')}
                />
              </Form.Item>
              <Form.Item label='Primary Email'>
                <Input
                  id='email'
                  value={this.state.email}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Secondary Email'>
                <Input
                  id='email_secondary'
                  value={this.state.email_secondary}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={24} style={{textAlign: 'right'}}>
              <Button type='primary' htmlType='submit' onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {currentUserID: appStateSelectors.stateSelector(state).currentUserRecord.id}
}

const UserContactContainer = connect(mapStateToProps)(withStyles(styles, {injectTheme: true})(injectIntl(UserContact)))

export default UserContactContainer
