import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {
  Table,
  Tag,
  Descriptions,
  Button
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import {
  listHOC,
  inputTypes
} from 'common'
import {
  staffsActions,
  staffsSelectors
} from 'configs/ducks/staffs'
import {appStateSelectors} from 'configs/ducks/appState'

import {getStatusOptions} from '../common'

const statuses = {
  1: {
    color: 'purple',
    text: 'DRAFT'
  },
  2: {
    color: 'blue',
    text: 'PENDING'
  },
  3: {
    color: 'orange',
    text: 'REGISTERED'
  },
  4: {
    color: 'green',
    text: 'ACTIVE'
  },
  5: {
    color: 'red',
    text: 'DELETED'
  },
}
const statusFilters = getStatusOptions(statuses)
/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
function StaffListPageComponent({
  getColumnSearchProps,
  dataSource,
  loading,
  getTopics,
  fetchCb,
  currentAccountID
}) {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: id => <NavLink to={`/staff/view/${id}`}>{id}</NavLink>
    },
    {
      title: 'Status',
      dataIndex: 'staff_status',
      ...getColumnSearchProps({
        dataIndex: 'staff_status',
        placeholder: 'Status',
        filterInputType: inputTypes.SELECT,
        selectFilterOptions: statusFilters,
        getOptionProps: ({text, color}) => ({children: <Tag color={color}>{text}</Tag>}),
        optionValue: 'id',
        render: (_, {staff_status}) => {
          if (!staff_status) {
            return null
          }
          const {
            text,
            color
          } = statuses[staff_status]
          return (
            <Tag color={color} style={{textAlign: 'center'}}>
              {text}
            </Tag>
          )
        },
      })
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps({
        dataIndex: 'email',
        placeholder: 'Email',
      })
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      ...getColumnSearchProps({
        dataIndex: 'first_name',
        placeholder: 'First Name',
      })
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      ...getColumnSearchProps({
        dataIndex: 'last_name',
        placeholder: 'Last Name',
      })
    },
    {
      title: 'Deleted',
      dataIndex: 'deleted',
      defaultFilteredValue: ['0'],
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      onFilter: (value, record) => Boolean(record?.deleted) == value,
      render: (text, record) => {
        if (text === 1) {
          return <Tag color={'red'} style={{textAlign: 'center'}}>
            {'Deleted'}
          </Tag>
        }
        return ''
      }
    }
  ]
  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey='id'
      scroll={{x: true}}
      expandedRowRender={({
        tel_home,
        tel_work,
        tel_mobile
      }) =>
        <Descriptions
          bordered
          layout='vertical'
          size='small'
          column={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3
          }}
        >
          <Descriptions.Item label='Tel (home)'>{tel_home}</Descriptions.Item>
          <Descriptions.Item label='Tel (work)'>{tel_work}</Descriptions.Item>
          <Descriptions.Item label='Tel (mobile)'>{tel_mobile}</Descriptions.Item>
        </Descriptions>
      }
    />
  )
}
const mapStateToProps = state => {
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  const {
    loading,
    entities
  } = staffsSelectors.stateSelector(state)
  return {
    currentAccountID: currentAccountRecord.id,
    dataSource: entities,
    loading
  }
}

export default connect(
  mapStateToProps,
  {fetchCb: staffsActions.getStaffList}
)(listHOC(StaffListPageComponent))
