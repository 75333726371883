import React, {Component} from 'react'

import {Auth} from 'utils'
import {connect} from 'react-redux'
import {appStateDuck} from 'configs'

import {
  Form,
  Input,
  Card,
  Button,
  message
} from 'antd'

import {ResetPasswordModal} from './components'

import {InputEmail} from 'common'

import {
  FormattedMessage,
  injectIntl
} from 'react-intl'

export const warning = text => {
  message.warning(text, 10)
}

export const info = text => {
  message.info(text, 10)
}

class LoginPageComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      isModalOpen: false,
    }
  }

    onValuesChange = changed => {
      const [key, value] = Object.entries(changed)[0]
      this.setState({[key]: value})
    }

    onForgotPassword = () => {
      Auth.forgotPassword(this.state.email.toLowerCase())
        .then(data => {
          info(`Please check your email ${data.CodeDeliveryDetails.Destination} for a verification number`)
          this.setState({isModalOpen: true})
        })
        .catch(({message}) => {
          if (message === 'Username cannot be empty') {
            message = 'You must enter an email address'
          }
          warning(message)
        })
    };

    toggleModalOpen = () => {
      this.setState({isModalOpen: !this.state.isModalOpen})
    };

    onFinish = async () => {
      this.props.login(this.state.email, this.state.password)
    };
    render() {
      const {
        state: {isModalOpen,},
        toggleModalOpen,
        onFinish,
        onForgotPassword,
        onValuesChange
      } = this
      return (
        <>
          <Card
            title={<FormattedMessage id='login_page.form_title' />}
            style={{maxWidth: 360,}}
          >
            <Form
              style={{marginTop: 20}}
              onFinish={onFinish}
              onValuesChange={onValuesChange}
            >
              <InputEmail
                required
                label={null}
                placeholder={this.props.intl.formatMessage({
                  id: 'login_page.email_label',
                  defaultMessage: 'Email'
                })}
              />
              <Form.Item
                hasFeedback
                name='password'
                rules={[{
                  required: true,
                  message: 'Password is required'
                }]}
              >
                <Input.Password
                  placeholder={this.props.intl.formatMessage({
                    id: 'login_page.password_label',
                    defaultMessage: 'Password'
                  })}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={this.props.loading}
                  style={{
                    width: '100%',
                    marginBottom: 10
                  }}
                >
                  <FormattedMessage id='login_page.login_button' />
                </Button>
                <a onClick={onForgotPassword}>
                  <FormattedMessage id='login_page.forget_password' />
                </a>
              </Form.Item>
            </Form>
          </Card>
          <ResetPasswordModal
            visible={isModalOpen}
            onModalCancel={toggleModalOpen}
            email={this.state.email}
          />
        </>
      )
    }
}

export default connect((state => ({loading: state.appState.loading,})), {login: appStateDuck.appStateActions.login})(injectIntl(LoginPageComponent))
