import {useAsyncFn} from 'react-use'
import {API} from 'aws-amplify'
import { notification } from 'antd'

export default function () {
  return useAsyncFn(async (params) => {
    try {
      const {payload} = await API.post('account-financial-accounts', '/transaction', {body: params})
      notification.success({message: 'Saved !'})
      return payload
    } catch (e) {
      notification.error({message: e.message})
    }
  }, [])
}
