import React, {Component} from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'

import {
  Row,
  Col,
  Select,
  Button,
  notification,
  Form,
  Input,
  Switch
} from 'antd'

const {Option} = Select

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class CollectionsSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {is_saving: false}
  }

  async componentDidMount() {
    try {
      const checklistCollection = await this.getCollectionRecord(this.props.id)
      console.log('checklistCollection.payload', checklistCollection.payload)
      this.setState({...checklistCollection.payload})
    } catch (e) {
      console.log(e)
    }
  }

  async getCollectionRecord(collectionID) {
    return API.get('doc-check', `/collections/id/${collectionID}`)
  }

  async saveCollectionRecord(collection_record) {
    await API.put('doc-check', `/collections/id/${this.state.id}`, {body: collection_record})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onHandleChangeAccount = value => {
    console.log(value)
    this.setState({'accountID': value})
  }

  onHandleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id, recordCreated, accountID, title
    } = this.state
    try {
      await this.saveCollectionRecord({
        id,
        recordCreated,
        accountID,
        title
      })
      this.setState({is_saving: false})
    } catch (e) {
      console.log(e)
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form layout='vertical' onSubmit={this.onHandleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label='ID'>
                <Input
                  id='id'
                  value={this.state.id || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Created'>
                <Input
                  id='recordCreated'
                  value={
                    this.state?.recordCreated
                      ? moment(this.state.recordCreated).format('YYYY/MM/DD HH:mm:ss')
                      : ''
                  }
                  onChange={event => {
                    this.handleChange(event)
                  }}
                  disabled
                />
              </Form.Item>
              <Form.Item label='Account'>
                {/*<Input
                  id='account'
                  value={this.state.accountID || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />*/}
                <Select
                  onChange={this.onHandleChangeAccount}
                  defaultValue={this.state.accountID || ''}
                  value={this.state.accountID || ''}
                  disabled
                >
                  {
                    this.props.accountsList.map(({ id, file_by }) => (
                      <Option key={id} value={id}>
                        {file_by}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item label='Title'>
                <Input
                  id='title'
                  value={this.state.title || ''}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit' onClick={this.onHandleSubmit}>
            Save
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

export default CollectionsSummary
