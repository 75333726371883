import {notification} from 'antd'

import {OpenNotification} from './types'

const showNotificationWithIcon: OpenNotification = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  })
}

export default showNotificationWithIcon
