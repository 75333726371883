import React, {
  useState,
  useRef,
  FC
} from 'react'
import {createUseStyles} from 'react-jss'
import {
  Form,
  Steps,
  StepsButtons
} from 'components'
import {listHOC} from 'hocs'
import {routes} from 'configs'
import * as LC from './components'
import {useQueryParams} from 'hooks'
import {API} from 'utils'
import moment from 'moment'
import {RouteComponentProps} from 'types'
import {CommonProps} from './types'

const useStyles = createUseStyles({
  steps: {
    overflowX: 'auto',
    marginBottom: 20
  },
  '@media screen and (max-width: 768px)': {steps: {overflow: 'hidden'}},
  space: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
})

const New: FC<RouteComponentProps> = ({history}) => {
  const classes = useStyles()
  const [state, setState] = useState({
    submitting: false,
    currentStep: 0
  })
  const [{clientID}] = useQueryParams()
  const stepValues = useRef<Record<string, any>>({clientId: clientID && parseInt(clientID)})

  const steps: {
    title: string;
    Component: React.FC<CommonProps>
  }[] = [
    {
      title: 'Client',
      Component: LC.ClientStep
    },
    {
      title: 'Personal Info',
      Component: LC.PersonalInfo
    },
    {
      title: 'Location',
      Component: LC.LocationStep
    },
    {
      title: 'Communication',
      Component: LC.CommunicationStep
    },
    {
      title: 'Verification',
      Component: LC.VerificationStep
    },
    {
      title: 'Confirm',
      Component: LC.LastStep
    }
  ]

  const {Component} = steps[state.currentStep]

  return (
    <Form.Provider
      onFormFinish={async (name, {values, forms}) => {
        stepValues.current = {
          ...stepValues.current,
          ...values,
        }

        if (name === 'lastStep') {
          try {
            setState(prevState => ({
              ...prevState,
              submitting: true
            }))

            const momentDate = moment.utc(stepValues.current.dateOfBirth)

            const resp = await API.post('verifications', '/trulioo/verify', {
              body: {
                ...stepValues.current,
                yearOfBirth: momentDate.format('YYYY'),
                monthOfBirth: momentDate.format('MM'),
                dayOfBirth: momentDate.format('DD'),
              }
            })

            setState(prevState => ({
              ...prevState,
              submitting: false
            }))

            history.push(
              routes.verificationsView(resp.verificationsId)
            )
          } catch (e) {
            setState(prevState => ({
              ...prevState,
              submitting: false
            }))
          }
        } else {
          setState(prevState => ({
            ...prevState,
            currentStep: prevState.currentStep + 1
          }))
        }
      }}
    >
      <Steps
        className={classes.steps}
        current={state.currentStep}
        size='small'
        labelPlacement='vertical'
      >
        {steps.map(item => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Component initialValues={stepValues.current}>
        <StepsButtons
          steps={steps}
          currentStep={state.currentStep}
          loading={state.submitting}
          onPrevClick={() => {
            setState(prevState => ({
              ...prevState,
              currentStep: prevState.currentStep - 1
            }))
          }}
        />
      </Component>
    </Form.Provider>
  )
}

export default listHOC(New)