import * as React from 'react'
import {
  Row,
  Col,
  Typography,
  Table,
  Spin,
} from 'antd'
import {API} from 'utils'
import {
  constants,
  selectors,
  Types
} from './duck'
const {Title} = Typography

// TODO Refactor

const View: React.FC<Types.ViewProps> = (props) => {
  const id = props.match.params.id
  const [state, setState] = React.useState<Types.State>({
    record: null,
    loading: false,
    documentRecords: [],
    documentFiles: []
  })

  React.useEffect(() => {
    setState(prev => ({
      ...prev,
      loading: true
    }))

    API.get('identification', `/getById/${id}`)
      .then(async ({payload}) => {
        const {identificationRecord} = payload

        if (identificationRecord) {
          const {payload: resp} = await API.get('documents', `/id/${identificationRecord.document_id}`)

          setState(prev => ({
            ...prev,
            record: identificationRecord,
            documentRecords: [resp.document_results],
            documentFiles: resp.file_results,
          }))
        }

      })
      .finally(() => {
        setState(prev => ({
          ...prev,
          loading: false
        }))
      })
  }, [])

  return (
    <Spin spinning={state.loading}>
      <Row gutter={[14, 14]}>
        <Col xs={24} md={12}>
          <Title level={4}>Identification info</Title>
          <Table
            dataSource={state.record ? [state.record] : []}
            columns={selectors.getRecordColumns(state.record)}
            pagination={false}
          />
        </Col>
        <Col xs={24} md={12}>
          <Title level={4}>Document File Ids</Title>
          <Table
            columns={constants.DOCUMENT_FILE_IDS_COLUMNS}
            dataSource={state.documentFiles}
            pagination={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={4}>Documents</Title>
          <Table
            columns={constants.DOCUMENTS_COLUMNS}
            dataSource={state.documentRecords}
            pagination={false}
          />
        </Col>
      </Row>
    </Spin>
  )
}

export default View
