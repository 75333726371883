import React from 'react'
import {
  Typography,
  Descriptions,
  Skeleton
} from 'antd'
import { FINANCIAL_ACCOUNT_TYPES } from 'consts'

const AccountDetails = ({ value, loading }) => {
  const {
    financial_account_type_id,
    financial_account_type,
    financial_account_name,
    bank_account_number,
    wallet_address,
    coin_type,
    currency
  } = value || {}
  return (
    <Skeleton loading={loading}>
      <div style={{ marginBottom: 40 }}>
        <Typography.Title level={4}>Account Details [{financial_account_type_id}]</Typography.Title>
        <Descriptions bordered layout="vertical">
          <Descriptions.Item label="ID">{financial_account_type_id}</Descriptions.Item>
          <Descriptions.Item label="Account Type">{financial_account_type}</Descriptions.Item>
          <Descriptions.Item label="Name">{financial_account_name}</Descriptions.Item>
          {
            financial_account_type_id === FINANCIAL_ACCOUNT_TYPES.BANK && (
              <>
                <Descriptions.Item label="Currency">{currency}</Descriptions.Item>
                <Descriptions.Item label="Account Number">{bank_account_number}</Descriptions.Item>
              </>
            )
          }
          {
            financial_account_type_id === FINANCIAL_ACCOUNT_TYPES.WALLET && (
              <>
                <Descriptions.Item label="Coin Type">{coin_type}</Descriptions.Item>
                <Descriptions.Item label="Wallet Address">{wallet_address}</Descriptions.Item>
              </>
            )
          }
        </Descriptions>
      </div>
    </Skeleton>
  )
}

export default React.memo(AccountDetails)