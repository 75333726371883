import {moduleName} from './types'

/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const clientsSelector = state => state[moduleName].entities.toJS()

/**
 * @param {Object} state
 * @returns {*}
 */
export const clientDocumentsSelector = state => state[moduleName].documents.toJS()
/**
 * @param {Object} state
 * @returns {*}
 */
export const newClientSelector = state => state[moduleName].newClient.toJS()
