import React, {
  useState,
  useEffect
} from 'react'

import {withRouter} from 'react-router-dom'

import {Tabs} from 'antd'
import UserSummary from './userprofile_view/UserSummary'
import UserContact from './userprofile_view/UserContact'
import UserAddress from './userprofile_view/UserAddress'

import queryString from 'query-string'

import {appStateSelectors} from 'configs/ducks/appState'
import {connect} from 'react-redux'

const {TabPane} = Tabs

const UserProfilePageComponent = (
  {
    location: {search},
    history,
    getTopics,
    currentStaffRecord
  }
) =>{
  const [activeTab, setActiveTab] = useState(queryString.parse(search))
  useEffect(() => {
    setActiveTab(search ? queryString.parse(search).tab : 'summary')
  }, [search])
  useEffect(() => {
    getTopics(
      'User Profile',
      `View and update details for ${currentStaffRecord.file_by || '...loading'}`
    )
  }, [currentStaffRecord.file_by])

  const onTabClick = e => {
    history.push(`/settings/user-profile?tab=${e}`)
  }
  return (
    <Tabs activeKey={activeTab} onTabClick={onTabClick}>
      <TabPane tab='User Summary' key='summary'>
        <UserSummary />
      </TabPane>
      <TabPane tab='Contact' key='contact'>
        <UserContact />
      </TabPane>
      <TabPane tab='Address' key='address'>
        <UserAddress />
      </TabPane>
    </Tabs>
  )
}

const mapStateToProps = state => {
  return {currentStaffRecord: appStateSelectors.stateSelector(state).currentStaffRecord}
}
export default connect(mapStateToProps)(UserProfilePageComponent)
