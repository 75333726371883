import React, {useState} from 'react'
import {useHistory} from 'hooks'
import {
  Button,
  Input,
  ExtendedDescriptions
} from 'components'
import {
  showNotification,
  API
} from 'utils'
import {ClientInformationRequest} from 'types'

interface ManagementProps {
  clientID: number | string;
  pincode?: string;
  informationRequestRecord: ClientInformationRequest;
  information_request_link: string;
}

const Management: React.FC<ManagementProps> = ({
  informationRequestRecord,
  information_request_link,
  clientID,
  pincode,
}) => {
  const history = useHistory()
  const [notes, setNotes] = useState(informationRequestRecord.adminNotes)
  const [notesLoading, setNotesLoading] = useState(false)
  const [sendEmailInformationRequestLoading, setLoadingEmailIrToClient] = useState(false)

  const onEmailInformationRequestClick = async () => {

    try {
      setLoadingEmailIrToClient(true)

      await API.post('email', '/client/eaf/ir-link', {
        body: {
          data: {
            clientId: clientID,
            eafIrLink: information_request_link,
            eafIrPin: pincode || '-',
            thirdParty: informationRequestRecord.thirdParty,
            recipientFirstName: informationRequestRecord.recipientFirstName,
            recipientEmail: informationRequestRecord.recipientEmail
          },
        }
      })

      await API.put('information-requests', `/update-information-request-status/${informationRequestRecord.id}`, {body: {'statusId': 2}})

      showNotification('success', 'Information Requests', 'Marked Sent')

      history.push('/clients/information-requests/list')
    } catch (e: any) {
      showNotification('error', 'Emails', e.message)
    }
    setTimeout(() => {
      setLoadingEmailIrToClient(false)
    }, 1000)
  }

  const onNotesSaveClick = async () => {
    try {
      await API.put('information-requests', `/update-information-request/${informationRequestRecord.id}`, {body: {'adminNotes': notes}})
      showNotification('success', 'Information Requests', 'Notes Saved')
    } catch (e: any) {
      showNotification('error', 'Information Requests', e.response?.data?.error || e.message)
    }
    setTimeout(() => {
      setNotesLoading(false)
    }, 1000)
  }

  return (
    <ExtendedDescriptions
      title='common.commands'
      items={[
        {
          label: 'Send Email',
          translate: false,
          copyable: false,
          value: (
            <>
              <Button
                onClick={onEmailInformationRequestClick}
                disabled={Number(informationRequestRecord.statusId) === 5}
                loading={sendEmailInformationRequestLoading}
              >

                Email Information Request
              </Button>
              {Number(informationRequestRecord.statusId) >= 2 &&
                  <p style={{color:'red'}}>Warning: Email has already been sent</p>
              }
              <p>Email will go to {!informationRequestRecord.thirdParty ? informationRequestRecord.clients_email : informationRequestRecord.recipientEmail}</p>
            </>
          )
        },
        {
          label: 'Notes',
          translate: false,
          copyable: false,
          value: (
            <>
              <Input.TextArea
                value={notes}
                autoSize={{
                  minRows: 5,
                  maxRows: 15
                }}
                onChange={event => {
                  setNotes(event.target.value)
                }}
              />
              <Button
                onClick={onNotesSaveClick}
                disabled={!notes}
                loading={notesLoading}
                style={{marginTop: 14}}
              >
                Save
              </Button>
            </>
          )
        }
      ]}
    />
  )
}
export default Management
