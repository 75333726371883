import {Record,} from 'immutable'

import * as types from './types'

import {CLEAR_STATE} from '../../constants'

import moment from 'moment'

const ReducerRecord = Record({
  entities: [],
  loading: false
})

export default (state = new ReducerRecord(), {
  type,
  payload
}) => {
  switch (type) {
    case types.FETCH_CHECKLISTCOLLECTIONS_REQUEST:
    case types.FETCH_CHECKLISTCOLLECTIONS_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: {results: payload}
        })
    case CLEAR_STATE:
      return state.clear()
    case types.FETCH_CHECKLISTCOLLECTIONS_ERROR:
    default:
      return state
  }
}