import React, {useEffect} from 'react'

import {
  Button,
  DatePicker,
  Table
} from 'antd'

import {listHOC} from 'common'

import {FileExcelOutlined} from '@ant-design/icons'
import {CSVLink} from 'react-csv'

import {connect} from 'react-redux'
import {
  tradingActions,
  tradingSelectors
} from 'configs/ducks/trading'
import moment from 'moment'

function TradingCommissionsFull(
  {
    getCommissionsFull,
    getTopics,
    getColumnSearchProps,
    list,
    loading
  }
) {
  useEffect(() => {
    const onChange = (momentDate) => {
      if (momentDate.length > 1) {
        const startDate = momentDate[0].startOf('day').add(momentDate[0].utcOffset(),'minutes')
        const endDate = momentDate[1].endOf('day').add(momentDate[1].utcOffset(),'minutes')
        startDate.utc()
        endDate.utc()
        getCommissionsFull(startDate.valueOf(), endDate.valueOf())
      }
    }
    getTopics({extra: <><DatePicker.RangePicker onChange={onChange} allowClear={false} /><p><em>Note: Date range is from 00:00 to 23:59 (UTC Time)</em></p></>})
  }, [])
  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      sorter: (a, b) => a.ID - b.ID,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'ID',
        placeholder: 'ID',
      })
    },
    {
      title: 'Account ID',
      dataIndex: 'accountID',
      sorter: (a, b) => a.accountID - b.accountID,
      ...getColumnSearchProps({
        dataIndex: 'accountID',
        placeholder: 'Account ID',
      })
    },
    {
      title: 'Date / Time',
      dataIndex: 'dateTime',
      sorter: (a, b) => a.ID - b.ID,
      ...getColumnSearchProps({
        dataIndex: 'dateTime',
        placeholder: 'Date / Time',
      })
    },
    {
      title: 'Instrument',
      dataIndex: 'instrument',
      sorter: (a, b) => a.ID - b.ID,
      ...getColumnSearchProps({
        dataIndex: 'instrument',
        placeholder: 'Instrument',
      })
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.ID - b.ID,
      ...getColumnSearchProps({
        dataIndex: 'amount',
        placeholder: 'Amount',
      })
    },
  ]

  return <Table
    rowKey='ID'
    columns={columns}
    dataSource={list}
    loading={loading}
    scroll={{x: true}}
    pagination={{
      total: list.length,
      showTotal: total => `Total ${total} records`,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      showSizeChanger: true
    }}
    footer={() => (
      <CSVLink
        filename={`trading_commission_full${moment().format('YYYYMMDD')}.csv`}
        data={list}
        className="btn btn-primary"
      >
        <Button disabled={list.length === 0 || loading}>
          Download <FileExcelOutlined />
        </Button>
      </CSVLink>
    )}
  />
}
const mapStateToProps = state => {
  const {
    loading,
    list
  } = tradingSelectors.commissionsFullSelector(state)
  return {
    loading,
    list
  }
}
const mapDispatchToProps = {getCommissionsFull: tradingActions.getCommissionsFull}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(listHOC(TradingCommissionsFull))