import React, {useState} from 'react'
import {routes} from 'configs'
import {listHOC} from 'hocs'

import {
  Form,
  Button,
  Card,
  Table,
  Modal,
  Tag,
  PlusOutlined,
  InputSelect,
  SubmitButton,
  NavLink,
} from 'components'
import {
  useSelector,
  useHistory
} from 'hooks'
import dayjs from 'dayjs'
import {UI_DATE_FORMAT, TIME_FORMAT} from 'consts'

import {
  showNotification,
  API
} from 'utils'

function TraderEvolution({
  entities, loading, getColumnSearchProps, clientRecord
}) {
  const history = useHistory()
  const [state, setState] = useState({
    modelOpen: false,
    submitting: false
  })

  const {tradingPlatformsList, currencies} = useSelector(state => ({
    currencies: state.appState.currencies,
    tradingPlatformsList: state.settings.tradingPlatformsList
  }))

  const onFinish = async values => {
    values = {
      ...values,
      client_id: clientRecord.id
    }
    try {
      setState(prevState => ({
        ...prevState,
        submitting: true
      }))

      const response = await API.post('trading-accounts', '/createAccount', {body:  values})

      setState(prevState => ({
        ...prevState,
        submitting: false
      }))

      history.push(`/trading/accounts/${response.payload.insertId}`)

      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error) {
      showNotification('error', 'Journals', error.message)
      setState(prevState => ({
        ...prevState,
        submitting: false
      }))
    }
  }

  const columns = [
    getColumnSearchProps({
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (text, record) => <NavLink to={routes.tradingAccountsView(record.id)}>{text}</NavLink>
    }),
    getColumnSearchProps({
      title: 'Platform',
      dataIndex: 'trading_platform_type_file_by',
      render: (text, record) => <span>{record.trading_platform_type_file_by}<br/>{record.trading_platform_mode_file_by}</span>
    }),
    getColumnSearchProps({
      title: 'Platform ID',
      dataIndex: 'tradingPlatformUsername',
    }),
    getColumnSearchProps({
      title: 'Created',
      dataIndex: 'recordCreated',
      render: (text, record) => {
        if (record.recordCreated) {
          const dayJsObject = dayjs(record.recordCreated)
          return (
            <>
              {dayJsObject.format(UI_DATE_FORMAT)}
              <br/>
              {dayJsObject.format(TIME_FORMAT)}
            </>
          )
        }
      }
    }),
    getColumnSearchProps({
      title: 'Account Status',
      dataIndex: 'accountLock',
      render: (_, record) => {
        const {text, color} = !record.accountLock ? {
          text: 'OK',
          color: 'green'
        } : {
          text: 'LOCK',
          color: 'red'
        }
        return (
          <Tag color={color}>
            {text}
          </Tag>
        )
      }
    }),
    getColumnSearchProps({
      title: 'Security Status',
      dataIndex: 'securityLock',
      render: (_, record) => {
        const {text, color} = !record.accountLock ? {
          text: 'OK',
          color: 'green'
        } : {
          text: 'LOCK',
          color: 'red'
        }
        return (
          <Tag color={color}>
            {text}
          </Tag>
        )
      }
    }),
  ]

  return (
    <React.Fragment>
      <Card
        style={{minHeight: 360}}
        title='Trader Evolution'
        extra={
          <Button
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                modelOpen: true
              }))
            }}
            icon={<PlusOutlined />}
            type='primary'
          />
        }
      >
        <Table
          rowKey='id'
          columns={columns}
          dataSource={entities}
          loading={loading}
          scroll={{x: true}}
        />
      </Card>
      <Modal
        title={'Create Trading Account'}
        open={state.modelOpen}
        onCancel={() => {
          setState(prevState => ({
            ...prevState,
            modelOpen: false
          }))
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          layout='vertical'
          onFinish={onFinish}
        >
          <InputSelect
            required
            label='tradingAccounts.title'
            name='trading_platform_account_id'
            options={tradingPlatformsList}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.fileBy
            })}
          />
          <InputSelect
            required
            label='common.currency'
            name='currency_id'
            options={currencies}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.iso_alpha_3
            })}
          />
          <SubmitButton loading={state.submitting} />
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default listHOC(TraderEvolution, ({clientRecord}) => API.get('trading-accounts', `/client/${clientRecord.id}`))