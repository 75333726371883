import {ClientCategory} from 'types'

export default {
  // login
  login: '/login',
  // dashboard
  dashboard: '/dashboard',

  // backoffice
  backoffice: '/backoffice',
  backofficeAccountsList: '/backoffice/accounts/list',
  backofficeXeondaIDsList: '/backoffice/xeonda-record-ids/list',
  backofficeFieldUpdate: '/backoffice/field-update',

  // organiser
  organiser: '/organiser',
  organiserTaskList: '/organiser/task-list',
  organiserMyTasks: '/organiser/my-tasks',

  // Client Documents
  clients: '/clients',
  clientsList: '/clients/list',
  clientsListWithParam: (categoryId: ClientCategory['id']) => {
    return `/clients/list?categoryId=${categoryId}`
  },
  clientsView: ({clientID, tab}: {clientID?: number; tab?: string}) => {
    const queryParam = tab ? `?tab=${tab}` : ''

    return `/clients/view/${clientID || ':client_id'}${queryParam}`
  },
  clientsLeads: '/clients/leads',
  clientsOpportunities: '/clients/opportunities',
  clientsNew: '/clients/new',
  clientsWatching: '/clients/watching',
  clientsFinancialAccounts: '/clients/financial-accounts',
  clientsPortalUsers: '/clients/portalUsers',
  clientsInformationRequests: '/clients/information-requests/list',
  clientsInformationRequestView: (clientID?: number, recordID?: number) => `/clients/${clientID || ':id'}/information-requests/${recordID || ':recordID'}`,
  clientsAccountClosures: '/clients/accountClosures/list',
  clientsNewBulk: '/clients/new-bulk',
  clientsNewCsv: '/clients/new-csv',
  clientDocWList: (clientID?: number) => `/clients/view/${clientID || ':id'}?tab=docw`,
  // clientDocWView: (id?: number) => `/clients/view/${clientID || ':id'}?tab=docw`,

  //Trading
  trading: '/trading',
  tradingCommissionsFull: '/trading/commissions-full',
  tradingCommissionsSum: '/trading/commissions-sum',
  tradingTransactions: '/trading/transactions',
  tradingTransactionsDeposit: (id?: number) => `/trading/transactions/${id || ':id'}/deposit`,
  tradingTransactions2: '/trading/transactions-list',
  tradingTransactions2View: (id?: number, tab?: string) => `/trading/transactions-view/${id || ':id'}${tab ? `?tab=${tab}` : ''}`,
  tradingAccounts: '/trading/accounts',
  tradingAccountsView: (id?: number) => `/trading/accounts/${id || ':id'}`,

  // trading commission
  tradingCommission: '/trading-commission',
  tradingCommissionClients: '/trading-commission/clients',
  tradingCommissionWallets: '/trading-commission/wallets',
  tradingCommissionWalletsTransactions: '/trading-commission/wallets-transactions',

  // Documents
  documents: '/documents',
  documentsNew: '/documents/new',
  documentsView: (id?: number) => `/documents/view/${id || ':id'}`,
  documentsDigiSignTemplates: '/documents/digisign/templates/list',
  documentsEmailTemplates: '/documents/emails/templates',
  documentsEmailCsv: '/documents/emails/csv',
  documentsWordTemplates: '/documents/templates',
  documentsWordTemplatesNew: '/documents/new-template',

  // Agents
  agents: '/agents',
  agentsList: '/agents/list',

  // Teams
  teams: '/teams',
  teamsList: '/teams/list',
  teamsNew: '/teams/new',

  // Staff
  staff: '/staff',
  staffList: '/staff/list',
  staffNew: '/staff/new',

  // Financial
  financial: '/financial',
  // TODO
  financialAccountFinancialAccounts: '/financial/account-financial-accounts',

  // Identification
  identification: '/identification',
  identificationList: '/identification/list',
  identificationView: (id?: number) => `/identification/view/${id || ':id'}`,
  identificationNew: '/identification/new',

  // reports
  reports: '/reports',
  reportsDashboard: '/reports/dashboard',

  // Settings
  settings: '/settings',
  settingsAccount: '/settings/account',
  settingsDocCheckCollections: '/settings/doc-check/collections',
  settingsTradingPlatforms: '/settings/trading-platforms',
  settingsUserProfile: '/settings/user-profile',
  settingsCurrencies: '/settings/currencies',
  settingsJournals: '/settings/journals',
  settingsSystemTools: '/settings/system-tools',

  // Verifications
  verifications: '/verifications',
  verificationsView: (id?: number) => `/verifications/view/${id || ':id'}`,
  verificationsNew: (clientID?: number) => {
    const queryParam = clientID ? `?clientID=${clientID}` : ''

    return `/verifications/new${queryParam}`
  }
}
