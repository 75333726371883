import React from 'react'

import {ExtendedDescriptions} from 'components'
import {SectionTitle} from '../shared'

interface FinanceProps {
  accountTransactions: {
    expectedTransactionCount: string;
    natureOfTransactions: string;
    sourceOfWealth: {label: string}[];
    sourceOfFunds: {label: string}[];
    purposeOfAccount: {label: string}[];
  }
}

const Finance: React.FC<FinanceProps> = ({accountTransactions}) => {

  return (
    <>
      <SectionTitle>Finance</SectionTitle>
      <ExtendedDescriptions
        items={[
          {
            label: 'Source Of Wealth',
            translate: false,
            copyable: false,
            value: accountTransactions?.sourceOfWealth.map(({label}) => <p key={label}>* {label}</p>)
          },
          {
            label: 'Source Of Funds',
            translate: false,
            copyable: false,
            value: accountTransactions?.sourceOfFunds.map(({label}) => <p key={label}>* {label}</p>)
          },
          {
            label: 'Purpose Of Account',
            translate: false,
            copyable: false,
            value: accountTransactions?.purposeOfAccount.map(({label}) => <p key={label}>* {label}</p>)
          },
          {
            label: 'Nature of Transactions',
            translate: false,
            value: accountTransactions?.natureOfTransactions
          },
          {
            label: 'Expected Transaction Count',
            translate: false,
            value: accountTransactions?.expectedTransactionCount
          },
        ]}
      />
    </>
  )
}

export default Finance
