import {moduleName} from './types'

import {createSelector} from 'reselect'

/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const stateSelector = state => state[moduleName].toJS()
/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const documentsEntitiesSelector = createSelector(stateSelector, ({loading, entities}) => ({
  entities,
  loading
}))
/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const documentFilesSelector = createSelector(stateSelector, (
  {
    loading,
    email,
    fileList,
    currentDocument,
  }
) => ({
  loading,
  email,
  fileList,
  currentDocument
}))
/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const documentEmailCustomTemplatesSelector = createSelector(stateSelector, (
  {
    loading,
    emailCustomTemplate,
    entities
  }
) => ({
  loading,
  emailCustomTemplate,
  entities
}))
/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const documentTypesSelector = createSelector(stateSelector, (
  {
    loading,
    docTypeList,
    fileIDs
  }
) => ({
  loading,
  docTypeList,
  fileIDs
}))
