import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  DatePicker,
  Row,
  Col,
  Typography,
  Table,
  Button
} from 'antd'
import moment from 'moment'
import Axios from 'axios'
import {useSelector} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'
// import { getTextOfJSDocComment } from 'typescript'
const {RangePicker} = DatePicker

interface IState {
  data: Array<String>[];
  headers: string[];
  name: string;
  dateSelected: boolean,
  startDate: Date;
  endDate: Date
}
const KEY: string = '999'

export const ProfitLoss: React.FC = () => {
  const {account_slug, account_name} = useSelector((state: any) => appStateSelectors.stateSelector(state).currentAccountRecord)
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    dateSelected: false,
    startDate: new Date(),
    endDate: new Date()
  })
  const [loading, setLoading] = useState(false)

  const fetch = async (start: moment.Moment, end: moment.Moment): Promise<any> => {
    const startOfMonth: Number = start.utc().unix() * 1000
    const currDate: Number = end.utc().unix() * 1000
    const response = await Axios.post('https://mario.pimwatech.com/ctin/profitloss', {
      key: KEY,
      account: account_slug, // ctin , gat ...
      startDate: startOfMonth,
      endDate: currDate
      // startDate: '1633050000000',      //commenting for testing
      // endDate: '1634173200000'
    })

    const data = response.data.te_response.data
    const temp: any = []
    if (data) {
      data.map((item: string[]) => {
        temp.push({
          name: item[0],
          value: item[1]
        })
      })
    }
    setLoading(false)
    setState({
      ...state,
      ...response.data.te_response,
      data: temp,
      dateSelected: true,
      startDate: start,
      endDate: end
    })
  }

  const columns = [
    {
      title: '',
      dataIndex: 'name'
    },
    {
      title: '',
      dataIndex: 'value'
    }
  ]

  const resetDatepicker = () => {
    setState({
      ...state,
      data: [],
      dateSelected: false
    })
  }

  const onChange = async (dates: any, dateStrings: Array<String>) => {
    if (dates){
      setLoading(true)
      await fetch(dates[0], dates[1])
    } else {
      setState({
        ...state,
        dateSelected: false
      })
    }
  }

  return (
    <Fragment>

      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={state.data}
            bordered={true}
            title={() => {
              return <>
                <Row>
                  <Col span={16}>
                    <Typography.Title level={4}> Profit &amp; Loss Report for {account_name}</Typography.Title>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Typography.Title level={5}>{
                      state?.dateSelected
                        ? `${moment(state.startDate).format('YYYY/MM/DD')} - ${moment(state.endDate).format('YYYY/MM/DD')}`
                        : ''}</Typography.Title>
                  </Col>
                  <Col span={8} style={{textAlign: 'right'}}>
                    {!state?.dateSelected ? <RangePicker onChange={onChange} /> : <Button type={'primary'} onClick={resetDatepicker}>Reset</Button>}
                  </Col>
                </Row></>
            }
            }
            showHeader={false}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default ProfitLoss
