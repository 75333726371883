import React, {
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {
  tradingCommissionsClientsActions,
  tradingCommissionsClientsSelectors,
} from 'configs/ducks/trading-commissions/index'

import {appStateSelectors} from 'configs/ducks/appState'

import {Tabs} from 'antd'

import TransactionSummary from './transaction_view/TransactionSummary'
import {API} from 'aws-amplify'

const {TabPane} = Tabs

const WalletTransactionViewPageComponent = (
  {
    getTopics,
    accountsList,
    match: {params: {walletTransactionID}}
  }
) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('trading-commissions', `/wallet-transactions/id/${walletTransactionID}`)
        getTopics(
          'View Transaction Details',
          response.payload.id
        )
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <Tabs defaultActiveKey='1'>
      <TabPane tab='Summary' key='1'>
        <TransactionSummary id={walletTransactionID} accountsList={accountsList}/>
      </TabPane>
    </Tabs>
  )
}

const {tradingCommissionsWalletsSelector} = tradingCommissionsClientsSelectors
const mapStateToProps = state => {
  const {accountsList} = appStateSelectors.stateSelector(state)
  return {
    accountsList,
    ...tradingCommissionsWalletsSelector(state)
  }
}

export default connect(mapStateToProps, {})(WalletTransactionViewPageComponent)