import React, { useEffect } from 'react'
import {
  Typography,
  Form,
  Input,
  Row,
  Col
} from 'antd'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import {leadsActions} from 'configs/ducks/leads/index'
import {FormSelect} from 'common'

const { Title } = Typography

const StaffClientAddress = ({countriesList, form}) => {

  const dispatch = useDispatch()
    
  const street_type_list = useSelector(
    (state) => state.leads?.streetCodes?.streetType
  )

  useEffect(() => {
    dispatch(leadsActions.getStreets())
  }, [dispatch])

  const getOptionProps = ({
    full_name, has_city, has_region, has_suburb
  }) => ({
    children: full_name,
    'data-address': {
      has_city,
      has_region,
      has_suburb
    }
  })

  const getOptionPropsStreetType = ({fileBy}) => ({children: fileBy})

  return (
    <div>
      <Title level={4}>Street Address</Title>
      <Row gutter={[12, 12]}>
        <Col span={7}>
          <Form.Item label='Unit number' name="streetUnitNumber">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={7}>
          <Form.Item label='Street number' name="streetStreetNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label='Street name' name="streetStreetName">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <FormSelect
            label="Street Type"
            name="streetStreetTypeId"
            optionValue='in'
            placeholder='Select Street Type'
            options={street_type_list}
            getOptionProps={getOptionPropsStreetType}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={10}>
          <Form.Item label='Suburb' name="street_address_suburb">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label='State' name="street_address_state">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label='Post Code' name="street_address_postcode">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={7}>
          <FormSelect
            label='Country'
            name="street_address_country"
            optionValue='id'
            placeholder='Select Country'
            options={countriesList}
            getOptionProps={getOptionProps}
          />
        </Col>
      </Row>
    </div>
  )
}

export default StaffClientAddress