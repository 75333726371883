import {Modal} from 'antd'
import React, {Fragment} from 'react'

export const ModalView = ({
  title, isModalVisible, handleOk, handleCancel, body, footer, ...rest
}) => {
  return (
    <Fragment>
      <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={footer} {...rest}>
        {body}
      </Modal>
    </Fragment>
  )
}