import React, {Fragment, useState} from 'react'
import {Button, Col, Form, Input, Modal, Row, Select} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import API from '@aws-amplify/api'
import {showNotification} from 'utils'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import draftToHtml from 'draftjs-to-html'

const {Option} = Select

export const JournalCreateNewComponent = ({
  isModalVisible,
  toggleModal,
  currentAccountId,
  clients,
  leads,
  staffId,
  asyncFunction,
  ...rest
}) => {
  const [form] = useForm()
  const [state, setState] = useState({
    reload: false,
    help: '',
    error: 'success'
  })

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, seteditorText] = useState('')

  const onEditorStateChange = (editor) => {
    setEditorState(editor)
    seteditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }

  const onFinish = async values => {
    if (!values.clientId && !values.leadId) {
      setState({
        ...state,
        help: 'Either Client or Lead is required',
        error: 'error'
      })
      return
    }
    try {
      values = {
        ...values,
        memo: editorText
      }
      await API.post('journals', '/create', {
        body: {
          ...values,
          staffId
        }
      })
      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Journals', error.message)
    }
    toggleModal(false)
    asyncFunction()
  }

  return (
    <Fragment>
      <Modal
        title='Create Journal'
        visible={isModalVisible}
        onOk={null}
        onCancel={() => {
          toggleModal(false)
        }}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        footer={null}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Row gutter={[96, 24]}>
            <Col xs={24}>
              <Form.Item
                name='journalTypeId'
                label='Journal Type'
                rules={[
                  {
                    required: true,
                    message: 'Journal Type is Required'
                  }
                ]}
              >
                <Select>
                  <Option value='2'>Staff Note</Option>
                  <Option value='1'>System Note</Option>
                  <Option value='3'>Client Update</Option>
                </Select>
              </Form.Item>
              <Row gutter={[16, 24]}>
                <Col xs={12}>
                  <Form.Item name='clientId' label='Select Client' help={state.help} validateStatus={state.error}>
                    <Select
                      allowClear
                      showSearch
                      showArrow
                      disabled={form.getFieldValue('leadId')}
                      onChange={() => {
                        setState({
                          ...state,
                          reload: true
                        })
                      }}
                    >
                      {clients.map(client => {
                        return (
                          <Option value={client.id}>
                            <Fragment>
                              {client.file_by} [{client.id}]
                            </Fragment>
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item name='leadId' label='Select Lead' help={state.help} validateStatus={state.error}>
                    <Select
                      showArrow
                      showSearch
                      disabled={form.getFieldValue('clientId')}
                      allowClear
                      onChange={() => {
                        setState({
                          ...state,
                          reload: true
                        })
                      }}
                    >
                      {leads.map(lead => {
                        return (
                          <Option value={lead.id}>
                            <Fragment>
                              {lead.file_by} [{lead.id}]
                            </Fragment>
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name='title'
                label='Journal Title'
                rules={[
                  {
                    required: true,
                    message: 'Journal Title is Required'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='memo'
                label='Memo'
                rules={[
                  {
                    required: true,
                    message: 'Memo is Required'
                  }
                ]}
              >
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    borderRadius: '2px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '250px'
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col offset={20}>
                    <Button type='primary' htmlType='submit'>
                      Create
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  )
}