import reducer from './reducers'

import * as documentsSelectors from './selectors'
import * as documentsActions from './actions'
import * as documentsTypes from './types'
import documentsSaga from './sagas'

export {
  documentsSaga,
  documentsTypes,
  documentsActions,
  documentsSelectors,
}

export default reducer