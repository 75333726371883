import React, {Component} from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'

import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input,
  Table
} from 'antd'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class WalletTransactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      loading: true,
      transactions: []
    }
  }

  async componentDidMount() {
    try {
      const transactions = await this.getTransactionsRecord(this.props.id)
      this.setState({
        transactions: transactions.payload,
        loading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  async getTransactionsRecord(walletID) {
    return API.get('trading-commissions', `/wallet-transactions/wallet/${walletID}`)
  }

  // async saveWalletRecord(wallet_record) {
  //   await API.patch('trading-commissions', `/wallet/${this.state.id}`, {body: wallet_record})
  //   openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  // }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  // onHandleSubmit = async event => {
  //   event.preventDefault()
  //   this.setState({is_saving: true})
  //   openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

  //   const {
  //     id,
  //     fileBy,
  //     firstName,
  //     lastName,
  //     clientID
  //   } = this.state
  //   try {
  //     await this.saveWalletRecord({
  //       id,
  //       fileBy,
  //       firstName,
  //       lastName,
  //       clientID
  //     })
  //     this.setState({is_saving: false})
  //   } catch (e) {
  //     console.log(e)
  //   }
  // };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Date',
      dataIndex: 'transactionDate',
      width: 70,
      render: (text, record) => {
        return text ? moment(text).format('DD/MM/YYYY') : ''
      }
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      width: 70,

    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      width: 70,
      render: value => {
        return (
          <span>
            {value && value.toFixed(2)}
          </span>
        )
      }
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      width: 70,
      render: value => {
        return (
          <span>
            {value && value.toFixed(2)}
          </span>
        )
      }
    }
  ]

  render() {
    return (
      <React.Fragment>
        <Form
          layout='vertical'
          // onSubmit={this.onHandleSubmit}
        >
          <Table
            // rowKey='id'
            scroll={{x: true}}
            style={{width: '100%'}}
            loading={this.state?.loading}
            columns={this.columns}
            dataSource={this.state.transactions}
          />

          {/*<Button type='primary' htmlType='submit' onClick={this.onHandleSubmit}>
            Save
                </Button>*/}
        </Form>
      </React.Fragment>
    )
  }
}

export default WalletTransactions
