import reducer from './reducers'

import * as xRecordIdsSelectors from './selectors'
import * as xRecordIdsActions from './actions'
import * as xRecordIdsTypes from './types'
import xRecordIdsSaga from './sagas'

export {
  xRecordIdsSaga,
  xRecordIdsTypes,
  xRecordIdsActions,
  xRecordIdsSelectors,
}

export default reducer
