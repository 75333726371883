import React, {
  useState,
  useEffect
} from 'react'
import {listHOC} from 'common'

import {
  Card,
  Checkbox,
  Row,
  Spin,
  Table,
  Tag
} from 'antd'
import {connect} from 'react-redux'
import {API} from 'utils'
import moment from 'moment'
import {EyeOutlined} from '@ant-design/icons'
import {ScheduledVerificationsDrawerComponent} from './drawers/ScheduledVerificationsDrawerComponent'

import {
  verificationsActions,
  verificationsSelectors
} from 'configs/ducks/verifications'
import {appStateSelectors} from 'configs/ducks/appState'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} leadsList
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const ClientMonitoring = listHOC(({
  getColumnSearchProps,
  loading,
  client_id,
  currentStaffId,
  currentStaff
}) => {
  
  const [state, setState] = useState({
    daily_checked: false,
    weekly_checked: false,
    dataLoading: true,
    VerificationChecksDrawerData: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      VerificationChecksDrawerData: record,
      visible: bool
    })
  }

  const [data, setTableData] = useState([])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID'
      })
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      ...getColumnSearchProps({
        dataIndex: 'clientId',
        placeholder: 'clientId'
      })
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      ...getColumnSearchProps({
        dataIndex: 'timestamp',
        placeholder: 'timestamp'
      }),
      render: (text) => {
        return text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : 'No'
      }
    },
    {
      title: 'Staff Review',
      dataIndex: 'staffCheckDatetime',
      ...getColumnSearchProps({
        dataIndex: 'staffCheckDatetime',
        placeholder: 'staffCheckDatetime'
      }),
      render: (text, record) => {
        return text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : <Tag color="gold">No Review</Tag>
      }
    },
    {
      title: 'Last Result',
      dataIndex: 'checkResult',
      ...getColumnSearchProps({
        dataIndex: 'checkResult',
        placeholder: 'checkResult'
      }),
      sorter: (a, b) => a.staffCheckId - b.staffCheckId,
      render: (text, record) => {
        return record.checkResult ?
          <Tag color="green">No Change</Tag> :
          <Tag color="red">Result Alert</Tag>
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return <React.Fragment>
          <a onClick={() => toggleDrawer(true, record)}> <EyeOutlined /> </a>
        </React.Fragment>
      }
    },
  ]

  useEffect(() => {
    getDataByClientId(client_id)
    getScheduledMonitoringStatus(client_id)
  }, [client_id])

  const getDataByClientId = async (clientId) => {
    await API.get('client-monitoring', `/scheduled/verifications/id/${clientId}`).then(
      res => {
        setTableData(res.payload)
      }
    )
  }
  const getScheduledMonitoringStatus = async (clientId) => {
    await API.get('client-monitoring', `/get-by-id/${clientId}`).then(
      res => {
        setState({
          daily_checked: Boolean(res.payload.monitoring_daily_status),
          weekly_checked: Boolean(res.payload.monitoring_weekly_status),
          dataLoading: false
        })
      }
    )
  }

  const setScheduledMonitoringStatus = (clientId, schedule) => {
    API.put('client-monitoring', `/update/${clientId}`, {
      body: {
        clientId,
        schedule
      }
    })
  }

  const deactivateMonitoringStatus = (clientId, schedule) => {
    API.post('client-monitoring', `/deactivate/${clientId}`, {body: {schedule}})
  }

  const activateMonitoringStatus = (clientId, schedule) => {
    API.post('client-monitoring', `/activate/${clientId}`, {body: {schedule}})
  }

  const onCheckboxChange = (event) => {
    // console.log(event)
    // console.log(event.target.id)
    // console.log(event.target.checked)

    switch (event.target.id){
      case 'daily':
        setScheduledMonitoringStatus(client_id, {
          'daily' : event.target.checked,
          'weekly': state.weekly_checked
        })
        setState({
          ...state,
          daily_checked:event.target.checked
        })

        if (event.target.checked){
          activateMonitoringStatus(client_id, 'Daily')
        } else if (!event.target.checked) {
          deactivateMonitoringStatus(client_id, 'Daily')
        }
        
        break
      case 'weekly':
        setScheduledMonitoringStatus(client_id, {
          'daily' : state.daily_checked,
          'weekly': event.target.checked
        })
        setState({
          ...state,
          weekly_checked: event.target.checked
        })

        if (event.target.checked){
          activateMonitoringStatus(client_id, 'Weekly')
        } else if (!event.target.checked) {
          deactivateMonitoringStatus(client_id, 'Weekly')
        }
        
        break
      default:
        break
        //do nothing
    }
  }

  // console.log(state)
  // console.log(state.monitoring_daily_status == 1)
  // console.log(state.monitoring_weekly_status == 1)

  return (
    <React.Fragment>
      <Card
        style={{minHeight: 360}}
        title='Monitoring'
      >
        <div style={{paddingTop: 24}}>
          <Spin spinning={state.dataLoading}>
            <Row>
              <Checkbox
                id='daily'
                checked={
                  state.daily_checked == 1
                }
                onChange={onCheckboxChange}>
                Scheduled Monitoring - Daily
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                id='weekly'
                checked={
                  state.weekly_checked == 1
                }
                onChange={onCheckboxChange}>
              Scheduled Monitoring - Weekly
              </Checkbox>
            </Row>
          </Spin>
        </div>
      </Card>
      <Card
        style={{minHeight: 360}}
        title='Schedule Verifications'
      >
        <div>
          <Table
            rowKey='id'
            scroll={{x: true}}
            columns={columns}
            loading={loading}
            dataSource={data}
          />
          {
            <ScheduledVerificationsDrawerComponent
              {...state}
              currentStaffId={currentStaffId}
              currentStaff={currentStaff}
              toggleModal={toggleDrawer}
              id={state.id}
              getDataByClientId={getDataByClientId}
              clientId={client_id}
            />
          }
        </div>
      </Card>
    </React.Fragment>
  )
})

const mapStateToProps = state => {
  const {currentStaffRecord} = appStateSelectors.stateSelector(state)
  return {
    ...verificationsSelectors.stateSelector(state),
    currentStaffId: currentStaffRecord.id,
    currentStaff: currentStaffRecord.file_by,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id
  }
}
const mapDispatchToProps = {fetchCb: verificationsActions.fetchChecks}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(listHOC(ClientMonitoring))
