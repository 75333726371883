import React, {
  useState,
  useEffect
} from 'react'

import {connect} from 'react-redux'

import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients/index'

import {appStateSelectors} from 'configs/ducks/appState'
import {EAF_PUBLIC_API} from 'consts'
import {
  inputChange,
  clearState
} from 'configs/actions/common'

import * as CSV from 'csv-string'
import {
  Table,
  Upload,
  Button,
  notification
} from 'antd'

import {InboxOutlined} from '@ant-design/icons'
import { API } from 'aws-amplify'
import axios from 'axios'

const ClientNewCSVPageComponent = ({currentAccountID, clearState}) => {
  const [state, setState] = useState({})

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  const showNotificationWithIcon = (
    type,
    header,
    message
  ) => {
    notification[type]({
      message: header,
      description: message,
    })
  }

  const columns = [
    {
      title: 'Create Time',
      dataIndex: 'createTime',
    },
    {
      title: 'GMT ID',
      dataIndex: 'gmtId',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Chinese Family Name',
      dataIndex: 'chineseFamilyName',
    },
    {
      title: 'Chinese Given Name',
      dataIndex: 'chineseGivenName',
    },
    {
      title: 'Family Name',
      dataIndex: 'familyName',
    },
    {
      title: 'Given Name',
      dataIndex: 'givenName',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'Phone Area',
      dataIndex: 'phoneArea',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'National ID',
      dataIndex: 'nationalId',
    },
    {
      title: 'Passport',
      dataIndex: 'passport',
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
    },
    {
      title: 'Referrer Number',
      dataIndex: 'referrerNumber',
    },
    {
      title: 'Referrer Name',
      dataIndex: 'referrerName',
    },
    {
      title: 'Language',
      dataIndex: 'language',
    },
  ]

  const createXeondaLead = async (
    element
  ) => {
    if (element?.email.length) {
      showNotificationWithIcon('info', 'Commencing', element.email)

      try {
        let dateOfBirth
        if (element.nationalId && element.nationalId.length === 18) {
          dateOfBirth = `${element.nationalId.substring(
            10,
            6
          )}-${element.nationalId.substring(
            12,
            10
          )}-${element.nationalId.substring(14, 12)}`
        }
        
        await API.post('clients', '/create',
          {
            body: {
              account_id: currentAccountID,
              client_type: 1,
              categoryId: 2,
              first_name: element.chineseGivenName,
              last_name: element.chineseFamilyName,
              email: element.email,
              preferred_first_name: element.givenName,
              preferred_last_name: element.familyName,
              tel_mobile: `+${element.phoneArea}${element.phoneNumber}`,
              date_of_birth: dateOfBirth,
              preferred_language: `${element.language}`,
            }
          }
        )

        sleep(200)
        showNotificationWithIcon('success', 'Completed', element.email)
      } catch (e) {
        await API.post('email', '/client/account-create-error', {
          body: {
            clientFirstName: element.chineseGivenName,
            clientLastName: element.chineseFamilyName,
          },
        })
        showNotificationWithIcon(
          'error',
          `Failed to create an account for: ${element.familyName} ${element.givenName} ${element.email}`,
          e?.response?.data?.error || e.message
        )
      }
    }
  }

  const emailLeadWithoutInfoRequest = async (element) => {
    if (element?.email.length) {
      showNotificationWithIcon('info', 'Commencing', element.email)

      try {
        await API.post('email', '/client/app-without-info-request', {
          body: {
            clientEmail: element.email,
            clientFirstName: element.chineseGivenName,
            accountId: currentAccountID,
          },
        })
        sleep(200)
        showNotificationWithIcon('success', 'Completed', element.email)
      } catch (e) {
        API.post('email', '/client/account-create-error', {
          body: {
            clientFirstName: element.chineseGivenName,
            clientLastName: element.chineseFamilyName,
          },
        })
        showNotificationWithIcon(
          'error',
          `Failed to create an account for: ${element.familyName} ${element.givenName} ${element.email}`,
          e?.response?.data?.error || e.message
        )
      }
    }
  }

  const emailLeadWithInfoRequest = async (element) => {
    if (element?.email.length) {
      showNotificationWithIcon('info', 'Commencing', element.email)

      try {
        const clientId = await API.post('clients', '/check-email-by-account', {
          body: {
            email: element.email,
            account_id: currentAccountID,
          }
        }).then(res => {
          // console.log(res)
          showNotificationWithIcon('success', 'Clients', 'Email Checking')
          return res.payload?.results[0]?.id
        })
        if (!clientId) {
          throw new Error('Client ID not found')
        }

        await API.post('information-requests', '/create', {
          body: {
            language: 'zh',
            clientId: clientId,
            informationRequestId: 1,
            accountId: currentAccountID,
            email: element.email,
            firstName: element.chineseGivenName,
            lastName: element.chineseFamilyName,
            activateFinancial: true,
            activateEdd: true,
            thirdParty: false,
            recipientFirstName: null,
            recipientLastName: null,
            recipientEmail: null
          }
        })
        showNotificationWithIcon('success', 'Information Requests', 'New Record has been created')
        
        const login_data = {
          accountId: 17,
          username: 'andre@deliostech.com',
          password: 'Abc123',
        }

        const eaf_api_response = await axios.post(
          `${EAF_PUBLIC_API}/auth/token`,
          login_data
        )

        const config = {
          headers: {
            authorization: `Bearer ${eaf_api_response.data.token}`,
            'content-Type': 'application/json',
          },
        }

        const eaf_api_get_response = await axios.get(
          `${EAF_PUBLIC_API}/information-requests`,
          config
        )
        const eafIrLink = eaf_api_get_response.data.metadata.url
        const eafIrPin = eaf_api_get_response.data.metadata.pincode

        await API.post('email', '/client/app-with-info-request', {
          body: {
            clientEmail: element.email,
            clientFirstName: element.chineseGivenName,
            eafIrLink,
            eafIrPin,
            accountId: currentAccountID,
          },
        })
        sleep(200)
      } catch (e) {
        console.log(e)
        await API.post('email', '/client/account-create-error', {
          body: {
            clientFirstName: element.chineseGivenName,
            clientLastName: element.chineseFamilyName,
          },
        })
        showNotificationWithIcon(
          'error',
          `Failed to create an account for: ${element.familyName} ${element.givenName} ${element.email}`,
          e?.response?.data?.error || e.message
        )
      }
    }
  }
  
  const sleep = (milliseconds) => {
    const date = Date.now()
    let currentDate = null
    do {
      currentDate = Date.now()
    } while (currentDate - date < milliseconds)
  }
  
  const onCreateXeondaLeadsClick = async () => {
    const keys = Object.keys(state.dataSource)
    for (let i = 0; i < keys.length; i += 1) {
      /* eslint-disable no-await-in-loop */
      // await createImportRecord(state.dataSource[i].email, state.importBatchId);
      await createXeondaLead(
        state.dataSource[i]
      )
    }

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )
  }

  const onEmailLeadWithoutInfoRequestClick = async () => {
    const keys = Object.keys(state.dataSource)

    for (let i = 0; i < keys.length; i += 1) {
      /* eslint-disable no-await-in-loop */
      await emailLeadWithoutInfoRequest(
        state.dataSource[i],
      )
    }

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )
  }

  const onEmailLeadWithInfoRequestClick = async () => {
    const keys = Object.keys(state.dataSource)

    for (let i = 0; i < keys.length; i += 1) {
      /* eslint-disable no-await-in-loop */
      await emailLeadWithInfoRequest(
        state.dataSource[i],
      )
    }

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )
  }

  return !state.loaded ? (
    <Upload.Dragger
      name="file"
      accept=".csv"
      multiple={false}
      beforeUpload={(file) => {
        const reader = new FileReader()

        reader.readAsText(file)

        reader.onload = async ({ target }) => {
          if (target && target.result && typeof target.result === 'string') {
            const arr = CSV.parse(target.result)

            const dataSource = []

            arr.forEach(
              (
                [
                  createTime,
                  gmtId,
                  fullName,
                  chineseFamilyName,
                  chineseGivenName,
                  familyName,
                  givenName,
                  gender,
                  country,
                  phoneArea,
                  phoneNumber,
                  email,
                  nationalId,
                  passport,
                  accountType,
                  referrerNumber,
                  referrerName,
                  language
                ],
                index
              ) => {
                if (index !== 0) {
                  dataSource.push({
                    createTime,
                    gmtId,
                    fullName,
                    chineseFamilyName,
                    chineseGivenName,
                    familyName,
                    givenName,
                    gender,
                    country,
                    phoneArea,
                    phoneNumber,
                    email,
                    nationalId,
                    passport,
                    accountType,
                    referrerNumber,
                    referrerName,
                    language
                  })
                }
              }
            )

            setState((prev) => ({
              ...prev,
              loaded: true,
              dataSource,
            }))
          }
        }

        return false
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading
        company data or other band files
      </p>
    </Upload.Dragger>
  ) : (
    
    <>
      <Table
        dataSource={state.dataSource}
        columns={columns}
        rowKey="gmtId"
        scroll={{ x: true }}
        pagination={{
          pageSizeOptions: ['5', '10', '15'],
          defaultPageSize: 5,
          showSizeChanger: true,
        }} />
      {state.dataSource.length > 0 &&
        <>
          <Button
            loading={state.loading}
            onClick={onCreateXeondaLeadsClick}
          >
            Create Xeonda Leads
          </Button>
          <Button
            loading={state.loading}
            onClick={onEmailLeadWithoutInfoRequestClick}
          >
              Email (without Info Req)
          </Button>
          <Button
            loading={state.loading}
            onClick={onEmailLeadWithInfoRequestClick}
          >
            Email (with Info Req)
          </Button>
          
        </>
      }
    </>
  )
}

const {createClient, checkClientsEmail} = clientsActions

const {newClientSelector} = clientsSelectors

const mapStateToProps = state => {
  const {
    duplicateEmail, loading, client
  } = newClientSelector(state)
  const {
    currentAccountRecord: {id: currentAccountID},
    countriesList,
    clientCategoriesList
  } = appStateSelectors.stateSelector(state)
  return {
    clientCategoriesList,
    duplicateEmail,
    loading,
    client,
    countriesList,
    currentAccountID
  }
}

export default connect(mapStateToProps, {
  clearState,
  inputChange,
  createClient,
  checkClientsEmail
})(ClientNewCSVPageComponent)