import * as React from 'react'
import {
  Route,
  Redirect
} from 'components'
import { RouteProps } from 'types'

export const queryString = (name: string, url = window.location.href) => {
  const parsedName = name.replace(/[[]]/g, '\\$&')
  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`, 'i')
  const results = regex.exec(url)

  if (!results || !results[2]) {
    return false
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

interface UnauthenticatedRouteProps extends RouteProps {
  authenticated: boolean;
  children: React.ReactElement;
}

const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  children,
  authenticated,
  ...rest
}) => {
  const redirect = queryString('redirect')
  return (
    <Route
      {...rest}
      render={() =>
        !authenticated ? children : <Redirect to={redirect || '/'} />
      }
    />
  )
}

export default UnauthenticatedRoute
