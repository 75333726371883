import React, {
  Fragment,
  useState,
  useEffect
} from 'react'

import {
  useDispatch,
  useSelector
} from 'hooks'
import {connect} from 'react-redux'
import {tradingSelectors} from 'configs/ducks/trading'
import {appStateSelectors} from 'configs/ducks/appState'

import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Divider,
  Tag,
  Spin,
  Popconfirm,
  Switch,
  Select,
  Typography,
  DatePicker
} from 'components'
import {
  ROW_GUTTER,
  DATE_FORMAT
} from 'consts'
import {
  showNotification,
  API
} from 'utils'
import {settingsActions} from 'configs/ducks/settings'
import moment from 'moment'
import generator from 'generate-password'

import {
  get_country_value,
  get_street_type_value,
  get_country_shortname_value
} from 'library/countries'
import dayjs from 'dayjs'

const {Option} = Select

function GatMoney({
  currentAccountID, clientRecord, clientPreferredLanguage, countriesList
}) {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [state, setState] = useState({
    gatmoney_active: '',
    gatmoney_address:'',
    gatmoney_english_first_name: '',
    gatmoney_english_last_name: '',
    gatmoney_city: '',
    gatmoney_state: '',
    gatmoney_country_code: '',
    gatmoney_dob: null,
    gatmoney_email: '',
    gatmoney_first_name: '',
    gatmoney_identification_type: 'none',
    gatmoney_identification_number: '',
    gatmoney_last_name:'',
    gatmoney_middle_name: '',
    gatmoney_phone_no: '',
    gatmoney_post_code: '',
    gatmoney_password: '',
    gatMoneyDataValid: false,
    emailClientAccountCreatedButtonLoading: false,
    emailClientAccountActivatedButtonLoading: false,
    gatMoneyActivateAccountButtonLoading: false,
    resetRequestSentLoading: false,
    resetRequestSent: false,
    fetching: false
  })
  const [username, setUsername] = useState(clientRecord.platformUsername ? clientRecord.platformUsername : '')
  const [platformPassword, setPassword] = useState(clientRecord.platformPassword ? clientRecord.platformPassword : '')
  const [gatMoneyAppDetails, setGATMoneyAppDetails] = useState('')

  const street_type_list = useSelector(
    (state) => state.leads?.streetCodes?.streetType
  )

  useEffect(() => {
    dispatch(settingsActions.getTradingPlatformsList(currentAccountID))

    if (username){
      onGetGATMoneyData()
    }
  }, [currentAccountID, dispatch])

  const onUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const onTextboxChangeV2 = (event) => {
    // Have to use event persist to stable https://reactjs.org/docs/events.html#event-pooling
    event.persist()

    setState({
      ...state,
      [event.target.id]: event.target.value
    })
  }

  const onGetGATMoneyData = async () => {
    if (!username) {
      return
    }

    try {
      setState(prevState => ({
        ...prevState,
        fetching: true
      }))

      const {payload} = await API.get('accounts-type-1', `/v2/get/detail/${username}`)

      setState(prevState => ({
        ...prevState,
        fetching: false,
        gatMoneyDataValid: true,
        gatmoney_email: payload.data.email,
        gatmoney_first_name: payload.data.firstName,
        gatmoney_last_name: payload.data.lastName,
        gatmoney_active: payload.data.customerDetail?.status,
        gatmoney_english_first_name: payload.data.customerDetail?.westernFirstName,
        gatmoney_english_last_name: payload.data.customerDetail?.westernLastName,
        gatmoney_address: payload.data.customerDetail?.address,
        gatmoney_city: payload.data.customerDetail?.city,
        gatmoney_state: payload.data.customerDetail?.stateOrProvince,
        gatmoney_post_code: payload.data.customerDetail?.postalCode,
        gatmoney_country_code: payload.data.customerDetail?.countryCode,
        gatmoney_dob: payload.data.customerDetail?.dateOfBirth && dayjs(payload.data.customerDetail?.dateOfBirth, DATE_FORMAT),
        gatmoney_phone_no: payload.data.phone,
        gatmoney_identification_type: payload.data.knowYourCustomer?.identificationType,
        gatmoney_identification_number: payload.data.knowYourCustomer?.identificationValue,
      }))

      setGATMoneyAppDetails(JSON.stringify(payload))
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        fetching: false
      }))
      showNotification('error', 'API', 'API is currently unavailable.  Unable to get data from GAT Money.')
    }

  }

  const onEmailAccountCreated = async (values) => {
    setState({
      ...state,
      emailClientAccountCreatedButtonLoading: true
    })

    values = {
      ...values,
      client_id: clientRecord.id
    }

    try {
      await API.post('email', '/gatmoney-create', {
        body: {
          interaction: 'Testing',
          data: {
            id: clientRecord.id,
            clientRecord: clientRecord
          },
        }
      })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
      showNotification('success', 'Email', 'Email send to client [Account Created] - Send Successful')

      await API.post('journals', '/create', {
        body: {
          journalTypeId: 5,
          clientId: clientRecord.id,
          title: 'Account create email sent',
          memo: `Account create email sent to ${[username]}`
        }
      }).then(
        response => {
          console.log(response.payload)
          showNotification('success', 'Journals', 'Successfully Created')
        }
      ).catch(error => {
        console.log(error)
        showNotification('error', 'API', error.message)
      })

      setState({
        ...state,
        emailClientAccountCreatedButtonLoading: false
      })
    } catch (error) {
      showNotification('error', 'Emails', error.message)
      setState({
        ...state,
        emailClientAccountCreatedButtonLoading: false
      })
    }
  }

  const onEmailAccountActivated = async (values) => {
    setState({
      ...state,
      emailClientAccountActivatedButtonLoading: true
    })
    
    values = {
      ...values,
      client_id: clientRecord.id
    }

    try {
      await API.post('email', '/gatmoney-active', {
        body: {
          interaction: 'Testing',
          data: {
            id: clientRecord.id,
            clientRecord: clientRecord,
            username: username,
            platformPassword: platformPassword
          },
        }
      })

      showNotification('success', 'Email', 'Email send to client [Account Activated] - Send Successful')

      await API.post('journals', '/create', {
        body: {
          journalTypeId: 5,
          clientId: clientRecord.id,
          title: 'Account activation email sent',
          memo: `Account activation email sent to ${[username]}`
        }
      })

      setState({
        ...state,
        emailClientAccountActivatedButtonLoading: false
      })
    } catch (error) {
      showNotification('error', 'Error', error.message)
      setState({
        ...state,
        emailClientAccountActivatedButtonLoading: false
      })
    }
  }

  const onPrefillClick = () => {
    try {
      const password = generator.generate({
        length: 8,
        numbers: true,
        lowercase: true,
        uppercase: true,
        symbols: true,
        strict: true,
      })

      // changeFormValue('platformUsername', clientRecord.email)
      // changeFormValue('platformPassword', platformPassword ? platformPassword : password)
      setUsername(clientRecord.email)

      setState({
        ...state,
        'gatmoney_first_name': clientRecord.first_name,
        'gatmoney_last_name':  clientRecord.last_name,
        'gatmoney_english_first_name': clientRecord.english_first_name,
        'gatmoney_english_last_name': clientRecord.english_last_name,
        'gatmoney_dob': clientRecord.date_of_birth && dayjs(clientRecord.date_of_birth, DATE_FORMAT),
        'gatmoney_address': clientRecord?.addressWestern_line1 ? clientRecord.addressWestern_line1 :
          String(clientRecord?.streetStreetName) + clientRecord?.streetStreetTypeId ? String(street_type_list ? get_street_type_value(street_type_list, clientRecord.streetStreetTypeId) : '') + String(', ' + clientRecord?.streetSuburb) + String(', ' + clientRecord?.streetState) + String(clientRecord?.streetPostcode ? ', ' + clientRecord.streetPostcode : '') + String(clientRecord?.streetCountryId ? ', ' + get_country_value(countriesList, clientRecord.streetCountryId) : '') : 'TBA',
        'gatmoney_addressWestern_line1': clientRecord?.addressWestern_line1 ? clientRecord.addressWestern_line1 : null,
        'gatmoney_addressWestern_suburb': clientRecord?.addressWestern_suburb ? clientRecord.addressWestern_suburb : null,
        'gatmoney_addressWestern_state': clientRecord?.addressWestern_state ? clientRecord.addressWestern_state : null,
        'gatmoney_addressWestern_postcode': clientRecord?.addressWestern_postcode ? clientRecord.addressWestern_postcode : null,
        'gatmoney_city': clientRecord?.addressWestern_suburb ? clientRecord.addressWestern_suburb : clientRecord?.streetSuburb ? clientRecord.streetSuburb : 'TBA',
        'gatmoney_state': clientRecord?.addressWestern_state ? clientRecord.addressWestern_state : clientRecord?.streetState ? clientRecord.streetState : 'TBA',
        'gatmoney_post_code': clientRecord?.addressWestern_postcode ? clientRecord.addressWestern_postcode : clientRecord?.streetPostcode ? clientRecord.streetPostcode : 'TBA',
        'gatmoney_country_code': clientRecord?.streetCountryId ? get_country_shortname_value(countriesList, clientRecord.streetCountryId) : '',
        'gatmoney_phone_no': clientRecord.tel_mobile,
        'gatmoney_email': clientRecord.email,
        'gatmoney_identification_type': 'none',
        'gatmoney_identification_number': null,
        // 'gatmoney_identification_validThrough': '2024-01-01',
        // 'gatmoney_identification_method': 'TBA',
        'gatmoney_password': platformPassword ? platformPassword : password,
        'gat_id': clientRecord.account_user_id || 'TBA',
        'xri': clientRecord.xri || 'TBA'
      })
      showNotification('success', 'Prefills', 'Prefills in process')
    } catch (error) {
      showNotification('error', 'Prefills', error.message)
    }
  }

  const onAccountCreatedClick = async () => {
    try {
      setSubmitting(true)
      showNotification('info', 'GAT Money API', 'Attempting to ceate customer via GAT Money API')
      // TODO: To test #759 Create journal entry when user creates GAT Money App account remember to comment out this block
      await API.post('accounts-type-1', '/v2/client/gatmoney-create', {
        body: {
          'first_name': state.gatmoney_first_name,
          'middle_name': state.gatmoney_middle_name,
          'last_name':  state.gatmoney_last_name,
          // 'nationality_code': state.gatmoney_nationality_code,
          'western_first_name': state.gatmoney_english_first_name,
          'western_last_name': state.gatmoney_english_last_name,
          // 'chinese_first_name': state.gatmoney_english_first_name,
          // 'chinese_last_name': state.gatmoney_english_last_name,
          'entityType': 'Individual',
          'dateOfBirth': state.gatmoney_dob.format(DATE_FORMAT),
          'address': state.gatmoney_address,
          'city': state.gatmoney_city,
          'post_code': state.gatmoney_post_code,
          'country_code': state.gatmoney_country_code,
          'state_province': state.gatmoney_state,
          'area_code': 0,
          'phone_no': state.gatmoney_phone_no,
          'email': state.gatmoney_email,
          'password': state.gatmoney_password,
          // 'identification_method':  state.gatmoney_identification_method,
          'identification_type': state.gatmoney_identification_type,
          'identification_value': state.gatmoney_identification_number,
          // 'identification_valid_through': state.gatmoney_identification_validThrough,
          'gat_id': state.gat_id,
          'xri': state.xri,
        }
      })

      // #759 Create journal entry when user creates GAT Money App account
      // https://delios.atlassian.net/browse/DCA-759
      await API.post('journals', '/create', {
        body: {
          journalTypeId: 5,
          clientId: clientRecord.id,
          title: 'GAT Money App User Created',
          memo: JSON.stringify({
            'first_name': state.gatmoney_first_name,
            // 'middle_name': state.gatmoney_middle_name,
            'last_name':  state.gatmoney_last_name,
            // 'nationality_code': state.gatmoney_nationality_code,
            'english_first_name': state.gatmoney_english_first_name,
            'english_last_name': state.gatmoney_english_last_name,
            'dateOfBirth': state.gatmoney_dob.format(DATE_FORMAT),
            'address': state.gatmoney_address,
            'city': state.gatmoney_city,
            'state': state.gatmoney_state,
            'post_code': state.gatmoney_post_code,
            'country_code': state.gatmoney_country_code,
            'phone_no': state.gatmoney_phone_no,
            'email': state.gatmoney_email,
            'password': state.gatmoney_password,
            // 'identification_method':  state.gatmoney_identification_method,
            'identification_type': state.gatmoney_identification_type,
            'identification_number': state.gatmoney_identification_number,
            // 'identification_valid_through': state.gatmoney_identification_validThrough
          })
        }
      })

      showNotification('success', 'Journals', 'Created journal to record account creation')

      await onGetGATMoneyData()

      setSubmitting(false)

      showNotification('success', 'Account Create', 'GAT Money Account creation now completed')
    } catch (error) {
      showNotification('error', 'API Call Failed', error.message)
    }
  }

  const onDataUpdateClick = async () => {
    try {
      setSubmitting(true)
      showNotification('info', 'GAT Money API', 'Attempting to ceate customer via GAT Money API')
      // TODO: To test #759 Create journal entry when user creates GAT Money App account remember to comment out this block
      await API.put('accounts-type-1', '/update', {
        body: {
          'FirstName': state.gatmoney_first_name,
          // 'MiddleName': state.gatmoney_middle_name,
          'LastName':  state.gatmoney_last_name,
          // 'nationality_code': state.gatmoney_nationality_code,
          'WesternFirstName': state.gatmoney_english_first_name,
          'WesternLastName': state.gatmoney_english_last_name,
          // 'chinese_first_name': state.gatmoney_english_first_name,
          // 'chinese_last_name': state.gatmoney_english_last_name,
          'DateOfBirth': state.gatmoney_dob.format(DATE_FORMAT),
          'Address': state.gatmoney_address,
          'StateOrProvince': state.gatmoney_state,
          'City': state.gatmoney_city,
          'PostalCode': state.gatmoney_post_code,
          'CountryCode': state.gatmoney_country_code,
          'MobilePhoneNumber': state.gatmoney_phone_no,
          'Email': state.gatmoney_email,
          'IdentificationType': state.gatmoney_identification_type,
          'IdentificationValue': state.gatmoney_identification_number,
          // 'password': state.gatmoney_password,
          // 'identification_method':  state.gatmoney_identification_method,
          // 'identification_number': state.gatmoney_identification_number,
          // 'identification_valid_through': state.gatmoney_identification_valid_through,
          'GatId': state.gat_id,
          'Xri': state.xri
        }
      })

      onGetGATMoneyData()

      // #759 Create journal entry when user creates GAT Money App account
      // https://delios.atlassian.net/browse/DCA-759
      await API.post('journals', '/create', {
        body: {
          journalTypeId: 5,
          clientId: clientRecord.id,
          title: 'GAT Money Portal User Updated',
          memo: JSON.stringify({
            'first_name': state.gatmoney_first_name,
            'last_name':  state.gatmoney_last_name,
            'english_first_name': state.gatmoney_english_first_name,
            'english_last_name': state.gatmoney_english_last_name,
            'dateOfBirth': state.gatmoney_dob.format(DATE_FORMAT),
            'address': state.gatmoney_address,
            'city': state.gatmoney_city,
            'state': state.gatmoney_state,
            'post_code': state.gatmoney_post_code,
            'country_code': state.gatmoney_country_code,
            'phone_no': state.gatmoney_phone_no,
            'email': state.gatmoney_email,
            'password': state.gatmoney_password,
            'IdentificationType': state.gatmoney_identification_type,
            'IdentificationValue': state.gatmoney_identification_number,
          })
        }
      })

      showNotification('success', 'Journals', 'Created journal to record account creation')

      await onGetGATMoneyData()

      setSubmitting(false)

      showNotification('success', 'Account Create', 'GAT Money Account creation now completed')
    } catch (error) {
      showNotification('error', 'API', 'API is currently unavailable.  Unable to access GAT Money.')
    }
  }

  const validateCode = (code) => {
    if (code == false){
      return false
    }
    return isCodeCorrectFormat(code)
  }

  const isCodeCorrectFormat = (code) => {
    const word = code
    const condition = word?.length === 2
    return condition
  }

  const resetGatMoneyPassword = (e) => {
    setState(prevState => ({
      ...prevState,
      resetRequestSentLoading: true,
    }))
    API.get('accounts-type-1', `/reset/${username}`).then(
      () => {
        setState(prevState => ({
          ...prevState,
          resetRequestSentLoading: false,
          resetRequestSent: true,
        }))
      }
    ).catch(() => {
      setState(prevState => ({
        ...prevState,
        resetRequestSentLoading: false,
        resetRequestSent: false,
      }))
    })
  }
  const popConfirmCancel = (e) => {}
  
  const onSwitchUpdateClick = async (status) => {
    try {
      setSubmitting(true)
      showNotification('info', 'GAT Money API', 'Attempting to update status via GAT Money API')
      const newStatus = state.gatmoney_active == 'Active' ? 'Inactive' : 'Active'

      // TODO: To test #759 Create journal entry when user creates GAT Money App account remember to comment out this block
      await API.put('accounts-type-1', '/update', {
        body: {
          'Email': state.gatmoney_email,
          'Status': newStatus
        }
      })

      setState({
        ...state,
        gatmoney_active: status ? 'Active' : 'Inactive',
      })

      onGetGATMoneyData()

      // #759 Create journal entry when user creates GAT Money App account
      // https://delios.atlassian.net/browse/DCA-759
      await API.post('journals', '/create', {
        body: {
          journalTypeId: 5,
          clientId: clientRecord.id,
          title: 'GAT Money Portal User Updated',
          memo: JSON.stringify({
            'Email': state.gatmoney_email,
            'Status': newStatus
          })
        }
      }).then(
        response => {
          console.log(response.payload)
          showNotification('success', 'Journals', 'Created journal to record account creation')
        }
      ).catch(error => {
        console.log(error)
        throw new Error(error.message)
        // showNotification('error', 'API', error.message)
      })
      onGetGATMoneyData()
      setSubmitting(false)
      showNotification('success', 'Account Create', 'GAT Money Account status update now completed')
    } catch (error) {
      showNotification('error', 'API', error.message)
      showNotification('error', 'API', 'API is currently unavailable.  Unable to access GAT Money.')
    }
  }

  return (
    <Spin spinning={state.fetching}>
      <Card
        style={{minHeight: 360}}
        title='Gat Money'
      >
        <Row gutter={[24,0]}>
          <Col xs={8}>
            <Form.Item
              label='Username'
              name='platformUsername'
              required
              shouldUpdate
            >
              <Input onChange={onUsernameChange}/>
            </Form.Item>
          
            <Form.Item
              label='Password'
              name='platformPassword'
              required
              shouldUpdate
            >
              <Input onChange={onPasswordChange}/>
            </Form.Item>
            <Row justify='end'>
              <Button
                type='primary'
                htmlType='submit'
              >
                Save Username & Password
              </Button>
            </Row>
          </Col>
          <Col xs={8}>
            <Form.Item label='GAT Money Interface'>
              <Button onClick={onGetGATMoneyData}>
                Read GAT Money
              </Button>
              <Divider type='vertical' />
              <Button disabled={state?.gatMoneyDataValid} onClick={onPrefillClick}>
                Prefill to Create
              </Button>
            </Form.Item>

            <Form.Item label='Account Activation'>
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={state?.gatmoney_active == 'Active'}
                onClick={() => onSwitchUpdateClick(state?.gatmoney_active == 'Active')}
              />
            </Form.Item>
            <Divider type='horizontal' />
            <Form.Item label='Reset GAT Money Password'>
              {!state?.resetRequestSent &&
                <Popconfirm
                  title="Are you sure you wish to reset this password?"
                  onConfirm={resetGatMoneyPassword}
                  onCancel={popConfirmCancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    loading={state.resetRequestSentLoading}
                    disabled={state?.resetRequestSent}>
                    Reset
                  </Button>
                </Popconfirm>
              }
              {state?.resetRequestSent && 'Password has been reset. Please ask SysAdmin for password'}
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label='Email Client - Account Created & Active'>
              <Button
                onClick={onEmailAccountCreated}
                loading={state.emailClientAccountCreatedButtonLoading}
                disabled={!clientRecord?.platformUsername || !clientRecord?.platformPassword }
              >
                {`Send Email (${clientPreferredLanguage ? clientPreferredLanguage : 'en'})`}
              </Button>
              <br/>
              <small>
                <em>
                This emails the customer that they have an active account and give
                their username & password
                </em>
              </small>
            </Form.Item>
            <Form.Item label='Email Client - Account Activated'>
              <Button
                onClick={onEmailAccountActivated}
                loading={state.emailClientAccountActivatedButtonLoading}
                disabled={!clientRecord?.platformUsername || !clientRecord?.platformPassword }>
                {`Send Email (${clientPreferredLanguage ? clientPreferredLanguage : 'en'})`}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,0]}>
          <Col xs={6}>
            <Form.Item label='GAT Money Data'>
              {state?.gatMoneyDataValid &&
                      <Tag color='green'>
                        Valid GAT Money Data
                      </Tag>
              }
              {!state?.gatMoneyDataValid &&
                      <Tag color='volcano'>
                        Invalid GAT Money Data
                      </Tag>
              }
            </Form.Item>
          </Col>
          {(!clientRecord?.platformUsername || !clientRecord?.platformPassword ) &&
            <Col xs={12}>
              <strong>Warning - make sure username & password are filled in above</strong>
            </Col>
          }
        </Row>
        <Typography.Title level={4}>
          Data below is for/from GAT Money
        </Typography.Title>
        <Row gutter={ROW_GUTTER}>
          <Col xs={8}>
            <Form.Item
              label='First Name'
              hasFeedback
              validateStatus={state?.gatmoney_first_name ? 'success' : ''}
              required
            >
              <Input id='gatmoney_first_name'
                value={state?.gatmoney_first_name}
                onChange={(event, state) => onTextboxChangeV2(event, state)}
              />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              label='Address'
              hasFeedback
              validateStatus={state?.gatmoney_address ? 'success' : ''}
              required
            >
              <Input id='gatmoney_address'
                value={state?.gatmoney_address ? state.gatmoney_address :
                             state?.gatmoney_addressWestern_line1 ? state.gatmoney_addressWestern_line1 :
                               String(state?.gatmoney_address).trim()}
                onChange={(event, state) => onTextboxChangeV2(event, state)}
              />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              label='Phone No.'
              hasFeedback
              validateStatus={state?.gatmoney_phone_no ? 'success' : ''}
              required
            >
              <Input
                id='gatmoney_phone_no'
                value={state?.gatmoney_phone_no}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Last Name'
              hasFeedback
              validateStatus={state?.gatmoney_last_name ? 'success' : ''}
              required
            >
              <Input id='gatmoney_last_name'
                value={state?.gatmoney_last_name}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              label='City'
              hasFeedback
              validateStatus={state?.gatmoney_city ? 'success' : ''}
              required
            >
              <Input id='gatmoney_city'
                value={state?.gatmoney_city}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>

            <Form.Item
              label='Date of Birth (yyyy-mm-dd)'
              hasFeedback
              validateStatus={state?.gatmoney_dob ? 'success' : 'error'}
              required
            >
              <DatePicker
                format={DATE_FORMAT}
                value={state?.gatmoney_dob}
                onChange={(dayJs) => {
                  setState(prevState => ({
                    ...prevState,
                    gatmoney_dob: dayJs
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Western Character First Name'
              hasFeedback
              validateStatus={state?.gatmoney_english_first_name ? 'success' : 'error'}
              required
              help={state?.gatmoney_english_first_name ? '' : 'Western Character First Name is required'}
              rules={[
                {
                  message: 'Western Character First Name is required',
                  validator: (_, value) => {
                    if (value.length > 0) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Western Character First Name is required')
                  }
                }
              ]}
            >
              <Input id='gatmoney_english_first_name'
                value={state?.gatmoney_english_first_name}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Western Character Last Name'
              hasFeedback
              validateStatus={state?.gatmoney_english_last_name ? 'success' : 'error'}
              required
              help={state?.gatmoney_english_last_name ? '' : 'Western Character Last Name is required'}
              rules={[
                {
                  message: 'Western Character Last Name is required',
                  validator: (_, value) => {
                    if (value.length > 0) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Western Character Last Name is required')
                  }
                }
              ]}
            >
              <Input id='gatmoney_english_last_name'
                value={state?.gatmoney_english_last_name}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Identification Type'
              hasFeedback
            >
              <Select
                value={state?.gatmoney_identification_type}
                onChange={(value) => {
                  setState({
                    ...state,
                    gatmoney_identification_type: value
                  })
                }}
              >
                <Option value='none'>None</Option>
                <Option value='drivers_license'>Drivers licence</Option>
                <Option value='passport'>Passport</Option>
                <Option value='national_id'>National ID</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='State / Province [must be 2 characters exactly]'
              hasFeedback
              validateStatus={state?.gatmoney_state && state?.gatmoney_state.length === 2 ? 'success' : 'error'}
              required
              help={state?.gatmoney_state && state?.gatmoney_state.length === 2 ? '' : 'State / Province Code must be 2 characters exactly'}
              rules={[
                {
                  message: 'State / Province Code must be 2 characters exactly',
                  validator: (_, value) => {
                    if (value.length === 2) {
                      return Promise.resolve()
                    }
                    return Promise.reject('State / Province Code must be 2 characters exactly')
                  }
                }
              ]}
            >
              <Input id='gatmoney_state'
                value={state?.gatmoney_state}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Post Code'
              hasFeedback
              validateStatus={state?.gatmoney_post_code ? 'success' : ''}
              required
            >
              <Input id='gatmoney_post_code'
                value={state?.gatmoney_post_code}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Country Code [must be 2 characters exactly]'
              hasFeedback
              validateStatus={state?.gatmoney_country_code && state?.gatmoney_country_code.length === 2 ? 'success' : 'error'}
              required
              help={state?.gatmoney_country_code && state?.gatmoney_country_code.length === 2 ? '' : 'Country Code must be 2 characters exactly'}
              rules={[
                {
                  message: 'Country Code must be 2 characters exactly',
                  validator: (_, value) => {
                    if (value.length === 2) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Country Code must be 2 characters exactly')
                  }
                }
              ]}
            >
              <Input id='gatmoney_country_code'
                value={state?.gatmoney_country_code}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Identification Number'
              hasFeedback
            >
              <Input id='gatmoney_identification_number'
                value={state?.gatmoney_identification_number}
                onChange={onTextboxChangeV2}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='Email'
              hasFeedback
              validateStatus={state?.gatmoney_email ? 'success' : ''}
              required
            >
              <Input id='gatmoney_email'
                value={state?.gatmoney_email}
                onChange={onTextboxChangeV2}
                disabled={gatMoneyAppDetails}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label='GAT Money Password'
              hasFeedback
              validateStatus={state?.gatmoney_password ? 'success' : ''}
              required
            >
              <Input id='gatmoney_password'
                value={state?.gatmoney_password}
                onChange={onTextboxChangeV2}
                disabled={gatMoneyAppDetails}
              />
            </Form.Item>
            <Row gutter={[24,0]}>
              {!gatMoneyAppDetails && <>
                <Col xs={12}>
                  <Button loading={submitting} type='primary' onClick={onAccountCreatedClick} disabled={
                    !state?.gatmoney_first_name ||
                        !state?.gatmoney_last_name ||
                        !state?.gatmoney_email ||
                        !state?.gatmoney_password ||
                        !state?.gatmoney_address ||
                        !state?.gatmoney_city ||
                        !state?.gatmoney_state ||
                        !state?.gatmoney_post_code ||
                        !state?.gatmoney_country_code ||
                        !state?.gatmoney_phone_no ||
                        !state?.gatmoney_dob
                  }>
                        Create GAT Money & CC
                  </Button>
                </Col>
              </>}
              {gatMoneyAppDetails && <>
                <Col xs={12}>
                  <Button loading={submitting} type='primary' onClick={onDataUpdateClick} disabled={
                    !state?.gatmoney_email ||
                    !state?.gatmoney_address ||
                    !state?.gatmoney_city ||
                    !validateCode(state?.gatmoney_state) ||
                    !state?.gatmoney_post_code ||
                    !validateCode(state?.gatmoney_country_code) ||
                    !state?.gatmoney_english_first_name ||
                    !state?.gatmoney_english_last_name ||
                    !state?.gatmoney_dob
                  }>
                      Update GAT Money & CC
                  </Button>
                </Col>
              </>}
            </Row>
          </Col>
        </Row>
      </Card>
    </Spin>
  )
}
const mapStateToProps = state => {
  const {
    currentAccountRecord,
    countriesList
  } = appStateSelectors.stateSelector(state)
  const {list, loading} = tradingSelectors.tradingAccountsSelector(state)
  const {clientRecord} = state.clients.entities

  return {
    currentAccountID: currentAccountRecord.id,
    list,
    loading,
    clientId: clientRecord.id,
    clientPreferredLanguage: clientRecord.preferred_language,
    countriesList
  }
}

export default connect(mapStateToProps)(GatMoney)