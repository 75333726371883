import React from 'react'
import AccountDetails from './AccountDetails'
import AccountTransaction from './AccountTransaction'
import useAccountDetail from '../hooks/useAccountDetail'

const Account = () => {
  const state = useAccountDetail()
  return (
    <div>
      <AccountDetails value={state.value} loading={state.loading} />
      <AccountTransaction type={state.value?.financial_account_type_id} />
    </div>
  )
}

export default Account