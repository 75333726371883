import React, {
  useState,
  useEffect,
  useRef
} from 'react'
import {connect} from 'react-redux'
import {
  useForm,
  useParams
} from 'hooks'

import {API} from 'utils'
import {clearState} from 'configs/actions/common'
import {clientsActions} from 'configs/ducks/clients'

import {appStateSelectors} from 'configs/ducks/appState'
import {
  createUseStyles,
  useTheme
} from 'react-jss'

import {
  Row,
  Col,
  Switch,
  Form,
  Button,
  notification
} from 'antd'

import AccountClosureManagement from './clientClosure_view/AccountClosureManagement'
import AccountClosureDetails from './clientClosure_view/AccountClosureDetails'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

const useStyles = createUseStyles(theme => ({
  label: {fontWeight: theme.label_font_weight},
  inputDisabled: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color
  }
}))

const ClientClosureViewPageComponent = ({
  loading,
  getClientClosureByID,
  currentAccountID,
  clearState,
  currentStaffRecord,
}) => {
  const theme = useTheme()
  const [form] = useForm()
  const ref = useRef(null)
  const classes = useStyles({theme})
  const [state, setState] = useState({
    clients: [],
    closureRecord: []
  })
  const [viewModeSwitchStatus, setViewModeSwitchStatus] = useState(true)

  const {closureRecordId} = useParams()
  const asyncFunction = async () => {
    try {
      const [clients, closureRecord] = await Promise.all([
        API.get('clients', `/getSimpleClosureListByAccountId/${currentAccountID}`),
        API.get('clients-acc-close',`/get-closure-by-id-v2/${closureRecordId}`)
      ])

      setState({
        ...state,
        clients: clients.payload,
        closureRecord: closureRecord.payload
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    asyncFunction()
    getClientClosureByID(closureRecordId)
    return clearState
  }, [])

  const getClientRecord = () => {
    getClientClosureByID(closureRecordId)
  }

  const toggleEdited = (viewModeSwitchStatus) => {
    setViewModeSwitchStatus(!viewModeSwitchStatus)
  }

  if (!state.closureRecord?.id > 0){
    return null
  }

  const onFinish = async values => {
    const {
      accountId,
      clientId,
      email,
      other,
      reason,
      statusId,
      staffNote
    } = values

    const dateRequested = values.dateRequested?.displayValue

    const savingObject = {
      accountId,
      clientId,
      dateRequested,
      email,
      other,
      reason,
      statusId,
      staffNote
    }

    await API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecordId}`, {body: savingObject})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        ref={ref}
        initialValues={state.closureRecord}
      >
        <Row>
          <Switch
            checkedChildren='Edit'
            unCheckedChildren='View'
            id='keyEnabled'
            checked={!viewModeSwitchStatus}
            onChange={
              editMode => toggleEdited(editMode)} />
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={12}>
            <AccountClosureDetails
              id={closureRecordId}
              records={state.closureRecord}
              currentAccountId={currentAccountID}
              editMode={viewModeSwitchStatus}
              {...state} />
          </Col>
          <Col xs={12}>
            <AccountClosureManagement
              classes={classes}
              {...state}
              records={state.closureRecord}
              currentAccountId={currentAccountID}
              staffId={currentStaffRecord.id}
              staffName={currentStaffRecord.file_by}
              currentStaffRecord={currentStaffRecord}
              clientClosureRecord={state.clients[0]}
              getClientRecord={getClientRecord}
              editMode = {viewModeSwitchStatus}
            />
          </Col>
        </Row>
        {!viewModeSwitchStatus &&
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
            >Save</Button>
        }
      </Form></>
  )
}

const {getClientByID, getClientClosureByID} = clientsActions

const mapStateToProps = state => {
  const {
    currentAccountRecord: {id: currentAccountID},
    currentStaffRecord,
  } = appStateSelectors.stateSelector(state)
  const {
    loading, clientRecord, clientClosureRecord
  } = state.clients.entities

  return {
    clientRecord,
    clientClosureRecord,
    currentStaffRecord,
    currentAccountID,
    loading: loading,
  }
}

const ClientViewPageComponentContainer = connect(mapStateToProps, {
  getClientByID,
  getClientClosureByID,
  clearState
})(ClientClosureViewPageComponent)

const ClientViewWrapper = (props) => {
  const {client_id} = useParams()
  return <ClientViewPageComponentContainer key={client_id} {...props} />
}

export default ClientViewWrapper
