import * as React from 'react'
import {
  Table,
  Button,
  Drawer
} from 'components'
import {FULL_DATE_TIME_FORMAT} from 'consts'
import {listHOC} from 'hocs'
import {
  useForm,
  useLocation,
  useHistory
} from 'hooks'
import dayjs from 'dayjs'
import {API} from 'utils'
import {routes} from 'configs'
import {ViewDetails} from './components'
import {
  DocWTemplate,
  ListHOCChildProps
} from 'types'

const TemplatesList: React.FC<ListHOCChildProps<DocWTemplate>> = ({
  getColumnSearchProps, entities, loading, updateState
}) => {
  const [form] = useForm()
  const {state: locationState, pathname} = useLocation()
  const history = useHistory()
  const [state, setState] = React.useState<{
    currentTemplate: DocWTemplate | null;
    submitting: boolean;
  }>({
    submitting: false,
    currentTemplate: null
  })

  React.useEffect(() => {
    const id = locationState && locationState.id && parseInt(locationState.id)
    const existingRecord = entities.find(item => item.id === id)

    if (existingRecord) {
      history.replace(pathname, {})
      setState(prevState => ({
        ...prevState,
        currentTemplate: existingRecord
      }))
    }
  }, [locationState, entities])

  const columns = [
    getColumnSearchProps({
      dataIndex: 'id',
      placeholder: 'ID',
      title: 'ID',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (id, record) => (
        <Button
          type='link'
          onClick={() => {
            setState(prevState => ({
              ...prevState,
              currentTemplate: record
            }))
          }}
        >
          {id}
        </Button>
      )
    }),
    getColumnSearchProps({
      title: 'title',
      dataIndex: 'title',
      placeholder: 'title'
    }),
    getColumnSearchProps({
      title: 'Created',
      dataIndex: 'createdAt',
      render: (_, record) => (
        dayjs(record.createdAt).format(FULL_DATE_TIME_FORMAT)
      )
    })
  ]

  return (
    <>
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        scroll={{x: true}}
        dataSource={entities}
        pagination={{
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true
        }}
      />
      <Drawer
        title='Template Details'
        open={Boolean(state.currentTemplate)}
        extra={
          <Button
            type='primary'
            loading={state.submitting}
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        }
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            currentTemplate: null
          }))
        }}
      >
        {state.currentTemplate && (
          <ViewDetails
            form={form}
            record={state.currentTemplate}
            onSuccess={record => {
              setState(prevState => ({
                ...prevState,
                submitting: false,
                currentTemplate: null
              }))

              updateState({
                entities: entities.map(template => {
                  if (template.id === record.id) {
                    return record
                  }

                  return template
                })
              })
            }}
            toggleSubmitting={(submitting) => {
              setState(prevState => ({
                ...prevState,
                submitting
              }))
            }}
          />
        )}
      </Drawer>
    </>
  )
}

export default listHOC(
  TemplatesList,
  ({rootState}) => API.get('accounts', `/${rootState.appState.currentAccountRecord.id}/docw/templates`)
)
