import React, {
  useState,
  useEffect
} from 'react'
import {
  Input,
  Button,
  Table
} from 'antd'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import {
  settingsActions,
  settingsSelectors
} from 'configs/ducks/settings'

const CurrenciesListPageComponentContainer = ({
  getCurrencyList,
  currencyList,
  loading
}) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  let searchInput = ''

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      )
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: text => <a>{text.toString().padStart(5, '0')}</a>
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      ...getColumnSearchProps('fullName')
    },
    {
      title: 'ISO Alpha 3',
      dataIndex: 'IsoAlpha3',
      ...getColumnSearchProps('IsoAlpha3')
    }
  ]

  useEffect(() => {
    getCurrencyList()
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  return (
    <Table
      columns={columns}
      dataSource={currencyList}
      loading={loading}
    />
  )
}

const mapStateToProps = state => {
  const {
    currencyList,
    loading
  } = settingsSelectors.stateSelector(state)
  return {
    currencyList,
    loading,
  }
}

const mapDispatchToProps = { getCurrencyList: settingsActions.getCurrencyList }

const CurrenciesListPageComponent = connect(mapStateToProps, mapDispatchToProps)(CurrenciesListPageComponentContainer)

export default CurrenciesListPageComponent
