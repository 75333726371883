import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {withRouter} from 'react-router-dom'

import {
  connect,
  useDispatch,
  useSelector
} from 'react-redux'

import {
  showNotification,
  API
} from 'utils'
import {
  FormSelect,
  CreateJournal
} from 'common'
import {inputChange} from 'configs/actions/common'

import {
  Row,
  Col,
  Form,
  Input,
  Descriptions,
  Card,
  InputSelect,
  InputDatePicker,
  Button,
  Modal,
  Typography,
  notification
} from 'components'

import {columnSize} from '../../common'

import {
  staffsSelectors,
  staffsActions
} from 'configs/ducks/staffs'

import {
  appStateActions,
  appStateSelectors
} from 'configs/ducks/appState'

import WatchClient from './WatchClient'
import moment from 'moment'
import {languages} from './utils'
import ReactCountryFlag from 'react-country-flag'

const { Paragraph } = Typography
const dateFormat = 'YYYY/MM/DD'

const ClientSummary = ({
  accountsList,
  clientStatusList,
  countriesList,
  clientTypeList,
  companyTypeList,
  clientCategoriesList,
  form,
  clientRecord,
  currentAccountId,
  staffId,
  clientId,
  backoffice,
  clientCanChangeEmail
}) => {
  const [rend, rerender] = useState(false)
  const dispatch = useDispatch()
  const staffList = useSelector(state => staffsSelectors.stateSelector(state).entities)
  const getOptionProps = ({file_by, email}) => ({
    children: file_by,
    email: email
  })
  const getCOptionProps = ({full_name}) => ({children: full_name})
  const [state, setState] = useState({
    reload: false,
    help: '',
    error: 'success',
    responsibleStaffMember: '',
    responsibleStaffMemberEmail: '',
    checkEmailResults: false,
    checkPlatformUsernameResults: false
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = bool => setIsModalVisible(bool)

  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false)
  const toggleChangeEmailModal = bool => setIsChangeEmailModalVisible(bool)
  const [originalEmail, setOriginalEmail] = useState(clientRecord ? clientRecord.email : '')
  const [changedEmail, setChangedEmail] = useState(clientRecord ? clientRecord.email : '')
  const [changedPlatformUsername, setChangedPlatformUsername] = useState(clientRecord ? clientRecord.platformUsername : '')
  const [submitting, setSubmitting] = useState(false)
  const [sentEmailButton, setSentEmailButton] = useState(false)

  useEffect(() => {
    if (currentAccountId) {
      dispatch(staffsActions.getStaffList(currentAccountId))
    }
    try {
      API.get('clients', `/white-space-by-id/${clientId}`).then(
        response => {
          if (response.payload?.checkEmailResults) {
            setState({
              ...state,
              checkEmailResults: true
            })
          }
          if (response.payload?.checkPlatformUsernameResults) {
            setState({
              ...state,
              checkPlatformUsernameResults: true
            })
          }
        }
      )
    } catch (e) {
      notification.error({message: e.message})
    }

    try {
      API.get('clients', `/carriage-return-by-id/${clientId}`).then(
        response => {
          if (response.payload?.checkEmailResults) {
            setState({
              ...state,
              checkEmailResults: true
            })
          }
          if (response.payload?.checkPlatformUsernameResults) {
            setState({
              ...state,
              checkPlatformUsernameResults: true
            })
          }
        }
      )
    } catch (e) {
      notification.error({message: e.message})
    }
  }, [currentAccountId, dispatch])

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }

  const onLanguageSelectChange = value => {
    setState({
      ...state,
      preferred_language: value
    })
  }

  const onChangeEmailButtonClicked = () => {
    toggleChangeEmailModal(true)
  }

  const sentCorrectEmailButtonClicked = () => {
    setState({
      ...state,
      sentCorrectEmailLoading: true
    })
    try {
      API.put('clients', `/simple-update/${clientRecord.id}`, {
        body: {
          'email': clientRecord.email?.trim(),
          'platformUsername': clientRecord.platformUsername?.trim()
        }
      })
      notification.success({message: 'Successfully updated!'})
      setState({
        ...state,
        sentCorrectEmailLoading: false,
        checkEmailResults: false,
        checkPlatformUsernameResults: false
      })
    } catch (error) {
      console.error('Err', error)
      notification.error({message: error.mesage})
      setState({
        ...state,
        sentCorrectEmailLoading: false
      })
    }

  }

  const onSendRequestEDDEmailButtonClicked = async () => {
    setSentEmailButton(true)
    await API.post('email', '/client/request-edd', {
      body: {
        data: {
          id: clientRecord.id,
          clientRecord: clientRecord
        },
      }
    })
      .then(response => {
        showNotification('success', 'Email', 'Email send to client [Request EDD Email] - Send Successful')
      })
      .catch(error => {
        console.log(error)
      })
    setSentEmailButton(false)
  }

  const onValuesChange = changed => {
    const [key, value] = Object.entries(changed)[0]
    inputChange(key, value)
    setState({
      ...state,
      [key]: value
    })
    if (changed?.changedEmail){
      form.setFieldsValue({email: changed.changedEmail})
      clientRecord['email'] = changed.changedEmail
      setChangedEmail(changed.changedEmail)
    }

    if (changed?.changedPlatformUsername){
      form.setFieldsValue({platformUsername: changed.changedPlatformUsername})
      clientRecord['platformUsername'] = changed.changedPlatformUsername
      setChangedPlatformUsername(changed.changedPlatformUsername)
    }

  }

  const onChangeEmailFinish = async values => {
    values = {
      ...values,
      client_id: clientRecord.id
    }

    try {
      setSubmitting(true)
      await API.put('clients', `/update-email/${clientRecord.id}`, {
        body: {
          email: changedEmail,
          platformUsername: changedPlatformUsername
        }
      })

      await API.post('email', '/interaction', {
        body: {
          interaction: `Client ID ${clientRecord.id} has changed email from ${originalEmail} to ${changedEmail}`,
          data: {
            client_id: clientRecord.id,
            email: originalEmail,
            changedEmail: changedEmail
          },
          result: 'portal record changed successfully'
        }
      })
        .then(() => {
  
        })
        .catch(error => {
          console.log(error)
        })

      setSubmitting(false)
      form.setFieldsValue({ email: changedEmail })
      form.setFieldsValue({ platformUsername: changedPlatformUsername })
      showNotification('success', 'Clients', 'Successfully Updated')
      toggleChangeEmailModal(false)
      setOriginalEmail(changedEmail)
    } catch (error) {
      showNotification('error', 'Clients', error.message)
      form.setFieldsValue({ email: clientRecord.email })
      form.setFieldsValue({ platformUsername: clientRecord.platformUsername })
    }
  }

  const sendResponsibilityEmail = async () => {

    try {
      await API.post('email', '/client-responsibility-notification', {
        body: {
          data: {
            clientId: clientRecord.id,
            responsibleStaffMemberEmail: state.responsibleStaffMemberEmail,
            responsibleStaffMember: state.responsibleStaffMember
          },
        }
      })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          throw error
        })
      showNotification('success', 'Email', 'Email send to responsible Staff Member - Send Successful')
    } catch (error) {
      showNotification('error', 'Emails', error.message)
    }
  }

  const onFinish = async values => {
    try {
      await API.post('journals', '/create', {
        body: {
          ...values,
          staffId
        }
      })
      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Journals', error.message)
    }
    toggleModal(false)
  }

  return (
    <Fragment>
      <Row gutter={[46, 24]}>
        <Col {...columnSize}>
          <Card title='Client' bordered={false}>
            <Form.Item
              label='File By'
              name='file_by'
              rules={[
                {
                  required: true,
                  message: 'File By is Required'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <Col {...columnSize}>
                <Form.Item
                  label='First Name'
                  name='first_name'
                  rules={[
                    {
                      required: true,
                      message: 'First name is required'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...columnSize}>
                <Form.Item label='Middle Name(s)' name='middle_name'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='Last Name'
              name='last_name'
              rules={[
                {
                  required: true,
                  message: 'Last name is required'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <Col {...columnSize}>
                <Form.Item label='Preferred First Name' name='preferred_first_name'>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...columnSize}>
                <Form.Item label='Preferred Last Name' name='preferred_last_name'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col {...columnSize}>
                <Form.Item label='English First Name' name='english_first_name'>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...columnSize}>
                <Form.Item label='English Last Name' name='english_last_name'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <Form.Item label='ABN' name='businessNumber'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {form.getFieldValue('client_type') === 3 && (
            <Card title='Trust (Individual Trustee)' bordered={false}>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <Form.Item
                    label='Trustee name'
                    name='trust_trusteeName'
                    rules={[
                      {
                        required: true,
                        message: 'Trustee name is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>
                  <Form.Item
                    label='Trust name'
                    name='trust_trustName'
                    rules={[
                      {
                        required: true,
                        message: 'Trust name is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <FormSelect
                    allowClear
                    label='Country of Establishment'
                    name='trustCountryId'
                    placeholder='Select Country'
                    optionValue='id'
                    getOptionProps={getCOptionProps}
                    options={countriesList}
                  />
                </Col>
                <Col {...columnSize}>
                  <InputDatePicker
                    name='trustEstablishmentDate'
                    label='date.trustEstablishment'
                    disabledDate={disabledDate}
                  />

                </Col>
              </Row>
            </Card>
          )}
          {form.getFieldValue('client_type') === 4 && (
            <Card title='Trust (Corporate Trustee)' bordered={false}>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <Form.Item
                    label='Trustee (company) name'
                    name='trust_trusteeName'
                    rules={[
                      {
                        required: true,
                        message: 'Trustee name is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>
                  <Form.Item
                    label='Trust name'
                    name='trust_trustName'
                    rules={[
                      {
                        required: true,
                        message: 'Trust name is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <FormSelect
                    allowClear
                    label='Country of Establishment'
                    name='trustCountryId'
                    placeholder='Select Country'
                    optionValue='id'
                    getOptionProps={getCOptionProps}
                    options={countriesList}
                  />
                </Col>
                <Col {...columnSize}>
                  <Form.Item
                    label='Company ACN'
                    name='companyNumber'
                    rules={[
                      {
                        required: true,
                        message: 'Company ACN is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>
                  <InputDatePicker
                    name='companyDateOfIncorporation'
                    label='date.incorporation'
                    disabledDate={disabledDate}
                  />
                </Col>
                <Col {...columnSize}>

                  <InputDatePicker
                    name='trustEstablishmentDate'
                    label='date.trustEstablishment'
                    disabledDate={disabledDate}
                  />
                </Col>
              </Row>
            </Card>
          )}
          {form.getFieldValue('client_type') === 5 && (
            <Card title='Trust (Bare Trust)' bordered={false}>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <Form.Item
                    label='Trustee name'
                    name='trust_trusteeName'
                    rules={[
                      {
                        required: true,
                        message: 'Trustee name is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>
                  <Form.Item
                    label='Trust name'
                    name='trust_trustName'
                    rules={[
                      {
                        required: true,
                        message: 'Trust name is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>
                  <InputDatePicker
                    name='trustEstablishmentDate'
                    label='date.trustEstablishment'
                    disabledDate={disabledDate}
                  />
                </Col>
              </Row>
            </Card>
          )}
          {form.getFieldValue('client_type') === 2 && (
            <Card title='Company Details' bordered={false}>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <Form.Item label='Company Name' name='companyName'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>
                  <FormSelect
                    allowClear
                    label='Company Type'
                    name='companyType'
                    placeholder='Select Company'
                    optionValue='id'
                    getOptionProps={getOptionProps}
                    options={companyTypeList}
                  />
                </Col>
              </Row>
              <Row gutter={[12, 0]}>
                <Col {...columnSize}>
                  <FormSelect
                    allowClear
                    label='Country of Incorporation'
                    name='companyCountryId'
                    placeholder='Select Country'
                    optionValue='id'
                    getOptionProps={getCOptionProps}
                    options={countriesList}
                  />
                </Col>
                <Col {...columnSize}>
                  <Form.Item label='Company Number' name='companyNumber'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...columnSize}>

                  <InputDatePicker
                    name='companyDateOfIncorporation'
                    label='date.incorporation'
                    disabledDate={disabledDate}
                  />
                </Col>
              </Row>

              {clientRecord.cli}
            </Card>
          )}
        </Col>

        <Col {...columnSize}>
          <Card title='Account Details' bordered={false}>
            <Row gutter={[12, 12]}>
              <Col xs={12}>
                <InputSelect
                  label='common.account'
                  name='account_id'
                  disabled
                  options={accountsList}
                  getOptionProps={opt => ({
                    value: opt.id,
                    children: opt.account_name
                  })}
                />
              </Col>
              <Col xs={12}>
                <WatchClient clientId={clientRecord.id} />
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col xs={12}>
                <FormSelect
                  label='Record Type'
                  name='categoryId'
                  placeholder='Select Category'
                  optionValue='id'
                  getOptionProps={getOptionProps}
                  options={clientCategoriesList}
                />
              </Col>
              <Col xs={12}>
                <FormSelect
                  required
                  label='Client Type'
                  name='client_type'
                  placeholder='Select Client'
                  optionValue='id'
                  getOptionProps={getOptionProps}
                  onClick={() => rerender(!rend)}
                  options={clientTypeList}
                />
              </Col>
            </Row>

            <Form.Item shouldUpdate>
              {({getFieldValue}) => (
                <Descriptions bordered layout='vertical' size='small'>
                  <Descriptions.Item label='E-mail'>
                    <Paragraph copyable>{getFieldValue('email')}</Paragraph>{' '}
                    {(backoffice || clientCanChangeEmail) && <Button loading={submitting} onClick={onChangeEmailButtonClicked}> Change Email </Button>}
                  </Descriptions.Item>
                  {
                    (state?.checkEmailResults || state?.checkPlatformUsernameResults) &&
                    <Descriptions.Item label='Warning: White Space Issue'>
                      <Button
                        loading={state?.sentCorrectEmailLoading}
                        onClick={sentCorrectEmailButtonClicked}
                      >
                        Click here to remove
                      </Button>
                    </Descriptions.Item>
                  }
                </Descriptions>
              )}
            </Form.Item>
            <Form.Item shouldUpdate>
              {({getFieldValue}) => (
                <Descriptions bordered layout='vertical' size='small'>
                  <Descriptions.Item label='Xeonda ID'>
                    <Paragraph copyable>{getFieldValue('xri')}</Paragraph>{' '}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Form.Item>
            <Row gutter={[12, 12]}>
              <Col xs={12}>
                {clientStatusList && (
                  <FormSelect
                    allowClear
                    label='Status ID'
                    name='status_id'
                    placeholder='Select Status'
                    optionValue='id'
                    getOptionProps={getOptionProps}
                    options={clientStatusList}
                    onChange={() => toggleModal(true)}
                  />
                )}
              </Col>
              <Col xs={12}>
                <FormSelect
                  allowClear
                  label='Staff Member'
                  name='responsibleStaffMember'
                  placeholder='Select Status'
                  optionValue='id'
                  getOptionProps={getOptionProps}
                  options={staffList}
                  onChange={(value, optionValue)=> {
                    setState({
                      ...state,
                      responsibleStaffMember: optionValue.children,
                      responsibleStaffMemberEmail: optionValue.email
                    })
                  }}
                />
                {state?.responsibleStaffMemberEmail && <a onClick={sendResponsibilityEmail}>{` Email notify ${state.responsibleStaffMemberEmail} of responsibility`}</a>}
              </Col>
              <Col xs={24} >
                
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col xs={12}>
                <Form.Item label='Record Created' name='createdDatetime'>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label='Record Updated' name='lastModifiedDatetime'>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col xs={12}>
                <FormSelect
                  label='Langauge'
                  name='preferred_language'
                  placeholder='Select Languages'
                  options={languages}
                  optionValue='value'
                  getOptionProps={({flag, text}) => ({
                    children: (
                      <>
                        <ReactCountryFlag
                          {...flag}
                          title={text}
                          style={{
                            fontSize: '1.5em',
                            lineHeight: '1.5em'
                          }}
                        />
                        {text}
                      </>
                    )
                  })}
                  filterOption={(input, option) => {
                    // console.log(option.children.props.children[1])
                    return option.children.props.children[1].toLowerCase().includes(input.toLowerCase()) || option.value.toLowerCase().includes(input.toLowerCase())
                  }}
                  onChange={onLanguageSelectChange}
                />
              </Col>
              <Col style={{textAlign: 'right'}}>
                <Form.Item label='Send Request EDD Email'>
                  <Button
                    loading={sentEmailButton}
                    onClick={onSendRequestEDDEmailButtonClicked}
                  >
                    Request EDD Email (ZH)
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        
      </Row>

      <CreateJournal
        clientId={clientId}
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onFinish={onFinish}
      />

      <Modal
        visible={isChangeEmailModalVisible}
        onCancel={() => toggleChangeEmailModal(false)}
        title='Change Email'
        footer={
          <Row gutter={[96, 24]}>
            <Col span={24} style={{textAlign: 'right'}}>
              <Button
                loading={submitting}
                type='primary'
                onClick={
                  onChangeEmailFinish
                }
                htmlType='submit'
                disabled={!clientRecord.email && !clientRecord.platformUsername}
              >
              Save Record
              </Button>
            </Col>
          </Row>
        }
      >
        <Form
          layout='vertical'
          onFinish={
            onChangeEmailFinish
          }
          onValuesChange={onValuesChange}
        >
          <Row gutter={[96, 24]}>
            <Col xs={24}>
              <Form.Item label='Client Email' name='changedEmail' initialValue={clientRecord.email}>
                <Input />
              </Form.Item>
              {
                clientRecord?.adminAccountTypeID === 2 &&
                <Form.Item label='Digital Money Username' name='changedPlatformUsername' initialValue={clientRecord.platformUsername}>
                  <Input />
                </Form.Item>
              }
            </Col>
          </Row>
        </Form>
      </Modal>
      
    </Fragment>
  )
}

// export default ClientSummary
const mapStateToProps = ({...state}) => {
  const {
    currentAccountRecord,
    currentStaffRecord,
    currentUserRecord: {id: currentUserID},
    staffAccess,
    accountsList
  } = appStateSelectors.stateSelector(state)
  const backoffice = appStateSelectors.stateSelector(state).currentUserRecord?.backoffice
  const clientCanChangeEmail = currentStaffRecord.security_client_canChangeEmail

  return {
    currentAccountRecord,
    currentStaffRecord,
    currentUserID,
    staffAccess,
    accountsList,
    backoffice,
    clientCanChangeEmail
  }
}

const {switchStaff, switchAccount} = appStateActions

const mapDispatchToProps = {
  switchStaff,
  switchAccount,
}

const DashboardHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(ClientSummary)

export default withRouter(DashboardHeaderContainer)
