import {APP_NAME} from 'consts'

export const moduleName = 'organiser'

export const FETCH_ORGANISER_REQUEST = `${APP_NAME}/${moduleName}/FETCH_ORGANISER_REQUEST`
export const FETCH_ORGANISER_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_ORGANISER_SUCCESS`
export const FETCH_ORGANISER_ERROR = `${APP_NAME}/${moduleName}/FETCH_ORGANISER_ERROR`

export const ORGANISER_BY_ID_REQUEST = `${APP_NAME}/${moduleName}/ORGANISER_BY_ID_REQUEST`
export const ORGANISER_BY_ID_SUCCESS = `${APP_NAME}/${moduleName}/ORGANISER_BY_ID_SUCCESS`
export const ORGANISER_BY_ID_ERROR = `${APP_NAME}/${moduleName}/ORGANISER_BY_ID_ERROR`

export const ORGANISER_BY_STAFF_ID_REQUEST = `${APP_NAME}/${moduleName}/ORGANISER_BY_STAFF_ID_REQUEST`
export const ORGANISER_BY_STAFF_ID_SUCCESS = `${APP_NAME}/${moduleName}/ORGANISER_BY_STAFF_ID_SUCCESS`
export const ORGANISER_BY_STAFF_ID_ERROR = `${APP_NAME}/${moduleName}/ORGANISER_BY_STAFF_ID_ERROR`

export const ORGANISER_BY_CLIENT_ID_REQUEST = `${APP_NAME}/${moduleName}/ORGANISER_BY_CLIENT_ID_REQUEST`
export const ORGANISER_BY_CLIENT_ID_SUCCESS = `${APP_NAME}/${moduleName}/ORGANISER_BY_CLIENT_ID_SUCCESS`
export const ORGANISER_BY_CLIENT_ID_ERROR = `${APP_NAME}/${moduleName}/ORGANISER_BY_CLIENT_ID_ERROR`