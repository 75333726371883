import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {API} from 'aws-amplify'
import {withRouter} from 'react-router-dom'
import {injectIntl} from 'react-intl'

import {
  connect,
  useDispatch
} from 'react-redux'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients/index'

import {
  Row,
  Col,
  Form,
  Card,
  Divider,
} from 'antd'

import {
  InputSelect,
  InputText,
  InputDatePicker,
  InputNumber
} from 'components'

import {InputEmail} from 'common'

import {staffsActions} from 'configs/ducks/staffs'

const dateFormat = 'YYYY-MM-DD'
const AccountClosureDetails = ({
  currentAccountId,
  duplicateEmail,
  records,
  intl,
  editMode
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [clientOptions, setClientOptions] = useState([])
  const [clientOptionsSelected, setSelected] = useState(false)
  
  const getClientOptions = async () => {

    // console.log('currentAccountId:', currentAccountId)
    if (currentAccountId) {
      try {
        await API.get('clients', `/selectList/account/${currentAccountId}`).then(
          response => {
            const optionArray = response.payload.map((obj) => {
              return {
                key: obj.file_by,
                id: obj.id,
                fileBy: `${obj.id} - ${obj.file_by} [${obj.account_user_id}] ${obj.email}`
              }
            })
            // console.log('optionArray:', optionArray)
            setClientOptions(optionArray)
          }
        )
      } catch (e) {
        console.log('error,', 'Failed to get selectList list for this account,', e.message)
      }
    }
  }
  useEffect(() => {
    if (currentAccountId) {
      dispatch(staffsActions.getStaffList(currentAccountId))
      getClientOptions(currentAccountId)
    }
  }, [currentAccountId, dispatch])

  useEffect(() => {
    if (form.isFieldTouched('email')) {
      form.validateFields()
    }
  }, [duplicateEmail])

  if (!records) {
    return null
  }

  if (!currentAccountId) {
    return null
  } else if (currentAccountId){
    records.accountId = currentAccountId
  }

  return (
    <Fragment>
      <Row gutter={[46, 24]}>
        <Col xs={18}>
          <Card title='Client Details'>

            <Divider orientation="left">Client Details</Divider>
            <Form.Item name='email'>
              <InputEmail required isDuplicate={duplicateEmail} disabled={editMode} />
            </Form.Item>
            <Form.Item name='clientId' label='Client Xeonda ID'>
              <InputNumber type={'number'} name="clientId" disabled/>
                
              <InputSelect
                label='common.client'
                name="clientId"
                disabled={editMode}
                options={
                  clientOptions
                }
                getOptionProps={(opt) => ({
                  children: opt.fileBy,
                  value: opt.id,
                })}
                required
                value={records.clientId}
                onChange={()=>{
                  setSelected(true)
                }}
              />
              {
                records.clientId == 0 && !clientOptionsSelected && <p style={{color:'red'}}> No client matched </p>
              }
            </Form.Item>
            <Form.Item name='accountId' label='Account ID'>
              <InputNumber type={'number'} name="accountId" disabled={true} />
            </Form.Item>
            <Divider orientation="left" style={{marginTop: 48}}>Client Request Details</Divider>
            <Form.Item label='Date Requested'>
              <InputDatePicker
                format={dateFormat}
                name='dateRequested'
                disabled={editMode}
              />
            </Form.Item>

            <InputSelect
              label='client.closure.reason'
              name="reason"
              disabled={editMode}
              options={[
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option1'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option1'}),
                },
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option2'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option2'}),
                },
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option3'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option3'}),
                },
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option4'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option4'}),
                },
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option5'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option5'}),
                },
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option6'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option6'}),
                },
                {
                  id: intl.formatMessage({id: 'account_closure_landing_page.option7'}),
                  fileBy: intl.formatMessage({id: 'account_closure_landing_page.option7'}),
                },
              ]}
              getOptionProps={(opt) => ({
                children: opt.fileBy,
                value: opt.id,
              })}
              required
              value={records.reason}
            />
            <InputText
              type='TextArea'
              label="common.other"
              name="other"
              disabled={editMode}
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

const {checkClientsEmail} = clientsActions

const {newClientSelector} = clientsSelectors

const mapStateToProps = state => {
  const {duplicateEmail} = newClientSelector(state)
  return {duplicateEmail}
}
export default connect(mapStateToProps, {checkClientsEmail})(withRouter(injectIntl(AccountClosureDetails)))
