import React, {
  Fragment,
  useState
} from 'react'
import {API} from 'aws-amplify'
import {FormattedMessage} from 'react-intl'

import {
  InputDatePicker,
  Row,
  Col,
  Select,
  Form,
  Input,
} from 'components'

import {
  filterOption,
  columnSize
} from '../../common'

const {Option} = Select

const ClientPersonal = ({
  classes, genderList, countriesList, clientRecord
}) => {

  const [state, setState] = useState({personal_nickname: null})

  const gender_id_options = genderList.map(d => (
    <Option key={d.id} value={d.id}>
      {d.file_by}
    </Option>
  ))

  const country_list_select_options = countriesList.map(({full_name, id}) => (
    <Option key={id} value={id}>
      {full_name}
    </Option>
  ))

  let currentStatusText
  switch (clientRecord.statusPersonal) {
    case 1: currentStatusText = 'COMPLETED'; break
    case 2: currentStatusText = 'COMPLETED'; break
    case 3: currentStatusText = 'COMPLETED'; break
  }

  // console.log(state.personal_nickname)
  try {
    if (!state.personal_nickname && !state.is_loaded){
      API.get('clients',`/get-by-id/${clientRecord.id}`).then(
        res => {
          // console.log(res.payload)
          setState(
            {
              personal_nickname: res.payload.personal_nickname,
              is_loaded: true,
              deletedFlag: res.payload?.deletedFlag
            }
          )
          return res.payload.personal_nickname
        }
      )
    }
    // console.log(state)
    // console.log(clientRecord)
  } catch (e) {
    console.log(e)
  }

  return (
    <Fragment>
      <Row gutter={[96, 24]}>
        <Col {...columnSize}>
          <Form.Item
            label={<FormattedMessage id='data_clients.gender_id' defaultMessage='Gender' />}
            className={classes.form_label}
            name='gender_id'
          >
            <Select
              showSearch
              showArrow
              placeholder='Set Gender'
              optionFilterProp='children'
              filterOption={filterOption}
            >
              {gender_id_options}
            </Select>
          </Form.Item>
        </Col>
        <Col {...columnSize}>
          <InputDatePicker
            name='date_of_birth'
            label='common.dateOfBirth'
            isAfterToday
          />
        </Col>
      </Row>

      <Row gutter={[96, 24]}>
        <Col {...columnSize}>
          <Form.Item
            label={<FormattedMessage id='data_clients.place_of_birth_city' defaultMessage='Place of Birth City' />}
            className={classes.form_label}
            name='place_of_birth_city'
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...columnSize}>
          <Form.Item
            label={
              <FormattedMessage id='data_clients.place_of_birth_country_id' defaultMessage='Place of Birth Country' />
            }
            className={classes.form_label}
            name='place_of_birth_country_id'
          >
            <Select
              showSearch
              placeholder='Select your country'
              optionFilterProp='children'
              filterOption={filterOption}
            >
              {country_list_select_options}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  )
}

export default ClientPersonal
