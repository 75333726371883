import reducer from './reducers'

import * as teamsSelectors from './selectors'
import * as teamsActions from './actions'
import * as teamsTypes from './types'
import teamsSaga from './sagas'

export {
  teamsSaga,
  teamsTypes,
  teamsActions,
  teamsSelectors,
}

export default reducer