import {APP_NAME} from 'consts'

export const moduleName = 'clients'

export const FETCH_CLIENTS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_REQUEST`
export const FETCH_CLIENTS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_SUCCESS`
export const FETCH_CLIENTS_ERROR = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_ERROR`

export const FETCH_CLIENTS_CLOSURE_REQUEST = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_CLOSURE_REQUEST`
export const FETCH_CLIENTS_CLOSURE_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_CLOSURE_SUCCESS`
export const FETCH_CLIENTS_CLOSURE_ERROR = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_CLOSURE_ERROR`

export const GET_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_CLIENT_REQUEST`
export const GET_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_CLIENT_SUCCESS`
export const GET_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_CLIENT_ERROR`

export const GET_CLIENT_CLOSURE_REQUEST = `${APP_NAME}/${moduleName}/GET_CLIENT_CLOSURE_REQUEST`
export const GET_CLIENT_CLOSURE_SUCCESS = `${APP_NAME}/${moduleName}/GET_CLIENT_CLOSURE_SUCCESS`
export const GET_CLIENT_CLOSURE_ERROR = `${APP_NAME}/${moduleName}/GET_CLIENT_CLOSURE_ERROR`

export const SAVE_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/SAVE_CLIENT_REQUEST`
export const SAVE_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_CLIENT_SUCCESS`
export const SAVE_CLIENT_ERROR = `${APP_NAME}/${moduleName}/SAVE_CLIENT_ERROR`

export const CREATE_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/CREATE_CLIENT_REQUEST`
export const CREATE_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_CLIENT_SUCCESS`
export const CREATE_CLIENT_ERROR = `${APP_NAME}/${moduleName}/CREATE_CLIENT_ERROR`

export const GET_DOCUMENTS_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_REQUEST`
export const GET_DOCUMENTS_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_SUCCESS`

export const GET_DOCUMENT_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCUMENT_REQUEST`
export const GET_DOCUMENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCUMENT_SUCCESS`
export const GET_DOCUMENT_ERROR = `${APP_NAME}/${moduleName}/GET_DOCUMENT_ERROR`

export const CREATE_NEW_DOCUMENT_REQUEST = `${APP_NAME}/${moduleName}/CREATE_NEW_DOCUMENT_REQUEST`
export const CREATE_NEW_DOCUMENT_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_NEW_DOCUMENT_SUCCESS`
export const CREATE_NEW_DOCUMENT_ERROR = `${APP_NAME}/${moduleName}/CREATE_NEW_DOCUMENT_ERROR`

export const CHECK_CLIENTS_EMAIL_REQUEST = `${APP_NAME}/${moduleName}/CHECK_CLIENTS_EMAIL_REQUEST`
export const CHECK_CLIENTS_EMAIL_SUCCESS = `${APP_NAME}/${moduleName}/CHECK_CLIENTS_EMAIL_SUCCESS`
export const CHECK_CLIENTS_EMAIL_ERROR = `${APP_NAME}/${moduleName}/CHECK_CLIENTS_EMAIL_ERROR`

export const GET_VERIFICATIONS_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_LIST_REQUEST`
export const GET_VERIFICATIONS_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_LIST_SUCCESS`
export const GET_VERIFICATIONS_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_LIST_ERROR`

export const DELETE_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/DELETE_CLIENT_REQUEST`
export const DELETE_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/DELETE_CLIENT_SUCCESS`
export const DELETE_CLIENT_ERROR = `${APP_NAME}/${moduleName}/DELETE_CLIENT_ERROR`

export const GET_CLIENT_TASKS_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_CLIENT_TASKS_LIST_REQUEST`
export const GET_CLIENT_TASKS_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_CLIENT_TASKS_LIST_SUCCESS`
export const GET_CLIENT_TASKS_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_CLIENT_TASKS_LIST_ERROR`

export const GET_CLIENT_TASK_REQUEST = `${APP_NAME}/${moduleName}/GET_CLIENT_TASK_REQUEST`
export const GET_CLIENT_TASK_SUCCESS = `${APP_NAME}/${moduleName}/GET_CLIENT_TASK_SUCCESS`
export const GET_CLIENT_TASK_ERROR = `${APP_NAME}/${moduleName}/GET_CLIENT_TASK_ERROR`

export const DIGIDOC_CREATE_REQUEST = `${APP_NAME}/${moduleName}/DIGIDOC_CREATE_REQUEST`
export const DIGIDOC_CREATE_SUCCESS = `${APP_NAME}/${moduleName}/DIGIDOC_CREATE_SUCCESS`
export const DIGIDOC_CREATE_ERROR = `${APP_NAME}/${moduleName}/DIGIDOC_CREATE_ERROR`

export const DIGIDOC_SEND_REQUEST = `${APP_NAME}/${moduleName}/DIGIDOC_SEND_REQUEST`
export const DIGIDOC_SEND_SUCCESS = `${APP_NAME}/${moduleName}/DIGIDOC_SEND_SUCCESS`
export const DIGIDOC_SEND_ERROR = `${APP_NAME}/${moduleName}/DIGIDOC_SEND_ERROR`

export const FINANCIAL_ACCOUNT_SEND_REQUEST = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_SEND_REQUEST`
export const FINANCIAL_ACCOUNT_SEND_SUCCESS = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_SEND_SUCCESS`
export const FINANCIAL_ACCOUNT_SEND_ERROR = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_SEND_ERROR`

export const FINANCIAL_ACCOUNT_UPDATE_SUCCESS = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_UPDATE_SUCCESS`

export const GET_JOURNAL_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_CLIENT_REQUEST`
export const GET_JOURNAL_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_CLIENT_SUCCESS`
export const GET_JOURNAL_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_CLIENT_ERROR`

export const GET_INFORMATIONREQUEST_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_INFORMATIONREQUEST_BY_CLIENT_REQUEST`
export const GET_INFORMATIONREQUEST_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_INFORMATIONREQUEST_BY_CLIENT_SUCCESS`
export const GET_INFORMATIONREQUEST_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_INFORMATIONREQUEST_BY_CLIENT_ERROR`

export const GET_CLIENT_TASKS_BY_CLIENT_ID_REQUEST = `${APP_NAME}/${moduleName}/GET_CLIENT_TASKS_BY_CLIENT_ID_REQUEST`
export const GET_CLIENT_TASKS_BY_CLIENT_ID_SUCCESS = `${APP_NAME}/${moduleName}/GET_CLIENT_TASKS_BY_CLIENT_ID_SUCCESS`
export const GET_CLIENT_TASKS_BY_CLIENT_ID_ERROR = `${APP_NAME}/${moduleName}/GET_CLIENT_TASKS_BY_CLIENT_ID_ERROR`

export const GET_OFFICEHOLDER_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_OFFICEHOLDER_BY_CLIENT_REQUEST`
export const GET_OFFICEHOLDER_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_OFFICEHOLDER_BY_CLIENT_SUCCESS`
export const GET_OFFICEHOLDER_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_OFFICEHOLDER_BY_CLIENT_ERROR`

export const GET_SHAREHOLDER_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_SHAREHOLDER_BY_CLIENT_REQUEST`
export const GET_SHAREHOLDER_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_SHAREHOLDER_BY_CLIENT_SUCCESS`
export const GET_SHAREHOLDER_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_SHAREHOLDER_BY_CLIENT_ERROR`

export const GET_APPOINTOR_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_APPOINTOR_BY_CLIENT_REQUEST`
export const GET_APPOINTOR_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_APPOINTOR_BY_CLIENT_SUCCESS`
export const GET_APPOINTOR_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_APPOINTOR_BY_CLIENT_ERROR`

export const GET_BENEFICIARY_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_BENEFICIARY_BY_CLIENT_REQUEST`
export const GET_BENEFICIARY_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_BENEFICIARY_BY_CLIENT_SUCCESS`
export const GET_BENEFICIARY_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_BENEFICIARY_BY_CLIENT_ERROR`

export const GET_SOURCEOFWEALTH_BY_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/GET_SOURCEOFWEALTH_BY_ACCOUNT_REQUEST`
export const GET_SOURCEOFWEALTH_BY_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/GET_SOURCEOFWEALTH_BY_ACCOUNT_SUCCESS`
export const GET_SOURCEOFWEALTH_BY_ACCOUNT_ERROR = `${APP_NAME}/${moduleName}/GET_SOURCEOFWEALTH_BY_ACCOUNT_ERROR`
