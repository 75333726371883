import {APP_NAME} from 'consts'

export const moduleName = 'documents'

export const GET_DOCUMENTS_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_REQUEST`
export const GET_DOCUMENTS_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_SUCCESS`
export const GET_DOCUMENTS_ERROR = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_ERROR`

export const FILE_UPLOAD_SUCCESS = `${APP_NAME}/${moduleName}/FILE_UPLOAD_SUCCESS`

export const GET_DOCTYPE_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCTYPE_LIST_REQUEST`
export const GET_DOCTYPE_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCTYPE_LIST_SUCCESS`
export const GET_DOCTYPE_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_DOCTYPE_LIST_ERROR`

export const GET_DIGISIGNTEMPLATES_REQUEST = `${APP_NAME}/${moduleName}/GET_DIGISIGNTEMPLATES_REQUEST`
export const GET_DIGISIGNTEMPLATES_SUCCESS = `${APP_NAME}/${moduleName}/GET_DIGISIGNTEMPLATES_SUCCESS`
export const GET_DIGISIGNTEMPLATES_ERROR = `${APP_NAME}/${moduleName}/GET_DIGISIGNTEMPLATES_ERROR`

export const GET_DOCW_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCW_REQUEST`
export const GET_DOCW_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCW_SUCCESS`
export const GET_DOCW_ERROR = `${APP_NAME}/${moduleName}/GET_DOCW_ERROR`

export const GET_DOCW_BY_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCW_BY_ACCOUNT_REQUEST`
export const GET_DOCW_BY_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCW_BY_ACCOUNT_SUCCESS`
export const GET_DOCW_BY_ACCOUNT_ERROR = `${APP_NAME}/${moduleName}/GET_DOCW_BY_ACCOUNT_ERROR`

export const GET_EMAILCUSTOMTEMPLATES_REQUEST = `${APP_NAME}/${moduleName}/GET_EMAILCUSTOMTEMPLATES_REQUEST`
export const GET_EMAILCUSTOMTEMPLATES_SUCCESS = `${APP_NAME}/${moduleName}/GET_EMAILCUSTOMTEMPLATES_SUCCESS`
export const GET_EMAILCUSTOMTEMPLATES_ERROR = `${APP_NAME}/${moduleName}/GET_EMAILCUSTOMTEMPLATES_ERROR`

export const GET_EMAILCUSTOMTEMPLATE_REQUEST = `${APP_NAME}/${moduleName}/GET_EMAILCUSTOMTEMPLATE_REQUEST`
export const GET_EMAILCUSTOMTEMPLATE_SUCCESS = `${APP_NAME}/${moduleName}/GET_EMAILCUSTOMTEMPLATE_SUCCESS`
export const GET_EMAILCUSTOMTEMPLATE_ERROR = `${APP_NAME}/${moduleName}/GET_EMAILCUSTOMTEMPLATE_ERROR`

export const SAVE_EMAILCUSTOMTEMPLATE_REQUEST = `${APP_NAME}/${moduleName}/SAVE_EMAILCUSTOMTEMPLATE_REQUEST`
export const SAVE_EMAILCUSTOMTEMPLATE_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_EMAILCUSTOMTEMPLATE_SUCCESS`
export const SAVE_EMAILCUSTOMTEMPLATE_ERROR = `${APP_NAME}/${moduleName}/SAVE_EMAILCUSTOMTEMPLATE_ERROR`

