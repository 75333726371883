import React, { useEffect, useCallback, useState } from 'react'
import { Switch, Form, notification } from 'antd'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'

const WatchClient = ({ clientId }) => {
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(true)
  const staffId = useSelector(state => state.appState.currentStaffRecord.id)

  const handleChange = useCallback(async (checked) => {
    setLoading(true)
    try {
      await API.post('watchlist-client', '/set', {
        body: {
          staffId,
          clientId
        }
      })
      setChecked(checked)
    } catch (error) {
      notification.error({message: error.message})
    } finally {
      setLoading(false)
    }
  }, [staffId, clientId])

  useEffect(() => {
    async function fetchData() {
      if (clientId && staffId) {
        const res = await API.get('watchlist-client', `/get-staff-client/${staffId}/${clientId}`)
        setChecked(res.payload)
        setLoading(false)
      }
    }
    fetchData()
  }, [clientId, staffId])

  return (
    <Form.Item label='Watch Client'>
      <Switch loading={loading} onChange={handleChange} checked={checked} />
      {checked && (<span style={{paddingLeft: 12}}>You are watching this client</span>)}
    </Form.Item>
  )
}

export default React.memo(WatchClient)
