import * as React from 'react'
import {useSelector} from 'hooks'
import {
  Button,
  Space,
  notification
} from 'components'
import {constants} from 'pages/dashboard/clients/duck'
import {API} from 'utils'
import {TabsHeaderProps} from '../../types'
import {
  Staff,
  StatusIndicator,
} from 'types'

type StatusIndicatorID = StatusIndicator['id']

interface StatusIndicatorButtons extends Omit<TabsHeaderProps, 'clientRecord'> {
  clientID: number;
  clientDeleted: boolean;
}

const StatusIndicatorButtons: React.FC<StatusIndicatorButtons> = (
  {
    activeTab,
    clientID,
    clientStatusIndicator,
    updateRecord,
    clientDeleted,
  }
) => {
  const [loading, toggleLoading] = React.useState<StatusIndicatorID | null>()
  const {statusIndicators, currentStaffRecord} = useSelector(state => ({
    statusIndicators: state.appState.statusIndicators,
    currentStaffRecord: state.appState.currentStaffRecord as Staff,
  }))

  if (clientDeleted) {
    return null
  }

  const onButtonClick = async (id: StatusIndicatorID) => {
    try {
      toggleLoading(id)

      const record = await API.patch('clients', `/${clientID}/status-indicators/${clientStatusIndicator.id}`, {
        body: {
          statusIndicatorID: id,
          staffID: currentStaffRecord.id,
          name: activeTab
        }
      })

      toggleLoading(null)
      updateRecord(record)
    } catch (e: any) {
      notification.error({
        message: 'Status Indicators',
        description: e.message
      })

      toggleLoading(null)
    }
  }
  
  return (
    <Space align='end'>
      {statusIndicators.map(({id, title}) => (
        <Button
          key={id}
          loading={loading === id}
          onClick={() => {
            onButtonClick(id)
          }}
        >
          {constants.STATUS_INDICATOR_ICONS.get(id)} {title}
        </Button>
      ))}
    </Space>
  )
}

export default StatusIndicatorButtons
