import * as types from './types'

/**
 * @param {number} id
 * @param {number} account_id
 * @param {number} user_id
 * @returns {{payload: *, type: string}}
 */
export const switchStaff = (id, account_id, user_id) => ({
  type: types.SWITCH_STAFF_REQUEST,
  payload: {
    id,
    account_id,
    user_id
  }
})

/**
 * @param {number} accountID
 * @param {number} userID
 * @returns {{payload: *, type: string}}
 */
export const switchAccount = (accountID, userID) => ({
  type: types.SWITCH_ACCOUNT_REQUEST,
  payload: {
    accountID,
    userID
  }
})

export const authenticateUser = () => ({type: types.AUTHENTICATE_USER_REQUEST})
export const login = (email, password) => ({
  type: types.LOGIN_USER_REQUEST,
  payload: {
    email,
    password
  }
})
/**
 * @returns {{type: string}}
 */
export const logOutUser = () => ({type: types.LOG_OUT_USER_REQUEST})

export const getUserGeoData = () => ({type: types.GET_CURRENT_COUNTRY_REQUEST})
