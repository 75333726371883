import React, {
  Fragment,
  useState
} from 'react'

import {
  showNotification,
  API
} from 'utils'
import {ModalView} from '../../../../common/ModalView'
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  InputDatePicker,
  Select
} from 'components'
import {useForm} from 'antd/lib/form/Form'
import PhoneInput from 'react-phone-input-2'
import {PREFERRED_COUNTRIES} from 'consts'

const { Option } = Select

export const ClientCreateNewAppointorComponent = ({
  isModalVisible,
  toggleModal,
  clientId,
  asyncFunction,
  getData,
  countriesList,
  streetCodes
}) => {

  const left = [
    {
      label: 'Telephone',
      name: 'telephone'
    }
  ]

  const [form] = useForm()
  const [telephone, setTelephone] = useState('')

  const onFinish = async values => {
    try {
      await API.post('client-trust', '/create-appointors', {
        body: {
          ...values,
          telephone,
          clientId
        }
      })
      showNotification('success', 'Appointors', 'Successfully Created')
      getData()
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Appointors', error.message)
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  const onPhoneInputChange = () => value => {
    setTelephone(value)
  }

  return (
    <ModalView
      title={'Create New Appointors'}
      isModalVisible={isModalVisible}
      footer={null}
      handleOk={() => toggleModal(!isModalVisible)}
      handleCancel={() => toggleModal(!isModalVisible)}
      body={
        <Fragment>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              firstName: '',
              middleNames: '',
              lastName: '',
              email: '',
              telephone: '',
              address: ''
            }}
          >
            <Form.Item
              name='firstName'
              label='First Name'
              rules={[
                {
                  required: true,
                  message: 'First Name is Required'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='middleNames'
              label='Middle names'
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='lastName'
              label='Last name'
              rules={[
                {
                  required: true,
                  message: 'Last Name is Required'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <InputDatePicker
              name='date_of_birth'
              label='common.dateOfBirth'
              isAfterToday
            />
            <Form.Item name='email' label='Email'>
              <Input />
            </Form.Item>
            <Form.Item name='telephone'>
              {left.map(props => (
                <Form.Item key={props.label} {...props}>
                  <PhoneInput
                    inputStyle={{width: '100%'}}
                    preferredCountries={PREFERRED_COUNTRIES}
                    masks={{au: '(.) ....-....'}}
                    enableSearch
                    disableCountryGuess={false}
                    onChange={onPhoneInputChange(props.name)}
                  />
                </Form.Item>
              ))}
            </Form.Item>

            <Form.Item name='address' label='Address'>
              <Input />
            </Form.Item>
            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item name='streetAddressUnitNumber' key='Unit Number' label='Unit Number' shouldUpdate>
                  <Input/>
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item name='streetAddressStreetNumber' key='Street Number' label='Street Number' shouldUpdate>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item name='streetAddressStreetName' key='Street name' label='Street name' shouldUpdate>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item name='streetAddressStreetTypeId' key='Street Type' label='Street Type' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Street'
                    optionFilterProp='children'
                    onChange={(value) => {
                      form.setFieldsValue({ 'streetAddressStreetTypeId': value })
                    } }
                  >
                    {
                      streetCodes.streetType &&
                          streetCodes.streetType.map(x => (
                            <Option key={x.id} value={x.id}>
                              {x.fileBy}
                            </Option>
                          ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item name='streetAddressState' key='State' label='State' shouldUpdate>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
                  <Input/>
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Country'
                    optionFilterProp='children'
                    onChange={(value) => {
                      form.setFieldsValue({ 'streetAddressCountryId': value })
                    } }
                  >
                    {
                      countriesList.map(({ id, full_name }) => (
                        <Option key={id} value={id}>
                          {full_name}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row justify='end'>
              <Space>
                <Button htmlType='submit' type='primary'>
                  Save
                </Button>
              </Space>
            </Row>
          </Form>
        </Fragment>
      }
    />
  )
}
