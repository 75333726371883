import * as React from 'react'
import {
  SubmitButton,
  Form,
  InputText,
  Modal,
} from 'components'

import {
  useSelector,
  useForm
} from 'hooks'
import {
  showNotification,
  API
} from 'utils'
import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {
  Staff,
  InformationRequestJournal
} from 'types'

interface CreateJournalModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (newJournal: InformationRequestJournal) => void;
  informationRequestID: string | number;
}

const CreateJournalModal: React.FC<CreateJournalModalProps> = ({
  open,
  onClose,
  onSuccess,
  informationRequestID,
}) => {
  const [form] = useForm()
  const [state, setState] = React.useState({
    editorState: EditorState.createEmpty(),
    submitting: false
  })

  const onEditorStateChange = (editor: EditorState) => {
    setState(prevState => ({
      ...prevState,
      editorState: editor,
    }))
  }

  const currentStaffID = useSelector(state => (state.appState.currentStaffRecord as Staff).id)

  const onFinish = async (values: any) => {
    try {
      setState(prevState => ({
        ...prevState,
        submitting: true
      }))

      const {payload} = await API.post('information-requests', `/create-journals/${informationRequestID}`, {
        body: {
          ...values,
          memo: draftToHtml(convertToRaw(state.editorState.getCurrentContent())),
          staffId: currentStaffID
        }
      })

      setState(prevState => ({
        ...prevState,
        submitting: false,
        editorState: EditorState.createEmpty()
      }))

      onSuccess(payload)

      showNotification('success', 'Journals', 'Successfully Created')
    } catch (e: any) {
      setState(prevState => ({
        ...prevState,
        submitting: false,
      }))
      showNotification('error', 'Journals', e.response?.data?.error || e.message)
    }
  }

  return (
    <Modal
      title='Create Journal'
      open={open}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        name='journal'
        layout='vertical'
        onFinish={onFinish}
        preserve={false}
      >
        <InputText
          required
          name='description'
          label='common.description'
        />
        <Form.Item
          name='memo'
          label='Memo'
          rules={[
            {
              required: true,
              message: 'Memo is Required'
            }
          ]}
        >
          <Editor
            editorState={state.editorState}
            onEditorStateChange={onEditorStateChange}
            editorStyle={{
              border: '1px solid #F1F1F1',
              borderRadius: '2px',
              paddingLeft: '10px',
              paddingRight: '10px',
              height: '250px'
            }}
          />
        </Form.Item>
        <SubmitButton loading={state.submitting} />
      </Form>
    </Modal>
  )
}

export default CreateJournalModal
