import React, {Component} from 'react'
import { API } from 'aws-amplify'

import {
  Row,
  Col,
  Select,
  Button,
  notification,
  Form,
  Input,
  Switch
} from 'antd'

const {Option} = Select

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class TeamSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {is_saving: false}
  }

  async componentDidMount() {
    try {
      const team = await this.getTeamRecord(this.props.id)
      this.setState({...team.payload})
    } catch (e) {
      console.log(e)
    }
  }

  async getTeamRecord(teamID) {
    return API.get('teams', `/id/${teamID}`)
  }

  async saveTeamRecord(team_record) {
    await API.patch('teams', `/id/${this.state.id}`, {body: team_record})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onHandleChangeAccount = value => {
    console.log(value)
    this.setState({'accountID': value})
  }

  onHandleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id, teamName, accountID, active
    } = this.state
    try {
      await this.saveTeamRecord({
        id,
        teamName,
        accountID,
        active
      })
      this.setState({is_saving: false})
    } catch (e) {
      console.log(e)
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form layout='vertical' onSubmit={this.onHandleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label='ID'>
                <Input
                  id='id'
                  value={this.state.id || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Team'>
                <Input
                  id='teamName'
                  value={this.state.teamName || ''}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Account'>
                {/*<Input
                  id='account'
                  value={this.state.accountID || ''}
                  disabled
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />*/}
                <Select
                  onChange={this.onHandleChangeAccount}
                  defaultValue={this.state.accountID || ''}
                  value={this.state.accountID || ''}
                >
                  {
                    this.props.accountsList.map(({ id, file_by }) => (
                      <Option key={id} value={id}>
                        {file_by}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item label='Active'>
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  id='active'
                  checked={this.state.active}
                  onChange={event => {
                    return this.setState(
                      {
                        ...this.state,
                        active: event,
                      }
                    )
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit' onClick={this.onHandleSubmit}>
            Save
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

export default TeamSummary
