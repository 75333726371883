import React from 'react'

import {
  Col,
  Row,
  Form,
  Button,
  InputDatePicker,
  InputText,
  Drawer,
  notification,
} from 'components'
import { API } from 'aws-amplify'
import {listHOC} from 'common'
import {
  ROW_GUTTER,
  COMMON_COL_PROPS
} from 'consts'

export const ClientAuthPersonsDrawerComponent = listHOC(({
  visible,
  authPersonsDrawerData,
  toggleModal,
  asyncFunction,
  getData,
}) => {
  const [form] = Form.useForm()

  const saveRecord = async (values) => {
    try {
      await API.put('clients', `/update-authpersons/${authPersonsDrawerData?.id}`, {body: values})

      notification.success({message: 'Saved !'})
      getData()
    } catch (error) {
      console.error('error occured', error)
      notification.error({message: error.message})
    }
    toggleModal(false)
    
    if (asyncFunction) {
      asyncFunction()
    }
  }

  console.log(authPersonsDrawerData)
  return (
    <Drawer
      title='Client Auth Person Detail'
      width={720}
      onClose={() => toggleModal(false)}
      open={visible}
      bodyStyle={{paddingBottom: 80}}
      mask={true}
      maskClosable={true}
      destroyOnClose
    >
      <Form
        form={form}
        preserve={false}
        onFinish={saveRecord}
        initialValues={authPersonsDrawerData}
        scrollToFirstError={true}
        layout='vertical'
      >
        <Row gutter={ROW_GUTTER}>
          <Col {...COMMON_COL_PROPS}>
            <InputText
              name='fileBy'
              label='common.name'
            />
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <InputText
              name='email'
              label='common.email'
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col {...COMMON_COL_PROPS}>
            <InputText
              name='telephone'
              label='common.telephone'
            />
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <InputText
              name='address'
              label='common.address'
            />
          </Col>
        </Row>
        <InputDatePicker
          name='dateOfBirth'
          label='common.dateOfBirth'
          isAfterToday
        />
        <Button htmlType='submit' type='primary'> Save </Button>
      </Form>
    </Drawer>
  )
})