export const ENDPOINTS = [
  {
    name: 'leads-new',
    endpoint: 'https://8f405cobgc.execute-api.us-east-1.amazonaws.com/dev/leads-new',
  },
  {
    name: 'tasksClient',
    endpoint: 'https://n1q10rcahh.execute-api.us-east-1.amazonaws.com/dev/tasksClient',
    // endpoint: 'http://localhost:3000/dev/tasksClient',
  },
  {
    name: 'organiser',
    endpoint: 'https://azye52l54l.execute-api.us-east-1.amazonaws.com/dev/organiserTasks',
    // endpoint: 'http://localhost:3000/dev/organiserTasks',
  },
  {
    name: 'accounts',
    endpoint: 'https://493drhjxpe.execute-api.us-east-1.amazonaws.com/dev/accounts',
    // endpoint: 'http://localhost:3000/dev/accounts',
  },
  {
    name: 'accounts-type-1',
    endpoint: 'https://799ul53i6k.execute-api.us-east-1.amazonaws.com/dev/acc1',

  },
  {
    name: 'accounts-type-2',
    endpoint: 'https://pag9ecg4v1.execute-api.us-east-1.amazonaws.com/dev/acc2',

  },
  {
    name: 'account-financial-accounts',
    endpoint: 'https://aql8zpk04a.execute-api.us-east-1.amazonaws.com/dev/account-financial-accounts',
  },
  {
    name: 'clients',
    endpoint: 'https://spslwzscu3.execute-api.us-east-1.amazonaws.com/dev/clients',
    // endpoint: 'http://localhost:4000/dev/clients',
  },
  {
    name: 'system',
    endpoint: 'https://daflxekodd.execute-api.us-east-1.amazonaws.com/dev/system',
    // endpoint: 'http://localhost:4001/dev/system',
  },
  {
    name: 'clients-acc-close',
    endpoint: 'https://pwa707dm45.execute-api.us-east-1.amazonaws.com/dev/clients-acc-close',

  },
  {
    name: 'client-financial-info',
    endpoint: 'https://jk123yyppe.execute-api.us-east-1.amazonaws.com/dev/client-financial-info',
    // endpoint: 'http://localhost:3000/dev/client-financial-info',
  },
  {
    name: 'client-company',
    endpoint: 'https://9zo05wmsq7.execute-api.us-east-1.amazonaws.com/dev/clientCompany',
  },
  {
    name: 'client-trust',
    endpoint: 'https://p9czdscb29.execute-api.us-east-1.amazonaws.com/dev/clientTrust',
  },
  {
    name: 'client-monitoring',
    endpoint: 'https://4tj1r7z6ea.execute-api.us-east-1.amazonaws.com/dev/client-monitoring',
    // endpoint: 'http://localhost:3000/dev/client-monitoring',
  },
  {
    name: 'clients-risk',
    endpoint: 'https://ywq0s5mcz2.execute-api.us-east-1.amazonaws.com/dev/clients-risk',
  },
  {
    name: 'opportunities',
    endpoint: 'https://62igfikfg8.execute-api.us-east-1.amazonaws.com/dev/opportunities',
  },
  {
    name: 'currencies',
    endpoint: 'https://j43l5s8nd1.execute-api.us-east-1.amazonaws.com/dev/currencies',
  },
  {
    name: 'countries',
    endpoint: 'https://2h3b9egjt9.execute-api.us-east-1.amazonaws.com/dev/countries',
  },
  {
    name: 'digidoc',
    endpoint: 'https://c1kvwqq7k2.execute-api.us-east-1.amazonaws.com/dev/digisign',
  },
  {
    name: 'documents',
    endpoint: 'https://j3omfue3j9.execute-api.us-east-1.amazonaws.com/dev/documents',
    // endpoint: 'http://localhost:3000/dev/documents',
  },
  {
    name: 'docw',
    endpoint: 'https://uvg8o0k5h2.execute-api.us-east-1.amazonaws.com/dev/docw',
  },
  {
    name: 'doc-check',
    endpoint: 'https://d7l5ig3k64.execute-api.us-east-1.amazonaws.com/dev/doc-check',
  },
  {
    name: 'email',
    endpoint: 'https://a8l9ki7c8i.execute-api.us-east-1.amazonaws.com/dev/email',
  },
  {
    name: 'email-custom-templates',
    endpoint: 'https://29kotle3qk.execute-api.us-east-1.amazonaws.com/dev/email-custom-templates',
  },
  {
    name: 'financial-accounts',
    endpoint: 'https://z1o5rxpf6b.execute-api.us-east-1.amazonaws.com/dev/financial-accounts',
  },
  {
    name: 'identification',
    endpoint: 'https://gc9rzds6ii.execute-api.us-east-1.amazonaws.com/dev/identification',
    // endpoint: 'http://localhost:4123/dev/identification',
  },
  {
    name: 'information-requests',
    endpoint: 'https://9wgidnc8i0.execute-api.us-east-1.amazonaws.com/dev/information-requests',
    // endpoint: 'http://localhost:3000/dev/information-requests',
  },
  {
    name: 'leads',
    endpoint: 'https://d4akdbs82l.execute-api.us-east-1.amazonaws.com/dev/leads',
  },
  {
    name: 'logging',
    endpoint: 'https://6l409nf28j.execute-api.us-east-1.amazonaws.com/dev/logging',
  },
  {
    name: 'reports',
    endpoint: 'https://gax50d5gog.execute-api.us-east-1.amazonaws.com/dev/reports',
  },
  {
    name: 'staff',
    endpoint: 'https://rw8safelil.execute-api.us-east-1.amazonaws.com/dev/staff',
  },
  {
    name: 'teams',
    endpoint: 'https://7be19erb5h.execute-api.us-east-1.amazonaws.com/dev/teams',
  },
  {
    name: 'status-types',
    endpoint: 'https://wa82ohezme.execute-api.us-east-1.amazonaws.com/dev/status-types',
  },
  {
    name: 'systemTools',
    endpoint: 'https://q62efa13q1.execute-api.us-east-1.amazonaws.com/dev/systemTools',
  },
  {
    name: 'trading-accounts',
    endpoint: 'https://h71hyy3opj.execute-api.us-east-1.amazonaws.com/dev/trading-accounts',
    // endpoint: 'http://localhost:3000/dev/trading-accounts',
  },
  {
    name: 'trading-commissions',
    endpoint: 'https://a7s5gola2h.execute-api.us-east-1.amazonaws.com/dev/trading-commissions',
  },
  {
    name: 'trading-platforms',
    endpoint: 'https://e5khp9jtia.execute-api.us-east-1.amazonaws.com/dev/trading-platforms',
  },
  {
    name: 'trading-transactions',
    endpoint: 'https://g8ztz8cftg.execute-api.us-east-1.amazonaws.com/dev/trading-transactions',
  },
  {
    name: 'trading-transactions-2',
    endpoint: 'https://ffzhfssezb.execute-api.us-east-1.amazonaws.com/dev/trading-transactions-2',
  },
  {
    name: 'user-client',
    endpoint: 'https://boxxi3ka3m.execute-api.us-east-1.amazonaws.com/dev/user-client',
  },
  {
    name: 'user-staff',
    endpoint: 'https://fnuoa8imp8.execute-api.us-east-1.amazonaws.com/dev/user-staff',
  },
  {
    name: 'verifications',
    endpoint: 'https://vemdow0j41.execute-api.us-east-1.amazonaws.com/dev/verifications',
  },
  {
    name: 'watchlist-client',
    endpoint: 'https://hyowpb9w4i.execute-api.us-east-1.amazonaws.com/dev/watchlist-client',
  },
  {
    name: 'widgets',
    endpoint: 'https://98cwoy5yj9.execute-api.us-east-1.amazonaws.com/dev',
  },
  {
    name: 'journals',
    endpoint: 'https://bwxxrcf1fh.execute-api.us-east-1.amazonaws.com/dev/journals',
  },
  {
    name: 'systemTools',
    endpoint: 'https://o7qosis4tk.execute-api.us-east-1.amazonaws.com/dev/systemTools',
  },
  {
    name: 'account-financial-accounts',
    endpoint: 'https://aql8zpk04a.execute-api.us-east-1.amazonaws.com/dev/account-financial-accounts',
  },
  {
    name: 'watchlist-client',
    endpoint: 'https://hyowpb9w4i.execute-api.us-east-1.amazonaws.com/dev/watchlist-client',
  },
  {
    name: 'xRecordIds',
    endpoint: 'https://csmn3e4g7b.execute-api.us-east-1.amazonaws.com/dev/xRecordIds',
  },
  {
    name: 'reportsClients',
    endpoint: 'https://wd14t8p9g0.execute-api.us-east-1.amazonaws.com/dev/reports-clients',
  },
  {
    name: 'reportsLeads',
    endpoint: 'https://p6fgnp0v5m.execute-api.us-east-1.amazonaws.com/dev/reports-leads',
  },
  {
    name: 'reportsInfoReq',
    endpoint: 'https://pgx61awk7b.execute-api.us-east-1.amazonaws.com/dev/reports-inforeq',
  },
  {
    name: 'reportsMetrics',
    endpoint: 'https://yuz45rqlyh.execute-api.us-east-1.amazonaws.com/dev/reports-metrics',
  },
  {
    name: 'agents',
    endpoint: 'https://icbawus69d.execute-api.us-east-1.amazonaws.com/dev/agents',
  },
] as const

