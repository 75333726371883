import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { constants, operations, Types } from "./duck";

const GoogleAutocomplete: React.FC<Types.GoogleAutocompleteProps> = ({
  countries,
  addressStreetTypes,
  onChange,
}) => {
  const [googleAddress, setGoogleAddress] = React.useState("");

  const handleSelectGoogleAddress = async (value: any) => {
    const addresses: any = await operations.getAddresses(
      value,
      countries,
      addressStreetTypes
    );

    onChange(addresses);

    setGoogleAddress(value);
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={constants.GOOGLE_API_KEY}
      selectProps={{
        value: googleAddress,
        onChange: handleSelectGoogleAddress,
      }}
      autocompletionRequest={{
        componentRestrictions: { country: countries.map((c) => c.iso_alpha_2) },
      }}
    />
  );
};

export default GoogleAutocomplete;
