import React, {Component} from 'react'
import {connect} from 'react-redux'
import { API } from 'aws-amplify'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Skeleton,
  Spin
} from 'antd'

import axios from 'axios'
import moment from 'moment'

// import AttachedDocument from './AttachedDocument'

const {Option} = Select
const { TextArea } = Input

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

// Transaction Status
// 1,Draft
// 2,Pending
// 3,Pending Reject
// 4,Pending Approve
// 5,Rejected
// 6,Approved
// 7,Cancelled

class TradingTransactionsDeposit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      teProcessing: false,
      statusList: [],
      financialAccountList: [],
      loading: true,
      currentStaff: props.currentStaff,
    }
  }

  async componentDidMount() {
    try {
      const TradingTransaction = await this.getTradingTransactionsRecord(this.props.id)
      this.setState({
        ...TradingTransaction.payload,
        'authorise1_datetime': TradingTransaction.payload?.authorise1_datetime ? moment(TradingTransaction.payload.authorise1_datetime).format('YYYY-MM-DD HH:mm:ss') : '',
        'authorise2_datetime': TradingTransaction.payload?.authorise2_datetime ? moment(TradingTransaction.payload.authorise2_datetime).format('YYYY-MM-DD HH:mm:ss') : '',
        loading: true
      })
      const {client_id} = this.state
      await API.get('trading-transactions-2', '/list-status')
        .then(response => {
          const dataSource = []
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              statusName: response.payload[i].statusName,
            })
          }
          this.setState({statusList: dataSource})
        })
        .catch(error => {
          console.log(error)
        })

      await API.get('financial-accounts', `/list/${client_id}`)
        .then(response => {
          const dataSource = []
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              fileBy: response.payload[i].fileBy,
            })
          }
          this.setState({financialAccountList: dataSource})
        })
        .catch(error => {
          console.log(error)
        })
      this.setState({loading: false})
    } catch (e) {
      console.log(e)
    }
  }

  async getTradingTransactionsRecord(tradingTransactionID) {
    return API.get('trading-transactions-2', `/id/${tradingTransactionID}`)
  }

  async saveTradingTransactionsRecord(tradingTransactionRecord) {
    await API.patch('trading-transactions-2', `/id/${this.state.id}`, {body: tradingTransactionRecord})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  onChangeDebit = value => {
    this.setState({'debit': value})
  };
  
  onChangePlatformDeposit = value => {
    this.setState({'platformDepositAmount': value})
  };

  onHandleChangeClient = value => {
    this.setState({'clientID': value})
  }

  onHandleChangeStatus = value => {
    this.setState({'statusID': value})
  }

  onHandleChangeSourceAccount = value => {
    this.setState({'sourceAccountID': value})
  }

  onHandleTextAreaChange = ({ target: { value } }) => {
    this.setState({ 'internalNotes': value })
  };

  apply98 = () => {
    this.setState({platformDepositAmount: (Number.parseFloat(this.state.debit) * 0.98).toFixed(2)})
  };

  apply100 = () => {
    this.setState({platformDepositAmount: (Number.parseFloat(this.state.debit)).toFixed(2)})
  };
  
  applyToTe = async () => {
    const statusFileBy = 'Applied to Trading'
    this.setState({teProcessing: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while this record is being updated')
    try {
      await axios
        .post('https://mario.pimwatech.com/ctin/live-add', {
          login_id: this.state.tradingPlatformUsername,
          amount: this.state.platformDepositAmount
        })
        .then(response => {
          console.log(response.data)
          return
          // this.setState({
          //   te_id: response.data.te_response.id,
          //   te_login: response.data.te_response.login
          // })
        })

      await this.saveDepositRecord()
      this.setState({
        teProcessing: false,
        justAppliedToTe: true
      })
      // await API.put('trading', `/deposits/update/${this.props.deposit_id}`, {
      //   body: {
      //     id: this.state.id,
      //     teDepositProcessedDatetime: moment(),
      //     teDepositProcessedStaff: this.props.app_state.current_staff_id,
      //     teDepositProcessedIp: this.props.app_state.userGeo.IPv4,

      //   }
      // })
      await API.post('email', '/interaction', {
        body: {
          interaction: 'Xeonda - Deposit Status Adjustment',
          data: {
            tradingAccountID: this.state.tradingAccountID,
            tradingPlatformUsername: this.state.tradingPlatformUsername,
            platformDepositAmount: this.state.platformDepositAmount,
          },
          result: 'Deposit SUCCESS'
        }
      })
        .then(response => {
          // console.log(response);
        })
        .catch(error => {
          console.log(error)
          API.post('email', '/interaction', {
            body: {
              interaction: 'Xeonda - Deposit Status Adjustment',
              data: {
                tradingAccountID: this.state.tradingAccountID,
                tradingPlatformUsername: this.state.tradingPlatformUsername,
                platformDepositAmount: this.state.platformDepositAmount,
              },
              result: 'Deposit FAIL'
            }
          })
        })
      // this.getDepositRecord();
      openNotificationWithIcon('success', 'Save Success', 'This deposit record has been updated')
    } catch (e) {
      console.log(e)
      openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
    }
  };

  saveDepositRecord = async () => {
    this.setState({is_saving: true})
    await this.saveTradingTransactionsRecord({
      id: this.state.id,
      platformDepositAmount: this.state.platformDepositAmount,
      transmissionDateTime: moment().utc(),
      sourceAccountID: this.state.sourceAccountID
    })
    this.setState({is_saving: false})
  }

  onCheckboxOnChange = (event) => {
    if ( event.target.id === 'authorise1_staffID'){
      this.setState({
        'authorise1_staffID': event.target.checked ? this.state.currentStaff.id : null,
        'authorise1_staff': event.target.checked ? this.state.currentStaff.file_by : null,
        'authorise1_datetime': moment().format('YYYY-MM-DD HH:mm:ss')
      })
      API.patch('trading-transactions-2', `/approval/${this.state.id}`, {
        body:
        {
          'authorise1_staffID': this.state.currentStaff.id,
          'authorise1_cancel': !event.target.checked
        }
      })
      openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
    } else if (event.target.id === 'authorise2_staffID'){
      this.setState({
        'authorise2_staffID': event.target.checked ? this.state.currentStaff.id : null,
        'authorise2_staff': event.target.checked ? this.state.currentStaff.file_by : null,
        'authorise2_datetime': moment().format('YYYY-MM-DD HH:mm:ss')
      })
      API.patch('trading-transactions-2', `/approval/${this.state.id}`, {
        body:
        {
          'authorise2_staffID': this.state.currentStaff.id,
          'authorise2_cancel': !event.target.checked
        }
      })
      openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
    }
  }

  onHandleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id,
      debit,
      statusID,
      sourceAccountID,
      internalNotes
    } = this.state
    try {
      await this.saveTradingTransactionsRecord({
        id,
        debit,
        statusID,
        sourceAccountID,
        internalNotes
      })
      this.setState({is_saving: false})
    } catch (e) {
      console.log(e)
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <h3>Deposit Transaction</h3>
          </Col>
        </Row>
        <Skeleton active loading={this.state.loading}>
          <Spin spinning={this.state.loading}>
            <Form layout='vertical' onSubmit={this.onHandleSubmit}>
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Card
                    title='Deposit Details'
                  >
                    <Row gutter={[96, 24]}>
                      <Col xs={8}>
                        <Form.Item label='Currency'>
                          <Input
                            id='currency'
                            value={this.state.currency || ''}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={16}>
                        <Form.Item label='Amount'>
                          <InputNumber
                            id='debit'
                            name='debit'
                            value={this.state.debit || ''}
                            style={{width: '100%'}} min='0' max='1000000' precision={2} step={0.1}
                            onChange={this.onChangeDebit}
                            disabled={
                              this.state.statusID === 3 ||
                              this.state.statusID === 5 ||
                              this.state.statusID === 6 ||
                              this.state.statusID === 7}
                          />
                        </Form.Item>
                        <Form.Item label='Platform Deposit Amount'>
                          <InputNumber
                            id='platformDepositAmount'
                            name='platformDepositAmount'
                            value={this.state.platformDepositAmount || ''}
                            style={{width: '100%'}} min='0' max='1000000' precision={2} step={0.1}
                            onChange={this.onChangePlatformDeposit}
                            disabled={
                              this.state.statusID === 3 ||
                              this.state.statusID === 5 ||
                              this.state.statusID === 6 ||
                              this.state.statusID === 7}
                          />
                        </Form.Item>
                        <a
                          onClick={() => this.apply98()}
                          disabled={
                            this.state.statusID === 3 ||
                            this.state.statusID === 5 ||
                            this.state.statusID === 6 ||
                            this.state.statusID === 7}
                        >
                          Apply 98%
                        </a>
                        <Divider type="vertical"/>
                        <a
                          onClick={() => this.apply100()}
                          disabled={
                            this.state.statusID === 3 ||
                            this.state.statusID === 5 ||
                            this.state.statusID === 6 ||
                            this.state.statusID === 7}
                        >
                          Apply 100%
                        </a>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card
                    title='Source Account'
                  >
                    <Form.Item>
                      <Select
                        onChange={this.onHandleChangeSourceAccount}
                        defaultValue={this.state.sourceAccountID || ''}
                        value={this.state.sourceAccountID || ''}
                      >
                        {
                          this.state.financialAccountList.map(({ id, fileBy }) => (
                            <Option key={id} value={id}>
                              {fileBy}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={12}>
                  {/* <AttachedDocument
                trading_account_id={this.state.tradingAccountID}
                debit={this.state.debit}
                id={this.state.id}
                // loading={loading}
                document={this.state.document}
                file={this.state.fileList?.[0]}
                // onSuccessUpload={refresh}
              /> */}
                </Col>
              </Row>
              <Divider />
              <Row gutter={[24, 24]}>
                <Col xs={8}>
                  <Card
                    title='Authorisations'
                  >
                    <Form.Item label='Status'>
                      <Select
                        onChange={this.onHandleChangeStatus}
                        defaultValue={this.state.statusID || ''}
                        value={this.state.statusID || ''}
                      >
                        {
                          this.state.statusList.map(({ id, statusName }) => (
                            <Option key={id} value={id}>
                              {statusName}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                    <Divider />
                    <Form.Item label='Internal Notes'>
                      <TextArea
                        autoSize={{
                          minRows: 3,
                          maxRows: 5
                        }}
                        value={this.state.internalNotes}
                        onChange={this.onHandleTextAreaChange}
                      />
                    </Form.Item>
                    <Divider/>
                    <Form.Item label="Authorisation">
                      <Checkbox
                        id='authorise1_staffID'
                        checked={
                          this.state.authorise1_staffID
                        }
                        disabled={this.state.currentStaff.id !== this.state.authorise1_staffID && this.state.authorise1_staffID !== null}
                        onChange={event => this.onCheckboxOnChange(event)}
                      >
                    Authorisation 1
                      </Checkbox>
                      {
                        this.state.authorise1_staffID && <span>
                          <br/>
                          <em>
                            {this.state.authorise1_staff} at {this.state.authorise1_datetime}
                          </em>
                          <br/>
                        </span>
                      }
                      <br/>
                      <Checkbox
                        id='authorise2_staffID'
                        checked={
                          this.state.authorise2_staffID
                        }
                        disabled={this.state.currentStaff.id !== this.state.authorise2_staffID && this.state.authorise2_staffID !== null}
                        onChange={event => this.onCheckboxOnChange(event)}
                      >
                    Authorisation 2
                      </Checkbox>
                      {
                        this.state.authorise2_staffID && <span>
                          <br/>
                          <em>
                            {this.state.authorise2_staff} at {this.state.authorise2_datetime}
                          </em>
                          <br/>
                        </span>
                      }
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={8}>
                  <Card
                    title='Trading Platform'
                  >
                    <Form.Item label='Mark Deposit'>
                      <Button
                        type='primary'
                        disabled={this.state.status_id !== 3}
                        style={{ width: 200 }}
                        onClick={() => this.handleStatus(8)}
                      >
                  Mark Applied to TE
                      </Button>
                      <br />
                      <br />
                      <Button
                        type='primary'
                        ghost={Boolean(this.state.justAppliedToTe)}
                        disabled={this.state.statusID !== 6 || !this.state.platformDepositAmount || !this.state.tradingPlatformUsername || this.state.transmissionDateTime}
                        loading={this.state.teProcessing}
                        style={{ width: 200 }}
                        onClick={() => this.applyToTe()}
                      >
                  Apply Deposit to TE
                      </Button>
                      <br />
                      <br />
                      <Button
                        type='primary'
                        ghost={Boolean(this.state.depositAdviceEmailStaff) || this.state.justEmailedClient}
                        // disabled={this.state.statusID && this.state.statusID !== 6}
                        disabled={true}
                        style={{ width: 200 }}
                        onClick={() => this.emailDeposit()}
                      >
                        {(this.state.depositAdviceEmailStaff || this.state.justEmailedClient) ? 'Resend Deposit Advice' : 'Email Deposit Advice'}
                      </Button>
                    </Form.Item>

                  </Card>
                </Col>
                <Col xs={8}>
                  <Card
                    title='Log'
                  >
                    <Form.Item label='Trading Platform Transmission'>
                      <Input
                        id='transmissionDateTime'
                        value={this.state.transmissionDateTime || ''}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Trading Platform Transmission Amount'>
                      <Input
                        id='transmissionAmount'
                        value={this.state.transmissionAmount ? Number.parseFloat(this.state.transmissionAmount).toFixed(2) : ''}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label='Trading Platform Transmission User'>
                      <Input
                        id='transmissionUser'
                        value={this.state.transmissionUserName || ''}
                        disabled
                      />
                    </Form.Item>

                  </Card>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={this.state.is_saving}
                  onClick={this.onHandleSubmit}
                >Save</Button>
              </Row>
            </Form>
          </Spin>
        </Skeleton>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentStaff: appStateSelectors.stateSelector(state).currentStaffRecord,
    app_state: state.app_state,
    language: state.language,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const TradingTransactionsDepositContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradingTransactionsDeposit)

export default TradingTransactionsDepositContainer

