import * as React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Input,
  DatePicker,
  TableColumnType,
  notification
} from 'antd'
import { InputSelect } from 'components/input-select'
import {
  useQueryParams,
  useSelector,
  useIntl
} from 'hooks'
import Highlighter from 'react-highlight-words'
import { FormattedMessage } from 'react-intl'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { Types } from './duck'
import {
  useRouteMatch,
  match
} from 'react-router-dom'

const { RangePicker } = DatePicker

const inputTypes: Types.INPUT_TYPES = {
  DATE_RANGE: 'DATE_RANGE',
  INPUT: 'INPUT',
  SELECT: 'SELECT',
}

type MatchParams<Params> = { [K in keyof Params]?: string }
// TODO
type FetchCb<Params extends MatchParams<Params> = {}, TProps = any> = (params: {match: match<Params>; rootState: Record<string, any>;} & TProps) => Promise<any>;

// interface ListHoc {
//   <R>(Component: any): React.FC<R>;
//   inputTypes: Types.INPUT_TYPES;
// }

function listHOC<TRecord, TProps = any, Params extends MatchParams<Params> = {}>(
  Component: any,
  fetchCb?: FetchCb<Params, TProps>,
  asyncTable?: boolean
): React.FC<TProps> {
  return (props: any) => {
    const [params, setParams] = useQueryParams()
    
    const [{
      searchWords, rangeValues, searchedColumns
    }, setState] =
      React.useState<Types.ListHocState>({
        searchWords: Object.values(params),
        rangeValues: null,
        searchedColumns: Object.keys(params),
      })

    const [entitiesState, setEntitiesState] = React.useState<{
      entities: TRecord[];
      loading: boolean;
    }>({
      entities: [],
      loading: false,
    })
    const intl = useIntl()

    const match = useRouteMatch<Params>()
    const rootState = useSelector((state) => state)

    const searchInput = React.useRef<{
      focus: () => void;
      select: () => void;
      blur: () => void;
    }>()

    React.useEffect(() => {
      if (fetchCb) {
        setEntitiesState((prev) => ({
          ...prev,
          loading: true,
        }))

        fetchCb({
          ...props,
          match,
          rootState
        })
          .then((resp) => {
            setEntitiesState((prev) => ({
              ...prev,
              loading: false,
              entities: resp,
            }))
          })
          .catch(() => {
            setEntitiesState((prev) => ({
              ...prev,
              loading: false,
            }))

            notification.error({message: intl.formatMessage({id: 'notifications.error.fetch'})})
          })
      }
    }, [])

    const handleSearch = (
      selectedKeys: React.Key[],
      confirm: FilterDropdownProps['confirm'],
      dataIndex: string
    ) => {
      confirm({ closeDropdown: true })

      setParams({
        ...params,
        [dataIndex]: selectedKeys,
      })

      setState({
        searchedColumns: searchedColumns.includes(dataIndex) ? searchedColumns : searchedColumns.concat(dataIndex),
        searchWords: selectedKeys,
        rangeValues,
      })
    }

    const handleReset = (clearFilters?: () => void) => {
      if (clearFilters) {
        clearFilters()
      }

      setState({
        searchedColumns: [],
        searchWords: [],
        rangeValues: null,
      })
    }

    const getColumnSearchProps: Types.GetColumnSearchProps<TRecord> = ({
      dataIndex,
      filterInputType = inputTypes.INPUT,
      render = (highlighter) => highlighter,
      selectFilterOptions = [],
      mode,
      getOptionProps,
      convertFilterValueToNumber,
      ...rest
    }) => {
      let onFilter: TableColumnType<any>['onFilter']
      let getFilterComponent: (
        props: Omit<FilterDropdownProps, 'prefixCls' | 'visible'>
      ) => React.ReactNode

      switch (filterInputType) {
        case inputTypes.DATE_RANGE:
          onFilter = (value, record) => {
            const [from, to] = searchWords
            return record[dataIndex] >= from && record[dataIndex] <= to
          }
          getFilterComponent = ({ setSelectedKeys }) => (
            <div style={{ marginBottom: 5 }}>
              <RangePicker
                showTime
                value={rangeValues}
                ref={(ref: any) => {
                  searchInput.current = ref
                }}
                onChange={(momentValues, stringValues) => {
                  setSelectedKeys(stringValues)

                  setState({
                    searchedColumns,
                    searchWords: searchWords.concat(stringValues),
                    rangeValues: momentValues,
                  })
                }}
              />
            </div>
          )
          break
        case inputTypes.SELECT:
          onFilter = (option, record) => {
            return record[dataIndex] === option
          }
          getFilterComponent = ({ setSelectedKeys, selectedKeys }) => {
            let filterValue: React.Key | React.Key[] = selectedKeys
            const multi = mode === 'multiple'

            if (multi) {
              filterValue = convertFilterValueToNumber ? filterValue.map(Number) : filterValue
            } else if (convertFilterValueToNumber && filterValue.length) {
              filterValue = Number(filterValue[0])
            } else {
              filterValue = filterValue[0]
            }

            return (
              <div style={{ marginBottom: 5 }}>
                <InputSelect
                  mode={mode}
                  allowClear={false}
                  showSearch={false}
                  isFormItem={false}
                  options={selectFilterOptions}
                  getOptionProps={getOptionProps}
                  onChange={(value) => {
                    setSelectedKeys(multi ? value : [value])
                  }}
                  value={filterValue}
                  innerRef={(ref: any) => {
                    searchInput.current = ref
                  }}
                />
              </div>
            )
          }
          break
        case inputTypes.INPUT:
          onFilter = (value, record) =>
            String(record[dataIndex])
              .toLowerCase()
              .includes(String(value).toLowerCase())

          getFilterComponent = ({
            setSelectedKeys, selectedKeys, confirm
          }) => (
            <Input
              ref={(ref: any) => {
                searchInput.current = ref
              }}
              value={selectedKeys[0]}
              onChange={({ target: { value } }) => setSelectedKeys(value ? [value] : [])}
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{
                width: 188,
                marginBottom: 8,
                display: 'block',
              }}
            />
          )
          break
        default:
          break
      }

      return {
        ...rest,
        onFilter: asyncTable ? undefined : onFilter,
        dataIndex,
        defaultFilteredValue: params[dataIndex] ? [params[dataIndex]] : rest.defaultFilteredValue,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          close,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            {getFilterComponent({
              setSelectedKeys,
              selectedKeys,
              confirm,
              close,
            })}
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                marginRight: 8,
              }}
            >
              <FormattedMessage id="button.search" />
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              <FormattedMessage id="button.reset" />
            </Button>
          </div>
        ),
        filterIcon: (filtered) => {
          const style = filtered ? { color: '#1890ff' } : {}
          return <SearchOutlined {...style} />
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            const focusMethods: Record<
              keyof typeof inputTypes,
              'focus' | 'select'
            > = {
              [inputTypes.DATE_RANGE]: 'focus',
              [inputTypes.INPUT]: 'select',
              [inputTypes.SELECT]: 'focus',
            }

            setTimeout(() => {
              const method = focusMethods[filterInputType]

              if (searchInput.current && searchInput.current[method]) {
                searchInput.current[method]()
              }
            }, 100)
          }
        },
        render: (text, record) =>
          render(
            searchedColumns.includes(dataIndex) ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
                }}
                searchWords={searchWords.map((el) => String(el))}
                autoEscape
                textToHighlight={(text || '').toString()}
              />
            ) : (
              text
            ),
            record
          ),
      }
    }

    return (
      <Component
        {...props}
        {...entitiesState}
        match={match}
        getColumnSearchProps={getColumnSearchProps}
        updateState={(
          newState: Partial<{ entities: TRecord[]; loading: boolean }>
        ) => {
          setEntitiesState((prev) => ({
            ...prev,
            ...newState,
          }))
        }}
      />
    )
  }
}

listHOC.inputTypes = inputTypes

export default listHOC
