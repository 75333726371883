import React from 'react'
import {
  Row,
  Col,
  Checkbox,
  Typography,
  Form,
  Collapse,
  Button
} from 'antd'
import {showNotification} from 'utils'
import generator from 'generate-password'
import { API } from 'aws-amplify'

const { Panel } = Collapse

const Security = ({staff, getStaffRecord}) => {

  const callback = () => {
    getStaffRecord(staff.id)
  }

  const onClickCreateAPIPassword = async() => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      lowercase: true,
      symbols: true,
      uppercase: true,
      strict: true
    })

    showNotification('success', 'Here is your password please copy it as you will not be able to see it again', password)

    await API.post('staff', '/api/create-user', {
      body: {
        staffId: staff.id,
        password: password
      }
    })
    callback()
  }

  const onClickDeleteAPIPassword = async() => {

    showNotification('success', 'APIPassword deleted successfully', '')

    await API.put('staff', `/update/${staff.id}`, {body: {'publicApiPwHash': null}})
    callback()
  }

  return (
    <div>
      <Typography.Title level={4} style={{ marginBottom: 30 }}>Client Management</Typography.Title>
      <Row gutter={[12, 0]}>
        <Col xs={12}>
          <Form.Item name="security_canChangeClientStatus" valuePropName="checked">
            <Checkbox>
            Change Status
            </Checkbox>
          </Form.Item>
          <Form.Item name="security_canMarkClientActive" valuePropName="checked">
            <Checkbox>
            Mark Active
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label='API Password Hash'>
            {staff?.publicApiPwHash}
          </Form.Item>
          <Form.Item>
            <Button onClick={onClickCreateAPIPassword}>Create API Password</Button>{' '}<Button onClick={onClickDeleteAPIPassword}>Delete API Password</Button>
          </Form.Item>
        </Col>
      </Row>
      <Collapse onChange={callback}>
        <Panel header="Account" key="1">
          Account
          <Form.Item name="security_account_accountManager" valuePropName="checked">
            <Checkbox>
              Account Manager
            </Checkbox>
          </Form.Item>
          <Form.Item name="security_account_canSetAccountManager" valuePropName="checked">
            <Checkbox>
              Can Set Account Manager
            </Checkbox>
          </Form.Item>
        </Panel>
      </Collapse>

      <Collapse onChange={callback}>
        <Panel header="Finance" key="1">
          Finance
          <Form.Item name="security_finance_canViewAccountFinancialReports" valuePropName="checked">
            <Checkbox>
              Can View Account Financial Reports
            </Checkbox>
          </Form.Item>
          <Form.Item name="security_finance_canViewClientFinancialReports" valuePropName="checked">
            <Checkbox>
              Can View Client Financial Reports
            </Checkbox>
          </Form.Item>
        </Panel>
      </Collapse>
      
      <Collapse onChange={callback}>
        <Panel header="Staff" key="1">
          Staff
          <Form.Item name="security_staff_canCreateStaff" valuePropName="checked">
            <Checkbox>
              Can Create Staff
            </Checkbox>
          </Form.Item>
          <Form.Item name="security_staff_canSetSecurity" valuePropName="checked">
            <Checkbox>
              Can Set Security
            </Checkbox>
          </Form.Item>
        </Panel>
      </Collapse>
    </div>
  )
}

export default Security