import listHOC from './listHOC'
import React, {
  useEffect,
  useState
} from 'react'
import {
  Descriptions,
  Button
} from 'antd'
import {showNotification} from 'utils'
import {API} from 'aws-amplify'
import {CopyToClipboardComponent} from 'common'
const ViewDocumentDocsign = listHOC(({
  document,
  clientRecord,
}) => {
  const [state, setState] = useState({
    esignaturesDetails: null,
    localSpinner: true,
    emailClientAccountCreatedButtonLoading: false
  })

  useEffect(() => {
    setState({
      ...state,
      esignaturesDetails: null,
      localSpinner: true,
    })

    asyncFunctions()

  }, [document])

  const asyncFunctions = async () => {
    if (document.account_id) {
      try {
        const url = await API.get('digidoc', `/documents/query/${document.id}`)
        setState({
          ...state,
          esignaturesDetails: url.payload.esignaturesResponse,
          localSpinner: false
        })
      } catch (error) {
        console.log('err', error)
      }
    } else {
      setState({
        ...state,
        esignaturesDetails: null,
        localSpinner: false
      })
    }
  }

  const onClickEmailDocument = async () => {
    setState({
      ...state,
      emailClientAccountCreatedButtonLoading: true
    })

    console.log('hit button', {
      clientId: clientRecord.id,
      documentId: document.id,
      signingUrl: state.esignaturesDetails.signers[0].sign_page_url
    })

    try {
      await API.post('email', '/client/docsign/link', {
        body: {
          interaction: 'Testing',
          data: {
            clientId: clientRecord.id,
            documentId: document.id,
            documentSigningLink: state.esignaturesDetails.signers[0].sign_page_url
          },
        }
      })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
      showNotification('success', 'Email', 'Email send to client - Send Successful')
    } catch (error) {
      showNotification('error', 'Emails', error.message)
    }

    setState({
      ...state,
      emailClientAccountCreatedButtonLoading: false
    })
  }

  console.log('state',state)
  return document.id ? (
    <React.Fragment>
      <Descriptions title="Docsign Data" column={1} size="small" bordered>
        <Descriptions.Item label="Template Name">
          {
            document?.description
          }
        </Descriptions.Item>
        <Descriptions.Item label="DocSign ID">
          {
            state?.esignaturesDetails?.id
          }
        </Descriptions.Item>
        <Descriptions.Item label="Document Status">
          {
            state?.esignaturesDetails?.status
          }
        </Descriptions.Item>
        <Descriptions.Item label="Signing URL">
          {
            state?.esignaturesDetails?.signers.length > 0 ? <a href={state.esignaturesDetails.signers[0].sign_page_url}>{state.esignaturesDetails.signers[0].sign_page_url}</a> : ''
          }{' '}<CopyToClipboardComponent copyText={state.esignaturesDetails?.signers[0].sign_page_url}/>
        </Descriptions.Item>
        <Descriptions.Item label="Signed PDF">
          {
            state?.esignaturesDetails?.contract_pdf_url ? <a href={state.esignaturesDetails.contract_pdf_url}>{state.esignaturesDetails.contract_pdf_url}</a> : 'No Record'
          }
        </Descriptions.Item>
        <Descriptions.Item label="Docsign Data">
          <Button onClick={onClickEmailDocument} loading={state.emailClientAccountCreatedButtonLoading}>
            Email Document
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  ) : <h3>No document selected</h3>
})

export default ViewDocumentDocsign