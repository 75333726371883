import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {
  Table,
  Button
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import {listHOC,} from 'common'
import {
  documentsActions,
  documentsSelectors
} from 'configs/ducks/documents'

import {appStateSelectors} from 'configs/ducks/appState'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const EmailCustomTemplatesListPageComponent = ({
  getColumnSearchProps,
  dataSource,
  loading,
  getTopics,
  fetchCb,
  currentAccountID
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: id => <NavLink to={`/documents/emails/templates/${id}`}>{id}</NavLink>
    },
    {
      title: 'Template Name',
      dataIndex: 'templateName',
      ...getColumnSearchProps({
        dataIndex: 'templateName',
        placeholder: 'templateName',
      })
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      ...getColumnSearchProps({
        dataIndex: 'account_name',
        placeholder: 'Account Name',
      })
    }
  ]

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey='id'
      scroll={{x: true}}
    />
  )
}
const mapStateToProps = state => {
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  const {
    loading,
    entities
  } = documentsSelectors.stateSelector(state)
  return {
    currentAccountID: currentAccountRecord.id,
    dataSource: entities,
    loading
  }
}

export default connect(
  mapStateToProps,
  {fetchCb: documentsActions.getEmailCustomTemplates}
)(listHOC(EmailCustomTemplatesListPageComponent))
