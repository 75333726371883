import React, {
  useState,
  useEffect
} from 'react'
import {API} from 'aws-amplify'
import moment from 'moment'
import CollectionTemplateNewModal from './collection_add_template_item/CollectionTemplateNewModal'
import {showNotification} from 'utils'
import {arrayMoveImmutable} from 'array-move'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'

import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

import {
  Row,
  Col,
  Select,
  Button,
  Form,
  Input,
  Switch,
  Table,
  Card,
  Modal,
  Popconfirm
} from 'antd'

import {
  MenuOutlined,
  DeleteOutlined
} from '@ant-design/icons'

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
))

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)

const CollectionsTemplate = (props) => {
  const [state, setState] = useState({
    is_saving: false,
    loading: true,
    isModalVisible: false,
    buttonLoading: false,
    buttonSaveSortOrderLoading: false,
    title: ''
  })

  const templatesColumns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Collection ID',
      dataIndex: 'documentChecklistCollectionID',
    },
    {
      title: 'Created',
      dataIndex: 'recordCreated',
      render: (text, record) => (
        record?.recordCreated
          ? moment(record.recordCreated).format('YYYY/MM/DD HH:mm:ss')
          : ''
      )
    },
    {
      title: 'Action',
      render: (text, record) => (
        <span>
          <Popconfirm
            title='Are you sure to delete this record?'
            onConfirm={() => deleteRecord(record.id)}
            okText='Yes'
            onCancel='No'
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        </span>
      )
    }
  ]
  
  const deleteRecord = async (id) => {
    try {
      await API.put('doc-check', `/collections/delete-item/${id}`, {body: {}})
        .then(async response => {
          console.log(response)
          showNotification('success', 'Templates', 'Successfully Deleted')
        })
        .catch(error => {
          console.log(error)
          showNotification('error', 'API', error.message)
        })
    } catch (e) {
      showNotification('error', 'API', e.message)
    }
    handleRefresh()
  }

  const handleRefresh = async () => {
    try {
      const checklistCollection = await getCollectionRecord(props.id)
      const checklistCollectionDetail = await getCollectionDetail(props.id)
      setTemplatesList(
        checklistCollection.payload.map((ele, index) => {
          return {
            ...ele,
            index
          }
        })
      )
      setState({
        title: checklistCollectionDetail.payload.title ? checklistCollectionDetail.payload.title : '',
        loading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const [templatesList, setTemplatesList] = useState([
    {},
  ])

  useEffect(() => {
    handleRefresh()
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(templatesList.slice(), oldIndex, newIndex).filter(
        (el) => Boolean(el),
      )
      console.log('Sorted items: ', newData)
      setTemplatesList(newData)
      handleSaveSortOrder(props.id, newData)
    }
  }
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
      pagination={null}
    />
  )
  const DraggableBodyRow = ({
    className, style, ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = templatesList.findIndex((x) => x.index === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  const getCollectionRecord = (collectionID) => {
    return API.get('doc-check', `/template-items/collection/${collectionID}`)
  }

  const getCollectionDetail = (collectionID) => {
    return API.get('doc-check', `/collections/id/${collectionID}`)
  }

  const toggleModal = bool => {
    setState({
      ...state,
      isModalVisible: !bool
    })
  }

  const onHandleClickCreateNewTemplate = () => {
    setState(prevState => ({
      ...prevState,
      buttonLoading: true
    }))
    toggleModal(state.isModalVisible)
    setState(prevState => ({
      ...prevState,
      buttonLoading: false
    }))
  }

  const handleSaveSortOrder = async (id, templatesList) => {
    setState(prevState => ({
      ...prevState,
      buttonSaveSortOrderLoading: true
    }))

    const tempArray = []
    for (let i = 0; i < templatesList.length; i++) {
      tempArray.push({
        id: templatesList[i].id,
        sortOrder: i
      })
    }

    try {
      await API.put('doc-check', `/update-template-items/collection/${id}`, {body: tempArray}).then(
        async response => {
          console.log(response)
          showNotification('success', 'Save Successful', 'Your data order has been saved successfully')
        })
        .catch(error => {
          console.log(error)
          throw error
        })
    } catch (error) {
      console.log(error)
      showNotification('error', 'API', error.message)
    }
    setState(prevState => ({
      ...prevState,
      buttonSaveSortOrderLoading: false
    }))
  }

  return (
    <>
      <Card
        style={{minHeight: 360}}
        title={state?.title}
        extra={
          <>
            <Button
              loading={state?.buttonLoading}
              onClick={onHandleClickCreateNewTemplate}
            > Create New </Button>
            <Button
              loading={state?.buttonSaveSortOrderLoading}
              onClick={() => handleSaveSortOrder(props.id, templatesList)}
            >
              Save
            </Button>
          </>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Table
            rowKey='index'
            loading={templatesList.length < 0 || state.loading}
            style={{width: '100%'}}
            columns={templatesColumns}
            dataSource={templatesList}
            size='small'
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            pagination={false}
          />
        </DndProvider>
        <CollectionTemplateNewModal
          isModalVisible={state.isModalVisible}
          toggleModal={toggleModal}
          documentChecklistCollectionID={props.id}
          handleRefresh={handleRefresh}
        />
      </Card>
    </>
  )
}

export default CollectionsTemplate