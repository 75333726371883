import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
  Typography,
  Skeleton,
  Button,
  Form,
  Image,
  Popconfirm,
  Space
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import {
  Document,
  Page
} from 'react-pdf/dist/esm/entry.webpack'
import { FileUpload } from 'common'

import {
  removeDocument,
  uploadDocument
} from 'configs/ducks/trading/actions'

import styles from './AttachedDocument.module.css'

const AttachedDocument = ({
  document, file, loading, id, trading_account_id, debit, onSuccessUpload
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const displayPdf = !loading && file?.contentType?.includes('pdf')
  const displayImage = !loading && file?.contentType?.includes('image')

  const handleDelete = () => {
    dispatch(removeDocument(id))
  }

  const handleUpload = async ({ aws_file_id }) => {
    if (aws_file_id) {
      await dispatch(uploadDocument({
        aws_file_id: aws_file_id[0].response.key,
        trading_account_id,
        id,
        debit
      }))
      onSuccessUpload && onSuccessUpload()
    }
  }

  return (
    <Skeleton loading={loading}>
      <Typography.Title level={4}>
        <FormattedMessage id='trading_transaction_detail.attached_document' />
      </Typography.Title>
      {document.description && <p style={{width: '100%'}}>
        <strong>{document.description}</strong>
      </p>}
      <p>{document.long_description}</p>
      {displayPdf && (
        <Document file={file.url}>
          <Page width="280" pageNumber={1}/>
        </Document>
      )}
      {displayImage && <div className={styles.imgHolder}><Image src={file.url} alt='' style={{maxHeight: 400}} /></div>}
      {file?.url ? (
        <Space>
          <Button className={styles.link} type="link" target="_blank" href={file?.url}>
            <FormattedMessage id='trading_transaction_detail.click_here' />
          </Button>
          <Popconfirm
            title='Are you sure to delete this document?'
            onConfirm={handleDelete}
            okText='Yes'
            onCancel='No'
          >
            <DeleteOutlined style={{color: 'red'}} />
          </Popconfirm>
        </Space>
      ) : (
        <Form
          form={form}
          layout='vertical'
          onFinish={handleUpload}
        >
          <FileUpload
            name='aws_file_id'
            multiple={false}
            maxCount={1}
          />
          <Button
            type='primary'
            htmlType='submit'
          >
            Save
          </Button>
        </Form>
      )}
    </Skeleton>
  )
}

export default React.memo(AttachedDocument)