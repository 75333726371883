import React, {
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {
  agentsActions,
  agentsSelectors
} from 'configs/ducks/agents/index'

import {appStateSelectors} from 'configs/ducks/appState'

import {Tabs} from 'antd'

import AgentSummary from './agent_view/AgentSummary'
import {API} from 'aws-amplify'

const {TabPane} = Tabs

const AgentsViewPageComponent = (
  {
    getTopics,
    accountsList,
    match: {params: {agentID}}
  }
) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('agents', `/id/${agentID}`)
        getTopics(
          'View Agent Details',
          response.payload.id
        )
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <Tabs defaultActiveKey='1'>
      <TabPane tab='Summary' key='1'>
        <AgentSummary id={agentID} accountsList={accountsList}/>
      </TabPane>
    </Tabs>
  )
}

const {agentsSelector} = agentsSelectors
const mapStateToProps = state => {
  const {accountsList} = appStateSelectors.stateSelector(state)
  return {
    accountsList,
    ...agentsSelector(state)
  }
}

export default connect(mapStateToProps, {})(AgentsViewPageComponent)