import React, {
  useEffect,
  Fragment,
  useState
} from 'react'

import {
  Col,
  Row,
  Spin,
  Form,
  Button,
  Descriptions,
  Drawer,
  Space,
  Switch,
  Input,
  notification,
  Select
} from 'antd'
import moment from 'moment'
import {API} from 'aws-amplify'

const {Option} = Select

export const ClientTrustsBeneficariesDrawerComponent = ({
  visible_beneficiaries,
  trustDrawerData_beneficiaries,
  toggleModal,
  asyncFunction,
  getData,
  countriesList,
  streetCodes
}) => {
  const [form] = Form.useForm()
  const [state, setState] = useState({
    address: trustDrawerData_beneficiaries?.address,
    beneficiaryType: trustDrawerData_beneficiaries?.beneficiaryType,
    clientId: trustDrawerData_beneficiaries?.clientId,
    companyId: trustDrawerData_beneficiaries?.companyId,
    companyName: trustDrawerData_beneficiaries?.companyName,
    companyNumber: trustDrawerData_beneficiaries?.companyNumber,
    dateOfBirth: trustDrawerData_beneficiaries?.dateOfBirth,
    email: trustDrawerData_beneficiaries?.email,
    fileBy: trustDrawerData_beneficiaries?.fileBy,
    firstName: trustDrawerData_beneficiaries?.firstName,
    id: trustDrawerData_beneficiaries?.id,
    individualId: trustDrawerData_beneficiaries?.individualId,
    individualName: trustDrawerData_beneficiaries?.individualName,
    lastName: trustDrawerData_beneficiaries?.lastName,
    middleNames: trustDrawerData_beneficiaries?.middleNames,
    streetAddressCountryId: trustDrawerData_beneficiaries?.streetAddressCountryId,
    streetAddressPostcode: trustDrawerData_beneficiaries?.streetAddressPostcode,
    streetAddressState: trustDrawerData_beneficiaries?.streetAddressState,
    streetAddressStreetName: trustDrawerData_beneficiaries?.streetAddressStreetName,
    streetAddressStreetNumber: trustDrawerData_beneficiaries?.streetAddressStreetNumber,
    streetAddressStreetTypeId: trustDrawerData_beneficiaries?.streetAddressStreetTypeId,
    streetAddressSuburb: trustDrawerData_beneficiaries?.streetAddressSuburb,
    streetAddressUnitNumber: trustDrawerData_beneficiaries?.streetAddressUnitNumber,
    telephone: trustDrawerData_beneficiaries?.telephone,
    telephoneHome: trustDrawerData_beneficiaries?.telephoneHome,
    telephoneMobile: trustDrawerData_beneficiaries?.telephoneMobile,
    telephoneWork: trustDrawerData_beneficiaries?.telephoneWork
  })
  const [beneficiaryType, setBeneficiaryType] = useState('1')

  useEffect(() => {
    setState({
      ...state,
      individualId: trustDrawerData_beneficiaries?.individualId,
      companyId: trustDrawerData_beneficiaries?.companyId,
    })
  }, [trustDrawerData_beneficiaries])

  if (trustDrawerData_beneficiaries == null) {
    return <div></div>
  }

  const onTextboxChange = (event, state) => {
    // console.log(event.target)
    // Have to use event persist to stable https://reactjs.org/docs/events.html#event-pooling
    event.persist()
  
    setState(prevState => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
        individualId: trustDrawerData_beneficiaries.individualId,
        companyId: trustDrawerData_beneficiaries.companyId,
        beneficiaryType: beneficiaryType
      }
    })
  }
  const saveRecord = (state) => {
    delete state.enableEdited
    try {
      API.put(
        'client-trust',
        `/update-beneficiaries/${trustDrawerData_beneficiaries?.clientId}/${trustDrawerData_beneficiaries?.id}`,
        {
          body: {
            beneficiaryType: trustDrawerData_beneficiaries.beneficiaryType,
            individualId: trustDrawerData_beneficiaries.individualId,
            companyId: trustDrawerData_beneficiaries.beneficiaryType === '2' ? trustDrawerData_beneficiaries.companyId : null,
            clientsBeneficiaries: {
              id: trustDrawerData_beneficiaries?.id,
              fileBy: `${state?.lastName ? state.lastName : trustDrawerData_beneficiaries?.lastName}, ${
                state?.firstName ? state.firstName : trustDrawerData_beneficiaries?.firstName
              }`,
              individualId: trustDrawerData_beneficiaries.individualId,
              companyId: trustDrawerData_beneficiaries.beneficiaryType === '2' ? trustDrawerData_beneficiaries.companyId : null,
              email: state?.email,
              individualName: state?.individualName,
              companyName: state?.companyName,
              dateOfBirth: state?.dateOfBirth
            },
            contactIndividuals: {
              fileBy: `${state?.lastName ? state.lastName : trustDrawerData_beneficiaries?.lastName}, ${
                state?.firstName ? state.firstName : trustDrawerData_beneficiaries?.firstName
              }`,
              firstName: state?.firstName,
              middleNames: state?.middleNames,
              lastName: state?.lastName,
              dateOfBirth: state?.dateOfBirth,
              telephoneMobile: state?.telephoneMobile,
              address: state?.address,
              streetAddressUnitNumber: state?.streetAddressUnitNumber,
              streetAddressStreetNumber: state?.streetAddressStreetNumber,
              streetAddressStreetName: state?.streetAddressStreetName,
              streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
              streetAddressSuburb: state?.streetAddressSuburb,
              streetAddressState: state?.streetAddressState,
              streetAddressPostcode: state?.streetAddressPostcode,
              streetAddressCountryId: state?.streetAddressCountryId
            },
            contactCompanies: {
              companyName: state?.companyName,
              companyNumber: state?.companyNumber
            }
          }
        }
      )

      notification.success({message: 'Saved !'})
      getData()
    } catch (error) {
      console.error('error occured', error)
      notification.error({message: error.message})
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  const onSelectChange = value => {
    setBeneficiaryType(value)
  }

  return (
    <Fragment>
      <Drawer
        title='Client Trusts Detail'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible_beneficiaries}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!trustDrawerData_beneficiaries.id}>
            <Form form={form} scrollToFirstError={true} layout='vertical'>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Form.Item label='Edit / View'>
                  <Switch
                    checkedChildren='Edit'
                    unCheckedChildren='View'
                    id='enableEdited'
                    checked={state.enableEdited == 1}
                    onChange={event => {
                      return setState({enableEdited: event})
                    }}
                  />
                </Form.Item>
                <Col xs={24}>
                  {trustDrawerData_beneficiaries.id && !state.enableEdited && (
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{
                        marginBottom: 24,
                        marginTop: 0
                      }}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='ID'>
                        <strong>{trustDrawerData_beneficiaries.id}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Client ID'>
                        <strong>{trustDrawerData_beneficiaries.clientId}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Firstname'>
                        <strong>{trustDrawerData_beneficiaries.firstName}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Middlename'>
                        <strong>{trustDrawerData_beneficiaries.middleNames}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Lastname'>
                        <strong>{trustDrawerData_beneficiaries.lastName}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Beneficiary Type'>
                        <strong>
                          {trustDrawerData_beneficiaries.beneficiaryType === '1' ? 'Individual' : 'Company'}
                        </strong>
                      </Descriptions.Item>

                      <Descriptions.Item label='Individual Name'>
                        <strong>{trustDrawerData_beneficiaries.individualName}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Date Of Birth'>
                        <strong>
                          {trustDrawerData_beneficiaries?.dateOfBirth
                            ? moment(trustDrawerData_beneficiaries.dateOfBirth).format('YYYY/MM/DD')
                            : ''}
                        </strong>
                      </Descriptions.Item>

                      {trustDrawerData_beneficiaries.beneficiaryType === '2' && (
                        <>
                          <Descriptions.Item label='Company Name'>
                            <strong>{trustDrawerData_beneficiaries.companyName}</strong>
                          </Descriptions.Item>
                          <Descriptions.Item label='Company Number'>
                            <strong>{trustDrawerData_beneficiaries.companyNumber}</strong>
                          </Descriptions.Item>
                        </>
                      )}

                      <Descriptions.Item label='Email'>
                        <strong>{trustDrawerData_beneficiaries.email}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Telephone'>
                        <strong>{trustDrawerData_beneficiaries.telephoneMobile}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                  {trustDrawerData_beneficiaries.id && state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='ID'>
                          <Input
                            id='id'
                            disabled
                            value={state?.id ? state.id : trustDrawerData_beneficiaries.id}
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Client ID'>
                          <Input
                            id='clientId'
                            value={state?.clientId ? state.clientId : trustDrawerData_beneficiaries.clientId}
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Firstname'>
                          <Input
                            id='firstName'
                            value={state?.firstName ? state.firstName : trustDrawerData_beneficiaries.firstName}
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Middlename'>
                          <Input
                            id='middleNames'
                            value={state?.middleNames ? state.middleNames : trustDrawerData_beneficiaries.middleNames}
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Lastname'>
                          <Input
                            id='lastName'
                            value={state?.lastName ? state.lastName : trustDrawerData_beneficiaries.lastName}
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Beneficiary Type'>
                          <Select
                            id='beneficiaryType'
                            style={{width: 120}}
                            placeholder={'Please select...'}
                            defaultValue={
                              trustDrawerData_beneficiaries?.beneficiaryType
                                ? trustDrawerData_beneficiaries.beneficiaryType
                                : '1'
                            }
                            onChange={onSelectChange}
                            disabled
                          >
                            <Option value='1'>Individual</Option>
                            <Option value='2'>Company</Option>
                          </Select>
                        </Descriptions.Item>

                        <Descriptions.Item label='Individual Name'>
                          <Input
                            id='individualName'
                            value={
                              state?.individualName
                                ? state.individualName
                                : trustDrawerData_beneficiaries.individualName
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        {trustDrawerData_beneficiaries.beneficiaryType === '2' && (
                          <Descriptions.Item label='Company Name'>
                            <Input
                              id='companyName'
                              value={state?.companyName ? state.companyName : trustDrawerData_beneficiaries.companyName}
                              onChange={event => onTextboxChange(event, state)}
                            />
                          </Descriptions.Item>
                        )}
                        {trustDrawerData_beneficiaries.beneficiaryType === '2' && (
                          <Descriptions.Item label='Company Number'>
                            <Input
                              id='companyNumber'
                              value={
                                state?.companyNumber ? state.companyNumber : trustDrawerData_beneficiaries.companyNumber
                              }
                              onChange={event => onTextboxChange(event, state)}
                            />
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label='Date Of Birth'>
                          <Input
                            id='dateOfBirth'
                            placeholder='YYYY-MM-DD'
                            defaultValue={
                              trustDrawerData_beneficiaries?.dateOfBirth
                                ? moment(trustDrawerData_beneficiaries.dateOfBirth).format('YYYY-MM-DD')
                                : ''
                            }
                            onChange={(event, state) => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label='Email'>
                          <Input
                            id='email'
                            value={state?.email ? state.email : trustDrawerData_beneficiaries.email}
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label='Telephone' height='100%'>
                          <Input
                            id='telephoneMobile'
                            value={
                              state?.telephoneMobile
                                ? state.telephoneMobile
                                : trustDrawerData_beneficiaries.telephoneMobile
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>
                      </Descriptions>
                      <p> Address </p>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='Unit Number'>
                          <Input
                            id='streetAddressUnitNumber'
                            value={
                              state?.streetAddressUnitNumber
                                ? state.streetAddressUnitNumber
                                : trustDrawerData_beneficiaries.streetAddressUnitNumber
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item
                          name='streetAddressStreetNumber'
                          key='Street Number'
                          label='Street Number'
                          shouldUpdate
                        >
                          <Input
                            id='streetAddressStreetNumber'
                            value={
                              state?.streetAddressStreetNumber
                                ? state.streetAddressStreetNumber
                                : trustDrawerData_beneficiaries.streetAddressStreetNumber
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item
                          name='streetAddressStreetName'
                          key='Street name'
                          label='Street name'
                          shouldUpdate
                        >
                          <Input
                            id='streetAddressStreetName'
                            value={
                              state?.streetAddressStreetName
                                ? state.streetAddressStreetName
                                : trustDrawerData_beneficiaries.streetAddressStreetName
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item
                          name='streetAddressStreetTypeId'
                          key='Street Type'
                          label='Street Type'
                          shouldUpdate
                        >
                          <Select
                            showSearch
                            value={
                              state?.streetAddressStreetTypeId
                                ? state.streetAddressStreetTypeId
                                : trustDrawerData_beneficiaries.streetAddressStreetTypeId
                            }
                            showArrow
                            placeholder='Select Street'
                            optionFilterProp='children'
                            onChange={value => {
                              form.setFieldsValue({streetAddressStreetTypeId: value})
                              setState(prevState => {
                                return {
                                  ...prevState,
                                  streetAddressStreetTypeId: value
                                }
                              })
                            }}
                          >
                            {streetCodes.streetType &&
                              streetCodes.streetType.map(x => (
                                <Option key={x.id} value={x.id}>
                                  {x.fileBy}
                                </Option>
                              ))}
                          </Select>
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
                          <Input
                            id='streetAddressSuburb'
                            value={
                              state?.streetAddressSuburb
                                ? state.streetAddressSuburb
                                : trustDrawerData_beneficiaries.streetAddressSuburb
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressState' key='State' label='State' shouldUpdate>
                          <Input
                            id='streetAddressState'
                            value={
                              state?.streetAddressState
                                ? state.streetAddressState
                                : trustDrawerData_beneficiaries.streetAddressState
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
                          <Input
                            id='streetAddressPostcode'
                            value={
                              state?.streetAddressPostcode
                                ? state.streetAddressPostcode
                                : trustDrawerData_beneficiaries.streetAddressPostcode
                            }
                            onChange={event => onTextboxChange(event, state)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>
                          <Select
                            showSearch
                            value={
                              state?.streetAddressCountryId
                                ? state.streetAddressCountryId
                                : trustDrawerData_beneficiaries.streetAddressCountryId
                            }
                            showArrow
                            placeholder='Select Country'
                            optionFilterProp='children'
                            onChange={value => {
                              form.setFieldsValue({streetAddressCountryId: value})
                              setState(prevState => {
                                return {
                                  ...prevState,
                                  streetAddressCountryId: value
                                }
                              })
                            }}
                          >
                            {countriesList.map(({id, full_name}) => (
                              <Option key={id} value={id}>
                                {full_name}
                              </Option>
                            ))}
                          </Select>
                        </Descriptions.Item>
                      </Descriptions>

                      <Button onClick={ () => saveRecord(state)}> Save </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}
