import React, {
  useState,
  FC,
  useEffect
} from 'react'

import {
  InputSelect,
  FormInputNumber,
  Form,
  Descriptions,
  Typography,
  Switch,
  Row,
  Col
} from 'components'
import {DynamicFields} from './components'
import {
  Account,
  Client,
  FormInstance
} from 'types'

interface ClientAccountProps {
  accountsList: Account[];
  clientRecord: Client;
  form: FormInstance;
}

const ClientAccount: FC<ClientAccountProps> = ({
  accountsList, clientRecord, form
}) => {
  const [editMode, toggleEditMode] = useState(false)

  useEffect(() => {
    form.resetFields()
  }, [editMode])

  // These "Accout Types" are hard-coded curently
  // For future tasks they should be fetched from a database table
  //   and also able to be customised on a per-account basis
  const accountTypes = [
    {
      id: 1,
      name: 'Corporate Account'
    },
    {
      id: 2,
      name: 'VIP'
    },
    {
      id: 3,
      name: 'Individual'
    },
    {
      id: 4,
      name: 'Individual Mini'
    },
  ]

  return (
    <>
      <Typography.Title level={4}> Account </Typography.Title>
      <Row justify='end'>
        <Switch
          checked={editMode}
          checkedChildren="Edit"
          unCheckedChildren="View"
          onChange={() => toggleEditMode(!editMode)}
        />
      </Row>
      <br/>
      <Form.Item shouldUpdate>
        {({getFieldsValue}) => {
          const {
            account_id,
            account_user_id,
          } = getFieldsValue(['account_id', 'account_user_id', 'accountUserType'])
          const {file_by} = accountsList.find(({id}) => id === account_id) || {file_by: '-'}

          return (
            <Descriptions
              bordered
              layout='vertical'
              size='small'
              column={{
                xxl: 4,
                xl: 3,
                lg: 3,
                md: 3,
                sm: 2,
                xs: 1
              }}
            >
              <Descriptions.Item label='Account'>
                <Row>
                  <Col>
                    <Form.Item>
                      {file_by}
                    </Form.Item>
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label='Account ID'>
                <Row>
                  <Col>
                    <Form.Item>
                      {!editMode ? (account_user_id || '-') : <FormInputNumber notLessThan={1} name='account_user_id' />}
                    </Form.Item>
                  </Col>
                </Row>
              </Descriptions.Item>

              <Descriptions.Item label='Account User Type'>
                <InputSelect
                  allowClear
                  disabled={!editMode}
                  name='account_userType'
                  getOptionProps={opt => ({
                    children: opt.name,
                    value: opt.id
                  })}
                  options={accountTypes}
                />
              </Descriptions.Item>
            </Descriptions>
          )
        }}
      </Form.Item>

      <DynamicFields
        clientID={clientRecord.id}
        clientAccountID={clientRecord.account_id}
        gatTraderClientID={clientRecord.gatTraderClientID}
        gatTraderAccountID={clientRecord.gatTraderAccountID}
        gatMoneyClientID={clientRecord.gatMoneyClientID}
      />
    </>
  )
}

export default ClientAccount

