import React, {
  Fragment,
  useState
} from 'react'

import API from '@aws-amplify/api'
import {showNotification} from 'utils'
import {ModalView} from '../../../../common/ModalView'
import moment from 'moment'
import {
  Form,
  Input,
  Row,
  Space,
  Button,
  DatePicker,
  Select,
  Radio
} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import PhoneInput from 'react-phone-input-2'
import {PREFERRED_COUNTRIES} from 'consts'
import {InputDatePicker} from '../../../../components'

const { Option } = Select

export const ClientCreateNewShareholderComponent = ({
  isModalVisible,
  toggleModal,
  clientId,
  asyncFunction,
  getData
}) => {

  const left = [
    {
      label: 'Telephone',
      name: 'telephone'
    }
  ]

  const [form] = useForm()
  const [telephone, setTelephone] = useState('')
  const [shareholderType, setShareholderType] = useState('1')
  const [beneficiallyOwned, setBeneficiallyOwned] = useState('1')

  const onFinish = async values => {
    try {
      console.log(values)
      console.log('shareholderType', shareholderType)
      if (shareholderType === '1'){
        console.log(shareholderType)
        console.log('individual type')
        await API.post('client-company', '/create-shareholders-individual-type', {
          body: {
            ...values,
            telephone,
            shareholderType,
            beneficiallyOwned,
            clientId
          }
        })
      }

      if (shareholderType === '2'){
        console.log('company type')
        await API.post('client-company', '/create-shareholders-company-type', {
          body: {
            ...values,
            shareholderType,
            clientId
          }
        })
      }

      showNotification('success', 'Shareholders', 'Successfully Created')
      getData()
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Shareholders', error.message)
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  const onPhoneInputChange = () => value => {
    setTelephone(value)
  }

  const onSelectChange = (value) => {
    setShareholderType(value)
  }

  const onRadioChange = (e) => {
    setBeneficiallyOwned(e.target.value)
  }

  return (
    <ModalView
      title={'Create New Shareholder'}
      isModalVisible={isModalVisible}
      footer={null}
      handleOk={() => toggleModal(!isModalVisible)}
      handleCancel={() => toggleModal(!isModalVisible)}
      body={
        <Fragment>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              firstName: '',
              middleNames: '',
              lastName: '',
              shareholderType: '1',
              email: '',
              telephone: '',
              address: ''
            }}
          >
            <Form.Item
              name='firstName'
              label='First Name'
              rules={[
                {
                  required: true,
                  message: 'First Name is Required'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='middleNames'
              label='Middle names'
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='lastName'
              label='Last name'
              rules={[
                {
                  required: true,
                  message: 'Last Name is Required'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='shareholderType' label='Shareholder Type'>
              <Select
                id='shareholderType'
                style={{width: 120,}}
                placeholder={'Please select...'}
                onChange={onSelectChange}
              >
                <Option value="1">Individual</Option>
                <Option value="2">Company</Option>
              </Select>
            </Form.Item>
            {shareholderType === '1' &&
              <Form.Item name='individualName' label='Individual Name'>
                <Input />
              </Form.Item>
            }
            {shareholderType === '1' &&
                <InputDatePicker
                  name='dateOfBirth'
                  label='common.dateOfBirth'
                  isAfterToday
                />
            }
            {shareholderType === '2' &&
              <Form.Item name='companyName' label='Company Name'>
                <Input />
              </Form.Item>
            }
            {shareholderType === '2' &&
              <Form.Item name='companyNumber' label='Company Number'>
                <Input />
              </Form.Item>
            }
            <Form.Item label='Beneficially Owned'>
              <Radio.Group onChange={onRadioChange} value={beneficiallyOwned}>
                <Space direction="vertical">
                  <Radio value={'1'}>Yes</Radio>
                  <Radio value={'0'}>No</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {beneficiallyOwned === '0' &&
              <Form.Item name='beneficialOwner' label='Beneficial Owner'>
                <Input />
              </Form.Item>
            }
            {/* TODO:  We will need to add proper address later
              *   <Form.Item name='address' label='Address'>
              *     <Input />
              *   </Form.Item>
              */}

            <Form.Item name='email' label='Email'>
              <Input />
            </Form.Item>
            <Form.Item name='telephone'>
              {left.map(props => (
                <Form.Item key={props.label} {...props}>
                  <PhoneInput
                    inputStyle={{width: '100%'}}
                    preferredCountries={PREFERRED_COUNTRIES}
                    masks={{au: '(.) ....-....'}}
                    enableSearch
                    disableCountryGuess={false}
                    onChange={onPhoneInputChange(props.name)}
                  />
                </Form.Item>
              ))}
            </Form.Item>

            <Row justify='end'>
              <Space>
                <Button htmlType='submit' type='primary'>
                  Save
                </Button>
              </Space>
            </Row>
          </Form>
        </Fragment>
      }
    />
  )
}
