import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Typography,
  Table,
  Button
} from 'antd'
import {API} from 'aws-amplify'
import {showNotification} from 'utils'

interface IState {
  data: any;
  headers: string[];
  name: string;
  localSpinner: boolean;
}

export const ListWithoutInfoReq: React.FC = () => {
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    localSpinner: false
  })
  const [sentEmailButton, setSentEmailButton] = useState(false)

  const columns = [
    {
      title: 'Xeonda ID',
      dataIndex: 'id'
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name'
    },
    {
      title: 'Language',
      dataIndex: 'preferred_language'
    },
  ]

  const fetch = async (): Promise<any> => {
    setState({
      ...state,
      localSpinner: true,
    })

    const response = await API.get('reportsInfoReq', '/leads/listWithoutInfoReq', {})
    const data = response.payload

    setState({
      ...state,
      data,
      localSpinner: false,
    })
  }

  const onSendEmailButtonClicked = async () => {
    setSentEmailButton(true)

    for (const i in state.data) {
      const record = state.data[i]
      // if (Number.parseInt(record.id) > 12400) continue
      console.log('Xeonda ID: ', record.id)

      let eafId
      let eafLink
      let eafPin
      await API.post('information-requests', '/create', {
        body: {
          language: record.preferred_language,
          clientId: record.id,
          informationRequestId: 1,
          accountId: 6,
          email: record.email,
          firstName: record.first_name,
          lastName: record.last_name,
          activateFinancial: true,
          activateEdd: true,
          thirdParty: false
        }
      })
        .then((res) => {
          eafId = res.payload.eafId
          showNotification('success', 'Information Requests', 'New Record has been created')
        })
        .catch(err => {
          showNotification('error', 'Information Requests', err.message)
        })

      await API.get('information-requests', `/eaf-ir-data/${eafId}`, {})
        .then(res => {
          console.log(res)
          const parsedData = JSON.parse(res.payload)
          console.log(parsedData)
          eafLink = parsedData.information_request_link
          eafPin = parsedData.pincode
        }
        )

      await API.post('reportsInfoReq', '/email/listWithoutInfoReq', {
        body: {
          data: {
            email: record.email,
            preferred_language: record.preferred_language,
            clientFirstName: record.first_name,
            eafIrLink: eafLink,
            eafIrPin: eafPin
          },
        }
      })
        .then(res => {
          showNotification('success', 'Email', 'Email send to client - Send Successful')
        })
        .catch(error => {
          console.log(error)
        })
    }

    setSentEmailButton(false)
  }

  useEffect(() => {
    if (!Array.isArray(state.data) || state.data.length == 0){
      fetch()
    }
  }, [state.data])

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={4}>
            Leads without corresponding Information Request
          </Typography.Title>
        </Col>
      </Row>
      
      <Row>
        <Col span={24}>
          <Table
            loading={state.localSpinner}
            size="small"
            columns={columns}
            dataSource={state.data}
            bordered={true}
            title={() => {
              return <>
                <Row>
                  <Col span={16}>
                    <Typography.Title level={4}> Data Table </Typography.Title>
                  </Col>
                </Row>
              </>
            }
            }
            showHeader={true}
          />
        </Col>
      </Row>
      <Button
        loading={sentEmailButton}
        onClick={onSendEmailButtonClicked}
      >
        Email {state.data.length} Leads
      </Button>
    </Fragment>
  )
}

export default ListWithoutInfoReq
