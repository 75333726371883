import React from 'react'
import {
  Table,
  Image,
  Button,
  Space,
  EyeOutlined,
} from 'components'
import {
  TableColumnType,
  DocumentFile
} from 'types'

interface FilesTableProps {
  fileList: DocumentFile[];
  eafDocument: boolean;
}

const FileList: React.FC<FilesTableProps> = ({fileList, eafDocument}) => {
  const columns: TableColumnType<DocumentFile>[] = [
    {
      title: 'File',
      dataIndex: 'url',
      render: (highlighter, {url, contentType}) => {
        if (url) {
          const imgFormat = ['jpg', 'jpeg', 'png']
          const isIMG = imgFormat.find((format) =>
            contentType.includes(format)
          )

          return (
            <Space>
              {isIMG ? (
                <Image src={url} width={50} height={50} />
              ) : (
                <Button
                  href={url}
                  type='link'
                  target="_blank"
                  icon={<EyeOutlined />}
                />
              )}
            </Space>
          )
        }
      }
    },
    ...(eafDocument ? [{
      title: 'Type',
      dataIndex: 'type',
    }] : [])
  ]

  return (
    <>
      <p>There are {fileList.length} file(s) attached to this document.</p>
      <Table
        rowKey='url'
        scroll={{
          x: true,
          y: 300
        }}
        style={{width: '100%'}}
        columns={columns}
        dataSource={fileList}
      />
    </>
  )
}

export default FileList