import React, {
  useState,
  useEffect
} from 'react'
import {connect} from 'react-redux'
import {
  useLocation,
  useHistory,
  useParams,
  useDispatch,
  usePrevious
} from 'hooks'
import {
  Tabs,
  Row,
  Space,
  Button,
  notification,
  Tag,
  Form,
  Skeleton,
  Spin,
  Empty,
  FormattedMessage,
  LoadingOutlined
} from 'components'
import {constants} from './duck'

import * as LC from './client_view'
import ClientClientPortal from './client_view/ClientClientPortal'
import ClientRisk from './client_view/client-risk'
import ClientPersonal from './client_view/ClientPersonal'
import ClientFinancial from './client_view/ClientFinancial'
import ClientKnowledgeContainer from './client_view/ClientKnowledge'
import ClientVerification from './client_view/ClientVerification'
import queryString from 'query-string'
import {API} from 'utils'
import {clearState} from 'configs/actions/common'
import {
  routes,
  appStateDuck,
  leadsDuck,
  clientsDuck,
  verificationsDuck
} from 'configs'
import moment from 'moment'
import _ from 'lodash'
import {
  createUseStyles,
  useTheme
} from 'react-jss'
import GatMoneyAgreement from './client_view/client-agreement/GatMoney'
import TraderEvolutionAgreement from './client_view/client-agreement/TraderEvolution'
import eApplicationFormAgreement from './client_view/client-agreement/eApplicationForm'
import ClientContact from './client_view/ClientContact'
import {ClientFinancialAccount} from './client_view/ClientFinancialAccount'
import ClientJournal from './client_view/ClientJournal'
import ClientInformationRequest from './client_view/ClientInformationRequest'
import ClientCompany from './client_view/ClientCompany'
import ClientTrusts from './client_view/ClientTrusts'
import ClientSummary from './client_view/ClientSummary'
import ClientTasksListPageComponent from './client_view/ClientTasks'
import ClientsTasksListPageComponent from './client_view/ClientTasksView'
import ClientAuthPersons from './client_view/ClientAuthPersons'
import ClientMonitoring from './client_view/ClientMonitoring'
import ClientReferrals from './client_view/ClientReferrals'

import {constants as clientRiskConstants} from './client_view/client-risk/duck'
import ClientChecklist from './client_view/ClientChecklist'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}
const useStyles = createUseStyles(theme => ({
  label: {fontWeight: theme.label_font_weight},
  inputDisabled: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color
  }
}))

const isGatMoney = (clientRecord) => clientRecord.adminAccountTypeID === 2

const ClientViewPageComponent = ({
  loading,
  saveClientRecord,
  countriesList,
  financialAccount,
  financialAccountAction,
  getClientByID,
  accountsList,
  getVerificationList,
  staffAccess,
  currentAccountID,
  getTopics,
  staffStatusList,
  clientStatusList,
  clearState,
  currentStaffRecord,
  clientTypeList,
  companyTypeList,
  clientCategoriesList,
  streetCodes,
  fetching,
  ...rest
}) => {
  const [form] = Form.useForm()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles({theme})
  const [activeTab, setTab] = useState('')
  const dateFormat = 'YYYY/MM/DD'
  const [state, setState] = useState({
    leads: [],
    totalRiskScore: 0,
    statusIndicators: {
      fetching: false,
      agreements: null,
      contact: null,
      documents: null,
      financial: null,
      knowledge: null,
      personal: null,
      risk: null,
      verification: null,
    }
  })

  const {clientRecord} = rest

  const [riskScoreListOptions, setRiskScoreOptions] = useState([])
  const {client_id} = useParams()
  const prevClientID = usePrevious(clientRecord.id)

  React.useEffect(() => {
    // When a new client is selected from Layout -> header -> autocomplete
    if (clientRecord.id && clientRecord.id !== prevClientID) {
      form.setFieldsValue(clientRecord)
    }
  }, [clientRecord.id])
  
  useEffect(() => {
    API.get('clients-risk', `/countries/riskScoreList/${currentAccountID}`)
      .then(({payload: resp}) => {
        setRiskScoreOptions(resp)
      })
  }, [clientRecord.account_id, currentAccountID])

  const asyncFunction = async () => {
    try {
      const leads = await API.get('leads', `/getSimpleListByAccountId/${currentAccountID}`)
      setState(prevState => ({
        ...prevState,
        leads: leads.payload,
      }))
    } catch (error) {
      console.log('error', error)
    }
  }

  const getRiskTagColor = (totalRiskScore) => {
    if (totalRiskScore < 1) {
      return 'purple'
    } else if (totalRiskScore <= 13) {
      return 'green'
    } else if (totalRiskScore <= 20) {
      return 'blue'
    } else if (totalRiskScore <= 25) {
      return 'orange'
    }
    return 'red'
    
  }
  const getRiskTagText = (totalRiskScore) => {
    if (totalRiskScore < 1) {
      return 'No Data'
    } else if (totalRiskScore <= 13) {
      return 'Low Risk'
    } else if (totalRiskScore <= 20) {
      return 'Medium Risk'
    } else if (totalRiskScore <= 25) {
      return 'High Risk'
    }
    return 'Declined'
    
  }

  useEffect(() => {
    const totalRiskScore = Number(
        clientRecord?.complianceFlag === 1 || clientRecord?.complianceFlag === true
          ? 21
          : clientRecord?.risk_totalScore
    )
    const riskTagColor = getRiskTagColor(totalRiskScore)
    const riskTagText = getRiskTagText(totalRiskScore)

    if (clientRecord.id) {
      getTopics({
        // title: `${clientRecord.first_name} ${clientRecord.last_name}: ${clientRecord.id}`,
        title: `${clientRecord.file_by}: ${clientRecord.id}`,
        extra: <>
          Client Risk: <Tag color={`${riskTagColor}`}>
            { `${riskTagText} (${totalRiskScore}`})
          </Tag>
        </>
      })
    }
  }, [clientRecord.id])

  const getStatusIndicators = async (clientID) => {
    try {
      setState(prevState => ({
        ...prevState,
        statusIndicators: {
          ...prevState.statusIndicators,
          fetching: true
        }
      }))
      const statusIndicators = await API.get('clients', `/${clientID}/status-indicators`)

      setState(prevState => ({
        ...prevState,
        statusIndicators: statusIndicators.reduce((prev, curr) => ({
          ...prev,
          ...curr
        }), {fetching: false})
      }))
    } catch (e) {
      openNotificationWithIcon('error', 'Status Indicators', e.message)

      setState(prevState => ({
        ...prevState,
        statusIndicators: {
          ...prevState.statusIndicators,
          fetching: false
        }
      }))
    }
  }

  useEffect(() => {
    asyncFunction()
    financialAccountAction(client_id)
    getClientByID(client_id)
    getVerificationList(client_id)
    dispatch(leadsDuck.leadsActions.getStreets())

    getStatusIndicators(client_id)
    return clearState
  }, [client_id])

  const getClientRecord = () => {
    getClientByID(client_id)
  }

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search)
    setTab(activeTab)
  }, [location.search])

  const onSubmit = values => {
    const {
      contact_nickname,
      documents_nickname,
      personal_nickname,
      financial_nickname,
      knowledge_nickname,
      verification_nickname,
      agreements_nickname,
      risk_nickname,
      password,
      user_client_aws_cognito_id: t,
      createPass,
      ...rest
    } = clientRecord

    let totalRiskScore = Number(clientRiskConstants.CUSTOMER_TYPES_OPTIONS.find(item => item.id === values?.risk_customer_type)?.riskScore || 0) +
      Number(clientRiskConstants.SOCIAL_STATUS_OPTIONS.find(item => item.id === values?.risk_social_status)?.riskScore || 0) +
      Number(riskScoreListOptions.find(item => item.id === values?.risk_country_nationality)?.riskScore || 0 ) +
      Number(clientRiskConstants.TRANSACTIONS_OPTIONS.find(item => item.id === values?.risk_transactions)?.riskScore || 0) +
      Number(clientRiskConstants.SOURCE_OF_WEALTH_OPTIONS.find(item => item.id === values?.risk_sourceofwealth)?.riskScore || 0) +
      Number(clientRiskConstants.PURPOSE_OF_ACCOUNT_OPTIONS.find(item => item.id === values?.risk_account_purpose)?.riskScore || 0) +
      Number(values?.risk_expected_activity) +
      Number(values?.risk_networth)

    if (values.complianceFlag === 1 || values.complianceFlag === true) {
      totalRiskScore = 21
    }

    let payload = {
      ...rest,
      ...values,
      staffId: currentStaffRecord.id
    }
    if (queryString.parse(location.search)?.tab === 'risk'){
      payload = {
        ...rest,
        ...values,
        risk_totalScore: (values.complianceFlag === 1 || values.complianceFlag === true) ? 21 : totalRiskScore,
        staffId: currentStaffRecord.id
      }
    }

    const dbDateFormat = 'YYYY-MM-DD'

    if (payload.trustEstablishmentDate) {
      payload = {
        ...rest,
        ...values,
        trustEstablishmentDate: moment(payload.trustEstablishmentDate).format(dbDateFormat)
      }
    }

    if (payload.companyDateOfIncorporation){
      payload = {
        ...rest,
        ...values,
        companyDateOfIncorporation: moment(payload.companyDateOfIncorporation).format(dbDateFormat)
      }
    }
    
    delete payload.xri
    delete payload.clients_sourceOfFunds
    delete payload.clients_sourceOfWealth
    delete payload.clients_purposeOfAccount

    if (payload?.employment_status_id !== 9) {
      payload.employment_status_other = ''
    }

    setState(prevState => ({
      ...prevState,
      totalRiskScore: totalRiskScore,
      ...rest
    }))

    const riskTagColor = getRiskTagColor(totalRiskScore)
    const riskTagText = getRiskTagText(totalRiskScore)
    getTopics({
      title: `${clientRecord.first_name} ${clientRecord.last_name}: ${clientRecord.id}`,
      extra: <>
        Client Risk: <Tag color={`${riskTagColor}`}>
          { `${riskTagText} (${totalRiskScore}`})
        </Tag>
      </>
    })

    saveClientRecord({
      ...payload,
      currentStaffId: currentStaffRecord.id
    })

  }

  const hideBtn = [
    'journal',
    'staff-tasks',
    'trading_accounts',
    'financial_accounts',
    'documents',
    'verification',
    'client-tasks',
    'monitoring',
    'informationRequest',
    'referrals',
    'docw',
    'agreements',
  ].some(e => e === activeTab)

  if (clientRecord.lastModifiedDatetime) {
    clientRecord.lastModifiedDatetime = moment(clientRecord.lastModifiedDatetime).format(dateFormat)
  }
  if (clientRecord.createdDatetime) {
    clientRecord.createdDatetime = moment(clientRecord.createdDatetime).format(dateFormat)
  }

  if (clientRecord.agreed_w8ben) {
    clientRecord.agreed_w8ben = moment(clientRecord.agreed_w8ben).format(dateFormat)
  }

  if (clientRecord.agreed_fsg) {
    clientRecord.agreed_fsg = moment(clientRecord.agreed_fsg).format(dateFormat)
  }

  if (clientRecord.agreed_pds) {
    clientRecord.agreed_pds = moment(clientRecord.agreed_pds).format(dateFormat)
  }

  if (clientRecord.trustEstablishmentDate) {
    clientRecord.trustEstablishmentDate = moment(
      moment(clientRecord.trustEstablishmentDate).format(dateFormat),
      dateFormat
    )
  }

  if (clientRecord.companyDateOfIncorporation) {
    clientRecord.companyDateOfIncorporation = moment(
      moment(clientRecord.companyDateOfIncorporation).format(dateFormat),
      dateFormat
    )
  }

  const prevClientId = React.useRef(null)

  React.useEffect(() => {
    prevClientId.current = clientRecord.id
  }, [clientRecord.id])

  // TODO refactor
  if (fetching) {
    return <Skeleton active loading />
  }

  if (!clientRecord.id) {
    return <Empty />
  }

  const getStatusIndicator = (name) => {
    if (state.statusIndicators.fetching) {
      return <Spin spinning size='small' indicator={<LoadingOutlined />} />
    }

    return constants.STATUS_INDICATOR_ICONS.get(state.statusIndicators[name]?.statusIndicatorID)
  }

  return (
    <Form
      form={form}
      loading={loading}
      initialValues={clientRecord}
      layout='vertical'
      onFinish={onSubmit}
    >
      <Tabs
        type='card'
        activeKey={activeTab}
        onTabClick={e => {
          history.push(routes.clientsView({
            clientID: client_id,
            tab: e
          }))
        }}
        items={[
          {
            label: 'Client Summary',
            key: 'summary',
            Component: ClientSummary,
            props: ['currentAccountID', 'client_id']
          },
          {
            label: 'CheckList',
            key: 'checklist',
            Component: ClientChecklist,
            props: ['currentAccountID', 'client_id']
          },
          {
            label: (
              <Space>
                {getStatusIndicator('contact')}
                <span>
                  Contact
                </span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.contact,
            key: 'contact',
            Component: ClientContact
          },
          ...([2, 4].includes(clientRecord.client_type) ? [
            {
              label: 'Company',
              key: 'company',
              Component: ClientCompany,
              props: ['client_id']
            },
          ] : []),
          ...([2, 3, 4, 5].includes(clientRecord.client_type) ? [
            {
              label: 'AuthPersons',
              key: 'authPersons',
              Component: ClientAuthPersons,
              props: ['currentAccountID', 'client_id']
            },
          ] : []),
          ...([3, 4, 5].includes(clientRecord.client_type) ? [
            {
              label: 'Trust',
              key: 'trusts',
              Component: ClientTrusts,
              props: ['currentAccountID', 'client_id']
            }
          ] : []),
          {
            label: 'Journal',
            key: 'journal',
            Component: ClientJournal,
            props: ['currentAccountID', 'client_id']
          },
          {
            label: 'Account',
            key: 'account',
            Component: LC.Account,
            props: ['accountsList']
          },
          ...(clientRecord.feature_gatTraderCommissions ? [
            {
              label: 'Referrals',
              key: 'referrals',
              Component: ClientReferrals,
              props: ['accountsList']
            }
          ] : []),
          // {
          //   label: 'Client Portal',
          //   key: 'clientPortal',
          //   Component: ClientClientPortal,
          //   props: ['clientId']
          // },
          {
            label: 'Staff Tasks',
            key: 'staff-tasks',
            Component: ClientTasksListPageComponent,
            props: ['clientId']
          },
          {
            label: 'Client Tasks',
            key: 'client-tasks',
            Component: ClientsTasksListPageComponent,
            props: ['clientId']
          },
          // {
          //   label: 'Platform',
          //   key: 'platforms',
          //   Component: LC.Platform,
          //   props: []
          // },
          {
            label: 'Financial Accounts',
            key: 'financial_accounts',
            Component: ClientFinancialAccount,
            props: ['countriesList', 'employmentStatusList', 'financialAccount']
          },
          {
            label: (
              <Space>
                {getStatusIndicator('risk')}
                <span>
                  Risk
                </span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.risk,
            key: 'risk',
            Component: ClientRisk,
            props: ['currentAccountID', 'client_id']
          },
          {
            label: (
              <Space>
                {getStatusIndicator('documents')}
                <span>Documents</span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.documents,
            key: 'documents',
            Component: LC.ClientDocumentsList,
            props: [ 'client_id']
          },
          {
            label: 'DocW',
            key: 'docw',
            Component: LC.wordDocuments.List,
            props: ['client_id']
          },
          {
            label: (
              <Space>
                {getStatusIndicator('personal')}
                <span>Personal</span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.personal,
            key: 'personal',
            Component: ClientPersonal,
            props: ['genderList', 'countriesList']
          },
          {
            label: (
              <Space>
                {getStatusIndicator('financial')}
                <span>Financial</span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.financial,
            key: 'financial',
            Component: ClientFinancial,
            props: ['countriesList', 'employmentStatusList', 'sicDivisions', 'noTFNreasonsList']
          },
          ...(isGatMoney(clientRecord) ? [] : [
            {
              label: (
                <Space>
                  {getStatusIndicator('knowledge')}
                  <span>Knowledge</span>
                </Space>
              ),
              statusIndicator: state.statusIndicators.knowledge,
              key: 'knowledge',
              Component: ClientKnowledgeContainer
            }
          ]),
          {
            label: (
              <Space>
                {getStatusIndicator('verification')}
                <span>Verification</span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.verification,
            key: 'verification',
            Component: ClientVerification,
            props: ['verificationData', 'verificationList', 'trulioData', 'getVerificationInfo', 'getVerificationList']
          },
          // {
          //   label: 'Monitoring',
          //   key: 'monitoring',
          //   Component: ClientMonitoring,
          //   props: ['clientId']
          // },
          {
            label: (
              <Space>
                {getStatusIndicator('agreements')}
                <span>Agreements</span>
              </Space>
            ),
            statusIndicator: state.statusIndicators.agreements,
            key: 'agreements',
            // Component: isGatMoney(clientRecord) ? GatMoneyAgreement : TraderEvolutionAgreement,
            Component: isGatMoney(clientRecord) ? GatMoneyAgreement : eApplicationFormAgreement,
            props: []
          },
          // {
          //   label: 'Information Request',
          //   key: 'informationRequest',
          //   Component: ClientInformationRequest,
          //   props: ['currentAccountID', 'employmentStatusList', 'client_id']
          // },
        ].map(({
          Component, props, statusIndicator = null, ...item
        }) => ({
          ...item,
          children: (
            <>
              <LC.TabsHeader
                activeTab={activeTab}
                clientRecord={clientRecord}
                clientStatusIndicator={state.statusIndicators[activeTab]}
                updateRecord={( record) => {
                  setState(prevState => ({
                    ...prevState,
                    statusIndicators: {
                      ...prevState.statusIndicators,
                      [activeTab]: record
                    }
                  }))
                }}
              />
              <Component
                {..._.pick(rest, props)}
                {...state}
                gatMoneyClient={isGatMoney(clientRecord)}
                client_id={client_id}
                classes={classes}
                staffStatusList={staffStatusList}
                clientStatusList={clientStatusList}
                form={form}
                staffAccess={staffAccess}
                currentAccountId={currentAccountID}
                staffId={currentStaffRecord.id}
                staffName={currentStaffRecord.file_by}
                clientTypeList={clientTypeList}
                companyTypeList={companyTypeList}
                clientCategoriesList={clientCategoriesList}
                clientRecord={clientRecord}
                financialAccount={financialAccount}
                countriesList={countriesList}
                accountsList={accountsList}
                getVerificationList={getVerificationList}
                streetCodes={streetCodes}
                clientId={client_id}
                getClientRecord={getClientRecord}
                changeFormValue={(index, value) => {
                  form.setFieldsValue({[index]: value})
                  clientRecord[index] = value
                }}
                onPhoneInputChange={id => value => {
                  form.setFieldsValue({[id]: value})
                }}
                totalRiskScore={state?.totalRiskScore}
              />
            </>
          )
        }))}
      />
      {!hideBtn
          && (queryString.parse(location.search)?.tab !== 'company')
          && (queryString.parse(location.search)?.tab !== 'trusts')
          && (queryString.parse(location.search)?.tab !== 'authPersons')
          && (queryString.parse(location.search)?.tab !== 'risk')
          && (queryString.parse(location.search)?.tab !== 'platforms')
          && (queryString.parse(location.search)?.tab !== 'checklist')
          && (
            <Row justify='end'>
              <Button type='primary' htmlType='submit'>
                <FormattedMessage id='components.save_record' />
              </Button>
            </Row>
          )
      }
    </Form>
  )
}

const {
  saveClientRecord, getClientByID, getVerificationList, financialAccountAction
} = clientsDuck.clientsActions
const {getVerificationInfo} = verificationsDuck.verificationsActions

const mapStateToProps = state => {
  const {
    accountsList,
    countriesList,
    clientStatusList,
    employmentStatusList,
    currentAccountRecord: {id: currentAccountID},
    currentStaffRecord,
    genderList,
    clientTypeList,
    staffAccess,
    clientCategoriesList,
    companyTypeList,
    sicDivisions,
    noTFNreasonsList
  } = appStateDuck.appStateSelectors.stateSelector(state)
  const {
    loading, verificationList, clientRecord, financialAccount, fetching
  } = state.clients.entities.toJS()

  const {
    verificationData, trulioData, loading: verificationLoading
  } = verificationsDuck.verificationsSelectors.stateSelector(state)

  return {
    fetching,
    streetCodes: state.leads.streetCodes,
    clientRecord,
    accountsList,
    financialAccount,
    countriesList,
    clientTypeList,
    staffAccess,
    companyTypeList,
    clientStatusList,
    employmentStatusList,
    genderList,
    clientCategoriesList,
    currentStaffRecord,
    currentAccountID,
    verificationData,
    trulioData,
    verificationList,
    sicDivisions,
    noTFNreasonsList,
    loading: loading || verificationLoading,
  }
}

const ClientViewPageComponentContainer = connect(mapStateToProps, {
  saveClientRecord,
  getVerificationInfo,
  getClientByID,
  financialAccountAction,
  getVerificationList,
  clearState
})(ClientViewPageComponent)

const ClientViewWrapper = (props) => {
  const {client_id} = useParams()
  return <ClientViewPageComponentContainer key={client_id} {...props} />
}

export default ClientViewWrapper
