import {APP_NAME} from 'consts'

export const moduleName = 'staffs'

export const GET_STAFF_RECORD_REQUEST = `${APP_NAME}/${moduleName}/GET_STAFF_RECORD_REQUEST`
export const GET_STAFF_RECORD_SUCCESS = `${APP_NAME}/${moduleName}/GET_STAFF_RECORD_SUCCESS`
export const GET_STAFF_RECORD_ERROR = `${APP_NAME}/${moduleName}/GET_STAFF_RECORD_ERROR`

export const GET_STAFF_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_STAFF_LIST_REQUEST`
export const GET_STAFF_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_STAFF_LIST_SUCCESS`
export const GET_STAFF_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_STAFF_LIST_ERROR`

export const UPDATE_STAFF_RECORD_REQUEST = `${APP_NAME}/${moduleName}/UPDATE_STAFF_RECORD_REQUEST`
export const UPDATE_STAFF_RECORD_SUCCESS = `${APP_NAME}/${moduleName}/UPDATE_STAFF_RECORD_SUCCESS`
export const UPDATE_STAFF_RECORD_ERROR = `${APP_NAME}/${moduleName}/UPDATE_STAFF_RECORD_ERROR`

export const CHECK_STAFF_EMAIL_REQUEST = `${APP_NAME}/${moduleName}/CHECK_STAFF_EMAIL_REQUEST`
export const CHECK_STAFF_EMAIL_SUCCESS = `${APP_NAME}/${moduleName}/CHECK_STAFF_EMAIL_SUCCESS`
export const CHECK_STAFF_EMAIL_ERROR = `${APP_NAME}/${moduleName}/CHECK_STAFF_EMAIL_ERROR`

export const CREATE_NEW_STAFF_REQUEST = `${APP_NAME}/${moduleName}/CREATE_NEW_STAFF_REQUEST`
export const CREATE_NEW_STAFF_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_NEW_STAFF_SUCCESS`
export const CREATE_NEW_STAFF_ERROR = `${APP_NAME}/${moduleName}/CREATE_NEW_STAFF_ERROR`