import {APP_NAME} from 'consts'

export const moduleName = 'trading'

export const GET_COMMISSIONS_FULL_REQUEST = `${APP_NAME}/${moduleName}/GET_COMMISSIONS_FULL_REQUEST`
export const GET_COMMISSIONS_FULL_SUCCESS = `${APP_NAME}/${moduleName}/GET_COMMISSIONS_FULL_SUCCESS`
export const GET_COMMISSIONS_FULL_ERROR = `${APP_NAME}/${moduleName}/GET_COMMISSIONS_FULL_ERROR`

export const GET_COMMISSIONS_SUM_REQUEST = `${APP_NAME}/${moduleName}/GET_COMMISSIONS_SUM_REQUEST`
export const GET_COMMISSIONS_SUM_SUCCESS = `${APP_NAME}/${moduleName}/GET_COMMISSIONS_SUM_SUCCESS`
export const GET_COMMISSIONS_SUM_ERROR = `${APP_NAME}/${moduleName}/GET_COMMISSIONS_SUM_ERROR`

export const GET_TRADING_ACCOUNTS_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_ACCOUNTS_REQUEST`
export const GET_TRADING_ACCOUNTS_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_ACCOUNTS_SUCCESS`
export const GET_TRADING_ACCOUNTS_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_ACCOUNTS_ERROR`

export const GET_TRADING_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_ACCOUNT_REQUEST`
export const GET_TRADING_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_ACCOUNT_SUCCESS`
export const GET_TRADING_ACCOUNT_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_ACCOUNT_ERROR`

export const GET_TRADING_TRANSACTIONS_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTIONS_REQUEST`
export const GET_TRADING_TRANSACTIONS_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTIONS_SUCCESS`
export const GET_TRADING_TRANSACTIONS_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTIONS_ERROR`

export const GET_TRADING_TRANSACTIONS_BY_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTIONS_BY_ACCOUNT_REQUEST`
export const GET_TRADING_TRANSACTIONS_BY_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTIONS_BY_ACCOUNT_SUCCESS`
export const GET_TRADING_TRANSACTIONS_BY_ACCOUNT_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTIONS_BY_ACCOUNT_ERROR`

export const GET_TRADING_TRANSACTION_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTION_REQUEST`
export const GET_TRADING_TRANSACTION_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTION_SUCCESS`
export const GET_TRADING_TRANSACTION_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTION_ERROR`

export const GET_TRADING_TRANSACTION2_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTION2_REQUEST`
export const GET_TRADING_TRANSACTION2_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTION2_SUCCESS`
export const GET_TRADING_TRANSACTION2_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_TRANSACTION2_ERROR`

export const FILTER_TRADING_TRANSACTIONS = `${APP_NAME}/${moduleName}/FILTER_TRADING_TRANSACTIONS`

export const CREATE_DEPOSIT_WITHDRAWAL_REQUEST = `${APP_NAME}/${moduleName}/CREATE_DEPOSIT_WITHDRAWAL_REQUEST`
export const CREATE_DEPOSIT_WITHDRAWAL_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_DEPOSIT_WITHDRAWAL_SUCCESS`
export const CREATE_DEPOSIT_WITHDRAWAL_ERROR = `${APP_NAME}/${moduleName}/CREATE_DEPOSIT_WITHDRAWAL_ERROR`

export const UPDATE_STATUS_ID_REQUEST = `${APP_NAME}/${moduleName}/UPDATE_STATUS_ID_REQUEST`
export const UPDATE_STATUS_ID_SUCCESS = `${APP_NAME}/${moduleName}/UPDATE_STATUS_ID_SUCCESS`
export const UPDATE_STATUS_ID_ERROR = `${APP_NAME}/${moduleName}/UPDATE_STATUS_ID_ERROR`

export const REMOVE_DOCUMENT_REQUEST = `${APP_NAME}/${moduleName}/REMOVE_DOCUMENT_REQUEST`
export const REMOVE_DOCUMENT_SUCCESS = `${APP_NAME}/${moduleName}/REMOVE_DOCUMENT_SUCCESS`
export const REMOVE_DOCUMENT_ERROR = `${APP_NAME}/${moduleName}/REMOVE_DOCUMENT_ERROR`

export const UPLOAD_DOCUMENT_REQUEST = `${APP_NAME}/${moduleName}/UPLOAD_DOCUMENT_REQUEST`
export const UPLOAD_DOCUMENT_SUCCESS = `${APP_NAME}/${moduleName}/UPLOAD_DOCUMENT_SUCCESS`
export const UPLOAD_DOCUMENT_ERROR = `${APP_NAME}/${moduleName}/UPLOAD_DOCUMENT_ERROR`

export const UPLOAD_DOCUMENT2_REQUEST = `${APP_NAME}/${moduleName}/UPLOAD_DOCUMENT2_REQUEST`
export const UPLOAD_DOCUMENT2_SUCCESS = `${APP_NAME}/${moduleName}/UPLOAD_DOCUMENT2_SUCCESS`
export const UPLOAD_DOCUMENT2_ERROR = `${APP_NAME}/${moduleName}/UPLOAD_DOCUMENT2_ERROR`

export const REMOVE_DOCUMENT2_REQUEST = `${APP_NAME}/${moduleName}/REMOVE_DOCUMENT2_REQUEST`
export const REMOVE_DOCUMENT2_SUCCESS = `${APP_NAME}/${moduleName}/REMOVE_DOCUMENT2_SUCCESS`
export const REMOVE_DOCUMENT2_ERROR = `${APP_NAME}/${moduleName}/REMOVE_DOCUMENT2_ERROR`

export const UPDATE_TRANSACTION_RECORD = `${APP_NAME}/${moduleName}/UPDATE_TRANSACTION_RECORD`

export const UPDATE_WITHDRAWAL_TARGET = 'UPDATE_WITHDRAWAL_TARGET'