import React, {
  useState,
  useEffect
} from 'react'
import {useParams} from 'hooks'

import {
  API,
  showNotification
} from 'utils'
import {
  COMMON_COL_PROPS,
  ROW_GUTTER
} from 'consts'

import {
  Row,
  Col,
  Skeleton,
  Empty,
  Form
} from 'components'
import {
  ClientInformationRequest,
  Client
} from 'types'
import * as LC from './components'
import ReactJson from 'react-json-view'

const View = () => {
  const [state, setState] = useState<{
    fetching: boolean;
    submitting: string[];
    informationRequestRecord: ClientInformationRequest | null;
    eApplicationFormData: any | null;
    clientRecord: Client | null;
  }>({
    fetching: true,
    submitting: [],
    informationRequestRecord: null,
    eApplicationFormData: null,
    clientRecord: null
  })

  const {id: clientID, recordID: informationRequestID} = useParams<{
    recordID: string;
    id: string;
  }>()

  useEffect(() => {
    const init = async () => {
      try {
        setState(prevState => ({
          ...prevState,
          fetching: true
        }))
        const [{payload: informationRequestRecord}, {payload: clientRecord}] = await Promise.all([
          API.get('information-requests',`/id/${informationRequestID}`),
          API.get('clients',`/get-by-id/${clientID}`),
        ])

        let eApplicationFormData: any

        if (informationRequestRecord.eafId) {
          const {payload} = await API.get('information-requests', `/eaf-ir-data/${informationRequestRecord.eafId}`)

          eApplicationFormData = payload
        }

        setState(prevState => ({
          ...prevState,
          clientRecord,
          fetching: false,
          informationRequestRecord,
          eApplicationFormData,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          fetching: false
        }))
      }
    }

    init()
  }, [])

  if (state.fetching) {
    return <Skeleton active loading />
  }

  const {
    clientRecord, informationRequestRecord, eApplicationFormData
  } = state

  if (!clientRecord || !informationRequestRecord || !eApplicationFormData) {
    return <Empty />
  }

  const onClientUpdate = async (formName: string, values: Record<string, any>) => {
    try {
      setState(prevState => ({
        ...prevState,
        submitting: prevState.submitting.concat(formName)
      }))
      
      const clientRecord = state.clientRecord as Client
      const body = {
        ...clientRecord,
        sicDivisionID: values.sicDivisionID || clientRecord.sicDivisionID,
        sicGroupID: values.sicGroupID || clientRecord.sicGroupID,
      }
      
      await API.put('clients', `/update/${clientRecord.id}`, {body})

      setState(prevState => ({
        ...prevState,
        clientRecord: body,
        submitting: prevState.submitting.filter(name => name !== formName)
      }))
      
      showNotification('success', 'Client', 'Client Record Updated')
    } catch (e: any) {
      showNotification('error', 'Client', e.response?.data?.error || e.message)

      setState(prevState => ({
        ...prevState,
        submitting: prevState.submitting.filter(name => name !== formName)
      }))
    }
  }

  const updateClientRecord = (newData: Partial<Client>) => {
    setState(prevState => ({
      ...prevState,
      clientRecord: {
        ...(prevState.clientRecord as Client),
        ...newData
      }
    }))
  }

  const commonProps = {
    updateClientRecord,
    thirdParty: informationRequestRecord.thirdParty,
    eApplicationFormData,
    clientRecord,
    submitting: state.submitting
  }

  return (
    <Form.Provider
      onFormFinish={(name, {values}) => {
        if (name === 'journal') {
          return
        }

        onClientUpdate(name, values)
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <LC.Details informationRequestRecord={informationRequestRecord} eApplicationFormData={eApplicationFormData} />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <LC.Management
            clientID={clientID}
            pincode={eApplicationFormData.pincode}
            information_request_link={eApplicationFormData.information_request_link}
            informationRequestRecord={informationRequestRecord}
          />
          <LC.Journals informationRequestID={informationRequestID} />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <LC.PersonalDetails
            {...commonProps}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <LC.Contact {...commonProps} />
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col {...COMMON_COL_PROPS}>
          <LC.Compliance eApplicationFormData={state.eApplicationFormData} />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <LC.Taxation taxation={state.eApplicationFormData.taxation} />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col {...COMMON_COL_PROPS}>
          <LC.Employment
            informationRequestRecord={informationRequestRecord}
            {...commonProps}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <LC.Finance accountTransactions={state.eApplicationFormData.accountTransactions} />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col {...COMMON_COL_PROPS}>
          <LC.Poa
            client_id={clientID}
            informationRequestId={informationRequestID}
            poaDocuments={state.eApplicationFormData.poaDocuments}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <LC.EddDocuments
            clientID={clientID}
            informationRequestID={informationRequestID}
            eddDocuments={state.eApplicationFormData.eddDocuments}
          />
        </Col>
      </Row>

      <LC.Verification
        verification={state.eApplicationFormData.verification}
        client_id={clientID}
        informationRequestId={informationRequestID}
      />
      <ReactJson
        src={state.eApplicationFormData}
        enableClipboard={true}
        style={{overflowY: 'auto'}}
      />
    </Form.Provider>
  )
}

export default View
