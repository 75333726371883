import {
  List,
  Record
} from 'immutable'

import {
  INPUT_CHANGE,
  CLEAR_STATE
} from '../../constants'

import {combineReducers} from 'redux'

import {
  FileListItemRecord,
  DocumentRecord
} from '../common'

import * as types from './types'
import moment from 'moment'

const ClientRecord = Record({
  id: null,
  account_user_id: null,
  account_id: null,
  account_userType: null,
  date_of_birth: null,
  gender_id: null,
  companyDateOfIncorporation: null,
  trustEstablishmentDate: null,
  place_of_birth_city: null,
  password: null,
  createPass: false,
  staffId: null,
  place_of_birth_country_id: null,
  place_of_birth_country: null,
  companyCountryId: null,
  businessNumber: null,
  companyNumber: null,
  trustCountryId: null,
  file_by: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  aws_cognito_id: null,
  email: '',
  tel_home: '',
  tel_work: '',
  tel_mobile: '',
  email_secondary: '',
  occupation: '',
  categoryId: null,
  status_id: null,
  street_address_city: null,
  street_address_region: null,
  street_address_line_1: null,
  street_address_line_2: null,
  street_address_suburb: null,
  street_address_state: null,
  street_address_postcode: null,
  street_address_country: null,
  postal_address_city: null,
  postal_address_region: null,
  postal_address_line_1: null,
  postal_address_line_2: null,
  postal_address_suburb: null,
  postal_address_state: null,
  postal_address_postcode: null,
  postal_address_country: null,
  preferred_first_name: null,
  preferred_last_name: null,
  english_first_name: null,
  english_last_name: null,
  employment_status_id: null,
  employment_status_other: null,
  employer: null,
  annual_income: null,
  net_worth: null,
  digiDocUuid: null,
  digiDocUrl: null,
  intended_deposit: null,
  tax_country_id: null,
  tfn_exemption: null,
  tax_file_number: null,
  us_citizen: null,
  us_tax_resident: null,
  knowledge_traded_leveraged: null,
  knowledge_traded_options: null,
  knowledge_qualification: null,
  streetUnitNumber: null,
  streetStreetNumber: null,
  streetStreetName: null,
  streetStreetTypeId: null,
  streetSuburb: null,
  streetState: null,
  streetPostcode: null,
  streetCountryId: null,
  postalBoxNumber: null,
  postalBoxTypeId: null,
  postalStreetNumber: null,
  postalStreetName: null,
  postalStreetTypeId: null,
  postalSuburb: null,
  postalState: null,
  postalPostcode: null,
  postalCountryId: null,
  statusContact: null,
  trust_trustName: null,
  trust_trusteeName: null,
  statusAddress: null,
  statusFinancial: null,
  statusKnowledge: null,
  statusVerification: null,
  statusDocuments: null,
  statusPersonal: null,
  statusAgreements: null,
  statusRisk: null,
  createdDateTime: null,
  lastModifiedDatetime: null,
  client_type: null,
  responsibleStaffMember: null,
  companyType: null,
  agreed_w8ben: null,
  agreed_w8benNotes: null,
  agreed_terms: null,
  agreed_termsNotes: null,
  agreed_fees: null,
  agreed_feesNotes: null,
  agreed_fsg: null,
  agreed_fsgNotes: null,
  agreed_pds: null,
  agreed_pdsNotes: null,
  agreed_privacy: null,
  agreed_privacyNotes: null,
  companyName: '',
  quickNotes: '',
  // contact_nickname: '',
  statusContactModifiedDatetime: null,
  // documents_nickname: '',
  statusDocumentsModifiedDatetime: null,
  // personal_nickname: '',
  statusPersonalModifiedDatetime: null,
  // financial_nickname: '',
  statusFinancialModifiedDatetime: null,
  // knowledge_nickname: '',
  statusKnowledgeModifiedDatetime: null,
  // verification_nickname: '',
  statusVerificationModifiedDatetime: null,
  // agreements_nickname: '',
  statusAgreementsModifiedDatetime: null,
  // risk_nickname: '',
  statusRiskModifiedDatetime: null,
  user_client_aws_cognito_id: null,
  user_client_id: null,
  signed_agreement_datetime: null,
  signed_agreement_notes: null,
  risk_customer_type: null,
  risk_customer_type_notes: null,
  risk_social_status: null,
  risk_social_status_notes: null,
  risk_country_nationality: null,
  risk_country_nationality_notes: null,
  risk_sourceofwealth: null,
  risk_sourceofwealth_notes: null,
  risk_transactions: null,
  risk_transactions_notes: null,
  risk_account_purpose: null,
  risk_account_purpose_notes: null,
  risk_expected_activity: null,
  risk_expected_activity_notes: null,
  risk_networth: null,
  risk_networth_notes: null,
  risk_totalScore: null,
  platformUsername: null,
  platformPassword: null,
  preferred_language: null,
  financialInfo_sourceOfWealth: [],
  financialInfo_purposeOfAccount: [],
  financialInfo_expAccActivityCount: null,
  financialInfo_expAccActivityPurpose: null,
  financialInfo_expAccActivityPurpose_other: null,
  xri: null,
  employment_industry: null,
  addressWestern_line1: null,
  addressWestern_suburb: null,
  addressWestern_state: null,
  addressWestern_postcode: null,
  complianceFlag: null,
  verificationProvider: null,
  verificationReference: null,
  gatTraderLogin: null,
  gatTraderClientID: null,
  gatTraderAccountID: null,
  gatMoneyClientID: null,
  sicDivisionID: null,
  sicGroupID: null,
  sourceOfFunds: [],
  sourceOfWealth: [],
  purposeOfAccount: [],
  noTfnReasonID: null,
  tfnFurtherDetails: null,
  feature_gatMoneyTraderTransfer: null,
  feature_gatTraderCommissions: null,
  feature_gatMoneyTraderWithdrawal: null,
  agreed_termsDate: null,
  agreed_privacyDate: null,
  agreed_feesDate: null,
  agreed_w8benDate: null,
  agreed_fsgDate: null,
  agreed_pdsDate: null,
  signed_agreementDate: null
})

const WalletRecord = Record({
  id: null,
  file_by: '',
  currency: null,
  tradingCommissionWalletsID: null,
  tradingCommissionWalletTransactionsID: null,
  short_name: null
})

const TransactionRecord = Record({
  id: null,
  file_by: '',
  currency: null,
  tradingCommissionWalletsID: null,
  tradingCommissionWalletTransactionsID: null,
  short_name: null,
  transactionDate: null,
  memo: null,
  debit: null,
  credit: null
})

const AccountClosureRecord = Record({
  id: null,
  clientId: null,
  email: '',
  dateRequested:null,
  accountId:null,
  reason:null,
  other: null,
  statusId: null,
  account_user_id: null,
  account_id: null,
  date_of_birth: null,
  gender_id: null,
  companyDateOfIncorporation: null,
  trustEstablishmentDate: null,
  place_of_birth_city: null,
  password: null,
  createPass: false,
  staffId: null,
  place_of_birth_country_id: null,
  place_of_birth_country: null,
  companyCountryId: null,
  businessNumber: null,
  companyNumber: null,
  trustCountryId: null,
  file_by: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  aws_cognito_id: null,
  tel_home: '',
  tel_work: '',
  tel_mobile: '',
  email_secondary: '',
  occupation: '',
  categoryId: null,
  status_id: null,
  street_address_city: null,
  street_address_region: null,
  street_address_line_1: null,
  street_address_line_2: null,
  street_address_suburb: null,
  street_address_state: null,
  street_address_postcode: null,
  street_address_country: null,
  postal_address_city: null,
  postal_address_region: null,
  postal_address_line_1: null,
  postal_address_line_2: null,
  postal_address_suburb: null,
  postal_address_state: null,
  postal_address_postcode: null,
  postal_address_country: null,
  preferred_first_name: null,
  preferred_last_name: null,
  employment_status_id: null,
  employer: null,
  annual_income: null,
  net_worth: null,
  digiDocUuid: null,
  digiDocUrl: null,
  intended_deposit: null,
  tax_country_id: null,
  tfn_exemption: null,
  tax_file_number: null,
  us_citizen: null,
  us_tax_resident: null,
  knowledge_traded_leveraged: null,
  knowledge_traded_options: null,
  knowledge_qualification: null,
  streetUnitNumber: null,
  streetStreetNumber: null,
  streetStreetName: null,
  streetStreetTypeId: null,
  streetSuburb: null,
  streetState: null,
  streetPostcode: null,
  streetCountryId: null,
  postalBoxNumber: null,
  postalBoxTypeId: null,
  postalStreetNumber: null,
  postalStreetName: null,
  postalStreetTypeId: null,
  postalSuburb: null,
  postalState: null,
  postalPostcode: null,
  postalCountryId: null,
  statusContact: null,
  trust_trustName: null,
  trust_trusteeName: null,
  statusAddress: null,
  statusFinancial: null,
  statusKnowledge: null,
  statusVerification: null,
  statusDocuments: null,
  statusPersonal: null,
  statusAgreements: null,
  createdDateTime: null,
  lastModifiedDatetime: null,
  client_type: null,
  responsibleStaffMember: null,
  companyType: null,
  agreed_w8ben: null,
  agreed_w8benNotes: null,
  agreed_terms: null,
  agreed_termsNotes: null,
  agreed_fees: null,
  agreed_feesNotes: null,
  agreed_fsg: null,
  agreed_fsgNotes: null,
  agreed_pds: null,
  agreed_pdsNotes: null,
  agreed_privacy: null,
  agreed_privacyNotes: null,
  companyName: '',
  quickNotes: '',
  // contact_nickname: '',
  statusContactModifiedDatetime: null,
  // documents_nickname: '',
  statusDocumentsModifiedDatetime: null,
  // personal_nickname: '',
  statusPersonalModifiedDatetime: null,
  // financial_nickname: '',
  statusFinancialModifiedDatetime: null,
  // knowledge_nickname: '',
  statusKnowledgeModifiedDatetime: null,
  // verification_nickname: '',
  statusVerificationModifiedDatetime: null,
  // agreements_nickname: '',
  statusAgreementsModifiedDatetime: null,
  user_client_aws_cognito_id: null,
  user_client_id: null,
  signed_agreement_datetime: null,
  signed_agreement_notes: null,
  employment_industry: null
})

const ReducerRecord = Record({
  entities: List([]),
  loading: false,
  clientRecord: new ClientRecord(),
  walletRecord: new WalletRecord(),
  transactionRecord: new TransactionRecord(),
  verificationList: [],
  financialAccount: [],
  tasksClient: [],
  taskClient: {},
  DigiDoc: {},
  DigiDocEmail: {},
  journals: [],
  informationRequests: [],
  clientTasksById: [],
  officeholders: [],
  shareholders: [],
  appointors: [],
  beneficiaries: []
})

const entities = (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.FETCH_TCCLIENTS_REQUEST:
    case types.FETCH_TCWALLETS_REQUEST:
    case types.FETCH_TCWALLETS_TRANSACTIONS_REQUEST:
    case types.GET_TCCLIENT_REQUEST:
    case types.SAVE_TCCLIENT_REQUEST:
    case types.GET_VERIFICATIONS_LIST_REQUEST:
    case types.DIGIDOC_CREATE_REQUEST:
    case types.FETCH_TCCLIENTS_V2_REQUEST:
    case types.FETCH_TCCLIENTS_CLOSURE_REQUEST:
    case types.DIGIDOC_SEND_REQUEST:
    case types.GET_JOURNAL_BY_CLIENT_REQUEST:
    case types.GET_INFORMATIONREQUEST_BY_CLIENT_REQUEST:
    case types.GET_OFFICEHOLDER_BY_CLIENT_REQUEST:
    case types.GET_SHAREHOLDER_BY_CLIENT_REQUEST:
    case types.GET_APPOINTOR_BY_CLIENT_REQUEST:
    case types.GET_BENEFICIARY_BY_CLIENT_REQUEST:
    case types.GET_CLIENT_TASKS_LIST_REQUEST:
    case types.GET_CLIENT_TASKS_BY_CLIENT_ID_REQUEST:
    case types.DELETE_CLIENT_REQUEST:
    case types.GET_CLIENT_TASK_REQUEST:
      return state.set('loading', true)
    case types.GET_SOURCEOFWEALTH_BY_ACCOUNT_REQUEST:
    case types.FETCH_TCCLIENTS_SUCCESS:
      return state.mergeWith((oldVal, newVal, key) => (key === 'entities' ? List(newVal) : newVal), {
        loading: false,
        entities: payload.map(client => new ClientRecord(client))
      })
    case types.FETCH_TCWALLETS_SUCCESS:
      return state.mergeWith((oldVal, newVal, key) => (key === 'entities' ? List(newVal) : newVal), {
        loading: false,
        entities: payload.map(wallet => new WalletRecord(wallet))
      })
    case types.FETCH_TCWALLETS_TRANSACTIONS_SUCCESS:
      return state.mergeWith((oldVal, newVal, key) => (key === 'entities' ? List(newVal) : newVal), {
        loading: false,
        entities: payload.map(transactions => new TransactionRecord(transactions))
      })
    case types.FETCH_TCCLIENTS_CLOSURE_SUCCESS:
      return state.mergeWith((oldVal, newVal, key) => (key === 'entities' ? List(newVal) : newVal), {
        loading: false,
        entities: payload.map(client => new AccountClosureRecord(client))
      })
    case types.FETCH_TCCLIENTS_ERROR:
    case types.FETCH_TCWALLETS_ERROR:
    case types.FETCH_TCWALLETS_TRANSACTIONS_ERROR:
    case types.GET_CLIENT_TASK_ERROR:
    case types.GET_TCCLIENT_ERROR:
    case types.SAVE_TCCLIENT_ERROR:
    case types.SAVE_TCCLIENT_SUCCESS:
    case types.FETCH_TCCLIENTS_V2_ERROR:
    case types.FETCH_TCCLIENTS_CLOSURE_ERROR:
    case types.GET_VERIFICATIONS_LIST_ERROR:
    case types.DIGIDOC_CREATE_ERROR:
    case types.DIGIDOC_SEND_ERROR:
    case types.GET_JOURNAL_BY_CLIENT_ERROR:
    case types.GET_INFORMATIONREQUEST_BY_CLIENT_ERROR:
    case types.GET_OFFICEHOLDER_BY_CLIENT_ERROR:
    case types.GET_SHAREHOLDER_BY_CLIENT_ERROR:
    case types.GET_APPOINTOR_BY_CLIENT_ERROR:
    case types.GET_BENEFICIARY_BY_CLIENT_ERROR:
    case types.GET_CLIENT_TASKS_LIST_ERROR:
    case types.GET_SOURCEOFWEALTH_BY_ACCOUNT_ERROR:
    case types.GET_CLIENT_TASKS_BY_CLIENT_ID_ERROR:
    case types.DELETE_CLIENT_ERROR:
      return state.set('loading', false)

    case types.DELETE_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        entities: state.entities.filter(client => client.id !== payload)
      })

    case types.GET_VERIFICATIONS_LIST_SUCCESS:
      return state.merge({
        loading: false,
        verificationList: payload.map(({request_datetime, ...verification}) => ({
          ...verification,
          request_datetime: moment(request_datetime).format('DD-MM-YYYY HH:mm')
        }))
      })

    case types.FINANCIAL_ACCOUNT_SEND_SUCCESS:
      return state.merge({
        financialAccount: payload,
        loading: false
      })
    case types.FINANCIAL_ACCOUNT_UPDATE_SUCCESS:
      const index = state.financialAccount.findIndex(acc => acc.id === payload.id)
      const financialAccount = [...state.financialAccount]
      financialAccount.splice(index, 1, payload)
      return state.set('financialAccount', financialAccount)

    case types.GET_JOURNAL_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        journals: payload
      })

    case types.GET_INFORMATIONREQUEST_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        informationRequests: payload
      })
      
    case types.GET_OFFICEHOLDER_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        officeholders: payload
      })

    case types.GET_SHAREHOLDER_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        shareholders: payload
      })

    case types.GET_APPOINTOR_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        appointors: payload
      })

    case types.GET_BENEFICIARY_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        beneficiaries: payload
      })

    case types.GET_CLIENT_TASKS_LIST_SUCCESS:
      return state.merge({
        loading: false,
        tasksClient: payload
      })

    case types.GET_CLIENT_TASK_SUCCESS:
      return state.merge({
        loading: false,
        taskClient: payload
      })

    case types.GET_CLIENT_TASKS_BY_CLIENT_ID_SUCCESS:
      return state.merge({
        loading: false,
        tasksClient: payload
      })

    case types.GET_TCCLIENT_SUCCESS:
      let clientRecord = new ClientRecord(payload)
      // For mapping between form values and radio button names /ClientFinancial
      clientRecord = clientRecord.merge({
        us_tax_resident: Boolean(clientRecord.us_tax_resident),
        tfn_exemption: Boolean(clientRecord.tfn_exemption),
        us_citizen: Boolean(clientRecord.us_citizen),
        knowledge_traded_leveraged: Boolean(clientRecord.knowledge_traded_leveraged),
        knowledge_traded_options: Boolean(clientRecord.knowledge_traded_options),
        knowledge_qualification: Boolean(clientRecord.knowledge_qualification)
      })
      return state.merge({
        clientRecord: clientRecord,
        loading: false
      })

    case types.GET_TCCLIENT_CLOSURE_SUCCESS:
      let clientClosureRecord = new AccountClosureRecord(payload)
      // For mapping between form values and radio button names /ClientFinancial
      clientClosureRecord = clientClosureRecord.merge({
        clientId: Boolean(clientClosureRecord.clientId),
        email: Boolean(clientClosureRecord.email),
        dateRequested: Boolean(clientClosureRecord.dateRequested),
        accountId: Boolean(clientClosureRecord.accountId),
        reason: Boolean(clientClosureRecord.reason),
        other: Boolean(clientClosureRecord.other),
        statusId: Boolean(clientClosureRecord.statusId)
      })
      return state.merge({
        clientClosureRecord: clientClosureRecord,
        loading: false
      })

    case types.DIGIDOC_CREATE_SUCCESS:
      return state.merge({
        loading: false,
        DigiDoc: payload
      })
    case types.DIGIDOC_SEND_SUCCESS:
      return state.merge({
        loading: false,
        DigiDocEmail: payload
      })

    case types.GET_SOURCEOFWEALTH_BY_ACCOUNT_SUCCESS:
      return state.merge({
        loading: false,
        SourceOfWelathList: payload
      })
    case INPUT_CHANGE:
      return state.setIn(['clientRecord', payload.inputName], payload.inputValue)
    case CLEAR_STATE:
      return state.update('clientRecord', record => record.clear())
    default:
      return state
  }
}

const DocumentsReducerRecord = Record({
  documentsList: List([]),
  fileList: List([]),
  currentDocument: new DocumentRecord({}),
  modalLoading: false,
  loading: false
})

const documents = (state = new DocumentsReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.GET_DOCUMENT_REQUEST:
    case types.GET_DOCUMENTS_REQUEST:
      return state.set('loading', true)

    case types.GET_DOCUMENTS_SUCCESS:
      return state.mergeWith((old, newVal, key) => (key === 'documentsList' ? List(newVal) : newVal), {
        loading: false,
        documentsList: payload.map(item => new DocumentRecord(item))
      })
    case types.GET_DOCUMENT_SUCCESS:
      return state.merge({
        fileList: payload.fileList.map(item => new FileListItemRecord(item)),
        loading: false,
        currentDocument: new DocumentRecord(payload.document)
      })
    case types.GET_DOCUMENT_ERROR:
      return state.set('loading', false)

    case types.CREATE_NEW_DOCUMENT_REQUEST:
      return state.set('modalLoading', true)

    case types.CREATE_NEW_DOCUMENT_SUCCESS:
    case types.CREATE_NEW_DOCUMENT_ERROR:
      return state.set('modalLoading', false)

    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}

const NewClientDefaultState = Record({
  client: new ClientRecord(),
  loading: false,
  duplicateEmail: false
})

const newClient = (state = new NewClientDefaultState(), {type, payload}) => {
  switch (type) {
    case types.CREATE_TCCLIENT_REQUEST:
      return state.set('loading', true)

    case types.CHECK_CLIENTS_EMAIL_REQUEST:
      return state.merge({
        loading: true,
        duplicateEmail: false
      })
    case types.CHECK_CLIENTS_EMAIL_SUCCESS:
      return state.set('loading', false)
    case types.CHECK_CLIENTS_EMAIL_ERROR:
      return state.merge({
        loading: false,
        duplicateEmail: true
      })
    case types.CREATE_TCCLIENT_SUCCESS:
    case types.CREATE_TCCLIENT_ERROR:
      return state.set('loading', false)
    case INPUT_CHANGE:
      let updatedState = state.setIn(['client', payload.inputName], payload.inputValue)
      if (payload.inputName === 'email') {
        updatedState = updatedState.set('duplicateEmail', false)
      }
      return updatedState
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}

export default combineReducers({
  entities,
  documents,
  newClient
})