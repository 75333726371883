import * as React from 'react'
import {
  Descriptions,
  DescriptionsProps,
  Empty,
  Spin,
  Typography
} from 'antd'
import { FormattedMessage } from 'react-intl'

interface ExtendedDescriptionsProps extends Omit<DescriptionsProps, 'title' | 'items'> {
  title?: string;
  translateTitle?: boolean;
  loading?: boolean;
  items?: {
    label: string;
    translate?: boolean;
    value: React.ReactNode;
    copyable?: boolean;
  }[];
}

const ExtendedDescriptions: React.FC<ExtendedDescriptionsProps> = ({
  items = [],
  size = 'small',
  layout = 'vertical',
  bordered = true,
  title,
  translateTitle = true,
  loading = false,
  ...props
}) => {
  return (
    <Spin spinning={loading}>
      <Descriptions
        title={
          title && translateTitle ? <FormattedMessage id={title} /> : title
        }
        bordered={bordered}
        layout={layout}
        size={size}
        contentStyle={{ fontWeight: 'bold' }}
        column={1}
        {...props}
      >
        {items.length ? (
          items.map(({
            label, value, copyable , translate = true
          }, i) => (
            <Descriptions.Item
              // eslint-disable-next-line react/no-array-index-key
              key={label + i}
              label={translate ? <FormattedMessage id={label} /> : label}
            >
              <Typography.Text
                copyable={
                  typeof copyable !== 'undefined'
                    ? copyable :
                    (typeof value === 'string' || typeof value === 'number')
                }
              >
                {value || '-'}
              </Typography.Text>
            </Descriptions.Item>
          ))
        ) : (
          <Descriptions.Item>
            <Empty />
          </Descriptions.Item>
        )}
      </Descriptions>
    </Spin>
  )
}

export default ExtendedDescriptions
