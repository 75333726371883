import React, {
  useState,
  useEffect
} from 'react'

import {
  useHistory,
  useSelector
} from 'hooks'
import {
  Card,
  Skeleton,
  Table,
  Button,
  Divider,
  Popconfirm,
  Typography,

  EyeOutlined,
  DeleteOutlined,
  SyncOutlined
} from 'components'
import {API} from 'utils'

import {ClientCompanyDrawerComponent} from './drawers/ClientCompanyDrawerComponent'
import {ClientCompanyShareholderDrawerComponent} from './drawers/ClientCompanyShareholderDrawerComponent'
import {ClientCompanyShareholderDrawerComponent_company} from './drawers/ClientCompanyShareholderDrawerComponent_company'
import {ClientCreateNewOfficeholderComponent} from './ClientCreateNewOfficeholder'
import {ClientCreateNewShareholderComponent} from './ClientCreateNewShareholder'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} leadsList
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const ClientCompany = ({
  loading,
  client_id,
  staffId,
  onPhoneInputChange,
}) => {
  const history = useHistory()
  const {
    streetCodes, currentAccountID, countriesList, clientRecord
  } = useSelector(state => ({
    streetCodes: state.leads.streetCodes,
    currentAccountID: state.appState.currentAccountRecord.id,
    countriesList: state.appState.countriesList,
    clientRecord: state.clients.entities.clientRecord
  }))

  const [openCreateNewOfficeholders, setOpenCreateNewOfficeholders] = useState(false)
  const [openCreateNewShareholders, setOpenCreateNewShareholders] = useState(false)

  const [officeholders, setOfficeholders] = useState({
    loading: true,
    officeholders: []
  })

  const [shareholders, setShareholders] = useState({
    loading: true,
    shareholders: []
  })

  const [shareholders_company, setShareholdersCompany] = useState({
    loading: true,
    shareholders_company: []
  })

  useEffect(() => {
    getData(client_id)
  }, [client_id])

  const getData = async () => {
    if (client_id) {
      try {
        setOfficeholders({
          loading: true,
          officeholders: []
        })
        await API.get('client-company', `/officeholders-by/${client_id}`).then(response => {
          setOfficeholders({
            loading: false,
            officeholders: response.payload
          })
        })
      } catch (e) {
        setOfficeholders({
          loading: false,
          officeholders: []
        })
        console.log('error,', 'Failed to get officeholders list,', e.message)
      }
    }

    if (client_id) {
      try {
        setShareholders({
          loading: true,
          shareholders: []
        })
        await API.get('client-company', `/shareholders-by/${client_id}`).then(response => {
          setShareholders({
            loading: false,
            shareholders: response.payload
          })
        })
      } catch (e) {
        setShareholders({
          loading: false,
          shareholders: []
        })
        console.log('error,', 'Failed to get officeholders list,', e.message)
      }
    }

    // getShareholdersCompaniesByClientId()
    if (client_id) {
      try {
        setShareholdersCompany({
          loading: true,
          shareholders_company: []
        })
        await API.get('client-company', `/shareholders-companies-by/${client_id}`).then(response => {
          setShareholdersCompany({
            loading: false,
            shareholders_company: response.payload
          })
        })
      } catch (e) {
        setShareholdersCompany({
          loading: false,
          shareholders_company: []
        })
        console.log('error,', 'Failed to get officeholders (Company) list,', e.message)
      }
    }

    // if (id) {
    //   // getClientsAndleads()
    // }
  }

  const [state, setState] = useState({
    visible: false,
    companyDrawerData: null,
    companyDrawerData_shareholder: null,
    companyDrawerData_shareholder_company: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      companyDrawerData: record,
      visible: bool
    })
    getData()
  }

  const toggleDrawer_shareholder = (bool, record) => {
    setState({
      ...state,
      companyDrawerData_shareholder: record,
      visible_shareholder: bool
    })
    getData()
  }

  const toggleDrawer_shareholder_company = (bool, record) => {
    setState({
      ...state,
      companyDrawerData_shareholder_company: record,
      visible_shareholder_company: bool
    })
    getData()
  }

  onPhoneInputChange = id => value => {
    this.setState({[id]: value})
  }

  const officeholders_columns = [
    {
      title: 'Office',
      dataIndex: 'officePosition'
    },
    {
      title: 'Name',
      dataIndex: 'fileBy',
      render: (text, record) => {
        if (!record?.firstName || !record?.lastName){
          return ''
        }
        return `${record.lastName}, ${record.firstName}`
      }
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Telephone',
      dataIndex: 'telephoneMobile'
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <React.Fragment>
            <a onClick={() => toggleDrawer(true, record)}>
              {' '}
              <EyeOutlined />{' '}
            </a>
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteClientsOfficeholderRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </React.Fragment>
        )
      }
    }
  ]

  const shareholders_columns = [
    {
      title: 'File By',
      dataIndex: 'fileBy',
      render: (text, record) => {
        if (!record?.firstName || !record?.lastName){
          return ''
        }
        return `${record.lastName}, ${record.firstName}`
      }
    },
    {
      title: 'Shareholding %',
      dataIndex: 'ownershipPercentage'
    },

    {
      title: 'Beneficially Held',
      dataIndex: 'beneficiallyOwned',
      render: text => {
        if (text) {
          return 'yes'
        } else if (!text) {
          return 'no'
        }

        return ''
      }
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <React.Fragment>
            <a onClick={() => toggleDrawer_shareholder(true, record)}>
              {' '}
              <EyeOutlined />{' '}
            </a>
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteClientsShareholderRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </React.Fragment>
        )
      }
    }
  ]

  const shareholders_columns_company = [
    {
      title: 'Company Name',
      dataIndex: 'companyName'
    },

    {
      title: 'Company Number',
      dataIndex: 'companyNumber',
    },

    {
      title: 'Shareholding %',
      dataIndex: 'ownershipPercentage'
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <React.Fragment>
            <a onClick={() => toggleDrawer_shareholder_company(true, record)}>
              {' '}
              <EyeOutlined />{' '}
            </a>
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteClientsShareholderRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </React.Fragment>
        )
      }
    }
  ]

  const handle_create_new_officeholders = () => {
    setOpenCreateNewOfficeholders(true)
  }

  const handle_create_new_shareholders = () => {
    setOpenCreateNewShareholders(true)
  }

  const deleteClientsOfficeholderRecord = async record => {
    if (record.id) {
      try {
        await API.del('clients',`/delete-clientsOfficeholder/${record.id}/${record.clientId}`)
        history.push(`/clients/view/${record.clientId}?tab=company`)
        getData()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const deleteClientsShareholderRecord = async record => {
    if (record.id) {
      try {
        await API.del('clients',`/delete-clientsShareholder/${record.id}/${record.clientId}`)
        history.push(`/clients/view/${record.clientId}?tab=company`)
        getData()
      } catch (e) {
        console.log(e)
      }
    }
  }
  return (
    <Card
      style={{minHeight: 360}}
      title='Company Details - Officeholders and Shareholders'
      extra={[
        <Button key='1' onClick={handle_create_new_officeholders}>
            New Officeholder
        </Button>,
        <Button key='2' onClick={handle_create_new_shareholders}>
            New Shareholder
        </Button>,
        <Button onClick={() => getData(currentAccountID)} icon={<SyncOutlined />} />
      ]}
    >
      <div style={{paddingTop: 24}}>
        <Skeleton active loading={loading}>
          <Typography.Title level={4}>Company Officeholders</Typography.Title>
          <Table
            rowKey='id'
            columns={officeholders_columns}
            dataSource={officeholders.officeholders}
            loading={officeholders.loading}
            scroll={{x: true}}
            pagination={{defaultPageSize: 3,}}
          />
          <ClientCreateNewOfficeholderComponent
            toggleModal={setOpenCreateNewOfficeholders}
            clientId={client_id}
            currentAccountId={currentAccountID}
            staffId={staffId}
            isModalVisible={openCreateNewOfficeholders}
            getData={getData}
            onPhoneInputChange={onPhoneInputChange}
            countriesList={countriesList}
            streetCodes={streetCodes}
          />
          <ClientCreateNewShareholderComponent
            toggleModal={setOpenCreateNewShareholders}
            clientId={client_id}
            currentAccountId={currentAccountID}
            staffId={staffId}
            isModalVisible={openCreateNewShareholders}
            getData={getData}
          />
          <Typography.Title level={4}>Shareholders - Individual</Typography.Title>
          <Table
            rowKey='id'
            columns={shareholders_columns}
            dataSource={shareholders.shareholders}
            loading={shareholders.loading}
            scroll={{x: true}}
            pagination={{defaultPageSize: 3,}}
          />
          <Typography.Title level={4}>Shareholders - Corporate</Typography.Title>
          <Table
            rowKey='id'
            columns={shareholders_columns_company}
            dataSource={shareholders_company.shareholders_company}
            loading={shareholders_company.loading}
            scroll={{x: true}}
            pagination={{defaultPageSize: 3,}}
          />
            
          <ClientCompanyDrawerComponent
            {...state}
            toggleModal={toggleDrawer}
            id={client_id}
            getData={getData}
            currentAccountID={currentAccountID}
            countriesList={countriesList}
            streetCodes={streetCodes}
            clientRecord={clientRecord}
          />
          <ClientCompanyShareholderDrawerComponent
            {...state}
            toggleModal={toggleDrawer_shareholder}
            id={client_id}
            getData={getData}
            currentAccountID={currentAccountID}
            clientRecord={clientRecord}
          />
          <ClientCompanyShareholderDrawerComponent_company
            {...state}
            toggleModal={toggleDrawer_shareholder_company}
            id={client_id}
            getData={getData}
          />
        </Skeleton>
      </div>
    </Card>
  )
}

export default ClientCompany

