import {all, call, put, takeEvery, select} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import * as types from './types'
import {appStateActions, appStateSelectors} from '../appState'

import {openNotificationWithIcon} from '../../utils'

function* saveAccount(action) {
  try {
    const {payload} = action

    yield call([API, API.put], 'accounts', `/update/${payload.id}`, {body: payload})
    yield put({
      type: types.SAVE_ACCOUNT_SUCCESS,
      payload
    })

    const {currentUserRecord} = yield select(appStateSelectors.stateSelector)
    yield put(appStateActions.switchAccount(payload.id, currentUserRecord.id))

    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  } catch (e) {
    yield put({type: types.SAVE_ACCOUNT_ERROR})
    openNotificationWithIcon('error', 'Save Failed', e.message)
  }
}

function* getCurrencyList() {
  try {
    const {payload} = yield call([API, API.get], 'currencies', '/list')
    yield put({
      type: types.GET_CURRENCY_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_CURRENCY_LIST_ERROR})
    openNotificationWithIcon('error', 'Fetching currency list failed', e.message)
  }
}

function* getFinancialAccountTypes() {
  try {
    const {payload} = yield call([API, API.get], 'financial-accounts', '/types')
    yield put({
      type: types.GET_FINANCIAL_ACCOUNT_TYPES_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_FINANCIAL_ACCOUNT_TYPES_ERROR})
    openNotificationWithIcon('error', 'Fetching financial account failed', e.message)
  }
}

function* getTradingPlatformList(action) {
  try {
    const {payload: id} = action
    const {payload} = yield call([API, API.get], 'trading-platforms', `/accounts/list/account/${id}`)
    yield put({
      type: types.GET_TRADING_PLATFORMS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_TRADING_PLATFORMS_LIST_ERROR})
    openNotificationWithIcon('error', 'Failed to get trading platforms list', e.message)
  }
}

function* getJournalsList(action) {
  try {
    const {payload} = yield call([API, API.get], 'journals', '/list')
    yield put({
      type: types.GET_JOURNALS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_TRADING_PLATFORMS_LIST_ERROR})
    openNotificationWithIcon('error', 'Failed to get journal list', e.message)
  }
}

function* getJournalsByAccounrId(action) {
  try {
    const {payload: id} = action
    const {payload} = yield call([API, API.get], 'journals', `/listByAccountId/${id}`)
    yield put({
      type: types.GET_JOURNALS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_JOURNALS_LIST_ERROR})
    openNotificationWithIcon('error', 'Failed to get journal list', e.message)
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_JOURNALS_LIST_REQUEST, getJournalsByAccounrId),
    yield takeEvery(types.SAVE_ACCOUNT_REQUEST, saveAccount),
    yield takeEvery(types.GET_CURRENCY_LIST_REQUEST, getCurrencyList),
    yield takeEvery(types.GET_TRADING_PLATFORMS_LIST_REQUEST, getTradingPlatformList),
    yield takeEvery(types.GET_FINANCIAL_ACCOUNT_TYPES_REQUEST, getFinancialAccountTypes)
  ])
}