import {APP_NAME} from 'consts'

export const moduleName = 'leads'

export const CREATE_LEAD_REQUEST = `${APP_NAME}/${moduleName}/CREATE_LEAD_REQUEST`
export const CREATE_LEAD_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_LEAD_SUCCESS`
export const CREATE_LEAD_ERROR = `${APP_NAME}/${moduleName}/CREATE_LEAD_ERROR`

export const FETCH_LEADS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_LEADS_REQUEST`
export const FETCH_LEADS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_LEADS_SUCCESS`
export const FETCH_LEADS_ERROR = `${APP_NAME}/${moduleName}/FETCH_LEADS_ERROR`

export const GET_LEAD_REQUEST = `${APP_NAME}/${moduleName}/GET_LEAD_REQUEST`
export const GET_LEAD_SUCCESS = `${APP_NAME}/${moduleName}/GET_LEAD_SUCCESS`
export const GET_LEAD_ERROR = `${APP_NAME}/${moduleName}/GET_LEAD_ERROR`

export const SAVE_LEAD_REQUEST = `${APP_NAME}/${moduleName}/SAVE_LEAD_REQUEST`
export const SAVE_LEAD_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_LEAD_SUCCESS`
export const SAVE_LEAD_ERROR = `${APP_NAME}/${moduleName}/SAVE_LEAD_ERROR`

export const CHECK_LEADS_EMAIL_REQUEST = `${APP_NAME}/${moduleName}/CHECK_LEADS_EMAIL_REQUEST`
export const CHECK_LEADS_EMAIL_SUCCESS = `${APP_NAME}/${moduleName}/CHECK_LEADS_EMAIL_SUCCESS`
export const CHECK_LEADS_EMAIL_ERROR = `${APP_NAME}/${moduleName}/CHECK_LEADS_EMAIL_ERROR`

export const STREET_TYPE_REQUEST = `${APP_NAME}/${moduleName}/STREET_TYPE_REQUEST`
export const STREET_TYPE_SUCCESS = `${APP_NAME}/${moduleName}/STREET_TYPE_SUCCESS`
export const STREET_TYPE_ERROR = `${APP_NAME}/${moduleName}/STREET_TYPE_ERROR`

export const GET_JOURNAL_BY_LEAD_REQUEST = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_LEAD_REQUEST`
export const GET_JOURNAL_BY_LEAD_SUCCESS = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_LEAD_SUCCESS`
export const GET_JOURNAL_BY_LEAD_ERROR = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_LEAD_ERROR`