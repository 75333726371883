import React from 'react'

import {
  Row,
  Col,
  Form,
  Input,
  Descriptions,
  Select,
  Card
} from 'antd'
import PhoneInput from 'react-phone-input-2'
import {filterOption} from '../../common'

import { PREFERRED_COUNTRIES } from 'consts'

const {Option} = Select

export const ClientNewStep2 = ({
  countriesList, onPhoneInputChange, streetCodes, ...rest
}) => {
  const left = [
    {
      label: 'Telephone (home)',
      name: 'tel_home'
    },
    {
      label: 'Telephone (work)',
      name: 'tel_work'
    },
    {
      label: 'Telephone (mobile)',
      name: 'tel_mobile'
    }
  ]

  return (
    <>
      <Row gutter={[96, 24]}>
        <Col xs={8}>
          <Card title={<h3>Telephone</h3>} bordered={false}>
            {left.map(props => (
              <Form.Item key={props.label} {...props}>
                <PhoneInput
                  inputStyle={{width: '100%'}}
                  preferredCountries={PREFERRED_COUNTRIES}
                  masks={{au: '(.) ....-....'}}
                  enableSearch
                  disableCountryGuess={false}
                  onChange={onPhoneInputChange(props.name)}
                />
              </Form.Item>
            ))}
          </Card>
        </Col>
        <Col xs={16}>
          <Card title={<h3>Street Address</h3>} bordered={false}>
            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Unit Number' label='Unit Number' name='streetUnitNumber' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Street Number' label='Street Number' name='streetStreetNumber' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item key='Street name' label='Street name' name='streetStreetName' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='Street Street Type' label='Street Type' name='streetStreetTypeId' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Street'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {streetCodes.streetType &&
                      streetCodes.streetType.map(x => (
                        <Option key={x.id} value={x.id}>
                          {x.fileBy}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item key='Suburb' label='Suburb' name='streetSuburb' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='State' label='State' name='streetState' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='Post Code' label='Post Code' name='streetPostcode' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item key='Country' label='Country' name='streetCountryId' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Country'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {countriesList.map(({id, full_name}) => (
                      <Option key={id} value={id}>
                        {full_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[96, 24]}>
        <Col xs={8}>
          <Card title={<h3>Email</h3>} bordered={false}>
            <Form.Item shouldUpdate>
              {({getFieldsValue}) => {
                const {email, email_secondary} = getFieldsValue(['email', 'email_secondary'])
                return (
                  <Descriptions
                    bordered
                    layout='vertical'
                    size='small'
                    column={{
                      xs: 1,
                      sm: 1
                    }}
                  >
                    <Descriptions.Item label='E-mail'>{email}</Descriptions.Item>
                    <Descriptions.Item label='E-mail secondary'>{email_secondary || '-'}</Descriptions.Item>
                  </Descriptions>
                )
              }}
            </Form.Item>
          </Card>
        </Col>
        <Col xs={16}>
          <Card title={<h3>Postal Address</h3>} bordered={false}>
            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Box Number' label='Box Number' name='postalBoxNumber' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item label='Box Type' name='postalBoxTypeId'>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Box Type'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {streetCodes.postalType &&
                    streetCodes.postalType.map(x => (
                      <Option key={x.id} value={x.id}>
                        {x.fileBy}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Street Number' label='Street Number' name='postalStreetNumber' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item key='Street Name' label='Street Name' name='postalStreetName' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='Postal Street Type' label='Street Type' name='postalStreetTypeId' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Street'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {streetCodes.streetType &&
                      streetCodes.streetType.map(x => (
                        <Option key={x.id} value={x.id}>
                          {x.fileBy}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item key='Suburb' label='Suburb' name='postalSuburb' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='State' label='State' name='postalState' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='postcode' label='postcode' name='postalPostcode' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item label='Country' name='postalCountryId'>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Country'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {countriesList.map(({id, full_name}) => (
                      <Option key={id} value={id}>
                        {full_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}