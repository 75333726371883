import React, {
  useState,
  FC
} from 'react'

import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  notification,
  InputPassword
} from 'components'

import {Auth} from 'utils'

interface ResetPasswordModalProps {
  open: boolean;
  email: string;
  onCancel: () => void;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  email, open, onCancel
}) => {
  const [loading, toggleLoading] = useState(false)

  const onFinish = async (values: {verificationCode: string; newPassword: string; confirmPassword: string; }) => {
    toggleLoading(true)

    try {
      await Auth.forgotPasswordSubmit(
        email.toLowerCase(),
        values.verificationCode,
        values.confirmPassword
      )

      notification.info({message: 'Password has been successfully set.  You may now login using the new password.'})

      toggleLoading(true)

      onCancel()

    } catch (e: any) {
      notification.error({message: e.message})
      toggleLoading(true)
    }
  }

  return (
    <Modal
      open={open}
      title='Password Reset'
      onCancel={onCancel}
      footer={null}
    >
      <p>
          Please check your email (including spam folder) for a verification code.
      </p>
      <p>
          Enter it below, with your chosen new password.
      </p>
      <p>
        <strong>New password must be 8 characters including uppercase, lowercase one number and one symbol.</strong>
      </p>
      <Divider />
      <Form
        onFinish={onFinish}
        layout='vertical'
      >
        <Form.Item
          label='Verification Code'
          hasFeedback
          name='verificationCode'
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Please enter verification code')
                }
                if (value.length !== 6) {
                  return Promise.reject('Verification Code must be 6 digits')
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Input placeholder='Verification Code'/>
        </Form.Item>
        <InputPassword
          required
          validate
          label="common.password"
          name="password"
          dependencies={['confirmPassword']}
        />
        <InputPassword
          required
          validate
          label="common.confirm_password"
          name="confirmPassword"
          preserve={false}
          dependencies={['password']}
          rules={[
            // TODO Find type
            ({getFieldValue,}: {
                getFieldValue: (field: string) => string;
              }) => ({
              validator: (rule: any, value: string) => {
                const password = getFieldValue('password')
                if (!value || !password || password === value) {
                  return Promise.resolve()
                }
                return Promise.reject('Passwords do not match')
              },
            }),
          ]}
        />
        <Space
          style={{
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={onCancel}>
              Cancel
          </Button>
          <Button
            htmlType='submit'
            type='primary'
            loading={loading}
          >
              Submit
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}
export default ResetPasswordModal
