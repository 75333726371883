import * as React from 'react'
import InputSelect from '../basic'
import {
  InputSelectProps,
  Option
} from '../types'
import {
  useSelector,
  useFormInstance
} from 'hooks'
import {API} from 'utils'
import {debounce} from 'lodash'
import {
  Client,
  Account
} from 'types'

type QueryParams = Record<keyof Pick<Client, 'id' | 'first_name' | 'last_name' | 'email'>, string>

const ClientsSelect: React.FC<Omit<InputSelectProps, 'options' | 'loading' | 'getOptionProps'>> = ({label = 'common.client', ...props}) => {
  const [state, setState] = React.useState<{
    loading: boolean;
    options: Client[];
  }>({
    loading: false,
    options: []
  })
  const account = useSelector(state => state.appState.currentAccountRecord as Account)
  const formInstance = useFormInstance()

  const onSearch = async (value: string) => {
    if (!value) {
      return
    }

    try {
      setState({
        options: [],
        loading: true
      })
      const queryStringParameters = ([
        'id',
        'first_name',
        'last_name',
        'email',
      ] as const).reduce<QueryParams>((prev, curr) => {
        prev[curr] = value

        return prev
      }, {} as QueryParams)

      const options = await API.post('clients', '/search', {
        queryStringParameters,
        body: {accountID: account.id}
      })

      setState({
        options,
        loading: false
      })
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        loading: false
      }))
    }
  }

  React.useEffect(() => {
    if (formInstance && props.name) {
      onSearch(formInstance.getFieldValue(props.name))
    }
  }, [formInstance])

  return (
    <InputSelect
      {...props}
      label={label}
      loading={state.loading}
      options={state.options}
      placeholder='button.search'
      onSearch={debounce(onSearch, 500)}
      getOptionProps={opt => ({
        value: opt.id,
        children: `${opt.id} ${opt.first_name} ${opt.last_name} ${opt.email}`
      })}
    />
  )
}

export default ClientsSelect
