import reducer from './reducers'

import * as verificationsSelectors from './selectors'
import * as verificationsActions from './actions'
import * as verificationsTypes from './types'
import verificationsSaga from './sagas'

export {
  verificationsSaga,
  verificationsTypes,
  verificationsActions,
  verificationsSelectors,
}

export default reducer