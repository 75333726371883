import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  API,
  Auth
} from 'aws-amplify'
import {
  FormattedMessage,
  injectIntl
} from 'react-intl'

import withStyles from 'react-jss'

import {appStateSelectors} from 'configs/ducks/appState'

//import { Layout } from 'antd';
import {
  Row,
  Col
} from 'antd'
import {
  Card,
  Button
} from 'antd'
import {notification} from 'antd'
import {
  Form,
  Input
} from 'antd'
import {Modal} from 'antd'

const styles = theme => ({
  form_label: {fontWeight: theme.label_font_weight},
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color
  }
})
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class UserSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      change_password_dialog: false,
      current_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  }

  async componentDidMount() {
    try {
      const user = await this.getUserProfile(this.props.currentUserID)
      // console.log(user)
      this.setState({...user.payload})
    } catch (e) {
      console.log(e)
    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    if (nextProps.currentUserID !== this.props.currentUserID) {
      const user = await this.getUserProfile(nextProps.currentUserID)
      // console.log(user)
      this.setState({...user.payload})
    }
  }

  async getUserProfile(user_id) {
    return API.get('staff', `/get/id/${user_id}`)
  }

  async saveUserProfile(user) {
    await API.put('staff', `/update/${this.state.id}`, {body: user})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value,
      [`${[event.target.id]}_state`]: '',
      [`${[event.target.id]}_help`]: ''
    })
  };

  change_password = () => {
    this.setState({change_password_dialog: true})
  };

  handle_change_password_ok = async (current_password, new_password, confirm_new_password) => {
    // Validate the modal form
    let modal_valid = true
    if (!current_password) {
      this.setState({
        current_password_state: 'error',
        current_password_help: 'Please enter current password'
      })
      modal_valid = false
    }
    if (!new_password) {
      this.setState({
        new_password_state: 'error',
        new_password_help: 'Password must be 8 characters with uppercase, lowercase and numbers'
      })
      modal_valid = false
    }
    if (!confirm_new_password) {
      this.setState({
        confirm_new_password_state: 'error',
        confirm_new_password_help: 'Please re-enter your password here'
      })
      modal_valid = false
    }
    if (new_password != confirm_new_password) {
      this.setState({
        confirm_new_password_state: 'error',
        confirm_new_password_help: 'Password and confirm must be the same'
      })
      modal_valid = false
    }

    if (!modal_valid) {
      return
    }

    this.setState({confirmLoading: true})

    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(currentUser, current_password, new_password)
      this.setState({
        new_password_changing: false,
        change_password_dialog: false,
        current_password: '',
        new_password: '',
        new_password_confirm: ''
      })
      openNotificationWithIcon('success', 'Password Changed', 'Your password has been changed successfully')
    } catch (e) {
      openNotificationWithIcon('error', 'Error', e.message)
      this.setState({
        change_password_dialog: false,
        confirmLoading: false,
        current_password: '',
        new_password: '',
        new_password_confirm: ''
      })
    }
  };

  handle_change_password_cancel = () => {
    console.log('Clicked cancel button')
    this.setState({change_password_dialog: false})
  };

  handleSubmit = async event => {
    event.preventDefault()
    // console.log(event)
    this.setState({isLoading: true})

    const {
      // aws_cognito_id,
      first_name,
      middle_name,
      last_name
      // email,
    } = this.state
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name
      })
    } catch (e) {
      console.log(e)
      openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state)
    const {
      change_password_dialog,
      confirmLoading
      // ChangePasswordContent
    } = this.state

    return (
      <React.Fragment>
        <Form layout='vertical'>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label='First Name'>
                    <Input
                      id='first_name'
                      value={this.state.first_name}
                      onChange={event => {
                        this.handleChange(event)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label='Middle Name(s)'>
                    <Input
                      id='middle_name'
                      value={this.state.middle_name}
                      onChange={event => {
                        this.handleChange(event)
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label='Last Name'>
                <Input
                  id='last_name'
                  value={this.state.last_name}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label='E-mail'>
                <Input
                  id='email'
                  value={this.state.email}
                  disabled={true}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Change Password'>
                <Button onClick={this.change_password}>Change Password</Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={24} style={{textAlign: 'right'}}>
              <Button type='primary' htmlType='submit' onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal
          title='Change Password'
          visible={change_password_dialog}
          onOk={() =>
            this.handle_change_password_ok(
              this.state.current_password,
              this.state.new_password,
              this.state.confirm_new_password
            )
          }
          confirmLoading={confirmLoading}
          onCancel={this.handle_change_password_cancel}
        >
          <React.Fragment>
            <p>
              Please enter your current password.
              <br />
              <br />
              You should also enter a new password (and confirm it) in the fields below.
              <br />
            </p>
            <Form layout='vertical'>
              <Form.Item
                label='Current Password'
                validateStatus={this.state.current_password_state}
                help={this.state.current_password_help}
              >
                <Input.Password
                  id='current_password'
                  placeholder='Current password'
                  value={this.state.current_password}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='New Password'
                validateStatus={this.state.new_password_state}
                help={this.state.new_password_help}
              >
                <Input.Password
                  id='new_password'
                  placeholder='New password'
                  value={this.state.new_password}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Confirm New Password'
                validateStatus={this.state.confirm_new_password_state}
                help={this.state.confirm_new_password_help}
              >
                <Input.Password
                  id='confirm_new_password'
                  placeholder='Confirm new password'
                  value={this.state.confirm_new_password}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
            </Form>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {currentUserID: appStateSelectors.stateSelector(state).currentUserRecord.id}

}
const UserSummaryContainer = connect(
  mapStateToProps
)(withStyles(styles, {injectTheme: true})(injectIntl(UserSummary)))

export default UserSummaryContainer
