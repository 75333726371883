import * as types from './types'

/**
 * @param {number} startDate
 * @param {number} endDate
 * @returns {{payload: *, type: string}}
 */
export const getCommissionsFull = (startDate, endDate) => ({
  type: types.GET_COMMISSIONS_FULL_REQUEST,
  payload: {
    startDate,
    endDate
  }
})
/**
 * @param {number} startDate
 * @param {number} endDate
 * @returns {{payload: *, type: string}}
 */
export const getCommissionsSum = (startDate, endDate) => ({
  type: types.GET_COMMISSIONS_SUM_REQUEST,
  payload: {
    startDate,
    endDate
  }
})

/**
 * @param {number} currentAccountID
 * @returns {{payload: *, type: string}}
 */
export const getTradingAccounts = currentAccountID => ({
  type: types.GET_TRADING_ACCOUNTS_REQUEST,
  currentAccountID
})

/**
 * @param {number} id
 * @returns {{payload: *, type: string}}
 */
export const getTradingAccount = id => ({
  type: types.GET_TRADING_ACCOUNT_REQUEST,
  id
})

/**
 * @param {number} currentAccountID
 * @returns {{payload: *, type: string}}
 */
export const getTradingTransactions = currentAccountID => ({
  type: types.GET_TRADING_TRANSACTIONS_REQUEST,
  currentAccountID
})

/**
 * @param {number} id
 * @param {number} route
 * @returns {{payload: *, type: string}}
 */
export const getTradingTransaction = (id, route) => ({
  type: types.GET_TRADING_TRANSACTION_REQUEST,
  id,
  route
})

/**
 * @param {number} id
 * @param {number} route
 * @returns {{payload: *, type: string}}
 */
export const getTradingTransaction2 = (id, route) => ({
  type: types.GET_TRADING_TRANSACTION2_REQUEST,
  id,
  route
})

export const removeDocument = (id) => ({
  type: types.REMOVE_DOCUMENT_REQUEST,
  payload: { id },
})

export const removeTradingTransaction2Document = (id) => ({
  type: types.REMOVE_DOCUMENT2_REQUEST,
  payload: { id },
})

export const uploadDocument = (payload) => ({
  type: types.UPLOAD_DOCUMENT_REQUEST,
  payload,
})

export const uploadDocument2 = (payload) => ({
  type: types.UPLOAD_DOCUMENT2_REQUEST,
  payload,
})

export const getTradingTransactionsByAccountId = currentAccountID => ({
  type: types.GET_TRADING_TRANSACTIONS_BY_ACCOUNT_REQUEST,
  currentAccountID
})

/**
 * @param {string} payload
 * @returns {{payload: *, type: string}}
 */
export const filterTradingTransactions = payload => ({
  type: types.FILTER_TRADING_TRANSACTIONS,
  payload
})
/**
 * @param {string} payload
 * @param {Function} cb
 * @returns {{payload: *, type: string}}
 */
export const createDepositWithdrawal = (payload, cb) => ({
  type: types.CREATE_DEPOSIT_WITHDRAWAL_REQUEST,
  payload,
  cb
})
/**
 * @param {number} transaction_id
 * @param {number} status_id
 * @returns {{payload: *, type: string}}
 */
export const updateStatusID = (transaction_id, status_id) => ({
  type: types.UPDATE_STATUS_ID_REQUEST,
  transaction_id,
  status_id
})

export const updateTransactionRecord = (payload) => ({
  type: types.UPDATE_TRANSACTION_RECORD,
  payload
})

export const updateWithdrawalTargetFinancialAccountId = payload => ({
  type: types.UPDATE_WITHDRAWAL_TARGET,
  payload
})
