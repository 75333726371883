import {
  all,
  call,
  put,
  takeEvery,
  select
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import {openNotificationWithIcon} from '../../utils'

import {create_client_cognito_account} from 'library/clients'

import * as types from './types'
import * as actions from './actions'

import {getDocument} from '../documents/sagas'
import {
  appStateActions,
  appStateSelectors
} from '../appState'

export function* fetchClients() {
  try {
    const payload = yield call([API, API.get], 'clients', '')
    yield put({
      type: types.FETCH_CLIENTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_CLIENTS_ERROR})
  }
}

function* fetchClientsClosure(action) {
  const {payload: id} = action
  try {
    const {payload} = yield call([API, API.get], 'clients', `/getSimpleClosureListByAccountId/${id}`)
    yield put({
      type: types.FETCH_CLIENTS_CLOSURE_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_CLIENTS_CLOSURE_ERROR})
  }
}

function* getClient(action) {
  try {
    const {payload: ID} = action
    const {payload: client} = yield call([API, API.get], 'clients', `/get-by-id/${ID}`)

    const {
      currentUserRecord,
      currentAccountRecord
    } = yield select(appStateSelectors.stateSelector)

    if (client.account_id !== currentAccountRecord.id) {
      yield put(appStateActions.switchAccount(client.account_id, currentUserRecord.id))
    }

    yield put({
      type: types.GET_CLIENT_SUCCESS,
      payload: client
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.response?.data?.error || e.message)
    yield put({type: types.GET_CLIENT_ERROR})
  }
}

function* getClientAccountClosureRecords(action) {
  try {
    const closureRecordId = action.payload?.closureRecordId ? action.payload.closureRecordId : 0
    const result = yield call([API, API.get], 'clients-acc-close', `/get-closure-by-id-v2/${closureRecordId}`)
    yield put({
      type: types.GET_CLIENT_CLOSURE_SUCCESS,
      payload: result
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.GET_CLIENT_CLOSURE_ERROR})
  }
}

function* financialAccount(action) {
  try {
    const {payload: id} = action
    const {payload: client} = yield call([API, API.get], 'financial-accounts', `/list/${id}`)
    yield put({
      type: types.FINANCIAL_ACCOUNT_SEND_SUCCESS,
      payload: client
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.FINANCIAL_ACCOUNT_SEND_ERROR})
  }
}

function* saveClientRecord(action) {
  try {
    const {payload: client} = action
    openNotificationWithIcon('info', 'In Process', 'Saving information...')

    const {payload} = yield call([API, API.put], 'clients', `/update/${client.id}`, {body: client})

    openNotificationWithIcon('success', 'Success', 'Successfully saved.')

    yield put({
      type: types.SAVE_CLIENT_SUCCESS,
      payload: payload.updated_client
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', 'Failed to save')
    yield put({type: types.SAVE_CLIENT_ERROR})
  }
}

function* createClient(action) {
  try {
    const {
      payload: {
        newClient, accountID, history
      }
    } = action
    openNotificationWithIcon('info', 'In Process', 'Creating new client in database.')
    const {
      createPass, password, ...clientRest
    } = newClient

    const {payload} = yield call([API, API.post], 'clients', '/create', {
      body: {
        ...clientRest,
        file_by: `${clientRest.last_name}, ${clientRest.first_name}`,
        account_id: accountID
      }
    })

    if (createPass) {
      openNotificationWithIcon('info', 'In Process', 'Creating Client Portal login.')
      create_client_cognito_account(payload.insertId, clientRest.email, password)
      openNotificationWithIcon('info', 'Record Created', 'Client Portal login created.')
    }
    openNotificationWithIcon('success', 'Record Created', 'New client has been successfully created.')
    yield put({type: types.CREATE_CLIENT_SUCCESS})
    history.push('/clients/list')
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.CREATE_CLIENT_ERROR})
  }
}

function* getDocuments(action) {
  try {
    const {payload: clientID} = action
    const documents = yield call([API, API.get], 'documents', `/list/client/${clientID}`)

    yield put({
      type: types.GET_DOCUMENTS_SUCCESS,
      payload: documents
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
  }
}

function* watchGetDocumentAction(action) {
  try {
    const {payload: itemID} = action
    const result = yield call(getDocument, itemID)
    yield put({
      type: types.GET_DOCUMENT_SUCCESS,
      payload: result
    })
  } catch (e) {
    yield put({type: types.GET_DOCUMENT_ERROR})
  }
}

function* createNewDocument(action) {
  try {
    const {
      payload: {
        description, long_description, files, currentAccountID, document_type_id, client_id, successCb
      }
    } = action

    const {payload: {document_id}} = yield call([API, API.post], 'documents', '/create', {
      body: {
        client_id,
        description,
        long_description,
        document_type_id,
        files,
        account_id: currentAccountID
      }
    })

    openNotificationWithIcon('success', 'Record Created', 'Document record has been successfully created.')

    successCb()

    yield put({
      type: types.CREATE_NEW_DOCUMENT_SUCCESS,
      payload: {
        document_type_id,
        description,
        id: document_id
      }
    })

    yield put(actions.getDocuments(client_id))
  } catch (e) {
    openNotificationWithIcon('error', 'Save Failed', e.message)
    yield put({type: types.CREATE_NEW_DOCUMENT_ERROR})
  }
}

function* checkClientsEmail(action) {
  try {
    const {payload: {email, cb}} = action
    const {payload} = yield call([API, API.post], 'clients', '/check-email', {body: email})
    if (!payload) {
      yield put({type: types.CHECK_CLIENTS_EMAIL_ERROR})
    } else {
      yield put({type: types.CHECK_CLIENTS_EMAIL_SUCCESS})
      yield call(cb)
    }
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
  }
}

function* getVerificationList(action) {
  try {
    const {payload: clientID} = action
    const {payload} = yield call([API, API.get], 'verifications', `/get/client/${clientID}`)
    yield put({
      type: types.GET_VERIFICATIONS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.GET_VERIFICATIONS_LIST_ERROR})
  }
}

function* createDigiDoc(action) {
  try {
    const {payload} = action
    const {payload: client} = yield call([API, API.post], 'digidoc', '/create', {body: payload})

    yield put({
      type: types.DIGIDOC_CREATE_SUCCESS,
      payload: client
    })
    openNotificationWithIcon('success', 'Document Created Successfully')
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.DIGIDOC_CREATE_ERROR})
  }
}

function* sendDigiDocEmail(action) {
  try {
    const {payload} = action
    const data = yield call([API, API.post], 'email', '/client/send-digidoc-url', {body: payload})
    yield put({
      type: types.DIGIDOC_SEND_SUCCESS,
      data
    })
    openNotificationWithIcon('success', 'Email sent Successsfully')
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.DIGIDOC_SEND_ERROR})
  }
}

function* getJournalsListByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'journals', `/byId/${action.payload}`)
    yield put({
      type: types.GET_JOURNAL_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_JOURNAL_BY_CLIENT_ERROR})
    openNotificationWithIcon('error', 'Failed to get journal list', e.message)
  }
}

function* getInformationRequestsListByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'information-requests', `/list-by-client-id/${action.payload}`)
    yield put({
      type: types.GET_INFORMATIONREQUEST_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_INFORMATIONREQUEST_BY_CLIENT_ERROR})
    openNotificationWithIcon('error', 'Failed to get information requests list', e.message)
  }
}

function* getOfficeholdersByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'clientCompany', `/officeholders-by/${action.payload}`)
    yield put({
      type: types.GET_OFFICEHOLDER_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_OFFICEHOLDER_BY_CLIENT_ERROR})
    openNotificationWithIcon('error', 'Failed to get officeholders list', e.message)
  }
}

function* getShareholdersByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'clientCompany', `/shareholders-by/${action.payload}`)
    yield put({
      type: types.GET_SHAREHOLDER_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_SHAREHOLDER_BY_CLIENT_ERROR})
    openNotificationWithIcon('error', 'Failed to get shareholders list', e.message)
  }
}

function* getAppointorByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'clients', `/appointors-by/${action.payload}`)
    yield put({
      type: types.GET_APPOINTOR_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_APPOINTOR_BY_CLIENT_ERROR})
    openNotificationWithIcon('error', 'Failed to get appointors list', e.message)
  }
}

function* getBeneficiaryByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'clients', `/beneficiaries-by/${action.payload}`)
    yield put({
      type: types.GET_BENEFICIARY_BY_CLIENT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_BENEFICIARY_BY_CLIENT_ERROR})
    openNotificationWithIcon('error', 'Failed to get beneficiaries list', e.message)
  }
}

function* getClientTasks(action) {
  try {
    const {payload} = yield call([API, API.get], 'tasksClient', `/getTaskList/${action.payload}`)
    yield put({
      type: types.GET_CLIENT_TASKS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.GET_CLIENT_TASKS_LIST_ERROR})
  }
}

function* getClientById(action) {
  try {
    const {payload} = yield call([API, API.get], 'tasksClient', `/byId/${action.payload}`)
    console.info('payload', payload)
    yield put({
      type: types.GET_CLIENT_TASK_SUCCESS,
      payload: payload.length > 0 ? payload[0] : {}
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.GET_CLIENT_TASK_ERROR})
  }
}

function* getClientTaskByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'tasksClient', `/byClientId/${action.payload}`)
    yield put({
      type: types.GET_CLIENT_TASKS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.GET_CLIENT_TASKS_LIST_ERROR})
  }
}

function* deleteClient(action) {
  try {
    yield call([API, API.del], 'clients', `/delete/${action.payload}`)
    yield put({
      type: types.DELETE_CLIENT_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.DELETE_CLIENT_ERROR})
  }
}

function* getSourceOfWealthSelectListByAccountId(action) {
  try {
    const {payload} = yield call([API, API.get], 'clients', `/get-source-of-wealth/${action.payload}`)
    yield put({
      type: types.GET_SOURCEOFWEALTH_BY_ACCOUNT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_SOURCEOFWEALTH_BY_ACCOUNT_ERROR})
    openNotificationWithIcon('error', 'Failed to get Source of Wealth list', e.message)
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.DELETE_CLIENT_REQUEST, deleteClient),
    yield takeEvery(types.GET_CLIENT_TASK_REQUEST, getClientById),
    yield takeEvery(types.GET_CLIENT_TASKS_LIST_REQUEST, getClientTasks),
    yield takeEvery(types.GET_JOURNAL_BY_CLIENT_REQUEST, getJournalsListByClientId),
    yield takeEvery(types.GET_INFORMATIONREQUEST_BY_CLIENT_REQUEST, getInformationRequestsListByClientId),
    yield takeEvery(types.GET_OFFICEHOLDER_BY_CLIENT_REQUEST, getOfficeholdersByClientId),
    yield takeEvery(types.GET_SHAREHOLDER_BY_CLIENT_REQUEST, getShareholdersByClientId),
    yield takeEvery(types.GET_APPOINTOR_BY_CLIENT_REQUEST, getAppointorByClientId),
    yield takeEvery(types.GET_BENEFICIARY_BY_CLIENT_REQUEST, getBeneficiaryByClientId),
    yield takeEvery(types.DIGIDOC_CREATE_REQUEST, createDigiDoc),
    yield takeEvery(types.FINANCIAL_ACCOUNT_SEND_REQUEST, financialAccount),
    yield takeEvery(types.DIGIDOC_SEND_REQUEST, sendDigiDocEmail),
    yield takeEvery(types.FETCH_CLIENTS_REQUEST, fetchClients),
    yield takeEvery(types.FETCH_CLIENTS_CLOSURE_REQUEST, fetchClientsClosure),
    yield takeEvery(types.CREATE_CLIENT_REQUEST, createClient),
    yield takeEvery(types.GET_CLIENT_REQUEST, getClient),
    yield takeEvery(types.GET_CLIENT_CLOSURE_REQUEST, getClientAccountClosureRecords),
    yield takeEvery(types.SAVE_CLIENT_REQUEST, saveClientRecord),
    yield takeEvery(types.CHECK_CLIENTS_EMAIL_REQUEST, checkClientsEmail),
    yield takeEvery(types.GET_DOCUMENTS_REQUEST, getDocuments),
    yield takeEvery(types.GET_DOCUMENT_REQUEST, watchGetDocumentAction),
    yield takeEvery(types.CREATE_NEW_DOCUMENT_REQUEST, createNewDocument),
    yield takeEvery(types.GET_VERIFICATIONS_LIST_REQUEST, getVerificationList),
    yield takeEvery(types.GET_CLIENT_TASKS_BY_CLIENT_ID_REQUEST, getClientTaskByClientId),
    yield takeEvery(types.GET_SOURCEOFWEALTH_BY_ACCOUNT_REQUEST, getSourceOfWealthSelectListByAccountId)
  ])
}
