import React, {
  useEffect,
  useState
} from 'react'

import {
  InputSelect,
  Button,
  Checkbox,
  Typography,
  ExtendedDescriptions
} from 'components'
import {FULL_DATE_TIME_FORMAT} from 'consts'
import dayjs from 'dayjs'
import {routes} from 'configs'
import {
  showNotification,
  API
} from 'utils'
import {ClientInformationRequest} from 'types'

interface DetailsProps {
  informationRequestRecord: ClientInformationRequest;
  eApplicationFormData: any;
}

const Details: React.FC<DetailsProps> = ({
  informationRequestRecord,
  eApplicationFormData
}) => {
  const [statusOptions, setStatusOptions] = useState([])
  const [reopenLoading, setReopenLoading] = useState(false)

  const onUpdateStatus = async (statusId: number) => {
    try {
      await API.put('information-requests', `/update-information-request-status/${informationRequestRecord.id}`, {body: {statusId}})

      showNotification('success', 'Information Requests', 'Status Changed')
    } catch (e: any) {
      showNotification('error', 'Information Requests', e.response?.data?.error || e.message)
    }
  }
  const onReopenClick = async () => {
    setReopenLoading(true)
    try {
      await API.patch('information-requests',`/reopen/${informationRequestRecord.eafId}`, {body: {submitted: null}})

      showNotification('success', 'Information Requests', 'Eaf Reopened')

      setReopenLoading(false)

      window.location.reload()
    } catch (e: any) {
      setReopenLoading(false)
      showNotification('error', 'Information Requests', e.response?.data?.error || e.message)
    }
  }

  useEffect(() => {
    API.get('information-requests', '/get-ir-status-list').then(
      response => {
        setStatusOptions(response.payload)
      }
    )
  }, [])

  if (!informationRequestRecord?.statusName || !eApplicationFormData) {
    return null
  }

  return (
    <ExtendedDescriptions
      title='common.details'
      items={[
        {
          label: 'ID',
          value: informationRequestRecord.id,
          translate: false
        },
        {
          label: 'Date Created',
          value: dayjs(informationRequestRecord.recordCreated).format(FULL_DATE_TIME_FORMAT),
          translate: false
        },
        {
          label: 'Client',
          copyable: false,
          translate: false,
          value: (
            <Typography.Link
              href={routes.clientsView({clientID: informationRequestRecord.clientId})}
              target="_blank"
              rel="noreferrer"
            >
              {informationRequestRecord.clientId}
            </Typography.Link>
          )
        },
        {
          label: 'Nickname',
          value: informationRequestRecord.clients_fileBy,
          translate: false
        },
        {
          label: 'Email',
          value: informationRequestRecord.clients_email,
          translate: false
        },
        {
          label: 'Mobile',
          value: informationRequestRecord.clients_tel_mobile,
          translate: false
        },
        {
          label: 'EAF ID',
          value: informationRequestRecord.eafId,
          translate: false
        },
        {
          label: 'Status',
          translate: false,
          copyable: false,
          value: (
            <InputSelect
              isFormItem={false}
              onChange={onUpdateStatus}
              defaultValue={informationRequestRecord?.statusName}
              options={statusOptions}
              getOptionProps={opt => ({
                value: opt.id,
                children: opt.fileBy
              })}
            />
          )
        },
        {
          label: 'Activate Financial',
          value: <Checkbox checked={Boolean(eApplicationFormData?.metadata?.activateFinancial)} />,
          translate: false
        },
        {
          label: 'Activate Edd Documents',
          value: <Checkbox checked={Boolean(eApplicationFormData?.metadata?.activate_edd_documents)} />,
          translate: false
        },
        {
          label: 'Submitted',
          translate: false,
          value: !eApplicationFormData?.submitted
            ? <em>This Information Request is not yet completed and is currently <strong>OPEN</strong> for editing</em>
            : (
              <>Customer completed on {dayjs(eApplicationFormData?.submitted).format(FULL_DATE_TIME_FORMAT)}
                <br/>
                <em>This Information Request is <strong>LOCKED</strong> for editing</em>&nbsp;&nbsp;&nbsp;
                <Button loading={reopenLoading} onClick={onReopenClick}>Re-open for Edit</Button>
              </>
            )
        },
        {
          label: 'Third Party',
          value: informationRequestRecord?.thirdParty ? 'Yes' : 'No - This Information Request is for the client',
          translate: false
        },
        ...(!informationRequestRecord?.thirdParty ? [] : [
          {
            label: 'Recipient First Name',
            value: informationRequestRecord?.recipientFirstName,
            translate: false
          },
          {
            label: 'Recipient Last Name',
            value: informationRequestRecord?.recipientLastName,
            translate: false
          },
          {
            label: 'Recipient Email',
            value: informationRequestRecord?.recipientEmail,
            translate: false
          },
        ]),
        {
          label: 'Info Request Link',
          translate: false,
          value: eApplicationFormData?.information_request_link && (
            <Typography.Link
              href={eApplicationFormData?.information_request_link}
              target="_blank"
              rel="noreferrer"
            >
              {eApplicationFormData?.information_request_link}
            </Typography.Link>
          )
        },

        {
          label: 'Pin code',
          translate: false,
          value: eApplicationFormData.pincode
        },
      ]}
    />
  )
}

export default Details
