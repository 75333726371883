import {useAsync} from 'react-use'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'

export default function() {
  const account_id = useSelector(state => state.appState?.currentAccountRecord?.id)
  const state = useAsync(async () => {
    if (account_id) {
      const { payload } = await API.get('account-financial-accounts', `/list/${account_id}`)
      return payload
    }
    return null
  }, [account_id])

  return state
}