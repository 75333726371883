import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {
  Table,
  Tag,
  Button,
  Row,
  Col,
  Card
} from 'antd'
import {
  listHOC,
  inputTypes
} from 'common'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {
  EditorState,
  convertToRaw
} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'

import {API} from 'aws-amplify'
import Checkbox from 'antd/lib/checkbox/Checkbox'

import {showNotification} from 'utils'
import {getStatusOptions} from '../../common'
import {
  staffsActions,
  staffsSelectors
} from 'configs/ducks/staffs'
import {ClientTasksPageDrawerComponent} from './ClientTasksViewPageDrawerComponent'
import { ClientNewTaskPageComponent } from '../ClientNewTaskPageComponent'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */

const statuses = {
  1: {
    color: 'geekblue',
    text: 'High'
  },
  2: {
    color: 'blue',
    text: 'Medium'
  },
  3: {
    color: 'warning',
    text: 'Low'
  }
}

const statusFilters = getStatusOptions(statuses)

const ClientsTasksListPageComponent = ({
  getColumnSearchProps,
  tasksClient,
  currentAccountID,
  clientId,
  entities,
  loading,
  fetchCb,
  staffList,
  currentStaff
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = bool => setIsModalVisible(bool)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, seteditorText] = useState('')

  const [state, setState] = useState({
    visible: false,
    organiserData: null
  })

  const toggleDrawer = (bool, record) => {
    setState({
      ...state,
      organiserData: record,
      visible: bool
    })
  }

  const columns = [
    {
      title: 'Task ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (highlighter, record) => (
          <Button
            htmlType='button'
            type='link'
            onClick={() => toggleDrawer(true, record)}
          >
            {highlighter}
          </Button>
        )
      })
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      sorter: (a, b) => a.priority - b.priority,
      ...getColumnSearchProps({
        dataIndex: 'priority',
        placeholder: 'priority',
        filterInputType: inputTypes.SELECT,
        selectFilterOptions: statusFilters,
        getOptionProps: ({text, color}) => ({children: <Tag color={color}>{text}</Tag>}),
        optionValue: 'id',
        render: (_, {priority}) => {
          if (!priority) {
            return null
          }
          const {text, color} = statuses[priority]
          return (
            <Tag color={color} style={{textAlign: 'center'}}>
              {text}
            </Tag>
          )
        }
      })
    },
    {
      title: 'Title',
      dataIndex: 'title',
      ...getColumnSearchProps({
        dataIndex: 'titile',
        placeholder: 'Title'
      })
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: (a, b) => a.dueDate - b.dueDate,
      ...getColumnSearchProps({
        dataIndex: 'dueDate',
        placeholder: 'Due Date',
        filterInputType: inputTypes.DATE_RANGE,
        render: (highlighter, record) => <Fragment>{moment(highlighter).format('YYYY-MM-DD')}</Fragment>
      })
    },
    {
      title: 'Completed',
      dataIndex: 'completedFlag',
      sorter: (a, b) => a.completedFlag - b.completedFlag,
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      onFilter: (value, record) => record.completedFlag === value,
      render: (completedFlag, record) => (
        <Fragment>
          <Row gutter={8}>
            <Col>
              <Checkbox
                checked={completedFlag === 1}
                onChange={e => {
                  updateCompletedFlags({
                    ...record,
                    completedFlag: e.target.checked
                  })
                }}
              />
            </Col>
            <Col>
              {!completedFlag ? (
                <Tag color='red' style={{textAlign: 'center'}}>
                  No
                </Tag>
              ) : (
                <span>{record.completedDate.split('T')[0]}</span>
              )}
            </Col>
          </Row>
        </Fragment>
      )
    }
  ]

  const updateCompletedFlags = async values => {
    if (values.completedFlag) {
      values.completedDate = moment()
      values.completedFlag = 1
    } else {
      values.completedFlag = 0
      values.completedDate = null
    }
    const {
      id, client_nickname, first_name, last_name, ...payload
    } = values
    await API.put('tasksClient', `/update/${id}`, {body: payload})
    fetchCb(clientId)
  }

  const onFinish = async values => {
    try {
      values = {
        ...values,
        notes: editorText
      }
      await API.post('tasksClient', '/create', {body: {...values}})
      fetchCb(clientId)
      toggleModal(false)
      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error) {
      showNotification('error', 'Journals', error.message)
    }
  }

  const handleCreateNew = () => {
    toggleModal(true)
  }

  return (
    <Fragment>
      <Card
        style={{minHeight: 360}}
        title='Client Tasks'
        extra={ state.deletedFlag !== 1 ? [
          <Button key='2' onClick={handleCreateNew}>
            Create New
          </Button>
        ] : []}
      >
        <div style={{padding: 0}}>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={tasksClient}
            loading={loading}
            scroll={{x: true}}
            pagination={{
              total: tasksClient.length,
              showTotal: total => `Total ${total} records`,
              pageSizeOptions: ['20', '50', '100'],
              defaultPageSize: 20,
              showSizeChanger: true
            }}
          />
          <ClientNewTaskPageComponent
            isModalVisible={isModalVisible}
            toggleModal={toggleModal}
            callback={fetchCb}
            clientId={clientId}
            clientList={entities}
            staffList={staffList}
            staffId = {currentStaff.id}
            id={clientId}
          />
        </div>
      </Card>
      <ClientTasksPageDrawerComponent
        {...state}
        toggleModal={toggleDrawer}
        staffList={staffList}
        currentStaff={currentStaff}
        callback={fetchCb}
        currentAccountID={currentAccountID}
      />
    </Fragment>
  )
}
const {clientsSelector} = clientsSelectors
const {clientsTasksbyClientId} = clientsActions

const mapStateToProps = state => {
  return {
    currentStaff: appStateSelectors.stateSelector(state).currentStaffRecord,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    entities: clientsSelectors.clientsSelector(state).entities,
    staffList: staffsSelectors.stateSelector(state).entities,
    ...clientsSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: clientsTasksbyClientId,})(listHOC(ClientsTasksListPageComponent))