import * as React from 'react'
import {
  EyeOutlined,
  Image,
  Typography
} from 'components'

interface FileRendererProps {
  url: string;
  contentType: string;
}

const FileRenderer: React.FC<FileRendererProps> = ({url, contentType}) => {
  const isImage = contentType.includes('image')

  if (!isImage) {
    return (
      <Typography.Link href={url} target="_blank">
        <EyeOutlined />
      </Typography.Link>
    )
  }

  return <Image src={url} width={50} height={50}/>
}

export default FileRenderer
