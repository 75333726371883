import * as React from 'react'

import {
  Divider,
  Card,
  Typography
} from 'antd'

import {createUseStyles} from 'react-jss'

interface Topics {
  title?: string,
  subTitle?: string,
  extra?: any
}

export interface ChildProps {
  getTopics(args: Topics): void;
}

interface DashboardHOC<P> {
  Component: React.FC<P>;
  title: string;
  subTitle?: string;
}

const useStyles = createUseStyles({
  header: {
    borderBottom: '1px solid rgb(235, 237, 240)',
    marginBottom: 16
  },
  card: {'& .ant-card-body': {}}
})

export const dashboardHOC = <P extends ChildProps>(
  {
    Component,
    title,
    subTitle
  }: DashboardHOC<P>
) => (props: any) => {

    const [topics, setTopics] = React.useState<Topics>({
      title,
      subTitle,
      extra: []
    })

    const classes = useStyles()

    const getTopics = (
      newValues : Topics
    ) => {
      setTopics(prevState => ({
        ...prevState,
        ...newValues
      }))
    }

    return (
      <Card
        className={classes.card}
        title={topics.title}
        extra={topics.extra}
      >
        {subTitle && (
          <>
            <Typography.Title level={5}>
              {subTitle}
            </Typography.Title>
            <Divider/>
          </>
        )}
        <Component {...props} getTopics={getTopics} />
      </Card>
    )
  }
