import {Record} from 'immutable'

import moment from 'moment'

import * as types from './types'

import {CLEAR_STATE} from '../../constants'

const VerificationData = Record({
  client_id: null,
  first_name: '',
  id: null,
  last_name: '',
  request_datetime: '',
  response_datetime: '',
  trulioo_record_id: '',
  trulioo_transaction_id: null
})
const TrulioDataRecord = Record({
  loaded: false,
  InputFields: [],
  trulioMatch: '',
  trulioMatchRule: '',
  DatasourceResults: []
})
const ReducerRecord = Record({
  entities: [],
  loading: false,
  verificationData: new VerificationData(),
  trulioData: new TrulioDataRecord(),
})

export default (state = new ReducerRecord(), {
  type,
  payload
}) => {
  switch (type) {
    case types.FETCH_VERIFICATIONS_REQUEST:
    case types.FETCH_ENROLMENTS_REQUEST:
    case types.FETCH_DAILY_REPORT_REQUEST:
    case types.FETCH_CHECKS_REQUEST:
    case types.UPDATE_VERIFICATION_REQUEST:
    case types.CREATE_VERIFICATION_REQUEST:
    case types.CREATE_TRULIOO_RECORD_REQUEST:
      return state.set('loading', true)

    case types.GET_VERIFICATIONS_INFO_REQUEST:
      return state
        .mergeDeepWith((oldVal, newVal) => newVal, {
          trulioData: {loaded: false},
          loading: true
        })
    case types.GET_VERIFICATIONS_INFO_ERROR:
      return state
        .mergeDeepWith((oldVal, newVal) => newVal, {
          trulioData: {loaded: false},
          loading: false
        })

    case types.FETCH_VERIFICATIONS_ERROR:
    case types.UPDATE_VERIFICATION_SUCCESS:
    case types.UPDATE_VERIFICATION_ERROR:
    case types.CREATE_VERIFICATION_ERROR:
    case types.CREATE_TRULIOO_RECORD_ERROR:
    case types.CREATE_TRULIOO_RECORD_SUCCESS:
      return state.set('loading', false)

    case types.FETCH_VERIFICATIONS_SUCCESS:
      return state.merge({
        entities: payload.map(({
          first_name,
          last_name,
          request_datetime,
          ...item
        }) => ({
          ...item,
          request_datetime: moment.utc(request_datetime).format('YYYY-MM-DD HH:mm:ss'),
          client: `${first_name} ${last_name}`
        })),
        loading: false
      })

    case types.FETCH_ENROLMENTS_SUCCESS:
      return state.merge({
        entities: payload,
        loading: false
      })

    case types.FETCH_CHECKS_SUCCESS:
      return state.merge({
        entities: payload,
        loading: false
      })

    case types.FETCH_DAILY_REPORT_SUCCESS:
      return state.merge({
        entities: payload,
        loading: false
      })
      
    case types.CREATE_VERIFICATION_SUCCESS:
      return state.merge({
        entities: [
          ...state.entities,
          payload
        ],
        loading: false
      })

    case types.GET_VERIFICATIONS_INFO_SUCCESS:
      const {
        verificationData,
        trulioResult: {
          InputFields,
          Record: {
            DatasourceResults,
            RecordStatus,
            Rule
          }
        }
      } = payload
      return state
        .mergeWith((old, newVal) => newVal, {
          loading: false,
          verificationData,
          trulioData: {
            loaded: true,
            InputFields,
            trulioMatch: RecordStatus,
            trulioMatchRule: `${Rule.RuleName || ''}: ${Rule.Note || ''}`,
            DatasourceResults
          },
        })
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}
