import React, {
  useEffect,
  useState
} from 'react'
import {
  Table,
  Button,
  notification,
  Descriptions,
  NavLink,
  CreateInformationRequestModal
} from 'components'
import moment from 'moment'
import {useSelector} from 'hooks'
import {FULL_DATE_TIME_FORMAT} from 'consts'
import {API} from 'utils'
import {listHOC} from 'hocs'
import {routes} from 'configs'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

const ClientsInformationRequests = ({
  getColumnSearchProps,
  getTopics
}) => {
  const [modalOpen, toggleModalOpen] = useState(false)
  const [statusFilterOptions, setStatusFilterOptions] = useState([])
  const [state, setState] = useState({
    fetching: false,
    submitting: false,
    results: [],
    countCreatedResults: [],
    countSentResults: [],
    countInProcessResults: [],
    countSubmittedResults: [],
    countAdditionalInformationResults: [],
    countReviewResults: [],
    countCompletedResults: [],
  })

  const accountID = useSelector(state => state.appState.currentAccountRecord.id)

  useEffect(() => {
    const init = async () => {
      try {
        setState(prevState => ({
          ...prevState,
          fetching: true
        }))
        const {payload} = await API.get('information-requests', `/list/${accountID}`)

        setState(prevState => ({
          ...prevState,
          fetching: false,
          ...payload
        }))
      } catch (e) {
        openNotificationWithIcon('error', 'Error', e?.response?.data?.error || e.message)
        setState(prevState => ({
          ...prevState,
          fetching: false
        }))
      }
    }
    init()
  }, [accountID])

  const columns = [
    getColumnSearchProps({
      placeholder: 'ID',
      title: 'Xeonda ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <NavLink to={routes.clientsInformationRequestView(record.clientId, record.id)}>
          {text}
        </NavLink>
      )
    }),
    getColumnSearchProps({
      title: 'EAF ID',
      dataIndex: 'eafId',
      placeholder: 'EAF ID'
    }),
    getColumnSearchProps({
      title: 'Created',
      dataIndex: 'recordCreated',
      filterInputType: listHOC.inputTypes.DATE_RANGE,
      render: (text, record) => (
          record?.recordCreated
            ? moment(record.recordCreated).format(FULL_DATE_TIME_FORMAT)
            : ''
      )
    }),
    {
      title: 'Client',
      dataIndex: 'clients_fileBy',
      ...getColumnSearchProps({
        dataIndex: 'clients_fileBy',
        placeholder: 'Client',
        render: (highlighter, record) => (
          <NavLink to={`/clients/view/${record.clientId}?tab=summary`}>{highlighter}</NavLink>
        )
      })
    },
    {
      title: 'Recipient',
      dataIndex: 'recipientFirstName',
      ...getColumnSearchProps({
        dataIndex: 'recipientFirstName',
        placeholder: 'Recipient',
        render: (highlighter, record) => (
          record?.recipientFirstName ? `${record.recipientFirstName}, ${record.recipientLastName}` : ''
        )
      })
    },
    {
      title: 'Status',
      dataIndex: 'adminInformationRequestStatus_fileBy',
      defaultFilteredValue: [
        'Created',
        'Sent',
        'Submitted',
        'In Process',
        'Completed',
        'Review Labuan',
        'Additional Information'
      ],
      filters: statusFilterOptions,
      onFilter: (value, record) => record.adminInformationRequestStatus_fileBy === value,
    }
  ]

  useEffect(() => {
    getTopics({
      extra: (
        <Button onClick={() => toggleModalOpen(true)}> New </Button>
      )
    })
    API.get('information-requests', '/get-ir-status-list').then(
      response => {
        const filterArray = response.payload.map((obj) => {
          return {
            key: obj.file_by,
            id: obj.id,
            value: obj.fileBy,
            text: obj.fileBy
          }
        })
        setStatusFilterOptions(filterArray)
      }
    )
  }, [])

  return (
    <>
      <Table
        rowKey='id'
        loading={state.fetching}
        columns={columns}
        scroll={{ x: true }}
        dataSource={state.results}
        pagination={{
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true
        }}
      />
      <Descriptions
        bordered
        layout='horizontal'
        size='small'
        style={{
          marginBottom: 24,
          width: '50%'
        }}
        column={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1
        }}
      >
        <Descriptions.Item label='Total Created'>
          <strong>{state.countCreatedResults[0]?.total}</strong>
        </Descriptions.Item>
        <Descriptions.Item label='Total Sent'>
          <strong>{state.countSentResults[0]?.total}</strong>
        </Descriptions.Item>
        <Descriptions.Item label='Total In Process'>
          <strong>{state.countInProcessResults[0]?.total}</strong>
        </Descriptions.Item>
        <Descriptions.Item label='Total Submitted'>
          <strong>{state.countSubmittedResults[0]?.total}</strong>
        </Descriptions.Item>
        <Descriptions.Item label='Total Additional Information'>
          <strong>{state.countAdditionalInformationResults[0]?.total}</strong>
        </Descriptions.Item>
        <Descriptions.Item label='Total Review Labuan'>
          <strong>{state.countReviewResults[0]?.total}</strong>
        </Descriptions.Item>
        <Descriptions.Item label='Total Completed'>
          <strong>{state.countCompletedResults[0]?.total}</strong>
        </Descriptions.Item>
      </Descriptions>
      <CreateInformationRequestModal
        open={modalOpen}
        onCancel={() => toggleModalOpen(false)}
        onSuccess={(record) => {
          setState(prevState => ({
            ...prevState,
            results: prevState.results.concat(record)
          }))

          toggleModalOpen(false)
        }}
      />
    </>
  )
}

export default listHOC(ClientsInformationRequests)
