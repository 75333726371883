import React from 'react'
import {CopyOutlined} from '@ant-design/icons'
import {showNotification} from 'utils'

const CopyToClipboardComponent = ({copyText, fieldName}) => {

  const clickCopyText = () => {
    navigator.clipboard.writeText(copyText)
    showNotification('success', '', `${copyText} copied to clipboard`)
  }

  return <CopyOutlined onClick={clickCopyText}/>
}

export default CopyToClipboardComponent