import * as types from './types'

export const fetchOrganiser = payload => ({
  type: types.FETCH_ORGANISER_REQUEST,
  payload
})

export const fetchOrganiserByStaffId = payload => ({
  type: types.ORGANISER_BY_STAFF_ID_REQUEST,
  payload
})

export const fetchOrganiserByClientId = payload => ({
  type: types.ORGANISER_BY_CLIENT_ID_REQUEST,
  payload
})

export const fetchOrganiserById = payload => ({
  type: types.ORGANISER_BY_ID_REQUEST,
  payload
})