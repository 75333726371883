import React, {useEffect} from 'react'
import { connect } from 'react-redux'

import {clientsActions} from 'configs/ducks/clients'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  documentsActions,
  documentsSelectors
} from 'configs/ducks/documents'

import {FileUpload} from 'common'

import {columnSize} from '../common'
import {saveBtnIsDisabled} from './common'

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Spin,
  InputSelect,
  ClientsSelect,
} from 'components'

const { TextArea } = Input

const DocumentNewPageComponent = (
  {
    loading,
    docTypeList,
    onFileUploadSuccess,
    getDoctypeList,
    createNewDocument,
    currentAccountID,
    fileIDs,
    history
  }
) => {
  const [form] = Form.useForm()
  useEffect(() => {
    getDoctypeList()
  }, [])
  const requiredFields = [
    'client_id',
    'description',
    'document_type_id'
  ]
  const onSubmit = values => {
    !(saveBtnIsDisabled(form, requiredFields) || !fileIDs.length) && createNewDocument({
      ...values,
      fileIDs,
      currentAccountID,
      successCb: () => history.push('/documents/list')
    })
  }

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onSubmit}
      >
        <Row gutter={[96, 24]}>
          <Col {...columnSize}>
            <InputSelect
              required
              label='common.documentType'
              name='document_type_id'
              options={docTypeList}
              getOptionProps={(opt) => ({
                value: opt.id,
                children: opt.fileBy
              })}
            />
            <ClientsSelect
              required
              name='client_id'
            />
            <Form.Item
              label='Short Description'
              name='description'
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label='Long Description'
              name='long_description'
            >
              <TextArea
                autoSize={{
                  minRows: 2,
                  maxRows: 8,
                }}
              />
            </Form.Item>
          </Col>
          <Col {...columnSize}>
            <FileUpload
              fileIDs={fileIDs}
              onUploadSuccess={(file) => onFileUploadSuccess(file.id)}
            />
          </Col>
        </Row>
        <Row justify='end'>
          <Form.Item dependencies={requiredFields}>
            {() => {
              return <Button
                type='primary'
                htmlType='submit'
                disabled={saveBtnIsDisabled(form, requiredFields) || !fileIDs.length}
              >
                Save Record
              </Button>
            }}
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  )
}

const mapStateToProps = (state) => {
  const {
    loading,
    fileIDs,
    docTypeList
  } = documentsSelectors
    .documentTypesSelector(state)
  return {
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    fileIDs,
    loading,
    docTypeList
  }
}

const {createNewDocument} = clientsActions
const {
  onFileUploadSuccess,
  getDoctypeList
} = documentsActions

const mapDispatchToProps = {
  createNewDocument,
  onFileUploadSuccess,
  getDoctypeList
}

const DocumentNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentNewPageComponent)

export default DocumentNewPageComponentContainer
