import * as types from './types'

/**
 * @param {number} currentAccountID
 * @returns {{id: *, type: string}}
 */
export const getStaffList = currentAccountID => ({
  type: types.GET_STAFF_LIST_REQUEST,
  currentAccountID
})
/**
 * @param {number} id
 * @returns {{id: *, type: string}}
 */
export const getStaffRecord = id => ({
  type: types.GET_STAFF_RECORD_REQUEST,
  id
})
/**
 * @param {Object} body
 * @returns {{id: *, type: string}}
 */
export const updateStaffRecord = body => ({
  type: types.UPDATE_STAFF_RECORD_REQUEST,
  body
})
/**
 * @param {email} email
 * @param {Function} cb
 * @returns {{id: *, type: string}}
 */
export const checkStaffEmail = (email, cb) => ({
  type: types.CHECK_STAFF_EMAIL_REQUEST,
  payload: {
    email,
    cb
  }
})
/**
 * @param {email} staff
 * @param {Function} history
 * @returns {{id: *, type: string}}
 */
export const createNewStaff = (staff, history) => ({
  type: types.CREATE_NEW_STAFF_REQUEST,
  payload: {
    staff,
    history
  }
})