import reducer from './reducers'

import * as opportunitiesSelectors from './selectors'
import * as opportunitiesActions from './actions'
import * as opportunitiesTypes from './types'
import opportunitiesSaga from './sagas'

export {
  opportunitiesSaga,
  opportunitiesTypes,
  opportunitiesActions,
  opportunitiesSelectors,
}

export default reducer