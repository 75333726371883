import {all, call, put, takeEvery} from 'redux-saga/effects'
import {API} from 'aws-amplify'

import * as types from './types'
import {openNotificationWithIcon} from '../../utils'

function* fetchOrganiser(action) {
  try {
    const data = yield call([API, API.get], 'organiser', `/getTaskByAcountId/${action.payload}`)

    yield put({
      type: types.FETCH_ORGANISER_SUCCESS,
      payload: data.payload
    })
  } catch (e) {
    yield put({type: types.FETCH_ORGANISER_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch Organiser', e.message)
  }
}

function* OrganiserById(action) {
  try {
    const data = yield call([API, API.get], 'organiser', `/getTaskByID/${action.payload}`)
    yield put({
      type: types.ORGANISER_BY_ID_SUCCESS,
      payload: data.payload
    })
  } catch (e) {
    yield put({type: types.ORGANISER_BY_ID_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch Organiser', e.message)
  }
}

function* OrganiserByStaffId(action) {
  try {
    const data = yield call([API, API.get], 'organiser', `/getTaskByStaffID/${action.payload}`)
    yield put({
      type: types.ORGANISER_BY_STAFF_ID_SUCCESS,
      payload: data.payload
    })
  } catch (e) {
    yield put({type: types.ORGANISER_BY_STAFF_ID_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch Organiser', e.message)
  }
}

function* OrganiserByClientId(action) {
  try {
    const data = yield call([API, API.get], 'organiser', `/getTaskByClientID/${action.payload}`)
    yield put({
      type: types.ORGANISER_BY_CLIENT_ID_SUCCESS,
      payload: data.payload
    })
  } catch (e) {
    yield put({type: types.ORGANISER_BY_CLIENT_ID_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch Organiser', e.message)
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.FETCH_ORGANISER_REQUEST, fetchOrganiser),
    yield takeEvery(types.ORGANISER_BY_ID_REQUEST, OrganiserById),
    yield takeEvery(types.ORGANISER_BY_STAFF_ID_REQUEST, OrganiserByStaffId),
    yield takeEvery(types.ORGANISER_BY_CLIENT_ID_REQUEST, OrganiserByClientId)
  ])
}