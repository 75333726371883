import * as types from './types'

export const createLead = payload => ({
  type: types.CREATE_LEAD_REQUEST,
  payload
})

export const fetchLeads = payload => ({
  type: types.FETCH_LEADS_REQUEST,
  payload
})

export const getLeadByID = id => ({
  type: types.GET_LEAD_REQUEST,
  payload: id
})

export const saveLeadRecord = leadRecord => ({
  type: types.SAVE_LEAD_REQUEST,
  payload: leadRecord
})

export const getJournalByLeadId = payload => ({
  type: types.GET_JOURNAL_BY_LEAD_REQUEST,
  payload
})

export const checkLeadsEmail = (email, cb) => ({
  type: types.CHECK_LEADS_EMAIL_REQUEST,
  payload: {
    email,
    cb
  }
})

export const getStreets = () => ({type: types.STREET_TYPE_REQUEST})