import {moduleName} from './types'

/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const tradingCommissionsClientsSelector = state => state[moduleName].entities.toJS()

/**
 * @param {Object} state
 * @returns {*}
 */
export const tradingCommissionsWalletsSelector = state => {
  return state[moduleName].entities.toJS()
}
