import React, {useEffect} from 'react'

import {ThemeProvider} from 'react-jss'
import {IntlProvider} from 'react-intl'
import flatten from 'flat'
import {
  Spin,
  LoadingOutlined
} from 'components'
import translations from 'translations'
import {BrowserRouter as Router} from 'react-router-dom'
import {
  configureAmplify,
  appStateDuck
} from 'configs'
import {
  useDispatch,
  useSelector
} from 'hooks'
import Layout from 'layout'

configureAmplify('staff')

const theme = {
  label_font_weight: 500,
  input_disabled_color: '#333 !important',
  input_disbaled_background_color: '#fafafa !important'
}

const App = () => {
  const dispatch = useDispatch()
  const {
    appRemountKey,
    authenticating,
    language
  } = useSelector(state => ({
    appRemountKey: state.appState.appRemountKey,
    authenticating: state.appState.authenticating,
    language: state.language.language_current_ui
  }))

  useEffect(() => {
    dispatch(appStateDuck.appStateActions.authenticateUser())
  }, [])

  return (
    <IntlProvider locale={language} messages={flatten(translations[language])}>
      <ThemeProvider theme={theme}>
        <Router>
          {authenticating ? (
            <Spin
              indicator={<LoadingOutlined />}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              size="large"
              spinning
            />
          ) : <Layout appRemountKey={appRemountKey} /> }
        </Router>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App