import React, {
  useState,
  useEffect,
} from 'react'
import {useSelector} from 'react-redux'

import {
  useLocation,
  useHistory
} from 'react-router-dom'
import {Tabs} from 'antd'
import {routes as appRoutes} from 'configs'

import TradingTransactionsSummary from './trading_transactions_view/TradingTransactionsSummary'
import TradingTransactionsTradingAccount from './trading_transactions_view/TradingTransactionsTradingAccount'
import TradingTransactionsClient from './trading_transactions_view/TradingTransactionsClient'
import TradingTransactionsDeposit from './trading_transactions_view/TradingTransactionsDeposit'
import TradingTransactionsWithdrawal from './trading_transactions_view/TradingTransactionsWithdrawal'

import {API} from 'utils'
import queryString from 'query-string'
import AttachedDocument from './sub-components/AttachedDocument'
import {tradingSelectors} from 'configs/ducks/trading'

const {TabPane} = Tabs

const routes = [
  {
    path: '/trading/transactions-2',
    breadcrumbName: 'Transactions',
  },
  {
    path: 'first',
    breadcrumbName: 'View Details',
  }
]

const TradingTransactionsViewPageComponent = (
  {
    getTopics,
    match: {params: {id: tradingTransactionID}}
  }
) => {
  const {search} = useLocation()
  const [state, setState] = useState({
    document: {},
    tradingTransaction: [],
    activeTab: queryString.parse(search).tab,
  })
  const {loading} = useSelector(tradingSelectors.tradingTransactionSelector)

  const history = useHistory()

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('trading-transactions-2', `/id/${tradingTransactionID}`)
        let fileList = []
        let document = []
        if (response.payload.documentsID) {
          const documentResponse = await API.get('documents', `/id/${response.payload.documentsID}`)
          const {file_results, document_results } = documentResponse.payload
          document = document_results

          fileList = file_results
        }

        getTopics({
          title: 'Trading Transaction Management',
          breadCrumb: routes,
          subTitle: 'View Trading Deposits Details'
        }
        )
        setState({
          ...state,
          tradingTransaction: response.payload,
          fileList,
          document: document[0],
        })
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  useEffect(() => {
    setState({
      ...state,
      activeTab: search ? queryString.parse(search).tab : 'summary'
    })
  }, [search])

  const onTabClick = e => {
    history.push(appRoutes.tradingTransactions2View(tradingTransactionID, e))
  }

  return (
    <Tabs defaultActiveKey='summary' activeKey={state.activeTab} onTabClick={onTabClick}>
      <TabPane tab='Summary' key='summary'>
        <TradingTransactionsSummary id={tradingTransactionID} />
      </TabPane>
      <TabPane tab='Trading Account' key='tradingAccount'>
        <TradingTransactionsTradingAccount id={tradingTransactionID} />
      </TabPane>
      <TabPane tab='Client' key='client'>
        <TradingTransactionsClient id={tradingTransactionID} />
      </TabPane>
      {state.tradingTransaction?.debit && <TabPane tab='Deposit' key='deposit'>
        <TradingTransactionsDeposit id={tradingTransactionID} />
      </TabPane>}
      {state.tradingTransaction?.credit && <TabPane tab='Withdrawal' key='withdrawal'>
        <TradingTransactionsWithdrawal id={tradingTransactionID} />
      </TabPane>}
      <TabPane tab='Documents' key='documents'>
        <AttachedDocument
          id={tradingTransactionID}
          loading={loading}
          document={state.document}
          file={state.fileList?.[0]}
        />
      </TabPane>
    </Tabs>
  )
}

export default TradingTransactionsViewPageComponent