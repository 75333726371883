import React, {
  Fragment,
  useState
} from 'react'
import {useSelector} from 'hooks'
import {
  Select,
  Button,
  Row,
  Space,
  Input,
  InputDatePicker,
  InputSelect,
  ClientsSelect,
  Form,
  Checkbox
} from 'components'
import {Editor} from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw
} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'

import {API} from 'aws-amplify'
import {openNotificationWithIcon} from 'configs/utils'
import {ModalView} from '../../../common/ModalView'
import {FormattedMessage} from 'react-intl'

export const ClientNewTaskPageComponent = ({
  isModalVisible,
  toggleModal,
  callback,
}) => {
  const [form] = Form.useForm()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, seteditorText] = useState('')
  const [sendEmail, setEmail] = useState(false)

  const {
    currentAccountID,
    currentStaffRecord,
    staffList,
    clientID
  } = useSelector(state => ({
    currentStaffRecord: state.appState.currentStaffRecord,
    currentAccountID: state.appState.currentAccountRecord.id,
    clientID: state.clients.entities.clientRecord.id,
    staffList: state.staffs.entities,
  }))
  const onEditorStateChange = editor => {
    setEditorState(editor)
    seteditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }

  const onFinish = async values => {
    try {
      values = {
        ...values,
        notes: editorText
      }
      await API.post('tasksClient', '/create', {body: {...values}})

      if (sendEmail) {
        await API.post('email', '/client/new-client-task-created', {body: {client_id: values.clientId}})
        openNotificationWithIcon('success', 'New Task Email', 'Email successfully sent')
      }
      callback(currentAccountID)
      toggleModal(false)
      openNotificationWithIcon('success', 'Journals', 'Successfully Created')
    } catch (error) {
      openNotificationWithIcon('error', 'Journals', error.message)
    }
  }

  return (
    <Fragment>
      <ModalView
        title='Create New Client Task'
        isModalVisible={isModalVisible}
        handleOk={() => toggleModal(!isModalVisible)}
        handleCancel={() => toggleModal(!isModalVisible)}
        footer={null}
        body={
          <Fragment>
            <Form
              form={form}
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                clientId: clientID,
                staffId: currentStaffRecord.id
              }}
              scrollToFirstError={true}
            >

              <InputSelect
                required
                label='common.staff'
                name='staffId'
                options={staffList}
                getOptionProps={(opt) => ({
                  value: opt.id,
                  children: `${opt.id} - ${opt.first_name} ${opt.last_name}`
                })}
              />
              <ClientsSelect
                required
                name='clientId'
              />
              <Checkbox
                onChange={e => {
                  setEmail(e.target.checked)
                }}
              >
                Send Email Notification to client
              </Checkbox>
              <br />
              <Form.Item name='title' label='Title'>
                <Input />
              </Form.Item>
              <Form.Item name='priority' label='Priority'>
                <Select>
                  <Select.Option value='1'>High</Select.Option>
                  <Select.Option value='2'>Medium</Select.Option>
                  <Select.Option value='3'>Low</Select.Option>
                </Select>
              </Form.Item>

              <InputDatePicker name='dueDate' label='date.due' />

              <Form.Item name='notes' label='Notes'>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    borderRadius: '2px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '250px'
                  }}
                  toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history']}}
                />
              </Form.Item>
              <Row justify='end'>
                <Space>
                  <Button htmlType='submit' type='primary'>
                    <FormattedMessage id='components.button.save' />
                  </Button>
                </Space>
              </Row>
            </Form>
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default ClientNewTaskPageComponent

