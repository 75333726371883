import reducer from './reducers'

import * as staffsSelectors from './selectors'
import * as staffsActions from './actions'
import * as staffsTypes from './types'
import staffsSaga, {getStaffList} from './sagas'

export {
  staffsSaga,
  getStaffList,
  staffsTypes,
  staffsActions,
  staffsSelectors,
}

export default reducer