import React from 'react'
import {
  Row,
  Space,
  Button
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { createUseStyles } from 'react-jss'
import { StepButtonsProps } from 'types'
import classNames from 'classnames'

const useStyles = createUseStyles({wrapper: {padding: '14px 0'}})

const StepButtons: React.FC<StepButtonsProps> = ({
  loading,
  onPrevClick,
  currentStep,
  steps,
  onNextClick,
  wrapperClassName,
}) => {
  const classes = useStyles()
  const isLastStep = steps.length - 1 === currentStep

  return (
    <Row
      justify="end"
      className={classNames(classes.wrapper, wrapperClassName)}
    >
      <Space>
        {currentStep > 0 && (
          <Button
            onClick={() => onPrevClick?.()}
            htmlType="button"
          >
            <FormattedMessage id="button.previous" />
          </Button>
        )}
        <Button
          type="primary"
          htmlType={onNextClick ? 'button' : 'submit'}
          loading={loading}
          // omit event object
          onClick={onNextClick ? () => onNextClick() : undefined}
        >
          <FormattedMessage id={isLastStep ? 'button.save' : 'button.next'} />
        </Button>
      </Space>
    </Row>
  )
}

export default StepButtons
