import * as React from 'react'
import {
  Form,
  DatePicker,
  FormItemProps,
  DatePickerProps
} from 'antd'
import { useIntl } from 'hooks'
import { DATE_FORMAT } from 'consts'
import dayjs from 'dayjs'

export interface InputDatePickerProps extends Omit<FormItemProps, 'status'>, Pick<DatePickerProps, 'picker' | 'onChange' | 'value' | 'disabledDate' | 'status'> {
  formItem?: boolean;
  allowClear?: boolean;
  isAfterToday?: boolean;
  dateFormat?: string;
  placeholder?: string;
  label?: string | null;
  formItemClassName?: string;
}

const InputDatePicker: React.FC<InputDatePickerProps> = ({
  label = 'common.dateOfBirth',
  name,
  dateFormat = DATE_FORMAT,
  formItem = true,
  allowClear = false,
  required = false,
  rules = [],
  isAfterToday,
  picker,
  onChange,
  value,
  disabledDate,
  formItemClassName,
  status
}) => {
  const intl = useIntl()
  const datePickerProps = {
    style: { width: '100%' },
    format: dateFormat,
    allowClear,
    disabledDate,
    picker,
    status
  }

  if (!formItem) {
    return (
      <DatePicker
        {...datePickerProps}
        onChange={onChange}
        value={value}
      />
    )
  }

  return (
    <Form.Item
      label={label && intl.formatMessage({id: label})}
      name={name}
      className={formItemClassName}
      normalize={(dayjsValue) => dayjsValue && dayjsValue.format(dateFormat)}
      getValueProps={(value) => value && { value: dayjs(value, dateFormat) }}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: 'validation.required' }),
        },
        {
          validator(__: any, value: undefined | string) {
            if (!value || !isAfterToday) {
              return Promise.resolve()
            }

            if (dayjs(value).valueOf() > dayjs().valueOf()) {
              return Promise.reject(
                intl.formatMessage({ id: 'validation.futureDate' })
              )
            }

            return Promise.resolve()
          }
        },
        ...rules,
      ]}
    >
      <DatePicker
        {...datePickerProps}
      />
    </Form.Item>
  )
}
export default InputDatePicker
