import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Typography,
  Descriptions,
  Skeleton,
} from 'antd'
import {useSelector} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'

import {API} from 'aws-amplify'

interface IState {
  data: any;
  headers: string[];
  name: string;
  loadingData: boolean;
  leadCount: number;
}

export const LeadStatusMetrics: React.FC = () => {
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    loadingData: true,
    leadCount: 0
  })

  const {id} = useSelector((state: any) => appStateSelectors.stateSelector(state).currentAccountRecord)

  const fetch = async (): Promise<any> => {
    setState({
      ...state,
      loadingData: true,
    })

    const response = await API.get('reportsLeads', `/current/by-status/${id}`, {})
    const data = response.payload?.resultArray
    const leadCount = response.payload?.leadCount

    setState({
      ...state,
      data,
      loadingData: false,
      leadCount
    })
  }

  useEffect(() => {
    fetch()
  }, [])
 
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={4}>
            Lead Metrics
          </Typography.Title>
        </Col>
      </Row>
      
      <Row gutter={[96, 24]}>
        <Skeleton loading={state.loadingData} active>
          <Col span={12}>
            <Descriptions
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              {state.data.map((item: any)=> {
                return <Descriptions.Item
                  contentStyle={{width: '150px'}}
                  label={item[0]?.leadStatusName}>
                  {item[0]?.leadCount}
                </Descriptions.Item>
              })
              }
              <Descriptions.Item label={<strong>Total Clients</strong>}>
                <strong>{state.leadCount}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Skeleton>
      </Row>
    </Fragment>
  )
}

export default LeadStatusMetrics