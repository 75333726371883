import React from 'react'

import {connect} from 'react-redux'
import {teamsSelectors} from 'configs/ducks/teams/index'
import {appStateSelectors} from 'configs/ducks/appState'

import NewTeamSinglePage from './team_new/NewTeamSinglePage'
import {useHistory} from 'react-router-dom'
const TeamNewPageComponent = (
  {accountsList}
) => {
  const history = useHistory()
  return <NewTeamSinglePage
    accountsList={accountsList}
    history = {history}
  />
}

const {teamsSelector} = teamsSelectors
const mapStateToProps = state => {
  const {accountsList} = appStateSelectors.stateSelector(state)
  return {
    accountsList,
    ...teamsSelector(state)
  }
}

export default connect(mapStateToProps, {})(TeamNewPageComponent)
