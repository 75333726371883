import React from 'react'

import {
  Col,
  Row,
  Form,
  InputText
} from 'components'
import {CommonProps} from '../../types'

import {
  COMMON_COL_PROPS,
  ROW_GUTTER
} from 'consts'

const VerificationStep: React.FC<CommonProps> = ({children, initialValues}) => {
  return (
    <Form
      name='verification'
      layout='vertical'
      initialValues={initialValues}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText name='nationalId' label='verification.nationalID' />
          <InputText name='passport_number' label='verification.passportNumber' />
          <InputText name='passport_expiry_day' label='verification.passportExpiryDay' />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputText name='passport_expiry_month' label='verification.passportExpiryMonth' />
          <InputText name='passport_expiry_year' label='verification.passportExpiryYear' />
        </Col>
      </Row>
      {children}
    </Form>
  )
}
export default VerificationStep
