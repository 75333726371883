import React, {
  useState,
  useEffect,
  Fragment
} from 'react'

import {
  connect,
  useDispatch
} from 'react-redux'
import {
  Divider,
  Table,
  Tag,
  Popconfirm,
  Button,
  notification
} from 'antd'
import {
  Link,
  NavLink
} from 'react-router-dom'
import {
  DeleteOutlined,
  EditOutlined,
  SyncOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import ReactCountryFlag from 'react-country-flag'

import {listHOC} from 'common'
import {
  tradingCommissionsClientsActions,
  tradingCommissionsClientsSelectors
} from 'configs/ducks/trading-commissions/index'
import {appStateSelectors} from 'configs/ducks/appState'
import {deleteClient} from 'configs/ducks/clients/actions'
import {API} from 'aws-amplify'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */

const TradingCommissionClientListPageComponent = ({
  getColumnSearchProps, entities, loading, fetchCb, currentAccountID, getTopics
}) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    statusMap: [],
    typeMap: []
  })
  const asyncFunction = async () => {
    try {
      const [typeMap, statusMap] = await Promise.all([
        API.get('leads', '/get-type-map'),
        API.get('clients', '/get-status-map')
      ])
      setState({
        ...state,
        typeMap: typeMap.payload,
        statusMap: statusMap.payload
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const statuses = {
    [state.statusMap[2]?.id]: {
      color: 'warning',
      text: state.statusMap[2]?.file_by
    },
    [state.statusMap[0]?.id]: {
      color: 'geekblue',
      text: state.statusMap[0]?.file_by
    },
    [state.statusMap[1]?.id]: {
      color: 'geekblue',
      text: state.statusMap[1]?.file_by
    },
    [state.statusMap[3]?.id]: {
      color: 'warning',
      text: state.statusMap[3]?.file_by
    },
    [state.statusMap[4]?.id]: {
      color: 'warning',
      text: state.statusMap[4]?.file_by
    },
    [state.statusMap[5]?.id]: {
      color: 'warning',
      text: state.statusMap[5]?.file_by
    },
    [state.statusMap[6]?.id]: {
      color: 'magenta',
      text: state.statusMap[6]?.file_by
    },
    [state.statusMap[7]?.id]: {
      color: 'purple',
      text: state.statusMap[7]?.file_by
    },
    [state.statusMap[8]?.id]: {
      color: 'purple',
      text: state.statusMap[8]?.file_by
    },
    [state.statusMap[9]?.id]: {
      color: 'green',
      text: state.statusMap[9]?.file_by
    }
  }

  const types = {
    [state.typeMap[0]?.id]: {
      color: 'geekblue',
      text: state.typeMap[0]?.fileBy
    },
    [state.typeMap[1]?.id]: {
      color: 'geekblue',
      text: state.typeMap[1]?.fileBy
    },
    [state.typeMap[2]?.id]: {
      color: 'warning',
      text: state.typeMap[2]?.fileBy
    },
    [state.typeMap[3]?.id]: {
      color: 'warning',
      text: state.typeMap[3]?.fileBy
    },
    [state.typeMap[4]?.id]: {
      color: 'warning',
      text: state.typeMap[4]?.fileBy
    }
  }

  useEffect(() => {
    asyncFunction()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (highlighter, record) => <NavLink to={`/clients/view/${record.id}?tab=summary`}>{highlighter}</NavLink>
      })
    },
    {
      title: 'Client Type',
      dataIndex: 'client_type',
      filters: [2,0,1,3,4].map(item => {
        return {
          value: state.typeMap[item]?.id,
          text: state.typeMap[item]?.fileBy
        }
      }),
      onFilter: (value, record) => record.client_type === value,
      placeholder: 'Client Type',
      render: (text) => <span>{types[text] ? types[text].text : 'INVALID'}</span>
    },
    {
      title: 'File By',
      dataIndex: 'file_by',
      ...getColumnSearchProps({
        dataIndex: 'file_by',
        placeholder: 'File By'
      })
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      ...getColumnSearchProps({
        dataIndex: 'first_name',
        placeholder: 'Name'
      })
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      ...getColumnSearchProps({
        dataIndex: 'last_name',
        placeholder: 'Last Name'
      })
    },
    {
      title: 'Client Reference',
      dataIndex: 'account_user_id',
      sorter: (a, b) => a.account_user_id - b.account_user_id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'account_user_id',
        placeholder: 'Account ID',
        render: (highlighter, record) => <NavLink to={`/clients/view/${record.id}?tab=account`}>{highlighter}</NavLink>
      })
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps({
        dataIndex: 'email',
        placeholder: 'Email'
      })
    },
    {
      title: 'Mobile',
      dataIndex: 'tel_mobile',
      ...getColumnSearchProps({
        dataIndex: 'tel_mobile',
        placeholder: 'Mobile'
      })
    },
    {
      title: 'Country',
      dataIndex: 'country_full_name',
      ...getColumnSearchProps({
        dataIndex: 'country_full_name',
        placeholder: 'Country',
        render: (highlighter, record) => (
          <React.Fragment>
            <ReactCountryFlag
              countryCode={record.country_iso_alpha2}
              style={{
                fontSize: '1.5em',
                lineHeight: '1.5em'
              }}
              svg
            />
            &nbsp;
            <span>{highlighter}</span>
          </React.Fragment>
        )
      })
    },
    {
      title: 'Risk',
      dataIndex: 'risk_totalScore',
      placeholder: 'Risk',
      filters: [
        {
          value: 0,
          text: 'No Data'
        },
        {
          value: 1,
          text: 'Low'
        },
        {
          value: 2,
          text: 'Medium'
        },
        {
          value: 3,
          text: 'High'
        },
        {
          value: 4,
          text: 'Declined'
        },
      ],
      onFilter: (text, record) => {
        if (text == 0){
          return record.risk_totalScore == 0 || record.risk_totalScore == null
        }
        if (text == 1){
          return record.risk_totalScore > 0 && record.risk_totalScore <= 13
        }
        if (text == 2){
          return record.risk_totalScore >= 14 && record.risk_totalScore <= 20
        }
        if (text == 3){
          return record.risk_totalScore >= 21 && record.risk_totalScore <= 25
        }
        if (text == 4){
          return record.risk_totalScore >= 25
        }
      },
      render: (text, record) => <Tag color={`${
        text == 0
          ? 'purple'
          : record.risk_totalScore <= 13
            ? 'green'
            : record.risk_totalScore >= 14 && record.risk_totalScore <= 20
              ? 'blue'
              : record.risk_totalScore >= 21 && record.risk_totalScore <= 25
                ? 'volcano'
                : 'red'
      }`}>
        { `${record.risk_totalScore == 0
          ? 'No Data'
          : record.risk_totalScore <= 13
            ? 'Low'
            : record.risk_totalScore >= 14 && record.risk_totalScore <= 20
              ? 'Medium'
              : record.risk_totalScore >= 21 && record.risk_totalScore <= 25
                ? 'High'
                : 'Declined'
        } ${record?.risk_totalScore ? '(' + record?.risk_totalScore + ')' : ''}`}
      </Tag>
    },
    {
      title: 'Status',
      dataIndex: 'status_id',
      filters: [2,0,1,3,4,5,6,7,8,9].map(item => {
        return {
          value: state.statusMap[item]?.id,
          text: state.statusMap[item]?.file_by
        }
      }),
      onFilter: (value, record) => record.status_id === value,
      placeholder: 'Status',
      render: (text) => <Tag color={statuses[text] ? statuses[text]?.color : '#f00'}> {statuses[text] ? statuses[text].text : 'INVALID'}</Tag>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <Fragment>
            <Link to={`/clients/view/${record.id}?tab=summary`}>
              <EditOutlined />
            </Link>
            <Divider type='vertical' />
            
            <Popconfirm
              title='Are you sure to create Information Request for this record?'
              onConfirm={() => createInformationRequest(record)}
              okText='Yes'
              onCancel='No'
            >
              <QuestionCircleOutlined />
            </Popconfirm>
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </Fragment>
        )
      }
    }
  ]

  const deleteRecord = async record => {
    dispatch(deleteClient(record.id))
  }

  const createInformationRequest = async record => {
    await API.post('information-requests', '/create', {
      body: {
        clientId: record.id,
        informationRequestId: 1,
        accountId: record.account_id,
        email: record.email,
        firstName: record.first_name,
        lastName: record.last_name,
        activateFinancial: true,
        activateEdd: true,
        thirdParty: false,
      }
    })
      .then(res => {
        openNotificationWithIcon('success', 'Information Requests', 'New Record has been created')

        API.get('information-requests', `/eaf-ir-data/${res.payload.eafId}`).then(
          response => {
            const parsedData = JSON.parse(response.payload)
            API.post('email', '/client/eaf/ir-link', {
              body: {
                data: {
                  clientId: record.id,
                  eafIrLink: parsedData?.information_request_link ? parsedData?.information_request_link : '',
                  eafIrPin: parsedData?.pincode ? parsedData.pincode : '',
                  thirdparty: false
                },
              }
            }).then(res => {
              openNotificationWithIcon('success', 'Email', 'Email send to client - Send Successful')
            }).catch(err => {
              openNotificationWithIcon('error', 'Email', err.message)
            })
            
          }
        ).catch(err => {
          openNotificationWithIcon('error', 'Information Requests', err.message)
        })

      })
      .catch(err => {
        openNotificationWithIcon('error', 'Information Requests', err.message)
      })
  }

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={entities}
      loading={loading}
      scroll={{x: true}}
      pagination={{
        total: entities.length,
        showTotal: total => `Total ${total} records`,
        pageSizeOptions: ['20', '50', '100'],
        defaultPageSize: 20,
        showSizeChanger: true
      }}
    />
  )
}

const {fetchClientsV2} = tradingCommissionsClientsActions
const {tradingCommissionsClientsSelector} = tradingCommissionsClientsSelectors

const mapStateToProps = state => {
  return {
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    ...tradingCommissionsClientsSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: fetchClientsV2})(listHOC(TradingCommissionClientListPageComponent))