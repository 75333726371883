import React from 'react'

import {
  Form,
  Input,
  FormItemProps
} from 'antd'

interface InputNumberProps extends FormItemProps {
  type?: 'number';
  placeholder?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

const InputRiskNumber: React.FC<InputNumberProps> = (
  {
    type,
    name,
    placeholder = '',
    rules = [],
    required,
    disabled,
    label,
    ...rest
  }
) => {
  const I = Input
  const isInteger = (values: string) => {
    // console.log(values)
    const re = /^[0-9\b]+$/
    if (values === '' || re.test(values)) {
      return false
    }
    return true
  }

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required,
          message: `${label || 'This field'} is required!`
        },
        {
          validator: async (_, name) => {
            if (name < 0) {
              return Promise.reject(new Error(`${label || 'This field'} cannot be negative!`))
            }
          },
        },
        {
          validator: async (_, name) => {
            if (isInteger(name)) {
              return Promise.reject(new Error(`${label || 'This field'} has to be an integer!`))
            }
          },
        },
        {
          validator: async (_, name) => {
            if (!name && name !== 0) {
              return Promise.reject(new Error(`${label || 'This field'} cannot be empty!`))
            }
          },
        },
        ...rules
      ]}
    >
      <I
        type='number'
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default InputRiskNumber