import React, {
  useState,
  useEffect
} from 'react'

import {createUseStyles} from 'react-jss'
import {connect} from 'react-redux'

import {
  staffsActions,
  staffsSelectors
} from 'configs/ducks/staffs'

import {appStateSelectors} from 'configs/ducks/appState'

import {
  inputChange,
  clearState
} from 'configs/actions/common'

import {
  Steps,
  Button,
  Form,
  Space,
  Spin
} from 'antd'

import {
  ClientStaffWizard01,
  ClientStaffWizard02
} from '../common'
import StaffNewStep3 from './staff_new/StaffNewStep3'

const useStyles = createUseStyles({
  stepsContent: {
    minHeight: 280,
    paddingTop: 48
  },
  space: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const {Step} = Steps

const steps = [
  {
    title: 'Staff Details',
    content: ClientStaffWizard01
  },
  {
    title: 'Contact Details',
    content: ClientStaffWizard02
  },
  {
    title: 'Confirm',
    content: StaffNewStep3
  }
]
const ClientNewPageComponent = ({
  clearState,
  checkStaffEmail,
  inputChange,
  createNewStaff,
  loading,
  duplicateEmail,
  currentAccountID,
  countriesList,
  accountsList,
  currentUserRecord,
  history
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const classes = useStyles()
  const [form] = Form.useForm()

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  useEffect(() => {
    if (form.isFieldTouched('email')) {
      form.validateFields()
    }
  }, [duplicateEmail])

  const onSubmit = values => {
    if (currentStep === 0) {
      checkStaffEmail(values.email, () => {
        setCurrentStep(currentStep + 1)
      })
    } else if (currentStep === 2) {
      const fields = form.getFieldsValue()
      if (fields?.account_id){
        createNewStaff(
          {
            ...fields,
            file_by: fields.last_name + ', ' + fields.first_name,
          },
          history
        )
      } else {
        createNewStaff(
          {
            ...fields,
            file_by: fields.last_name + ', ' + fields.first_name,
            account_id: currentAccountID
          },
          history
        )
      }

    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const onPrevStep = () => {
    setCurrentStep(currentStep - 1)
  }
  const onValuesChange = changed => {
    const [key, value] = Object.entries(changed)[0]
    inputChange(key, value)
  }

  const onPhoneInputChange = id => value => {
    inputChange(id, value)
  }
  return (
    <Form form={form} layout='vertical' onFinish={onSubmit} onValuesChange={onValuesChange}>
      <Steps current={currentStep}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Spin wrapperClassName={classes.stepsContent} spinning={loading}>
        {steps.map(({title, content: Component}, i) => (
          <div key={title} style={{display: i !== currentStep ? 'none' : 'block'}}>
            <Component
              duplicateEmail={duplicateEmail}
              countriesList={countriesList}
              accountsList={accountsList}
              currentUserRecord={currentUserRecord}
              form={form}
              onPhoneInputChange={onPhoneInputChange}
            />
          </div>
        ))}
        <Space className={classes.space}>
          {currentStep > 0 && <Button onClick={onPrevStep}>Previous</Button>}
          <Button type='primary' htmlType='submit'>
            {currentStep < 2 ? 'Next' : 'Done'}
          </Button>
        </Space>
      </Spin>
    </Form>
  )
}

const {createNewStaff, checkStaffEmail} = staffsActions

const mapStateToProps = state => {
  const {
    duplicateEmail, loading, client
  } = staffsSelectors.stateSelector(state)
  const {
    currentAccountRecord: {id: currentAccountID},
    currentUserRecord,
    accountsList,
    countriesList
  } = appStateSelectors.stateSelector(state)
  return {
    duplicateEmail,
    loading,
    client,
    countriesList,
    accountsList,
    currentUserRecord,
    currentAccountID
  }
}

export default connect(mapStateToProps, {
  clearState,
  inputChange,
  createNewStaff,
  checkStaffEmail
})(ClientNewPageComponent)