import React, {useEffect} from 'react'
import {
  Button,
  InputDatePicker,
  InputSelect,
  ClientsSelect,
  FormattedMessage,
  Typography,
  Input,
  Row,
  Space,
  Form,
  Col,
  NavLink,
  Modal
} from 'components'
import {routes} from 'configs'
import {Editor} from 'react-draft-wysiwyg'
import {
  EditorState,
  ContentState,
  convertToRaw
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {
  showNotification,
  API
} from 'utils'
import {useSelector} from 'hooks'
import moment from 'moment'
import {
  Document,
  Staff,
  TradingAccountTransaction
} from 'types'
import {Types} from '../../duck'

interface ClientNewTaskModalProps {
  documents: Document[];
  open: boolean;
  toggleModal: (open: boolean) => void;
  clientID: number;
  selectedDocument: Types.ClientDocument;
  updateDocuments: (documents: Types.ClientDocument[]) => void;
}

const ClientNewTaskModal: React.FC<ClientNewTaskModalProps> = ({
  documents,
  updateDocuments,
  open,
  toggleModal,
  clientID,
  selectedDocument
}) => {
  const [form] = Form.useForm()
  const [state, setState] = React.useState<{
    tradingAccountTransactions: TradingAccountTransaction[];
    editorState: EditorState;
    fetchingDocuments: boolean;
    fetchingTransactions: boolean;
  }>({
    fetchingDocuments: false,
    fetchingTransactions: false,
    editorState: EditorState.createWithContent(
      ContentState.createFromText(selectedDocument.long_description || '')
    ),
    tradingAccountTransactions: []
  })
  const {
    staffList,
    currentStaffRecord
  } = useSelector(state => ({
    staffList: state.staffs.entities,
    currentStaffRecord: state.appState.currentStaffRecord as Staff
  }))

  const getTransactions = async (cID: number) => {
    try {
      setState(prevState => ({
        ...prevState,
        fetchingDocuments: true
      }))

      const response = await API.get('trading-transactions', `/client/${cID}`)

      setState(prevState => ({
        ...prevState,
        fetchingTransactions: false,
        tradingAccountTransactions: response.payload
      }))
    } catch {
      setState(prevState => ({
        ...prevState,
        fetchingDocuments: false
      }))
    }
  }

  const getDocuments = async (cID: number) => {
    try {
      setState(prevState => ({
        ...prevState,
        fetchingDocuments: true
      }))

      const newDocuments = await API.get('documents', `/list/client/${cID}`)

      updateDocuments(newDocuments)

      setState(prevState => ({
        ...prevState,
        fetchingDocuments: false
      }))
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        fetchingDocuments: false
      }))
    }
  }

  useEffect(() => {
    getDocuments(clientID)
    getTransactions(clientID)
  }, [clientID])

  return (
    <Modal
      title='Create New Task'
      width={800}
      open={open}
      footer={null}
      destroyOnClose
      onOk={() => toggleModal(!open)}
      onCancel={() => toggleModal(!open)}
    >
      <Form
        form={form}
        layout='vertical'
        preserve={false}
        initialValues={{
          priority: 2,
          clientId: clientID,
          document: selectedDocument.id,
          staffId: currentStaffRecord.id,
          dueDate: moment(moment(), 'YYYY-MM-DD').add(7, 'days')
        }}
        onValuesChange={changed => {
          const [name, value] = Object.entries<number>(changed)[0]

          if (name === 'clientId') {
            getDocuments(value)
            getTransactions(value)

            form.setFieldsValue({
              transaction: null,
              document: null
            })
          }
        }}
        onFinish={async values => {
          try {
            await API.post('tasksClient', '/create', {
              body: {
                ...values,
                notes: draftToHtml(
                  convertToRaw(state.editorState.getCurrentContent())
                )
              }
            })

            toggleModal(false)

            showNotification('success', 'Success', 'Successfully Created')
          } catch (error: any) {
            showNotification('error', 'Error', error.message)
          }
        }}
      >
        <InputSelect
          required
          label='common.staff'
          name='staffId'
          options={staffList}
          getOptionProps={(option) => ({
            children: `${option.first_name} ${option.last_name}`,
            value: option.id
          })}
        />
        <Form.Item name='shortDescription' label='Short Description'>
          <Input />
        </Form.Item>
        <Row gutter={8}>
          <Col xs={12}>
            <InputSelect
              name='priority'
              label='common.priority'
              getOptionProps={({value, children}) => ({
                value,
                children
              })}
              options={[
                {
                  value: 1,
                  children: 'High'
                },
                {
                  value: 2,
                  children: 'Medium'
                },
                {
                  value: 3,
                  children: 'Low'
                },
              ]}
            />
          </Col>
          <Col xs={12}>
            <InputDatePicker name='dueDate' label='date.due' />
          </Col>
        </Row>

        <ClientsSelect
          name='clientId'
        />

        <Row gutter={8}>
          <Col xs={12}>
            <InputSelect
              allowClear
              label='common.document'
              name='document'
              loading={state.fetchingDocuments}
              getOptionProps={(option) => ({
                children: `${option.description} [${option.id}]`,
                value: option.id
              })}
              options={documents}
            />
          </Col>
          <Col xs={12}>
            <Form.Item dependencies={['transaction']} noStyle>
              {({getFieldValue}) => {
                const transaction = getFieldValue('transaction')

                if (transaction) {
                  return (
                    <NavLink to={routes.tradingTransactionsDeposit(transaction)}>
                      Click here to see transaction record
                    </NavLink>
                  )
                }
              }}
            </Form.Item>
            <InputSelect
              allowClear
              label='common.transactions'
              name='transaction'
              loading={state.fetchingTransactions}
              options={state.tradingAccountTransactions}
              getOptionProps={(opt) => ({
                children: `${opt.id},  ${opt.record_created.split('T')[0]}, [${opt.debit}]`,
                value: opt.id
              })}
            />
          </Col>
        </Row>
        
        <Typography.Text>
          long Description
        </Typography.Text>
        <Editor
          editorState={state.editorState}
          onEditorStateChange={editorState => {
            setState(prevState => ({
              ...prevState,
              editorState
            }))
          }}
          editorStyle={{
            border: '1px solid #F1F1F1',
            borderRadius: '2px',
            paddingLeft: '10px',
            paddingRight: '10px',
            height: '250px'
          }}
        />
        <Row justify='end'>
          <Space>
            <Button htmlType='submit' type='primary'>
              <FormattedMessage id='components.button.save' />
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default ClientNewTaskModal
