import {
  List,
  Record
} from 'immutable'

import {
  INPUT_CHANGE,
  CLEAR_STATE
} from '../../constants'

import {combineReducers} from 'redux'

import * as types from './types'

const AgentRecord = Record({
  id: null,
  deleted: null,
  accountID: null,
  fileBy: null,
  email: null,
  firstName: null,
  lastName: null,
  createdDatetime: null,
  lastModifiedDatetime: null
})

const ReducerRecord = Record({
  entities: List([]),
  loading: false,
  clientRecord: new AgentRecord()
})

const entities = (state = new ReducerRecord(), {type, payload}) => {

  switch (type) {
    case types.FETCH_AGENTS_REQUEST:
    case types.GET_AGENT_REQUEST:
    case types.SAVE_AGENT_REQUEST:
    case types.FETCH_AGENTS_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.length > 0 ? payload : []
        })
    case types.GET_AGENT_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload.length > 0 ? payload : []
        })
    case types.GET_AGENT_ERROR:
    case types.SAVE_AGENT_SUCCESS:
    case types.SAVE_AGENT_ERROR:
    case types.FETCH_AGENTS_ERROR:
    case INPUT_CHANGE:
      return state.setIn(['clientRecord', payload.inputName], payload.inputValue)
    case CLEAR_STATE:
      return state.update('clientRecord', record => record.clear())
    default:
      return state
  }
}

export default combineReducers({entities})
