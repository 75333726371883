import * as React from 'react'
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation'
import {Viewer} from '@react-pdf-viewer/core'
import {Button} from 'antd'

const PdfViewer: React.FC<{url: string;}> = ({url}) => {
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const {GoToNextPage, GoToPreviousPage} = pageNavigationPluginInstance

  return (
    <>
      <div
        style={{
          height: 300,
          overflow: 'hidden',
        }}
      >
        <Viewer
          fileUrl={url}
          plugins={[pageNavigationPluginInstance]}
        />
      </div>
      <div>
        <GoToPreviousPage>
          {props => (
            <Button
              onClick={props.onClick}
              disabled={props.isDisabled}
            >
								Previous
            </Button>
          )}
        </GoToPreviousPage>
        <GoToNextPage>
          {props => (
            <Button
              onClick={props.onClick}
              disabled={props.isDisabled}
            >
								Next
            </Button>
          )}
        </GoToNextPage>
      </div>
    </>
  )
}

export default PdfViewer
