import {Record} from 'immutable'

import * as types from './types'

const ReducerRecord = Record({
  loading: false,
  currencyList: [],
  journals: [],
  tradingPlatformsList: [],
  financialAccountTypes: []
})

export default (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.SAVE_ACCOUNT_REQUEST:
    case types.GET_CURRENCY_LIST_REQUEST:
    case types.GET_TRADING_PLATFORMS_LIST_REQUEST:
    case types.GET_JOURNALS_LIST_REQUEST:
      return state.set('loading', true)

    case types.SAVE_ACCOUNT_ERROR:
    case types.SAVE_ACCOUNT_SUCCESS:
    case types.GET_CURRENCY_LIST_ERROR:
    case types.GET_JOURNALS_LIST_ERROR:
    case types.GET_TRADING_PLATFORMS_LIST_ERROR:
      return state.set('loading', false)

    case types.GET_CURRENCY_LIST_SUCCESS:
      return state.merge({
        loading: false,
        currencyList: payload.map((item, i) => ({
          key: item.id,
          id: item.id,
          fullName: item.full_name,
          IsoAlpha3: item.iso_alpha_3
        }))
      })
    case types.GET_TRADING_PLATFORMS_LIST_SUCCESS:
      return state.merge({
        loading: false,
        tradingPlatformsList: payload
      })
    case types.GET_FINANCIAL_ACCOUNT_TYPES_SUCCESS:
      return state.merge({
        loading: false,
        financialAccountTypes: payload
      })
    case types.GET_JOURNALS_LIST_SUCCESS:
      return state.merge({
        loading: false,
        journals: payload
      })

    default:
      return state
  }
}