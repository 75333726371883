import React, {Component} from 'react'
import { API } from 'aws-amplify'

import {
  Row,
  Col,
  notification,
  Form,
  Input,
  Skeleton,
  Spin
} from 'antd'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class TradingTransactionsClient extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      loading: true
    }

  }

  async componentDidMount() {
    try {
      const TradingTransaction = await this.getTradingTransactionsRecord(this.props.id)
      this.setState({
        ...TradingTransaction.payload,
        loading: true
      })
    } catch (e) {
      console.log(e)
    }
    this.setState({loading: false})
  }

  async getTradingTransactionsRecord(tradingTransactionID) {
    return API.get('trading-transactions-2', `/id/${tradingTransactionID}`)
  }

  async saveTradingTransactionsRecord(tradingTransactionRecord) {
    await API.patch('trading-transactions-2', `/id/${this.state.id}`, {body: tradingTransactionRecord})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  // handleChange = value => {
  //   this.setState({'amount': value})
  // };

  // onHandleSubmit = async event => {
  //   event.preventDefault()
  //   this.setState({is_saving: true})
  //   openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

  //   const {clientID} = this.state
  //   try {
  //     await this.saveTradingTransactionsRecord({clientID})
  //     this.setState({is_saving: false})
  //   } catch (e) {
  //     console.log(e)
  //   }
  // };

  render() {
    return (
      <React.Fragment>
        <Skeleton active loading={this.state.loading}>
          <Spin spinning={this.state.loading}>
            <Form layout='vertical' onSubmit={this.onHandleSubmit}>
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Form.Item label='Client ID'>
                    <Input
                      id='client_id'
                      value={this.state.client_id || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label='Client'>
                    <Input
                      id='client'
                      value={this.state.client || ''}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Skeleton>
      </React.Fragment>
    )
  }
}

export default TradingTransactionsClient
