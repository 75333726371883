import React, {
  useEffect,
  useState
} from 'react'

import {
  Button,
  Card,
  Skeleton,
  Table,
  NavLink,
  CreateInformationRequestModal
} from 'components'

import {routes} from 'configs'

import moment from 'moment'
import {API} from 'utils'
import {listHOC} from 'hocs'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} leadsList
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const ClientInformationRequest = ({
  getColumnSearchProps, entities: informationRequests, loading, updateState,
}) => {
  const [modalOpen, toggleModalOpen] = useState(false)
  const [statusFilterOptions, setStatusFilterOptions] = useState([])

  useEffect(() => {
    API.get('information-requests', '/get-ir-status-list').then(
      response => {
        const filterArray = response.payload.map((obj) => {
          return {
            key: obj.file_by,
            id: obj.id,
            value: obj.fileBy,
            text: obj.fileBy
          }
        })
        setStatusFilterOptions(filterArray)
      }
    )
  }, [])

  const columns = [
    getColumnSearchProps({
      placeholder: 'ID',
      title: 'Xeonda ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <NavLink to={routes.clientsInformationRequestView(record.clientId, record.id)}>
          {text}
        </NavLink>
      )
    }),
    {
      title: 'EAF ID',
      dataIndex: 'eafId',
      ...getColumnSearchProps({
        dataIndex: 'eafId',
        placeholder: 'EAF ID'
      })
    },
    {
      title: 'Created',
      dataIndex: 'recordCreated',
      ...getColumnSearchProps({
        dataIndex: 'recordCreated',
        placeholder: 'recordCreated'
      }),
      render: (text, record) => (
        record?.recordCreated
          ? moment(record.recordCreated).format('YYYY/MM/DD HH:mm:ss')
          : ''
      )
    },
    {
      title: 'Client',
      dataIndex: 'clientId',
      ...getColumnSearchProps({
        dataIndex: 'clientId',
        placeholder: 'clientId',
        render: (highlighter, record) => (
          <a
            target='_blank'
            rel='noreferrer'
            href={`/clients/view/${record.clientId}?tab=summary`}
          >
            {record.clients_fileBy}
          </a>
        )
      })
    },
    {
      title: 'Recipient',
      dataIndex: 'recipientFirstName',
      ...getColumnSearchProps({
        dataIndex: 'recipientFirstName',
        placeholder: 'Recipient',
        render: (highlighter, record) => (
          record?.recipientFirstName ? `${record.recipientFirstName}, ${record.recipientLastName}` : ''
        )
      })
    },
    {
      title: 'Status',
      dataIndex: 'statusName',
      filters: statusFilterOptions,
      onFilter: (value, record) => record.statusName === value,
    }
  ]

  return (
    <React.Fragment>
      <Card
        style={{minHeight: 360}}
        title='Information Requests'
        extra={<Button onClick={() => toggleModalOpen(true)}> New </Button>}
      >
        <div style={{paddingTop: 24}}>
          <Skeleton active loading={loading}>
            <Table
              rowKey='id'
              columns={columns}
              dataSource={informationRequests}
              loading={loading}
              scroll={{x: true}}
              pagination={{
                total: informationRequests.length,
                showTotal: total => `Total ${total} records`,
                pageSizeOptions: ['20', '50', '100'],
                defaultPageSize: 20,
                showSizeChanger: true
              }}
            />
          </Skeleton>
        </div>
      </Card>
      <CreateInformationRequestModal
        open={modalOpen}
        onCancel={() => toggleModalOpen(false)}
        onSuccess={(record) => {
          updateState({entities: informationRequests.concat(record)})
          toggleModalOpen(false)
        }}
      />
    </React.Fragment>
  )
}

export default listHOC(ClientInformationRequest, ({match}) =>
  API.get('information-requests', `/list-by-client-id/${match.params.client_id}`)
    .then(r => r.payload)
)
