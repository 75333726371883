import React from 'react'

import {
  showNotification,
  API
} from 'utils'
import CreateJournal from 'common/CreateJournal'

interface ClientJournalCreateNew {
  staffId: number;
  clientId: number;
  documentId?: number;
  asyncFunction?: () => void;
  isModalVisible: boolean;
  toggleModal: (open: boolean) => void;
}

const ClientJournalCreateNew: React.FC<ClientJournalCreateNew> = ({
  isModalVisible,
  toggleModal,
  staffId,
  clientId,
  asyncFunction,
  documentId ,
}) => {
  const onFinish = async (values: any) => {
    try {
      await API.post('journals', '/create', {
        body: {
          ...values,
          staffId
        }
      })
      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error: any) {
      showNotification('error', 'Journals', error.message)
    }

    toggleModal(false)

    if (asyncFunction) {
      asyncFunction()
    }
  }

  return (
    <CreateJournal
      clientId={clientId}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
      onFinish={onFinish}
      documentId={documentId}
    />
  )
}

export default ClientJournalCreateNew
