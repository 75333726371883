import React from 'react'
import {FormattedMessage} from 'react-intl'

import {
  Form,
  Radio,
} from 'antd'

const radioBtnOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]
const ClientKnowledge = ({
  classes,
  clientRecord,
}) => {

  const buttons = [
    {
      name: 'knowledge_traded_leveraged',
      label: 'clients.knowledge.knowledge_traded_leveraged',
      defaultMessage: 'Have you traded CFDs, FX or other leveraged products before?'
    },
    {
      name: 'knowledge_traded_options',
      label: 'clients.knowledge.knowledge_traded_options',
      defaultMessage: 'Have you traded Options before?'
    },
    {
      name: 'knowledge_qualification',
      label: 'clients.knowledge.knowledge_qualification',
      defaultMessage:
        'Do you have diploma or higher qualification in Commerce, Economics, Accounting, Finance or similar?'
    }
  ]

  let currentStatusText
  switch (clientRecord.statusKnowledge) {
    case 1: currentStatusText = 'COMPLETED'; break
    case 2: currentStatusText = 'COMPLETED'; break
    case 3: currentStatusText = 'COMPLETED'; break
  }

  return (
    <React.Fragment>
      {buttons.map(({
        name, label, defaultMessage
      }) => (
        <Form.Item
          key={name}
          name={name}
          className={classes.form_label}
          label={<FormattedMessage id={label} defaultMessage={defaultMessage} />}
        >
          <Radio.Group options={radioBtnOptions} optionType='button' buttonStyle='solid' />
        </Form.Item>
      ))}
    </React.Fragment>
  )
}

export default ClientKnowledge