import React, {
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Divider,
  Form,
  Tabs
} from 'antd'
import {
  useDispatch,
  useSelector
} from 'react-redux'

import {
  tradingActions,
  tradingSelectors
} from 'configs/ducks/trading'

import ClientDetails from './sub-components/clientDetails'
import AttachedDocument from './sub-components/AttachedDocument'
import TransactionDetails from './sub-components/TransactionDetail'
import RequireInfoFromClient from './sub-components/RequireInfoFromClient'
import WithdrawalDetails from './sub-components/WithdrawalDetails'
import {
  useHistory,
  useLocation
} from 'react-router'
import queryString from 'query-string'

const routes = [
  {
    path: '/trading/transactions',
    breadcrumbName: 'Transacctions',
  },
  {
    path: 'first',
    breadcrumbName: 'View Details',
  }
]

function TradingTransactionDetailsWithdrawal({ getTopics,match: {params}}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeTab, setTab] = useState('')
  const [form] = Form.useForm()
  const location = useLocation()
  const {tradingTransactionRecord, loading} = useSelector(tradingSelectors.tradingTransactionSelector)

  useEffect(() => {
    if (params.id) {
      dispatch(tradingActions.getTradingTransaction(params.id, 'withdrawal'))
    }
  }, [params.id, dispatch])

  useEffect(() => {
    getTopics({
      title: 'Trading Transactions',
      breadCrumb: routes,
    })
  }, [])

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search)
    setTab(activeTab)
  }, [location.search])

  const onTabClick = e => {
    history.push(`/trading/transactions/${params.id}/withdrawal?tab=${e}`)
  }

  return (
    <>
      <Tabs type='card' activeKey={activeTab} onTabClick={onTabClick}>
        <Tabs.TabPane tab='Client' key='dclient'>
          <ClientDetails />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Transactions' key='dtransaction'>
          <TransactionDetails name="credit" />
          <Divider />
          <WithdrawalDetails />
          <Divider />
          <RequireInfoFromClient />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Documents' key='ddocuments'>
          <AttachedDocument
            loading={loading}
            document={tradingTransactionRecord.document}
            file={tradingTransactionRecord.fileList?.[0]}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default TradingTransactionDetailsWithdrawal
