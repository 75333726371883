import {moduleName} from './types'

import {createSelector} from 'reselect'

/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const stateSelector = state => state[moduleName].toJS()
/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const informationRequestsEntitiesSelectors = createSelector(stateSelector, ({loading, entities}) => ({
  entities,
  loading
}))