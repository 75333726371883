import {useAsync} from 'react-use'
import { API } from 'aws-amplify'
import { useParams } from 'react-router-dom'

export default function() {
  const { id } = useParams()
  const state = useAsync(async () => {
    if (id) {
      const { payload } = await API.get('account-financial-accounts', `/account/${id}`)
      
      return payload
    }
    return null
  }, [id])

  return state
}