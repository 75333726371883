import {List, Record} from 'immutable'

import {INPUT_CHANGE, CLEAR_STATE} from '../../constants'

import {combineReducers} from 'redux'

import {FileListItemRecord, DocumentRecord} from '../common'

import * as types from './types'
import moment from 'moment'

const ClientRecord = Record({
  id: null,
  account_user_id: null,
  account_id: null,
  date_of_birth: null,
  gender_id: null,
  place_of_birth_city: null,
  place_of_birth_country_id: null,
  place_of_birth_country: null,
  file_by: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  email: '',
  tel_home: '',
  tel_work: '',
  tel_mobile: '',
  email_secondary: '',
  occupation: '',
  status_id: null,
  street_address_city: null,
  street_address_region: null,
  street_address_line_1: null,
  street_address_line_2: null,
  street_address_suburb: null,
  street_address_state: null,
  street_address_postcode: null,
  street_address_country: null,
  postal_address_city: null,
  postal_address_region: null,
  client_type: null,
  categoryId: null,
  postal_address_line_1: null,
  postal_address_line_2: null,
  postal_address_suburb: null,
  postal_address_state: null,
  postal_address_postcode: null,
  postal_address_country: null,
  employment_status_id: null,
  employer: null,
  annual_income: null,
  net_worth: null,
  digiDocUuid: null,
  digiDocUrl: null,
  intended_deposit: null,
  tax_country_id: null,
  tfn_exemption: null,
  tax_file_number: null,
  us_citizen: null,
  us_tax_resident: null,
  knowledge_traded_leveraged: null,
  knowledge_traded_options: null,
  knowledge_qualification: null,
  streetUnitNumber: null,
  streetStreetNumber: null,
  streetStreetName: null,
  streetStreetTypeId: null,
  streetSuburb: null,
  streetState: null,
  streetPostcode: null,
  streetCountryId: null,
  postalBoxNumber: null,
  postalBoxTypeId: null,
  postalStreetNumber: null,
  postalStreetName: null,
  postalStreetTypeId: null,
  postalSuburb: null,
  postalState: null,
  postalPostcode: null,
  postalCountryId: null,
  statusContact: null,
  statusAddress: null,
  statusFinancial: null,
  statusKnowledge: null,
  statusVerification: null,
  statusDocuments: null,
  statusPersonal: null,
  statusAgreements: null,
  createdDateTime: null,
  lastModifiedDatetime: null,
  responsibleStaffMember: null,
  companyType: null,
  companyNumber: null,
  companyName: '',
  agreed_w8ben: null,
  agreed_w8benNotes: null,
  agreed_fsg: null,
  agreed_fsgNotes: null,
  agreed_pds: null,
  agreed_pdsNotes: null,
  quickNotes: '',
  contact_nickname: '',
  statusContactModifiedDatetime: null,
  documents_nickname: '',
  statusDocumentsModifiedDatetime: null,
  personal_nickname: '',
  statusPersonalModifiedDatetime: null,
  financial_nickname: '',
  statusFinancialModifiedDatetime: null,
  knowledge_nickname: '',
  statusKnowledgeModifiedDatetime: null,
  verification_nickname: '',
  statusVerificationModifiedDatetime: null,
  agreements_nickname: '',
  statusAgreementsModifiedDatetime: null,
  employment_industry: null
})

const ReducerRecord = Record({
  entities: List([]),
  loading: false,
  clientRecord: new ClientRecord(),
  verificationList: [],
  financialAccount: [],
  DigiDoc: {},
  DigiDocEmail: {},
  journals: []
})

const entities = (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.FETCH_CLIENTS_REQUEST:
    case types.GET_CLIENT_REQUEST:
    case types.SAVE_CLIENT_REQUEST:
    case types.GET_VERIFICATIONS_LIST_REQUEST:
    case types.DIGIDOC_CREATE_REQUEST:
    case types.DIGIDOC_SEND_REQUEST:
    case types.DELETE_OPPORTUNITIES_REQUEST:
    case types.GET_JOURNAL_BY_CLIENT_REQUEST:
      return state.set('loading', true)
    case types.FETCH_CLIENTS_SUCCESS:
      return state.mergeWith((oldVal, newVal, key) => (key === 'entities' ? List(newVal) : newVal), {
        loading: false,
        entities: payload.map(client => new ClientRecord(client))
      })
    case types.FETCH_CLIENTS_ERROR:
    case types.GET_CLIENT_ERROR:
    case types.SAVE_CLIENT_ERROR:
    case types.SAVE_CLIENT_SUCCESS:
    case types.DELETE_OPPORTUNITIES_ERROR:
    case types.GET_VERIFICATIONS_LIST_ERROR:
    case types.DIGIDOC_CREATE_ERROR:
    case types.DIGIDOC_SEND_ERROR:
    case types.GET_JOURNAL_BY_CLIENT_ERROR:
      return state.set('loading', false)

    case types.GET_VERIFICATIONS_LIST_SUCCESS:
      return state.merge({
        loading: false,
        verificationList: payload.map(({request_datetime, ...verification}) => ({
          ...verification,
          request_datetime: moment(request_datetime).format('DD-MM-YYYY HH:mm')
        }))
      })
    case types.DELETE_OPPORTUNITIES_SUCCESS:
      return state.merge({
        loading: false,
        entities: state.entities.filter(client => client.id !== payload)
      })
    case types.FINANCIAL_ACCOUNT_SEND_SUCCESS:
      return state.merge({
        financialAccount: payload,
        loading: false
      })
    case types.GET_JOURNAL_BY_CLIENT_SUCCESS:
      return state.merge({
        loading: false,
        journals: payload
      })

    case types.GET_CLIENT_SUCCESS:
      let clientRecord = new ClientRecord(payload)
      // For mapping between form values and radio button names /ClientFinancial
      clientRecord = clientRecord.merge({
        us_tax_resident: Boolean(clientRecord.us_tax_resident),
        tfn_exemption: Boolean(clientRecord.tfn_exemption),
        us_citizen: Boolean(clientRecord.us_citizen),
        knowledge_traded_leveraged: Boolean(clientRecord.knowledge_traded_leveraged),
        knowledge_traded_options: Boolean(clientRecord.knowledge_traded_options),
        knowledge_qualification: Boolean(clientRecord.knowledge_qualification)
      })
      return state.merge({
        clientRecord: clientRecord,
        loading: false
      })
    case types.DIGIDOC_CREATE_SUCCESS:
      return state.merge({
        loading: false,
        DigiDoc: payload
      })
    case types.DIGIDOC_SEND_SUCCESS:
      return state.merge({
        loading: false,
        DigiDocEmail: payload
      })
    case INPUT_CHANGE:
      return state.setIn(['clientRecord', payload.inputName], payload.inputValue)
    case CLEAR_STATE:
      return state.update('clientRecord', record => record.clear())
    default:
      return state
  }
}

const DocumentsReducerRecord = Record({
  documentsList: List([]),
  fileList: List([]),
  currentDocument: new DocumentRecord({}),
  modalLoading: false,
  loading: false
})

const documents = (state = new DocumentsReducerRecord(), {type, payload}) => {
  switch (type) {
    case types.GET_DOCUMENT_REQUEST:
    case types.GET_DOCUMENTS_REQUEST:
      return state.set('loading', true)

    case types.GET_DOCUMENTS_SUCCESS:
      return state.mergeWith((old, newVal, key) => (key === 'documentsList' ? List(newVal) : newVal), {
        loading: false,
        documentsList: payload.map(item => new DocumentRecord(item))
      })
    case types.GET_DOCUMENT_SUCCESS:
      return state.merge({
        fileList: payload.fileList.map(item => new FileListItemRecord(item)),
        loading: false,
        currentDocument: new DocumentRecord(payload.document)
      })
    case types.GET_DOCUMENT_ERROR:
      return state.set('loading', false)

    case types.CREATE_NEW_DOCUMENT_REQUEST:
      return state.set('modalLoading', true)

    case types.CREATE_NEW_DOCUMENT_SUCCESS:
    case types.CREATE_NEW_DOCUMENT_ERROR:
      return state.set('modalLoading', false)

    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}

const NewClientDefaultState = Record({
  client: new ClientRecord(),
  loading: false,
  duplicateEmail: false
})

const newClient = (state = new NewClientDefaultState(), {type, payload}) => {
  switch (type) {
    case types.CREATE_CLIENT_REQUEST:
      return state.set('loading', true)

    case types.CHECK_CLIENTS_EMAIL_REQUEST:
      return state.merge({
        loading: true,
        duplicateEmail: false
      })
    case types.CHECK_CLIENTS_EMAIL_SUCCESS:
      return state.set('loading', false)
    case types.CHECK_CLIENTS_EMAIL_ERROR:
      return state.merge({
        loading: false,
        duplicateEmail: true
      })
    case types.CREATE_CLIENT_SUCCESS:
    case types.CREATE_CLIENT_ERROR:
      return state.set('loading', false)
    case INPUT_CHANGE:
      let updatedState = state.setIn(['client', payload.inputName], payload.inputValue)
      if (payload.inputName === 'email') {
        updatedState = updatedState.set('duplicateEmail', false)
      }
      return updatedState
    case CLEAR_STATE:
      return state.clear()
    default:
      return state
  }
}

export default combineReducers({
  entities,
  documents,
  newClient
})
