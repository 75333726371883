import React from 'react'

import {
  Descriptions,
  Skeleton
} from 'antd'

import {connect} from 'react-redux'

import {appStateSelectors} from 'configs/ducks/appState'
import {
  settingsActions,
  settingsSelectors
} from 'configs/ducks/settings'

import {listHOC} from 'common'

const TradingPlatformsViewPageComponent = ({currentRecord, loading}) => {
  if (loading) {
    return <Skeleton active loading={loading} />
  }
  const {
    id,
    trading_platform_mode_file_by,
    trading_platform_type_file_by,
    tradingPlatformUserGroupId,
    fileBy
  } = currentRecord
  return <Descriptions
    bordered
    layout='vertical'
    size='small'
    column={{
      xs: 1,
      sm: 2,
      md: 3
    }}
  >
    <Descriptions.Item label='ID'>{id}</Descriptions.Item>
    <Descriptions.Item label='Mode'>{trading_platform_mode_file_by}</Descriptions.Item>
    <Descriptions.Item label='Type'>{trading_platform_type_file_by}</Descriptions.Item>
    <Descriptions.Item label='Group'>{tradingPlatformUserGroupId}</Descriptions.Item>
    <Descriptions.Item label='File By'>{fileBy}</Descriptions.Item>
  </Descriptions>
}

const mapStateToProps = (state, {match: {params: {id}}}) => {
  id = Number(id)
  const {
    loading,
    tradingPlatformsList
  } = settingsSelectors
    .stateSelector(state)
  const {currentAccountRecord} = appStateSelectors
    .stateSelector(state)
  return {
    currentRecord: tradingPlatformsList.find(item => item.id === id) || {},
    currentAccountID: currentAccountRecord.id,
    tradingPlatformsList,
    loading
  }
}

const mapDispatchToProps = {fetchCb: settingsActions.getTradingPlatformsList}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(listHOC(TradingPlatformsViewPageComponent))