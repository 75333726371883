import React, {useEffect} from 'react'

import {
  Button,
  DatePicker,
  Table
} from 'antd'

import {listHOC} from 'common'

import {connect} from 'react-redux'
import {
  tradingActions,
  tradingSelectors
} from 'configs/ducks/trading'
import {CSVLink} from 'react-csv'
import {FileExcelOutlined} from '@ant-design/icons'
import moment from 'moment'

function TradingCommissionsSum(
  {
    getCommissionsSum,
    getColumnSearchProps,
    getTopics,
    loading,
    list
  }
) {
  useEffect(() => {
    const onChange = (momentDate) => {
      if (momentDate.length > 1) {
        const startDate = momentDate[0].startOf('day').add(momentDate[0].utcOffset(),'minutes')
        const endDate = momentDate[1].endOf('day').add(momentDate[1].utcOffset(),'minutes')
        startDate.utc()
        endDate.utc()
        getCommissionsSum(startDate.valueOf(), endDate.valueOf())
      }
    }
    getTopics({extra: <><DatePicker.RangePicker onChange={onChange} allowClear={false} /><p><em>Note: Date range is from 00:00 to 23:59 (UTC Time)</em></p></>})
  }, [])
  const columns = [
    {
      title: 'Account ID',
      dataIndex: 'accountID',
      sorter: (a, b) => a.accountID - b.accountID,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'accountID',
        placeholder: 'Account ID',
      })
    },
    {
      title: 'Count',
      dataIndex: 'count',
      sorter: (a, b) => a.count - b.count,
      ...getColumnSearchProps({
        dataIndex: 'count',
        placeholder: 'Count',
      })
    },
    {
      title: 'Total',
      dataIndex: 'sum',
      sorter: (a, b) => a.total - b.total,
      ...getColumnSearchProps({
        dataIndex: 'sum',
        placeholder: 'Total',
      })
    },
  ]
  return <Table
    rowKey='accountID'
    columns={columns}
    dataSource={list}
    loading={loading}
    scroll={{x: true}}
    pagination={{
      total: list.length,
      showTotal: total => `Total ${total} records`,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      showSizeChanger: true
    }}
    footer={() => (
      <CSVLink
        filename={`trading_commission_summary${moment().format('YYYYMMDD')}.csv`}
        data={list}
        className="btn btn-primary"
      >
        <Button disabled={list.length === 0 || loading}>
          Download <FileExcelOutlined />
        </Button>
      </CSVLink>
    )}
  />
}

const mapStateToProps = state => {
  const {
    loading,
    list
  } = tradingSelectors.commissionsSumSelector(state)
  return {
    loading,
    list
  }
}
const mapDispatchToProps = {getCommissionsSum: tradingActions.getCommissionsSum}
export default connect(mapStateToProps, mapDispatchToProps)(listHOC(TradingCommissionsSum))