import React from 'react'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone
} from 'components'

export const STATUS_INDICATOR_ICONS = {
  1: <CheckCircleTwoTone twoToneColor='#52c41a' />,
  2: <ExclamationCircleTwoTone twoToneColor='#ffa90a' />,
  3: <CloseCircleTwoTone twoToneColor='#eb2f96' />,
  getDefault() {
    return this['2']
  },
  get(id?: 1 | 2 | 3) {
    const defaultIcon = this.getDefault()

    if (!id) {
      return defaultIcon
    }

    return this[id] || defaultIcon
  }
}