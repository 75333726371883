import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {
  NavLink,
  useHistory
} from 'react-router-dom'
import {
  Table,
  Descriptions,
  Button
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import {listHOC,} from 'common'
import {
  accountsActions,
  accountsSelectors
} from 'configs/ducks/backoffice-accounts'
import {appStateSelectors} from 'configs/ducks/appState'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
function AccountListPageComponent({
  getColumnSearchProps,
  dataSource,
  loading,
  getTopics,
  fetchCb,
  currentAccountID,
  backoffice
}) {
  const history = useHistory()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: id => <NavLink to={`/backoffice/accounts/view/${id}`}>{id}</NavLink>
    },
    {
      title: 'Email',
      dataIndex: 'account_email',
      ...getColumnSearchProps({
        dataIndex: 'account_email',
        placeholder: 'Account Email',
      })
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      ...getColumnSearchProps({
        dataIndex: 'account_name',
        placeholder: 'Account Name',
      })
    },
    {
      title: 'Slug',
      dataIndex: 'account_slug',
      ...getColumnSearchProps({
        dataIndex: 'account_slug',
        placeholder: 'Slug',
      })
    }
  ]
  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
          <Button onClick={() => history.push('/backoffice/accounts/new')}> Create New </Button>
          
        </>
      )
    })
  }, [])

  if (backoffice !== 1) {
    return <div> You don't have access to this page, please contact xxx for help.</div>
  }

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey='id'
      scroll={{x: true}}
      expandedRowRender={({
        file_by,
        fromEmail,
        page_header_logo
      }) =>
        <Descriptions
          bordered
          layout='vertical'
          size='small'
          column={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3
          }}
        >
          <Descriptions.Item label='File By'>{file_by}</Descriptions.Item>
          <Descriptions.Item label='From Email'>{fromEmail}</Descriptions.Item>
          <Descriptions.Item label='Page Header Logo'>{page_header_logo}</Descriptions.Item>
        </Descriptions>
      }
    />
  )
}
const mapStateToProps = state => {
  const {currentUserRecord} = appStateSelectors.stateSelector(state)
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  const {
    loading,
    entities
  } = accountsSelectors.stateSelector(state)
  return {
    currentAccountID: currentAccountRecord.id,
    backoffice: currentUserRecord.backoffice,
    dataSource: entities,
    loading
  }
}

export default connect(
  mapStateToProps,
  {fetchCb: accountsActions.getAccountList}
)(listHOC(AccountListPageComponent))
