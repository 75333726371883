import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Typography,
  Table,
  Button,
  Descriptions,
  Skeleton,
  Divider,
  Card
} from 'antd'
import {useSelector} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'
import {Line} from '@ant-design/plots'

import {API} from 'aws-amplify'
import {showNotification} from 'utils'

import moment from 'moment'

interface IState {
  data: any;
  headers: string[];
  name: string;
  loadingData: boolean;
}

export const AccountMetrics: React.FC = () => {
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    loadingData: true
  })
  const [sentEmailButton, setSentEmailButton] = useState(false)
  const {id} = useSelector((state: any) => appStateSelectors.stateSelector(state).currentAccountRecord)

  const fetch = async (): Promise<any> => {
    setState({
      ...state,
      loadingData: true,
    })

    const response = await API.get('reportsMetrics', `/clients/current/${id}`, {})
    const data = response.payload

    setState({
      ...state,
      data,
      loadingData: false,
    })
  }

  const onSendEmailButtonClicked = async () => {
    setSentEmailButton(true)
    await API.get('reportsMetrics', `/email/daily/${id}`, {})
      .then(response => {
        showNotification('success', 'Email', 'Email send to client - Send Successful')
      })
      .catch(error => {
        console.log(error)
      })
    setSentEmailButton(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  const leadsChartConfig = {
    data: state.data.leadMetrics,
    height: 300,
    xField: 'recordDatetime',
    yField: 'leadsTotal',
    seriesField: 'category',
    animation: {
      appear: {
        animation: 'path-in',
        duration: 2500,
      },
    },
    xAxis: {label: {formatter: (v: any) => `${moment(v).utc().format('DD-MM-YY')}`}}
  }

  const clientsChartConfig = {
    data: state.data.clientMetrics,
    height: 300,
    xField: 'recordDatetime',
    yField: 'clientsTotal',
    seriesField: 'category',
    animation: {
      appear: {
        animation: 'path-in',
        duration: 2500,
      },
    },
    xAxis: {label: {formatter: (v: any) => `${moment(v).utc().format('DD-MM-YY')}`}}
  }
 
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={4}>
            Account Metrics
          </Typography.Title>
        </Col>
      </Row>
      
      <Row gutter={[96, 24]}>
        <Skeleton loading={state.loadingData} active>
          <Col span={12}>
            <Descriptions
              title="Lead Metrics"
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Pending Verification'>
                {state.data.leadPendingVerificationCount}
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Require Information'>
                {state.data.leadRequireInfoCount}
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Incomplete'>
                {state.data.leadIncompleteCount}
              </Descriptions.Item>
              <Descriptions.Item label='To Review'>
                {state.data.leadToReviewCount}
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='On Hold'>
                {state.data.leadOnHoldCount}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Total Leads</strong>}>
                <strong>{state.data.leadCount}</strong>
              </Descriptions.Item>
            </Descriptions>
            <Divider/>
            <Descriptions
              title="Client Metrics"
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Pending Verification'>
                {state.data.clientPendingVerificationCount}
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Require Information'>
                {state.data.clientRequireInfoCount}
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Incomplete'>
                {state.data.clientIncompleteCount}
              </Descriptions.Item>
              <Descriptions.Item label='To Review'>
                {state.data.clientToReviewCount}
              </Descriptions.Item>
              <Descriptions.Item label='On Hold'>
                {state.data.clientOnHoldCount}
              </Descriptions.Item>
              <Descriptions.Item label='Active'>
                {state.data.clientActiveCount}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Total Clients</strong>}>
                <strong>{state.data.clientCount}</strong>
              </Descriptions.Item>
            </Descriptions>
            <Divider/>
            <Descriptions
              title="Information Requests"
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              <Descriptions.Item
                contentStyle={{width: '150px'}}
                label='Leads Without Info Requests'
              >
                {state.data.leadsWithoutInfoRequests}
              </Descriptions.Item>
              <Descriptions.Item label='Leads With Outstanding Info Requests'>
                {state.data.leadsWithOutstandingInfoReq}
              </Descriptions.Item>
            </Descriptions>
            <Divider/>
          </Col>
          <Col span={12}>
            <Descriptions
              title="Historical - Leads"
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              <Descriptions.Item label='Pending Verification'>
                {state.data.metricsToday?.leadsPendingVerification} [+{Number.parseInt(state.data.metricsToday?.leadsPendingVerification) - Number.parseInt(state.data.metricsYesterday?.leadsPendingVerification)}]
              </Descriptions.Item>
              <Descriptions.Item label='Require Information'>
                {state.data.metricsToday?.leadsRequireInfo} [+{Number.parseInt(state.data.metricsToday?.leadsRequireInfo) - Number.parseInt(state.data.metricsYesterday?.leadsRequireInfo)}]
              </Descriptions.Item>
              <Descriptions.Item
                label='Incomplete'
                contentStyle={{width: '150px'}}>
                {state.data.metricsToday?.leadsIncomplete} [+{Number.parseInt(state.data.metricsToday?.leadsIncomplete) - Number.parseInt(state.data.metricsYesterday?.leadsIncomplete)}]
              </Descriptions.Item>
              <Descriptions.Item label='To Review'>
                {state.data.metricsToday?.leadsToReview} [+{Number.parseInt(state.data.metricsToday?.leadsToReview) - Number.parseInt(state.data.metricsYesterday?.leadsToReview)}]
              </Descriptions.Item>
              <Descriptions.Item label='On Hold'>
                {state.data.metricsToday?.leadsOnHold} [+{Number.parseInt(state.data.metricsToday?.leadsOnHold) - Number.parseInt(state.data.metricsYesterday?.leadsOnHold)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total'>
                {state.data.metricsToday?.leadsTotal} [+{Number.parseInt(state.data.metricsToday?.leadsTotal) - Number.parseInt(state.data.metricsYesterday?.leadsTotal)}]
              </Descriptions.Item>
            </Descriptions>
            <Divider/>
            <Descriptions
              title="Historical - Clients"
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              <Descriptions.Item label='Pending Verification'>
                {state.data.metricsToday?.clientsPendingVerification} [+{Number.parseInt(state.data.metricsToday?.clientsPendingVerification) - Number.parseInt(state.data.metricsYesterday?.clientsPendingVerification)}]
              </Descriptions.Item>
              <Descriptions.Item label='Require Information'>
                {state.data.metricsToday?.clientsRequireInfo} [+{Number.parseInt(state.data.metricsToday?.clientsRequireInfo) - Number.parseInt(state.data.metricsYesterday?.clientsRequireInfo)}]
              </Descriptions.Item>
              <Descriptions.Item
                label='Incomplete'
                contentStyle={{width: '150px'}}>
                {state.data.metricsToday?.clientsIncomplete} [+{Number.parseInt(state.data.metricsToday?.clientsIncomplete) - Number.parseInt(state.data.metricsYesterday?.clientsIncomplete)}]
              </Descriptions.Item>
              <Descriptions.Item label='To Review'>
                {state.data.metricsToday?.clientsToReview} [+{Number.parseInt(state.data.metricsToday?.clientsToReview) - Number.parseInt(state.data.metricsYesterday?.clientsToReview)}]
              </Descriptions.Item>
              <Descriptions.Item label='On Hold'>
                {state.data.metricsToday?.clientsOnHold} [+{Number.parseInt(state.data.metricsToday?.clientsOnHold) - Number.parseInt(state.data.metricsYesterday?.clientsOnHold)}]
              </Descriptions.Item>
              <Descriptions.Item label='Active'>
                {state.data.metricsToday?.clientsActive} [+{Number.parseInt(state.data.metricsToday?.clientsActive) - Number.parseInt(state.data.metricsYesterday?.clientsActive)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total'>
                {state.data.metricsToday?.clientsTotal} [+{Number.parseInt(state.data.metricsToday?.clientsTotal) - Number.parseInt(state.data.metricsYesterday?.clientsTotal)}]
              </Descriptions.Item>
            </Descriptions>
            <Divider/>
          </Col>
 
          <Col span={12}>
            <Card
              title="Leads Historical">
              <Line {...leadsChartConfig} />
            </Card>
            <Divider/>
          </Col>
          <Col span={12}>
            <Card
              title="Client Historical">
              <Line {...clientsChartConfig} />
            </Card>
            <Divider/>
          </Col>
        </Skeleton>
      </Row>
      <Button
        loading={sentEmailButton}
        onClick={onSendEmailButtonClicked}
      >
        Email
      </Button>
    </Fragment>
  )
}

export default AccountMetrics