import * as React from 'react'

import {
  Button,
  Col,
  Form,
  Row
} from 'antd'

import * as duck from '../../duck'
import {ColumnsRendererProps} from './types'

import {
  InputDatePicker,
  FileUpload,
  InputText
} from 'components'

import {DATE_FORMAT} from 'consts'

const uploadLabels = {
  file_id_back: 'Document Back',
  file_id_front: 'Document Front',
  pofadd_file_id: 'Proof of Address',
} as const

const ColumnsRenderer: React.FC<ColumnsRendererProps> = (
  {
    categoryName,
    identificationType,
    renderInputFields,
    categoryItems,
    onBtnClick
  }
) => {
  return (
    <Row gutter={duck.constants.ROW_GUTTER}>
      <Col {...duck.constants.COMMON_COL_PROPS}>
        <Form.Item shouldUpdate>
          {({getFieldValue}) => {
            const id = getFieldValue(categoryName)
            if (id) {
              const {categoryEntries} = duck.selectors.getCategory(
                categoryItems,
                id
              )

              const dynamicData = categoryEntries
                .map((v) => {
                  const [key, value] = v
                  const {
                    show,
                    label,
                    name
                  } = renderInputFields(key as keyof duck.Types.ApiNames, value, duck.constants.API_NAMES)

                  if (show) {
                    return (
                      <InputText
                        key={key}
                        label={label}
                        name={name}
                      />
                    )
                  }

                  if (key.includes('expiry') && value) {
                    return (
                      <InputDatePicker
                        required
                        isAfterToday
                        key={key}
                        label='Document Expiry'
                        name={duck.constants.API_NAMES[key as keyof duck.Types.ApiNames]}
                        dateFormat={DATE_FORMAT}
                      />
                    )
                  }
                  return null
                })
              return <>
                {identificationType === 1 && (
                  <InputText
                    required
                    preserve={false}
                    label='user_profile_verification_form.text_title_document_number'
                    name='document_number'
                    placeholder='eg: 1234 1234'
                  />
                )}
                {dynamicData}
              </>
            }
            return null
          }}
        </Form.Item>
      </Col>
      <Col {...duck.constants.COMMON_COL_PROPS}>
        <Form.Item shouldUpdate>
          {({
            getFieldValue,
            validateFields
          }) => {
            const id = getFieldValue(categoryName)
            if (id) {
              const {categoryEntries} = duck.selectors.getCategory(
                categoryItems,
                id
              )

              const arr = identificationType === 1 ? categoryEntries.reduce<React.ReactNode[]>((prev, [name, value]) => {
                const item = []
                if (name.includes('upload') && value) {
                  const fileName = duck.constants.API_NAMES[name as keyof duck.Types.ApiNames]

                  item.push(
                    <FileUpload
                      required
                      name={fileName}
                      // TODO
                      // @ts-ignore
                      label={uploadLabels[fileName]}
                      listType="picture"
                      multiple={false}
                    />
                  )
                }
                return [...prev, ...item]
              }, []) : [

                <FileUpload
                  required
                  label='Proof of Address'
                  name='pofadd_file_id'
                  key='pofadd_file_id'
                  listType="picture"
                  multiple={false}
                />
              ]
              if (arr.length) {
                return <>
                  {arr}
                  <Button
                    style={{
                      marginTop: 14,
                      whiteSpace: 'break-spaces',
                      height: 'auto'
                    }}
                    onClick={() => validateFields()
                      .then(onBtnClick)
                      .catch(e => e)}
                    type='primary'
                  >
                    Add Identification Record
                  </Button>
                </>
              }
              return null
            }
          }}
        </Form.Item>
      </Col>
    </Row>
  )
}

export default ColumnsRenderer
