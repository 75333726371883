import React, {
  useState,
  useRef,
  useEffect,
} from 'react'
import {
  Table,
  Tag,
  Popconfirm,
  NavLink,
  DeleteOutlined,
  notification
} from 'components'
import {
  useSelector,
  useQueryParams
} from 'hooks'
import {routes} from 'configs'
import {STATUS_COLOR} from 'consts'
import ReactCountryFlag from 'react-country-flag'
import {listHOC} from 'hocs'
import _values from 'lodash/values'
import {createUseStyles} from 'react-jss'
import {API} from 'utils'

const getUserFilters = ({filters = {}}) => {
  return Object.keys(filters)
    .filter(key => filters[key])
    .reduce((prev, key) => {
      prev[key] = filters[key]

      return prev
    }, {})
}

const useStyles = createUseStyles({
  tag: {
    width: '100%',
    textAlign: 'center'
  }
})

const getRiskTotalScore = (score) => {
  const total = {
    text: 'No Data',
    color: 'purple'
  }

  if (score === 0 || !score) {
    total.text = 'No Data'
    total.color = 'purple'
  } else if ((score > 0 && score <= 13) || score === '1,13') {
    total.text = 'Low'
    total.color = 'green'
  } else if ((score >= 14 && score <= 20) || score === '14,20') {
    total.text = 'Medium'
    total.color = 'blue'
  } else if ((score >= 21 && score <= 25) || score === '21,25') {
    total.text = 'High'
    total.color = 'volcano'
  } else if (score > 25) {
    total.text = 'Declined'
    total.color = 'red'
  }

  return total
}

const pageTitle = {
  1: 'Opportunity',
  2: 'Lead',
  3: 'Client'
}

const ClientListPageComponent = ({getColumnSearchProps, getTopics}) => {
  const classes = useStyles()
  const [queryParams, setQueryParams] = useQueryParams()
  const [state, setState] = useState({
    statuses: [],
    types: [],
    deletedIDs: [],
    data: [],
    loading: false,
    filters: queryParams,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const {currentAccountID, countriesList} = useSelector(state => ({
    currentAccountID: state.appState.currentAccountRecord.id,
    countriesList: state.appState.countriesList,
  }))

  const prevFilters = useRef()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {categoryId } = queryParams

        getTopics({title: `${pageTitle[categoryId]} List`})

        const queryStringParameters = getUserFilters({
          filters: {
            ...state.filters,
            ...queryParams,
            categoryId: ![1, 2, 3].includes(Number(categoryId)) ? 3 : categoryId
          }
        })

        if (_values(prevFilters.current).join() === _values(queryStringParameters).join()) {
          return
        }

        setState(prevState => ({
          ...prevState,
          loading: true
        }))

        const data = await API.post('clients', '/search', {
          queryStringParameters,
          body: {accountID: currentAccountID}
        })

        setQueryParams(queryStringParameters)
        prevFilters.current = queryStringParameters

        setState(prevState => ({
          ...prevState,
          data,
          loading: false,
          filters: {
            ...prevState.filters,
            ...queryStringParameters,
          },
          pagination: {
            ...prevState.pagination,
            total: data.length
          }
        }))

      } catch (e) {
        setState(prevState => ({
          ...prevState,
          loading: false
        }))
      }
    }

    fetchData()
  }, [JSON.stringify(state.filters), queryParams.categoryId])

  const handleTableChange = async (
    pagination,
    filters,
    sorter,
  ) => {
    setState(prevState => ({
      ...prevState,
      pagination,
      sorter,
      filters: {
        ...prevState.filters,
        ...filters
      },
    }))

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== state.pagination.pageSize) {
      setState(prevState => ({
        ...prevState,
        data: []
      }))
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const [types, statuses] = await Promise.all([
          API.get('leads', '/get-type-map'),
          API.get('clients', '/get-status-map')
        ])
        setState(prevState => ({
          ...prevState,
          types: types.payload,
          statuses: statuses.payload
        }))
      } catch (error) {
        console.log('error', error)
      }
    }

    init()
  }, [])

  const columns = [
    getColumnSearchProps({
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (highlighter, record) => <NavLink to={routes.clientsView({clientID: record.id})}>{highlighter}</NavLink>
    }),
    getColumnSearchProps({
      title: 'Client Type',
      dataIndex: 'client_type',
      filterInputType: 'SELECT',
      convertFilterValueToNumber: true,
      selectFilterOptions: state.types,
      getOptionProps:  item => ({
        value: item.id,
        children: item.fileBy
      }),
      render: (text, record) => state.types.find(item => item.id === record.client_type)?.fileBy || 'INVALID'
    }),
    getColumnSearchProps({
      title: 'Name',
      dataIndex: 'c_file_by',
    }),
    getColumnSearchProps({
      title: 'First Name',
      dataIndex: 'first_name',
    }),
    getColumnSearchProps({
      title: 'Last Name',
      dataIndex: 'last_name',
    }),
    getColumnSearchProps({
      title: 'Client Reference',
      dataIndex: 'account_user_id',
      sorter: (a, b) => a.account_user_id - b.account_user_id,
      defaultSortOrder: 'descend',
      placeholder: 'Account ID',
      render: (highlighter, record) => <NavLink to={routes.clientsView({
        clientID: record.id,
        tab: 'account'
      })}>{highlighter}</NavLink>
    }),
    getColumnSearchProps({
      title: 'Email',
      dataIndex: 'email',
    }),
    getColumnSearchProps({
      title: 'Mobile',
      dataIndex: 'tel_mobile',
    }),
    // getColumnSearchProps({
    //   title: 'Country',
    //   dataIndex: 'street_address_country',
    //   filterInputType: 'SELECT',
    //   convertFilterValueToNumber: true,
    //   selectFilterOptions: countriesList,
    //   getOptionProps: opt => ({
    //     value: opt.id,
    //     children: opt.full_name
    //   }),
    //   render: (highlighter, record) => (
    //     <>
    //       <ReactCountryFlag
    //         countryCode={record.countryIsoAlpha2}
    //         style={{
    //           fontSize: '1.5em',
    //           lineHeight: '1.5em'
    //         }}
    //         svg
    //       />
    //         &nbsp;
    //       <span>{record.countryFullName}</span>
    //     </>
    //   )
    // }),
    getColumnSearchProps({
      title: 'Risk',
      dataIndex: 'risk_totalScore',
      filterInputType: 'SELECT',
      selectFilterOptions: [
        {
          value: '0',
          children: 'No Data'
        },
        {
          value: '1,13',
          children: 'Low'
        },
        {
          value: '14,20',
          children: 'Medium'
        },
        {
          value: '21,25',
          children: 'High'
        },
        {
          value: '26',
          children: 'Declined'
        },
      ],
      getOptionProps: (opt) => {
        const {text, color} = getRiskTotalScore(opt.value)

        return {
          value: opt.value,
          children: (
            <Tag
              color={color}
              className={classes.tag}
            >
              {text}
            </Tag>
          )
        }
      },
      render: (_, record) => {
        const {text, color} = getRiskTotalScore(record.risk_totalScore)

        return (
          <Tag
            color={color}
          >
            {text} {record?.risk_totalScore ? '(' + record?.risk_totalScore + ')' : ''}
          </Tag>
        )
      }
    }),
    getColumnSearchProps({
      title: 'Status',
      dataIndex: 'status_id',
      filterInputType: 'SELECT',
      convertFilterValueToNumber: true,
      selectFilterOptions: state.statuses,
      getOptionProps: item => ({
        value: item.id,
        children: (
          <Tag
            color={STATUS_COLOR[item.id]}
            className={classes.tag}
          >
            {item.file_by}
          </Tag>
        )
      }),
      render: (text, record) => (
        <Tag color={STATUS_COLOR[record.status_id] || STATUS_COLOR.default}>
          {state.statuses.find(item => item.id === record.status_id)?.file_by || 'INVALID'}
        </Tag>
      )
    }),
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => (
        <Popconfirm
          okText='Yes'
          onCancel='No'
          title='Are you sure to delete this record?'
          okButtonProps={{loading: state.deletedIDs.includes(record.id)}}
          onConfirm={async () => {
            try {
              setState(prevState => ({
                ...prevState,
                deletedIDs: prevState.deletedIDs.concat(record.id)
              }))

              await API.del( 'clients', `/delete/${record.id}`)

              prevFilters.current.id = null

              setState(prevState => ({
                ...prevState,
                data: prevState.data.filter(item => item.id !== record.id),
                deletedIDs: prevState.deletedIDs.filter(id => id !== record.id),
              }))
            } catch (e) {
              notification.error({message: e.response?.data?.error || e.message})

              setState(prevState => ({
                ...prevState,
                deletedIDs: prevState.deletedIDs.filter(id => id !== record.id)
              }))
            }
          }}
        >
          <DeleteOutlined style={{color: 'red'}} />
        </Popconfirm>
      )
    }
  ]

  return (
    <Table
      rowKey='id'
      columns={columns}
      loading={state.loading}
      dataSource={state.data}
      pagination={state.pagination}
      onChange={handleTableChange}
      scroll={{x: true}}
    />
  )
}

export default listHOC(ClientListPageComponent, undefined, true)