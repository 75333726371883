import API from '@aws-amplify/api'
import {Button, Col, Divider, Row} from 'antd'
import React, {Fragment, useState} from 'react'

import {showNotification} from 'utils'

export const XeondaRecordIds = ({currentAccountID}) => {
  const [loading, setLoading] = useState(false)
  const onClickGetClientsWithoutXri = async () => {
    try {
      setLoading(true)
      let clientsWithoutXri = await API.get('systemTools', `/xri/getClientsWithoutXri/${currentAccountID}`)
      console.log(clientsWithoutXri)
      setLoading(false)
      showNotification('success', 'System Tools', 'XRI Created')
    } catch (error) {
      setLoading(false)
      showNotification('error', 'System Tools', error.message)
    }
  }
  const onClickCreateXriForMissing = async () => {
    try {
      setLoading(true)
      let clientsWithoutXri = await API.get('systemTools', `/xri/createXriForMissing/${currentAccountID}`)
      console.log(clientsWithoutXri)
      setLoading(false)
      showNotification('success', 'System Tools', 'XRI Created')
    } catch (error) {
      setLoading(false)
      showNotification('error', 'System Tools', error.message)
    }
  }
  return (
    <Fragment>
      <Row justify='start'>
        <Col xs={12}>
          <Row justify='end' gutter={[12.12]}>
            <Col xs={12}>
              <h4>Get Clients without XRIs</h4>
            </Col>
            <Col xs={12} onClick={onClickGetClientsWithoutXri}>
              <Button htmlType='button' disabled={loading}>
                Run
              </Button>
            </Col>
          </Row>
          <Divider/>
          <Row justify='end' gutter={[12.12]}>
            <Col xs={12}>
              <h4>Create XRI for all clients missing one</h4>
            </Col>
            <Col xs={12} onClick={onClickCreateXriForMissing}>
              <Button htmlType='button' disabled={loading}>
                Run
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}