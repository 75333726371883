import * as COLORS from '@ant-design/colors'

export {COLORS}

export const PREFERRED_COUNTRIES = ['au', 'us', 'sg', 'cn', 'my']

export const INPUT_GROUP_OPTIONS = [
  {
    label: 'common.yes',
    value: true,
  },
  {
    label: 'common.no',
    value: false,
  },
]

export const languages = [
  {
    value: 'en',
    text: 'English',
    flag: {
      countryCode: 'GB',
      'aria-label': 'English Language'
    }
  },
  {
    value: 'zh',
    text: '繁體中文',
    flag: {
      countryCode: 'CN',
      'aria-label': 'English Language'
    }
  }
]

export const FINANCIAL_ACCOUNT_TYPES = {
  BANK: 1,
  WALLET: 2
}

export const COMMON_COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
}

export const ROW_GUTTER = {
  xs: 16,
  sm: 16,
  md: 24,
  lg: 32,
}

export const APP_NAME = 'DELIOS_CRM'

export const STATUS_COLOR = {
  1: 'orange',
  2: 'geekblue',
  3: 'gold',
  4: 'lime',
  5: 'cyan',
  6: 'magenta',
  7: 'red',
  8: 'warning',
  9: 'purple',
  10: 'green',
  default: 'geekblue'
}
