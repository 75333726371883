import React, {Component} from 'react'
import { API } from 'aws-amplify'

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Spin
} from 'antd'

import moment from 'moment'

const {Option} = Select

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class TradingTransactionsSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      statusList: [],
      tradingAccountsByAccount: [],
      loading: true
    }
  }

  async componentDidMount() {
    try {
      const TradingTransaction = await this.getTradingTransactionsRecord(this.props.id)
      this.setState({
        ...TradingTransaction.payload,
        loading: true
      })
      const {accountID} = this.state
      await API.get('trading-transactions-2', `/get-ta-list/account/${accountID}`).then(
        response => {
          this.setState({
            ...this.state,
            tradingAccountsByAccount: response.payload
          })
        }
      )
      await API.get('trading-transactions-2', '/list-status')
        .then(response => {
          const dataSource = []
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              statusName: response.payload[i].statusName,
            })
          }
          this.setState({statusList: dataSource})
        })
        .catch(error => {
          console.log(error)
        })
      this.setState({loading: false})
    } catch (e) {
      console.log(e)
    }
  }

  async getTradingTransactionsRecord(tradingTransactionID) {
    return API.get('trading-transactions-2', `/id/${tradingTransactionID}`)
  }

  async saveTradingTransactionsRecord(tradingTransactionRecord) {
    await API.patch('trading-transactions-2', `/id/${this.state.id}`, {body: tradingTransactionRecord})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = value => {
    this.setState({'amount': value})
  };

  onHandleChangeStatus = value => {
    this.setState({'statusID': value})
  }

  onHandleChangeTradingAccount = value => {
    this.setState({'tradingAccountID': value})
  }

  onHandleSubmit = async event => {
    event.preventDefault()
    this.setState({is_saving: true})
    openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

    const {
      id,
      statusID,
      tradingAccountID
    } = this.state

    try {
      await this.saveTradingTransactionsRecord({
        id,
        statusID,
        tradingAccountID
      })
      this.setState({is_saving: false})
    } catch (e) {
      console.log(e)
    }
  };

  render() {

    const deposit = this.state.debit > 0
    const withdrawal = this.state.credit > 0

    return (
      <React.Fragment>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <h3>{deposit ? 'Deposit Transaction' : ''}{withdrawal ? 'Withdrawal Transaction' : ''}</h3>
          </Col>
        </Row>
        <Skeleton active loading={this.state.loading}>
          <Spin spinning={this.state.loading}>
            <Form layout='vertical' onSubmit={this.onHandleSubmit}>
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Card
                    title='Account Details'
                  >
                    <Row gutter={[96, 24]}>
                      <Col xs={8}>
                        <Form.Item label='Transaction ID'>
                          <Input
                            id='id'
                            value={this.state.id || ''}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={16}>
                        <Form.Item label='Record Created'>
                          {this.state?.recordCreated
                            ? moment(this.state?.recordCreated).format('YYYY/MM/DD HH:mm:ss')
                            : ''
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label='Trading Account'>
                      <Select
                        onChange={this.onHandleChangeTradingAccount}
                        defaultValue={this.state.tradingAccountID || ''}
                        value={this.state.tradingAccountID || ''}
                        disabled={
                          this.state.statusID === 3 || 
                          this.state.statusID === 5 || 
                          this.state.statusID === 6 || 
                          this.state.statusID === 7}
                      >
                        {
                          this.state.tradingAccountsByAccount.map(({
                            id, trading_platform_mode_file_by, trading_platform_type_file_by
                          }) => (
                            <Option key={id} value={id}>
                              {`[${id}] ${trading_platform_mode_file_by} ${trading_platform_type_file_by}`}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>

                <Col xs={12}>
                  <Card
                    title='Client Details'
                  >
                    <Form.Item label='Client'>
                      <Input
                        id='client'
                        value={this.state.client || ''}
                        disabled
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Card
                    title='Transaction Details'
                  >
                    <Row gutter={[96, 24]}>
                      <Col xs={12}>
                        {deposit &&
                      <Form.Item label='Deposit Amount'>
                        <Input
                          id='debit'
                          value={Number.parseFloat(this.state.debit).toFixed(2)}
                          disabled
                        />
                      </Form.Item>
                        }
                        {withdrawal &&
                      <Form.Item label='Withdrawal Amount'>
                        <Input
                          id='credit'
                          value={Number.parseFloat(this.state.credit).toFixed(2)}
                          disabled
                        />
                      </Form.Item>
                        }
                      </Col>
                      <Col xs={12}>
                        <Form.Item label='Status'>
                          <Select
                            onChange={this.onHandleChangeStatus}
                            defaultValue={this.state.statusID || ''}
                            value={this.state.statusID || ''}
                          >
                            {
                              this.state.statusList.map(({ id, statusName }) => (
                                <Option key={id} value={id}>
                                  {statusName}
                                </Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                  </Card>
                </Col>

                <Col xs={12}>
                </Col>
              </Row>

              <Row gutter={[96, 24]}>
                <Col xs={12}>
                </Col>

                <Col xs={12}>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={this.state.is_saving}
                  onClick={this.onHandleSubmit}
                >Save</Button>
              </Row>
            </Form>
          </Spin>
        </Skeleton>
      </React.Fragment>
    )
  }
}

export default TradingTransactionsSummary
