import {
  API,
  Auth
} from 'aws-amplify'

import {configureAmplify} from 'configs'

export async function create_client_cognito_account(id, username, password) {
  try {
    configureAmplify('client')

    const newUser = await Auth.signUp({
      username: username,
      password: password
    })

    await update_client_in_database(id, {aws_cognito_id: newUser.userSub})

    configureAmplify('staff')

    API.post('email', '/client/new-client-created', {
      body: {
        id: id,
        username: username,
        password: password
      }
    })
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
    API.post('email', '/interaction', {
      body: {
        interaction: 'Create New Client Portal Record',
        data: {
          id: id,
          username: username,
          password: password,
          aws_cognito_id: newUser.userSub
        },
        result: 'portal record created successfully'
      }
    })
      .then(() => {

      })
      .catch(error => {
        console.log(error)
      })
  } catch (e) {
    console.log(e)

    configureAmplify('staff')
  }
}

async function update_client_in_database(id, client_record) {
  return API.put('clients', `/update/${id}`, {body: client_record})
}

export async function create_user_client_record(client_id, aws_cognito_id, email) {
  console.log(client_id, aws_cognito_id)
  return API.post('user-client', '/create/user-client', {
    body: {
      client_id,
      aws_cognito_id,
      email
    }
  })
}