import React from 'react'
import { Table } from 'antd'
import { Link } from 'react-router-dom'
import {listHOC} from 'common'
import { FINANCIAL_ACCOUNT_TYPES } from 'consts'

import useFinancialAccounts from './hooks/useFinancialAccounts'

const AccountFinancialAccounts = ({getColumnSearchProps}) => {
  const state = useFinancialAccounts()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
      }),
      render: (value) => <Link to={`/financial/account-financial-account/${value}`}>{value}</Link>
    },
    {
      title: 'Account Type',
      dataIndex: 'financial_account_type',
    },
    {
      title: 'Classification',
      render: (_, raw) => {
        const {
          financial_account_type_id, coin_type, currency
        } = raw
        return (
          <div>
            {financial_account_type_id === FINANCIAL_ACCOUNT_TYPES.BANK && <span>{currency}</span>}
            {financial_account_type_id === FINANCIAL_ACCOUNT_TYPES.WALLET && <span>{coin_type}</span>}
          </div>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'financial_account_name',
      ...getColumnSearchProps('financial_account_name'),
    },
  ]

  return (
    <div>
      <Table rowKey='id' columns={columns}
        dataSource={state.value}
        loading={state.loading}
      />
    </div>
  )
}

export default listHOC(AccountFinancialAccounts)