import React, {useState} from 'react'
import {
  Table,
  Button,
  Popconfirm,
  DeleteOutlined,
  PlusOutlined,
  Space,
  Switch,
  Tooltip,
  Card,
  List
} from 'components'
import {listHOC} from 'hocs'
import {
  showNotification,
  API
} from 'utils'

import {CreateTaxationModal} from './components'

const TaxationRecordsListPageComponent = ({
  loading, entities, getColumnSearchProps, updateState, client_id, noTINReasons
}) => {
  const [state, setState] = useState({
    deleting: [],
    visible: false,
    createTaxation: false
  })

  const deleteRecord = async (id) => {
    try {
      setState(prevState => ({
        ...prevState,
        deleting: prevState.deleting.concat(id)
      }))

      await API.del('clients', `/${client_id}/taxation/${id}`)

      setState(prevState => ({
        ...prevState,
        deleting: prevState.deleting.filter(currentID => currentID !== id)
      }))

      updateState({entities: entities.filter(item => item.id !== id)})
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        deleting: prevState.deleting.filter(currentID => currentID !== id)
      }))

      showNotification('error', 'API', e.message)
    }
  }

  const columns = [
    getColumnSearchProps({
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      placeholder: 'ID',
    }),
    {
      title: 'Country',
      dataIndex: 'country',
    },
    getColumnSearchProps({
      title: 'TIN',
      dataIndex: 'tin',
      placeholder: 'tin'
    }),
    getColumnSearchProps({
      title: 'No TIN Reason',
      dataIndex: 'noTinReason',
      placeholder: 'noTinReason',
      render: (value) => {
        if (!value) {
          return ''
        }
        return `Reason ${value}`
      }
    }),
    getColumnSearchProps({
      title: 'Details',
      dataIndex: 'furtherDetails',
      placeholder: 'furtherDetails'
    }),
    {
      title: 'Action',
      render: (text, record) => (
        <Popconfirm
          title='Are you sure to delete this record?'
          onConfirm={() => deleteRecord(record.id)}
          okText='Yes'
          onCancel='No'
        >
          <Button
            danger
            type='text'
            icon={<DeleteOutlined />}
            loading={state.deleting.includes(record.id)}
          />
        </Popconfirm>
      )
    }
  ]

  const toggleVisible = (visible) => {
    setState(prevState => ({
      ...prevState,
      visible
    }))
  }
  const toggleCreateModal = (open) => {
    setState(prevState => ({
      ...prevState,
      createTaxation: open
    }))
  }

  return (
    <Card
      style={{ height: '100%' }}
      title="Taxation CRS Records"
      extra={
        <Space>
          <Switch
            checked={state.visible}
            checkedChildren='Hide'
            unCheckedChildren='View'
            onClick={toggleVisible}
          />
          <CreateTaxationModal
            clientID={client_id}
            open={state.createTaxation}
            noTINReasons={noTINReasons}
            onCancel={() => {
              toggleCreateModal(false)
            }}
            onSuccess={(record) => {
              updateState({entities: entities.concat(record)})

              toggleCreateModal(false)
            }}
          />
          {state.visible && (
            <Tooltip title='Create Taxation Record'>
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => {
                  toggleCreateModal(true)
                }}
              />
            </Tooltip>
          )}
        </Space>
      }
    >
      {state.visible ? (
        <>
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            scroll={{x: true}}
            dataSource={entities}
            pagination={{
              pageSize: ['5'],
              pageSizeOptions: [5, 10, 20]
            }}
          />
          <List
            size="small"
            bordered
            dataSource={noTINReasons}
            renderItem={(item) => <List.Item>Code: {item.code}. {item.reason}</List.Item>}
          />
        </>
      ) : (
        <p> Taxation data is hidden. </p>
      )}
    </Card>
  )
}

export default listHOC(
  TaxationRecordsListPageComponent,
  ({client_id}) => API.get('clients', `/${client_id}/taxation`)
)