import React, {useEffect} from 'react'

import {createUseStyles} from 'react-jss'
import {connect} from 'react-redux'
import {
  Spin,
  Row,
  Col
} from 'antd'

import {leadsEntitiesSelector} from 'configs/ducks/leads'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  inputChange,
  clearState
} from 'configs/actions/common'

import ClientsGraphAndListComponent from './widgets/ClientsGraphAndList'
import LeadsGraphAndListComponent from './widgets/LeadsGraphAndList'
import OpportunitiesGraphAndList from './widgets/OpportunitiesGraphAndList'
import {ProfitAndLossGraph} from './widgets/ProfitAndLossGraph'

const useStyles = createUseStyles({
  stepsContent: {
    minHeight: 280,
    paddingTop: 48
  }
})

const DashboardPageComponent = ({
  clearState, loading, currentAccountID, account_slug, currentStaffRecord
}) => {
  const classes = useStyles()

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  return (
    <Spin wrapperClassName={classes.stepsContent} spinning={loading}>
      <Row gutter={[96, 24]}>
        <Col xs={8}>
          <OpportunitiesGraphAndList accountId={currentAccountID} days='7' />
        </Col>
        <Col xs={8}>
          <LeadsGraphAndListComponent accountId={currentAccountID} days='7' />
        </Col>
        <Col xs={8}>
          <ClientsGraphAndListComponent accountId={currentAccountID} days='7' />
        </Col>
        {currentStaffRecord.security_finance_canViewAccountFinancialReports &&
          <Col xs={8}>
            <ProfitAndLossGraph account_slug={account_slug} />
          </Col>
        }
      </Row>
    </Spin>
  )
}

const mapStateToProps = state => {
  const {loading} = leadsEntitiesSelector.leadsEntitiesSelector(state)
  const {
    accountsList,
    currentAccountRecord: {id: currentAccountID, account_slug},
    currentStaffRecord,
  } = appStateSelectors.stateSelector(state)

  return {
    loading,
    account_slug,
    currentStaffRecord,
    accountsList,
    currentAccountID
  }
}

const mapDispatchToProps = {
  clearState,
  inputChange
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageComponent)
