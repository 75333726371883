export const languages = [
  {
    value: 'en',
    text: ' English',
    flag: {
      countryCode: 'GB',
      'aria-label': 'English Language'
    }
  },
  {
    value: 'zh',
    text: ' 繁體中文',
    flag: {
      countryCode: 'CN',
      'aria-label': 'English Language'
    }
  }
]