import React, {useEffect} from 'react'

import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Skeleton
} from 'antd'
import {
  FormSelect,
  listHOC
} from 'common'
import {columnSize} from '../../common'
import {
  staffsActions,
  staffsSelectors
} from 'configs/ducks/staffs'
import {appStateSelectors} from 'configs/ducks/appState'
import {connect} from 'react-redux'

const AccountSummary = (
  {
    file_by,
    account_slug,
    account_name,
    saveAccountRecord,
    defaultResponsibleStaffID,
    staffList,
    getStaffList,
    currentAccountID
  }
) => {
  useEffect(() => {
    getStaffList(currentAccountID)
  }, [])

  return (
    <Form
      layout='vertical'
      onFinish={saveAccountRecord}
      initialValues={{
        file_by,
        account_slug,
        account_name,
        defaultResponsibleStaffID
      }}
    >
      <Row gutter={[24, 0]}>
        <Col {...columnSize}>
          <Form.Item
            label='File by'
            name='file_by'
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label='Account Slug'
            name='account_slug'
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col {...columnSize}>
          <Form.Item
            label='Account Name'
            name='account_name'
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            label='Default Responsible Staff for new Client/Lead Records'
            name='defaultResponsibleStaffID'
          >
            <Skeleton loading={staffList.length === 0}>
              <FormSelect
                required
                name='defaultResponsibleStaffID'
                optionValue='id'
                placeholder='Select Staff'
                getOptionProps={({
                  file_by, id, email
                }) => ({children: `[${id}] ${file_by} - ${email}`})}
                options={staffList}
              />
            </Skeleton>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='end'>
        <Button type='primary' htmlType='submit'>
          Save Record
        </Button>
      </Row>
    </Form>
  )
}

const {stateSelector} = staffsSelectors
const {getStaffList} = staffsActions
const mapStateToProps = state => {
  const {
    accountsList,
    currentAccountRecord: {id: currentAccountID}
  } = appStateSelectors.stateSelector(state)

  return {
    accountsList,
    currentAccountID,
    staffList: staffsSelectors.stateSelector(state).entities,
    ...stateSelector(state)
  }
}

export default connect(mapStateToProps, {getStaffList: getStaffList})(listHOC(AccountSummary))