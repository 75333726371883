import * as types from './types'

/**
 * @param {Object} accountData
 * @returns {{payload: *, type: string}}
 */
export const saveAccount = accountData => ({
  type: types.SAVE_ACCOUNT_REQUEST,
  payload: accountData
})
/**
 * @returns {{type: string}}
 */
export const getCurrencyList = () => ({type: types.GET_CURRENCY_LIST_REQUEST})

export const getFinancialAccountTypes = () => ({type: types.GET_FINANCIAL_ACCOUNT_TYPES_REQUEST})

/**
 * @param {Number} accountID
 * @returns {{type: string}}
 */
export const getTradingPlatformsList = accountID => ({
  type: types.GET_TRADING_PLATFORMS_LIST_REQUEST,
  payload: accountID
})
export const getAllJournals = payload => ({
  type: types.GET_JOURNALS_LIST_REQUEST,
  payload: payload
})