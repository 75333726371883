import React, {
  useEffect,
  useCallback,
  useState
}
from 'react'
import {connect} from 'react-redux'
import {
  Link,
  NavLink
} from 'react-router-dom'
import {
  Table,
  Button
} from 'antd'
import {
  SyncOutlined,
  EditOutlined
} from '@ant-design/icons'

import {
  docCheckSelectors,
  docCheckActions
} from 'configs/ducks/doc-check'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  inputTypes,
  listHOC
} from 'common'
import moment from 'moment'
import CollectionsNewModal from './CollectionsNewModal'

const CollectionsListPageComponent = ({
  loading, dataSource, getColumnSearchProps, docTypeList, getTopics, fetchCb, history, currentAccountID
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = bool => setIsModalVisible(bool)
  const [state, setState] = useState({})
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
      })
    },
    {
      title: 'Title',
      dataIndex: 'title',
      ...getColumnSearchProps({
        dataIndex: 'title',
        placeholder: 'title'
      })
    },
    {
      title: 'Count',
      dataIndex: 'number_of_collections',
      ...getColumnSearchProps({
        dataIndex: 'number_of_collections',
        placeholder: 'number_of_collections'
      })
    },
    {
      title: 'Created',
      dataIndex: 'recordCreated',
      ...getColumnSearchProps({
        dataIndex: 'recordCreated',
        placeholder: 'recordCreated'
      }),
      render: (text, record) => (
        record?.recordCreated
          ? moment(record.recordCreated).format('YYYY/MM/DD HH:mm:ss')
          : ''
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return <React.Fragment>
          <Link to={`/settings/doc-checks/${record.id}?tab=summary`}>
            <EditOutlined />
          </Link>
        </React.Fragment>
      }
    }
  ]

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
          <Button
            loading={state?.buttonLoading}
            onClick={handleClickCreateNewCollection}
          > Create New </Button>
        </>
      )
    })
  }, [])

  const handleClickCreateNewCollection = useCallback(record => {
    setState({buttonLoading: true})
    toggleModal(true)
    setState({buttonLoading: false})
  }, [])

  return (
    <>
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        scroll={{x: true}}
        dataSource={dataSource.length > 0 ? dataSource : []}
        pagination={{
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true
        }}
      />
      <CollectionsNewModal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        fetchCb={fetchCb}
      />
    </>
  )
}

const {docCheckEntitiesSelector} = docCheckSelectors
const {fetchChecklistCollections} = docCheckActions

const mapStateToProps = state => {
  const {entities, loading} = docCheckEntitiesSelector(state)
  return {
    dataSource: entities?.results ? entities.results : [],
    loading,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id
  }
}

const CollectionsListPageComponentContainer = connect(mapStateToProps, dispatch => ({
  fetchCb: (currentAccountID) => {
    dispatch(fetchChecklistCollections(currentAccountID))
  }
}))(listHOC(CollectionsListPageComponent))

export default CollectionsListPageComponentContainer
