import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import {openNotificationWithIcon} from '../../utils'

import * as types from './types'

function* fetchAgents(action) {
  const {payload: id} = action

  try {
    const {payload} = yield call([API, API.get], 'agents', `/list/${id}`)
    yield put({
      type: types.FETCH_AGENTS_SUCCESS,
      payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.FETCH_AGENTS_ERROR})
  }
}

function* getAgent(action) {
  try {
    const {payload: id} = action
    const {payload: agent} = yield call([API, API.get], 'agents', `/id/${id}`)
    yield put({
      type: types.GET_AGENT_SUCCESS,
      payload: agent
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.GET_AGENT_ERROR})
  }
}

function* saveAgentRecord(action) {
  try {
    const {payload: agent} = action
    openNotificationWithIcon('info', 'In Process', 'Saving information...')

    yield call([API, API.put], 'agents', `/update/${agent.id}`, {body: {...agent}})

    openNotificationWithIcon('success', 'Success', 'Successfully saved.')
    yield put({type: types.SAVE_AGENT_SUCCESS})
  } catch (e) {
    openNotificationWithIcon('error', 'Error', 'Failed to save')
    yield put({type: types.SAVE_AGENT_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.FETCH_AGENTS_REQUEST, fetchAgents),
    yield takeEvery(types.GET_AGENT_REQUEST, getAgent),
    yield takeEvery(types.SAVE_AGENT_REQUEST, saveAgentRecord),
  ])
}