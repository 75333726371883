import {
  Record,
  List
} from 'immutable'

import * as types from './types'
import {settingsTypes} from '../settings'

const ReducerRecord = Record({
  authenticating: true,
  loading: false,
  countriesList: [],
  accountsList: List([]),
  accountTypeList: [],
  clientStatusList: [],
  clientTypeList: [],
  companyTypeList: [],
  clientCategoriesList: [],
  genderList: [],
  streetCodes: [],
  employmentStatusList: [],
  currentAccountRecord: {},
  currentStaffRecord: {},
  currentUserRecord: {},
  staffAccess: {},
  authenticatedUser: null,
  appRemountKey: 0,
  sicDivisions: [],
  sicGroups: [],
  userGeo: '',
  statusIndicators: [],
  natureOfTransactions: [],
  expectedTransactionsCount: [],
  purposeOfAccount: [],
  sourceOfWealth: [],
  noTfnReasons: [],
  tradingPlatformAccounts: [],
  currencies: [],
})

export default (state = new ReducerRecord(), {type, payload}) => {
  switch (type) {

    case types.SWITCH_STAFF_REQUEST:
    case types.SWITCH_ACCOUNT_REQUEST:
    case types.LOG_OUT_USER_REQUEST:
    case types.LOGIN_USER_REQUEST:
      return state.set('loading', true)

    case types.RE_MOUNT_APP:
      return state.update('appRemountKey', appRemountKey => appRemountKey + 1)

    case types.AUTHENTICATE_USER_SUCCESS:
    case types.LOGIN_USER_SUCCESS:
      const {
        countriesList,
        accountsList,
        accountTypeList,
        clientStatusList,
        genderList,
        streetCodes,
        clientTypeList,
        companyTypeList,
        clientCategoriesList,
        employmentStatusList,
        currentStaffRecord,
        currentUserRecord,
        staffAccess,
        sicDivisions,
        sicGroups,
        user,
        statusIndicators,
        natureOfTransactions,
        expectedTransactionsCount,
        purposeOfAccount,
        sourceOfWealth,
        noTfnReasons,
        tradingPlatformAccounts,
        currencies
      } = payload

      return state.merge({
        statusIndicators,
        authenticating: false,
        authenticatedUser: user,
        countriesList,
        accountsList: List(accountsList),
        accountTypeList,
        clientStatusList,
        clientTypeList,
        companyTypeList,
        clientCategoriesList,
        genderList,
        streetCodes,
        employmentStatusList,
        currentUserRecord: currentUserRecord,
        staffAccess,
        currentStaffRecord: staffAccess.find(
          ({id}) => id === (currentUserRecord.lastActiveStaffId || currentStaffRecord.id)),
        currentAccountRecord: accountsList.find(
          ({id}) => id === (currentUserRecord.last_active_account || currentUserRecord.id)
        ),
        sicDivisions,
        sicGroups,
        natureOfTransactions,
        expectedTransactionsCount,
        purposeOfAccount,
        sourceOfWealth,
        noTfnReasons,
        tradingPlatformAccounts,
        currencies
      })

    case settingsTypes.SAVE_ACCOUNT_SUCCESS:
      const index = state.accountsList.findIndex(({id}) => id === payload.id)
      return state.updateIn(['accountsList', index], () => payload)

    case types.UPDATE_USER_LAST_ACTIVE_ACCOUNT:
      return state.updateIn(['currentUserRecord', 'last_active_account'], lastActiveAccount => {
        return payload
      })

    case types.SWITCH_STAFF_SUCCESS:
      return state.merge({
        loading: false,
        currentStaffRecord: payload
      })

    case types.SWITCH_ACCOUNT_SUCCESS:
      return state.merge(payload)

    case types.AUTHENTICATE_USER_ERROR:
      return state.set('authenticating', false)

    case types.LOG_OUT_USER_SUCCESS:
      return new ReducerRecord().set('authenticating', false)

    case types.SWITCH_STAFF_ERROR:
    case types.LOG_OUT_USER_ERROR:
    case types.LOGIN_USER_ERROR:
      return state.set('loading', false)

    case types.GET_CURRENT_COUNTRY_SUCCESS:
      return state.merge({
        userGeo: payload,
        loading: false
      })
    default:
      return state
  }
}
