import {useAsyncFn} from 'react-use'
import { API } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import moment from 'moment'

export default function() {
  const { id } = useParams()
  const [state, doFetch] = useAsyncFn(async () => {
    if (id) {
      const { payload } = await API.get('account-financial-accounts', `/transaction/${id}`)
      
      return (payload || [] ).sort((a, b) => moment(b.createdDate) - moment(a.createdDate))
    }
    return null
  }, [id])

  useEffect(() => {
    doFetch()
  }, [doFetch])

  return [state, doFetch]
}