import {APP_NAME} from 'consts'

export const moduleName = 'settings'

export const SAVE_ACCOUNT_REQUEST = `${APP_NAME}/${moduleName}/SAVE_ACCOUNT_REQUEST`
export const SAVE_ACCOUNT_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_ACCOUNT_SUCCESS`
export const SAVE_ACCOUNT_ERROR = `${APP_NAME}/${moduleName}/SAVE_ACCOUNT_ERROR`

export const GET_CURRENCY_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_CURRENCY_LIST_REQUEST`
export const GET_CURRENCY_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_CURRENCY_LIST_SUCCESS`
export const GET_CURRENCY_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_CURRENCY_LIST_ERROR`

export const GET_FINANCIAL_ACCOUNT_TYPES_REQUEST = `${APP_NAME}/${moduleName}/GET_FINANCIAL_ACCOUNT_TYPES_REQUEST`
export const GET_FINANCIAL_ACCOUNT_TYPES_SUCCESS = `${APP_NAME}/${moduleName}/GET_FINANCIAL_ACCOUNT_TYPES_SUCCESS`
export const GET_FINANCIAL_ACCOUNT_TYPES_ERROR = `${APP_NAME}/${moduleName}/GET_FINANCIAL_ACCOUNT_TYPES_ERROR`

export const GET_TRADING_PLATFORMS_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_TRADING_PLATFORMS_LIST_REQUEST`
export const GET_TRADING_PLATFORMS_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_TRADING_PLATFORMS_LIST_SUCCESS`
export const GET_TRADING_PLATFORMS_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_TRADING_PLATFORMS_LIST_ERROR`

export const GET_JOURNALS_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_JOURNALS_LIST_REQUEST`
export const GET_JOURNALS_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_JOURNALS_LIST_SUCCESS`
export const GET_JOURNALS_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_JOURNALS_LIST_ERROR`
