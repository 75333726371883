import React from 'react'

import {
  Col,
  Form,
  Row,
  PhoneInput,
  InputEmail
} from 'components'
import {CommonProps} from '../../types'

import {COMMON_COL_PROPS} from 'consts'

const CommunicationStep: React.FC<CommonProps> = ({children, initialValues}) => {
  return (
    <Form
      name='communication'
      layout='vertical'
      initialValues={initialValues}
    >
      <Row
        gutter={[12, 0]}
      >
        <Col {...COMMON_COL_PROPS}>
          <PhoneInput
            name='telephone'
            label='common.telephone'
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputEmail
            name='emailAddress'
            label='common.email'
          />
        </Col>
      </Row>
      {children}
    </Form>
  )
}
export default CommunicationStep
