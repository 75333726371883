import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'

import {
  Row,
  Col,
  List,
} from 'antd'

export const Dashboard: React.FC = () => {

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <List
            header="Clients"
            bordered
          >
            <List.Item>
              <Link to={'/reports/clients/client-status-metrics'}>
                Client Status Metrics
              </Link>
            </List.Item>
            <List.Item>
              <Link to={'/reports/leads/lead-status-metrics'}>
                Lead Status Metrics
              </Link>
            </List.Item>
          </List>
          <br/>
          <List
            header="Financial Reports"
            bordered
          >
            <List.Item>
              <Link to={'/reports/financial/profit-loss'}>
                  Profit / Loss
              </Link>
            </List.Item>
          </List>
          <br/>
          <List
            header="Account Management Reports"
            bordered
          >
            <List.Item>
              <Link to={'/reports/management/metrics'}>
                  Account Metrics
              </Link>
            </List.Item>
          </List>
          <br/>

          <List
            header="Information Requests"
            bordered
          >
            <List.Item>
              <Link to={'/reports/information-requests/metrics'}>
                  Information Request Metrics
              </Link>
            </List.Item>
            <List.Item>
              <Link to={'/reports/information-requests/leads-without-requests'}>
                  Leads without corresponding Information Request
              </Link>
            </List.Item>
            <List.Item>
              <Link to={'/reports/information-requests/outstanding-requests'}>
                  Leads with Outstanding Information Request
              </Link>
            </List.Item>
          </List>
          <br/>
            
          <List
            header="Derivative Reports"
            bordered
          >
            <List.Item>
              <Link to={'/reports/derivatives/derivative-company-statement'}>
                  Derivative Company Statement
              </Link>
            </List.Item>
            <List.Item>
              <Link to={'/reports/financial/profit-loss'}>
                  Profit / Loss
              </Link>
            </List.Item>
          </List>
          <br/>
          <List
            header="B Book Reports"
            bordered
          >
            <List.Item>
              <Link to={'/reports/positions/daily'}>
                  Daily Position
              </Link>
            </List.Item>
            <List.Item>
              <Link to={'/reports/positions/hourly'}>
                  Hourly Position
              </Link>
            </List.Item>
          </List>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Dashboard