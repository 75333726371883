import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'

import {
  API,
  Storage
} from 'aws-amplify'

import {openNotificationWithIcon} from '../../utils'

import * as types from './types'

function* getDocuments(action) {
  const {payload: id} = action
  try {
    const {payload} = yield call([API, API.get], 'documents', `/list/account/${id}`)
    yield put({
      type: types.GET_DOCUMENTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_DOCUMENTS_ERROR})
  }
}

function* getDocumentDigisignTemplates(action) {
  const accountId = action.payload
  try {
    const {payload} = yield call([API, API.get], 'digidoc', `/templates/account/${accountId}`)
    yield put({
      type: types.GET_DIGISIGNTEMPLATES_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_DIGISIGNTEMPLATES_ERROR})
  }
}

function* getDocwByAccount(action) {
  const accountID = action.payload
  try {
    const {payload} = yield call([API, API.get], 'docw', `/documents/account/${accountID}`)
    yield put({
      type: types.GET_DOCW_BY_ACCOUNT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_DOCW_BY_ACCOUNT_ERROR})
  }
}

function* getDocwByClient(action) {
  const clientID = action.payload
  try {
    const {payload} = yield call([API, API.get], 'docw', `/documents/client/${clientID}`)
    yield put({
      type: types.GET_DOCW_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_DOCW_ERROR})
  }
}

function* getEmailCustomTemplates(action) {
  const accountId = action.payload
  try {
    const {payload} = yield call([API, API.get], 'email-custom-templates', `/list-by-accountId/${accountId}`)
    yield put({
      type: types.GET_EMAILCUSTOMTEMPLATES_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_EMAILCUSTOMTEMPLATES_ERROR})
  }
}

function* saveEmailCustomTemplate(action) {
  const {
    payload: {
      emailCustomTemplate,
      successCb
    }
  } = action
  try {
    yield call([API, API.put], 'email-custom-templates', `/update/${emailCustomTemplate.id}`, {body: emailCustomTemplate})
    yield put({type: types.SAVE_EMAILCUSTOMTEMPLATE_SUCCESS})
    successCb()
    openNotificationWithIcon(
      'success',
      'Save Successful',
      'Your data has been saved successfully'
    )
  } catch ({response: {data: {error}}, message}) {
    yield put({type: types.SAVE_EMAILCUSTOMTEMPLATE_ERROR})
    openNotificationWithIcon(
      'error',
      'Error',
      error || message
    )
  }
}

export function* getEmailCustomTemplate(action) {
  try {
    const {payload: id} = action
    if (!id) {
      return {emailCustomTemplate: {}}
    }
    const {payload} = yield call(
      [API, API.get],
      'email-custom-templates', `/get-by-id/${id}`
    )
    yield put({
      type: types.GET_EMAILCUSTOMTEMPLATE_SUCCESS,
      payload
    })
    return {emailCustomTemplate: payload || {},}
  } catch (e) {
    const {response: {data: {error}}, message} = e
    yield put({type: types.GET_EMAILCUSTOMTEMPLATE_ERROR})
    openNotificationWithIcon('error', 'Failed to get document', error || message)
    throw e
  }
}

function* getDoctypeList() {
  try {
    const {payload} = yield call([API, API.get], 'documents', '/doctypes/list')
    yield put({
      type: types.GET_DOCTYPE_LIST_SUCCESS,
      payload
    })
  } catch ({response: {data: {error}}, message}) {
    openNotificationWithIcon(
      'error',
      'Error',
      error || message
    )
    yield put({type: types.GET_DOCTYPE_LIST_ERROR})
  }
}

export function* getDocument(itemID) {
  try {
    if (!itemID) {
      return {
        document: {},
        fileList: [],
        email: []
      }
    }
    const {
      files,
      document,
      emails
    } = yield call(
      [API, API.get],
      'documents', `/${itemID}`
    )

    return {
      document,
      fileList: files,
      email: emails
    }
  } catch (e) {
    const {response: {data: {error}}, message} = e
    openNotificationWithIcon('error', 'Failed to get document', error || message)
    throw e
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_DOCUMENTS_REQUEST, getDocuments),
    yield takeEvery(types.GET_DOCTYPE_LIST_REQUEST, getDoctypeList),
    yield takeEvery(types.GET_DOCW_REQUEST, getDocwByClient),
    yield takeEvery(types.GET_DOCW_BY_ACCOUNT_REQUEST, getDocwByAccount),
    yield takeEvery(types.GET_DIGISIGNTEMPLATES_REQUEST, getDocumentDigisignTemplates),
    yield takeEvery(types.GET_EMAILCUSTOMTEMPLATES_REQUEST, getEmailCustomTemplates),
    yield takeEvery(types.GET_EMAILCUSTOMTEMPLATE_REQUEST, getEmailCustomTemplate),
    yield takeEvery(types.SAVE_EMAILCUSTOMTEMPLATE_REQUEST, saveEmailCustomTemplate)
  ])
}