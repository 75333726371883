import React, {useEffect} from 'react'

import {connect} from 'react-redux'

import {
  startNewAccountCreation,
  updateNewAccountCreation
} from 'configs/actions/accounts'

import NewAccountSinglePage from './account_new/NewAccountSinglePage'

const AccountNewPageComponent = (
  {
    startNewAccountCreation,
    updateNewAccountCreation,
    accounts
  }
) => {
  // removed death code. Refactoring TBD

  useEffect(() => {
    startNewAccountCreation()
  }, [])

  if (!accounts.new_account_data) {
    return null
  }

  return <NewAccountSinglePage
    updateNewAccountCreation={updateNewAccountCreation}
    accounts={accounts}
  />
}

const mapStateToProps = state => {
  return {accounts: state.accounts}
}

const mapDispatchToProps = {
  startNewAccountCreation,
  updateNewAccountCreation
}

const AccountNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountNewPageComponent)

export default AccountNewPageComponentContainer
