import React, {
  useState,
  Fragment,
  useEffect
} from 'react'
import {
  connect,
  useDispatch
} from 'react-redux'

import moment from 'moment'
import {
  Button,
  Card,
  Skeleton,
  Descriptions,
  Drawer,
  Space,
  Row,
  Col,
  Timeline,
  Divider
} from 'antd'
import API from '@aws-amplify/api'
import {listHOC} from 'common'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {createUseStyles} from 'react-jss'
import ClientJournalCreateNewComponent from './ClientCreateNewJournal'
import {SyncOutlined} from '@ant-design/icons'

const useStyles = createUseStyles({
  flagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {marginLeft: '0.25rem'}
  }
})

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} leadsList
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */
const ClientJournal = ({
  getColumnSearchProps, journals, loading, clientId, currentAccountID, staffId, fetchCb, ...rest
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const toggleModal2 = bool => {
    setState({
      ...state,
      loading: bool
    })
    setIsModalVisible(bool)
  }
  const [state, setState] = useState({
    loading: true,
    journalDetail: {
      adminJournalType: '',
      clientId: null,
      id: null,
      journalTypeId: null,
      ipAddress: null,
      lead: null,
      leadId: null,
      memo: '',
      recordCreated: null,
      staff: null,
      staffId: null,
      title: '',
      linkedDocumentId: null,
      documentType: '',
      documentDescription: ''
    },
    isModalVisible: false,
    clientDetail: {
      first_name: '',
      last_name: ''
    },
    clients: [],
    leads: [],
    help: '',
    error: 'success',
    htmlContent: ''
  })

  useEffect(() => {
    if (id) {
      getClientsAndleads()
    }
  }, [id])

  const toggleModal = async bool => {
    setState({
      ...state,

      isModalVisible: bool
    })
  }

  const getClientsAndleads = async () => {
    try {
      const [clients, leads, journal] = await Promise.all([
        API.get('clients', `/getSimpleListByAccountId/${currentAccountID}`),
        API.get('leads', `/getSimpleListByAccountId/${currentAccountID}`),
        API.get('journals', `/byJournalId/${id}`)
      ])

      const clientInfo = await API.get('clients', `/get-by-id/${journal.payload.clientId}`)

      setState({
        ...state,
        leads: leads.payload,
        clients: clients.payload,
        loading: false,
        journalDetail: journal.payload,
        clientDetail: clientInfo.payload,
        htmlContent: journal.payload.memo
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const callLeads = () => {
    dispatch(getJournalByClientId(clientId))
  }

  const handle_create_new = () => {
    toggleModal(true)
  }

  const journalItems = journals.map(item => {
    return <Timeline.Item
      label={
        item.recordCreated
          ? moment(item.recordCreated).local().format('DD/MM/YYYY - HH:mm:ss')
          : null}
      onClick={()=>{
        toggleModal2(true)
        setId(item.id)
      }}
    >
      <strong>{item.adminJournalType}</strong><br/>
      <em>{item.staff ? <span>{item.staff}<br/></span> : null}</em>
      <em>{item.agents ? <span>{item.agents}<br/></span> : null}</em>
      {item.title}<br/>
      <a href='' onClick={(e)=>{
        e.preventDefault()
        toggleModal2(true)
        setId(item.id)
      }}>Click to view</a> full details
      <Divider/>
    </Timeline.Item>
  })

  return (
    <React.Fragment>
      <Row gutter={[96,24]}>
        <Col xs={24}>
          <Card
            style={{minHeight: 360}}
            title='Journals'
            extra={ state.deletedFlag !== 1
              ? [
                <Button key='1' onClick={handle_create_new}>
            Create New
                </Button>,
                <Button key='2' onClick={() => fetchCb(clientId)} icon={<SyncOutlined />} />
              ] : []}
          >
            <div style={{paddingTop: 24}}>
              <Skeleton active loading={loading}>
                <Timeline mode='left' reverse>
                  {journalItems}
                </Timeline>
              </Skeleton>
              <ClientJournalCreateNewComponent
                toggleModal={toggleModal}
                {...state}
                {...rest}
                asyncFunction={callLeads}
                clientId={clientId}
                currentAccountId={currentAccountID}
                staffId={staffId}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Drawer
        title={`Journal Detail [${id}]`}
        width={720}
        onClose={() => toggleModal2(false)}
        visible={isModalVisible}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal2(false)}>Cancel</Button>
            <Button onClick={() => toggleModal2(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Skeleton loading={state.loading}>
            <Descriptions layout='vertical' bordered>
              <Descriptions.Item label='Journal Type' span={2}>
                {state.journalDetail.adminJournalType}
              </Descriptions.Item>
              <Descriptions.Item label='IP Address' span={2}>
                {state.journalDetail.ipAddress}
              </Descriptions.Item>
              <Descriptions.Item label='Created' span={2}>
                {moment(state.journalDetail.recordCreated).local().format('DD/MM/YYYY - HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label='Client' span={2}>
                {state.journalDetail.clientId}
              </Descriptions.Item>
              <Descriptions.Item
                label='Client Name'
                span={2}
              >{`${state.clientDetail.first_name} ${state.clientDetail.last_name}`}</Descriptions.Item>
              <Descriptions.Item label='Journal Title' span={5}>
                {state.journalDetail.title}
              </Descriptions.Item>
              <Descriptions.Item label='Linked Document' span={6}>
                {state.journalDetail.linkedDocumentId}, {state.journalDetail.documentType}{' '}
                {state.journalDetail.documentDescription}
              </Descriptions.Item>
              <Descriptions.Item label='Journal Memo'>
                <div className={classes.contentViewer} dangerouslySetInnerHTML={{__html: state.htmlContent}} />
              </Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </Fragment>
      </Drawer>
    </React.Fragment>
  )
}

const {getJournalByClientId} = clientsActions

const mapStateToProps = state => {
  const {
    loading, journals, clientRecord
  } = clientsSelectors.clientsSelector(state)
  return {
    loading,
    journals,
    clientId: clientRecord.id
  }
}

export default connect(mapStateToProps, {fetchCb: getJournalByClientId})(listHOC(ClientJournal))
