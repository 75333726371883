import React from 'react'
import { Select } from 'antd'

import useClientDocuments from './useClientDocuments'

const { Option } = Select

const SelectDocuments = ({
  clientId, onChange, value
}) => {
  const [documents] = useClientDocuments(clientId)
  return (
    <Select
      showSearch
      showArrow
      value={value}
      placeholder='Select Document'
      loading={documents.loading}
      onChange={(v) => onChange(v)}
    >
      {documents?.documentsList?.length &&
                documents.documentsList.map(d => (
                  <Option key={d.id} value={d.id}>
                    {d.id}, {d.document_type_fileby} {d.description}
                  </Option>
                ))}
    </Select>
  )
}

export default React.memo(SelectDocuments)
