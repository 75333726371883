import {moduleName} from './types'

import {createSelector} from 'reselect'

import moment from 'moment'

/**
 * @param {Object} state
 * @returns {any|Array<any>}
 */
export const stateSelector = state => state[moduleName].toJS()

export const commissionsFullSelector = createSelector(stateSelector, (
  {
    commissionsFullList,
    loading
  }
) => {
  const list = commissionsFullList.map(arr => {
    const [
      accountID,
      ,
      ,
      dateTime,
      ID,
      ,
      ,
      ,
      ,
      instrument,
      ,
      ,
      amount
    ] = arr
    return {
      accountID,
      dateTime,
      ID,
      instrument,
      amount
    }
  })
  return {
    list,
    loading
  }
})
export const commissionsSumSelector = createSelector(stateSelector, (
  {
    commissionsSumList,
    loading
  }
) => {
  const list = Object
    .entries(commissionsSumList)
    .map(([accountID, {count, sum}]) => ({
      accountID,
      count,
      sum: sum.toFixed(2)
    }))
  return {
    list,
    loading
  }
})
export const tradingAccountsSelector = createSelector(stateSelector, (
  {
    tradingAccountsList,
    loading
  }
) => ({
  list: tradingAccountsList.map((
    {
      client_first_name,
      client_last_name,
      trading_platform_type_file_by,
      trading_platform_mode_file_by,
      ...rest
    }
  ) => ({
    ...rest,
    client: `${client_first_name || ''} ${client_last_name || ''}`,
    platform: `${trading_platform_type_file_by} ${trading_platform_mode_file_by}`
  })),
  loading
}))
export const tradingTransactionsSelector = createSelector(stateSelector, (
  {
    loading,
    tradingTransactionsList,
    tradingTransactionDetail,
    tradingTransactionsBtnValue
  }
) => ({
  loading,
  tradingTransactionDetail,
  tradingTransactionsBtnValue,
  list: tradingTransactionsList.map((
    {
      trading_platform_type_file_by,
      trading_platform_mode_file_by,
      clients_first_name,
      clients_last_name,
      record_created,
      debit,
      credit,
      ...rest
    }
  ) => ({
    ...rest,
    client: `${clients_first_name || ''} ${clients_last_name || ''}`,
    record_created: moment(record_created).format('YYYY-MM-DD HH:mm:ss'),
    debit: debit && debit.toFixed(2),
    credit: credit && credit.toFixed(2),
    tradingAccount: `${trading_platform_type_file_by} ${trading_platform_mode_file_by}`
  }))
}))
export const tradingAccountSelector = createSelector(stateSelector,
  ({
    loading,
    tradingAccountRecord
  }) => ({
    loading,
    tradingAccountRecord
  }))
/*
account_id: 5
credit: 55
debit: null
document_id: null
status_id: 2
trading_account_id: 3

 */
export const tradingTransactionSelector = createSelector(stateSelector,
  ({
    loading,
    tradingTransactionRecord: {
      record_created,
      trading_platform_mode_file_by,
      trading_platform_type_file_by,
      ...tradingTransactionRecord
    }
  }) => ({
    loading,
    tradingTransactionRecord: {
      ...tradingTransactionRecord,
      tradingAccount: `${trading_platform_type_file_by || ''} ${trading_platform_mode_file_by || ''}`,
      record_created: moment(record_created).format('YYYY-MM-DD HH:mm:ss')
    }
  }))
