import React, {Component} from 'react'
import {connect} from 'react-redux'
import {API} from 'aws-amplify'
import {
  FormattedMessage,
  injectIntl
} from 'react-intl'

import {appStateSelectors} from 'configs/ducks/appState'

import withStyles from 'react-jss'

import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input,
  Select,
  Typography
} from 'antd'

import {get_country_value} from 'library/countries'

const {Title} = Typography
const {Option} = Select

const styles = theme => ({
  form_label: {fontWeight: theme.label_font_weight},
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color
  }
})
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class UserAddress extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const user = await this.getUserProfile(this.props.currentUserID)
    console.log(user)
    this.setState({...user.payload})

    if (!this.state.street_address_country || !this.state.postal_address_country) {
      this.setState({has_suburb: 1})
    } else {
      const found = this.props.countriesList.find(
        element => element.id == this.state.street_address_country
      )
      console.log(found)
      this.setState({
        street_address_has_region: found.has_region,
        street_address_has_suburb: found.has_suburb,
        street_address_has_city: found.has_city
      })

      const foundPostal = this.props.countriesList.find(
        element => element.id == this.state.postal_address_country
      )
      this.setState({
        postal_address_has_region: foundPostal.has_region,
        postal_address_has_suburb: foundPostal.has_suburb,
        postal_address_has_city: foundPostal.has_city
      })
    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.currentUserID !== nextProps.currentUserID) {
      const user = await this.getUserProfile(nextProps.currentUserID)
      console.log(user)
      this.setState({...user.payload})
    }
  }

  async getUserProfile(user_id) {
    return API.get('staff', `/get/id/${user_id}`)
  }

  async saveUserProfile(user) {
    await API.put('staff', `/update/${this.state.id}`, {body: user})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value})
    // this.props.updateNewClientCreation(event.target.id, event.target.value);
  };

  handleSelectChange = (item_id, option, name) => {
    // console.log(item_id)
    // console.log(option)
    // console.log(name)

    const found = this.props.countriesList.find(element => element.id == item_id)
    console.log(found)

    if (name == 'street_address_country' && found.has_region) {
      this.setState({street_address_has_region: found.has_region})

      if (found.has_suburb != 1) {
        this.setState({street_address_has_suburb: ''})
      }

      if (found.has_city != 1) {
        this.setState({street_address_has_city: ''})
      }
    }

    if (name == 'street_address_country' && found.has_suburb) {
      this.setState({street_address_has_suburb: found.has_suburb})

      if (found.has_region != 1) {
        this.setState({street_address_has_region: ''})
      }

      if (found.has_city != 1) {
        this.setState({street_address_has_city: ''})
      }
    }

    if (name == 'street_address_country' && found.has_city) {
      this.setState({street_address_has_city: found.has_city})

      if (found.has_region != 1) {
        this.setState({street_address_has_region: ''})
      }

      if (found.has_suburb != 1) {
        this.setState({street_address_has_suburb: ''})
      }
    }

    if (name == 'postal_address_country' && found.has_region) {
      this.setState({postal_address_has_region: found.has_region})

      if (found.has_suburb != 1) {
        this.setState({postal_address_has_suburb: ''})
      }

      if (found.has_city != 1) {
        this.setState({postal_address_has_city: ''})
      }
    }

    if (name == 'postal_address_country' && found.has_suburb) {
      this.setState({postal_address_has_suburb: found.has_suburb})

      if (found.has_region != 1) {
        this.setState({postal_address_has_region: ''})
      }

      if (found.has_city != 1) {
        this.setState({postal_address_has_city: ''})
      }
    }

    if (name == 'postal_address_country' && found.has_city) {
      this.setState({postal_address_has_city: found.has_city})

      if (found.has_region != 1) {
        this.setState({postal_address_has_region: ''})
      }

      if (found.has_suburb != 1) {
        this.setState({postal_address_has_suburb: ''})
      }
    }

    this.setState({[name]: item_id})
  };
  handleSelectChange_street_address_country = value => {
    this.setState({street_address_country: value})
    //this.props.updateNewClientCreation("street_address_country", value);
  };

  handleSelectChange_postal_address_country = value => {
    this.setState({postal_address_country: value})
    //this.props.updateNewClientCreation("postal_address_country", value);
  };

  handleSubmit = async event => {
    event.preventDefault()
    // console.log(event)
    this.setState({isLoading: true})

    const {
      // aws_cognito_id,
      // first_name,
      // middle_name,
      // last_name,
      // email,
      street_address_line_1,
      street_address_line_2,
      street_address_suburb,
      street_address_city,
      street_address_region,
      street_address_state,
      street_address_postcode,
      street_address_country,
      postal_address_line_1,
      postal_address_line_2,
      postal_address_suburb,
      postal_address_city,
      postal_address_region,
      postal_address_state,
      postal_address_postcode,
      postal_address_country
    } = this.state
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        // first_name: first_name,
        // middle_name: middle_name,
        // last_name: last_name,
        street_address_line_1,
        street_address_line_2,
        street_address_suburb,
        street_address_city,
        street_address_region,
        street_address_state,
        street_address_postcode,
        street_address_country,
        postal_address_line_1,
        postal_address_line_2,
        postal_address_suburb,
        postal_address_city,
        postal_address_region,
        postal_address_state,
        postal_address_postcode,
        postal_address_country
      })
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveUserProfile({
          // aws_cognito_id: aws_cognito_id,
          // first_name: first_name,
          // middle_name: middle_name,
          // last_name: last_name,
          street_address_line_1,
          street_address_line_2,
          street_address_suburb,
          street_address_state,
          street_address_city,
          street_address_region,
          street_address_postcode,
          street_address_country,
          postal_address_line_1,
          postal_address_line_2,
          postal_address_suburb,
          postal_address_city,
          postal_address_region,
          postal_address_state,
          postal_address_postcode,
          postal_address_country
        })
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {

    const {classes} = this.props

    const country_list_select_options = this.props.countriesList.map(d => (
      <Option key={d.id}>{d.full_name}</Option>
    ))

    return (
      <React.Fragment>
        <Form layout='vertical'>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Street Address</Title>
              <Form.Item label='Country'>
                <Select
                  id='street_address_country'
                  showSearch
                  value={get_country_value(this.props.countriesList, this.state.street_address_country)}
                  placeholder='Select Country'
                  showArrow={true}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={(event, option) => this.handleSelectChange(event, option, 'street_address_country')}
                >
                  {country_list_select_options}
                </Select>
              </Form.Item>
              <Form.Item label='Line 1'>
                <Input
                  id='street_address_line_1'
                  value={this.state.street_address_line_1}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Line 2'>
                <Input
                  id='street_address_line_2'
                  value={this.state.street_address_line_2}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                {/*<Form.Item label="Suburb"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="street_address_suburb"
                      value={this.state.street_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                    </Form.Item>*/}
                <React.Fragment>
                  {Boolean(this.state.street_address_has_region) && (
                    <Col xs={12}>
                      <Form.Item
                        label={<FormattedMessage id='data_new_client_page.region' defaultMessage='Region' />}
                        className={classes.form_label}
                        // style={{ marginBottom: 0 }}
                      >
                        <Input
                          id='street_address_region'
                          value={this.state.street_address_region}
                          onChange={event => {
                            this.handleChange(event)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {Boolean(this.state.street_address_has_city) && (
                    <Col xs={12}>
                      <Form.Item
                        label={<FormattedMessage id='data_new_client_page.city' defaultMessage='City' />}
                        className={classes.form_label}
                        // style={{ marginBottom: 0 }}
                      >
                        <Input
                          id='street_address_city'
                          value={this.state.street_address_city}
                          onChange={event => {
                            this.handleChange(event)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {Boolean(this.state.street_address_has_suburb) && (
                    <Col xs={12}>
                      <Form.Item
                        label={
                          <FormattedMessage id='data_new_client_page.street_address_suburb' defaultMessage='Suburb' />
                        }
                        className={classes.form_label}
                        // style={{ marginBottom: 0 }}
                      >
                        <Input
                          id='street_address_suburb'
                          value={this.state.street_address_suburb}
                          onChange={event => {
                            this.handleChange(event)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </React.Fragment>
                <Col xs={8}>
                  <Form.Item label='State'>
                    <Input
                      id='street_address_state'
                      value={this.state.street_address_state}
                      onChange={event => {
                        this.handleChange(event)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label='PostCode' style={{marginBottom: 0}}>
                    <Input
                      id='street_address_postcode'
                      value={this.state.street_address_postcode}
                      onChange={event => {
                        this.handleChange(event)
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Title level={4}>Postal Address</Title>
              <Form.Item label='Country'>
                <Select
                  id='postal_address_country'
                  showSearch
                  value={get_country_value(this.props.countriesList, this.state.postal_address_country)}
                  placeholder='Select Country'
                  showArrow={true}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={(event, option) => this.handleSelectChange(event, option, 'postal_address_country')}
                >
                  {country_list_select_options}
                </Select>
              </Form.Item>
              <Form.Item label='Line 1'>
                <Input
                  id='postal_address_line_1'
                  value={this.state.postal_address_line_1}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Form.Item label='Line 2'>
                <Input
                  id='postal_address_line_2'
                  value={this.state.postal_address_line_2}
                  onChange={event => {
                    this.handleChange(event)
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <React.Fragment>
                  {Boolean(this.state.postal_address_has_region) && (
                    <Col xs={12}>
                      <Form.Item
                        label={<FormattedMessage id='data_new_client_page.region' defaultMessage='Region' />}
                        className={classes.form_label}
                        // style={{ marginBottom: 0 }}
                      >
                        <Input
                          id='postal_address_region'
                          value={this.state.postal_address_region}
                          onChange={event => {
                            this.handleChange(event)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {Boolean(this.state.postal_address_has_city) && (
                    <Col xs={12}>
                      <Form.Item
                        label={<FormattedMessage id='data_new_client_page.city' defaultMessage='City' />}
                        className={classes.form_label}
                        // style={{ marginBottom: 0 }}
                      >
                        <Input
                          id='postal_address_city'
                          value={this.state.postal_address_city}
                          onChange={event => {
                            this.handleChange(event)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {Boolean(this.state.postal_address_has_suburb) && (
                    <Col xs={12}>
                      <Form.Item
                        label={
                          <FormattedMessage id='data_new_client_page.postal_address_suburb' defaultMessage='Suburb' />
                        }
                        className={classes.form_label}
                        // style={{ marginBottom: 0 }}
                      >
                        <Input
                          id='postal_address_suburb'
                          value={this.state.postal_address_suburb}
                          onChange={event => {
                            this.handleChange(event)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </React.Fragment>
                <Col xs={8}>
                  <Form.Item label='State'>
                    <Input
                      id='postal_address_state'
                      value={this.state.postal_address_state}
                      onChange={event => {
                        this.handleChange(event)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label='PostCode' style={{marginBottom: 0}}>
                    <Input
                      id='postal_address_postcode'
                      value={this.state.postal_address_postcode}
                      onChange={event => {
                        this.handleChange(event)
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[96, 24]}>
            <Col xs={24} style={{textAlign: 'right'}}>
              <Button type='primary' htmlType='submit' onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {
    currentUserRecord: {id: currentUserID},
    countriesList
  } = appStateSelectors.stateSelector(state)
  return {
    currentUserID,
    countriesList,
  }
}

const UserAddressContainer = connect(
  mapStateToProps
)(withStyles(styles, {injectTheme: true})(injectIntl(UserAddress)))

export default UserAddressContainer
