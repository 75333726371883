import React, {
  Fragment,
  useState
} from 'react'
import {API} from 'aws-amplify'
import {
  Button,
  Col,
  Row,
  Table,
  Form,
  Input
} from 'antd'
import {NavLink} from 'react-router-dom'
import {routes} from 'configs'

import {useForm} from 'antd/lib/form/Form'
import {showNotification} from 'utils'

import {VerificationTrulioResults} from '../../common'
import ClientVerificationNewPageComponent from './ClientNewVerification'
import {FormSelect} from 'common'
const { TextArea } = Input

const ClientVerification = ({
  getVerificationInfo,
  verificationList,
  verificationData,
  getVerificationList,
  trulioData,
  clientRecord,

}) => {
  const [state, setState] = useState({
    verificationProvider: null,
    verificationReference: null
  })
  const [modal, setModal] = useState({isModalVisible: false})
  const [form] = useForm()

  const onDone = (values) => {
    onFinish(values)
  }

  const onFinish = async values => {
    values['verificationProvider'] = state.verificationProvider
    values['verificationReference'] = state.verificationReference

    try {
      await API.put('clients', `/simple-update/${clientRecord.id}`, {
        body: {
          verificationProvider: state.verificationProvider,
          verificationReference: state.verificationReference
        }
      })
      showNotification('success', 'Clients', 'Successfully Updated')
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Clients', error.message)
    }
    toggleModal(false)
  }

  const toggleModal = bool => {
    setModal({
      ...modal,
      isModalVisible: bool
    })
  }

  const columns = [
    // {
    //   title: 'Record ID',
    //   dataIndex: 'id',
    //   render: id => <a onClick={() => getVerificationInfo(id)}>{id}</a>
    // },
    {
      title: 'Date',
      dataIndex: 'request_datetime'
    },
    {
      title: 'eApplicationForm ID',
      dataIndex: 'eafID',
      render: (text, record) => <a
        target='_blank' 
        href={`https://admin.eapplicationform.com/verify-internal/${record.eafID}/details?applicantID=${record.eafApplicantID}&accountID=27`}>
        {record.eafID}-{record.eafApplicantID}
      </a>
    },
    {
      title: 'Status',
      dataIndex: 'eafStatus'
    },
  ]

  let currentStatusText
  switch (clientRecord.statusVerification) {
    case 1:
      currentStatusText = 'COMPLETED'
      break
    case 2:
      currentStatusText = 'COMPLETED'
      break
    case 3:
      currentStatusText = 'COMPLETED'
      break
  }

  const handleSelectChange = (id, values) => {
    setState({
      ...state,
      verificationProvider: values.id
    })
  }

  const handleTextChange = (e) => {
    setState({
      ...state,
      verificationReference: e.target.value
    })
  }

  return (
    <Fragment>
      {/* <Form
        form={form}
        layout='vertical'
        onFinish={onDone}
      >

        <Row>
          <Col xs={12}>
            <Form.Item label='Verification Provider' name='verificationProvider' initialValue={clientRecord.verificationProvider === 1 ? 'Trulioo' : clientRecord.verificationProvider === 2 ? 'Comply Advantage' : ''}>
              <FormSelect
                name='verificationProvider'
                placeholder='Select Verification Provider'
                optionValue='id'
                // getOptionProps={getOptionProps}
                options={[{
                  id: 1,
                  value: 'Trulioo'
                }, {
                  id: 2,
                  value: 'Comply Advantage'
                }]}
                onChange={handleSelectChange}
                initialValue={clientRecord.verificationProvider}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label='Reference' name='verificationReference' initialValue={clientRecord.verificationReference}>
              <TextArea onChange={handleTextChange}/>
            </Form.Item>
          </Col>
        </Row>
      </Form> */}
      <Table
        rowKey='id'
        columns={columns}
        dataSource={verificationList}
        scroll={{x: true}}
        pagination={{
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true,
          defaultPageSize: 5
        }}
      />
      <Row style={{textAlign: 'right'}}>
        <Col offset={18} xs={3}>
          {/* <NavLink to={routes.verificationsNew(clientRecord.id)}>
            <Button type='primary'>Create Verification</Button>
          </NavLink> */}
        </Col>
        <Col xs={2}>
          {/* <Button
            type='primary'
            htmlType='button'
            onClick={() => {
              toggleModal(true)
            }}
          >
            Create Record
          </Button> */}
        </Col>
        <Col xs={2}>
          {/* <Button
            htmlType='button'
            type='primary'
            onClick={onFinish}
          >
            Save Record
          </Button> */}
        </Col>
      </Row>
      <VerificationTrulioResults verificationData={verificationData} trulioData={trulioData} />
      <ClientVerificationNewPageComponent
        {...modal}
        toggleModal={toggleModal}
        clientRecord={clientRecord}
        getVerificationList={getVerificationList}
      />
    </Fragment>
  )
}

export default ClientVerification
