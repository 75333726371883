import React, {
  useState,
  useEffect
} from 'react'
import {
  connect,
  useSelector
} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {
  Button,
  Row,
  Tabs,
  Col
} from 'antd'

import {
  StaffClientSummary,
  StaffClientContact,
  StaffClientForm
} from '../common'

import queryString from 'query-string'
import {clearState} from 'configs/actions/common'
import {
  staffsActions,
  staffsSelectors
} from 'configs/ducks/staffs'
import {appStateSelectors} from 'configs/ducks/appState'
import {FormattedMessage} from 'react-intl'
import Security from './security/Security'
import StaffClientAddress from './staff-client-address/StaffClientAddress'

const {TabPane} = Tabs

const routes = [
  {
    path: '/staff/list',
    breadcrumbName: 'Staff List',
  },
  {
    path: 'first',
    breadcrumbName: 'View Details',
  }
]

const StaffViewPageComponent = ({
  accountsList,
  countriesList,
  getStaffRecord,
  updateStaffRecord,
  currentStaff,
  getTopics,
  history,
  match: {params: {id}},
  clearState,
  loading
}) => {
  const {search} = useLocation()
  const [state, setState] = useState({
    activeTab: queryString.parse(search).tab,
    currentStep: 0
  })
  useEffect(() => {
    setState({
      ...state,
      activeTab: search ? queryString.parse(search).tab : 'summary'
    })
  }, [search])
  useEffect(() => {
    getStaffRecord(id)
    getTopics({
      title: 'Staff Management',
      breadCrumb: routes,
    })
    return clearState
  }, [])

  const onTabClick = e => {
    history.push(`/staff/view/${id}?tab=${e}`)
  }
  const onSubmit = values => {
    
    updateStaffRecord({
      ...currentStaff,
      ...values
    })
  }
  const adminStaff = useSelector(state => state.appState.currentStaffRecord)
  
  return (
    <StaffClientForm mounting={!currentStaff.id} loading={loading} initialValues={currentStaff} onSubmit={onSubmit}>
      {form => (
        <>
          <Tabs activeKey={state.activeTab} onTabClick={onTabClick}>
            <TabPane tab='Staff Summary' key='summary'>
              <StaffClientSummary accountsList={accountsList} id={id} getStaffRecord={getStaffRecord}/>
            </TabPane>
            <TabPane tab='Contact' key='contact'>
              <Row gutter={50}>
                <Col span={8}>
                  <StaffClientContact
                    onPhoneInputChange={id => value => {
                      form.setFieldsValue({[id]: value})
                    }}
                  />
                </Col>
                <Col span={16}>
                  <StaffClientAddress
                    form={form}
                    countriesList={countriesList}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab='Security' key='security' disabled={!adminStaff?.security_staff_canSetSecurity}>
              <Security staff={currentStaff} getStaffRecord={getStaffRecord}/>
            </TabPane>
          </Tabs>
          <Row justify='end'>
            <Button type='primary' htmlType='submit'>
              <FormattedMessage id='components.save_record' />
            </Button>
          </Row>
        </>
      )}
    </StaffClientForm>
  )
}

const mapStateToProps = state => {
  const {loading, currentStaff} = staffsSelectors.stateSelector(state)
  const {accountsList, countriesList} = appStateSelectors.stateSelector(state)
  return {
    loading,
    currentStaff,
    accountsList,
    countriesList
  }
}

const {updateStaffRecord, getStaffRecord} = staffsActions
export default connect(mapStateToProps, {
  getStaffRecord,
  updateStaffRecord,
  clearState
})(StaffViewPageComponent)