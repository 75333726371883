import * as React from 'react'
import {
  Checkbox,
  Form,
  FormItemProps
} from 'antd'
import {FormattedMessage} from 'react-intl'

interface InputCheckboxProps extends Omit<FormItemProps, 'label'> {
  label: string;
  labelValues?: Record<string, any>;
  name: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  label, labelValues, required = false, ...props
}) => {
  return (
    <Form.Item
      {...props}
      valuePropName='checked'
      rules={[
        {
          required,
          validator: (rule, value) => {
            // for some reason antd doesn't see this for checkbox
            if (!required) {
              return Promise.resolve()
            }

            if (value === undefined || value === null) {
              return Promise.reject(<FormattedMessage id='validation.required' />)
            }

            return Promise.resolve()
          }
        }
      ]}
    >
      <Checkbox>
        <FormattedMessage id={label} values={labelValues} />
      </Checkbox>
    </Form.Item>
  )
}

export default InputCheckbox
