import React, {Component} from 'react'
import { API } from 'aws-amplify'

import {
  Row,
  Col,
  Form,
  Skeleton,
  Spin
} from 'antd'

class TradingTransactionsTradingAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      tradingAccountByID: [],
      loading: true
    }
  }

  async componentDidMount() {
    try {
      const TradingTransaction = await this.getTradingTransactionsRecord(this.props.id)
      this.setState({
        ...TradingTransaction.payload,
        loading: true
      })
      const {tradingAccountID} = this.state
      await API.get('trading-accounts', `/id/${tradingAccountID}`).then(
        response => {
          this.setState({
            ...this.state,
            tradingAccountByID: response.payload
          })
        }
      )
    } catch (e) {
      console.log(e)
    }
    this.setState({loading: false})
  }

  async getTradingTransactionsRecord(tradingTransactionID) {
    return API.get('trading-transactions-2', `/id/${tradingTransactionID}`)
  }

  render() {
    return (
      <React.Fragment>
        <Skeleton active loading={this.state.loading}>
          <Spin spinning={this.state.loading}>
            <Form layout='vertical' onSubmit={this.onHandleSubmit}>
              <Row gutter={[96, 24]}>
                <Col xs={12}>
                  <Form.Item label='Trading Account'>
                    {this.state.tradingAccountByID?.trading_platform_account_id}
                  </Form.Item>
                  <Form.Item label='Currency'>
                    {this.state.tradingAccountByID?.currencies_iso_alpha_3}
                  </Form.Item>
                  <Form.Item label='Trading Platform Account ID'>
                    {this.state.tradingAccountByID?.trading_platform_account_id}
                  </Form.Item>
                  <Form.Item label='Trading Platform User Name'>
                    {this.state.tradingAccountByID?.tradingPlatformUsername}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Skeleton>
      </React.Fragment>
    )
  }
}

export default TradingTransactionsTradingAccount
