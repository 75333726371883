import React, {
  useEffect,
  useState
} from 'react'
import {FormattedMessage} from 'react-intl'
import {
  Form,
  Button,
  Checkbox,
  Typography,
  Skeleton,
  notification,
  Row,
  Col,
  Input,
  InputNumber
} from 'antd'
import {useSelector} from 'react-redux'
import {useForm} from 'antd/lib/form/Form'
import {API} from 'aws-amplify'
import {tradingTransactionSelector} from 'configs/ducks/trading/selectors'
import OrganiserCreateNewTaskPageComponent from '../../organiser/OrganiserCreateNewTaskPageComponent'

const RequireInfoFromClient = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModal = bool => setIsModalVisible(bool)
  const [submitting, setSubmitting] = useState(false)
  const [enableReconciled, setEnableReconciled] = useState(true)
  const account_id = useSelector(state => state.appState?.currentStaffRecord?.account_id)
  const currentStaffRecord = useSelector(state => state.appState?.currentStaffRecord)
  const {tradingTransactionRecord, loading} = useSelector(tradingTransactionSelector)
  const {
    id, accountFinancialAccountTransactionId, transactionDate
  } = (tradingTransactionRecord || {})
  const [form] = useForm()

  const onFinish = async values => {
    try {
      setSubmitting(true)
      await API.put('trading-transactions', '/update-flag-required-info', {
        body: {
          flagRequireInfoFromClient: values.flagRequireInfoFromClient,
          reconciledFlag: values.reconciledFlag,
          accountFinancialAccountTransactionId: values.accountFinancialAccountTransactionId,
          transaction_id: id,
          reconciledStaffId: account_id,
          transactionDate,
          teOperationId: values.teOperationId,
          reconciledDatetime: new Date().toISOString(),
          debit: tradingTransactionRecord.debit,
          credit: tradingTransactionRecord.credit
        }
      })
      notification.success({message: 'Saved!'})
    } catch (e) {
      notification.error({message: e.message})
    } finally {
      setSubmitting(false)
    }
  }
  
  const onValuesChange = (_value, allValues) => {
    const { teOperationId, accountFinancialAccountTransactionId } = allValues
    if (teOperationId && accountFinancialAccountTransactionId) {
      setEnableReconciled(true)
    } else {
      setEnableReconciled(false)
    }
  }

  useEffect(() => {
    if (tradingTransactionRecord?.teOperationId && tradingTransactionRecord?.accountFinancialAccountTransactionId ) {
      setEnableReconciled(true)
    } else {
      setEnableReconciled(false)
    }
  }, [tradingTransactionRecord?.teOperationId, tradingTransactionRecord?.accountFinancialAccountTransactionId])

  useEffect(() => {
    form.setFieldsValue({
      flagRequireInfoFromClient: tradingTransactionRecord?.flagRequireInfoFromClient === 1,
      reconciledFlag: Boolean(tradingTransactionRecord?.reconciledFlag),
      accountFinancialAccountTransactionId: tradingTransactionRecord?.accountFinancialAccountTransactionId,
      teOperationId: tradingTransactionRecord?.teOperationId
    })
  }, [tradingTransactionRecord, form, accountFinancialAccountTransactionId])

  return (
    <Skeleton loading={loading}>
      <Form layout='vertical' onValuesChange={onValuesChange} loading={submitting} form={form} onFinish={onFinish} requiredMark={false}>
        <Row>
          <Col span={6}>
            <Typography.Title level={4}>
              <FormattedMessage id='trading_transaction_detail.transaction' />
            </Typography.Title>
            <Form.Item name='flagRequireInfoFromClient' valuePropName='checked'>
              <Checkbox>
                <FormattedMessage id='trading_transaction_detail.require_info_from_client' />
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Typography.Title level={4}>
              <FormattedMessage id='trading_transaction_detail.action' />
            </Typography.Title>
            <Button onClick={() => toggleModal(true)}>
              <FormattedMessage id='trading_transaction_detail.newStaffTask' />
            </Button>
            <OrganiserCreateNewTaskPageComponent
              isModalVisible={isModalVisible}
              toggleModal={toggleModal}
              staffId={currentStaffRecord.id}
              defaultClient={tradingTransactionRecord.clients_id}
              currentAccountID={account_id}
              id={account_id}
              fetchC={() => {}}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>
              <FormattedMessage id='trading_transaction_detail.reconciliation' />
            </Typography.Title>
            <Form.Item
              name='reconciledFlag'
              valuePropName='checked'
              help="Transaction cannot be reconciled until below fields are completed"
            >
              <Checkbox disabled={!enableReconciled}>
                <FormattedMessage id='trading_transaction_detail.reconciled' />
              </Checkbox>
            </Form.Item>
            <Form.Item
              name='accountFinancialAccountTransactionId'
              label={<FormattedMessage id='trading_transaction_detail.financial_account_transaction' />}
            >
              <Input type='number' />
            </Form.Item>
            <Form.Item
              name='teOperationId'
              label={<FormattedMessage id='trading_transaction_detail.te_operation_id' />}
              help="Remember TE deposit will be 96% if USDT deposit"
            >
              <InputNumber style={{width: '100%'}} precision={0} min={1} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{textAlign: 'right'}}>
          <Button loading={submitting} type='primary' htmlType='submit'>
            <FormattedMessage id='trading_transaction_detail.save' />
          </Button>
        </Form.Item>
      </Form>
    </Skeleton>
  )
}

export default RequireInfoFromClient