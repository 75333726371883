import reducer from './reducers'

import * as docCheckSelectors from './selectors'
import * as docCheckActions from './actions'
import * as docCheckTypes from './types'
import docCheckSaga from './sagas'

export {
  docCheckSaga,
  docCheckTypes,
  docCheckActions,
  docCheckSelectors,
}

export default reducer