import * as React from 'react'
import {
  Space,
  notification,
  Button,
  Tooltip,
  PlusOutlined,
} from 'components'
import FileRenderer from '../file-renderer'
import {
  API,
  showNotification
} from 'utils'
import {routes} from 'configs'
import {XOR} from 'types'
import {operations} from '../../../duck'

interface CreateDocumentProps {
  kind: 'edd' | 'poa';
  client_id: number | string;
  informationRequestId: number | string;
}

interface WithFile extends CreateDocumentProps {
  url: string;
  contentType: string;
}

const CreateDocument: React.FC<XOR<CreateDocumentProps, WithFile>> = (
  {
    url,
    client_id,
    informationRequestId,
    kind,
    contentType
  }
) => {
  const [state, setState] = React.useState({loading: false})

  if (url) {
    const onClick = async () => {
      try {
        setState(prev => ({
          ...prev,
          loading: true
        }))

        const fileID = await operations.createFileFromURL(url)

        let description

        if (kind === 'edd') {
          description = `EDD submitted in Info Request #${informationRequestId}`
        } else {
          description = `POA submitted in Info Request #${informationRequestId}`
        }

        const {document} = await API.post('documents', '/create', {
          body: {
            client_id,
            description,
            document_type_id: 2,
            files: [
              fileID
            ],
          }
        })

        setState(prev => ({
          ...prev,
          loading: false
        }))

        const link = <a href={routes.documentsView(document.id)} target='_blank' rel="noreferrer">View {document.id}</a>

        notification.success({message: link})
      } catch (e: any) {
        showNotification('error', 'Error', e?.response?.data?.error || e.message)

        setState(prev => ({
          ...prev,
          loading: false
        }))
      }

    }

    return (
      <Space>
        <FileRenderer url={url} contentType={contentType} />

        <Tooltip title='Create Document'>
          <Button
            icon={<PlusOutlined/>}
            onClick={onClick}
            loading={state.loading}
          />
        </Tooltip>
      </Space>
    )
  }

  return <span>No file uploaded</span>
}

export default CreateDocument
