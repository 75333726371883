import reducer from './reducers'

import * as leadsNewSelectors from './selectors'
import * as leadsNewActions from './actions'
import * as leadsNewTypes from './types'
import leadsNewSaga from './sagas'

export {
  leadsNewSaga,
  leadsNewTypes,
  leadsNewActions,
  leadsNewSelectors,
}

export default reducer