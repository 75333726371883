import React, {
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Checkbox,
  Form,
  Input,
  Button,
  InputDatePicker,
  FormInputNumber,
  Radio,
  Typography
} from 'components'
import {Editor} from 'react-draft-wysiwyg'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'

import useCreateTransaction from '../hooks/useCreateTransaction'
import useUpdateTransaction from '../hooks/useUpdateTransaction'

const AccountTransactionDrawer = ({
  onSaveSuccess, isUpdate = false, transaction
}) => {
  const {id} = useParams()
  const account_id = useSelector(state => state.appState?.currentStaffRecord?.account_id)
  const [form] = Form.useForm()
  const [state, doCreate] = useCreateTransaction()
  const [stateUpdate, doUpdate] = useUpdateTransaction(transaction?.id)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, setEditorText] = useState('')

  useEffect(() => {
    if (transaction?.reconciledComments) {
      const blocksFromHTML = convertFromHTML(transaction.reconciledComments)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      )
      setEditorState(EditorState.createWithContent(state))
    }
  }, [transaction?.reconciledComments, setEditorState])

  const onEditorStateChange = editor => {
    setEditorState(editor)
    setEditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }

  const onFinish = async values => {
    const params = {}
    const {
      createdDate, type, amount, memo, reconciledFlag, tradingAccountTransactionId
    } = values

    params[type] = amount
    params.memo = memo
    params.createdDate = createdDate.toISOString()
    params.tradingAccountTransactionId = tradingAccountTransactionId
    params.reconciledFlag = reconciledFlag

    if (reconciledFlag) {
      params.reconciledStaffId = account_id
      params.reconciledDatetime = new Date().toISOString()
    }
    params.reconciledComments = editorText

    if (isUpdate) {
      await doUpdate(params)
    } else {
      params.account_financialAccountId = id
      await doCreate(params)
    }
    onSaveSuccess && onSaveSuccess()
  }

  let initialValues = {
    createdDate: moment(),
    type: 'debit'
  }

  if (isUpdate) {
    const {
      createdDate, credit, debit, memo, tradingAccountTransactionId, reconciledFlag
    } = transaction
    initialValues = {
      createdDate: createdDate ? moment(createdDate) : moment(),
      type: credit ? 'credit' : 'debit',
      amount: credit || debit,
      memo,
      tradingAccountTransactionId,
      reconciledFlag: Boolean(reconciledFlag)
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      name='register'
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <InputDatePicker
        required
        name='createdDate'
        label='trading_transaction_detail.transaction_details'
      />
      <Form.Item
        name='memo'
        label='Description'
        rules={[
          {
            required: true,
            message: 'Please input description!'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='type'>
        <Radio.Group>
          <Radio value='debit'>Debit</Radio>
          <Radio value='credit'>Credit</Radio>
        </Radio.Group>
      </Form.Item>
      <FormInputNumber
        required
        name='amount'
        label='Amount'
        min={0}
        max={1000000}
        precision={2}
        step={0.1}
      />

      <Typography.Title level={4}>Reconciliation</Typography.Title>
      <Row>
        <Col span={12}>
          <div style={{ paddingTop: 23 }}>
            <Form.Item name="reconciledFlag" valuePropName="checked">
              <Checkbox>Reconciled</Checkbox>
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <FormInputNumber
            required
            name='tradingAccountTransactionId'
            label='Trading Transaction'
            min={0}
            max={1000000}
            precision={0} step={1}
          />
        </Col>
      </Row>
      <Form.Item
        name='reconciledComments'
        label='Comments'
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          editorStyle={{
            border: '1px solid #F1F1F1',
            borderRadius: '2px',
            paddingLeft: '10px',
            paddingRight: '10px',
            height: '250px'
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type='primary' loading={state.loading || stateUpdate.loading} htmlType='submit'>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AccountTransactionDrawer
