import * as React from 'react'

import {
  ExtendedDescriptions,
  Empty,
  Table
} from 'components'
import {
  CreateDocument,
  SectionTitle
} from '../shared'

interface EddDocumentsProps {
  clientID: number | string;
  informationRequestID: number | string;
  eddDocuments: Array<{
    description: string;
    contentType: string;
    url: string;
  }>
}

const EddDocuments: React.FC<EddDocumentsProps> = ({
  clientID,
  informationRequestID,
  eddDocuments
}) => {

  return (
    <>
      <SectionTitle>
        Edd Documents
      </SectionTitle>
      <Table
        dataSource={eddDocuments}
        columns={[
          {
            title: 'Description',
            dataIndex: 'description'
          },
          {
            title: 'Content Type',
            dataIndex: 'contentType'
          },
          {
            title: 'Action',
            dataIndex: 'contentType',
            render: (_, doc) => (
              <CreateDocument
                kind='edd'
                url={doc.url}
                contentType={doc.contentType}
                client_id={clientID}
                informationRequestId={informationRequestID}
              />
            )
          },
        ]}
      />
    </>
  )
}

export default EddDocuments
