import React from 'react'
import { useSelector } from 'react-redux'
import {
  Table,
  Button
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import {listHOC} from 'common'
import useClientWatching from './useClientWatching'

const ClientWatchingPage = ({ getColumnSearchProps }) => {
  const {
    state, update, updateState
  } = useClientWatching()
  const columns = [
    {
      title: 'Client ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
      })
    },
    {
      title: 'Client Name',
      dataIndex: 'file_by',
      ...getColumnSearchProps({
        dataIndex: 'file_by',
        placeholder: 'Name',
      })
    },
    {
      title: 'Client Email',
      dataIndex: 'email',
      ...getColumnSearchProps({
        dataIndex: 'email',
        placeholder: 'Email',
      })
    },
    {
      title: '',
      render: (_, record) => {
        return (
          <Button shape="circle" onClick={() => update(record.id)} type="danger" icon={<DeleteOutlined />} />
        )
      }
    }
  ]
  return (
    <div>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={state?.value}
        loading={state.loading || updateState.loading}
        scroll={{x: true}}
      />
    </div>
  )
}

export default listHOC(ClientWatchingPage)
