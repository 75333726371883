import React, {
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Spin
} from 'antd'

import {
  listHOC,
  FormSelect
} from 'common'
import {inputChange} from 'configs/actions/common'
import {
  verificationsActions,
  verificationsSelectors
} from 'configs/ducks/verifications'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients'
import {appStateSelectors} from 'configs/ducks/appState'
import Modal from 'antd/lib/modal/Modal'

const ClientNewVerification = ({
  loading,
  verificationData,
  verificationData: {trulioo_record_id},
  inputChange,
  createVerification,
  getVerificationList,
  toggleModal,
  clientRecord,
  isModalVisible
}) => {
  const history = useHistory()

  const [state, setState] = useState({
    client_id: clientRecord.id,
    trulioo_record_id,
    trulioo_transaction_id: '',
    confirmLoading: false
  })
  const toggle = bool => {
    setState({
      ...state,
      confirmLoading: bool
    })
  }
  let saveIsDisabled = true

  if (state.trulioo_record_id || state.trulioo_transaction_id) {
    saveIsDisabled = false
  }
  const onSubmit = () => {
    toggle(true)
    verificationData.client_id = state.client_id
    verificationData.trulioo_record_id = state.trulioo_record_id
    verificationData.trulioo_transaction_id = state.trulioo_transaction_id
    !saveIsDisabled &&
      createVerification({
        ...verificationData,
        request_datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
        successCb: () => {
          history.push(`/clients/view/${state.client_id}?tab=verification`)
          getVerificationList(state.client_id)
          toggleModal(false)
        }
      })
  }

  const onValuesChange = changed => {
    const [key, value] = Object.entries(changed)[0]
    inputChange(key, value)

    setState({
      ...state,
      [key]: value
    })
  }

  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => toggleModal(false)}
      confirmLoading={state.confirmLoading}
      title='Link existing Trulioo Verification'
      footer={
        <Row gutter={[96, 24]}>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button type='primary' onClick={onSubmit} htmlType='submit' disabled={saveIsDisabled}>
              Save Record
            </Button>
          </Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout='vertical' onFinish={onSubmit} onValuesChange={onValuesChange}>
          <Row gutter={[96, 24]}>
            <Col xs={24}>
              <Form.Item label='Client' name='client_id' initialValue={clientRecord.id}>
                <Input disabled />
              </Form.Item>
              <Form.Item label='Trulioo Transaction Record Id' name='trulioo_record_id'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

const mapStateToProps = state => {
  const {loading, verificationData} = verificationsSelectors.stateSelector(state)

  return {
    verificationData,
    loading: loading,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id
  }
}

const mapDispatchToProps = {
  createVerification: verificationsActions.createVerification,
  inputChange
}

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(ClientNewVerification))