import * as React from 'react'
import {
  Form,
  FormItemProps
} from 'antd'
import PhoneInput2 from 'react-phone-input-2'
import {
  useSelector,
  useIntl
} from 'hooks'

interface PhoneInputProps {
  value?: string | null;
  onChange?: (v: any) => void;
}

const CustomPhoneInput: React.FC<PhoneInputProps> = ({ value, onChange }) => {
  const language = useSelector((state) => state.language.language_current_ui)
  const [defaultCountryCode, setCountryCode] = React.useState<string>(language === 'zh' ? 'cn' : 'au')

  const onInputChange = (
    newValue: string,
    countryData: { countryCode: string }
  ) => {
    if (onChange) {
      onChange(newValue)
    }

    if (countryData.countryCode !== defaultCountryCode) {
      setCountryCode(countryData.countryCode)
    }
  }

  const country = !value ? { country: defaultCountryCode } : {}
  const preferredCountries =
    language === 'zh'
      ? ['cn', 'tw', 'sg', 'us', 'au']
      : ['au', 'us', 'sg', 'cn', 'tw']

  return (
    <PhoneInput2
      {...country}
      inputStyle={{ width: '100%' }}
      enableSearch
      key={language}
      value={value}
      onChange={onInputChange}
      preferredCountries={preferredCountries}
      preserveOrder={['preferredCountries']}
      masks={{ au: '(.) ....-....' }}
    />
  )
}

const PhoneInput: React.FC<FormItemProps & { label?: string }> = ({
  required,
  rules = [],
  label,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Form.Item
      {...props}
      label={label && intl.formatMessage({
        id: label,
        defaultMessage: ''
      })}
      getValueProps={(value) => value && { value }}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: 'validation.required' }),
        },
        ...rules,
      ]}
    >
      <CustomPhoneInput />
    </Form.Item>
  )
}

export default PhoneInput
