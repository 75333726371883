import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import {
  API,
  Auth
} from 'aws-amplify'

import * as types from './types'

import {openNotificationWithIcon} from '../../utils'

import generator from 'generate-password'

export function* getStaffList(action) {
  try {
    const {currentAccountID} = action
    const {payload} = yield call([API, API.get], 'staff', `/list/account/${currentAccountID}?showDeleted=N`)
    yield put({
      type: types.GET_STAFF_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_STAFF_LIST_ERROR})
  }
}

function* getStaffRecord(action) {
  try {
    const {id} = action
    const {payload} = yield call([API, API.get], 'staff', `/get/id/${id}`)
    yield put({
      type: types.GET_STAFF_RECORD_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_STAFF_RECORD_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* updateStaffRecord(action) {
  try {
    const {body} = action
    yield call([API, API.put], 'staff', `/update/${body.id}`, {body})
    yield put({
      type: types.UPDATE_STAFF_RECORD_SUCCESS,
      payload: body
    })
    openNotificationWithIcon('success', 'Success', 'Successfully updated')
  } catch (e) {
    yield put({type: types.UPDATE_STAFF_RECORD_ERROR})
    openNotificationWithIcon('error', 'Failed to update', e.message)
  }
}

function* checkStaffEmail(action) {
  try {
    const {
      payload: {
        email,
        cb
      }
    } = action
    const {payload} = yield call([API, API.get],'staff', `/check-email/${email}`)
    if (!payload) {
      yield put({type: types.CHECK_STAFF_EMAIL_ERROR})
    } else {
      yield put ({type: types.CHECK_STAFF_EMAIL_SUCCESS})
      yield call(cb)
    }
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
  }
}

function* createNewStaff(action) {
  try {
    const {
      payload: {
        staff,
        history
      }
    } = action
    openNotificationWithIcon(
      'info',
      'In Process',
      'Creating new staff member in database.'
    )

    const {payload} = yield call([API, API.post], 'staff', '/create', {body: staff})
    yield put({type: types.CREATE_NEW_STAFF_SUCCESS})
    yield call([history, history.push], '/staff/list')

    openNotificationWithIcon(
      'success',
      'Record Created',
      'Creating portal login for new staff member.'
    )
    const password = generator.generate({
      length: 8,
      numbers: true,
      lowercase: true,
      uppercase: true,
      symbols: true,
      strict: true
    })

    if (staff?.createStaffLogin){
      yield call(createStaffCognitoAccount, payload.insertId, staff.email, password, staff.account_id)
      openNotificationWithIcon(
        'success',
        'Record Created',
        'New staff has been successfully created.'
      )
    } else {
      yield call(createStaffwithNoCognitoAccount, payload.insertId, staff.email)
      openNotificationWithIcon(
        'success',
        'Record Created',
        'New staff has been successfully created.'
      )
      
    }
  } catch (e) {
    yield put({type: types.CREATE_NEW_STAFF_ERROR})
    openNotificationWithIcon(
      'error',
      'Request failed',
      'Failed to create new staff.'
    )
  }
}

function* createStaffCognitoAccount(id, username, password, account_id) {
  try {
    const newUser = yield call([Auth, Auth.signUp], {
      username,
      password
    })
    yield all([
      call([API, API.post], 'user-staff', '/create-access', {
        body: {
          staff_id: id,
          aws_cognito_id: newUser.userSub,
          email_address: username,
          account_id: account_id
        }
      }),
      call([API, API.post], 'email', '/staff/new-staff-created', {
        body: {
          id: id,
          username: username,
          password: password
        }
      }),
      call([API, API.post], 'email', '/interaction', {
        body: {
          interaction: 'Create New Staff Member Record',
          data: {
            id: id,
            username: username,
            password: password,
            aws_cognito_id: newUser.userSub
          },
          result: 'Admin record created successfully'
        }
      })
    ])
  } catch (e) {
    openNotificationWithIcon(
      'error',
      'Failed to create staff cognito',
      e.message
    )
  }
}

function* createStaffwithNoCognitoAccount(id, username) {
  try {
    yield call([API, API.post], 'email', '/interaction', {
      body: {
        interaction: 'Create New Staff Member Record',
        data: {
          id: id,
          username: username,
        },
        result: 'Admin record created successfully'
      }
    })
  } catch (e) {
    openNotificationWithIcon(
      'error',
      'Failed to create staff cognito',
      e.message
    )
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_STAFF_LIST_REQUEST, getStaffList),
    yield takeEvery(types.GET_STAFF_RECORD_REQUEST, getStaffRecord),
    yield takeLatest(types.UPDATE_STAFF_RECORD_REQUEST, updateStaffRecord),
    yield takeLatest(types.CHECK_STAFF_EMAIL_REQUEST, checkStaffEmail),
    yield takeLatest(types.CREATE_NEW_STAFF_REQUEST, createNewStaff),
  ])
}