import listHOC from './listHOC'
import React from 'react'
import {
  Form,
  Input,
  Card,
  Descriptions,
  Divider
} from 'antd'
import moment from 'moment'

const ViewDocumentEmail = listHOC(({
  document,
  email,
  loading
}) => {
  return document.id ? (
    <React.Fragment>
      <Descriptions title="Email Data" column={1} size="small" bordered>
        <Descriptions.Item label="To Address">
          {email?.toAddress}
        </Descriptions.Item>
        <Descriptions.Item label="Subject">
          {email?.subject}
        </Descriptions.Item>
        <Descriptions.Item label="Send Datetime">
          {email?.sendDatetime ? moment(email?.sendDatetime).format('DD/MM/YYYY HH:mm:ss') : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Message ID">
          {email?.sendGridMessageId}
        </Descriptions.Item>
      </Descriptions>
      <Divider/>
      <Descriptions title="Email Tracking" column={1} size="small" bordered>
        <Descriptions.Item label="Delivered">
          {email?.status_delivered_datetime ? moment(email?.status_delivered_datetime).format('DD/MM/YYYY HH:mm:ss') : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Delivered IP">
          {email?.status_delivered_ip}
        </Descriptions.Item>
        <Descriptions.Item label="Delivered Response">
          {email?.status_delivered_response}
        </Descriptions.Item>
        <hr/>
        <Descriptions.Item label="Open Datetime">
          {email?.status_opened_datetime ? moment(email?.status_opened_datetime).format('DD/MM/YYYY HH:mm:ss') : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Open IP">
          {email?.status_opened_ip}
        </Descriptions.Item>
        <Descriptions.Item label="User Agent">
          {email?.status_opened_useragent}
        </Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  ) : <h3>No document selected</h3>
})

export default ViewDocumentEmail