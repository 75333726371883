import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Typography,
  Descriptions,
  Skeleton,
  Divider,
} from 'antd'
import {useSelector} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'

import {API} from 'aws-amplify'

interface IState {
  data: any;
  headers: string[];
  name: string;
  loadingData: boolean;
}

export const InformationRequestMetrics: React.FC = () => {
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    loadingData: true
  })
  const {id} = useSelector((state: any) => appStateSelectors.stateSelector(state).currentAccountRecord)

  const fetch = async (): Promise<any> => {
    setState({
      ...state,
      loadingData: true,
    })

    const response = await API.get('reportsMetrics', `/information-requests/current/${id}`, {})
    const data = response.payload

    setState({
      ...state,
      data,
      loadingData: false,
    })
  }

  useEffect(() => {
    fetch()
  }, [])
 
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={4}>
            Information Request Metrics
          </Typography.Title>
        </Col>
      </Row>
      
      <Row gutter={[96, 24]}>
        <Skeleton loading={state.loadingData} active>
          <Col span={12}>
            <Descriptions
              bordered
              size='small'
              column={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
              }}
            >
              <Descriptions.Item label='Total Created'>
                {state.data.metricsToday?.informationRequest_created} [+{Number.parseInt(state.data.metricsToday?.informationRequest_created) - Number.parseInt(state.data.metricsYesterday?.informationRequest_created)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total Sent'>
                {state.data.metricsToday?.informationRequest_sent} [+{Number.parseInt(state.data.metricsToday?.informationRequest_sent) - Number.parseInt(state.data.metricsYesterday?.informationRequest_sent)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total In Process'>
                {state.data.metricsToday?.informationRequest_inProcess} [+{Number.parseInt(state.data.metricsToday?.informationRequest_inProcess) - Number.parseInt(state.data.metricsYesterday?.informationRequest_inProcess)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total Submitted'>
                {state.data.metricsToday?.informationRequest_submitted} [+{Number.parseInt(state.data.metricsToday?.informationRequest_submitted) - Number.parseInt(state.data.metricsYesterday?.informationRequest_submitted)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total Additional Information'>
                {state.data.metricsToday?.informationRequest_additionalInfo} [+{Number.parseInt(state.data.metricsToday?.informationRequest_additionalInfo) - Number.parseInt(state.data.metricsYesterday?.informationRequest_additionalInfo)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total Review Labuan'>
                {state.data.metricsToday?.informationRequest_toReview} [+{Number.parseInt(state.data.metricsToday?.informationRequest_toReview) - Number.parseInt(state.data.metricsYesterday?.informationRequest_toReview)}]
              </Descriptions.Item>
              <Descriptions.Item label='Total Completed'>
                {state.data.metricsToday?.informationRequest_completed} [+{Number.parseInt(state.data.metricsToday?.informationRequest_completed) - Number.parseInt(state.data.metricsYesterday?.informationRequest_completed)}]
              </Descriptions.Item>
            </Descriptions>
            <Divider/>
          </Col>
        </Skeleton>
      </Row>
    </Fragment>
  )
}

export default InformationRequestMetrics