import * as React from 'react'
import {
  Button,
  InputSelect,
  InputText,
  Drawer,
  Form,
  notification
} from 'components'
import {FormattedMessage} from 'react-intl'
import {
  API,
  saveFile,
  Storage
} from 'utils'
import {
  useIntl,
  useForm,
  useSelector
} from 'hooks'
import {
  DocWTemplate,
  DocWDocument
} from 'types'
import createReport from 'docx-templates'
import dayjs from 'dayjs'

interface CreateDocumentProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (document: DocWDocument) => void;
}

const CreateDocument: React.FC<CreateDocumentProps> = ({
  open, onClose, onSuccess
}) => {
  const intl = useIntl()
  const [form] = useForm()
  const [state, setState] = React.useState<{
    loading: boolean;
    submitting: boolean;
    templates: DocWTemplate[];
  }>({
    submitting: false,
    loading: false,
    templates: []
  })

  const {
    clientRecord, countriesList, streetTypes
  } = useSelector(state => ({
    // client is fetched from the parent
    clientRecord: state.clients.entities.clientRecord,
    countriesList: state.appState.countriesList,
    streetTypes: state.leads.streetCodes.streetType
  }))

  const clientAccountID = clientRecord.account_id
  React.useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }))
    API.get('accounts', `/${clientAccountID}/docw/templates`)
      .then(templates => {
        setState(prevState => ({
          ...prevState,
          loading: false,
          templates
        }))
      })
      .catch(() => {
        notification.error({message: intl.formatMessage({id: 'notifications.error.fetch'})})

        setState(prevState => ({
          ...prevState,
          loading: true
        }))
      })
  }, [clientAccountID])

  return (
    <Drawer
      title='Create Document'
      open={open}
      onClose={onClose}
      extra={
        <Button
          onClick={() => form.submit()}
          loading={state.submitting}
          htmlType="button"
          type="primary"
        >
          <FormattedMessage id="button.create" />
        </Button>
      }
    >
      <Form
        form={form}
        layout='vertical'
        onValuesChange={values => {
          if (values.templateID) {
            const title = state.templates.find(template => template.id === values.templateID)?.title || ''

            form.setFieldValue('title', title)
          }
        }}
        onFinish={async ({templateID, title}) => {
          setState(prevState => ({
            ...prevState,
            submitting: true
          }))

          try {
            const awsFileID = state.templates.find(template => template.id === templateID)?.awsFileID as string

            const {Body} = await Storage.get(awsFileID, {
              bucket: 'wordTemplates',
              download: true
            })

            const report = await createReport({
              cmdDelimiter: ['{', '}'],
              rejectNullish: false,
              template: await Body.arrayBuffer(),
              data: {
                identificationNumber: null,
                nationality: null,
                addressLine1: null,
                addressLine2: null,
                currentEmployer: null,
                currentEmployerIndustry: null,
                jobTitle: null,
                firstName: clientRecord.first_name,
                lastName: clientRecord.last_name,
                dateofBirth: clientRecord.date_of_birth ? dayjs(clientRecord.date_of_birth).format('YYYY/MM/DD') : null,
                countryOfBirth: countriesList.find(country =>
                  country.id === clientRecord.place_of_birth_country
                )?.full_name,
                telHome: clientRecord.tel_home,
                telWork: clientRecord.tel_work,
                telMobile: clientRecord.tel_mobile,
                email: clientRecord.email,
                netWorth: null,
                streetNumber: clientRecord.streetStreetNumber,
                streetType: streetTypes.find(streetType => streetType.id === clientRecord.streetStreetTypeId)?.fileBy,
                addressSuburb: clientRecord.streetSuburb,
                addressState: clientRecord.streetState,
                addressPostcode: clientRecord.streetPostcode,
                addressCountry: countriesList.find(country =>
                  country.id === clientRecord.streetCountryId
                )?.full_name,
                annualIncome: clientRecord.annual_income
              },
            })

            const {key: docwFileID} = await Storage.put(awsFileID, new File([report], awsFileID), {bucket: 'wordDocuments',})

            const document = await API.post('clients', `/${clientRecord.id}/docw/documents`, {
              body: {
                title,
                awsFileID: docwFileID
              }
            })

            setState(prevState => ({
              ...prevState,
              submitting: false
            }))

            onSuccess(document)
            onClose()
          } catch (e: any) {
            setState(prevState => ({
              ...prevState,
              submitting: false
            }))
            notification.error({message: e.response?.data?.error || e.message})
          }
        }}
      >
        <InputSelect
          required
          name='templateID'
          label='client.docw.selectTemplate'
          options={state.templates}
          getOptionProps={template => ({
            value: template.id,
            children: template.title
          })}
        />
        <InputText
          name='title'
          required
          label='common.title'
        />
      </Form>
    </Drawer>
  )
}

export default CreateDocument
