import React from 'react'

import {
  Col,
  Row,
  Form,
  InputText,
  InputDatePicker,
  InputSelect
} from 'components'
import {CommonProps} from '../../types'
import {COMMON_COL_PROPS} from 'consts'

const PersonalInfo: React.FC<CommonProps> = ({children, initialValues}) => {
  return (
    <Form
      name='personalInfo'
      layout='vertical'
      initialValues={initialValues}
    >
      <Row gutter={[12, 0]}>
        <Col {...COMMON_COL_PROPS}>
          <InputText
            required
            name='firstGivenName'
            label='common.firstName'
          />
          <InputText
            name='middleName'
            label='common.middleName'
          />
          <InputText
            required
            name='firstSurName'
            label='common.lastName'
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputDatePicker
            name='dateOfBirth'
            label='common.dateOfBirth'
          />
          <InputSelect
            label='common.gender'
            name='gender'
            options={[
              {
                value: 1,
                children: 'M'
              },
              {
                value: 2,
                children: 'F'
              }
            ]}
          />
        </Col>
      </Row>
      {children}
    </Form>
  )
}
export default PersonalInfo
