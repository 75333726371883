import React, {Component} from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input,
  Skeleton
} from 'antd'
const dbDateFormat = 'YYYY-MM-DD HH:mm:ss'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class TransactionSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      loading: true
    }
  }

  async componentDidMount() {
    try {
      const walletTransaction = await this.getTransactionRecord(this.props.id)
      this.setState({
        ...walletTransaction.payload,
        loading: false
      })
    } catch (e) {
      console.log(e)
      this.setState({loading: false})
    }
  }

  async getTransactionRecord(walletID) {
    return API.get('trading-commissions', `/wallet-transactions/id/${walletID}`)
  }
  
  // async saveWalletRecord(wallet_record) {
  //   await API.patch('trading-commissions', `/wallet/${this.state.id}`, {body: wallet_record})
  //   openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  // }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onHandleChangeAccount = value => {
    console.log(value)
    this.setState({'accountID': value})
  }

  // onHandleSubmit = async event => {
  //   event.preventDefault()
  //   this.setState({is_saving: true})
  //   openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

  //   const {
  //     id,
  //     fileBy,
  //     firstName,
  //     lastName,
  //     clientID
  //   } = this.state
  //   try {
  //     await this.saveWalletRecord({
  //       id,
  //       fileBy,
  //       firstName,
  //       lastName,
  //       clientID
  //     })
  //     this.setState({is_saving: false})
  //   } catch (e) {
  //     console.log(e)
  //   }
  // };

  render() {
    return (
      <React.Fragment>
        <Skeleton active loading={this.state.loading}>
          <Form
            layout='vertical'
          // onSubmit={this.onHandleSubmit}
          >
            <Row gutter={[96, 24]}>
              <Col xs={12}>
                <Form.Item label='ID'>
                  <Input
                    id='id'
                    value={this.state.id || ''}
                    disabled
                    onChange={event => {
                      this.handleChange(event)
                    }}
                  />
                </Form.Item>
                <Form.Item label='Wallet ID'>
                  <Input
                    id='walletID'
                    value={this.state.walletID || ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Memo'>
                  <Input
                    id='memo'
                    value={this.state.memo || ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Debit'>
                  <Input
                    id='debit'
                    value={this.state.debit > 0 ? Number(this.state.debit).toFixed(2) : ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Credit'>
                  <Input
                    id='credit'
                    value={this.state.credit > 0 ? Number(this.state.credit).toFixed(2) : ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Record Created'>
                  <Input
                    id='recordCreated'
                    value={this.state?.recordCreated ? moment(this.state.recordCreated).format(dbDateFormat) : ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Transaction Date'>
                  <Input
                    id='transactionDate'
                    value={this.state?.transactionDate ? moment(this.state.transactionDate).format(dbDateFormat) : ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*<Button type='primary' htmlType='submit' onClick={this.onHandleSubmit}>
            Save
                </Button>*/}
          </Form>
        </Skeleton>
      </React.Fragment>
    )
  }
}

export default TransactionSummary
