import React, {
  useState,
  useEffect
} from 'react'
import {
  Input,
  Button,
  Table,
  Divider,
  Card,
  Collapse
} from 'antd'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import {settingsSelectors} from 'configs/ducks/settings'
import {API} from 'aws-amplify'
import moment from 'moment'
import {Area} from '@ant-design/charts'

const { Panel } = Collapse

const OpportunitiesGraphAndListContainer = ({
  loading,
  accountId,
  days
}) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [opportunitiesList, setOpportunities] = useState([])
  const [opportunitiesData, setOpportunitiesChart] = useState([])

  let searchInput = ''
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      )
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const opportunitiesColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'Client ID',
        render: (text, record) =>
          <a
            href={`/clients/view/${text}?tab=summary`}
            rel='noreferrer'
            target='_blank'
          >
            {text}
          </a>
      }),
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      render: (text, record) => `${record.first_name} ${record.last_name}`
    },
    {
      title: 'Created',
      dataIndex: 'createdDatetime',
      width: 200,
      render: (text) =>
        <React.Fragment>
          {text
            ? moment(text).format('DD/MM/YYYY')
            : ''
          }
        </React.Fragment>
    }
  ]

  const opportunitiesConfig = {
    data: opportunitiesData,
    height: 200,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {style: {fill: '#aaa',},},
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' }
    },
  }

  useEffect(() => {
    API.get('widgets', `/opportunities/list/${days}/${accountId}`)
      .then(response => {
        setOpportunities(response.payload)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    API.get('widgets', `/opportunities/chart/${days}/${accountId}`)
      .then(response => {
        const dataSource = []
        for (let i = 0; i <= 7; i++) {
          dataSource.push({
            date: moment(Date.now()).subtract(7 - i, 'day').format('YYYY-MM-DD'),
            count: 0
          })
        }
        for (let i = 0; i < response.payload.length; i++) {
          for (let j = 0 ; j <= 7 ; j++){
            if (moment(response.payload[i].createdDatetime).format('YYYY-MM-DD') == moment(Date.now()).subtract(7 - j, 'day').format('YYYY-MM-DD')) {
              dataSource[j].count = dataSource[j].count + 1
            }
          }
        }
        setOpportunitiesChart(dataSource)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const callback = (key) => {
    console.log(key)
  }

  return (
    <Card
      title = {`Opportunities Created (previous ${days} days)`}
      style = {{height: '100%'}}
    >
      <Area {...opportunitiesConfig} />
      <Divider/>
      <Collapse onChange={callback}>
        <Panel header="View Opportunities Details" key="1">
          <Table
            rowKey='id'
            size="small"
            scroll={{x: true}}
            style={{width: '100%'}}
            loading={loading}
            columns={opportunitiesColumns}
            dataSource={opportunitiesList}
          />
        </Panel>
      </Collapse>
    
    </Card>
  )
}

const mapStateToProps = state => {
  const {loading} = settingsSelectors.stateSelector(state)
  return {loading,}
}

const OpportunitiesGraphAndListComponent = connect(mapStateToProps, {})(
  OpportunitiesGraphAndListContainer
)

export default OpportunitiesGraphAndListComponent
