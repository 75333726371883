import * as React from 'react'
import moment from 'moment'

const CatchAllPage = () => (
  <div
    style={{
      padding: 24,
      background: '#fff',
      minHeight: 360,
    }}
  >
    <div
      style={{
        paddingLeft: 0,
        borderBottom: '1px solid rgb(235, 237, 240)',
        marginBottom: 16,
      }}
      title="Delios Technologies"
    />
    <strong>Page not found</strong>
    <br />
    Delios Technologies ©{moment().format('yyyy')}
  </div>
)

export default CatchAllPage
