import React, {
  useState,
  useEffect
} from 'react'

import {createUseStyles} from 'react-jss'
import {
  connect,
  useSelector,
  useDispatch
} from 'react-redux'

import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients/index'

import {appStateSelectors} from 'configs/ducks/appState'

import {
  inputChange,
  clearState
} from 'configs/actions/common'

import {
  Steps,
  Button,
  Form,
  Space,
  Spin
} from 'antd'
import {leadsActions} from 'configs/ducks/leads/index'
import {ClientNewStep1} from './client_new/ClientNewStep1'
import {ClientNewStep2} from './client_new/ClientNewStep2'
import {ClientNewStep3} from './client_new/ClientNewStep3'

const useStyles = createUseStyles({
  stepsContent: {
    minHeight: 280,
    paddingTop: 48
  },
  space: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const {Step} = Steps

const steps = [
  {
    title: 'Client Details',
    content: ClientNewStep1
  },
  {
    title: 'Contact Details',
    content: ClientNewStep2
  },
  {
    title: 'Confirm',
    content: ClientNewStep3 //({first_name}) => first_name
  }
]

const ClientNewPageComponentLegacy = ({
  clearState,
  checkClientsEmail,
  inputChange,
  createClient,
  loading,
  client,
  duplicateEmail,
  clientCategoriesList,
  currentAccountID,
  currentAccountRecord,
  countriesList,
  history
}) => {
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(0)
  const {streetCodes} = useSelector(state => state.leads)
  const classes = useStyles()
  const [form] = Form.useForm()

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  useEffect(() => {
    dispatch(leadsActions.getStreets())
    return clearState
  }, [])

  useEffect(() => {
    if (form.isFieldTouched('email')) {
      form.validateFields()
    }
  }, [duplicateEmail])

  const onNextStepBtnClick = () => {
    if (currentStep === 0) {
      checkClientsEmail(
        {
          email: client.email,
          account_id: currentAccountID
        },
        () => {
          setCurrentStep(currentStep + 1)
        }
      )
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const onPrevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const onSubmit = () => {
    createClient(client, currentAccountID, history)
  }

  const onFinishHandlers = {
    0: onNextStepBtnClick,
    1: onNextStepBtnClick,
    2: onSubmit
  }

  const onPhoneInputChange = id => value => {
    inputChange(id, value)
  }
  const [reload, setReload] = useState(false)

  const StepComponent = steps[currentStep].content
  const onValuesChange = changed => {
    setReload(!reload)
    const [key, value] = Object.entries(changed)[0]
    inputChange(key, value)
  }

  return (
    <Form form={form} layout='vertical' onFinish={onFinishHandlers[currentStep]} onValuesChange={onValuesChange}>
      <Steps current={currentStep}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Spin wrapperClassName={classes.stepsContent} spinning={loading}>
        <StepComponent
          form={form}
          duplicateEmail={duplicateEmail}
          countriesList={countriesList}
          client={client}
          currentAccountRecord={currentAccountRecord}
          first_name={client.first_name}
          clientCategoriesList={clientCategoriesList}
          streetCodes={streetCodes}
          onPhoneInputChange={onPhoneInputChange}
          inputChange={inputChange}
        />
        <Space className={classes.space}>
          {currentStep > 0 && <Button onClick={onPrevStep}>Previous</Button>}
          <Button type='primary' htmlType='submit'>
            {currentStep < 2 ? 'Next' : 'Confirm'}
          </Button>
        </Space>
      </Spin>
    </Form>
  )
}

const {createClient, checkClientsEmail} = clientsActions

const {newClientSelector} = clientsSelectors

const mapStateToProps = state => {
  const {
    duplicateEmail, loading, client
  } = newClientSelector(state)
  const {
    currentAccountRecord: {id: currentAccountID},
    currentAccountRecord,
    countriesList,
    clientCategoriesList
  } = appStateSelectors.stateSelector(state)
  return {
    clientCategoriesList,
    duplicateEmail,
    loading,
    client,
    countriesList,
    currentAccountID,
    currentAccountRecord
  }
}

export default connect(mapStateToProps, {
  clearState,
  inputChange,
  createClient,
  checkClientsEmail
})(ClientNewPageComponentLegacy)