import { combineReducers } from 'redux'

import clientsReducer, { clientsTypes } from '../ducks/clients'
import staffsReducer, { staffsTypes } from '../ducks/staffs'
import accountsReducer, { accountsTypes } from '../ducks/backoffice-accounts'
import documentsReducer, { documentsTypes } from '../ducks/documents'
import verificationsReducer, { verificationsTypes } from '../ducks/verifications'
import leadsReducer, { leadsTypes } from '../ducks/leads'
import appStateReducer, { appStateTypes } from '../ducks/appState'
import settingsReducer, { settingsTypes } from '../ducks/settings'
import tradingReducer, { tradingTypes } from '../ducks/trading'
import teamsReducer, { teamsTypes } from '../ducks/teams'
import agentsReducer, { agentsTypes } from '../ducks/agents'
import docCheckReducer, { docCheckTypes } from '../ducks/doc-check'

import language from './language'
import accounts from './accounts'
import opportunitiesReducer, {opportunitiesTypes} from '../ducks/opportunities'
import leadsNewReducer, {leadsNewTypes} from '../ducks/leads-new'
import organiserReducer, {organiserTypes} from '../ducks/organiser'
import xRecordIdsReducer, {xRecordIdsTypes} from '../ducks/xRecordIds'
import informationRequestsReducer, {informationRequestsTypes} from '../ducks/information-requests'
import tradingCommissionsReducer, {tradingCommissionsClientsTypes} from '../ducks/trading-commissions'

const rootReducer = combineReducers({
  [appStateTypes.moduleName]: appStateReducer,
  [accountsTypes.moduleName]: accountsReducer,
  [agentsTypes.moduleName]: agentsReducer,
  [clientsTypes.moduleName]: clientsReducer,
  [documentsTypes.moduleName]: documentsReducer,
  [docCheckTypes.moduleName]: docCheckReducer,
  [verificationsTypes.moduleName]: verificationsReducer,
  [leadsTypes.moduleName]: leadsReducer,
  [settingsTypes.moduleName]: settingsReducer,
  [staffsTypes.moduleName]: staffsReducer,
  [teamsTypes.moduleName]: teamsReducer,
  [tradingTypes.moduleName]: tradingReducer,
  [opportunitiesTypes.moduleName]: opportunitiesReducer,
  [leadsNewTypes.moduleName]: leadsNewReducer,
  [organiserTypes.moduleName]: organiserReducer,
  [xRecordIdsTypes.moduleName]: xRecordIdsReducer,
  [informationRequestsTypes.moduleName]: informationRequestsReducer,
  [tradingCommissionsClientsTypes.moduleName]: tradingCommissionsReducer,
  language,
  accounts
})

export default rootReducer