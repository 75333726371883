import * as React from 'react'
import {
  PdfViewer,
  Image
} from 'components'
import {TableColumnType} from 'antd'

export const DOCUMENTS_COLUMNS = [
  {
    title: 'Id',
    dataIndex: 'id'
  },
  {
    title: 'Account Id',
    dataIndex: 'account_id'
  },
  {
    title: 'Client Id',
    dataIndex: 'client_id'
  },

  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Long Description',
    dataIndex: 'long_description'
  },
  {
    title: 'Document Type Id',
    dataIndex: 'document_type_id'
  }
]

export const DOCUMENT_FILE_IDS_COLUMNS: TableColumnType<{file_id: string; contentType: string; url: string}>[] = [
  {
    title: 'Document ID',
    dataIndex: 'document_id'
  },
  {
    title: 'File ID',
    dataIndex: 'url',
    render: (_, {contentType, url}) => {
      const isPdf = contentType.toLocaleLowerCase().includes('pdf')

      return (
        <>
          <div>
            {isPdf ? <PdfViewer url={url} /> : <Image src={url} width={50} height={50} />}
          </div>
          <a href={url} target='_blank' rel="noreferrer">
            Click here{' '}
          </a>
          to open image file (then press CTRL-S to save)
        </>
      )
    }
  }
]
