import * as types from './types'

/**
 * @param {number} id
 * @returns {{payload: *, type: string}}
 */
export const getDocuments = id => ({
  type: types.GET_DOCUMENTS_REQUEST,
  payload: id
})

/**
 * @param {number} accountId
 * @returns {{payload: *, type: string}}
 */
export const getDocumentDigisignTemplates = accountId => ({
  type: types.GET_DIGISIGNTEMPLATES_REQUEST,
  payload: accountId
})

/**
 * @param {number} accountID
 * @returns {{payload: *, type: string}}
 */
export const getDocwByAccount = accountID => ({
  type: types.GET_DOCW_BY_ACCOUNT_REQUEST,
  payload: accountID
})

/**
 * @param {number} clientID
 * @returns {{payload: *, type: string}}
 */
export const getDocwByClient = clientID => ({
  type: types.GET_DOCW_REQUEST,
  payload: clientID
})

/**
 * @param {number} accountId
 * @returns {{payload: *, type: string}}
 */
export const getEmailCustomTemplates = accountId => ({
  type: types.GET_EMAILCUSTOMTEMPLATES_REQUEST,
  payload: accountId
})

/**
 * @param {number} id
 * @returns {{payload: *, type: string}}
 */
export const getEmailCustomTemplate = id => ({
  type: types.GET_EMAILCUSTOMTEMPLATE_REQUEST,
  payload: id
})

/**
 * @param {Object} emailCustomTemplate
 * @param {Function} successCb
 * @returns {{payload: *, type: string}}
 */
export const saveEmailCustomTemplate = (emailCustomTemplate, successCb = () => null) => ({
  type: types.SAVE_EMAILCUSTOMTEMPLATE_REQUEST,
  payload: {
    emailCustomTemplate,
    successCb
  }
})

/**
 * @param {string} id
 * @returns {{payload: *, type: string}}
 */
export const onFileUploadSuccess = id => ({
  type: types.FILE_UPLOAD_SUCCESS,
  payload: id
})
/**
 * @returns {{payload: *, type: string}}
 */
export const getDoctypeList = () => ({type: types.GET_DOCTYPE_LIST_REQUEST})