import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import {openNotificationWithIcon} from '../../utils'

import * as types from './types'

function* fetchTeams(action) {
  const {payload: id} = action
  try {
    const {payload} = yield call([API, API.get], 'teams', `/list/${id}`)
    yield put({
      type: types.FETCH_TEAMS_SUCCESS,
      payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.FETCH_TEAMS_ERROR})
  }
}

function* getTeam(action) {
  try {
    const {payload: id} = action
    const {payload: team} = yield call([API, API.get], 'teams', `/id/${id}`)
    yield put({
      type: types.GET_TEAM_SUCCESS,
      payload: team
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.GET_TEAM_ERROR})
  }
}

function* saveTeamRecord(action) {
  try {
    const {payload: client} = action
    openNotificationWithIcon('info', 'In Process', 'Saving information...')

    yield call([API, API.put], 'teams', `/update/${client.id}`, {body: {...client}})

    openNotificationWithIcon('success', 'Success', 'Successfully saved.')
    yield put({type: types.SAVE_TEAM_SUCCESS})
  } catch (e) {
    openNotificationWithIcon('error', 'Error', 'Failed to save')
    yield put({type: types.SAVE_TEAM_ERROR})
  }
}

function* createTeam(action) {
  try {
    const {
      payload: {
        newClient, accountID, history
      }
    } = action
    openNotificationWithIcon('info', 'In Process', 'Creating new client in database.')
    const {payload} = yield call([API, API.post], 'teams', '/create', {
      body: {
        ...newClient,
        file_by: `${newClient.last_name}, ${newClient.first_name}`,
        account_id: accountID
      }
    })
    openNotificationWithIcon('success', 'Record Created', 'New team has been successfully created.')
    yield put({type: types.CREATE_TEAM_SUCCESS})
    history.push('/teams/list')
  } catch (e) {
    openNotificationWithIcon('error', 'Error', e.message)
    yield put({type: types.CREATE_TEAM_ERROR})
  }
}

function* deleteTeam(action) {
  try {
    yield call([API, API.del], 'teams', `/delete/${action.payload}`)
    yield put({
      type: types.DELETE_TEAM_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.DELETE_TEAM_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.DELETE_TEAM_REQUEST, deleteTeam),
    yield takeEvery(types.FETCH_TEAMS_REQUEST, fetchTeams),
    yield takeEvery(types.CREATE_TEAM_REQUEST, createTeam),
    yield takeEvery(types.GET_TEAM_REQUEST, getTeam),
    yield takeEvery(types.SAVE_TEAM_REQUEST, saveTeamRecord),
  ])
}