import find from 'lodash/find'
import get from 'lodash/get'
import head from 'lodash/head'
import last from 'lodash/last'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import * as Types from './types'

const getStreetId = (street?: google.maps.GeocoderAddressComponent, addressStreetTypes?: Types.AddressStreetType[]) => {
  if (!street || !addressStreetTypes) {
    return null
  }

  const routeType = last(street.long_name.split(' ')) as string

  const found = addressStreetTypes.find((str) => str.file_by.includes(routeType))
  return found
    ? {
      streetStreetTypeId: found.id,
      routeType,
    }
    : null
}

export const getAddresses: Types.GetAddresses = async (
  geo,
  countries,
  addressStreetTypes
) => {
  const place_id = get(geo, 'value.place_id')
  const geocode = await geocodeByPlaceId(place_id)

  const { address_components } = head(geocode) || {}

  const street_number = find(address_components, (component) =>
    component.types.includes('street_number')
  )
  const street = find(address_components, (component) =>
    component.types.includes('route')
  )
  const postal_code = find(address_components, (component) =>
    component.types.includes('postal_code')
  )
  const administrative_area_level_1 = find(address_components, (component) =>
    component.types.includes('administrative_area_level_1')
  )
  const sublocality_level_1 = find(address_components, (component) =>
    component.types.includes('sublocality_level_1')
  )
  const country: google.maps.GeocoderAddressComponent | undefined = find(
    address_components,
    (component) => component.types.includes('country')
  )
  const suburb = find(address_components, (component) =>
    component.types.includes('locality')
  )
  const streetCountry = countries.find(
    (c) => country?.short_name === c.iso_alpha_2
  )
  const result = getStreetId(street, addressStreetTypes)

  let streetStreetTypeId = null
  let routeType = ''

  if (result) {
    streetStreetTypeId = result.streetStreetTypeId
    routeType = result.routeType
  }

  return {
    ...(street && {streetStreetName: street.long_name.replace(routeType, ''),}),
    ...(street_number && { streetStreetNumber: street_number.long_name }),
    ...(postal_code && { streetPostcode: postal_code.long_name }),
    ...(sublocality_level_1 && { streetSuburb: sublocality_level_1.long_name }),
    ...(administrative_area_level_1 && {streetState: administrative_area_level_1.long_name,}),
    ...(suburb && { streetSuburb: suburb.long_name }),
    ...(streetCountry && { streetCountryId: streetCountry.id }),
    ...(streetStreetTypeId && { streetStreetTypeId }),
  }
}
