import reducer from './reducers'

import * as agentsSelectors from './selectors'
import * as agentsActions from './actions'
import * as agentsTypes from './types'
import agentsSaga from './sagas'

export {
  agentsSaga,
  agentsTypes,
  agentsActions,
  agentsSelectors,
}

export default reducer