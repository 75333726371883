import {APP_NAME} from 'consts'

export const moduleName = 'opportunities'

export const FETCH_CLIENTS_REQUEST = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_REQUEST`
export const FETCH_CLIENTS_SUCCESS = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_SUCCESS`
export const FETCH_CLIENTS_ERROR = `${APP_NAME}/${moduleName}/FETCH_CLIENTS_ERROR`

export const GET_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_CLIENT_REQUEST`
export const GET_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_CLIENT_SUCCESS`
export const GET_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_CLIENT_ERROR`

export const SAVE_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/SAVE_CLIENT_REQUEST`
export const SAVE_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/SAVE_CLIENT_SUCCESS`
export const SAVE_CLIENT_ERROR = `${APP_NAME}/${moduleName}/SAVE_CLIENT_ERROR`

export const CREATE_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/CREATE_CLIENT_REQUEST`
export const CREATE_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_CLIENT_SUCCESS`
export const CREATE_CLIENT_ERROR = `${APP_NAME}/${moduleName}/CREATE_CLIENT_ERROR`

export const DELETE_OPPORTUNITIES_REQUEST = `${APP_NAME}/${moduleName}/DELETE_OPPORTUNITIES_REQUEST`
export const DELETE_OPPORTUNITIES_SUCCESS = `${APP_NAME}/${moduleName}/DELETE_OPPORTUNITIES_SUCCESS`
export const DELETE_OPPORTUNITIES_ERROR = `${APP_NAME}/${moduleName}/DELETE_OPPORTUNITIES_ERROR`

export const GET_DOCUMENTS_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_REQUEST`
export const GET_DOCUMENTS_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCUMENTS_SUCCESS`

export const GET_DOCUMENT_REQUEST = `${APP_NAME}/${moduleName}/GET_DOCUMENT_REQUEST`
export const GET_DOCUMENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_DOCUMENT_SUCCESS`
export const GET_DOCUMENT_ERROR = `${APP_NAME}/${moduleName}/GET_DOCUMENT_ERROR`

export const CREATE_NEW_DOCUMENT_REQUEST = `${APP_NAME}/${moduleName}/CREATE_NEW_DOCUMENT_REQUEST`
export const CREATE_NEW_DOCUMENT_SUCCESS = `${APP_NAME}/${moduleName}/CREATE_NEW_DOCUMENT_SUCCESS`
export const CREATE_NEW_DOCUMENT_ERROR = `${APP_NAME}/${moduleName}/CREATE_NEW_DOCUMENT_ERROR`

export const CHECK_CLIENTS_EMAIL_REQUEST = `${APP_NAME}/${moduleName}/CHECK_CLIENTS_EMAIL_REQUEST`
export const CHECK_CLIENTS_EMAIL_SUCCESS = `${APP_NAME}/${moduleName}/CHECK_CLIENTS_EMAIL_SUCCESS`
export const CHECK_CLIENTS_EMAIL_ERROR = `${APP_NAME}/${moduleName}/CHECK_CLIENTS_EMAIL_ERROR`

export const GET_VERIFICATIONS_LIST_REQUEST = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_LIST_REQUEST`
export const GET_VERIFICATIONS_LIST_SUCCESS = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_LIST_SUCCESS`
export const GET_VERIFICATIONS_LIST_ERROR = `${APP_NAME}/${moduleName}/GET_VERIFICATIONS_LIST_ERROR`

export const DIGIDOC_CREATE_REQUEST = `${APP_NAME}/${moduleName}/DIGIDOC_CREATE_REQUEST`
export const DIGIDOC_CREATE_SUCCESS = `${APP_NAME}/${moduleName}/DIGIDOC_CREATE_SUCCESS`
export const DIGIDOC_CREATE_ERROR = `${APP_NAME}/${moduleName}/DIGIDOC_CREATE_ERROR`

export const DIGIDOC_SEND_REQUEST = `${APP_NAME}/${moduleName}/DIGIDOC_SEND_REQUEST`
export const DIGIDOC_SEND_SUCCESS = `${APP_NAME}/${moduleName}/DIGIDOC_SEND_SUCCESS`
export const DIGIDOC_SEND_ERROR = `${APP_NAME}/${moduleName}/DIGIDOC_SEND_ERROR`

export const FINANCIAL_ACCOUNT_SEND_REQUEST = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_SEND_REQUEST`
export const FINANCIAL_ACCOUNT_SEND_SUCCESS = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_SEND_SUCCESS`
export const FINANCIAL_ACCOUNT_SEND_ERROR = `${APP_NAME}/${moduleName}/FINANCIAL_ACCOUNT_SEND_ERROR`

export const GET_JOURNAL_BY_CLIENT_REQUEST = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_CLIENT_REQUEST`
export const GET_JOURNAL_BY_CLIENT_SUCCESS = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_CLIENT_SUCCESS`
export const GET_JOURNAL_BY_CLIENT_ERROR = `${APP_NAME}/${moduleName}/GET_JOURNAL_BY_CLIENT_ERROR`