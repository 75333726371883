import React, {
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {teamsSelectors} from 'configs/ducks/teams/index'
import {appStateSelectors} from 'configs/ducks/appState'

import {Tabs} from 'antd'

import TeamSummary from './team_view/TeamSummary'
import {API} from 'aws-amplify'

const {TabPane} = Tabs

const TeamViewPageComponent = (
  {
    getTopics,
    accountsList,
    match: {params: {teamID}}
  }
) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('teams', `/id/${teamID}`)
        getTopics(
          'View Team Details',
          response.payload.id
        )
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <Tabs defaultActiveKey='1'>
      <TabPane tab='Summary' key='1'>
        <TeamSummary id={teamID} accountsList={accountsList}/>
      </TabPane>
    </Tabs>
  )
}

const {teamsSelector} = teamsSelectors
const mapStateToProps = state => {
  const {accountsList} = appStateSelectors.stateSelector(state)
  return {
    accountsList,
    ...teamsSelector(state)
  }
}

export default connect(mapStateToProps, {})(TeamViewPageComponent)