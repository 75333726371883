import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button
} from 'antd'
import React, {Fragment} from 'react'
import {columnSize} from '../../common'
import generator from 'generate-password'
import {
  FormSelect,
  InputEmail
} from 'common'

export const ClientNewStep1 = ({
  duplicateEmail, clientCategoriesList, form, inputChange, currentAccountRecord
}) => {

  const getOptionProps = ({file_by}) => ({children: file_by})

  return (
    <Row gutter={[96, 24]}>
      <Col {...columnSize}>
        <Row gutter={[12, 0]}>
          <Col {...columnSize}>
            <FormSelect
              label='Record Type'
              name='categoryId'
              placeholder='Select Category'
              optionValue='id'
              getOptionProps={getOptionProps}
              options={clientCategoriesList}
            />
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col {...columnSize}>
            <Form.Item
              hasFeedback
              label='First Name'
              name='first_name'
              rules={[
                {
                  required: true,
                  message: 'First name is required'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...columnSize}>
            <Form.Item label='Middle Name(s)' name='middle_name' style={{marginBottom: 0}}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          hasFeedback
          label='Last Name'
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Last name is required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <InputEmail required isDuplicate={duplicateEmail} />
      </Col>
      <Col {...columnSize}>
        <Form.Item hasFeedback name='createPass' valuePropName='checked'>
          <Checkbox disabled={!currentAccountRecord?.feature_xeondaClientPortal}>Create a User Login to Xeonda Client Portal</Checkbox>
        </Form.Item>
        {form.getFieldValue('createPass') === true && (
          <Fragment>
            <label>UserName</label>
            <br />
            <Input value={form ? form.getFieldValue('email') : ''} disabled />
            <br /> <br />
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item
                  hasFeedback
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/
                        if (!value) {
                          return Promise.reject('Please provide password')
                        }
                        if (!reg.test(value)) {
                          return Promise.reject(
                            '8 characters long including uppercase, lowercase, a number and a symbol'
                          )
                        }
                        return Promise.resolve()
                      }
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <br />
                <Button
                  style={{marginTop: '2%'}}
                  onClick={() => {
                    const password = generator.generate({
                      length: 10,
                      numbers: true,
                      lowercase: true,
                      symbols: true,
                      uppercase: true,
                      strict: true
                    })
                    inputChange('password', password)
                    form.setFieldsValue({password: password})
                  }}
                >
                  Generate Passwod
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </Col>
    </Row>
  )
}