import React, {Component} from 'react'
import { API } from 'aws-amplify'
import {NavLink} from 'react-router-dom'
import {getStatusOptions} from '../common'
import {listHOC} from 'common'
import {routes} from 'configs'
import {
  Row,
  Col,
  Button,
  InputDatePicker,
  Form,
  Input,
  Modal,
  Table,
  Skeleton,
  Spin,
  notification,
  Tag,
  Radio
} from 'components'
import {SyncOutlined} from '@ant-design/icons'

import moment from 'moment'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

const dateFormat = 'YYYY-MM-DD'
const tradingTransactionStatuses = {
  1: {
    text: 'Draft',
    color: 'default'
  },
  2: {
    text: 'Pending',
    color: 'processing'
  },
  3: {
    text: 'Pending Reject',
    color: 'warning'
  },
  4: {
    text: 'Pending Approve',
    color: 'warning'
  },
  5: {
    text: 'Rejected',
    color: 'error'
  },
  6: {
    text: 'Approved',
    color: 'success'
  },
  7: {
    text: 'Cancelled',
    color: 'default'
  }
}
const statusFilters = getStatusOptions(tradingTransactionStatuses)

class TradingTransactions2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentAccountID: props.currentAccountID,
      is_saving: false,
      dataSource: [],
      loading: true,
      showNewDepositModal: false,
      showNewWithdrawalModal: false,
      debit: 0,
      credit: 0,
      transactionDate: '1970-01-01',
      modalLoading: false,
      tableLoading: true,
      radioValue: 'all',
    }
  }

  async componentDidMount() {
    this.loadData()
  }

  onCloseNewDepositModal = () => {
    this.setState({showNewDepositModal: false})
  }
  onCloseNewWithdrawalModal = () => {
    this.setState({showNewWithdrawalModal: false})
  }
  onOpenNewDepositModal = async () => {
    this.setState({showNewDepositModal: true})
  }
  onOpenNewWithdrawalModal = async () => {
    this.setState({showNewWithdrawalModal: true})
  }

  saveNewTradingTransactionRecord = async record => {
    let validate = true
    validate = this.validate_step_1()

    if (validate) {
      const selectedDate = moment(record.transactionDate).format(dateFormat)
      const {currentAccountID} = this.state
      await API.post('trading-transactions-2', '/create', {
        body: {
          tradingAccountID: currentAccountID,
          debit: record?.debit ? Number(record.debit) : null,
          credit: record?.credit ? Number(record.credit) : null,
          statusID: 1,
          transactionDate: selectedDate
        }
      })
        .then(database_result => {
          openNotificationWithIcon('success', 'Record Created', 'New Record has been successfully created.')
          return database_result
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  validate_step_1 = event => {
    let validate1 = false
    if (this.state.debit > 0) {
      validate1 = true
    }

    return validate1
  };

  onFinish = async values => {
    const payload = {...values}
    await this.saveNewTradingTransactionRecord(payload)
    this.setState({showNewDepositModal: false})
    this.setState({showNewWithdrawalModal: false})
    this.loadData()
  }

  onHandleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onDatePickerChange = (date, dateString) => {
    const selectedDate = moment(date).format(dateFormat)
    this.setState({'transactionDate': selectedDate})
  };

  loadData = async () => {
    this.setState({tableLoading: true})
    try {
      const {currentAccountID} = this.state
      await API.get('trading-transactions-2', `/list-by-trading-account/${currentAccountID}`)
        .then(response => {
          const dataSource = []
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              client: response.payload[i].client,
              client_id: response.payload[i].client_id,
              credit: response.payload[i].credit,
              currency: response.payload[i].currency,
              debit: response.payload[i].debit,
              platformDepositAmount: response.payload[i].platformDepositAmount,
              recordCreated: response.payload[i].recordCreated,
              statusName: response.payload[i].statusName,
              statusID: response.payload[i].statusID
            })
          }
          this.setState({
            dataSource: dataSource,
            loading: true
          })
        })
        .catch(error => {
          console.log(error)
        })
      this.setState({loading: false})
    } catch (e) {
      console.log(e)
    }
    this.setState({tableLoading: false})
  }

  onRadioGroupChange = async (event) => {
    this.setState({radioValue: event.target.value})
    let dataSourceAll = []
    let dataSourceDebit = []
    let dataSourceCredit = []
    switch (event.target.value) {
      case 'all':
        await this.loadData()
        dataSourceAll = this.state.dataSource.filter(function(value) {
          return value.credit > 0 || value.debit > 0
        })
        this.setState({dataSource: dataSourceAll})
        break
      case 'debit':
        await this.loadData()
        dataSourceDebit = this.state.dataSource.filter(function(value) {
          return value?.debit > 0
        })
        this.setState({dataSource: dataSourceDebit})
        break
      case 'credit':
        await this.loadData()
        dataSourceCredit = this.state.dataSource.filter(function(value) {
          return value?.credit > 0
        })
        this.setState({dataSource: dataSourceCredit})
        break
      default:
        console.log('all')
        break
    }
  }

  render() {

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        render: (text, {
          id, credit, debit
        }) => {
          if (debit || credit) {
            return <NavLink to={routes.tradingTransactions2View(id)}>{text}</NavLink>
          }

          return id
        }
      },
      {
        title: 'Created',
        dataIndex: 'recordCreated',
        render: (text, record) => (
          record?.recordCreated
            ? moment(record.recordCreated).format('DD/MM/YYYY HH:mm:ss')
            : ''
        )
      },
      {
        title: 'Client',
        dataIndex: 'client'
      },
      {
        title: 'Currency',
        dataIndex: 'currency'
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        placeholder: 'Status',
        ...this.props.getColumnSearchProps({
          dataIndex: 'statusID',
          placeholder: 'Status',
          filterInputType: 'SELECT',
          selectFilterOptions: statusFilters,
          getOptionProps: ({text, color}) => ({children: <Tag color={color}>{text}</Tag>}),
          optionValue: 'id',
          render: (_, {status_id}) => {
            if (!status_id) {
              return null
            }
            const {text, color} = tradingTransactionStatuses[status_id]
            return <Tag color={color}>{text}</Tag>
          }
        }),
        render: (_, {statusID, statusName}) => {
          if (!statusName) {
            return null
          }
          const {text, color} = tradingTransactionStatuses[statusID]
          return <Tag color={color}>{text}</Tag>
        },
      }
    ]

    if (this.state?.radioValue === 'debit' || this.state?.radioValue === 'all') {
      this.columns.push({
        title: 'Deposit',
        dataIndex: 'debit',
        render: (text, record) => (
          record?.debit ? record.debit.toFixed(2) : ''
        )
      })
    }

    if (this.state?.radioValue === 'credit' || this.state?.radioValue === 'all') {
      this.columns.push({
        title: 'Withdrawal',
        dataIndex: 'credit',
        render(text, record) {
          return (
            record?.credit ? record.credit.toFixed(2) : ''
          )
        }
      })
    }

    return (
      <React.Fragment>
        <Skeleton active loading={this.state.loading}>
          <Spin spinning={this.state.loading}>
            <Radio.Group onChange={this.onRadioGroupChange} value={this.state.radioValue || ''}>
              <Radio value='all'>All</Radio>
              <Radio value='debit'>Deposits</Radio>
              <Radio value='credit'>Withdrawals</Radio>
            </Radio.Group>
            <Row gutter={[96, 24]}>
              <Col xs={24} style={{textAlign: 'right'}}>
                <Button onClick={this.onOpenNewDepositModal}>Create Deposit</Button>
                <Button onClick={this.onOpenNewWithdrawalModal}>Create Withdrawal</Button>
                <Button onClick={()=> [this.setState({radioValue: 'all'}), this.loadData()]} icon={<SyncOutlined />} />
              </Col>
            </Row>
            <Table
              dataSource={this.state.dataSource}
              columns={this.columns}
              loading={this.state.loading}
            />
            <Modal
              title='Create Deposit'
              open={this.state.showNewDepositModal}
              onCancel={this.onCloseNewDepositModal}
              width={600}
              footer={null}
            >
              <Form onFinish={this.onFinish}>
                <Form.Item label='Amount' name='debit'>
                  <Input id='debit'value={this.state.debit} onChange={this.onHandleChange}/>
                </Form.Item>
                <InputDatePicker
                  label='common.date'
                  name='transactionDate'
                />
                <Button loading={this.state.modalLoading} type='primary' htmlType='submit'>
              Create
                </Button>
              </Form>
            </Modal>

            <Modal
              title='Create Withdrawal'
              open={this.state.showNewWithdrawalModal}
              onCancel={this.onCloseNewWithdrawalModal}
              footer={null}
            >
              <Form onFinish={this.onFinish}>
                <Form.Item label='Amount' name='credit'>
                  <Input id='credit' value={this.state.credit} onChange={this.onHandleChange}/>
                </Form.Item>
                <InputDatePicker
                  label='common.date'
                  name='transactionDate'
                />
                <Button loading={this.state.modalLoading} type='primary' htmlType='submit'>
              Create
                </Button>
              </Form>
            </Modal>
          </Spin>
        </Skeleton>
      </React.Fragment>
    )
  }
}

export default listHOC(TradingTransactions2)
