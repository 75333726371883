import {Record} from 'immutable'

import * as types from './types'
import {
  CLEAR_STATE,
  INPUT_CHANGE
} from '../../constants'

const ReducerRecord = Record({
  entities: [],
  duplicateEmail: false,
  currentStaff: {},
  loading: false,
})

export default (state = new ReducerRecord(), {
  type,
  payload
}) => {
  switch (type) {
    case types.GET_STAFF_LIST_REQUEST:
    case types.UPDATE_STAFF_RECORD_REQUEST:
    case types.CHECK_STAFF_EMAIL_REQUEST:
    case types.CREATE_NEW_STAFF_REQUEST:
      return state.set('loading', true)
    case types.GET_STAFF_LIST_ERROR:
    case types.UPDATE_STAFF_RECORD_ERROR:
    case types.CREATE_NEW_STAFF_SUCCESS:
    case types.CREATE_NEW_STAFF_ERROR:
      return state.set('loading', false)

    case INPUT_CHANGE:
      if (payload.inputName === 'email') {
        return state.set('duplicateEmail', false)
      }
      return state
    case types.CHECK_STAFF_EMAIL_SUCCESS:
      return state.set('loading', false)
    case types.CHECK_STAFF_EMAIL_ERROR:
      return state.merge({
        loading: false,
        duplicateEmail: true
      })

    case types.GET_STAFF_LIST_SUCCESS:
      return state
        .merge({
          loading: false,
          entities: payload
        })
    case types.GET_STAFF_RECORD_SUCCESS:
      return state
        .merge({
          loading: false,
          currentStaff: payload
        })
    case types.UPDATE_STAFF_RECORD_SUCCESS:
      return state
        .merge({
          loading: false,
          currentStaff: payload
        })
    // case CLEAR_STATE:
    //   return state.clear()
    default:
      return state
  }
}