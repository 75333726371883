import React, {Component} from 'react'
import { API } from 'aws-amplify'

import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input,
  Skeleton
} from 'antd'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class WalletSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      loading: true
    }
  }

  async componentDidMount() {
    try {
      const wallet = await this.getWalletRecord(this.props.id)
      this.setState({
        ...wallet.payload,
        loading: false
      })
    } catch (e) {
      console.log(e)
      this.setState({loading: false})
    }
  }

  async getWalletRecord(walletID) {
    return API.get('trading-commissions', `/wallet/${walletID}`)
  }

  // async saveWalletRecord(wallet_record) {
  //   await API.patch('trading-commissions', `/wallet/${this.state.id}`, {body: wallet_record})
  //   openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  // }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
  };

  onHandleChangeAccount = value => {
    console.log(value)
    this.setState({'accountID': value})
  }

  // onHandleSubmit = async event => {
  //   event.preventDefault()
  //   this.setState({is_saving: true})
  //   openNotificationWithIcon('info', 'Save In Progress', 'Please wait while data is being saved')

  //   const {
  //     id,
  //     fileBy,
  //     firstName,
  //     lastName,
  //     clientID
  //   } = this.state
  //   try {
  //     await this.saveWalletRecord({
  //       id,
  //       fileBy,
  //       firstName,
  //       lastName,
  //       clientID
  //     })
  //     this.setState({is_saving: false})
  //   } catch (e) {
  //     console.log(e)
  //   }
  // };

  render() {
    return (
      <React.Fragment>
        <Skeleton active loading={this.state.loading}>
          <Form
            layout='vertical'
          // onSubmit={this.onHandleSubmit}
          >
            <Row gutter={[96, 24]}>
              <Col xs={12}>
                <Form.Item label='Client'>
                  <Input
                    id='id'
                    value={this.state.file_by || ''}
                    disabled
                    onChange={event => {
                      this.handleChange(event)
                    }}
                  />
                </Form.Item>
                <Form.Item label='Currency'>
                  <Input
                    id='short_name'
                    value={this.state.short_name || ''}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*<Button type='primary' htmlType='submit' onClick={this.onHandleSubmit}>
            Save
                </Button>*/}
          </Form>
        </Skeleton>
      </React.Fragment>
    )
  }
}

export default WalletSummary
