import reducer from './reducers'

import * as clientsSelectors from './selectors'
import * as clientsActions from './actions'
import * as clientsTypes from './types'
import clientsSaga from './sagas'

export {
  clientsSaga,
  clientsTypes,
  clientsActions,
  clientsSelectors,
}

export default reducer