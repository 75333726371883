import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import find from 'lodash/find'

export const getStreetId = (street, streets) => {
  if (isEmpty(street) || isEmpty(streets)) {
    return null
  }

  const routeType = last(street.long_name.split(' '))
  const found = find(streets, str => str.fileBy.includes(routeType))

  return found ? {
    streetStreetTypeId: found.id,
    routeType
  } : null
}