import React from 'react'

import { Row, Col, Button, Form, Input, Descriptions, Select, Card } from 'antd'

export const ClientNewStep3 = ({
  countriesList, onPhoneInputChange, streetCodes, ...rest
}) => {
  const getOptionProps = ({ full_name }) => ({ children: full_name })

  const left = [
    {
      label: 'Telephone (home)',
      name: 'tel_home'
    },
    {
      label: 'Telephone (work)',
      name: 'tel_work'
    },
    {
      label: 'Telephone (mobile)',
      name: 'tel_mobile'
    }
  ]
  // console.log(rest)
  // console.log(rest.clientCategoriesList.find(ele => ele.id == `${rest.client.categoryId}`)?.file_by)
  return (
    <>
      <p>
        You are creating a new {rest.clientCategoriesList.find(ele => ele.id == `${rest.client.categoryId}`)?.file_by} record.
        The new record is for {rest.client.first_name} {rest.client.last_name} ({rest.client.email}).
      </p>
      <Row gutter={[96, 24]}>
        <Col xs={12}>
          <Card title={<h3>Telephone</h3>} bordered={false}>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => {
                const {
                  tel_home, tel_work, tel_mobile
                } = getFieldsValue(['tel_home', 'tel_work', 'tel_mobile'])
                return (
                  <Descriptions
                    bordered
                    layout='vertical'
                    size='small'
                    column={{
                      xs: 1,
                      sm: 1
                    }}
                  >
                    <Descriptions.Item label='Telephone (Home)'>{tel_home || 'none'}</Descriptions.Item>
                    <Descriptions.Item label='Telephone (Work)'>{tel_work || 'none'}</Descriptions.Item>
                    <Descriptions.Item label='Mobile'>{tel_mobile || 'none'}</Descriptions.Item>
                  </Descriptions>
                )
              }}
            </Form.Item>
          </Card>
        </Col>

        <Col xs={12}>
          <Card title={<h3>Email</h3>} bordered={false}>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => {
                const { email, email_secondary } = getFieldsValue(['email', 'email_secondary'])
                return (
                  <Descriptions
                    bordered
                    layout='vertical'
                    size='small'
                    column={{
                      xs: 1,
                      sm: 1
                    }}
                  >
                    <Descriptions.Item label='E-mail'>{email}</Descriptions.Item>
                    <Descriptions.Item label='E-mail secondary'>{email_secondary || '-'}</Descriptions.Item>
                  </Descriptions>
                )
              }}
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={[96, 24]}>

        <Col xs={12}>
          <Card title={<h3>Street Address</h3>} bordered={false}>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => {
                const {
                  streetCountryId,
                  streetPostcode,
                  streetState,
                  streetStreetName,
                  streetStreetNumber,
                  streetStreetTypeId,
                  streetSuburb,
                  streetUnitNumber
                } = getFieldsValue([
                  'streetCountryId',
                  'streetPostcode',
                  'streetState',
                  'streetStreetName',
                  'streetStreetNumber',
                  'streetStreetTypeId',
                  'streetSuburb',
                  'streetUnitNumber'
                ])
                // console.log(countriesList.find(ele => ele.id == `${streetCountryId}`))
                // console.log(streetCodes)
                // console.log(streetCodes.streetType.find(ele => ele.in == `${streetStreetTypeId}`))
                return (
                  <Descriptions
                    bordered
                    size='small'
                    column={{
                      xs: 1,
                      sm: 1
                    }}
                  >
                    <Descriptions.Item>{
                      `${streetUnitNumber || ''}` + `${streetUnitNumber ? '/' : ''}` + `${streetStreetNumber || ''}` + ' ' +
                      `${streetStreetName || ''}` + ' ' + `${streetCodes.streetType.find(ele => ele.in == `${streetStreetTypeId}`)?.fileBy || ''
                      }`
                      + ' ' +
                      `${streetSuburb || ''}` + ' ' +
                      `${streetState || ''}` + ' ' +
                      `${streetPostcode || ''}` + `${streetCountryId ? ', ' : ''}` +
                      `${countriesList.find(ele => ele.id == `${streetCountryId}`)?.full_name || ''
                      }`}
                    </Descriptions.Item>
                  </Descriptions>
                )
              }}
            </Form.Item>
          </Card>
        </Col>

        <Col xs={12}>
          <Card title={<h3>Postal Address</h3>} bordered={false}>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => {
                const {
                  postalBoxNumber,
                  postalBoxTypeId,
                  postalCountryId,
                  postalPostcode,
                  postalState,
                  postalStreetName,
                  postalStreetNumber,
                  postalStreetTypeId,
                  postalSuburb
                } = getFieldsValue([
                  'postalBoxNumber',
                  'postalBoxTypeId',
                  'postalCountryId',
                  'postalPostcode',
                  'postalState',
                  'postalStreetName',
                  'postalStreetNumber',
                  'postalStreetTypeId',
                  'postalSuburb'
                ])
                // console.log(countriesList.find(ele => ele.id == `${postalCountryId}`))
                // console.log(streetCodes)
                // console.log(streetCodes.streetType.find(ele => ele.in == `${postalStreetTypeId}`))
                return (
                  <Descriptions
                    bordered
                    size='small'
                    column={{
                      xs: 1,
                      sm: 1
                    }}
                  >
                    <Descriptions.Item>{
                      `${postalBoxNumber || ''}` + ' ' +
                      `${streetCodes.postalType.find(ele => ele.id == `${postalBoxTypeId}`)?.fileBy || ''
                      }` + ' ' +
                      `${postalBoxNumber ? '/' : ''}` +
                      `${postalStreetNumber || ''}` + ' ' +
                      `${postalStreetName || ''}` + ' ' +
                      `${streetCodes.streetType.find(ele => ele.in == `${postalStreetTypeId}`)?.fileBy || ''
                      }` + ' ' +
                      `${postalSuburb || ''}` + ' ' +
                      `${postalState || ''}` + ' ' +
                      `${postalPostcode || ''}` + `${postalCountryId ? ', ' : ''}` +
                      `${countriesList.find(ele => ele.id == `${postalCountryId}`)?.full_name || ''
                      }`
                    }
                    </Descriptions.Item>
                  </Descriptions>
                )
              }}
            </Form.Item>
          </Card>
        </Col>

      </Row>
      <p>
        Press “Confirm” below to proceed.
      </p>
    </>
  )
}