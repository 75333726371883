const saveFile = (filename: string, blob: Blob) => {

  // see: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link

  // get downloadable url from the blob
  const blobUrl = URL.createObjectURL(blob)

  // create temp link element
  let link: HTMLAnchorElement | null = document.createElement('a')

  link.download = filename
  link.href = blobUrl

  // use the link to invoke a download
  document.body.appendChild(link)
  link.click()

  // remove the link
  setTimeout(() => {
    link?.remove()
    window.URL.revokeObjectURL(blobUrl)
    link = null
  }, 0)
}

export default saveFile
