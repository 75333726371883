import React, {
  useState,
  useEffect
} from 'react'

import {createUseStyles} from 'react-jss'
import {connect} from 'react-redux'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients/index'

import {appStateSelectors} from 'configs/ducks/appState'

import {
  inputChange,
  clearState
} from 'configs/actions/common'
import PhoneInput from 'react-phone-input-2'

import { PREFERRED_COUNTRIES } from 'consts'
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Spin,
  Space,
  InputEmail
} from 'components'
import {FormSelect} from 'common'
import { API } from 'aws-amplify'
import {languages} from './client_view/utils.js'
import {showNotification} from 'utils'
import {
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons'

import ReactCountryFlag from 'react-country-flag'

const useStyles = createUseStyles({
  stepsContent: {
    minHeight: 280,
    paddingTop: 48
  },
  space: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const ClientNewBulkPageComponent = ({
  clearState,
  checkClientsEmail,
  inputChange,
  createClient,
  loading,
  client,
  duplicateEmail,
  clientCategoriesList,
  currentAccountID,
  countriesList,
  history
}) => {
  const classes = useStyles()
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  useEffect(() => {
    if (form.isFieldTouched('email')) {
      form.validateFields()
    }
  }, [duplicateEmail])

  const onLanguageSelectChange = value => {
    setState({
      ...state,
      preferred_language: value
    })
  }

  const onFinish = async values => {
    const bulkData = []
    if (values?.users[0]) {
      bulkData.push({
        categoryId: values?.categoryId ? values.categoryId : 3,
        preferred_language: values?.preferred_language ? values.preferred_language : 'en',
        account_id: currentAccountID,
        email: values?.users[0].email,
        first_name: values?.users[0].first_name,
        last_name: values?.users[0].last_name,
        tel_mobile: values?.users[0].tel_mobile
      })
    }
    if (values?.users[1]) {
      bulkData.push({
        categoryId: values?.categoryId ? values.categoryId : 3,
        preferred_language: values?.preferred_language ? values.preferred_language : 'en',
        account_id: currentAccountID,
        email: values?.users[1].email,
        first_name: values?.users[1].first_name,
        last_name: values?.users[1].last_name,
        tel_mobile: values?.users[1].tel_mobile
      })
    }
    if (values?.users[2]) {
      bulkData.push({
        categoryId: values?.categoryId ? values.categoryId : 3,
        preferred_language: values?.preferred_language ? values.preferred_language : 'en',
        account_id: currentAccountID,
        email: values?.users[2].email,
        first_name: values?.users[2].first_name,
        last_name: values?.users[2].last_name,
        tel_mobile: values?.users[2].tel_mobile
      })
    }
    if (values?.users[3]) {
      bulkData.push({
        categoryId: values?.categoryId ? values.categoryId : 3,
        preferred_language: values?.preferred_language ? values.preferred_language : 'en',
        account_id: currentAccountID,
        email: values?.users[3].email,
        first_name: values?.users[3].first_name,
        last_name: values?.users[3].last_name,
        tel_mobile: values?.users[3].tel_mobile
      })
    }
    if (values?.users[4]) {
      bulkData.push({
        categoryId: values?.categoryId ? values.categoryId : 3,
        preferred_language: values?.preferred_language ? values.preferred_language : 'en',
        account_id: currentAccountID,
        email: values?.users[4].email,
        first_name: values?.users[4].first_name,
        last_name: values?.users[4].last_name,
        tel_mobile: values?.users[4].tel_mobile
      })
    }

    try {
      setSubmitting(true)
      await API.post('clients', '/create-bulk', {body: bulkData})
      resetState()
      showNotification('success', 'clients', 'Successfully Created')
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'clients', error.message)
      setSubmitting(false)
    }
    setSubmitting(false)
  }

  const getOptionProps = ({file_by}) => ({children: file_by})

  const resetState = () => {
    form.setFieldsValue({ 'categoryId': '' })
    form.setFieldsValue({ 'users': [] })
  }
  
  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      // onValuesChange={onValuesChange}
    >

      <Spin spinning={loading}>
        <Row gutter={[12, 0]}>
          <Col xs={12}>
            <FormSelect
              label='Record Type'
              name='categoryId'
              placeholder='Select Category'
              optionValue='id'
              getOptionProps={getOptionProps}
              options={clientCategoriesList} />
          </Col>
          <Col xs={12}>
            <FormSelect
              label='Langauge'
              name='preferred_language'
              placeholder='Select Languages'
              options={languages}
              optionValue='value'
              getOptionProps={({flag, text}) => ({
                children: (
                  <>
                    <ReactCountryFlag
                      {...flag}
                      title={text}
                      style={{
                        fontSize: '1.5em',
                        lineHeight: '1.5em'
                      }}
                    />
                    {text}
                  </>
                )
              })}
              onChange={onLanguageSelectChange}
            />
          </Col>
        </Row>
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({
                key, name, ...restField
              }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'first_name']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing first name',
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'last_name']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing last name',
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                  <InputEmail
                    required
                    name={[name, 'email']}
                    placeholder='placeholders.email'
                  />

                  <Form.Item
                    {...restField}
                    name={[name, 'tel_mobile']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing tel_mobile',
                      },
                    ]}
                  >
                    <PhoneInput
                      inputStyle={{width: '100%'}}
                      preferredCountries={PREFERRED_COUNTRIES}
                      masks={{au: '(.) ....-....'}}
                      enableSearch
                      disableCountryGuess={false}
                    
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              {fields.length < 5 ? (
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add New Row
                  </Button>
                </Form.Item>
              ) : ''
              }
            </>
          )}
        </Form.List>
      </Spin>
      <Space className={classes.space}>
        <Button loading={submitting} type='primary' htmlType='submit'>
          Create Records
        </Button>
      </Space>
    </Form>
  )
}

const {createClient, checkClientsEmail} = clientsActions

const {newClientSelector} = clientsSelectors

const mapStateToProps = state => {
  const {
    duplicateEmail, loading, client
  } = newClientSelector(state)
  const {
    currentAccountRecord: {id: currentAccountID},
    countriesList,
    clientCategoriesList
  } = appStateSelectors.stateSelector(state)
  return {
    clientCategoriesList,
    duplicateEmail,
    loading,
    client,
    countriesList,
    currentAccountID
  }
}

export default connect(mapStateToProps, {
  clearState,
  inputChange,
  createClient,
  checkClientsEmail
})(ClientNewBulkPageComponent)