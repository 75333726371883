import * as React from 'react'
import {
  Button,
  Descriptions,
  Divider,
  Form,
  Modal,
  Typography,
  notification
} from 'antd'
import InputCheckbox from 'components/input-checkbox'
import InputEmail from 'components/input-email'
import {
  ClientsSelect,
  InputSelect
} from 'components/input-select'
import InputText from 'components/input-text'
import {languages} from 'consts'
import {useSelector} from 'hooks'
import {API} from 'utils'
import {ClientInformationRequest} from 'types'

interface CreateInformationRequestModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: (record: ClientInformationRequest) => void;
}

const CreateInformationRequestModal: React.FC<CreateInformationRequestModalProps> = ({
  open, onCancel, onSuccess
}) => {
  const [loading, toggleLoading] = React.useState(false)

  return (
    <Modal
      title='New Information Request Records'
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <Form
        layout='vertical'
        initialValues={{language: 'zh'}}
        preserve={false}
        onFinish={async values => {
          try {
            toggleLoading(true)

            const {payload} = await API.post('information-requests', '/create', {
              body: {
                language: values.language,
                clientId: values.clientID,
                activateFinancial: values.activateFinancial,
                activateEdd: values.activateEdd,
                thirdParty: values.differentRecipient,
                recipientFirstName: values.recipientFirstName,
                recipientLastName: values.recipientLastName,
                recipientEmail: values.recipientEmail
              }
            })

            toggleLoading(false)

            onSuccess(payload.createdRecord)

            notification.success({message: 'New Record has been created'})
          } catch (e: any) {
            notification.error({message: e?.response?.data?.error || e.message})
            toggleLoading(false)
          }
        }}
      >
        <ClientsSelect
          required
          name='clientID'
        />
        <InputCheckbox
          name='differentRecipient'
          label='informationRequest.differentRecipient'
        />
        <Form.Item dependencies={['differentRecipient']} noStyle>
          {({getFieldValue}) => {
            const differentRecipient = getFieldValue('differentRecipient')

            return differentRecipient && (
              <>
                <InputText
                  required
                  name='recipientFirstName'
                  label='common.firstName'
                />
                <InputText
                  required
                  name='recipientLastName'
                  label='common.lastName'
                />
                <InputEmail
                  required
                  name='recipientEmail'
                  label='common.email'
                />
              </>
            )
          }}
        </Form.Item>
        <Divider/>
        <Typography.Title level={4}>Options</Typography.Title>
        <InputSelect
          name='language'
          label='common.language'
          options={languages}
          getOptionProps={opt => ({
            value: opt.value,
            children: opt.text
          })}
        />
        <InputCheckbox
          name='activateFinancial'
          label='informationRequest.activateFinancial'
        />
        <InputCheckbox
          name='activateEdd'
          label='informationRequest.activateEdd'
        />
        <Button loading={loading} htmlType='submit' type='primary'>
          Create
        </Button>
      </Form>
    </Modal>
  )
}

export default CreateInformationRequestModal
