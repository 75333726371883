import * as React from 'react'
import {
  Row,
  Col,
  Divider
} from 'components'
import moment from 'moment'
import {useSelector} from 'hooks'
import {
  FULL_DATE_TIME_FORMAT,
  COMMON_COL_PROPS,
  ROW_GUTTER
} from 'consts'
import {StatusIndicatorButtons} from './components'
import {TabsHeaderProps} from './types'

const TITLE_MAP: Record<TabsHeaderProps['activeTab'], string> = {
  contact: 'Client Contact Information',
  risk: 'Client Risk Score',
  documents: 'Client Documentation',
  personal: 'Client Personal Information',
  financial: 'Client Financial Information',
  knowledge: 'Client Knowledge Status',
  verification: 'Client Verification Status',
  agreements: 'Client Agreement Status',
} as const

const TabsHeader: React.FC<TabsHeaderProps> = (
  {
    activeTab,
    clientRecord,
    clientStatusIndicator,
    updateRecord
  }
) => {
  const title = TITLE_MAP[activeTab]
  const staffList = useSelector((state) => state.staffs.entities)

  if (!title) {
    return null
  }

  const staff = staffList.find(item => item.id === clientStatusIndicator?.staffID)

  return (
    <Row
      gutter={ROW_GUTTER}
      justify='space-between'
    >
      <Col {...COMMON_COL_PROPS}>
        {staff && `Last Modified Staff: ${staff.first_name} ${staff.last_name}`}
        <br />
        {clientStatusIndicator?.updatedAt && `Last Modified Date/Time: ${moment(clientStatusIndicator.updatedAt).format(FULL_DATE_TIME_FORMAT)}`}
      </Col>
      <Col
        {...COMMON_COL_PROPS}
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <StatusIndicatorButtons
          activeTab={activeTab}
          clientID={clientRecord.id}
          clientDeleted={clientRecord.deletedFlag}
          clientStatusIndicator={clientStatusIndicator}
          updateRecord={updateRecord}
        />
      </Col>
      <Divider/>
    </Row>
  )
}

export default TabsHeader
