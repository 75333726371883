import React, {Component} from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import ClientItemsNewModal from './checklist_add_client_items/ClientItemsNewModal'
import {showNotification} from 'utils'
import {connect} from 'react-redux'

import {
  Row,
  Col,
  Select,
  Button,
  notification,
  Form,
  Input,
  Switch,
  Table,
  Card,
  Modal,
  Popconfirm,
  Checkbox,
  Tag
} from 'antd'

import {DeleteOutlined} from '@ant-design/icons'
const {Option} = Select

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class ClientChecklist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_saving: false,
      checklistClientItemsList: [],
      loading: true,
      isModalVisible: false,
      currentStaffID: props.staffId,
      collections_list_select_options: [],
      documentChecklistCollectionID: ''
    }
  }

  async componentDidMount() {
    await this.handleRefresh()
    await this.getCollectionsRecord()
  }

  handleRefresh = async () => {
    try {
      const checklistClientItems = await this.getClientItemsRecord(this.props.client_id)
      this.setState({
        checklistClientItemsList: checklistClientItems.payload,
        loading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  async getClientItemsRecord(clientID) {
    return API.get('doc-check', `/client-items/client/${clientID}`)
  }

  async getCollectionsRecord() {

    API.get('doc-check', `/collections/account/${this.props.currentAccountId}`).then(response => {
      const collections_list_select_options = response.payload.map(d => <Option key={d.id}>{`[${d.id}] ${d.title} [${d.number_of_collections} item(s)]`}</Option>)
      this.setState({collections_list_select_options: collections_list_select_options})
    })
      .catch(error => {
        console.log(error)
      })
  }

  validate_step_1 = async () => {
    let validate = true

    if (!(this.state.documentChecklistCollectionID.length > 0)) {
      validate = false
    }

    return validate
  };

  onHandleSubmit = async event => {
    let validate = true
    validate = await this.validate_step_1()

    if (validate) {
      await API.post('doc-check', '/collections/add-to-client', {
        body: {
          clientID: this.props.client_id,
          documentChecklistCollectionID: this.state.documentChecklistCollectionID
        }
      })
        .then(database_result => {
          openNotificationWithIcon('success', 'Record Created', 'New Record has been successfully created.')
          return database_result
        })
        .then(database_result => {
          this.handleRefresh()
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  updateCompletedFlags = async (updatedItem) => {
    try {
      await API.put('doc-check', `/complete-client-items/id/${updatedItem.id}`, {
        body: {
          'completedStaffID': !updatedItem.completed ? this.state.currentStaffID : null,
          'completed': !updatedItem.completed
        }
      })
      showNotification('success', 'Client Items', 'Client Items Saved')
    } catch (error) {
      showNotification('error', 'Client Items', error.message)
    }
    await this.handleRefresh()
  }

  checklistClientItemsListColumns = [
    {
      title: 'Item ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Required',
      dataIndex: 'required',
      render: (value) =>
        Boolean(value) ? 'Yes' : 'No'
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      sorter: (a, b) => a.completed - b.completed,
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      onFilter: (value, record) => record.completed === value,
      render: (completed, record) => (
        <>
          <Row gutter={8}>
            <Col>
              <Checkbox
                checked={completed === 1}
                onChange={e => {
                  this.updateCompletedFlags({
                    ...record,
                    completed: !e.target.checked
                  })
                }}
              />
            </Col>
            <Col>
              {!completed ? (
                <Tag color='red' style={{textAlign: 'center'}}>
                    No
                </Tag>
              ) : (
                <span>{
                  record.completedDatetime.split('T')[0]
                } [{record.completedStaff}]</span>
              )}
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Action',
      render: (text, record) => (
        <span>
          <Popconfirm
            title='Are you sure to delete this record?'
            onConfirm={() => this.deleteRecord(record.id)}
            okText='Yes'
            onCancel='No'
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        </span>
      )
    }
  ]

  deleteRecord = async (id) => {
    try {
      await API.put('doc-check', `/client-items/delete-item/${id}`, {body: {}})
        .then(async response => {
          console.log(response)
          showNotification('success', 'Client Items', 'Successfully Deleted')
        })
        .catch(error => {
          console.log(error)
          showNotification('error', 'API', error.message)
        })
    } catch (e) {
      showNotification('error', 'API', e.message)
    }
    await this.handleRefresh()
  }

  toggleModal = bool => {
    this.setState({isModalVisible: !bool})
  }

  onHandleClickAddItem = () => {
    this.setState({buttonLoading: true})
    this.toggleModal(this.state.isModalVisible)
    this.setState({buttonLoading: false})
  }

  onHandleSelectChange = value => {
    this.setState({documentChecklistCollectionID: value})
  };

  render() {
    return <>
      <Card
        style={{minHeight: 360}}
        title=''
        extra={
          <Button
            loading={this.state?.buttonLoading}
            onClick={this.onHandleClickAddItem}
          > Add Item </Button>
        }
      >
        <Form>
          <Row gutter={[24, 0]}>
            <Col xs={12}>
              <Form.Item
                label='Collection ID'
                required
              >
                <Select
                  id='documentChecklistCollectionID'
                  showSearch
                  value={
                    typeof this.state.documentChecklistCollectionID === 'number'
                      ? this.state.documentChecklistCollectionID.toString()
                      : this.state.documentChecklistCollectionID
                  }
                  placeholder='Select Collection'
                  showArrow={true}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={this.onHandleSelectChange}
                >
                  {this.state.collections_list_select_options}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label=''>
                <Button type='primary' onClick={this.onHandleSubmit}>
                Add Collection
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey='id'
          loading={this.state.loading}
          style={{width: '100%'}}
          scroll={{x: true}}
          columns={this.checklistClientItemsListColumns}
          dataSource={this.state?.checklistClientItemsList}
          size='small'
          pagination={{
            defaultPageSize: 5,
            showSizeChanger: false
          }}
        />
        <ClientItemsNewModal
          isModalVisible={this.state.isModalVisible}
          toggleModal={this.toggleModal}
          clientID={this.props.client_id}
          handleRefresh={this.handleRefresh}
        />
      </Card>
    </>
  }
}

export default ClientChecklist