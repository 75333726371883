import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  Table,
  NavLink,
  EditOutlined
} from 'components'

import {useSelector} from 'hooks'
import {listHOC} from 'hocs'
import {API} from 'utils'
import dayjs from 'dayjs'

/**
 * @param {Function} getColumnSearchProps
 * @param {Array} entities
 * @param {Boolean} loading
 * @returns {JSX.Element}
 * @constructor
 */

const ClientClosureListPageComponent = ({getColumnSearchProps}) => {
  const [withNullRecords, setWithNullRecords] = useState([])

  const [withMatchedRecords, setWithMatchedRecords] = useState([])
  const currentAccountID = useSelector(state => state.appState.currentAccountRecord.id)

  useEffect(() => {
    const fn = async () => {
      const [{payload: withNullRecords}, {payload: withMatchedRecords}] = await Promise.all([
        API.get('clients-acc-close', `/get-closure-by-accountId/${currentAccountID}`),
        API.get('clients', `/getSimpleClosureListByAccountId/${currentAccountID}`)
      ])
      setWithNullRecords(withNullRecords)
      setWithMatchedRecords(withMatchedRecords)
    }

    fn()
  }, [currentAccountID])

  const nullRecordsColumns = [
    {
      title: 'Requested',
      dataIndex: 'dateRequested',
      render: (text, record) => {
        return text ? dayjs(text).format('DD/MM/YYYY') : ''
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, record) => {
        return text ? text : ''
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: (text, record) => {
        return text ? text : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'statusId',
      defaultFilteredValue: [],
      filters: [
        {
          text: 'Pending',
          value: '1',
        },
        {
          text: 'In Process',
          value: '2',
        },
        {
          text: 'Complete Not Actioned',
          value: '3'
        },
        {
          text: 'Complete Actioned',
          value: '4'
        }
      ],
      onFilter: (value, record) => record.statusId === Number(value),
      render: (text, record) => {
        // return text ? text : ''
        switch (text){
          case 1:
            return 'Pending'
          case 2:
            return 'In Process'
          case 3:
            return 'Complete Not Actioned'
          case 4:
            return 'Complete Actioned'
          default:
            return 'Error'
        }
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return text ? <NavLink
          to={`/clients/accountClosures/view/${record.id}`}
        >{<EditOutlined />}</NavLink>
          : ''
      }
    }
  ]

  const columns = [
    {
      title: 'Requested',
      dataIndex: 'dateRequested',
      render: (text, record) => {
        return text ? dayjs(text).format('DD/MM/YYYY') : ''
      }
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      sorter: (a, b) => a.clientId - b.clientId,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'clientId',
        placeholder: 'ID',
        render: (highlighter, record) => <NavLink to={`/clients/view/${record.clientId}?tab=summary`}>{highlighter}</NavLink>
      })
    },
    {
      title: 'Name',
      dataIndex: 'file_by',
      ...getColumnSearchProps({
        dataIndex: 'file_by',
        placeholder: 'File By'
      })
    },
    {
      title: 'Ref',
      dataIndex: 'account_user_id',
      sorter: (a, b) => a.account_user_id - b.account_user_id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'account_user_id',
        placeholder: 'Account ID',
        render: (highlighter, record) => <NavLink to={`/clients/view/${record.clientId}?tab=account`}>{highlighter}</NavLink>
      })
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps({
        dataIndex: 'email',
        placeholder: 'Email'
      })
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: (text, record) => {
        return text ? text : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'statusId',
      defaultFilteredValue: ['1','2'],
      filters: [
        {
          text: 'Pending',
          value: '1',
        },
        {
          text: 'In Process',
          value: '2',
        },
        {
          text: 'Complete Not Actioned',
          value: '3'
        },
        {
          text: 'Complete Actioned',
          value: '4'
        }
      ],
      onFilter: (value, record) => record.statusId === Number(value),
      render: (text, record) => {
        // return text ? text : ''
        switch (text){
          case 1:
            return 'Pending'
          case 2:
            return 'In Process'
          case 3:
            return 'Complete Not Actioned'
          case 4:
            return 'Complete Actioned'
          default:
            return 'Error'
        }
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <Fragment>
            <NavLink to=
              {`/clients/accountClosures/view/${record.id}`}>
              <EditOutlined />
            </NavLink>
          </Fragment>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <br/>
      <h3> Account Closure Requests </h3>
      <div>
        <Table
          rowKey='id'
          columns={columns}
          dataSource={withMatchedRecords}
          scroll={{x: true}}
          pagination={{
            total: withMatchedRecords.length,
            showTotal: total => `Total ${total} records`,
            pageSizeOptions: ['20', '50', '100'],
            defaultPageSize: 20,
            showSizeChanger: true
          }}
        />
      </div>

      <br/>
      <h3> Unmatched Account Closure Requests </h3>
      <div>
        <Table
          rowKey='id'
          columns={nullRecordsColumns}
          dataSource={withNullRecords}
          scroll={{x: true}}
          pagination={{
            total: withNullRecords.length,
            showTotal: total => `Total ${total} records`,
            pageSizeOptions: ['20', '50', '100'],
            defaultPageSize: 20,
            showSizeChanger: true
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default listHOC(ClientClosureListPageComponent)