import React from 'react'
import {
  Descriptions,
  Tag,
  Typography,
  InputDatePicker,
  InputSelect,
  InputNumber,
} from 'components'
import { FormattedMessage } from 'react-intl'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import moment from 'moment'

import {tradingTransactionSelector} from 'configs/ducks/trading/selectors'
import {
  updateStatusID,
  updateTransactionRecord
} from 'configs/ducks/trading/actions'
import {tradingTransactionStatuses} from '../TradingTransactions'

const TransactionDetails = (props) => {
  const dispatch = useDispatch()
  const { tradingTransactionRecord, loading } = useSelector(tradingTransactionSelector)
  const { accountTransactionId, trading_accounts_trading_platform_account_id } = (tradingTransactionRecord || {})
  const showLink = accountTransactionId && trading_accounts_trading_platform_account_id === 3

  const handleChangeDate = (date) => {
    if (date instanceof moment) {
      dispatch(updateTransactionRecord({transactionDate: date.toISOString()}))
    }
  }

  const changeAmount = value => {
    dispatch(updateTransactionRecord({[props.name]: value}))
  }

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id='trading_transaction_detail.transaction_details' />
      </Typography.Title>
      <Descriptions layout="vertical" bordered>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.transaction_id' />} span={2}>
          {tradingTransactionRecord?.id}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.created' />} span={2}>
          {tradingTransactionRecord?.record_created}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.transaction_date' />} span={2}>
          <InputDatePicker
            formItem={false}
            value={tradingTransactionRecord?.transactionDate && moment(tradingTransactionRecord?.transactionDate)}
            onChange={handleChangeDate}
          />
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.status' />} span={2}>
          <InputSelect
            value={tradingTransactionRecord?.status_id ? String(tradingTransactionRecord?.status_id) : '1'}
            isFormItem={false}
            allowClear={false}
            showSearch={false}
            options={Object.keys(tradingTransactionStatuses)}
            onChange={value => dispatch(updateStatusID(tradingTransactionRecord.id, Number(value)))}
            getOptionProps={option => {
              const {text, color} = tradingTransactionStatuses[option]
              return {
                value: option,
                children: <Tag color={color}>{text}</Tag>
              }
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.trading_account_id' />} span={2}>
          {tradingTransactionRecord?.trading_account_id}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.trading_account_platform' />} span={6}>
          {tradingTransactionRecord?.trading_account_platform}
        </Descriptions.Item>
        {showLink && (
          <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.link_to_gat_deposit' />} span={6}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://referrer-admin.gat.technology/trading/deposits/view/${accountTransactionId}`}
            >
              {`https://referrer-admin.gat.technology/trading/deposits/view/${accountTransactionId}`}
            </a>
          </Descriptions.Item>
        )}
        <Descriptions.Item label={<FormattedMessage id='trading_transaction_detail.amount' />} span={6}>
          <InputNumber min="0" value={tradingTransactionRecord?.[props.name]} onChange={changeAmount} />
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

export default TransactionDetails
