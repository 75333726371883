import * as React from 'react'
import {
  Record,
  FileColumn
} from './types'
import {
  Image,
  PdfViewer
} from 'components'

export const getRecordColumns = (record: Record | null) => {
  // because this field is required, we can refer on it and determine the type of the record (identification / proof of address)
  const isPOA = Boolean(record?.pofadd_file_id)

  const columns = isPOA
    ? [
      {
        title: 'File ID',
        dataIndex: 'pofadd_file_id',
        render: (file: FileColumn) => {
          if (!file) {
            return null
          }

          const isPdf = file.type.toLocaleLowerCase().includes('pdf')

          return (
            <>
              <div>
                {isPdf ? <PdfViewer url={file.url} /> : <Image src={file.url} width={50} height={50} />}
              </div>
              <a href={file.url} target='_blank' rel="noreferrer">
                Click here{' '}
              </a>
              to open image file (then press CTRL-S to save)
            </>
          )
        }
      },
      {
        title: 'Other',
        dataIndex: 'pofadd_type_other'
      }
    ]
    : [
      {
        title: 'Image Front',
        dataIndex: 'file_id_front',
        render: (file: FileColumn) => {
          if (!file) {
            return null
          }

          const isPdf = file.type.toLocaleLowerCase().includes('pdf')

          return (
            <>
              <div>
                {isPdf ? <PdfViewer url={file.url} /> : <Image src={file.url} width={50} height={50} />}
              </div>
              <a href={file.url} target='_blank' rel="noreferrer">
                Click here{' '}
              </a>
              to open image file (then press CTRL-S to save)
            </>
          )
        }
      },
      {
        title: 'Image Back',
        dataIndex: 'file_id_back',
        render: (file: FileColumn) => {
          if (!file) {
            return null
          }

          const isPdf = file.type.toLocaleLowerCase().includes('pdf')

          return (
            <>
              <div>
                {isPdf ? <PdfViewer url={file.url} /> : <Image src={file.url} width={50} height={50} />}
              </div>
              <a href={file.url} target='_blank' rel="noreferrer">
                Click here{' '}
              </a>
              to open image file (then press CTRL-S to save)
            </>
          )
        }
      },
      {
        title: 'Document Number',
        dataIndex: 'document_number'
      },
    ]

  return [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    ...columns
  ]
}