export const get_country_value = (country_list, id) => {
  if (id > 0) {
    for (let i = 0; i < country_list.length; i++) {
      if (id === country_list[i].id) {
        return country_list[i].full_name
      }
    }
  } else {
    return ''
  }
}

export const get_country_id = (country_list, country) => {
  if (country.length > 0) {
    for (let i = 0; i < country_list.length; i++) {
      if (country === country_list[i].full_name) {
        return country_list[i].id
      }
    }
  } else {
    return 0
  }
}

export const get_country_shortname_value = (country_list, id) => {
  if (!country_list) {
    return []
  }

  if (id > 0) {
    for (let i = 0; i < country_list.length; i++) {
      if (id === country_list[i].id) {
        return country_list[i].iso_alpha_2
      }
    }
  }

  return country_list.map(c => c.iso_alpha_2)
}

/*
 * GET COUNTRY OBJECT BY ISO NAME
 * @param {String} shortName
 * @param {Array} country_list
 * @returns {}
 */
export const get_country_by_name = (shortName, country_list) => {
  if (!shortName || !country_list) {
    return null
  }

  for (let i = 0; i < country_list.length; i++) {
    if (shortName === country_list[i].iso_alpha_2) {
      return country_list[i]
    }
  }

  return null
}

/*
 * GET COUNTRIES SHORT NAME
 * use to limit result in google place search
 * @param {Array} country_list
 * @returns {Array}
 */

export const get_country_shortname = (country_list) => {
  if (!country_list) {
    return []
  }

  return country_list.map(c => c.iso_alpha_2)
}

export const get_street_type_value = (street_type_list, id) => {
  if (id > 0) {
    for (let i = 0; i < street_type_list.length; i++) {
      if (id === street_type_list[i].id) {
        return street_type_list[i].fileBy
      }
    }
  } else {
    return ''
  }
}