import React, {
  useState,
  useEffect
} from 'react'

import {connect} from 'react-redux'
import {appStateSelectors} from 'configs/ducks/appState'

import * as CSV from 'csv-string'
import {
  Table,
  Upload,
  Button,
  notification
} from 'antd'

import {InboxOutlined} from '@ant-design/icons'
import {API} from 'aws-amplify'
import {clearState} from 'configs/actions/common'

const BackofficeUpdateCSVPageComponent = ({currentAccountID, clearState}) => {
  const [state, setState] = useState({})

  useEffect(() => {
    clearState()
    return clearState
  }, [clearState])

  const showNotificationWithIcon = (
    type,
    header,
    message
  ) => {
    notification[type]({
      message: header,
      description: message,
    })
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    }
  ]

  const fieldUpdateFunction = async (data) => {
    await API.post('clients', '/update-field', {body: {...data}})
      .then((res) => {
        console.log(res)
        if (res.payload.changedRows) {
          showNotificationWithIcon(
            'success',
            'Success',
            'Record updated.'
          )
        } else {
          showNotificationWithIcon(
            'warning',
            'No Match',
            'No match for the given email address in the given account.'
          )
        }
      })
      .catch((err) => {
        console.error(err)
        showNotificationWithIcon(
          'error',
          'Error',
          err.message
        )
      })
  }
  
  const onFieldUpdateClick = async () => {

    showNotificationWithIcon(
      'info',
      'Processing',
      'Processing data has commenced'
    )

    for (let i = 0; i < state.dataSource.length; i++) {
      const record = state.dataSource[i]
      await fieldUpdateFunction({
        clientEmail: record.email,
        accountId: currentAccountID,
        fieldName: 'account_userType',
        fieldValue: record.value
      })
    }
    
    // const promise = state.dataSource.map(user => API.post('clients', '/update-field', {
    //   body: {
    //     clientEmail: user.email,
    //     accountId: currentAccountID,
    //     fieldName: 'account_userType',
    //     fieldValue: user.value
    //   }
    // }))
    // await Promise.all(promise)

    showNotificationWithIcon(
      'success',
      'Processing',
      'Processing data has completed'
    )

  }

  return !state.loaded ? (
    <Upload.Dragger
      name="file"
      accept=".csv"
      multiple={false}
      beforeUpload={(file) => {
        const reader = new FileReader()

        reader.readAsText(file)

        reader.onload = async ({ target }) => {
          if (target && target.result && typeof target.result === 'string') {
            const arr = CSV.parse(target.result)

            const dataSource = []

            arr.forEach(
              (
                [
                  email,
                  value,
                ]
              ) => {
                dataSource.push({
                  email,
                  value
                })
              }
            )

            setState((prev) => ({
              ...prev,
              loaded: true,
              dataSource,
            }))
          }
        }

        return false
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single CSV upload.
      </p>
    </Upload.Dragger>
  ) : (
    
    <>
      <Table
        dataSource={state.dataSource}
        columns={columns}
        rowKey="gmtId"
        scroll={{ x: true }}
        pagination={{
          pageSizeOptions: ['5', '10', '15'],
          defaultPageSize: 5,
          showSizeChanger: true,
        }} />
      {state.dataSource.length > 0 &&
        <>
          <Button
            loading={state.loading}
            onClick={onFieldUpdateClick}
          >
            Update field
          </Button>
        </>
      }
    </>
  )
}

const mapStateToProps = ({language, ...state}) => {
  const {currentAccountRecord} = appStateSelectors.stateSelector(state)
  return {currentAccountID: currentAccountRecord.id}
}

export default connect(mapStateToProps, {clearState})(BackofficeUpdateCSVPageComponent)
