import React, {
  Fragment,
  useEffect
} from 'react'
import {
  connect,
  useDispatch
} from 'react-redux'
import {
  Divider,
  Table,
  Popconfirm,
  Button
} from 'antd'
import {
  Link,
  NavLink
} from 'react-router-dom'
import {
  DeleteOutlined,
  EditOutlined,
  SyncOutlined
} from '@ant-design/icons'

import {listHOC} from 'common'
import {
  teamsActions,
  teamsSelectors
} from 'configs/ducks/teams/index'
import {appStateSelectors} from 'configs/ducks/appState'
import { deleteTeam } from 'configs/ducks/teams/actions'

const TeamListPageComponent = ({
  getColumnSearchProps, entities, loading, getTopics,
  currentAccountID, fetchCb
}) => {

  const dispatch = useDispatch()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
        render: (highlighter, record) =>
          <NavLink to={`/teams/view/${record.id}?tab=summary`}>
            {highlighter}
          </NavLink>
      })
    },
    {
      title: 'Team',
      dataIndex: 'teamName',
      ...getColumnSearchProps({
        dataIndex: 'teamName',
        placeholder: 'Team'
      })
    },
    {
      title: 'Active',
      dataIndex: 'active',
      ...getColumnSearchProps({
        dataIndex: 'active',
        placeholder: 'Active',
        render: (highlighter) =>
          Boolean(highlighter) ? 'Yes' : 'No'
      })
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <Fragment>
            <Link to={`/teams/view/${record.id}?tab=summary`}>
              <EditOutlined />
            </Link>
            <Divider type='vertical'/>
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => deleteRecord(record)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{color: 'red'}} />
            </Popconfirm>
          </Fragment>
        )
      }
    }
  ]
  const deleteRecord = async record => {
    dispatch(deleteTeam(record.id))
  }

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={entities}
      loading={loading}
      scroll={{x: true}}
      pagination={{
        total: entities.length,
        showTotal: total => `Total ${total} records`,
        pageSizeOptions: ['20', '50', '100'],
        defaultPageSize: 20,
        showSizeChanger: true
      }}
    />
  )
}

const {fetchTeams} = teamsActions
const {teamsSelector} = teamsSelectors

const mapStateToProps = state => {
  return {
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id,
    ...teamsSelector(state)
  }
}

export default connect(mapStateToProps, {fetchCb: fetchTeams})(listHOC(TeamListPageComponent))