import * as React from 'react'
import {
  Form,
  Radio,
  RadioGroupProps,
  FormItemProps
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { INPUT_GROUP_OPTIONS } from 'consts'
import {} from 'hooks'
import { XOR } from 'types'

interface InputGroupProps
  extends Omit<RadioGroupProps, 'children' | 'name' | 'options'>,
    Omit<FormItemProps, 'label'> {
  options?: { label: string; value: boolean | string | number }[];
}

interface WithFormProps extends InputGroupProps {
  label?: string;
  name: string | string[];
  formItemClassName?: string;
}

const InputGroup: React.FC<
  XOR<WithFormProps, InputGroupProps & { formItem: boolean }>
> = ({
  name,
  label,
  rules = [],
  required,
  formItem = true,
  options = INPUT_GROUP_OPTIONS,
  optionType = 'button',
  buttonStyle = 'solid',
  className,
  onChange,
  value,
  formItemClassName,
  disabled,
}) => {
  const commonProps = {
    className,
    optionType,
    buttonStyle,
    disabled,
    options: options.map((opt) => ({
      ...opt,
      label: <FormattedMessage id={opt.label} />,
    })),
  }

  if (!formItem) {
    return <Radio.Group {...commonProps} onChange={onChange} value={value} />
  }

  return (
    <Form.Item
      label={label && <FormattedMessage id={label} />}
      name={name}
      className={formItemClassName}
      rules={[
        {
          validator: (rule, value) => {
            // for some reason antd doesn't see this for checkbox
            if (!required) {
              return Promise.resolve()
            }

            if (value === undefined || value === null) {
              return Promise.reject(<FormattedMessage id='validation.required' />)
            }

            return Promise.resolve()
          }
        },
        ...rules,
      ]}
    >
      <Radio.Group {...commonProps} />
    </Form.Item>
  )
}

export default InputGroup
