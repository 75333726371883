import React, {
  Fragment,
  useState
} from 'react'

import {
  showNotification,
  API
} from 'utils'
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  InputDatePicker,
  Select,
  Modal
} from 'components'
import {useForm} from 'antd/lib/form/Form'
import PhoneInput from 'react-phone-input-2'
import {PREFERRED_COUNTRIES} from 'consts'

const { Option } = Select

export const ClientCreateNewOfficeholderComponent = ({
  isModalVisible,
  toggleModal,
  clientId,
  asyncFunction,
  getData,
  countriesList,
  streetCodes
}) => {
  const left = [
    {
      label: 'Telephone',
      name: 'telephone'
    }
  ]

  const [form] = useForm()
  const [telephone, setTelephone] = useState('')
  const [officePosition, setOfficePostion] = useState('')

  const onFinish = async values => {
    try {
      await API.post('client-company', '/create-officeholders', {
        body: {
          ...values,
          telephone,
          officePosition,
          clientId
        }
      })
      showNotification('success', 'Officeholders', 'Successfully Created')
      getData()
    } catch (error) {
      console.error('error occured', error)
      showNotification('error', 'Officeholders', error.message)
    }
    toggleModal(false)

    if (asyncFunction) {
      asyncFunction()
    }
  }

  const onPhoneInputChange = () => value => {
    setTelephone(value)
  }

  const onSelectChange = (value) => {
    setOfficePostion(value)
  }
  
  return (
    <Modal
      title='Create New Officeholder'
      open={isModalVisible}
      footer={null}
      onOk={() => toggleModal(!isModalVisible)}
      onCancel={() => toggleModal(!isModalVisible)}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          firstName: '',
          middleNames: '',
          lastName: '',
          email: '',
          telephone: '',
          address: '',
          other: ''
        }}
      >
        <Form.Item
          name='firstName'
          label='First Name'
          rules={[
            {
              required: true,
              message: 'First Name is Required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='middleNames'
          label='Middle names'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='lastName'
          label='Last name'
          rules={[
            {
              required: true,
              message: 'Last Name is Required'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={[24,0]}>
          <Col xs={10}>
            <Form.Item
              name='officePosition'
              label='Office Position'
              rules={[
                {
                  required: true,
                  message: 'Office Position is Required'
                }
              ]}>
              <Select
                id='officePosition'
                style={{width: '100%'}}
                placeholder={'Please select...'}
                onChange={onSelectChange}
              >
                <Option value="Director">Director</Option>
                <Option value="Secretary">Secretary</Option>
                <Option value="Director/Secretary">Director/Secretary</Option>
                <Option value="President">President</Option>
                <Option value="CEO">CEO</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={14}>
            {officePosition === 'Other' &&
                <Form.Item name='other' label='Other'
                  rules={[
                    {
                      required: Boolean(`${officePosition === 'Other'}`),
                      message: 'Office Position is Required'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
            }
          </Col>
        </Row>
        <Form.Item name='address' label='Address'>
          <Input />
        </Form.Item>
        <Row gutter={[8, 0]}>
          <Col xs={6}>
            <Form.Item name='streetAddressUnitNumber' key='Unit Number' label='Unit Number' shouldUpdate>
              <Input/>
            </Form.Item>
          </Col>
        </Row><Row gutter={[8, 0]}>
          <Col xs={6}>
            <Form.Item name='streetAddressStreetNumber' key='Street Number' label='Street Number' shouldUpdate>
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name='streetAddressStreetName' key='Street name' label='Street name' shouldUpdate>
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item name='streetAddressStreetTypeId' key='Street Type' label='Street Type' shouldUpdate>
              <Select
                showSearch
                showArrow
                placeholder='Select Street'
                optionFilterProp='children'
                onChange={(value) => {
                  form.setFieldsValue({ 'streetAddressStreetTypeId': value })
                } }
              >
                {
                  streetCodes.streetType &&
                  streetCodes.streetType.map(x => (
                    <Option key={x.id} value={x.id}>
                      {x.fileBy}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row><Row gutter={[8, 0]}>
          <Col xs={12}>
            <Form.Item name='streetAddressSuburb' key='Suburb' label='Suburb' shouldUpdate>
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item name='streetAddressState' key='State' label='State' shouldUpdate>
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item name='streetAddressPostcode' key='Post Code' label='Post Code' shouldUpdate>
              <Input/>
            </Form.Item>
          </Col>
        </Row><Row gutter={[8, 0]}>
          <Col xs={12}>
            <Form.Item name='streetAddressCountryId' key='Country' label='Country' shouldUpdate>
              <Select
                showSearch
                showArrow
                placeholder='Select Country'
                optionFilterProp='children'
                onChange={(value) => {
                  form.setFieldsValue({ 'streetAddressCountryId': value })
                } }
              >
                {
                  countriesList.map(({ id, full_name }) => (
                    <Option key={id} value={id}>
                      {full_name}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <InputDatePicker
          name='date_of_birth'
          label='common.dateOfBirth'
          isAfterToday
        />
        <Form.Item name='email' label='Email'>
          <Input />
        </Form.Item>
        <Form.Item name='telephone'>
          {left.map(props => (
            <Form.Item key={props.label} {...props}>
              <PhoneInput
                inputStyle={{width: '100%'}}
                preferredCountries={PREFERRED_COUNTRIES}
                masks={{au: '(.) ....-....'}}
                enableSearch
                disableCountryGuess={false}
                onChange={onPhoneInputChange(props.name)}
              />
            </Form.Item>
          ))}
        </Form.Item>

        <Row justify='end'>
          <Space>
            <Button htmlType='submit' type='primary'>
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}
