import React from 'react'
import {
  Row,
  Col,
  Input,
  Form,
  InputDatePicker,
  Divider
} from 'components'

import {columnSize} from '../../../common'
import moment from 'moment'

const GatMoneyAgreement = () => {
  return (
    <Row gutter={[16, 24]}>
      <Col {...columnSize}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label='Terms & Conditions Agreed'
              name='agreed_terms'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_termsDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_termsNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label='Privacy Policy Agreed'
              name='agreed_privacy'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_privacyDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_privacyNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label='Fees Agreed'
              name='agreed_fees'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_feesDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_feesNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default GatMoneyAgreement