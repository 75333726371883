import React, {useState} from 'react'
import {
  Form,
  Descriptions,
  Typography,
  Input,
  Table,
  Button,
  Modal,
  Popconfirm,
  Row,
  Col
} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import {openNotificationWithIcon} from 'configs/utils'
import generator from 'generate-password'
import {API} from 'aws-amplify'
import {
  useAsync,
  useAsyncFn
} from 'react-use'
import {create_client_cognito_account} from 'library/clients'
import {listHOC} from 'common'

const ClientClientPortal = ({
  getColumnSearchProps, clientRecord, getClientRecord
}) => {
  const [disable, setDisable] = useState(false)
  const [new_password, setNewPassword] = useState()
  const [validPassword, setValidPassword] = useState(false)
  const [new_password_help, setNewPasswordHelp] = useState('')
  const [new_password_status, setNewPasswordStatus] = useState('')
  const [clientModal, setClientModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [form] = Form.useForm()
  const [user_client_id, setUserClientId] = useState('')
  const [aws_cognito_id, setAwsCognitoId] = useState('')

  const deleteRecord = async record => {
    try {
      await deleteUserClientRecord(record.user_id, record.client_id)

      setRefresh(prevState => !prevState)
    } catch (error) {
      console.error(error)
    }
  }

  const handle_reset_password = async () => {
    try {
      await API.post('clients', '/change/password', {
        body: {
          client_id: clientRecord.id,
          new_password: new_password,
        },
      })
      setNewPassword('')
      openNotificationWithIcon(
        'success',
        'Change Successful',
        "The client's password has been changed."
      )
    } catch (e) {
      console.log('Error...')
      openNotificationWithIcon(
        'error',
        'Error Occurred',
        'An error occurred while trying to change password'
      )
    }
  }

  const handleChange = event => {
    setNewPassword(event.target.value)

    function hasNumber(str) {
      return /\d/.test(str)
    }

    function hasLowerCase(str) {
      return (/[a-z]/.test(str))
    }

    function hasUpperCase(str) {
      return (/[A-Z]/.test(str))
    }

    // Validate new password
    if (event.target.value.length > 7 && hasUpperCase(event.target.value) && hasLowerCase(event.target.value) && hasNumber(event.target.value)){
      setValidPassword(true)
      setNewPasswordHelp('')
      setNewPasswordStatus('')
    } else {
      setValidPassword(false)
      setNewPasswordHelp('New password needs to contain at least one Uppercase letter, one Lowercase letter and one Number')
      setNewPasswordStatus('error')
    }
  }

  const columns = [
    {
      title: 'Client ID',
      dataIndex: 'client_id',
      sorter: (a, b) => a.client_id - b.client_id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'client_id',
        placeholder: 'ID',
      })
    },
    {
      title: 'Allow',
      dataIndex: 'allow',
    },
    {
      title: 'Action',
      dataIndex: 'client_id',
      render: (text, record) => {
        return (
          <Popconfirm
            title='Are you sure to delete this record?'
            onConfirm={() => deleteRecord(record)}
            okText='Yes'
            onCancel='No'
          >
            <DeleteOutlined style={{color: 'red'}} />
          </Popconfirm>
        )
      }
    }
  ]

  const clientAccess = useAsync(async () => {
    if (user_client_id) {
      const {payload} = await API.get('user-client', `/access-list-by-id/${user_client_id}`)
      return payload
    }
    return null
  }, [user_client_id, refresh])

  const [submitState, doSubmit] = useAsyncFn(async payload => {
    const response = await API.post('user-client', '/user-client-access', {body: payload})
    return response
  }, [])

  const createClientLogin = async () => {
    if (clientRecord.aws_cognito_id) {
      openNotificationWithIcon('warning', 'client', 'Unable to create client login account')
      return
    }
    setDisable(true)

    const password = generator.generate({
      length: 10,
      numbers: true,
      lowercase: true,
      uppercase: true,
      strict: true
    })

    try {
      await create_client_cognito_account(clientRecord.id, clientRecord.email, password)
      openNotificationWithIcon('success', 'Record Created', 'New client has been successfully created.')
      getClientRecord()
    } catch (error) {
      openNotificationWithIcon('error', 'Failed To', error.message)
    }
  }

  const saveUserClientRecord = async (userClient_record) => {
    await API.post('user-client', '/create/access-record', {body: userClient_record})
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  const deleteUserClientRecord = async (user_client_id, client_id) => {
    await API.put('user-client', `/delete/${user_client_id}/${client_id}`)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const openClientModal = () => {
    setClientModal(true)
  }
  const closeClientModal = () => setClientModal(false)

  const onFinish = async values => {
    const payload = {
      ...values,
      user_client_id: user_client_id
    }
    await saveUserClientRecord(payload)
    setClientModal(false)
    setRefresh(prevState => !prevState)
  }

  const dataSource = clientAccess?.value
    ? clientAccess.value.map((item, index) => ({
      ...item,
      key: `${index}_${item.client_id}`
    }))
    : []

  return (
    <>
      <Typography.Title level={4}> Client Portal</Typography.Title>
      <Form.Item name={'user_client_id'} shouldUpdate>
        {
          <Descriptions
            bordered
            layout='vertical'
            size='small'
            column={{
              xs: 1,
              sm: 2
            }}
          >
            <Descriptions.Item label='User Client ID'>{user_client_id}</Descriptions.Item>
            <Descriptions.Item label='AWS Cognito ID'>{aws_cognito_id}</Descriptions.Item>
          </Descriptions>
        }
      </Form.Item>

      <Button type='primary' disabled={clientRecord.aws_cognito_id || disable} onClick={createClientLogin}>
        Create Client Login
      </Button>

      <Row>
        <Col xs={12}>
          <Form.Item
            label="Change User Password"
            help={new_password_help}
            validateStatus={new_password_status}
            name='new_password'
          >
            <Input.Group>
              <Input
                style={{ width: '70%' }}
                id="new_password"
                onChange={handleChange}
                value={new_password}
              />
              <Button
                style={{ width: '30%' }}
                type="primary"
                disabled={
                  !new_password || !validPassword
                }
                onClick={handle_reset_password}
              >
                Execute Change
              </Button>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>

      <div style={{paddingTop: 30}}>
        <Table loading={clientAccess.loading} dataSource={dataSource} columns={columns} />
        <Button disabled={clientAccess.loading} onClick={openClientModal}>
          Add client to user
        </Button>
      </div>
      <Modal
        title='Add Client to User'
        visible={clientModal}
        onCancel={closeClientModal}
        footer={null}
      >
        <Form form={form} name='add-client-to-user' onFinish={onFinish}>
          <Form.Item
            name='client_id'
            label='Client ID'
            rules={[
              {
                required: true,
                message: 'Please select client!'
              }
            ]}
          >
            <Input
              id="client_id"
            />
          </Form.Item>
          <Button loading={submitState.loading} htmlType='submit' type='primary'>
            Add
          </Button>
        </Form>
      </Modal>
    </>
  )
}
export default listHOC(ClientClientPortal)
