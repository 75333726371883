import React from 'react'

import {
  Row,
  Col,
  SubmitButton,
  ExtendedDescriptions,
  Form,
  Divider,
  Table
} from 'components'
import dayjs from 'dayjs'
import {DATE_FORMAT} from 'consts'
import {
  SectionTitle,
  InteractiveColumn
} from '../shared'
import {useSelector} from 'hooks'
import {Country,} from 'types'
import {Types} from '../../duck'

const FORM_NAME = 'personDetails'

interface PersonalDetailsProps extends Types.CommonProps {
  thirdParty?: boolean;
  eApplicationFormData: any;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  thirdParty,
  eApplicationFormData,
  clientRecord,
  updateClientRecord,
  submitting
}) => {
  const {countriesList} = useSelector(state => ({countriesList: state.appState.countriesList,}))

  const loading = submitting.includes(FORM_NAME)

  return (
    <Form
      name={FORM_NAME}
      layout='vertical'
      initialValues={eApplicationFormData}
    >
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <SectionTitle>
            Personal Detail
          </SectionTitle>
          {!thirdParty ?
            <>
              <Table<Types.InteractiveTableColumn>
                pagination={false}
                columns={[
                  {
                    title: 'Item',
                    dataIndex: 'item',
                  },
                  {
                    title: 'New Data (Info Request)',
                    dataIndex: 'clientFieldName',
                    render: (key, {clientFieldName, formFieldName}) => {
                      let props: Types.InteractiveColumnProps<Country> = {
                        clientFieldName,
                        formFieldName,
                        updateClientRecord,
                        clientRecord,
                        loading
                      }

                      if (key === 'date_of_birth') {
                        props = {
                          ...props,
                          inputType: 'date'
                        }
                      }

                      if (key === 'place_of_birth_country_id') {
                        props = {
                          ...props,
                          inputType: 'select',
                          options: countriesList,
                          getOptionProps: opt => ({
                            value: opt.id,
                            children: opt.full_name
                          })
                        }
                      }

                      return (
                        <InteractiveColumn {...props} />
                      )
                    }
                  },
                  {
                    title: 'Current Client',
                    dataIndex: 'clientFieldName',
                    render: (key, {clientFieldName}) => {
                      const value = clientRecord[clientFieldName]

                      if (key === 'date_of_birth') {
                        return typeof value === 'string' && dayjs(value).format(DATE_FORMAT)
                      }

                      if (key === 'place_of_birth_country_id') {
                        return countriesList.find(c => c.id === value)?.full_name
                      }

                      return value
                    }
                  },
                ]}
                dataSource={[
                  {
                    item: 'First Name',
                    clientFieldName: 'first_name',
                    formFieldName: 'firstName',
                  },
                  {
                    item: 'First Name (EN)',
                    clientFieldName: 'english_first_name',
                    formFieldName: 'firstNameEN',
                  },
                  {
                    item: 'Last Name',
                    clientFieldName: 'last_name',
                    formFieldName: 'lastName',
                  },
                  {
                    item: 'Last Name (EN)',
                    clientFieldName: 'english_last_name',
                    formFieldName: 'lastNameEN',
                  },
                  {
                    item: 'Date Of Birth',
                    clientFieldName: 'date_of_birth',
                    formFieldName: ['birth', 'date'],
                  },
                  {
                    item: 'Place Of Birth City',
                    clientFieldName: 'place_of_birth_city',
                    formFieldName: ['birth', 'place'],
                  },
                  {
                    item: 'Place Of Birth Country',
                    clientFieldName: 'place_of_birth_country_id',
                    formFieldName: ['birth', 'countryId'],
                  },
                ]}
              />
              <SubmitButton loading={loading} />
              <Divider />
            </>
            : (
              <ExtendedDescriptions
                items={[
                  {
                    label: 'First Name',
                    translate: false,
                    value: eApplicationFormData?.firstName,
                  },
                  {
                    label: 'First Name (EN)',
                    translate: false,
                    value: eApplicationFormData?.firstNameEN,
                  },
                  {
                    label: 'Last Name',
                    translate: false,
                    value: eApplicationFormData?.lastName,
                  },
                  {
                    label: 'Last Name (EN)',
                    translate: false,
                    value: eApplicationFormData?.lastNameEN,
                  },
                  {
                    label: 'Date Of Birth',
                    translate: false,
                    value: eApplicationFormData?.birth?.date && dayjs(eApplicationFormData?.birth?.date).format(DATE_FORMAT),
                  },
                  {
                    label: 'Place Of Birth City',
                    translate: false,
                    value: eApplicationFormData?.birth?.place,
                  },
                  {
                    label: 'Place Of Birth Country',
                    translate: false,
                    value: eApplicationFormData?.birth?.country,
                  },
                ]}
              />
            )}
        </Col>
      </Row>
    </Form>
  )
}

export default PersonalDetails