import * as React from 'react'
import {
  Form,
  Row,
  Col,
  InputText,
  InputSelect,
  FileUpload,
  SubmitButton,
  notification,
  FormattedMessage
} from 'components'
import {
  ROW_GUTTER,
  COMMON_COL_PROPS
} from 'consts'
import {
  useSelector,
  useForm,
  useHistory
} from 'hooks'
import {
  API,
  Storage
} from 'utils'
import {routes} from 'configs'

const ViewTemplate = () => {
  const [form] = useForm()
  const history = useHistory()
  const [submitting, toggleSubmitting] = React.useState(false)
  const accountsList = useSelector(state => state.appState.accountsList)

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{file: []}}
      onFinish={async (values) => {
        toggleSubmitting(true)

        try {
          const [file] = values.file

          const bucketConfig = {bucket: 'wordTemplates',} as const

          const {key: awsFileID} = await Storage.put(`${Date.now()}-${file.name}`, file.originFileObj, bucketConfig)

          const newTemplate = await API.post('accounts', '/docw/templates', {
            body: {
              title: values.title,
              awsFileID,
              accountID: values.accountID,
            }
          })

          toggleSubmitting(false)

          history.push(
            routes.documentsWordTemplates,
            {id: newTemplate.id}
          )
        } catch (e: any) {
          toggleSubmitting(false)
          notification.error({message: e.response?.data?.error || e.message})
        }
      }}
    >
      <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
        <Col {...COMMON_COL_PROPS}>
          <Row gutter={ROW_GUTTER}>
            <Col {...COMMON_COL_PROPS}>
              <InputSelect
                required
                name='accountID'
                label='common.account'
                getOptionProps={opt => ({
                  value: opt.id,
                  children: opt.account_name
                })}
                options={accountsList}
              />
            </Col>
            <Col {...COMMON_COL_PROPS}>
              <InputText
                name='title'
                required
                label='common.title'
              />
            </Col>
          </Row>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <FileUpload
            required
            name='file'
            listType='picture'
            maxCount={1}
            accept='.doc, .docx, .msword, .vnd.openxmlformats-officedocument.wordprocessingml.document'
          />
        </Col>
      </Row>
      <SubmitButton loading={submitting} />
    </Form>
  )
}

export default ViewTemplate