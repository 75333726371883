import React, {Component} from 'react'
import {connect} from 'react-redux'

import {API} from 'aws-amplify'

import {Row, Col, Select,Button,notification,Form, Input} from 'antd'

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  })
}

class NewAccountSinglePage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const {Option} = Select

    let staff_list_select_options = {}
    API.get('staff', '/list').then(response => {
      staff_list_select_options = response.payload.map(d => <Option key={d.id}>{d.file_by}</Option>)
      this.setState({staff_list_select_options: staff_list_select_options})
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {}

  validate_step_1 = async () => {
    let validate = true

    if (!(this.props.accounts.new_account_data.file_by.length > 2)) {
      await this.props.updateNewAccountCreation('file_by_status', 'error')
      validate = false
    }

    if (!(this.props.accounts.new_account_data.admin_user_id.length > 0)) {
      await this.props.updateNewAccountCreation('admin_user_id_status', 'error')
      validate = false
    }

    return validate
  };

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value})
    this.props.updateNewAccountCreation(event.target.id, event.target.value)
    this.props.updateNewAccountCreation(`${event.target.id}_status`, 'success')
  };

  handleSelectChange_admin = value => {
    this.setState({admin_user_id: value})
    this.props.updateNewAccountCreation('admin_user_id', value)
    this.props.updateNewAccountCreation('admin_user_id_status', 'success')
  };

  handleSubmit = async event => {
    let validate = true
    validate = await this.validate_step_1()

    if (validate) {
      await API.post('accounts', '/create', {
        body: {
          admin_user_id: this.props.accounts.new_account_data.admin_user_id,
          file_by: this.props.accounts.new_account_data.file_by
        }
      })
        .then(database_result => {
          openNotificationWithIcon('success', 'Record Created', 'New account has been successfully created.')
          return database_result
        })
        .then(database_result => {
          this.props.history.push('/account/list')
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  render() {

    return (
      <Form layout='vertical'>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item
                  label='File By (Nickname)'
                  style={{marginBottom: 0}}
                  validateStatus={this.props.accounts.new_account_data.file_by_status}
                  hasFeedback
                  help={this.props.accounts.new_account_data.file_by_status === 'error' ? 'File By is required' : ''}
                >
                  <Input
                    id='file_by'
                    value={this.props.accounts.new_account_data.file_by}
                    onChange={event => {
                      this.handleChange(event)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label='Admin User ID'
                  style={{marginBottom: 0}}
                  validateStatus={this.props.accounts.new_account_data.admin_user_id_status}
                  hasFeedback
                  help={
                    this.props.accounts.new_account_data.admin_user_id_status === 'error'
                      ? 'Admin User ID is required'
                      : ''
                  }
                >
                  <Select
                    id='admin_user_id'
                    showSearch
                    value={
                      typeof this.state.admin_user_id === 'number'
                        ? this.state.admin_user_id.toString()
                        : this.state.admin_user_id
                    }
                    placeholder='Select Admin'
                    showArrow={true}
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    onChange={this.handleSelectChange_admin}
                  >
                    {this.state.staff_list_select_options}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[96, 24]}>
          <Col xs={24} style={{textAlign: 'right'}}>
            <Button type='primary' onClick={this.handleSubmit}>
              Save Record
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default NewAccountSinglePage
