import React, {
  useState,
  useCallback,
  useEffect
} from 'react'
import {
  Form,
  Input,
  Select,
  Skeleton
} from 'antd'
import {API} from 'aws-amplify'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import {
  settingsActions,
  settingsSelectors
} from 'configs/ducks/settings'
import {InputSelect} from 'components'
import {showNotification} from 'utils'

const {Option} = Select

const layout = {
  labelCol: {span: 9},
  wrapperCol: {span: 15}
}

const ClientCreateFinancialAccount = ({data}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [state, setState] = useState({loading: true})
  const [selectedType, setSelectedType] = useState(0)
  const {financialAccountTypes} = useSelector(settingsSelectors.stateSelector)
  const [currencies, setCurrencies] = useState([])
  const [cryptoCurrencies, setCryptoCurrencies] = useState([])

  useEffect(async () => {
    try {
      setState({
        ...state,
        loading: true,
      })

      await API.get('currencies', '/list')
        .then(({payload}) => setCurrencies(payload))
        .catch(() => {
          showNotification('error', 'Error', 'Failed to fetch currencies')
        })

      await API.get('currencies', '/crypto/list')
        .then(({payload}) => setCryptoCurrencies(payload))
        .catch(() => {
          showNotification('error', 'Error', 'Failed to fetch currencies')
        })

      setState({
        ...state,
        loading: false
      })
    } catch (error) {
      console.log('err', error)
      setState({
        ...state,
        loading: true
      })
    }
  }, [])

  useEffect(() => {
    if (data?.id) {
      form.setFieldsValue({
        bankAccountNumber: data?.bankAccountNumber,
        currencyId: data?.currencyId,
        walletAddress: data?.walletAddress,
        fileBy: data?.fileBy,
        coinId: data?.coinId,
        financialAccountType: data?.financialAccountType,
        gatMoneyXeondaID: data?.gatMoneyXeondaID
      })
      setSelectedType(data?.financialAccountType)
    }
  }, [data, form])

  useEffect(() => {
    dispatch(settingsActions.getFinancialAccountTypes())
  }, [dispatch])

  const getSubForm = () => {
    if (selectedType === 1) {
      return (
        <React.Fragment>
          <InputSelect
            required
            name='currencyId'
            label='common.currency'
            options={currencies}
            getOptionProps={option => ({
              children: option.iso_alpha_3,
              value: option.id
            })}
            disabled
          />
          <Form.Item
            name='bankAccountNumber'
            label='Bank Account Number'
            rules={[
              {
                required: true,
                message: 'Please input bank account!'
              }
            ]}
          >
            <Input disabled/>
          </Form.Item>
        </React.Fragment>
      )
    }

    if (selectedType === 2) {
      return (
        <React.Fragment>
          <Form.Item
            name='walletAddress'
            label='Wallet Address'
            rules={[
              {
                required: true,
                message: 'Please input Wallet Address!'
              },
            ]}
          >
            <Input disabled/>
          </Form.Item>
          <InputSelect
            required
            name='coinId'
            label='common.coin'
            options={cryptoCurrencies}
            getOptionProps={option => ({
              children: option.code,
              value: option.id
            })}
          />
        </React.Fragment>
      )
    }

    if (selectedType === 3) {
      return (
        <React.Fragment>
          <Form.Item
            name='gatMoneyXeondaID'
            label='Xeonda Client ID'
            rules={[
              {
                required: true,
                message: 'Please enter Xeonda Client ID'
              },
            ]}
          >
            <Input disabled/>
          </Form.Item>
        </React.Fragment>
      )
    }
    return null
  }

  const handleChangeType = useCallback(
    value => {
      setSelectedType(value)
      form.setFieldsValue({financialAccountType: value})
    },
    [setSelectedType, form]
  )

  return (
    <Skeleton loading={state.loading}>
      <Form {...layout} form={form} name='create-financial-account'>
        <Form.Item
          name='fileBy'
          label='Account Name'
          rules={[
            {
              required: true,
              message: 'Please input account name!'
            }
          ]}
        >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          name='financialAccountType'
          label='Account Type'
          rules={[
            {
              required: true,
              message: 'Please select account type!'
            }
          ]}
        >
          <Select onChange={handleChangeType} disabled>
            {React.Children.toArray(financialAccountTypes?.map(type => <Option value={type.id}>{type.fileBy}</Option>))}
          </Select>
        </Form.Item>
        {getSubForm()}
      </Form>
    </Skeleton>
  )
}

export default React.memo(ClientCreateFinancialAccount)
