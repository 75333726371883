import React from 'react'

import {
  Form,
  Input
} from 'antd'

function InputEmail(
  {
    hasFeedback = true,
    required ,
    label = 'E-mail',
    name = 'email',
    help,
    disabled,
    validateStatus,
    isDuplicate,
    placeholder = ''
  }
) {

  return (
    <Form.Item
      hasFeedback={hasFeedback}
      label={label}
      name={name}
      help={help}
      validateStatus={validateStatus}
      rules={[
        {
          required,
          message: 'Please input your email'
        },
        {
          type: 'email',
          message: 'Email is invalid'
        },
        {
          validator() {
            return isDuplicate ?
              Promise.reject('Email already exists') :
              Promise.resolve()
          }
        }
      ]}
    >
      <Input placeholder={placeholder} disabled={disabled} />
    </Form.Item>
  )
}
export default InputEmail