import React from 'react'
import {
  Descriptions,
  Empty,
  Typography,
  NavLink
} from 'components'
import {routes} from 'configs'

export const TradingClientView = ({tradingAccountRecord}) => {
  if (!tradingAccountRecord) {
    return <Empty />
  }

  return (
    <>
      <Typography.Title level={3}>Client</Typography.Title>
      <Descriptions
        bordered
        layout='vertical'
        size='small'
        column={{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3
        }}
      >
        <Descriptions.Item label='Client ID'>
          <NavLink to={routes.clientsView({clientID: tradingAccountRecord.client_id})}>
            {tradingAccountRecord.client_id}
          </NavLink>
        </Descriptions.Item>
        <Descriptions.Item label='Client'>
          {tradingAccountRecord.client_first_name} {tradingAccountRecord.client_last_name}
        </Descriptions.Item>
        <Descriptions.Item label='Client Email'>
          {tradingAccountRecord.client_email}
        </Descriptions.Item>
        <Descriptions.Item label='Account User ID'>
          {tradingAccountRecord.client_account_user_id}
        </Descriptions.Item>
        <Descriptions.Item label='Status'>
          {tradingAccountRecord.client_status}
        </Descriptions.Item>
        <Descriptions.Item label='Responsible Staff'>
          {tradingAccountRecord.responsible_staff}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
