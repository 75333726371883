import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {
  Table,
  Button
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import {
  documentsSelectors,
  documentsActions
} from 'configs/ducks/documents'
import {appStateSelectors} from 'configs/ducks/appState'
import {
  inputTypes,
  listHOC
} from 'common'

const DocumentDigisignTemplatesListPageComponent = ({
  loading, dataSource, getColumnSearchProps, docTypeList, getTopics, fetchCb, currentAccountID, history,
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps({
        dataIndex: 'id',
        placeholder: 'ID',
      })
    },
    {
      title: 'Name',
      dataIndex: 'templateName',
      ...getColumnSearchProps({
        dataIndex: 'templateName',
        placeholder: 'Record templateName'
      })
    },
    {
      title: 'eSignatures ID',
      dataIndex: 'eSignaturesId',
      ...getColumnSearchProps({
        dataIndex: 'eSignaturesId',
        placeholder: 'Record eSignaturesId'
      })
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (highlighter, record) => {

        return (
          <a onClick={() => {
            history.push(`/documents/digisign/templates/id/${record.id}`)
          }}>
            View
          </a>
        )

      }
    },
  ]

  useEffect(() => {
    getTopics({
      extra: (
        <>
          <Button onClick={() => fetchCb(currentAccountID)} icon={<SyncOutlined />} />
        </>
      )
    })
  }, [])

  return (
    <Table
      rowKey='id'
      loading={loading}
      columns={columns}
      scroll={{x: true}}
      dataSource={dataSource}
      pagination={{
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true
      }}
    />
  )
}

const {documentsEntitiesSelector, documentTypesSelector} = documentsSelectors
const {getDocumentDigisignTemplates} = documentsActions

const mapStateToProps = state => {
  const {entities, loading} = documentsEntitiesSelector(state)
  const {docTypeList} = documentTypesSelector(state)
  return {
    dataSource: entities,
    loading,
    docTypeList,
    currentAccountID: appStateSelectors.stateSelector(state).currentAccountRecord.id
  }
}

const DocumentDigisignTemplatesListPageComponentContainer = connect(mapStateToProps, dispatch => ({
  fetchCb: (currentAccountID) => {
    dispatch(getDocumentDigisignTemplates(currentAccountID))
  }
}))(listHOC(DocumentDigisignTemplatesListPageComponent))

export default DocumentDigisignTemplatesListPageComponentContainer
