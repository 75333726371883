import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import * as types from './types'

function* getXRecordIdsList(action) {
  try {
    const {payload} = yield call([API, API.get], 'xRecordIds', '/list')
    yield put({
      type: types.GET_XRECORDIDS_LIST_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_XRECORDIDS_LIST_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_XRECORDIDS_LIST_REQUEST, getXRecordIdsList),
  ])
}