import React, {
  useState,
  useEffect
} from 'react'
import {
  connect,
  useSelector
} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {
  Button,
  Row,
  Tabs
} from 'antd'

import {
  AccountSummary,
  AccountForm
} from '../common'

import queryString from 'query-string'
import {clearState} from 'configs/actions/common'
import {
  accountsActions,
  accountsSelectors
} from 'configs/ducks/backoffice-accounts'
import {appStateSelectors} from 'configs/ducks/appState'
import {FormattedMessage} from 'react-intl'

const {TabPane} = Tabs

const routes = [
  {
    path: '/backoffice/accounts/list',
    breadcrumbName: 'Account List',
  },
  {
    path: 'first',
    breadcrumbName: 'View Details',
  }
]

const BackofficeAccountViewPageComponent = ({
  accountsList,
  accountTypeList,
  countriesList,
  getAccountRecord,
  updateAccountRecord,
  currentStaff,
  getTopics,
  history,
  match: {params: {id}},
  clearState,
  loading,
  backoffice
}) => {
  const {search} = useLocation()
  const [state, setState] = useState({
    activeTab: queryString.parse(search).tab,
    currentStep: 0
  })
  useEffect(() => {
    setState({
      ...state,
      activeTab: search ? queryString.parse(search).tab : 'summary'
    })
  }, [search])
  useEffect(() => {
    getAccountRecord(id)
    getTopics({
      title: 'Account Management',
      breadCrumb: routes,
    })
    return clearState
  }, [])

  const onTabClick = e => {
    history.push(`/backoffice/accounts/view/${id}?tab=${e}`)
  }
  const onSubmit = values => {
    
    updateAccountRecord({
      // ...currentStaff,
      ...values
    })
  }
  const adminStaff = useSelector(state => state.appState.currentStaffRecord)
  
  if (backoffice !== 1) {
    return <div> You don't have access to this page, please contact xxx for help.</div>
  }
  
  return (
    <AccountForm mounting={!currentStaff.id} loading={loading} initialValues={currentStaff} onSubmit={onSubmit}>
      {form => (
        <>
          <Tabs activeKey={state.activeTab} onTabClick={onTabClick}>
            <TabPane tab='Account Summary' key='summary'>
              <AccountSummary accountsList={accountsList} accountTypeList={accountTypeList} />
            </TabPane>
          </Tabs>
          <Row justify='end'>
            <Button type='primary' htmlType='submit'>
              <FormattedMessage id='components.save_record' />
            </Button>
          </Row>
        </>
      )}
    </AccountForm>
  )
}

const mapStateToProps = state => {
  const {loading, currentStaff} = accountsSelectors.stateSelector(state)
  const {currentUserRecord} = appStateSelectors.stateSelector(state)
  const {
    accountsList, countriesList, accountTypeList
  } = appStateSelectors.stateSelector(state)
  return {
    loading,
    currentStaff,
    accountsList,
    countriesList,
    accountTypeList,
    backoffice: currentUserRecord.backoffice,
  }
}

const {updateAccountRecord, getAccountRecord} = accountsActions
export default connect(mapStateToProps, {
  getAccountRecord,
  updateAccountRecord,
  clearState
})(BackofficeAccountViewPageComponent)