import React from 'react'

import {
  Checkbox,
  ExtendedDescriptions
} from 'components'

interface ComplianceProps {
  eApplicationFormData: any;
}

const Compliance: React.FC<ComplianceProps> = ({eApplicationFormData}) => {

  return (
    <>
      <ExtendedDescriptions
        title='compliance.title'
        items={[
          {
            label: 'Public Office Or Pep',
            translate: false,
            value: <span>
                <Checkbox checked={Boolean(eApplicationFormData?.publicOfficeOrPep)} />&nbsp;
                {eApplicationFormData?.publicOfficeOrPep ? 'YES' : 'NO'}
              </span>,
          },
          {
            label: 'Family Public Office Or Pep',
            translate: false,
            value: <span>
                <Checkbox checked={Boolean(eApplicationFormData?.familyPublicOfficeOrPep)} />&nbsp;
                {eApplicationFormData?.familyPublicOfficeOrPep ? 'YES' : 'NO'}
              </span>,
          },
          {
            label: 'Aml Or Cft',
            translate: false,
            value: <span>
                <Checkbox checked={Boolean(eApplicationFormData?.amlOrCft)} />&nbsp;
                {eApplicationFormData?.amlOrCft ? 'YES' : 'NO'}
            </span>,
          },
          {
            label: 'Adverse Information',
            translate: false,
            value: <span>
                <Checkbox checked={Boolean(eApplicationFormData?.adverseInformation)} />&nbsp;
                {eApplicationFormData?.adverseInformation ? 'YES' : 'NO'}
            </span>,
          },
          {
            label: 'Refused Banking',
            translate: false,
            value: <span>
                <Checkbox checked={Boolean(eApplicationFormData?.refusedBanking)} />&nbsp;
                {eApplicationFormData?.refusedBanking ? 'YES' : 'NO'}
            </span>,
          },
        ]}
      />
    </>
  )
}

export default Compliance
