import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'
import { API } from 'aws-amplify'

import * as types from './types'
import { openNotificationWithIcon } from '../../utils'

function* createLead(action) {
  try {
    const { payload } = action

    yield call(
      [API, API.post],
      'leads',
      '/create',
      { body: payload },
    )

    yield put({
      type: types.CREATE_LEAD_SUCCESS,
      payload,
    })

    openNotificationWithIcon('success', 'Lead created successfully')
    payload.successCb()
  } catch (e) {
    yield put({ type: types.CREATE_LEAD_ERROR })
    openNotificationWithIcon('error', 'Failed to create lead', e.message)
  }
}

function* fetchLeads(action) {
  const { payload: id } = action
  try {
    const data = yield call(
      [API, API.get],
      'leads',
      `/list/account/${id}`,
    )

    yield put({
      type: types.FETCH_LEADS_SUCCESS,
      payload: data.payload,
    })

  } catch (e) {
    yield put({ type: types.FETCH_LEADS_ERROR })
    openNotificationWithIcon('error', 'Failed to fetch lead', e.message)
  }
}

function* getLeadById(action) {
  try {
    const { payload } = action
    const data = yield call(
      [API, API.get],
      'leads',
      `/getById/${payload}`,
    )

    yield put({
      type: types.GET_LEAD_SUCCESS,
      payload: data.payload,
    })

  } catch (e) {
    yield put({ type: types.GET_LEAD_ERROR })
    openNotificationWithIcon('error', 'Failed to Fetch lead', e.message)
  }
}

function* saveLeadRecord(action) {
  try {
    const { payload } = action

    openNotificationWithIcon('info', 'In Process', 'Saving information...')

    yield call(
      [API, API.put],
      'leads',
      `/update/${payload.id}`,
      { body: { ...payload } },
    )

    openNotificationWithIcon('success', 'Success', 'Successfully saved.')
    yield put({ type: types.SAVE_LEAD_SUCCESS })
  } catch (e) {
    openNotificationWithIcon('error', 'Error', 'Failed to save')
    yield put({ type: types.SAVE_LEAD_ERROR })
  }
}

function* checkleadsEmail(action) {
  try {
    const {
      payload: {
        email,
        cb
      }
    } = action
    const { payload } = yield call([API, API.get], 'leads', `/checkEmail/${email}`)
    if (!payload) {
      yield put({ type: types.CHECK_LEADS_EMAIL_ERROR })
    } else {
      yield put({ type: types.CHECK_LEADS_EMAIL_SUCCESS })
      yield call(cb)
    }
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
  }
}

function* streetCodes() {
  try {

    const { payload } = yield call([API, API.get], 'leads', '/getStreetTypes')
    yield put({
      type: types.STREET_TYPE_SUCCESS,
      payload: payload,
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({ type: types.STREET_TYPE_ERROR })
  }
}

function* getJournalsListByClientId(action) {
  try {
    const {payload} = yield call([API, API.get], 'journals', `/byLeadId/${action.payload}`)
    yield put({
      type: types.GET_JOURNAL_BY_LEAD_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.GET_JOURNAL_BY_LEAD_ERROR})
    openNotificationWithIcon('error', 'Failed to get journal list', e.message)
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.GET_JOURNAL_BY_LEAD_REQUEST, getJournalsListByClientId),
    yield takeEvery(types.CREATE_LEAD_REQUEST, createLead),
    yield takeEvery(types.FETCH_LEADS_REQUEST, fetchLeads),
    yield takeEvery(types.GET_LEAD_REQUEST, getLeadById),
    yield takeEvery(types.STREET_TYPE_REQUEST, streetCodes),
    yield takeEvery(types.SAVE_LEAD_REQUEST, saveLeadRecord),
    yield takeEvery(types.CHECK_LEADS_EMAIL_REQUEST, checkleadsEmail),
  ])
}
