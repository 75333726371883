import * as React from 'react'
import {
  Checkbox,
  Modal
} from 'antd'
import {CheckboxChangeEvent} from 'antd/es/checkbox'

interface ModalProps {
  visible: boolean;
  loading: boolean;
  onCloseModal: () => void;
  onOk: () => void;
}

const ConfirmModal: React.FC<ModalProps> = (
  {
    visible,
    loading,
    onCloseModal,
    onOk
  }
) => {
  const [state, setState] = React.useState({
    color: '',
    checked: false
  })

  React.useEffect(() => () => setState({
    color: '',
    checked: false
  }), [])
  React.useEffect(() => {
    if (!visible) {
      setState({
        color: '',
        checked: false
      })
    }
  }, [visible])

  const {
    color,
    checked
  } = state

  const onChange = ({target: {checked}}: CheckboxChangeEvent) => setState({
    checked,
    color: checked ? '' : 'red'
  })

  return (
    <Modal
      title='Verification Check Consent'
      visible={visible}
      confirmLoading={loading}
      onOk={() => {
        if (!checked) {
          setState({
            checked,
            color: 'red'
          })
        } else {
          setState({
            checked,
            color: ''
          })
          onOk()
        }
      }}
      onCancel={onCloseModal}
      okText='I Agree (Proceed)'
      cancelText='Cancel'
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
      >
        <span style={{color}}>
          I agree to for my Identification to be transmitted to Trulioo for the purpose of verification.
        </span>
      </Checkbox>
    </Modal>
  )
}

export default ConfirmModal
