import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import {API} from 'aws-amplify'

import * as types from './types'
import * as actions from './actions'
import {stateSelector} from './selectors'
import {openNotificationWithIcon} from '../../utils'

function* fetchVerifications(action) {
  try {
    const {payload: id} = action
    const {payload} = yield call([API, API.get], 'verifications', `/get/account/${id}`)
    yield put({
      type: types.FETCH_VERIFICATIONS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_VERIFICATIONS_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* fetchEnrolmentsList(action) {
  try {
    const {payload: id} = action
    const {payload} = yield call([API, API.get], 'client-monitoring', `/scheduled/enrolments/${id}`)
    yield put({
      type: types.FETCH_ENROLMENTS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_ENROLMENTS_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* fetchDailyReport(action) {
  try {
    const {payload} = yield call([API, API.get], 'client-monitoring', `/report-daily/${action.payload}`)
    yield put({
      type: types.FETCH_DAILY_REPORT_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_DAILY_REPORT_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* fetchChecksList(action) {
  try {
    const {payload} = yield call([API, API.get], 'client-monitoring', '/scheduled/verifications')
    yield put({
      type: types.FETCH_CHECKS_SUCCESS,
      payload
    })
  } catch (e) {
    yield put({type: types.FETCH_CHECKS_ERROR})
    openNotificationWithIcon('error', 'Failed to fetch data', e.message)
  }
}

function* createVerification(action) {
  try {
    const { payload } = action

    yield call(
      [API, API.post],
      'verifications',
      '/create',
      { body: payload },
    )

    yield put({
      type: types.CREATE_VERIFICATION_SUCCESS,
      payload,
    })
    payload.successCb()
  } catch (e) {
    yield put({type: types.CREATE_VERIFICATION_ERROR})
    openNotificationWithIcon('error', 'Failed to create record', e.message)
  }
}

function* getVerificationInfo(action) {
  try {
    const {payload: id} = action
    const {payload: verificationData} = yield call([API, API.get], 'verifications', `/get/id/${id}`)
    if (!verificationData) {
      throw new Error('Not found')
    }
    if (!verificationData.trulioo_record_id) {
      yield put({
        type: types.GET_VERIFICATIONS_INFO_SUCCESS,
        payload: {
          verificationData,
          trulioResult: {
            InputFields: [],
            Record: {
              DatasourceResults: [],
              RecordStatus: '',
              Rule: {}
            }
          }
        }
      })
    } else {
      const trulioResult = yield call(
        [API, API.get],
        'verifications',
        `/trulioo/${verificationData.trulioo_record_id}`
      )

      yield put({
        type: types.GET_VERIFICATIONS_INFO_SUCCESS,
        payload: {
          verificationData,
          trulioResult
        }
      })
    }

  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.GET_VERIFICATIONS_INFO_ERROR})
  }
}

function* updateVerification(action) {
  try {
    const {payload: body} = action
    yield call([API, API.put], 'verifications', `/update/${body.id}`, {body})
    openNotificationWithIcon(
      'success',
      'Save Successful',
      'Your data has been saved successfully'
    )
    yield put({type: types.UPDATE_VERIFICATION_SUCCESS})

    const {verificationData} = yield select(stateSelector)
    yield put(actions.getVerificationInfo(verificationData.id))

  } catch (e) {
    openNotificationWithIcon('error', 'Save Failed', e.message)
    yield put({type: types.UPDATE_VERIFICATION_ERROR})
  }
}

function* createTruliooRecord(action) {
  try {
    const {
      payload: {
        body,
        cb
      }
    } = action
    const resp = yield call([API, API.post], 'verifications', '/trulioo/verify', {body})
    yield put({type: types.CREATE_TRULIOO_RECORD_SUCCESS})
    yield call(cb, resp.verificationsId)
  } catch (e) {
    openNotificationWithIcon('error', 'Request Failed', e.message)
    yield put({type: types.CREATE_TRULIOO_RECORD_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.FETCH_VERIFICATIONS_REQUEST, fetchVerifications),
    yield takeEvery(types.FETCH_ENROLMENTS_REQUEST, fetchEnrolmentsList),
    yield takeEvery(types.FETCH_CHECKS_REQUEST, fetchChecksList),
    yield takeLatest(types.FETCH_DAILY_REPORT_REQUEST, fetchDailyReport),
    yield takeLatest(types.CREATE_VERIFICATION_REQUEST, createVerification),
    yield takeEvery(types.GET_VERIFICATIONS_INFO_REQUEST, getVerificationInfo),
    yield takeLatest(types.UPDATE_VERIFICATION_REQUEST, updateVerification),
    yield takeLatest(types.CREATE_TRULIOO_RECORD_REQUEST, createTruliooRecord),
  ])
}
