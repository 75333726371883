import {
  applyMiddleware,
  createStore
} from 'redux'
import rootReducer from './reducers/index'

import {createLogger} from 'redux-logger'

import thunkMiddleware from 'redux-thunk'

import createSagaMiddleware from 'redux-saga'
import saga from './saga'

const sagaMiddleware = createSagaMiddleware()

const logger = createLogger({collapsed: true})

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' ?
    applyMiddleware(
      sagaMiddleware,
      thunkMiddleware,
      logger
    ) : applyMiddleware(
      sagaMiddleware,
      thunkMiddleware
    )
)

sagaMiddleware.run(saga)

export default store
