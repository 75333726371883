import React, {
  Fragment,
  useEffect,
  useState
} from 'react'

import {
  Button,
  InputDatePicker,
  ClientsSelect,
  Input,
  Row,
  Space,
  Form,
  Select,
  Col
} from 'components'
import {DATE_FORMAT} from 'consts'
import {FormattedMessage} from 'react-intl'
import {ModalView} from '../../../common/ModalView'
import { organiserEntitiesSelector} from 'configs/ducks/organiser'
import {staffsSelectors} from 'configs/ducks/staffs'
import {
  clientsActions,
  clientsSelectors
} from 'configs/ducks/clients/index'
import {connect} from 'react-redux'
import {showNotification} from 'utils'
import listHOC from '../../../common/listHOC'
import {appStateSelectors} from 'configs/ducks/appState'
import {API} from 'aws-amplify'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw
} from 'draft-js'
import moment from 'moment'
import {FormSelect} from 'common'
import {Link} from 'react-router-dom'

const OrganiserCreateNewTaskPageComponent = ({
  isModalVisible,
  toggleModal,
  staffId,
  fetchC,
  id,
  documentsList,
  staffList,
  getDocuments,
  defaultClient
}) => {
  const [form] = Form.useForm()
  const [tradingAccountTransactions, setTradingAccountTrasactions] = useState([])
  const [currStaff, setCurrentStaff] = useState(staffId)
  const [currDocument, setCurrentDocument] = useState(null)
  const [currTransactions, setCurrentTransactions] = useState(null)

  useEffect(() => {
    if (defaultClient) {
      form.setFieldsValue({clientId: defaultClient})
    }
    if (staffId) {
      form.setFieldsValue({staffId: staffId})
    }
  }, [defaultClient, staffId])

  useEffect(() => {
    if (defaultClient) {
      getDocuments(defaultClient)
      getTransactions(defaultClient)
    }
  }, [defaultClient])

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorText, seteditorText] = useState('')

  const onEditorStateChange = editor => {
    setEditorState(editor)
    seteditorText(draftToHtml(convertToRaw(editor.getCurrentContent())))
  }

  const getTransactions = async clientId => {
    const response = await API.get('trading-transactions', `/client/${clientId}`)
    setTradingAccountTrasactions(response.payload)
  }

  const onFinish = async values => {
    try {
      await API.post('organiser', '/create', {
        body: {
          ...values,
          longDescription: editorText
        }
      })
      if (fetchC) {
        fetchC(id)
      }
      toggleModal(false)
      showNotification('success', 'Journals', 'Successfully Created')
    } catch (error) {
      showNotification('error', 'Journals', error.message)
    }
  }

  return (
    <Fragment>
      <ModalView
        title='Create New Task'
        width={800}
        isModalVisible={isModalVisible}
        handleOk={() => toggleModal(!isModalVisible)}
        handleCancel={() => toggleModal(!isModalVisible)}
        footer={null}
        body={
          <Fragment>
            <Form
              layout='vertical'
              onFinish={onFinish}
              form={form}
              initialValues={{
                priority: '2',
                dueDate: moment(moment(), 'YYYY-MM-DD').add(7, 'days').format(DATE_FORMAT)
              }}
            >
              <FormSelect
                required
                label='Staff'
                name='staffId'
                value={currStaff}
                defaultValue={staffId}
                optionValue='id'
                placeholder='Select Staff'
                getOptionProps={({file_by}) => ({children: file_by})}
                options={staffList}
                onSelect={async select => {
                  setCurrentStaff(select)
                }}
              />
              <Form.Item name='shortDescription' label='Short Description'>
                <Input />
              </Form.Item>
              <Row gutter={8}>
                <Col xs={12}>
                  <Form.Item name='priority' label='Priority'>
                    <Select>
                      <Select.Option value='1'>High</Select.Option>
                      <Select.Option value='2'>Medium</Select.Option>
                      <Select.Option value='3'>Low</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <InputDatePicker
                    name='dueDate'
                    label='date.due'
                  />
                </Col>
              </Row>

              <ClientsSelect
                required
                name='clientId'
              />

              {defaultClient && (
                <Fragment>
                  <Row gutter={8}>
                    <Col xs={12}>
                      <FormSelect
                        allowClear
                        label={
                          <Fragment>
                            <span>Document</span>

                            {currDocument && (
                              <Fragment>
                                &nbsp; &nbsp;
                                <Link to={`/documents/view/${currDocument}`}>Click here to see Document record</Link>
                              </Fragment>
                            )}
                          </Fragment>
                        }
                        name='document'
                        optionValue='id'
                        placeholder='Select Document'
                        getOptionProps={({description, id}) => ({children: `${description} [${id}]`})}
                        options={documentsList}
                        onSelect={async select => {
                          setCurrentDocument(select)
                        }}
                      />
                    </Col>
                    <Col xs={12}>
                      <FormSelect
                        allowClear
                        label={
                          <Fragment>
                            <span>Transactions</span>

                            {currTransactions && (
                              <Fragment>
                                &nbsp; &nbsp;
                                <Link to={`/trading/transactions/${currTransactions}/deposit`}>
                                  Click here to see transaction record
                                </Link>
                              </Fragment>
                            )}
                          </Fragment>
                        }
                        name='transaction'
                        optionValue='id'
                        placeholder='Select Document'
                        getOptionProps={({
                          id, record_created, debit
                        }) => ({children: `${id},  ${record_created.split('T')[0]}, [${debit}]`})}
                        options={tradingAccountTransactions}
                        onSelect={async select => {
                          setCurrentTransactions(select)
                        }}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}

              <Form.Item name='longDescription' label='long Description'>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    border: '1px solid #F1F1F1',
                    borderRadius: '2px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    height: '250px'
                  }}
                />
              </Form.Item>
              <Row justify='end'>
                <Space>
                  <Button htmlType='submit' type='primary'>
                    <FormattedMessage id='components.button.save' />
                  </Button>
                </Space>
              </Row>
            </Form>
          </Fragment>
        }
      />
    </Fragment>
  )
}

const {organiserSelector} = organiserEntitiesSelector
const {getDocuments} = clientsActions
const mapStateToProps = state => {
  return {
    currentStaff: appStateSelectors.stateSelector(state).currentStaffRecord,
    clientId: appStateSelectors.stateSelector(state).currentStaffRecord.id,
    staffId: appStateSelectors.stateSelector(state).currentStaffRecord.id,
    staffList: staffsSelectors.stateSelector(state).entities,
    documentsList: clientsSelectors.clientDocumentsSelector(state).documentsList,
    ...organiserSelector(state)
  }
}

export default connect(mapStateToProps, {getDocuments: getDocuments})(listHOC(OrganiserCreateNewTaskPageComponent))