import React from 'react'

import {
  Table,
  FormattedMessage
} from 'components'
import {SectionTitle} from '../shared'

interface TaxationProps {
  taxation: Array<{
    taxJurisdiction: string;
    tin?: string;
    noTinReason?: string;
    furtherDetails: string;
  }>
}

const Taxation: React.FC<TaxationProps> = ({taxation}) => {
  return (
    <>
      <SectionTitle>
        <FormattedMessage id='taxation.title' />
      </SectionTitle>
      <Table
        dataSource={taxation}
        columns={[
          {
            title: 'Tax Jurisdiction',
            dataIndex: 'taxJurisdiction'
          },
          {
            title: 'TIN',
            dataIndex: 'tin'
          },
          {
            title: 'No TIN Reason',
            dataIndex: 'noTinReason'
          },
          {
            title: 'Further Details',
            dataIndex: 'furtherDetails'
          },
        ]}
      />
    </>
  )
}

export default Taxation
