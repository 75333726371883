import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {API} from 'aws-amplify'
import {
  Row,
  Col,
  Form,
  Card,
  Checkbox,
  Divider
} from 'antd'

import {FormSelect} from 'common'
import dayjs from 'dayjs'
import {showNotification} from 'utils'
import {InputText} from 'components'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const AccountClosureManagement = ({
  currentStaffRecord,
  closureRecord,
  editMode,
  records
}) => {
  const [recordStatus, setStatus] = useState([])
  const [statusId, setStatusId] = useState('')
  const [staffNote, setStaffNote] = useState('')
  const [state, setState] = useState({statusId: null})

  const [record, setRecord] = useState('')
  const [task1_completeStaff_nickname, setStaff1Nickname] = useState('')
  const [task2_completeStaff_nickname, setStaff2Nickname] = useState('')
  const [task3_completeStaff_nickname, setStaff3Nickname] = useState('')
  const [task4_completeStaff_nickname, setStaff4Nickname] = useState('')

  const [task1_checked, setTask1Checked] = useState(false)
  const [task2_checked, setTask2Checked] = useState(false)
  const [task3_checked, setTask3Checked] = useState(false)
  const [task4_checked, setTask4Checked] = useState(false)

  const [checkTask1Date, setCheckTask1Date] = useState('')
  const [checkTask2Date, setCheckTask2Date] = useState('')
  const [checkTask3Date, setCheckTask3Date] = useState('')
  const [checkTask4Date, setCheckTask4Date] = useState('')
  const [checkTask1Staff, setCheckTask1Staff] = useState('')
  const [checkTask2Staff, setCheckTask2Staff] = useState('')
  const [checkTask3Staff, setCheckTask3Staff] = useState('')
  const [checkTask4Staff, setCheckTask4Staff] = useState('')

  useEffect(() => {
    API.get('clients-acc-close', '/get-closure-status-list')
      .then(({payload}) => setStatus(payload))
      .catch(() => {
        showNotification('error', 'Error', 'Failed to fetch status')
      })
  }, [])

  const getRecord = async statusId => {
    let result = ''
    result = await API.get('clients-acc-close', `/get-closure-by-id-v2/${closureRecord.id}`)
    // console.log('payload:', result.payload)
    setRecord(result.payload)
    setStaff1Nickname(result.payload?.task1_completeStaff_nickname)
    setStaff2Nickname(result.payload?.task2_completeStaff_nickname)
    setStaff3Nickname(result.payload?.task3_completeStaff_nickname)
    setStaff4Nickname(result.payload?.task4_completeStaff_nickname)
    setStaffNote(result.payload?.staffNote)
    if (result.payload?.task1_completeStaffId) {
      setTask1Checked(true)
    }
    if (result.payload?.task2_completeStaffId) {
      setTask2Checked(true)
    }
    if (result.payload?.task3_completeStaffId) {
      setTask3Checked(true)
    }
    if (result.payload?.task4_completeStaffId) {
      setTask4Checked(true)
    }
  }

  const toggleTask1Checked = task1_checked => {
    setTask1Checked(task1_checked)
  }

  const toggleTask2Checked = task2_checked => {
    setTask2Checked(task2_checked)
  }

  const toggleTask3Checked = task3_checked => {
    setTask3Checked(task3_checked)
  }

  const toggleTask4Checked = task4_checked => {
    setTask4Checked(task4_checked)
  }

  const toggleTask1Date = editedDate => {
    setCheckTask1Date(editedDate)
  }

  const toggleTask2Date = editedDate => {
    setCheckTask2Date(editedDate)
  }

  const toggleTask3Date = editedDate => {
    setCheckTask3Date(editedDate)
  }

  const toggleTask4Date = editedDate => {
    setCheckTask4Date(editedDate)
  }

  useEffect(() => {
    if (!record) {
      getRecord()
    }
  }, [record])

  return (
    <Fragment>
      <Row>
        <Col xs={18}>
          <Card title='Management'>
            <Divider orientation="left" orientationMargin="0">Task Status</Divider>
            <FormSelect
              disabled={editMode}
              required
              label='Status'
              name='statusId'
              value={statusId}
              defaultValue={statusId}
              optionValue='id'
              placeholder='Select Status'
              getOptionProps={({fileBy}) => ({children: fileBy})}
              options={recordStatus}
              onSelect={async select => {
                setStatusId(select)
                setState({statusId: select})
              }}
            />
            <InputText
              type='TextArea'
              label="account_closure_landing_page.staffNote"
              name="staffNote"
              disabled={editMode}
            />

            <Divider orientation="left" orientationMargin="0" style={{marginTop: 48}}>Closing Tasks</Divider>
            {task1_checked && (
              <React.Fragment>
                <Form.Item label={'Task 1'}>
                  <Checkbox
                    disabled={editMode || records.clientId == 0}
                    checked={task1_checked}
                    onChange={e => {
                      console.log(e)

                      if (!e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task1_completeDate: null,
                            task1_completeStaffId: null
                          }
                        })
                        toggleTask1Checked(e.target.checked)
                        setCheckTask1Staff(currentStaffRecord?.file_by)
                        toggleTask1Date('')
                      } else if (e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task1_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                            task1_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                          }
                        })
                        toggleTask1Checked(e.target.checked)
                        setCheckTask1Staff(currentStaffRecord?.file_by)
                        // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                        toggleTask1Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      }
                    }}
                  >
                    {closureRecord?.task1_completeDate
                      ? dayjs(closureRecord.task1_completeDate).format('YYYY-MM-DD HH:mm:ss')
                      : checkTask1Date}
                  </Checkbox>{' '}
                  <br/>
                  <em>
                  Completed by: &nbsp;
                    {(checkTask1Staff === '') && task1_completeStaff_nickname}{' '}
                    {(currentStaffRecord.file_by !== task1_completeStaff_nickname) && checkTask1Staff}{' '}
                  </em>
                </Form.Item>
              </React.Fragment>
            )}

            {!task1_checked && (
              <Form.Item label='Task 1' valuePropName='task1_checked'>
                <Checkbox
                  disabled={editMode || records.clientId == 0}
                  checked={task1_checked}
                  onChange={e => {
                    console.log(e)

                    if (!e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task1_completeDate: null,
                          task1_completeStaffId: null
                        }
                      })
                      toggleTask1Checked(e.target.checked)
                      setCheckTask1Staff(currentStaffRecord?.file_by)
                      toggleTask1Date('')
                    } else if (e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task1_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                          task1_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                        }
                      })
                      toggleTask1Checked(e.target.checked)
                      setCheckTask1Staff(currentStaffRecord?.file_by)
                      // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      toggleTask1Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                    }
                  }}
                >
                  Mark task as complete
                </Checkbox>
              </Form.Item>
            )}

            {task2_checked && (
              <React.Fragment>
                <Form.Item label={'Task 2'}>
                  <Checkbox
                    disabled={editMode || records.clientId == 0}
                    checked={task2_checked}
                    onChange={e => {
                      console.log(e)

                      if (!e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task2_completeDate: null,
                            task2_completeStaffId: null
                          }
                        })
                        toggleTask2Checked(e.target.checked)
                        setCheckTask2Staff(currentStaffRecord?.file_by)
                        toggleTask2Date('')
                      } else if (e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task2_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                            task2_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                          }
                        })
                        toggleTask2Checked(e.target.checked)
                        setCheckTask2Staff(currentStaffRecord?.file_by)
                        // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                        toggleTask2Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      }
                    }}
                  >
                    {closureRecord?.task2_completeDate
                      ? dayjs(closureRecord.task2_completeDate).format('YYYY-MM-DD HH:mm:ss')
                      : checkTask2Date}
                  </Checkbox>{' '}
                  <br/>
                  <em>
                  Completed by: &nbsp;
                    {(checkTask2Staff === '') && task2_completeStaff_nickname}{' '}
                    {(currentStaffRecord.file_by !== task2_completeStaff_nickname) && checkTask2Staff}{' '}
                  </em>
                </Form.Item>
              </React.Fragment>
            )}

            {!task2_checked && (
              <Form.Item label='Task 2' valuePropName='checked'>
                <Checkbox
                  disabled={editMode || records.clientId == 0}
                  checked={task2_checked}
                  onChange={e => {
                    console.log(e)

                    if (!e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task2_completeDate: null,
                          task2_completeStaffId: null
                        }
                      })
                      toggleTask2Checked(e.target.checked)
                      setCheckTask2Staff(currentStaffRecord?.file_by)
                      toggleTask2Date('')
                    } else if (e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task2_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                          task2_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                        }
                      })
                      toggleTask2Checked(e.target.checked)
                      setCheckTask2Staff(currentStaffRecord?.file_by)
                      // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      toggleTask2Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                    }
                  }}
                >
                  Mark task as complete
                </Checkbox>
              </Form.Item>
            )}

            {task3_checked && (
              <React.Fragment>
                <Form.Item label={'Task 3'}>
                  <Checkbox
                    disabled={editMode || records.clientId == 0}
                    checked={task3_checked}
                    onChange={e => {
                      console.log(e)

                      if (!e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task3_completeDate: null,
                            task3_completeStaffId: null
                          }
                        })
                        toggleTask3Checked(e.target.checked)
                        setCheckTask3Staff(currentStaffRecord?.file_by)
                        toggleTask3Date('')
                      } else if (e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task3_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                            task3_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                          }
                        })
                        toggleTask3Checked(e.target.checked)
                        setCheckTask3Staff(currentStaffRecord?.file_by)
                        // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                        toggleTask3Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      }
                    }}
                  >
                    {closureRecord?.task3_completeDate
                      ? dayjs(closureRecord.task3_completeDate).format('YYYY-MM-DD HH:mm:ss')
                      : checkTask3Date}
                  </Checkbox>{' '}
                  <br/>
                  <em>
                  Completed by: &nbsp;
                    {(checkTask3Staff === '') && task3_completeStaff_nickname}{' '}
                    {(currentStaffRecord.file_by !== task3_completeStaff_nickname) && checkTask3Staff}{' '}
                  </em>
                </Form.Item>
              </React.Fragment>
            )}

            {!task3_checked && (
              <Form.Item label='Task 3' valuePropName='checked'>
                <Checkbox
                  disabled={editMode || records.clientId == 0}
                  checked={task3_checked}
                  onChange={e => {
                    console.log(e)

                    if (!e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task3_completeDate: null,
                          task3_completeStaffId: null
                        }
                      })
                      toggleTask3Checked(e.target.checked)
                      setCheckTask3Staff(currentStaffRecord?.file_by)
                      toggleTask3Date('')
                    } else if (e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task3_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                          task3_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                        }
                      })
                      toggleTask3Checked(e.target.checked)
                      setCheckTask3Staff(currentStaffRecord?.file_by)
                      // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      toggleTask3Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                    }
                  }}
                >
                  Mark task as complete
                </Checkbox>
              </Form.Item>
            )}

            {task4_checked && (
              <React.Fragment>
                <Form.Item label={'Task 4'}>
                  <Checkbox
                    disabled={editMode || records.clientId == 0}
                    checked={task4_checked}
                    onChange={e => {
                      console.log(e)

                      if (!e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task4_completeDate: null,
                            task4_completeStaffId: null
                          }
                        })
                        toggleTask4Checked(e.target.checked)
                        setCheckTask4Staff(currentStaffRecord?.file_by)
                        toggleTask4Date('')
                      } else if (e.target.checked) {
                        API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                          body: {
                            task4_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                            task4_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                          }
                        })
                        toggleTask4Checked(e.target.checked)
                        setCheckTask4Staff(currentStaffRecord?.file_by)
                        // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                        toggleTask4Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      }
                    }}
                  >
                    {closureRecord?.task4_completeDate
                      ? dayjs(closureRecord.task4_completeDate).format('YYYY-MM-DD HH:mm:ss')
                      : checkTask4Date}
                  </Checkbox>{' '}
                  <br/>
                  <em>
                  Completed by: &nbsp;
                    {(checkTask4Staff === '') && task4_completeStaff_nickname}{' '}
                    {(currentStaffRecord.file_by !== task4_completeStaff_nickname) && checkTask4Staff}{' '}
                  </em>
                </Form.Item>
              </React.Fragment>
            )}

            {!task4_checked && (
              <Form.Item label='Task 4' valuePropName='checked'>
                <Checkbox
                  disabled={editMode || records.clientId == 0}
                  checked={task4_checked}
                  onChange={e => {
                    console.log(e)

                    if (!e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task4_completeDate: null,
                          task4_completeStaffId: null
                        }
                      })
                      toggleTask4Checked(e.target.checked)
                      setCheckTask4Staff(currentStaffRecord?.file_by)
                      toggleTask4Date('')
                    } else if (e.target.checked) {
                      API.put('clients-acc-close', `/update-clientClosureRecord/${closureRecord.id}`, {
                        body: {
                          task4_completeDate: dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                          task4_completeStaffId: `${currentStaffRecord?.id ? currentStaffRecord.id : null}`
                        }
                      })
                      toggleTask4Checked(e.target.checked)
                      setCheckTask4Staff(currentStaffRecord?.file_by)
                      // console.log(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                      toggleTask4Date(dayjs(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'))
                    }
                  }}
                >
                  Mark task as complete
                </Checkbox>
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}
export default AccountClosureManagement
