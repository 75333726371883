import React from 'react'
import {
  Button,
  Form,
  Modal,
  Row,
  Space,
  InputText,
  InputSelect,
  FileUpload,
  InputTextArea,
  FormattedMessage,
  notification
} from 'components'

import {
  API,
  Storage
} from 'utils'
import {Types} from '../../duck'
import {RcFile} from 'types'

interface CreateNewDocumentModal {
  client_id: number;
  open: boolean;
  toggleOpen: (open: boolean) => void;
  onSuccess: (document: Types.ClientDocument) => void;
}

const CreateNewDocument: React.FC<CreateNewDocumentModal> = (
  {
    client_id,
    toggleOpen,
    open,
    onSuccess
  }
) => {
  const [state, setState] = React.useState({
    saving: false,
    docTypes: []
  })

  React.useEffect(() => {
    API.get('documents', '/doctypes/list')
      .then(resp => {
        setState(prevState => ({
          ...prevState,
          docTypes: resp.payload,
        }))
      })
      .catch(e => {
        notification.error({message: e.response?.data?.error || e.message})
      })
  }, [])

  return (
    <Modal
      open={open}
      title='File Uploader'
      footer={null}
      destroyOnClose
      onCancel={() => toggleOpen(false)}
    >
      <p>Please enter your file description.</p>
      <p>You should also upload a new file in the fields below.</p>
      <Form
        preserve={false}
        layout='vertical'
        onFinish={async ({files, ...values}) => {
          try {

            setState(prevState => ({
              ...prevState,
              saving: true
            }))
            const fileResults = await Promise.all(
              files.map((file: RcFile) =>
                Storage.put(`${Date.now()}-${client_id}-${file.name}`, file.originFileObj as File, {
                  bucket: 'documents',
                  contentType: file.type
                })
                  .then(result => result.key)
              )
            )

            const {document} = await API.post('documents', '/create', {
              body: {
                ...values,
                client_id,
                files: fileResults
              }
            })

            setState(prevState => ({
              ...prevState,
              saving: false
            }))

            toggleOpen(false)
            onSuccess(document)
          } catch (e: any) {
            notification.error({message: e?.response?.data || e.message})

            setState(prevState => ({
              ...prevState,
              saving: false
            }))
          }
        }}
      >
        <InputSelect
          required
          label='common.documentType'
          name='document_type_id'
          options={state.docTypes}
          getOptionProps={(opt) => ({
            children: opt.fileBy,
            value: opt.id
          })}
        />

        <InputText
          required
          label='common.shortDescription'
          name='description'
        />
        <InputTextArea
          label='common.longDescription'
          name='long_description'
          autoSize={{
            minRows: 2,
            maxRows: 8
          }}
        />
        <FileUpload
          name='files'
          label='Files'
          listType="picture"
          multiple
        />
        <Row justify='end'>
          <Space>
            <Button
              onClick={() => toggleOpen(false)}
            >
              <FormattedMessage id='components.button.cancel' />
            </Button>
            <Button
              htmlType='submit'
              type='primary'
              loading={state.saving}
            >
              <FormattedMessage id='components.button.save' />
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default CreateNewDocument

