import * as React from 'react'
import {
  InputTextArea,
  Form,
  InputCheckbox,
  Drawer,
  Button
} from 'components'
import {clientsDuck} from 'configs'
import {
  useDispatch,
  useSelector
} from 'hooks'
import {Client} from 'types'

interface RequirementsModalProps {
  open: boolean;
  onClose: () => void;
  clientRecord: Client;
}

const RequirementsDrawer: React.FC<RequirementsModalProps> = ({
  open, onClose, clientRecord
}) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.clients.entities.loading)
  const [form] = Form.useForm()

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title="Document Requirements"
      extra={(
        <Button
          type='primary'
          loading={loading}
          onClick={() => form.submit()}
        >
          Save
        </Button>
      )}
    >
      <Form
        form={form}
        layout='vertical'
        preserve={false}
        initialValues={clientRecord}
        onFinish={async (values) => {
          dispatch(
            clientsDuck.clientsActions.saveClientRecord({
              ...values,
              id: clientRecord.id
            })
          )
        }}
      >
        <InputCheckbox
          name='documents_requireIdentity'
          label='data_clients.requireIdentity'
        />
        <InputCheckbox
          name='documents_requirePoA'
          label='data_clients.requirePoA'
        />
        <InputCheckbox
          name='documents_requireAppForm'
          label='data_clients.requireAppForm'
        />
        <InputCheckbox
          name='documents_requireEdd'
          label='data_clients.requireEdd'
        />
        <InputTextArea
          name='documents_requireNotes'
          label='data_clients.requireNotes'
          rows={2}
        />
      </Form>
    </Drawer>
  )
}

export default RequirementsDrawer
