import React, {
  useEffect,
  Fragment,
  useState
} from 'react'

import {
  Col,
  Row,
  Spin,
  Form,
  Button,
  Descriptions,
  Drawer,
  Space,
  Switch,
  Input,
  InputNumber,
  notification,
  Select,
  Radio
} from 'antd'
import moment from 'moment'
import { API } from 'aws-amplify'

const { Option } = Select

export const ClientCompanyShareholderDrawerComponent_company = ({
  visible_shareholder_company, companyDrawerData_shareholder_company, toggleModal, asyncFunction, getData
}) => {
  const [form] = Form.useForm()
  const [state, setState] = useState({})
  const [shareholderType, setShareholderType] = useState('2')
  const [beneficiallyOwned, setBeneficiallyOwned] = useState('1')

  useEffect(() => {
    setState({
      address: companyDrawerData_shareholder_company?.address,
      beneficialOwner: companyDrawerData_shareholder_company?.beneficialOwner,
      beneficiallyOwned: companyDrawerData_shareholder_company?.beneficiallyOwned,
      clientId: companyDrawerData_shareholder_company?.clientId,
      companyName: companyDrawerData_shareholder_company?.companyName,
      companyNumber: companyDrawerData_shareholder_company?.companyNumber,
      dateOfBirth: companyDrawerData_shareholder_company?.dateOfBirth,
      deleted: companyDrawerData_shareholder_company?.deleted,
      email: companyDrawerData_shareholder_company?.email,
      fileBy: companyDrawerData_shareholder_company?.fileBy,
      id: companyDrawerData_shareholder_company?.id,
      individualName: companyDrawerData_shareholder_company?.individualName,
      shareholderType: companyDrawerData_shareholder_company?.shareholderType,
      telephoneMobile: companyDrawerData_shareholder_company?.telephoneMobile,
      ownershipPercentage: companyDrawerData_shareholder_company?.ownershipPercentage,
    })
  }, [companyDrawerData_shareholder_company])

  if (companyDrawerData_shareholder_company == null) {
    return <div></div>
  }

  const onTextboxChange = (event, state) => {
    // console.log(event.target)
    // Have to use event persist to stable https://reactjs.org/docs/events.html#event-pooling
    event.persist()

    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value
      }
    })
  }

  const onInputNumbeChange = (value, state) => {
    setState((prevState) => {
      return {
        ...prevState,
        ['ownershipPercentage']: value
      }
    })
  }
  const saveRecord = (state) => {
    delete state.enableEdited
    try {
      API.put('client-company', `/update-shareholders/${companyDrawerData_shareholder_company?.clientId}/${companyDrawerData_shareholder_company?.id}`, {
        body: {
          shareholderType: state.shareholderType,
          beneficiallyOwned: beneficiallyOwned,
          beneficialOwner: state?.beneficialOwner,
          individualId: state.shareholderType === '1' ? companyDrawerData_shareholder_company.companyId : null,
          companyId: state.shareholderType === '2' ? companyDrawerData_shareholder_company.companyId : null,
          clientsShareholders: {
            individualName: state.shareholderType === '1' ? state?.individualName : null,
            companyName: state.shareholderType === '2' ? state?.companyName : null,
            companyNumber: state.shareholderType === '2' ? state?.companyNumber : null,
            shareholderType: state?.shareholderType ? state.shareholderType : companyDrawerData_shareholder_company.shareholderType,
            beneficiallyOwned,
            individualId: state.shareholderType === '1' ? companyDrawerData_shareholder_company.companyId : null,
            companyId: state.shareholderType === '2' ? companyDrawerData_shareholder_company.companyId : null,
            ownershipPercentage: state.ownershipPercentage
          },
          contactIndividuals: {
            fileBy: `${state?.lastName ? state.lastName : companyDrawerData_shareholder_company?.lastName}, ${state?.firstName ? state.firstName : companyDrawerData_shareholder_company?.firstName}`,
            firstName: state?.firstName,
            middleNames: state?.middleNames,
            lastName: state?.lastName,
            dateOfBirth: state?.dateOfBirth,
            telephoneMobile: state?.telephoneMobile,
            address: state?.address,
            streetAddressUnitNumber: state?.streetAddressUnitNumber,
            streetAddressStreetNumber: state?.streetAddressStreetNumber,
            streetAddressStreetName: state?.streetAddressStreetName,
            streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
            streetAddressSuburb: state?.streetAddressSuburb,
            streetAddressState: state?.streetAddressState,
            streetAddressPostcode: state?.streetAddressPostcode,
            streetAddressCountryId: state?.streetAddressCountryId
          },
          contactCompanies: {
            fileBy: `${state?.lastName ? state.lastName : companyDrawerData_shareholder_company?.lastName}, ${state?.firstName ? state.firstName : companyDrawerData_shareholder_company?.firstName}`,
            firstName: state?.firstName,
            middleNames: state?.middleNames,
            lastName: state?.lastName,
            dateOfBirth: state?.dateOfBirth,
            telephoneMobile: state?.telephoneMobile,
            address: state?.address,
            streetAddressUnitNumber: state?.streetAddressUnitNumber,
            streetAddressStreetNumber: state?.streetAddressStreetNumber,
            streetAddressStreetName: state?.streetAddressStreetName,
            streetAddressStreetTypeId: state?.streetAddressStreetTypeId,
            streetAddressSuburb: state?.streetAddressSuburb,
            streetAddressState: state?.streetAddressState,
            streetAddressPostcode: state?.streetAddressPostcode,
            streetAddressCountryId: state?.streetAddressCountryId
          }
        }
      })

      notification.success({message: 'Saved !'})
      getData()
    } catch (error) {
      console.error('error occured', error)
      notification.error({message: error.message})
    }
    toggleModal(false)
    if (asyncFunction) {
      asyncFunction()
    }
  }

  const onSelectChange = (value) => {
    setShareholderType(value)
    setState(
      (prevState) => {
        return {
          ...prevState,
          'shareholderType': value,
        }
      })
  }

  const onRadioChange = (e) => {
    setBeneficiallyOwned(e.target.value)
    setState(
      (prevState) => {
        return {
          ...prevState,
          'beneficiallyOwned': e.target.value,
        }
      })
  }

  return (
    <Fragment>
      <Drawer
        title='Client Company Detail (Shareholder-Company)'
        width={720}
        onClose={() => toggleModal(false)}
        visible={visible_shareholder_company}
        bodyStyle={{paddingBottom: 80}}
        mask={true}
        maskClosable={true}
        extra={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button onClick={() => toggleModal(false)} type='primary'>
              Submit
            </Button>
          </Space>
        }
      >
        <Fragment>
          <Spin spinning={!companyDrawerData_shareholder_company.id}>
            <Form form={form} scrollToFirstError={true} layout='vertical'>
              <Row gutter={[48, 0]} /*align='bottom ' justify='end'*/>
                <Form.Item label='Edit / View'>
                  <Switch
                    checkedChildren='Edit'
                    unCheckedChildren='View'
                    id='enableEdited'
                    checked={state.enableEdited == 1}
                    onChange={event => {
                      return setState(
                        {
                          ...state,
                          enableEdited: event,
                        }
                      )
                    }}
                  />
                </Form.Item>
                <Col xs={24}>
                  {companyDrawerData_shareholder_company.id && !state.enableEdited && (
                    <Descriptions
                      bordered
                      layout='vertical'
                      size='small'
                      style={{
                        marginBottom: 24,
                        marginTop: 0
                      }}
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2
                      }}
                    >
                      <Descriptions.Item label='ID'>
                        <strong>{companyDrawerData_shareholder_company.id}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Client ID'>
                        <strong>{companyDrawerData_shareholder_company.clientId}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Shareholder Type'>
                        <strong>{companyDrawerData_shareholder_company.shareholderType === '1' ? 'Individual' : 'Company'}</strong>
                      </Descriptions.Item>
                      {companyDrawerData_shareholder_company.shareholderType === '2' &&
                      <Descriptions.Item label='Company Name'>
                        <strong>{companyDrawerData_shareholder_company.companyName}</strong>
                      </Descriptions.Item>
                      }
                      {companyDrawerData_shareholder_company.shareholderType === '2' &&
                      <Descriptions.Item label='Company Number'>
                        <strong>{companyDrawerData_shareholder_company.companyNumber}</strong>
                      </Descriptions.Item>
                      }
                      <Descriptions.Item label='Ownership Percentage'>
                        <strong>{companyDrawerData_shareholder_company.ownershipPercentage}</strong>
                      </Descriptions.Item>
                    </Descriptions>

                  )}
                  {companyDrawerData_shareholder_company.id && state.enableEdited && (
                    <>
                      <Descriptions
                        bordered
                        layout='vertical'
                        size='small'
                        style={{
                          marginBottom: 24,
                          marginTop: 0
                        }}
                        column={{
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2
                        }}
                      >
                        <Descriptions.Item label='ID'>
                          <Input id='id' disabled value={state?.id ? state.id : companyDrawerData_shareholder_company.id} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        
                        <Descriptions.Item label='Shareholder Type' required>
                          <Select
                            id='shareholderType'
                            style={{width: 120,}}
                            placeholder={'Please select...'}
                            defaultValue={companyDrawerData_shareholder_company?.shareholderType ? companyDrawerData_shareholder_company.shareholderType : '2'}
                            onChange={onSelectChange}
                            disabled
                          >
                            <Option value="1">Individual</Option>
                            <Option value="2">Company</Option>
                          </Select>
                        </Descriptions.Item>
                        {(state.shareholderType === '2') &&
                          <Descriptions.Item label='Company Name'>
                            <Input id='companyName' value={state?.companyName ? state.companyName : companyDrawerData_shareholder_company.companyName} onChange={(event) => onTextboxChange(event, state)} />
                          </Descriptions.Item>
                        }
                        {(state.shareholderType === '2') &&
                        <Descriptions.Item label='Company Number'>
                          <Input id='companyNumber' value={state?.companyNumber ? state.companyNumber : companyDrawerData_shareholder_company.companyNumber} onChange={(event) => onTextboxChange(event, state)} />
                        </Descriptions.Item>
                        }
                        <Descriptions.Item label='Ownership Percentage'>
                          <InputNumber id='ownershipPercentage' max={100} min={1} value={state?.ownershipPercentage ? state.ownershipPercentage : companyDrawerData_shareholder_company.ownershipPercentage} onChange={(event) => onInputNumbeChange(event, state)} precision={0} step={1}/>
                        </Descriptions.Item>
                      </Descriptions>
                      <Button onClick={ ()=> saveRecord(state)}> Save </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}