import {v4 as uuid} from 'uuid'
import {Storage} from 'utils'

export const createFileFromURL = async (url: string) => {
  const response = await fetch(url, {headers: {'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0'}})
  const blob = await response.blob()

  const file = new File([blob], 'informationRequestsDocument', {type: blob.type})

  const s3Filename = `${Date.now()}-${uuid()}`
  
  const {key: fileID} = await Storage.put(
    s3Filename,
    file, {
      bucket: 'documents',
      contentType: file.type
    }) as { key: string; }

  return fileID
}