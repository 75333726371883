import React, {
  useEffect,
  useState
} from 'react'
import {connect} from 'react-redux'
import {docCheckSelectors} from 'configs/ducks/doc-check'
import {appStateSelectors} from 'configs/ducks/appState'

import {Tabs} from 'antd'

import CollectionsSummary from './doc_check_view/CollectionsSummary'
import CollectionsTemplateContainer from './doc_check_view/CollectionsTemplate'
import {API} from 'aws-amplify'
import {
  useHistory,
  useLocation
} from 'react-router'
import queryString from 'query-string'

const {TabPane} = Tabs

const CollectionsViewPageComponent = (
  {
    getTopics,
    accountsList,
    match: {params: {collectionID}}
  }
) => {
  const history = useHistory()
  const [activeTab, setTab] = useState('')
  const location = useLocation()

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search)
    setTab(activeTab)
  }, [location.search])

  const onTabClick = e => {
    history.push(`/settings/doc-checks/${collectionID}?tab=${e}`)
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('doc-check', `/collections/id/${collectionID}`)
        getTopics(
          'View Collection Details',
          response.payload.id
        )
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <Tabs defaultActiveKey='summary' activeKey={activeTab} onTabClick={onTabClick}>
      <TabPane tab='Summary' key='summary'>
        <CollectionsSummary id={collectionID} accountsList={accountsList}/>
      </TabPane>
      <TabPane tab='Template' key='template'>
        <CollectionsTemplateContainer id={collectionID}/>
      </TabPane>
    </Tabs>
  )
}

const {docCheckEntitiesSelector} = docCheckSelectors
const mapStateToProps = state => {
  const {accountsList} = appStateSelectors.stateSelector(state)
  return {
    accountsList,
    ...docCheckEntitiesSelector(state)
  }
}

export default connect(mapStateToProps, {})(CollectionsViewPageComponent)