import React, {
  Fragment,
  useEffect,
  useState
} from 'react'
import {
  DatePicker,
  Row,
  Col,
  Typography,
  Table,
  Button,
  Divider,
  Switch,
  Card
} from 'antd'
import {
  Area,
  // Line
} from '@ant-design/charts'
import { Line } from '@ant-design/plots'
import moment from 'moment'
import {API} from 'aws-amplify'
const {RangePicker} = DatePicker

interface IState {
  data: Array<String>[];
  headers: string[];
  name: string;
  dateSelected: boolean,
  startDate: Date;
  endDate: Date,
  totalProfit: number,
  totalSpread: number,
  totalSwaps: number,
  totalCommission: number,
  totalMarkup: number,
  dataTableTotalData: Array<String>[]
  dataCumulativeProfitTotalData: Array<String>[],
  dataCumulativeProfitTotalStringArray: string[]
}

export const DailyPositions: React.FC = () => {
  const [state, setState] = useState<IState>({
    data: [],
    headers: [],
    name: '',
    dateSelected: false,
    startDate: new Date(),
    endDate: new Date(),
    totalProfit: 0,
    totalSpread: 0,
    totalSwaps: 0,
    totalCommission: 0,
    totalMarkup: 0,
    dataTableTotalData: [],
    dataCumulativeProfitTotalData: [],
    dataCumulativeProfitTotalStringArray: []
  })
  const [checkedDataTableReport, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  // recordDateTime vs openPositionsTotalProfit
  const [openPositionsTotalProfitChartData, setOpenPositionsTotalProfitChart] = useState([])
  // recordDateTime vs totalProfitTradesReport vs cumulativeProfit
  const [closePositionsTotalProfitChartData, setClosePositionsTotalProfitChart] = useState([])
  const dataCumulativeProfitTotalStringArray : string[] = []
  const fetch = async (start: moment.Moment, end: moment.Moment): Promise<any> => {
    //#621 Fix DataTime Picker potential bugs, so that user always pick a utc date to search
    const response = await API.post('reports', '/position/daily', {
      body: {
        startDate: moment(start).format('YYYY-MM-DD 21:00:00'),
        endDate: moment(end).format('YYYY-MM-DD 22:00:00')
      }
    })

    const data = response.payload.database_result
    const temp: any = []
    const dataCumulativeProfitTotalData: any = []
    if (data) {
      data.map((item: any) => {
        temp.push({
          id: item.id,
          recordDateTime: item?.recordDateTime ? `${moment(item.recordDateTime).utc().format('YYYY-MM-DD HH:mm:ss')} UTC` : '',
          openPositionsTotalProfit: item.openPositionsTotalProfit,
          totalProfitTradesReport: item.totalProfitTradesReport,
          spread: item.spread,
          swap: item.swap,
          commission: item.commission,
          markup: item.markup
        })
      })

      let previousValue = 0
      let currentValue = 0
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++){
          currentValue = data[i].totalProfitTradesReport
          previousValue = previousValue + currentValue
          dataCumulativeProfitTotalStringArray.push(Number(previousValue).toFixed(2))
          dataCumulativeProfitTotalData.push({
            recordDateTime: temp[i].recordDateTime,
            cumulativeProfit: Number(previousValue).toFixed(2),
            closedPositionsProfit: parseFloat(Number(previousValue).toFixed(2)),
            category: 'Cumulative Profit'
          })
        }
      }
    }

    const dataTableTotalData: any = []

    let totalProfit = 0
    let totalSpread = 0
    let totalSwaps = 0
    let totalCommission = 0
    let totalMarkup = 0

    await data.forEach((pageData: any) => {
      totalProfit += Number(pageData.totalProfitTradesReport.toFixed(2))
      totalSpread += Number(pageData.spread.toFixed(2))
      totalSwaps += Number(pageData.swap.toFixed(2))
      totalCommission += Number(pageData.commission.toFixed(2))
      totalMarkup += Number(pageData.markup.toFixed(2))
    })

    await dataTableTotalData.push({
      totalProfit: totalProfit.toFixed(2),
      totalSpread: totalSpread.toFixed(2),
      totalSwaps: totalSwaps.toFixed(2),
      totalCommission: totalCommission.toFixed(2),
      totalMarkup: totalMarkup.toFixed(2)
    })

    //Reduce Data for openPositionsTotalProfits
    const openPositionsReduceData = temp.map(
      (ele: any) => {
        return {
          recordDateTime: ele.recordDateTime,
          openPositionsTotalProfit: ele.openPositionsTotalProfit
        }
      }
    )

    //Reduce Data for totalProfitTradesReport
    const totalProfitTradesReportReduceData = temp.map(
      (ele: any) => {
        return {
          recordDateTime: ele.recordDateTime,
          totalProfitTradesReport: ele.totalProfitTradesReport,
          closedPositionsProfit: ele.totalProfitTradesReport,
          category: 'Daily Profit'
        }
      }
    )

    //Reduce Data for closedPositionsProfit (with Cumulative Profit fields)
    Array.prototype.push.apply(dataCumulativeProfitTotalData,totalProfitTradesReportReduceData)

    setOpenPositionsTotalProfitChart(openPositionsReduceData)
    setClosePositionsTotalProfitChart(dataCumulativeProfitTotalData)
    
    setLoading(false)
    setState({
      ...state,
      data: temp,
      startDate: start.toDate(),
      endDate: end.toDate(),
      dateSelected: true,
      totalProfit,
      totalSpread,
      totalSwaps,
      totalCommission,
      totalMarkup,
      dataTableTotalData: dataTableTotalData,
      dataCumulativeProfitTotalData: dataCumulativeProfitTotalData,
      dataCumulativeProfitTotalStringArray: dataCumulativeProfitTotalStringArray
    })
  }

  const openPositionsTotalProfitChartConfig = {
    data: openPositionsTotalProfitChartData,
    height: 400,
    xField: 'recordDateTime',
    yField: 'openPositionsTotalProfit',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {style: {fill: '#aaa',},},
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' }
    },
    xAxis: {label: {formatter: (v: any) => `${moment(v).utc().format('DD-MM')}`}}
  }

  const totalProfitTradesReportChartConfig = {
    data: closePositionsTotalProfitChartData,
    height: 400,
    xField: 'recordDateTime',
    yField: 'closedPositionsProfit',
    seriesField: 'category',
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
    xAxis: {label: {formatter: (v: any) => `${moment(v).utc().format('DD-MM')}`}}
  }

  const columns = [
    {
      title: 'Date Time',
      dataIndex: 'recordDateTime',
      render: (text: any) => {
        return `${moment(text).utc().format('DD-MM-YYYY')}`
      }
    },
    {
      title: 'Open Positions',
      dataIndex: 'openPositionsTotalProfit'
    },
    {
      title: 'Close Positions',
      dataIndex: 'totalProfitTradesReport'
    },
    {
      title: 'Closed Positions Cumulative',
      dataIndex: 'totalProfitTradesReport',
      render: (item: any, record: any, index: any) => state.dataCumulativeProfitTotalStringArray[index]
    },
    {
      title: 'Spread',
      dataIndex: 'spread'
    },
    {
      title: 'Swap',
      dataIndex: 'swap'
    },
    {
      title: 'Commission',
      dataIndex: 'commission'
    },
    {
      title: 'Markup',
      dataIndex: 'markup'
    }
  ]

  const columnsDataTableTotalTable = [
    {
      key: 'totalProfit',
      title: 'Total Profit',
      dataIndex: 'totalProfit',
    },
    {
      key: 'totalSpread',
      title: 'Total Spread',
      dataIndex: 'totalSpread',
    },
    {
      key: 'totalSwaps',
      title: 'Total Swaps',
      dataIndex: 'totalSwaps',
    },
    {
      key: 'totalCommission',
      title: 'Total Commission',
      dataIndex: 'totalCommission',
    },
    {
      key: 'totalMarkup',
      title: 'Total Markup',
      dataIndex: 'totalMarkup',
    }
  ]

  const resetDatepicker = () => {
    setState({
      ...state,
      data: [],
      dateSelected: false
    })
  }

  const onChange = async (dates: any, dateStrings: Array<String>) => {
    if (dates){
      setLoading(true)
      await fetch(dates[0], dates[1])
    } else {
      setState({
        ...state,
        dateSelected: false
      })
    }
  }

  const handleChange = async (checkedDataTableReport: boolean | ((prevState: boolean) => boolean)) => {
    setChecked(checkedDataTableReport)
  }
  
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={16}>
          <Typography.Title level={5}>{
            state?.dateSelected
              ? `${moment(state.startDate).format('YYYY/MM/DD')} - ${moment(state.endDate).format('YYYY/MM/DD')}`
              : ''}</Typography.Title>
        </Col>
        <Col span={8} style={{textAlign: 'right'}}>
          {!state?.dateSelected ? <RangePicker onChange={onChange} /> : <Button type={'primary'} onClick={resetDatepicker}>Reset</Button>}
        </Col>
      </Row>
      <br />
      <Row gutter={[0, 24]}>
        <Col span={12}>
          <Card
            title={
              <Typography.Title level={4}>
                Open Positions
              </Typography.Title>
            }
          >
            <Area {...openPositionsTotalProfitChartConfig} />
          </Card>
        </Col>

        <Col span={12}>
 
          <Card
            title={
              <Typography.Title level={4}>
                Closed Positions
              </Typography.Title>
            }
          >
            <Line {...totalProfitTradesReportChartConfig} />
          </Card>
        </Col>
      </Row>
      <br />
      
      <Divider/>
      <Row>
        <Col span={20}>
          <Typography.Title level={4}> Data Table </Typography.Title>
        </Col>
        <Col span={4}push={1}>
          <Switch loading={loading} onChange={handleChange} checked={checkedDataTableReport} /> Show / Hide
        </Col>
      </Row>
      
      <Divider/>
      {checkedDataTableReport &&
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            size="small"
            columns={columns}
            dataSource={state.data}
            bordered={true}
            pagination={false}
            showHeader={true}
            footer={content =>
              <Row>
                <Col span={3}>
                  <strong>TOTALS:</strong>
                </Col>
                <Col span={21}>
                  <Table
                    dataSource={state.dataTableTotalData}
                    columns={columnsDataTableTotalTable}
                    bordered={true}
                    pagination={false}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>}
      {!checkedDataTableReport &&
            <Row>
              <Col span={3}>
                <strong>TOTALS:</strong>
              </Col>
              <Col span={21}>
                <Table
                  dataSource={state.dataTableTotalData}
                  columns={columnsDataTableTotalTable}
                  bordered={true}
                  pagination={false}
                />
              </Col>
            </Row>
      }
    </Fragment>
  )
}

export default DailyPositions
