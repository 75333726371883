import * as types from './types'

/**
 * @param {number} currentAccountID
 * @returns {{id: *, type: string}}
 */
export const getXRecordIdsList = currentAccountID => ({
  type: types.GET_XRECORDIDS_LIST_REQUEST,
  currentAccountID
})
