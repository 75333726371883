import {useAsyncFn} from 'react-use'
import {API} from 'aws-amplify'
import { notification } from 'antd'

export default function (id) {
  return useAsyncFn(async (data) => {
    try {
      const {payload} = await API.put('account-financial-accounts', `/transaction/${id}`, {body: data})
      notification.success({message: 'Updated !'})
      return payload
    } catch (e) {
      notification.error({message: e.message})
    }
  }, [id])
}
