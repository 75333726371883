import React from 'react'
import ReactDOM from 'react-dom'
import { Worker } from '@react-pdf-viewer/core'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import './index.css'
import 'react-phone-input-2/lib/style.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import AppContainer from './App'

import {Provider} from 'react-redux'
import store from './configs/store'

dayjs.extend(utc)

ReactDOM.render(
  <Provider store={store}>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <AppContainer />
    </Worker>
  </Provider>,
  document.getElementById('root')
)
